import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import * as moment from 'moment';
import { GoogleAnalyticsService } from "../../services/google-analytics.service";
import { UtilitiesService } from "../../services/utilities.service";

export enum sendStatus {
  SENDING = 'SENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

@Component({
  selector: "ping-download-modal",
  templateUrl: "./download-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./download-modal.component.scss"]
})


export class DownloadModalComponent implements OnInit, AfterViewInit {

  public today: Date = new Date();
  public season: string;

  public isMobile: boolean;

  constructor(
    private utils: UtilitiesService,
    public modal: MatDialogRef<DownloadModalComponent>,
    private analytics: GoogleAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.season = this.getSeason(moment().month());

  }

  ngAfterViewInit() {
    if (this.utils.isMobile.Android() || this.utils.isMobile.iOS()) {
      this.isMobile = true;
    }
  }

  public getSeason(month: number): string {

    if (month >= 3 && month <= 5) {
        return 'spring';
    }

    if (month >= 6 && month <= 8) {
        return 'summer';
    }

    if (month >= 9 && month <= 11) {
        return 'autumn';
    }

    return 'winter';
  }

  public openAppStore(){

    let platform: string;

    if (this.utils.isMobile.Android()) {
      window.open('https://play.google.com/store/apps/details?id=com.app.ping', '_blank');
      platform = 'android';
    }
    else if (this.utils.isMobile.iOS()) {
      window.open('https://apps.apple.com/us/app/ping-culture/id1487319809', '_blank');
      platform = 'ios';
    }

    if (platform) {
      this.analytics.trackFbEvent('Lead', {content_name: platform, value: 1});
      this.analytics.trackMixpanelEvent('app-download', { platform: platform });
      this.analytics.trackEvent('Download Link', 'Download Modal', platform);
    }

    this.modal.close();
  }

}
