import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageBannerComponent } from "./components/image-banner/image-banner.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FormatAddressPipe } from "./pipes/format-address.pipe";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { EmbedVideo } from "ngx-embed-video";
import { VideoEmbedPipe } from "./pipes/video-embed.pipe";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { VideoEmbedComponent } from "./components/video-embed/video-embed.component";
import { EventCardComponent } from "./components/event-card/event-card.component";
import { OfferCardComponent } from "./components/offer-card/offer-card.component";
import { FeatureCardComponent } from "./components/feature-card/feature-card.component";
import { PlaceCardComponent } from "./components/place-card/place-card.component";
import { ArtistCardComponent } from "./components/artist-card/artist-card.component";
import { ParallaxDirective } from "./directives/parallax.directive";
import { DownloadAppComponent } from "./components/download-app/download-app.component";
import { TwoDatesFormat } from "./pipes/two-dates.pipe";
import { SafeIframePipe } from "./pipes/safe-iframe.pipe";
import { TimeIntToStringPipe } from "./pipes/time-int-to-string.pipe";
import { DaysOfWeekPipe } from "./pipes/day-of-week.pipe";
import { FriendUrlPipe } from "./pipes/friend-profile-pic.pipe";
import { IsOpenPipe } from "./pipes/is-open.pipe";
import { OpenHoursTodayPipe } from "./pipes/open-hours-today.pipe";
import { SeoService } from "./services/seo.service";
import { GalleryDirective } from "./directives/gallery.directive";

import { AdditionalInfoComponent } from "./components/additional-info/additional-info.component";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { ImgFadeComponent } from './components/img-fade/img-fade.component';
import { ReadMoreComponent } from "./components/read-more/read-more.component";
import { FacilitiesComponent } from "./components/facilities/facilities.component";
import { OpenHoursComponent } from "./components/open-hours/open-hours.component";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LazyBgImgDirective } from './directives/lazy-bg-img.directive';
import { IntersectionObserverDirective } from './directives/intersection-observer.directive';
import { PromoCardComponent } from './components/promo-card/promo-card.component';
import { MatTabsModule } from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { FilterOutPipe } from './pipes/filter-out.pipe';
import { S3ImgPipe } from './pipes/s3-img.pipe';
import { GoogleImagePipe } from './pipes/google-image.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { CategoryCitiesFilterPipe } from './pipes/category-cities-filter.pipe';
import { BlogPostCardComponent } from './components/blog-post-card/blog-post-card.component';
import { CategoryCardComponent } from "./components/category-card/category-card.component";
import { RouteEncodePipe } from './pipes/route-encode.pipe';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { CitySelectComponent } from './components/city-select/city-select.component';
import { TruncatePipe } from "./pipes/truncate.pipe";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { PingVerifiedReviewComponent } from './components/ping-verified-review/ping-verified-review.component';
import { FeatureModalComponent } from "./components/feature-modal/feature-modal.component";
import { VideoContainerComponent } from "./components/video-container/video-container.component";
import { IsAwardWinningVenue } from "./pipes/award-winning-venue.pipe";
import { BlogPlaceCardComponent } from "../modules/blog/components/blog-place-card/blog-place-card.component";
import { DateAgoPipe } from "./pipes/date-ago";
import { SubscribeSendyComponent } from './components/subscribe-sendy/subscribe-sendy.component';
import { SearchFilter } from "./pipes/search.pipe";
import { OrderByPipe } from "./pipes/orderby.pipe";
import { SidebarBlogListComponent } from "./components/sidebar-blog-list/sidebar-blog-list.component";
import { FooterBlogsListComponent } from "./components/footer-blogs-list/footer-blogs-list.component";
import { RouterModule } from "@angular/router";
import { MenuComponent } from "../modules/places/components/menu/menu.component";

import { MapBoxComponent } from "./components/map-box/map-box.component";
import { MapItemSummaryComponent } from "./components/map-item-summary/map-item-summary.component";
import { SearchFilterModalComponent } from "./components/search-filter-modal/search-filter-modal.component";
import { AppStoreIconsComponent } from "./components/app-store-icons/app-store-icons.component";
import { DownloadAppLockedComponent } from "./components/download-app-locked/download-app-locked.component";
import { DownloadAppCardsComponent } from "./components/download-app-cards/download-app-cards.component";
import { AutoFocusDirective } from "./directives/autofocus.directive";
import { ShortenDaysOfWeekPipe } from "./pipes/shorten-days-of-week.pipe";
import { BlogPostListCardComponent } from "../modules/blog/components/blog-post-card/blog-post-card.component";
import { CategoriesBarComponent } from "./components/categories-bar/categories-bar.component";
import { OpenDownloadModalDirective } from "./directives/download-app-modal.directive";

@NgModule({
  declarations: [
    ImageBannerComponent,
    FormatAddressPipe,
    VideoEmbedPipe,
    ShortenDaysOfWeekPipe,
    VideoEmbedComponent,
    EventCardComponent,
    PlaceCardComponent,
    OfferCardComponent,
    FeatureCardComponent,
    CitySelectComponent,
    ArtistCardComponent,
    ParallaxDirective,
    DownloadAppComponent,
    ReadMoreComponent,
    FacilitiesComponent,
    TwoDatesFormat,
    SafeIframePipe,
    TimeIntToStringPipe,
    DaysOfWeekPipe,
    FilterOutPipe,
    IsOpenPipe,
    FriendUrlPipe,
    OpenHoursTodayPipe,
    GalleryDirective,
    AdditionalInfoComponent,
    DownloadAppCardsComponent,
    DownloadAppLockedComponent,
    AppStoreIconsComponent,
    GalleryComponent,
    ImgFadeComponent,
    VideoContainerComponent,
    OpenHoursComponent,
    LazyBgImgDirective,
    AutoFocusDirective,
    IntersectionObserverDirective,
    PromoCardComponent,
    S3ImgPipe,
    GoogleImagePipe,
    FilterPipe,
    CategoryCitiesFilterPipe,
    BlogPostCardComponent,
    CategoryCardComponent,
    RouteEncodePipe,
    FeedbackModalComponent,
    TruncatePipe,
    PingVerifiedReviewComponent,
    DateAgoPipe,
    FeatureModalComponent,
    IsAwardWinningVenue,
    BlogPlaceCardComponent,
    SubscribeSendyComponent,
    SearchFilter,
    OrderByPipe,
    SidebarBlogListComponent,
    FooterBlogsListComponent,
    MenuComponent,
    MapBoxComponent,
    MapItemSummaryComponent,
    SearchFilterModalComponent,
    BlogPostListCardComponent,
    CategoriesBarComponent,
    OpenDownloadModalDirective,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgxSkeletonLoaderModule,
    HttpClientModule,
    HttpClientJsonpModule,
    EmbedVideo,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatButtonModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    ScrollingModule,
  ],
  entryComponents: [
    DownloadAppComponent,
    ReadMoreComponent,
    GalleryComponent,
    FacilitiesComponent,
    FeedbackModalComponent,
    FeatureModalComponent,
    MapItemSummaryComponent,
    SearchFilterModalComponent,
  ],
  exports: [
    ImageBannerComponent,
    FontAwesomeModule,
    TruncatePipe,
    MatProgressSpinnerModule,
    DateAgoPipe,
    FormatAddressPipe,
    NgxSkeletonLoaderModule,
    SubscribeSendyComponent,
    VideoEmbedPipe,
    IsOpenPipe,
    PingVerifiedReviewComponent,
    OpenHoursTodayPipe,
    ShortenDaysOfWeekPipe,
    LazyBgImgDirective,
    AutoFocusDirective,
    VideoEmbedComponent,
    MatSnackBarModule,
    MatSlideToggleModule,
    EventCardComponent,
    FormsModule,
    ReactiveFormsModule,
    PlaceCardComponent,
    OfferCardComponent,
    FeatureCardComponent,
    CitySelectComponent,
    ArtistCardComponent,
    DownloadAppComponent,
    DownloadAppCardsComponent,
    DownloadAppLockedComponent,
    AppStoreIconsComponent,
    ReadMoreComponent,
    FacilitiesComponent,
    ParallaxDirective,
    GoogleImagePipe,
    CategoryCitiesFilterPipe,
    FilterPipe,
    IsAwardWinningVenue,
    GalleryDirective,
    TwoDatesFormat,
    BlogPostCardComponent,
    SafeIframePipe,
    TimeIntToStringPipe,
    DaysOfWeekPipe,
    FriendUrlPipe,
    FilterOutPipe,
    AdditionalInfoComponent,
    VideoContainerComponent,
    ImgFadeComponent,
    OpenHoursComponent,
    PromoCardComponent,
    IntersectionObserverDirective,
    MatTabsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatRadioModule,
    MatExpansionModule,
    ScrollingModule,
    S3ImgPipe,
    CategoryCardComponent,
    RouteEncodePipe,
    FeedbackModalComponent,
    FeatureModalComponent,
    BlogPlaceCardComponent,
    SearchFilter,
    OrderByPipe,
    SidebarBlogListComponent,
    FooterBlogsListComponent,
    MenuComponent,
    MapBoxComponent,
    MapItemSummaryComponent,
    SearchFilterModalComponent,
    BlogPostListCardComponent,
    CategoriesBarComponent,
    OpenDownloadModalDirective,
  ],
  providers: [
    TwoDatesFormat,
    SafeIframePipe,
    SeoService,
    TimeIntToStringPipe,
    DaysOfWeekPipe,
    FriendUrlPipe,
    FilterOutPipe,
    IsOpenPipe,
    OpenHoursTodayPipe,
    ShortenDaysOfWeekPipe,
  ]
})
export class SharedModule {}
