import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { StoreService } from 'src/app/core/services/store.service';
import { isNotEmpty } from 'src/app/core/guards/type.guards';
import { PlacesSearchFilter, SearchService } from 'src/app/shared/services/search.service';
import { GraphResponse } from 'src/app/core/models/aws';
import { EventSearchFilter } from 'src/app/core/models/models';
import { SeoService } from 'src/app/shared/services/seo.service';

@Injectable()
export class EventsListResolver implements Resolve<GraphResponse<any>> {

    constructor(
        private store: StoreService,
        private searchService: SearchService,
        @Inject(PLATFORM_ID) private platformId,
        private seoService: SeoService,
        private router: Router,
        private transferState:TransferState) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GraphResponse<any>> {

      let city = route.params['city'];
        let area = route.params['area'];
        let category = route.params['category'];
        let subCategory = route.params['subCategory'];

        let eventsFilter: EventSearchFilter = {};
        if (!city && typeof localStorage !== 'undefined') {
          city = localStorage.getItem('city') || null;
        }
        eventsFilter['city'] = city;

        if (area) {
          eventsFilter['area'] = area;
        }
        if (category) {
          eventsFilter['categories'] = [category];
        }
        if (subCategory) {
          eventsFilter['subCategories'] = [subCategory];
        }

        this.applySEO(city, area, category, subCategory);

        const eventsKeyString = `eventslist${city ? '-' + city : ''}${area ? '-' + area : ''}${category ? '-' + category : ''}${subCategory ? '-' + subCategory : ''}`;
        const EVENTS_KEY = makeStateKey<GraphResponse<any>>(eventsKeyString);

        if (this.transferState.hasKey(EVENTS_KEY)) {
          const events: GraphResponse<any> = this.transferState.get<GraphResponse<any>>(EVENTS_KEY, null);
          this.transferState.remove(EVENTS_KEY);
          return of(events);
        } else {
          this.searchService.searchEvents(eventsFilter, null, 12, 'events-events', null);
          return this.store.list$<GraphResponse<any>>('events-events')
              .pipe(
                  filter(isNotEmpty),
                  tap(e => {
                      if (isPlatformServer(this.platformId)) {
                          this.transferState.set(EVENTS_KEY, e);
                      }
                  }),
                  first()
              );
      }
    }

    private applySEO(inputCity?: string, inputArea?: string, inputCategory?: string, inputSubCategory?: string) {
      var area = inputCity ?
        inputArea ? inputArea + ', ' + inputCity : inputCity
        : null
      var category = inputSubCategory ? inputSubCategory + ' ' + inputCategory : inputCategory || null;

      if (this.router.url.includes('ping-culture.com/events')) {
        this.seoService.createLinkForCanonicalURL('https://ping-culture.com/events');
      }

      this.seoService.updateMeta(
        `Upcoming ${category || 'Events'} ${area ? 'in ' + area : 'in your area'} this weekend - Ping Culture`,
        `Check out the best upcoming ${category ? (category + ' events') : 'events'} ${area ? area : 'around you '} and book your tickets online at Ping Culture. Explore the dating events, new year events, and much more.`,
        this.router?.url || null,
        'https://ping-culture.com/assets/images/events.jpg'
      );
    }
}
