import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IArtist } from 'src/app/shared/interfaces';
import { map, catchError, first } from 'rxjs/operators';
import { getArtist } from '../api/artists.queries';
import { SearchableEventFilterInput, SearchableEventSortInput, SearchableSortDirection, SearchableEventSortableFields } from 'src/app/core/api/api';
import { searchEvents } from 'src/app/shared/api/queries';
import { ApiService } from 'src/app/core/services/api.service';
import { LoggerService } from 'src/app/core/services/logger.service';

@Injectable()
export class ArtistService extends ApiService {

  constructor(private router: Router, private logger: LoggerService) {
    super();
  }

  public artist$(id: string): Observable<IArtist | null> {
    return this.runQuery(getArtist, { id }, 'getArtist', true, true)
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          } else {
            this.router.navigate(['']);
            return null;
          }
        }),
        catchError(err => {
          this.logger.logError(err);
          this.router.navigate([''])
          return of(null);
        })
      ) as Observable<IArtist | null>;
  }

  public getArtistEvents(artist: string, nextToken?: any): Observable<{ items: [], nextToken: any }> {

    const filter: SearchableEventFilterInput = {
      artistsList: { matchPhrase: artist }
    }

    const sort: SearchableEventSortInput = {
      direction: SearchableSortDirection.asc,
      field: SearchableEventSortableFields.dateTime
    }

    return this.runQuery<any>(searchEvents, { filter, sort, nextToken, limit: 10 }, 'searchEvents', true, true)
      .pipe(first());
  }
}
