<button  class="close-button" aria-label="Close download popup" style="position: absolute;" (click)="modal.close();">
</button>

<div class="w-100 py-2 text-center mx-auto d-flex flex-column align-items-center" style="max-width: 370px;">

  <h5 class="mb-2 font-weight-normal text-uppercase">Ping Culture {{ today | date: 'y'}}</h5>
  <h5 class="black-box text-uppercase mt-0 mt-sm-1">{{ season }} release</h5>
  <h4 class="my-sm-4 my-2" style="text-transform: none;">
    A new
    <br/><span class="text-uppercase">Ping Culture</span><br/>
    for a new era of going out
  </h4>

  <img class="w-100 app-screenshot" src="assets/static/image/places-dark.png" alt="Screenshot of the new Ping Culture version"/>

  <p class="mt-3 mb-0 mobile-show" style="text-decoration: underline;" (click)="openAppStore()">Learn more</p>

  <!-- <div class="d-flex flex-row mt-2">
    <a
      href="https://apps.apple.com/us/app/ping-culture/id1487319809"
      target="_blank"
      class="p-2"
    >
      <img
        class="app-store-icon"
        src="assets/app-store-icons/download-on-the-app-store-apple.svg"
      />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.app.ping"
      target="_blank"
      class="p-2"
    >
      <img
        class="app-store-icon"
        src="assets/app-store-icons/google-play-download-android-app.svg"
      />
    </a>
  </div> -->

</div>
