import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { FeedbackService } from '../../services/feedback.service';

@Component({
  selector: 'ping-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit {

  public comment: string;
  public type: string;
  public email: string;
  public name: string;
  public loading: boolean;

  constructor(private cd: ChangeDetectorRef,
              private modal: MatDialog,
              private feedbackService: FeedbackService) { }

  ngOnInit() {
  }

  public submit() {
    if (this.comment && this.comment !== '' && this.type && this.type !== '') {
      this.loading = true;
      this.cd.detectChanges();
      this.feedbackService.submitReport({ comment: this.comment, type: this.type, name: this.name, email: this.email})
        .pipe(
          first()
        )
        .subscribe((data: any) => {
          this.modal.closeAll();
        })
    }
  }
}
