import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventsListResolver } from './modules/events/resolvers/events-list.resolver';
import { CategoriesResolver } from './modules/places/resolvers/categories.resolver';
import { CitiesResolver } from './modules/places/resolvers/cities.resolver';
import { PlacesListResolver } from './modules/places/resolvers/places-page.resolver';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/static/static.module').then(m => m.StaticModule)
  },
  {
    path: 'business',
    loadChildren: () => import('./modules/static/static.module').then(m => m.StaticModule)
  },
  {
    path: 'discover',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    resolve: { categories: CategoriesResolver, places: PlacesListResolver, cities: CitiesResolver, events: EventsListResolver }
  },
  {
    path: 'places',
    loadChildren: () => import('./modules/places/places.module').then(m => m.PlacesModule),
    // resolve: { categories: CategoriesResolver, places: PlacesListResolver, cities: CitiesResolver }

  },
  {
    path: 'events',
    loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'promoter',
    loadChildren: () => import('./modules/promoter/promoter.module').then(m => m.PromoterModule)
  },
  {
    path: 'artists',
    loadChildren: () => import('./modules/artists/artists.module').then(m => m.ArtistsModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./modules/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'top-ten',
    loadChildren: () => import('./modules/top-ten/top-ten.module').then(m => m.TopTenModule)
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./modules/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
  },
  {
    path: 'link',
    loadChildren: () => import('./modules/link/link.module').then(m => m.LinkModule)
  },
  {
    path: 'pingers',
    loadChildren: () => import('./modules/influencer/influencer.module').then(m => m.InfluencerPageModule)
  },
  // {
  //   path: 'links',
  //   loadChildren: () => import('./modules/linkin-bio/linkin-bio.module').then(m => m.LinkinBioModule)
  // },
  {
    path: 'social-connect',
    loadChildren: () => import('./modules/social-connect/social-connect.module').then(m => m.SocialConnectModule)
  },
  {
    path: 'presents',
    loadChildren: () => import('./modules/podcasts/podcasts.module').then(m => m.PodcastModule)
  },
  {
    path: 'share',
    loadChildren: () => import('./modules/link-handler/link-handler.module').then(m => m.LinkHandlerModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./modules/help/help.module').then(m => m.HelpMainModule)
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
