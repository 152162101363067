import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { IBlogPost } from 'src/app/core/models/blog';
import { Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { BlogService } from '../services/blog/blog.service';
import { GraphResponse } from 'src/app/core/models/aws';
import { SeoService } from 'src/app/shared/services/seo.service';

@Injectable()
export class BlogsResolver implements Resolve<GraphResponse<IBlogPost>> {

    constructor(
        private blogService: BlogService,
        private seo: SeoService,
        @Inject(PLATFORM_ID) private platformId,
        private router: Router,
        private transferState: TransferState) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GraphResponse<IBlogPost>> {

        let city = route.params['city'];
        if (!city && typeof localStorage !== 'undefined') {
          city = localStorage.getItem('city') || null;
        }

        const area = route.params['area'];

        this.applySEO(city, area);

        let CITY_KEY = city ? makeStateKey<GraphResponse<IBlogPost>>('blogpostlist-' + city) : null;
        const AREA_KEY = area ? makeStateKey<GraphResponse<IBlogPost>>('blogpostlist-' + area) : null;
        const KEY = makeStateKey<GraphResponse<IBlogPost>>('blogpostlist');
        if (!city && !area) {
            if (this.transferState.hasKey(KEY)) {
                const posts = this.transferState.get<GraphResponse<IBlogPost>>(KEY, null);
                this.transferState.remove(KEY);
                return of(posts);
            }
        }
        if (this.transferState.hasKey(AREA_KEY)) {
            const posts = this.transferState.get<GraphResponse<IBlogPost>>(AREA_KEY, null);
            this.transferState.remove(AREA_KEY);
            return of(posts);
        } else if (this.transferState.hasKey(CITY_KEY)) {
            const posts = this.transferState.get<GraphResponse<IBlogPost>>(CITY_KEY, null);
            this.transferState.remove(CITY_KEY);
            return of(posts);
        } else {
            if (!city && !area) {
                return this.blogService.getBlogPosts()
                    .pipe(
                        tap(postS => {
                            if (isPlatformServer(this.platformId)) {
                                this.transferState.set(KEY, postS);
                            }

                        }),
                        first()
                    );
            }
            return this.blogService.getBlogPostByCity$(city, area)
                .pipe(
                    tap(postS => {
                        if (isPlatformServer(this.platformId)) {
                            this.transferState.set(area ? AREA_KEY : CITY_KEY, postS);
                        }

                    }),
                    first()
                );
        }
    }

    private applySEO(city?: string, area?: string) {
      area = area ? area + ', ' + city : city || null;

      this.seo.updateMeta(
        `Our thoughts and insights ${area ? 'into ' + area : ''} - Ping Culture`,
        `Our hand-written thoughts and inspirations for the best (or worst) places and things to do ${area ? 'in ' + area : ' in your area'}. `,
        this.router?.url || null,
        'https://ping-culture.com/assets/images/banner-crop.jpg',
        'website'
      );

      this.seo.createLinkForCanonicalURL('https://ping-culture.com/blog/list')
    }
}
