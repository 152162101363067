/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCountersInput = {
  id?: string | null,
  parentId: string,
  itemId: string,
  attending?: number | null,
  views?: number | null,
  week?: string | null,
  booked?: number | null,
  transactions?: number | null,
  idTypeKey?: string | null,
  bookings_clicked?: number | null,
  subscribed?: number | null,
  unsubscribed?: number | null,
  checkins?: number | null,
  list?: number | null,
  shared?: number | null,
  visits?: number | null,
  reviews?: number | null,
  offersClaimed?: number | null,
  offersRedeemed?: number | null,
  loyaltyPoints?: number | null,
  loyaltyCard?: number | null,
  scan?: number | null,
  score?: number | null,
  ttl?: number | null,
  reviewScore?: number | null,
  date?: string | null,
  updatedAt?: number | null,
  createdAt?: number | null,
};

export type ModelCountersConditionInput = {
  parentId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  attending?: ModelFloatInput | null,
  views?: ModelFloatInput | null,
  week?: ModelStringInput | null,
  booked?: ModelFloatInput | null,
  transactions?: ModelFloatInput | null,
  idTypeKey?: ModelStringInput | null,
  bookings_clicked?: ModelFloatInput | null,
  subscribed?: ModelFloatInput | null,
  unsubscribed?: ModelFloatInput | null,
  checkins?: ModelFloatInput | null,
  list?: ModelFloatInput | null,
  shared?: ModelFloatInput | null,
  visits?: ModelFloatInput | null,
  reviews?: ModelFloatInput | null,
  offersClaimed?: ModelFloatInput | null,
  offersRedeemed?: ModelFloatInput | null,
  loyaltyPoints?: ModelFloatInput | null,
  loyaltyCard?: ModelFloatInput | null,
  scan?: ModelFloatInput | null,
  score?: ModelFloatInput | null,
  ttl?: ModelFloatInput | null,
  reviewScore?: ModelFloatInput | null,
  date?: ModelStringInput | null,
  updatedAt?: ModelFloatInput | null,
  createdAt?: ModelFloatInput | null,
  and?: Array< ModelCountersConditionInput | null > | null,
  or?: Array< ModelCountersConditionInput | null > | null,
  not?: ModelCountersConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Counters = {
  __typename: "Counters",
  id: string,
  parentId: string,
  itemId: string,
  attending?: number | null,
  views?: number | null,
  week?: string | null,
  booked?: number | null,
  transactions?: number | null,
  idTypeKey?: string | null,
  bookings_clicked?: number | null,
  subscribed?: number | null,
  unsubscribed?: number | null,
  checkins?: number | null,
  list?: number | null,
  shared?: number | null,
  visits?: number | null,
  reviews?: number | null,
  offersClaimed?: number | null,
  offersRedeemed?: number | null,
  loyaltyPoints?: number | null,
  loyaltyCard?: number | null,
  scan?: number | null,
  score?: number | null,
  ttl?: number | null,
  reviewScore?: number | null,
  date?: string | null,
  updatedAt?: number | null,
  createdAt?: number | null,
};

export type UpdateCountersInput = {
  id: string,
  parentId?: string | null,
  itemId?: string | null,
  attending?: number | null,
  views?: number | null,
  week?: string | null,
  booked?: number | null,
  transactions?: number | null,
  idTypeKey?: string | null,
  bookings_clicked?: number | null,
  subscribed?: number | null,
  unsubscribed?: number | null,
  checkins?: number | null,
  list?: number | null,
  shared?: number | null,
  visits?: number | null,
  reviews?: number | null,
  offersClaimed?: number | null,
  offersRedeemed?: number | null,
  loyaltyPoints?: number | null,
  loyaltyCard?: number | null,
  scan?: number | null,
  score?: number | null,
  ttl?: number | null,
  reviewScore?: number | null,
  date?: string | null,
  updatedAt?: number | null,
  createdAt?: number | null,
};

export type DeleteCountersInput = {
  id: string,
};

export type CreateDailyCountersInput = {
  id?: string | null,
  parentId: string,
  itemId: string,
  attending?: number | null,
  views?: number | null,
  week?: string | null,
  booked?: number | null,
  bookings_clicked?: number | null,
  subscribed?: number | null,
  unsubscribed?: number | null,
  checkins?: number | null,
  shared?: number | null,
  reviews?: number | null,
  offersClaimed?: number | null,
  offersRedeemed?: number | null,
  loyaltyPoints?: number | null,
  loyaltyCard?: number | null,
  scan?: number | null,
  ttl?: number | null,
  date?: string | null,
  updatedAt?: number | null,
  createdAt?: number | null,
};

export type ModelDailyCountersConditionInput = {
  parentId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  attending?: ModelIntInput | null,
  views?: ModelIntInput | null,
  week?: ModelStringInput | null,
  booked?: ModelIntInput | null,
  bookings_clicked?: ModelFloatInput | null,
  subscribed?: ModelIntInput | null,
  unsubscribed?: ModelIntInput | null,
  checkins?: ModelIntInput | null,
  shared?: ModelIntInput | null,
  reviews?: ModelIntInput | null,
  offersClaimed?: ModelFloatInput | null,
  offersRedeemed?: ModelFloatInput | null,
  loyaltyPoints?: ModelFloatInput | null,
  loyaltyCard?: ModelFloatInput | null,
  scan?: ModelIntInput | null,
  ttl?: ModelFloatInput | null,
  date?: ModelStringInput | null,
  updatedAt?: ModelFloatInput | null,
  createdAt?: ModelFloatInput | null,
  and?: Array< ModelDailyCountersConditionInput | null > | null,
  or?: Array< ModelDailyCountersConditionInput | null > | null,
  not?: ModelDailyCountersConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type DailyCounters = {
  __typename: "DailyCounters",
  id: string,
  parentId: string,
  itemId: string,
  attending?: number | null,
  views?: number | null,
  week?: string | null,
  booked?: number | null,
  bookings_clicked?: number | null,
  subscribed?: number | null,
  unsubscribed?: number | null,
  checkins?: number | null,
  shared?: number | null,
  reviews?: number | null,
  offersClaimed?: number | null,
  offersRedeemed?: number | null,
  loyaltyPoints?: number | null,
  loyaltyCard?: number | null,
  scan?: number | null,
  ttl?: number | null,
  date?: string | null,
  updatedAt?: number | null,
  createdAt?: number | null,
};

export type UpdateDailyCountersInput = {
  id: string,
  parentId?: string | null,
  itemId?: string | null,
  attending?: number | null,
  views?: number | null,
  week?: string | null,
  booked?: number | null,
  bookings_clicked?: number | null,
  subscribed?: number | null,
  unsubscribed?: number | null,
  checkins?: number | null,
  shared?: number | null,
  reviews?: number | null,
  offersClaimed?: number | null,
  offersRedeemed?: number | null,
  loyaltyPoints?: number | null,
  loyaltyCard?: number | null,
  scan?: number | null,
  ttl?: number | null,
  date?: string | null,
  updatedAt?: number | null,
  createdAt?: number | null,
};

export type DeleteDailyCountersInput = {
  id: string,
};

export type CreateUserInteractionInput = {
  id?: string | null,
  type?: string | null,
  itemId?: string | null,
  categories?: Array< string | null > | null,
  itemType?: string | null,
  city?: string | null,
  itemTitle?: string | null,
  userId?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
};

export type ModelUserInteractionConditionInput = {
  type?: ModelStringInput | null,
  itemId?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  itemType?: ModelStringInput | null,
  city?: ModelStringInput | null,
  itemTitle?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserInteractionConditionInput | null > | null,
  or?: Array< ModelUserInteractionConditionInput | null > | null,
  not?: ModelUserInteractionConditionInput | null,
};

export type UserInteraction = {
  __typename: "UserInteraction",
  id: string,
  type?: string | null,
  itemId?: string | null,
  categories?: Array< string | null > | null,
  itemType?: string | null,
  city?: string | null,
  itemTitle?: string | null,
  userId?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdateUserInteractionInput = {
  id: string,
  type?: string | null,
  itemId?: string | null,
  categories?: Array< string | null > | null,
  itemType?: string | null,
  city?: string | null,
  itemTitle?: string | null,
  userId?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
};

export type DeleteUserInteractionInput = {
  id: string,
};

export type UpdateTrackingActivityStatInput = {
  id: string,
  action?: string | null,
  itemType?: string | null,
  itemId?: string | null,
  location?: LocationInput | null,
  gender?: string | null,
  city?: string | null,
  owners?: Array< string | null > | null,
  ttl?: number | null,
  createdAt?: string | null,
};

export type LocationInput = {
  lat?: number | null,
  lon?: number | null,
};

export type ModelTrackingActivityStatConditionInput = {
  action?: ModelStringInput | null,
  itemType?: ModelStringInput | null,
  itemId?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  city?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTrackingActivityStatConditionInput | null > | null,
  or?: Array< ModelTrackingActivityStatConditionInput | null > | null,
  not?: ModelTrackingActivityStatConditionInput | null,
};

export type TrackingActivityStat = {
  __typename: "TrackingActivityStat",
  id: string,
  action?: string | null,
  itemType?: string | null,
  itemId?: string | null,
  location?: Location | null,
  gender?: string | null,
  city?: string | null,
  owners: Array< string | null >,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type Location = {
  __typename: "Location",
  lat?: number | null,
  lon?: number | null,
};

export type DeleteTrackingActivityStatInput = {
  id: string,
};

export type CreateArtistInput = {
  id?: string | null,
  title: string,
  country?: string | null,
  currency?: CurrencyInput | null,
  spotifyAppUri?: string | null,
  videoUrls?: Array< string | null > | null,
  nickname?: string | null,
  bookingConfig?: ArtistBookingConfigInput | null,
  rating?: number | null,
  pingGigsPlayed?: number | null,
  numReviews?: number | null,
  highlights?: Array< PlaceHighlightInput | null > | null,
  logo?: string | null,
  socials?: SocialItemsInput | null,
  soundcloudAppUri?: string | null,
  appleMusicAppUri?: string | null,
  youtubeUrl?: string | null,
  spotifyUrl?: string | null,
  deezerUrl?: string | null,
  mixcloudUrl?: string | null,
  stripeConnectId?: string | null,
  amazonMusicUrl?: string | null,
  stripeCustomerId?: string | null,
  tidalUrl?: string | null,
  soundcloudUrl?: string | null,
  appleMusicUrl?: string | null,
  description?: string | null,
  genres?: Array< string | null > | null,
  images?: Array< string | null > | null,
  address?: AddressInput | null,
  location?: LocationInput | null,
  createdAt: number,
  updatedAt: number,
};

export type CurrencyInput = {
  code?: string | null,
  name?: string | null,
  symbol?: string | null,
};

export type ArtistBookingConfigInput = {
  bookingsEnabled?: boolean | null,
  messageAutoResponse?: string | null,
  emailsDisabled?: boolean | null,
  hourlyRate?: number | null,
  flatRate?: number | null,
  tastersAavailable?: number | null,
  tasterDurationInMins?: number | null,
  flatRateDurationInMins?: number | null,
  countriesAvailable?: Array< string | null > | null,
  citiesAvailable?: Array< string | null > | null,
};

export type PlaceHighlightInput = {
  title?: string | null,
  image?: string | null,
  highlights?: Array< PlaceHighlightItemInput | null > | null,
  order?: number | null,
};

export type PlaceHighlightItemInput = {
  title?: string | null,
  videoUrl?: string | null,
  thumbnail?: string | null,
  createdAt?: string | null,
  isInfluencer?: boolean | null,
  duration?: number | null,
  userName?: string | null,
  userId?: string | null,
};

export type SocialItemsInput = {
  facebook?: string | null,
  twitter?: string | null,
  tiktok?: string | null,
  linkedIn?: string | null,
  instagram?: string | null,
};

export type AddressInput = {
  title?: string | null,
  streetNameNumber?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city?: string | null,
  province?: string | null,
  country?: string | null,
  postcode?: string | null,
};

export type ModelArtistConditionInput = {
  title?: ModelStringInput | null,
  country?: ModelStringInput | null,
  spotifyAppUri?: ModelStringInput | null,
  videoUrls?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  rating?: ModelFloatInput | null,
  pingGigsPlayed?: ModelFloatInput | null,
  numReviews?: ModelFloatInput | null,
  logo?: ModelStringInput | null,
  soundcloudAppUri?: ModelStringInput | null,
  appleMusicAppUri?: ModelStringInput | null,
  youtubeUrl?: ModelStringInput | null,
  spotifyUrl?: ModelStringInput | null,
  deezerUrl?: ModelStringInput | null,
  mixcloudUrl?: ModelStringInput | null,
  stripeConnectId?: ModelStringInput | null,
  amazonMusicUrl?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  tidalUrl?: ModelStringInput | null,
  soundcloudUrl?: ModelStringInput | null,
  appleMusicUrl?: ModelStringInput | null,
  description?: ModelStringInput | null,
  genres?: ModelStringInput | null,
  images?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelArtistConditionInput | null > | null,
  or?: Array< ModelArtistConditionInput | null > | null,
  not?: ModelArtistConditionInput | null,
};

export type Artist = {
  __typename: "Artist",
  id: string,
  title: string,
  country?: string | null,
  currency?: Currency | null,
  spotifyAppUri?: string | null,
  videoUrls?: Array< string | null > | null,
  nickname?: string | null,
  bookingConfig?: ArtistBookingConfig | null,
  rating?: number | null,
  pingGigsPlayed?: number | null,
  numReviews?: number | null,
  highlights?:  Array<PlaceHighlight | null > | null,
  logo?: string | null,
  socials?: SocialItems | null,
  soundcloudAppUri?: string | null,
  appleMusicAppUri?: string | null,
  youtubeUrl?: string | null,
  spotifyUrl?: string | null,
  deezerUrl?: string | null,
  mixcloudUrl?: string | null,
  stripeConnectId?: string | null,
  amazonMusicUrl?: string | null,
  stripeCustomerId?: string | null,
  tidalUrl?: string | null,
  soundcloudUrl?: string | null,
  appleMusicUrl?: string | null,
  description?: string | null,
  genres?: Array< string | null > | null,
  images?: Array< string | null > | null,
  address?: Address | null,
  location?: Location | null,
  createdAt: number,
  updatedAt: number,
  reviews?: ModelArtistReviewConnection | null,
};

export type Currency = {
  __typename: "Currency",
  code?: string | null,
  name?: string | null,
  symbol?: string | null,
};

export type ArtistBookingConfig = {
  __typename: "ArtistBookingConfig",
  bookingsEnabled?: boolean | null,
  messageAutoResponse?: string | null,
  emailsDisabled?: boolean | null,
  hourlyRate?: number | null,
  flatRate?: number | null,
  tastersAavailable?: number | null,
  tasterDurationInMins?: number | null,
  flatRateDurationInMins?: number | null,
  countriesAvailable?: Array< string | null > | null,
  citiesAvailable?: Array< string | null > | null,
};

export type PlaceHighlight = {
  __typename: "PlaceHighlight",
  title?: string | null,
  image?: string | null,
  highlights?:  Array<PlaceHighlightItem | null > | null,
  order?: number | null,
};

export type PlaceHighlightItem = {
  __typename: "PlaceHighlightItem",
  title?: string | null,
  videoUrl?: string | null,
  thumbnail?: string | null,
  createdAt?: string | null,
  isInfluencer?: boolean | null,
  duration?: number | null,
  userName?: string | null,
  userId?: string | null,
};

export type SocialItems = {
  __typename: "SocialItems",
  facebook?: string | null,
  twitter?: string | null,
  tiktok?: string | null,
  linkedIn?: string | null,
  instagram?: string | null,
};

export type Address = {
  __typename: "Address",
  title?: string | null,
  streetNameNumber?: string | null,
  addressLine1?: string | null,
  addressLine2?: string | null,
  addressLine3?: string | null,
  city?: string | null,
  province?: string | null,
  country?: string | null,
  postcode?: string | null,
};

export type ModelArtistReviewConnection = {
  __typename: "ModelArtistReviewConnection",
  items:  Array<ArtistReview | null >,
  nextToken?: string | null,
};

export type ArtistReview = {
  __typename: "ArtistReview",
  id: string,
  review?: string | null,
  score?: number | null,
  artistId: string,
  dateOfVisit?: number | null,
  publisherId: string,
  entityId: string,
  status?: string | null,
  entityTitle?: string | null,
  createdAt: number,
  updatedAt: number,
};

export type UpdateArtistInput = {
  id: string,
  title?: string | null,
  country?: string | null,
  currency?: CurrencyInput | null,
  spotifyAppUri?: string | null,
  videoUrls?: Array< string | null > | null,
  nickname?: string | null,
  bookingConfig?: ArtistBookingConfigInput | null,
  rating?: number | null,
  pingGigsPlayed?: number | null,
  numReviews?: number | null,
  highlights?: Array< PlaceHighlightInput | null > | null,
  logo?: string | null,
  socials?: SocialItemsInput | null,
  soundcloudAppUri?: string | null,
  appleMusicAppUri?: string | null,
  youtubeUrl?: string | null,
  spotifyUrl?: string | null,
  deezerUrl?: string | null,
  mixcloudUrl?: string | null,
  stripeConnectId?: string | null,
  amazonMusicUrl?: string | null,
  stripeCustomerId?: string | null,
  tidalUrl?: string | null,
  soundcloudUrl?: string | null,
  appleMusicUrl?: string | null,
  description?: string | null,
  genres?: Array< string | null > | null,
  images?: Array< string | null > | null,
  address?: AddressInput | null,
  location?: LocationInput | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteArtistInput = {
  id: string,
};

export type CreateArtistReviewInput = {
  id?: string | null,
  review?: string | null,
  score?: number | null,
  artistId: string,
  dateOfVisit?: number | null,
  publisherId: string,
  entityId: string,
  status?: string | null,
  entityTitle?: string | null,
  createdAt: number,
  updatedAt: number,
};

export type ModelArtistReviewConditionInput = {
  review?: ModelStringInput | null,
  score?: ModelIntInput | null,
  artistId?: ModelIDInput | null,
  dateOfVisit?: ModelFloatInput | null,
  publisherId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  entityTitle?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelArtistReviewConditionInput | null > | null,
  or?: Array< ModelArtistReviewConditionInput | null > | null,
  not?: ModelArtistReviewConditionInput | null,
};

export type UpdateArtistReviewInput = {
  id: string,
  review?: string | null,
  score?: number | null,
  artistId?: string | null,
  dateOfVisit?: number | null,
  publisherId?: string | null,
  entityId?: string | null,
  status?: string | null,
  entityTitle?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteArtistReviewInput = {
  id: string,
};

export type CreateArtistEventPositionInput = {
  id?: string | null,
  location?: LocationInput | null,
  eventDate?: number | null,
  city?: string | null,
  ttl?: number | null,
  createdAt: number,
  updatedAt: number,
  artistEventPositionArtistId?: string | null,
};

export type ModelArtistEventPositionConditionInput = {
  eventDate?: ModelFloatInput | null,
  city?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelArtistEventPositionConditionInput | null > | null,
  or?: Array< ModelArtistEventPositionConditionInput | null > | null,
  not?: ModelArtistEventPositionConditionInput | null,
};

export type ArtistEventPosition = {
  __typename: "ArtistEventPosition",
  id: string,
  location?: Location | null,
  eventDate?: number | null,
  city?: string | null,
  ttl?: number | null,
  createdAt: number,
  updatedAt: number,
  artist?: Artist | null,
};

export type UpdateArtistEventPositionInput = {
  id: string,
  location?: LocationInput | null,
  eventDate?: number | null,
  city?: string | null,
  ttl?: number | null,
  createdAt?: number | null,
  updatedAt?: number | null,
  artistEventPositionArtistId?: string | null,
};

export type DeleteArtistEventPositionInput = {
  id: string,
};

export type CreateBlogPostInput = {
  id?: string | null,
  postType?: string | null,
  title?: string | null,
  desc?: string | null,
  city: string,
  area?: string | null,
  catgories?: Array< string | null > | null,
  headerImg?: string | null,
  readCount?: number | null,
  shareCount?: number | null,
  metaDesc?: string | null,
  metaTitle?: string | null,
  videoUrl?: string | null,
  instagram?: string | null,
  twitter?: string | null,
  website?: string | null,
  facebook?: string | null,
  userId?: string | null,
  publishStatus?: string | null,
  owners?: Array< string | null > | null,
  ttl?: number | null,
  content?: Array< BlogSectionContentInput | null > | null,
  summary?: Array< BlogSummaryInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type BlogSectionContentInput = {
  title?: string | null,
  image?: string | null,
  content?: string | null,
};

export type BlogSummaryInput = {
  title?: string | null,
  content?: string | null,
};

export type ModelBlogPostConditionInput = {
  postType?: ModelStringInput | null,
  title?: ModelStringInput | null,
  desc?: ModelStringInput | null,
  city?: ModelStringInput | null,
  area?: ModelStringInput | null,
  catgories?: ModelStringInput | null,
  headerImg?: ModelStringInput | null,
  readCount?: ModelFloatInput | null,
  shareCount?: ModelFloatInput | null,
  metaDesc?: ModelStringInput | null,
  metaTitle?: ModelStringInput | null,
  videoUrl?: ModelStringInput | null,
  instagram?: ModelStringInput | null,
  twitter?: ModelStringInput | null,
  website?: ModelStringInput | null,
  facebook?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  publishStatus?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBlogPostConditionInput | null > | null,
  or?: Array< ModelBlogPostConditionInput | null > | null,
  not?: ModelBlogPostConditionInput | null,
};

export type BlogPost = {
  __typename: "BlogPost",
  id: string,
  postType?: string | null,
  title?: string | null,
  desc?: string | null,
  city: string,
  area?: string | null,
  catgories?: Array< string | null > | null,
  headerImg?: string | null,
  readCount?: number | null,
  shareCount?: number | null,
  metaDesc?: string | null,
  metaTitle?: string | null,
  videoUrl?: string | null,
  instagram?: string | null,
  twitter?: string | null,
  website?: string | null,
  facebook?: string | null,
  userId?: string | null,
  publishStatus?: string | null,
  owners?: Array< string | null > | null,
  ttl?: number | null,
  content?:  Array<BlogSectionContent | null > | null,
  summary?:  Array<BlogSummary | null > | null,
  createdAt: string,
  updatedAt: string,
  entities?: ModelBlogPostEntityConnection | null,
  events?: ModelBlogPostEventConnection | null,
  repeatEvents?: ModelBlogPostRepeatEventConnection | null,
  user?: Publisher | null,
};

export type BlogSectionContent = {
  __typename: "BlogSectionContent",
  title?: string | null,
  image?: string | null,
  content?: string | null,
};

export type BlogSummary = {
  __typename: "BlogSummary",
  title?: string | null,
  content?: string | null,
};

export type ModelBlogPostEntityConnection = {
  __typename: "ModelBlogPostEntityConnection",
  items:  Array<BlogPostEntity | null >,
  nextToken?: string | null,
};

export type BlogPostEntity = {
  __typename: "BlogPostEntity",
  id: string,
  entityId: string,
  blogPostId: string,
  summary?: string | null,
  title?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
  blogPost: BlogPost,
  owner?: string | null,
  entity: Entity,
};

export type Entity = {
  __typename: "Entity",
  id: string,
  title: string,
  shortUrl?: string | null,
  subtitle?: string | null,
  lastAdminCheck?: string | null,
  area?: string | null,
  contacts?:  Array<ContactInfo | null > | null,
  notes?: string | null,
  summary?: string | null,
  uniqueAspect?: string | null,
  tags?: Array< string | null > | null,
  highlights?:  Array<PlaceHighlight | null > | null,
  nickname?: string | null,
  hasEmailed?: boolean | null,
  firstName?: string | null,
  hasStudentDiscount?: boolean | null,
  LastName?: string | null,
  config?: EntityConfig | null,
  nearbyStations?:  Array<EntityClosestStation | null > | null,
  outdoorArea?: boolean | null,
  outdoorHeating?: boolean | null,
  description?: string | null,
  stripeSubscriptionItemId?: string | null,
  emojiSummary?: Array< string | null > | null,
  categories?: Array< string | null > | null,
  phone?: string | null,
  internationalPhoneNumber?: string | null,
  logo?: string | null,
  pushTimestamps?: Array< number | null > | null,
  websiteUrl?: string | null,
  orderfromTableUrl?: string | null,
  location?: Location | null,
  entityTitle?: string | null,
  opens?: number | null,
  closes?: number | null,
  asFeaturedOn?: Array< string | null > | null,
  username?: string | null,
  trendingScore?: number | null,
  openHours?: DailyOpeningTimes | null,
  status: string,
  address?: Address | null,
  virtualTourUrl?: string | null,
  emailsDisabled?: boolean | null,
  reviewsDisabled?: boolean | null,
  video?: string | null,
  pageVideos?:  Array<VideoData | null > | null,
  cardVideos?:  Array<VideoData | null > | null,
  sponsors?:  Array<Sponsor | null > | null,
  headerVideos?: Array< string | null > | null,
  videoData?:  Array<VideoData | null > | null,
  city: string,
  currency?: Currency | null,
  features?:  Array<Feature | null > | null,
  entityFeatures?:  Array<EntityFeature | null > | null,
  rules?: string | null,
  position?: number | null,
  score?: number | null,
  score_before_boost?: number | null,
  boost_multiplier?: number | null,
  boost_until?: string | null,
  boostPurchaseId?: string | null,
  reviewRating?: number | null,
  reviewCount?: number | null,
  canMessage?: boolean | null,
  forceupdate?: boolean | null,
  email?: string | null,
  menuUrlList?:  Array<UrlType | null > | null,
  menuPDFList?:  Array<UrlType | null > | null,
  menuImages?: Array< string | null > | null,
  menu?: Menu | null,
  price?: string | null,
  featured?: string | null,
  cuisines?: Array< string | null > | null,
  dietryOptions?: Array< string | null > | null,
  foodDeliveryAppLinks?:  Array<FoodDeliveryAppLink | null > | null,
  verified?: boolean | null,
  qrIds?: Array< string | null > | null,
  publisherId: string,
  subscribeOfferId?: string | null,
  subscriberCount?: number | null,
  viewCount?: number | null,
  bookCount?: number | null,
  shareCount?: number | null,
  checkInCount?: number | null,
  addToListCount?: number | null,
  pushTimestamp?: number | null,
  extraInfo?:  Array<AccordionData | null > | null,
  images?: Array< string | null > | null,
  googleImages?:  Array<GoogleImage | null > | null,
  socials?: SocialItems | null,
  minBookingLimit?: number | null,
  maxBookingLimit?: number | null,
  bookingUrl?: string | null,
  createdAt: number,
  offersArray?: Array< string | null > | null,
  whatsappNumber?: string | null,
  updatedAt: number,
  conversations?: ModelConvoLinkConnection | null,
  timelines?:  Array<Timeline | null > | null,
  checklist?: Checklist | null,
  owner?: string | null,
  reviewScores?: ReviewScores | null,
  safetyChecklist?: Checklist | null,
  venueSafetyData?: VenueSafetyData | null,
  capacity?: number | null,
  acceptWalkIns?: boolean | null,
  covidMeasures?: CovidMeasures | null,
  mediaChannels?: MediaChannels | null,
  fidelConfig?: FidelConfig | null,
  pingReview?: PingReview | null,
  pingAwards?:  Array<PingAward | null > | null,
  blogPosts?: ModelBlogPostEntityConnection | null,
  bookingTables?: ModelBookableTableConnection | null,
  checkins?: ModelCheckInConnection | null,
  events?: ModelEventConnection | null,
  repeatEvents?: ModelRepeatEventConnection | null,
  reviews?: ModelReviewConnection | null,
  offers?: ModelSpecialOfferConnection | null,
  dailyPromotions?: ModelDailyPromotionsConnection | null,
  publisher?: Publisher | null,
  tables?: ModelVenueTableConnection | null,
  collection?: ModelPlaceCollectionEntityConnection | null,
  subscribers?: ModelSubscriberConnection | null,
};

export type ContactInfo = {
  __typename: "ContactInfo",
  title?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  venue?: string | null,
  email?: string | null,
  phone?: string | null,
  position?: string | null,
  notes?: string | null,
};

export type EntityConfig = {
  __typename: "EntityConfig",
  loyaltyEnabled?: boolean | null,
  canMessage?: boolean | null,
  canBook?: boolean | null,
  canCheckin?: boolean | null,
  canCall?: boolean | null,
  canEmail?: boolean | null,
  autoResponseMessage?: string | null,
  enterVenueCheckInEnabled?: boolean | null,
  checkInMessage?: string | null,
  colorTheme?: string | null,
  colourScheme?: EntityColourScheme | null,
  bookingConfig?: EntityBookingConfig | null,
  emailsDisabled?: boolean | null,
  reviewsDisabled?: boolean | null,
  enableTrackTrace?: boolean | null,
};

export type EntityColourScheme = {
  __typename: "EntityColourScheme",
  primary?: string | null,
  secondary?: string | null,
  text?: string | null,
  buttons?: Array< string | null > | null,
  backgrounds?: Array< string | null > | null,
};

export type EntityBookingConfig = {
  __typename: "EntityBookingConfig",
  type?: string | null,
  bookingProvider?: EntityBookingProvider | null,
  bookingProviderBackup?: string | null,
  bookingProviderUsername?: string | null,
  bookingProviderUsernameBackup?: string | null,
  canAutoBook?: boolean | null,
  styles?: EntityBookingEmailStyles | null,
  notes?: string | null,
  bookingDurationMins?: number | null,
  bookingEmails?: Array< string | null > | null,
  bookingPhoneNumber?: string | null,
  serviceCategories?:  Array<EntityBookingServiceCategory | null > | null,
  shifts?:  Array<EntityBookingShifts | null > | null,
  bookingTypes?:  Array<ConfigBookingType | null > | null,
  areas?: Array< string | null > | null,
};

export enum EntityBookingProvider {
  SEVENROOMS = "SEVENROOMS",
  RESDIARY = "RESDIARY",
  OPENTABLE = "OPENTABLE",
  DESIGNMYNIGHT = "DESIGNMYNIGHT",
  BOOKATABLE = "BOOKATABLE",
  RESY = "RESY",
  TOCK = "TOCK",
  OBEE = "OBEE",
  NOWBOOKIT = "NOWBOOKIT",
  PAXBOOKING = "PAXBOOKING",
  CUSTOM = "CUSTOM",
  PING = "PING",
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  TABLEAGENT = "TABLEAGENT",
  DINEPLAN = "DINEPLAN",
}


export type EntityBookingEmailStyles = {
  __typename: "EntityBookingEmailStyles",
  headerBg?: string | null,
  logoWidth?: number | null,
  slideValue?: number | null,
  btnColour?: string | null,
  headerPaddingHeight?: number | null,
  logo?: string | null,
};

export type EntityBookingServiceCategory = {
  __typename: "EntityBookingServiceCategory",
  title?: string | null,
  color?: string | null,
  status?: string | null,
};

export type EntityBookingShifts = {
  __typename: "EntityBookingShifts",
  shiftCategory?: EntityBookingServiceCategory | null,
  days?: Array< number | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  maxCovers?: number | null,
  times?: Array< string | null > | null,
  durationPerTable?:  Array<EntityBookingShiftDuration | null > | null,
  status?: string | null,
};

export type EntityBookingShiftDuration = {
  __typename: "EntityBookingShiftDuration",
  guests?: number | null,
  durationInMinutes?: number | null,
};

export type ConfigBookingType = {
  __typename: "ConfigBookingType",
  title?: string | null,
  desc?: string | null,
};

export type EntityClosestStation = {
  __typename: "EntityClosestStation",
  title?: string | null,
  distance?: number | null,
  line?: string | null,
  zone?: number | null,
  type?: string | null,
};

export type DailyOpeningTimes = {
  __typename: "DailyOpeningTimes",
  monday?: OpenHours | null,
  tuesday?: OpenHours | null,
  wednesday?: OpenHours | null,
  thursday?: OpenHours | null,
  friday?: OpenHours | null,
  saturday?: OpenHours | null,
  sunday?: OpenHours | null,
};

export type OpenHours = {
  __typename: "OpenHours",
  open?: number | null,
  close?: number | null,
  fullDay?: boolean | null,
  closed?: boolean | null,
  vibe?: string | null,
  walkInWaitTimeInMins?: number | null,
  perfectFor?: Array< string | null > | null,
};

export type VideoData = {
  __typename: "VideoData",
  state?: string | null,
  key?: string | null,
  thumbnail?: string | null,
  duration?: number | null,
  updatedAt?: string | null,
  types?: Array< string | null > | null,
};

export type Sponsor = {
  __typename: "Sponsor",
  id?: string | null,
  title?: string | null,
  logo?: string | null,
  desc?: string | null,
};

export type Feature = {
  __typename: "Feature",
  title?: string | null,
  icon?: Array< string | null > | null,
};

export type EntityFeature = {
  __typename: "EntityFeature",
  images?: Array< string | null > | null,
  title?: string | null,
  description?: string | null,
  bookUrl?: string | null,
  status?: string | null,
};

export type UrlType = {
  __typename: "UrlType",
  title?: string | null,
  type?: string | null,
  url?: string | null,
};

export type Menu = {
  __typename: "Menu",
  id: string,
  sections?:  Array<MenuSection | null > | null,
  title?: string | null,
  status: string,
};

export type MenuSection = {
  __typename: "MenuSection",
  id: string,
  description?: string | null,
  title?: string | null,
  type?: string | null,
  icon?: Array< string | null > | null,
  items?:  Array<MenuItem | null > | null,
  status?: string | null,
};

export type MenuItem = {
  __typename: "MenuItem",
  id: string,
  title: string,
  description?: string | null,
  price?: number | null,
  image?: string | null,
  offerPrice?: number | null,
  type?: MenuItemType | null,
  status?: string | null,
};

export enum MenuItemType {
  FOOD = "FOOD",
  DRINK = "DRINK",
  SNACK = "SNACK",
  ITEM = "ITEM",
}


export type FoodDeliveryAppLink = {
  __typename: "FoodDeliveryAppLink",
  title?: string | null,
  icon?: string | null,
  url?: string | null,
};

export type AccordionData = {
  __typename: "AccordionData",
  title?: string | null,
  content?: string | null,
  url?: string | null,
};

export type GoogleImage = {
  __typename: "GoogleImage",
  html_attributions?: Array< string | null > | null,
  image?: string | null,
};

export type ModelConvoLinkConnection = {
  __typename: "ModelConvoLinkConnection",
  items:  Array<ConvoLink | null >,
  nextToken?: string | null,
};

export type ConvoLink = {
  __typename: "ConvoLink",
  id: string,
  status?: string | null,
  createdAt: number,
  updatedAt: number,
  owners: Array< string | null >,
  messages?: ModelMessageConnection | null,
  publisherId: string,
  entityId: string,
  userId: string,
  entity?: Entity | null,
  publisher?: Publisher | null,
  user?: User | null,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  messageConversationId: string,
  conversation?: ConvoLink | null,
  content: string,
  contentType?: string | null,
  author: string,
  entity: string,
  receiver: string,
  owners: Array< string | null >,
  status?: string | null,
  createdAt: number,
  updatedAt: number,
};

export type Publisher = {
  __typename: "Publisher",
  id: string,
  cognitoUsername?: string | null,
  financeEmail?: string | null,
  role?: string | null,
  profilePicture?: string | null,
  bio?: string | null,
  config?: PublisherConfig | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  publisherType: PublisherType,
  editors?: Array< string | null > | null,
  owner: string,
  username?: string | null,
  currency?: Currency | null,
  country?: Country | null,
  pushTimestamps?: Array< number | null > | null,
  emailsDisabled?: boolean | null,
  address?: Address | null,
  timezone?: string | null,
  subscibedToEmails?: boolean | null,
  timezoneOffset?: number | null,
  logo?: string | null,
  businessName?: string | null,
  description?: string | null,
  websiteUrl?: string | null,
  whatsappNumber?: string | null,
  phone?: string | null,
  socials?: SocialItems | null,
  canMessage?: boolean | null,
  subscriberCount?: number | null,
  loyaltyCount?: number | null,
  status?: string | null,
  termsVersionSigned?: number | null,
  activated?: string | null,
  reviewsDisabled?: boolean | null,
  reviewRating?: number | null,
  reviewCount?: number | null,
  activationDate?: string | null,
  stripeConnectId?: string | null,
  contacts?:  Array<ContactInfo | null > | null,
  stripeCustomerId?: string | null,
  fidelId?: string | null,
  stripeSubscriptions?:  Array<StripeCustomerSubscription | null > | null,
  conversations?: ModelConvoLinkConnection | null,
  fidelConfig?: FidelConfig | null,
  createdAt: number,
  updatedAt: number,
  events?: ModelEventConnection | null,
  repeatEvents?: ModelRepeatEventConnection | null,
  reviews?: ModelReviewConnection | null,
  offers?: ModelSpecialOfferConnection | null,
  loyaltyCards?: ModelLoyaltyCardConnection | null,
  entities?: ModelEntityConnection | null,
  subscribers?: ModelSubscriberConnection | null,
};

export type PublisherConfig = {
  __typename: "PublisherConfig",
  features?: ConfigFeatures | null,
  type?: PublisherType | null,
  isBespoke?: boolean | null,
  standAloneVenue?: boolean | null,
  notificationsEnabled?: boolean | null,
  mailMarketingProvider?: MailMarketingProvider | null,
  paymentProvier?: PaymentProvider | null,
  POSProviders?: POSProvider | null,
  eventsConfig?: ConfigEvents | null,
  bookingsConfig?: ConfigBookings | null,
  offersConfig?: ConfigOffers | null,
  subscribersConfig?: ConfigSubscribers | null,
  loyaltyConfig?: ConfigLoyalty | null,
  menuConfig?: MenuConfig | null,
  colourScheme?: EntityColourScheme | null,
};

export type ConfigFeatures = {
  __typename: "ConfigFeatures",
  dashboard?: boolean | null,
  events?: boolean | null,
  places?: boolean | null,
  loyalty?: boolean | null,
  messaging?: boolean | null,
  offers?: boolean | null,
  promos?: boolean | null,
  subscribers?: boolean | null,
  updates?: boolean | null,
  reviews?: boolean | null,
  bookings?: boolean | null,
};

export enum PublisherType {
  ADMIN = "ADMIN",
  USER = "USER",
  PUBLISHER = "PUBLISHER",
  ARTIST = "ARTIST",
  PROMOTER = "PROMOTER",
}


export enum MailMarketingProvider {
  MAILCHIMP = "MAILCHIMP",
  SENDGRID = "SENDGRID",
}


export enum PaymentProvider {
  STRIPE = "STRIPE",
  PAYPAL = "PAYPAL",
}


export enum POSProvider {
  EPOS = "EPOS",
}


export type ConfigEvents = {
  __typename: "ConfigEvents",
  usePaymentProvider?: boolean | null,
  tablesEnabled?: boolean | null,
  tablesConfig?: ConfigTables | null,
};

export type ConfigTables = {
  __typename: "ConfigTables",
  emailsDisabled?: boolean | null,
  integratePOS?: boolean | null,
};

export type ConfigBookings = {
  __typename: "ConfigBookings",
  bookingProvider?: BookingSystemProvider | null,
  bookingTypes?:  Array<ConfigBookingType | null > | null,
  integratePOS?: boolean | null,
  emailsDisabled?: boolean | null,
};

export enum BookingSystemProvider {
  SEVENROOMS = "SEVENROOMS",
  RESDIARY = "RESDIARY",
  OPENTABLE = "OPENTABLE",
  DESIGNMYNIGHT = "DESIGNMYNIGHT",
  BOOKATABLE = "BOOKATABLE",
  RESY = "RESY",
  TOCK = "TOCK",
  PAXBOOKING = "PAXBOOKING",
  CUSTOM = "CUSTOM",
  PING = "PING",
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  TABLEAGENT = "TABLEAGENT",
  DINEPLAN = "DINEPLAN",
}


export type ConfigOffers = {
  __typename: "ConfigOffers",
  integratePOS?: boolean | null,
};

export type ConfigSubscribers = {
  __typename: "ConfigSubscribers",
  isPaid?: boolean | null,
  subscriptionLength?: number | null,
};

export type ConfigLoyalty = {
  __typename: "ConfigLoyalty",
  integratePOS?: boolean | null,
  loyaltyEnabled?: boolean | null,
  tiers?:  Array<LoyaltyTier | null > | null,
  color?: string | null,
  textColor?: string | null,
  loyaltyPeriodInDays?: number | null,
  logo?: string | null,
  listLogo?: string | null,
  cardLogo?: string | null,
  tiersEnabled?: boolean | null,
  type?: LoyaltySystemType | null,
  businessShortName?: string | null,
  loyaltyPointCurrencyValue?: number | null,
  classes?: string | null,
  securityCode?: number | null,
  disabledAutoTierCardColours?: boolean | null,
  useMainLogo?: boolean | null,
  textPosition?: string | null,
  customCardDesignUrl?: string | null,
  text?: string | null,
};

export type LoyaltyTier = {
  __typename: "LoyaltyTier",
  id: string,
  title?: string | null,
  desc?: string | null,
  pointsRequired?: number | null,
  color?: string | null,
  benefits?: Array< string | null > | null,
  minSpend?: number | null,
  discount?: number | null,
  customerTypeId?: number | null,
  birthdayGift?: string | null,
  logo?: string | null,
  disabledAutoTierCardColours?: boolean | null,
  loyaltyPointsBonusPercentage?: number | null,
  images?: Array< string | null > | null,
  icon?: string | null,
  isPrivate?: boolean | null,
};

export enum LoyaltySystemType {
  DEFAULT = "DEFAULT",
  CHECKIN = "CHECKIN",
  RECURRING_REWARD = "RECURRING_REWARD",
}


export type MenuConfig = {
  __typename: "MenuConfig",
  integratePOS?: boolean | null,
};

export type Country = {
  __typename: "Country",
  currencies?:  Array<Currency | null > | null,
  flag?: string | null,
  name?: string | null,
  alpha2Code?: string | null,
  alpha3Code?: string | null,
  callingCodes?: Array< string | null > | null,
  timezones?: Array< string | null > | null,
  region?: string | null,
};

export type StripeCustomerSubscription = {
  __typename: "StripeCustomerSubscription",
  id?: string | null,
  current_period_end?: number | null,
  current_period_start?: number | null,
  quantity?: number | null,
  entitiesIncluded?: Array< string | null > | null,
  items?:  Array<StripeCustomerSubscriptionItem | null > | null,
  latest_invoice?: string | null,
  status?: string | null,
  created?: number | null,
};

export type StripeCustomerSubscriptionItem = {
  __typename: "StripeCustomerSubscriptionItem",
  id?: string | null,
  priceId?: string | null,
  quantity?: number | null,
};

export type FidelConfig = {
  __typename: "FidelConfig",
  merchantId?: string | null,
  brandId?: string | null,
  status?: string | null,
  brandUserId?: string | null,
  locationId?: string | null,
  contractSigned?: string | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
};

export type Event = {
  __typename: "Event",
  id: string,
  title: string,
  entityId: string,
  logo?: string | null,
  headerVideos?: Array< string | null > | null,
  publisherId: string,
  area?: string | null,
  sponsors?:  Array<Sponsor | null > | null,
  subtitle?: string | null,
  description?: string | null,
  type?: string | null,
  score?: number | null,
  address?: Address | null,
  categories?: Array< string | null > | null,
  dateTime?: number | null,
  startDate?: string | null,
  pageVideos?:  Array<VideoData | null > | null,
  cardVideos?:  Array<VideoData | null > | null,
  videoData?:  Array<VideoData | null > | null,
  endDate?: string | null,
  closingTime?: number | null,
  rules?: string | null,
  discount?: number | null,
  buyTicketUrl?: string | null,
  canBook?: boolean | null,
  isOnlineEvent?: boolean | null,
  featured?: string | null,
  video?: string | null,
  images?: Array< string | null > | null,
  eventFeatures?:  Array<EntityFeature | null > | null,
  artistsList?: Array< string | null > | null,
  mapImages?: Array< string | null > | null,
  location?: Location | null,
  price?: string | null,
  city: string,
  features?:  Array<Feature | null > | null,
  attending?: number | null,
  extraInfo?:  Array<AccordionData | null > | null,
  promoterIds?: Array< string | null > | null,
  ttl?: number | null,
  views?: number | null,
  whatsappNumber?: string | null,
  phone?: string | null,
  status?: string | null,
  owner?: string | null,
  owners?: Array< string | null > | null,
  pushTimestamps?: Array< number | null > | null,
  ownerType?: string | null,
  tickets?: ModelTicketConnection | null,
  timelines?:  Array<Timeline | null > | null,
  checklist?: Checklist | null,
  tablesAvailable?: number | null,
  menuUrlList?:  Array<UrlType | null > | null,
  menuPDFList?:  Array<UrlType | null > | null,
  menuImages?: Array< string | null > | null,
  influencerInfo?: EventInfluencerInfo | null,
  username?: string | null,
  menu?: Menu | null,
  config?: EventConfig | null,
  createdAt: number,
  updatedAt: number,
  blogPosts?: ModelBlogPostEventConnection | null,
  attendees?: ModelUserEventConnection | null,
  entity?: Entity | null,
  publisher?: Publisher | null,
  tables?: ModelVenueTableConnection | null,
  variations?: ModelTicketVariationOccurenceConnection | null,
};

export type ModelTicketConnection = {
  __typename: "ModelTicketConnection",
  items:  Array<Ticket | null >,
  nextToken?: string | null,
};

export type Ticket = {
  __typename: "Ticket",
  id: string,
  userId: string,
  bookingReference: string,
  eventId: string,
  repeatEventId: string,
  repeatEventOccurencId: string,
  eventType?: string | null,
  eventStartTimestamp?: number | null,
  price?: TicketPrice | null,
  chargeId?: string | null,
  paymentIntentId?: string | null,
  content?: string | null,
  ticketActivasted?: string | null,
  userName?: string | null,
  additionalOptions?:  Array<TicketChosenOption | null > | null,
  tier?: string | null,
  variation?: string | null,
  owners?: Array< string | null > | null,
  status?: string | null,
  ttl?: number | null,
  test?: string | null,
  refunded?: string | null,
  createdAt: string,
  updatedAt: string,
  event?: Event | null,
  repeatEvent?: RepeatEvent | null,
  repeatEventOccurence?: RepeatEventOccurence | null,
  user?: User | null,
};

export type TicketPrice = {
  __typename: "TicketPrice",
  price?: number | null,
  currency?: Currency | null,
};

export type TicketChosenOption = {
  __typename: "TicketChosenOption",
  title?: string | null,
  chosenOption?: string | null,
};

export type RepeatEvent = {
  __typename: "RepeatEvent",
  id: string,
  title: string,
  entityId: string,
  area?: string | null,
  headerVideos?: Array< string | null > | null,
  publisherId: string,
  sponsors?:  Array<Sponsor | null > | null,
  logo?: string | null,
  subtitle?: string | null,
  description?: string | null,
  type?: string | null,
  pageVideos?:  Array<VideoData | null > | null,
  cardVideos?:  Array<VideoData | null > | null,
  videoData?:  Array<VideoData | null > | null,
  discount?: number | null,
  isOnlineEvent?: boolean | null,
  score?: number | null,
  address?: Address | null,
  eventFeatures?:  Array<EntityFeature | null > | null,
  categories?: Array< string | null > | null,
  closingTime?: number | null,
  dayOfWeek?: number | null,
  daysOfWeek?: Array< number | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  showWeather?: boolean | null,
  buyTicketUrl?: string | null,
  canBook?: boolean | null,
  ttl?: number | null,
  whatsappNumber?: string | null,
  phone?: string | null,
  mapImages?: Array< string | null > | null,
  video?: string | null,
  images?: Array< string | null > | null,
  artistsList?: Array< string | null > | null,
  featured?: string | null,
  features?:  Array<Feature | null > | null,
  firstEventDate?: string | null,
  lastEventDate?: string | null,
  location?: Location | null,
  price?: string | null,
  rules?: string | null,
  city?: string | null,
  attending?: number | null,
  status: string,
  ownerType?: string | null,
  owner?: string | null,
  pushTimestamps?: Array< number | null > | null,
  promoterIds?: Array< string | null > | null,
  extraInfo?:  Array<AccordionData | null > | null,
  owners?: Array< string | null > | null,
  tickets?: ModelTicketConnection | null,
  occurencesRules?:  Array<OccurencesRules | null > | null,
  timelines?:  Array<Timeline | null > | null,
  checklist?: Checklist | null,
  menuUrlList?:  Array<UrlType | null > | null,
  menuPDFList?:  Array<UrlType | null > | null,
  menuImages?: Array< string | null > | null,
  menu?: Menu | null,
  username?: string | null,
  influencerInfo?: EventInfluencerInfo | null,
  config?: EventConfig | null,
  createdAt: number,
  updatedAt: number,
  blogPosts?: ModelBlogPostRepeatEventConnection | null,
  occurences?: ModelRepeatEventOccurenceConnection | null,
  attendees?: ModelUserEventConnection | null,
  entity?: Entity | null,
  publisher?: Publisher | null,
  tables?: ModelVenueTableConnection | null,
};

export type OccurencesRules = {
  __typename: "OccurencesRules",
  title?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  typeOfRepeat?: string | null,
  repeatInterval?: number | null,
  daysOfWeek?: Array< number | null > | null,
  daysOfMonth?: Array< number | null > | null,
  customDates?:  Array<EventDates | null > | null,
  weeksOfMonth?: Array< number | null > | null,
  weeksDaysOfMonth?: Array< number | null > | null,
  occurenceTotalRepeat?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  usesEntityTables?: boolean | null,
  price?: TicketPrice | null,
  quantity?: number | null,
};

export type EventDates = {
  __typename: "EventDates",
  startDate?: string | null,
  endDate?: string | null,
};

export type Timeline = {
  __typename: "Timeline",
  id: string,
  title?: string | null,
  description?: string | null,
  hideTimes?: boolean | null,
  timeslots?:  Array<TimelineItem | null > | null,
};

export type TimelineItem = {
  __typename: "TimelineItem",
  id?: string | null,
  title?: string | null,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  artistId?: string | null,
  genres?: Array< string | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  image?: string | null,
  location?: Location | null,
};

export type Checklist = {
  __typename: "Checklist",
  title: string,
  listItems?: Array< string | null > | null,
};

export type EventInfluencerInfo = {
  __typename: "EventInfluencerInfo",
  hashtags?: Array< string | null > | null,
  tags?: Array< string | null > | null,
  details?: string | null,
};

export type EventConfig = {
  __typename: "EventConfig",
  canBuyTickets?: boolean | null,
  ticketConfig?: TicketConfig | null,
};

export type TicketConfig = {
  __typename: "TicketConfig",
  title?: string | null,
  price?: TicketPrice | null,
  additionalOptions?:  Array<TicketAdditionalOptions | null > | null,
  quantity?: number | null,
  amountSold?: number | null,
  maxPerUser?: number | null,
  images?: Array< string | null > | null,
  tiers?:  Array<TicketTier | null > | null,
  variations?:  Array<TicketVariation | null > | null,
  variationSoldCount?: number | null,
  disclaimer?: string | null,
  customEmailMessage?: string | null,
  description?: string | null,
  dateAvailableFrom?: string | null,
  dateAvailableTill?: string | null,
};

export type TicketAdditionalOptions = {
  __typename: "TicketAdditionalOptions",
  id?: string | null,
  title?: string | null,
  description?: string | null,
  images?: Array< string | null > | null,
  options?:  Array<AdditionalOption | null > | null,
  required?: boolean | null,
};

export type AdditionalOption = {
  __typename: "AdditionalOption",
  id?: string | null,
  title?: string | null,
  description?: string | null,
  images?: Array< string | null > | null,
};

export type TicketTier = {
  __typename: "TicketTier",
  title?: string | null,
  startDate?: string | null,
  endDate?: string | null,
};

export type TicketVariation = {
  __typename: "TicketVariation",
  id?: string | null,
  title?: string | null,
  description?: string | null,
  images?: Array< string | null > | null,
  amountSold?: number | null,
  quantity?: number | null,
  price?: number | null,
  additionalOptions?:  Array<TicketAdditionalOptions | null > | null,
  status?: string | null,
};

export type ModelBlogPostRepeatEventConnection = {
  __typename: "ModelBlogPostRepeatEventConnection",
  items:  Array<BlogPostRepeatEvent | null >,
  nextToken?: string | null,
};

export type BlogPostRepeatEvent = {
  __typename: "BlogPostRepeatEvent",
  id: string,
  repeatEventId: string,
  blogPostId: string,
  summary?: string | null,
  title?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
  blogPost: BlogPost,
  owner?: string | null,
  repeatEvent: RepeatEvent,
};

export type ModelRepeatEventOccurenceConnection = {
  __typename: "ModelRepeatEventOccurenceConnection",
  items:  Array<RepeatEventOccurence | null >,
  nextToken?: string | null,
};

export type RepeatEventOccurence = {
  __typename: "RepeatEventOccurence",
  id: string,
  repeatEventId: string,
  startDate?: string | null,
  endDate?: string | null,
  owners?: Array< string | null > | null,
  isSoldOut?: boolean | null,
  totalTicketsSold?: number | null,
  canBuyTickets?: boolean | null,
  tickets?: ModelTicketConnection | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
  repeatEvent?: RepeatEvent | null,
  attendees?: ModelUserEventConnection | null,
  variations?: ModelTicketVariationOccurenceConnection | null,
};

export type ModelUserEventConnection = {
  __typename: "ModelUserEventConnection",
  items:  Array<UserEvent | null >,
  nextToken?: string | null,
};

export type UserEvent = {
  __typename: "UserEvent",
  id: string,
  eventDate: number,
  eventId: string,
  userName?: string | null,
  repeatEventId: string,
  repeatEventOccurenceId: string,
  type?: string | null,
  userId: string,
  owners?: Array< string | null > | null,
  status?: string | null,
  checkInDate?: string | null,
  documents?:  Array<UrlType | null > | null,
  tablesBooked?: Array< string | null > | null,
  subscriptionArn?: string | null,
  plusOnesRemaining?: number | null,
  authCode?: string | null,
  subscriptionArns?: Array< string | null > | null,
  pushSubscriptions?:  Array<PushSubscription | null > | null,
  subscriptionActive?: boolean | null,
  title: string,
  createdAt?: number | null,
  updatedAt?: number | null,
  event?: Event | null,
  repeatEvent?: RepeatEvent | null,
  user?: User | null,
};

export type PushSubscription = {
  __typename: "PushSubscription",
  endpointArn?: string | null,
  deviceEndpointArn?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  cognitoUsername?: string | null,
  role?: UserRole | null,
  appVersion?: string | null,
  activated?: string | null,
  blocked?: boolean | null,
  firstName?: string | null,
  emailSettings?: UserEmailSettings | null,
  lastName?: string | null,
  currency?: Currency | null,
  placesAddedToListCount?: number | null,
  bio?: string | null,
  preferredTitle?: string | null,
  country?: Country | null,
  dob?: string | null,
  linkCardRewardClaimed?: string | null,
  email?: string | null,
  stripeConnectId?: string | null,
  stripeCustomerId?: string | null,
  gender?: string | null,
  phone?: string | null,
  city?: string | null,
  allowNotifications?: boolean | null,
  allowLocation?: boolean | null,
  allowPersonalisation?: boolean | null,
  allowCalendar?: boolean | null,
  profilePicture?: string | null,
  subscibedToEmails?: boolean | null,
  pushTokens?:  Array<PushToken | null > | null,
  points?: number | null,
  accumulatedPoints?: number | null,
  isStudent?: boolean | null,
  lastLoggedIn?: string | null,
  locale?: string | null,
  isInfluencer?: string | null,
  summary?: string | null,
  categories?: Array< string | null > | null,
  social?: SocialItems | null,
  createdAt: number,
  updatedAt: number,
  termsSigned?: boolean | null,
  termsVersionSigned?: string | null,
  EPOSId?: number | null,
  appConfigs?: string | null,
  disabled?: boolean | null,
  username?: string | null,
  offers?: ModelClaimedOfferConnection | null,
  tickets?: ModelTicketConnection | null,
  conversations?: ModelConvoLinkConnection | null,
  linkedCards?:  Array<IUserCard | null > | null,
  checkIns?: ModelCheckInConnection | null,
  reviews?: ModelReviewConnection | null,
  userEvents?: ModelUserEventConnection | null,
  loyaltyCards?: ModelLoyaltyCardConnection | null,
  tableBookings?: ModelTableBookingRequestConnection | null,
  lists?: ModelUserWishListLinkConnection | null,
  subscriptions?: ModelSubscriberConnection | null,
};

export enum UserRole {
  USER = "USER",
  ADMIN = "ADMIN",
  ARTIST = "ARTIST",
  BUSINESS = "BUSINESS",
  PROMOTER = "PROMOTER",
  BLOGGER = "BLOGGER",
  GUEST = "GUEST",
}


export type UserEmailSettings = {
  __typename: "UserEmailSettings",
  transactions?: boolean | null,
  offers?: boolean | null,
  loyaltyCard?: boolean | null,
  eventInvites?: boolean | null,
  marketing?: boolean | null,
};

export type PushToken = {
  __typename: "PushToken",
  token?: string | null,
  device?: string | null,
  endpointArn?: string | null,
  parentApp?: string | null,
};

export type ModelClaimedOfferConnection = {
  __typename: "ModelClaimedOfferConnection",
  items:  Array<ClaimedOffer | null >,
  nextToken?: string | null,
};

export type ClaimedOffer = {
  __typename: "ClaimedOffer",
  id: string,
  userId: string,
  offerId: string,
  fidelOfferId?: string | null,
  activated?: string | null,
  pointsCosts?: number | null,
  entityId: string,
  metadata?: string | null,
  publisherId: string,
  type?: string | null,
  ttl?: number | null,
  owners?: Array< string | null > | null,
  status: string,
  createdAt: number,
  updatedAt: number,
  offer?: SpecialOffer | null,
  entity?: Entity | null,
  publisher?: Publisher | null,
  user?: User | null,
};

export type SpecialOffer = {
  __typename: "SpecialOffer",
  id: string,
  daysAvailable?: Array< number | null > | null,
  startTime?: string | null,
  price?: number | null,
  discount?: number | null,
  endTime?: string | null,
  endDate?: string | null,
  countryCode?: string | null,
  declineReason?: string | null,
  requiresBooking?: string | null,
  startDate?: string | null,
  maxTransactionAmount?: number | null,
  minTransactionAmount?: number | null,
  returnPeriod?: number | null,
  metaData?: IFidelMetaData | null,
  description?: string | null,
  totalLoyaltyPointsRequiredForAccess?: number | null,
  title: string,
  images?: Array< string | null > | null,
  weeks?: number | null,
  entityId: string,
  publisherId: string,
  location?: Location | null,
  fidelId?: string | null,
  spendRequired?: number | null,
  delayMinutes?: number | null,
  delayHours?: number | null,
  currency?: string | null,
  isStudentDiscount?: boolean | null,
  city?: string | null,
  quantity?: number | null,
  status: string,
  loyaltyPointsRequired?: number | null,
  loyaltyPointsValue?: number | null,
  visitsRequired?: number | null,
  redeemCount?: number | null,
  claimCount?: number | null,
  dateAvailable?: string | null,
  allowanceLimit?: number | null,
  refreshDaysPeriod?: string | null,
  type?: string | null,
  rewardType?: string | null,
  subscribersOnly?: boolean | null,
  owner?: string | null,
  disclaimer?: string | null,
  metadata?: IFidelMetaData | null,
  fidelOfferId?: string | null,
  fidelStartDate?: string | null,
  fidelEndDate?: string | null,
  fidelMinTransactionAmount?: number | null,
  fidelMaxTransactionAmount?: number | null,
  fidelReturnPeriod?: number | null,
  fidelDaysOfWeek?: Array< number | null > | null,
  createdAt: number,
  updatedAt: number,
  entity?: Entity | null,
  publisher?: Publisher | null,
};

export type IFidelMetaData = {
  __typename: "IFidelMetaData",
  userId?: string | null,
  publisherId?: string | null,
  brandId?: string | null,
  entityId?: string | null,
  title?: string | null,
  gender?: string | null,
  dob?: string | null,
  cognitoUsername?: string | null,
};

export type IUserCard = {
  __typename: "IUserCard",
  scheme?: string | null,
  accountId?: string | null,
  countryCode?: string | null,
  created?: string | null,
  expYear?: number | null,
  expDate?: string | null,
  live?: boolean | null,
  lastNumbers?: string | null,
  expMonth?: number | null,
  updated?: string | null,
  programId?: string | null,
  firstNumbers?: string | null,
  id?: string | null,
  type?: string | null,
};

export type ModelCheckInConnection = {
  __typename: "ModelCheckInConnection",
  items:  Array<CheckIn | null >,
  nextToken?: string | null,
};

export type CheckIn = {
  __typename: "CheckIn",
  id: string,
  userId: string,
  entityId: string,
  fullName?: string | null,
  phone?: string | null,
  partySize?: number | null,
  gender?: string | null,
  dob?: string | null,
  owners?: Array< string | null > | null,
  age?: number | null,
  email?: string | null,
  ttl?: number | null,
  status?: string | null,
  location?: Location | null,
  updatedAt?: number | null,
  createdAt: number,
  user?: User | null,
};

export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
};

export type Review = {
  __typename: "Review",
  id: string,
  review?: string | null,
  score?: number | null,
  feedback?: string | null,
  scores?: ReviewScores | null,
  dateOfVisit?: number | null,
  userId: string,
  entityId: string,
  publisherId: string,
  status?: string | null,
  verified?: boolean | null,
  createdAt: number,
  updatedAt: number,
  entity?: Entity | null,
  publisher?: Publisher | null,
  user?: User | null,
};

export type ReviewScores = {
  __typename: "ReviewScores",
  food?: number | null,
  vibe?: number | null,
  service?: number | null,
  value?: number | null,
  overall?: number | null,
};

export type ModelLoyaltyCardConnection = {
  __typename: "ModelLoyaltyCardConnection",
  items:  Array<LoyaltyCard | null >,
  nextToken?: string | null,
};

export type LoyaltyCard = {
  __typename: "LoyaltyCard",
  id: string,
  userId: string,
  publisherId: string,
  businessTitle?: string | null,
  currentTier?: string | null,
  entityId?: string | null,
  visits?: number | null,
  businessAssignedTier?: string | null,
  transactions?: number | null,
  totalLoyaltyPointsRequiredForAccess?: number | null,
  subscriptionArns?: Array< string | null > | null,
  pushSubscriptions?:  Array<PushSubscription | null > | null,
  notificationsEnabled?: boolean | null,
  totalSpend?: number | null,
  pendingPoints?: number | null,
  totalPoints?: number | null,
  ownerType?: string | null,
  status?: string | null,
  userFullName?: string | null,
  owner?: string | null,
  owners?: Array< string | null > | null,
  totalSpendablePoints?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  publisher?: Publisher | null,
  user?: User | null,
};

export type ModelTableBookingRequestConnection = {
  __typename: "ModelTableBookingRequestConnection",
  items:  Array<TableBookingRequest | null >,
  nextToken?: string | null,
};

export type TableBookingRequest = {
  __typename: "TableBookingRequest",
  id: string,
  userName?: string | null,
  userId: string,
  paid?: boolean | null,
  amountPaid?: number | null,
  eventId: string,
  status?: string | null,
  owners?: Array< string | null > | null,
  tableId: string,
  message?: string | null,
  createdAt: number,
  updatedAt: number,
  table?: VenueTable | null,
  user?: User | null,
};

export type VenueTable = {
  __typename: "VenueTable",
  id: string,
  capacity?: number | null,
  quantityAvailable?: number | null,
  tablesBooked?: number | null,
  price?: number | null,
  includes?:  Array<TableIncludes | null > | null,
  disclaimer?: string | null,
  instructions?: string | null,
  parentType?: TableParentType | null,
  entityId: string,
  eventId: string,
  repeatEventId: string,
  status?: string | null,
  title?: string | null,
  owner: string,
  createdAt: number,
  updatedAt: number,
  event?: Event | null,
  repeatEvent?: RepeatEvent | null,
  entity?: Entity | null,
};

export type TableIncludes = {
  __typename: "TableIncludes",
  quantity?: number | null,
  title?: string | null,
  description?: string | null,
};

export enum TableParentType {
  ENTITY = "ENTITY",
  EVENT = "EVENT",
}


export type ModelUserWishListLinkConnection = {
  __typename: "ModelUserWishListLinkConnection",
  items:  Array<UserWishListLink | null >,
  nextToken?: string | null,
};

export type UserWishListLink = {
  __typename: "UserWishListLink",
  id: string,
  listId: string,
  userId: string,
  remminderSet?: boolean | null,
  createdAt: string,
  updatedAt: string,
  list: PlaceCollection,
  owner?: string | null,
  user: User,
};

export type PlaceCollection = {
  __typename: "PlaceCollection",
  id: string,
  userId: string,
  title?: string | null,
  description?: string | null,
  area?: string | null,
  saved?: number | null,
  shared?: number | null,
  likes?: number | null,
  venueCount?: number | null,
  headerImg?: string | null,
  videos?:  Array<VideoData | null > | null,
  views?: number | null,
  lockCriteria?: string | null,
  score?: number | null,
  areas?: Array< string | null > | null,
  location?: Location | null,
  username?: string | null,
  city?: string | null,
  cities?: Array< string | null > | null,
  status?: string | null,
  isPublic?: string | null,
  dateTime?: string | null,
  securityCode?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  users?: ModelUserWishListLinkConnection | null,
  collectionLink?: ModelPlaceCollectionEntityConnection | null,
};

export type ModelPlaceCollectionEntityConnection = {
  __typename: "ModelPlaceCollectionEntityConnection",
  items:  Array<PlaceCollectionEntity | null >,
  nextToken?: string | null,
};

export type PlaceCollectionEntity = {
  __typename: "PlaceCollectionEntity",
  id: string,
  entityId: string,
  listId: string,
  usersVisited?: Array< string | null > | null,
  owners?: Array< string | null > | null,
  orderIndex?: number | null,
  images?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  entity: Entity,
  list: PlaceCollection,
  owner?: string | null,
};

export type ModelSubscriberConnection = {
  __typename: "ModelSubscriberConnection",
  items:  Array<Subscriber | null >,
  nextToken?: string | null,
};

export type Subscriber = {
  __typename: "Subscriber",
  id: string,
  userId: string,
  entityId: string,
  publisherId: string,
  userFullName?: string | null,
  counters?: FollowerCounters | null,
  subscriptionArn?: string | null,
  subscriptionArns?: Array< string | null > | null,
  notificationsEnabled?: boolean | null,
  credit?: number | null,
  loyaltyPoints?: number | null,
  loyaltyStatus?: string | null,
  EPOSId?: string | null,
  type?: string | null,
  status: string,
  createdAt: number,
  updatedAt: number,
  entity?: Entity | null,
  publisher?: Publisher | null,
  user?: User | null,
};

export type FollowerCounters = {
  __typename: "FollowerCounters",
  bookings?: number | null,
  offersRedeemed?: number | null,
  checkins?: number | null,
};

export type ModelTicketVariationOccurenceConnection = {
  __typename: "ModelTicketVariationOccurenceConnection",
  items:  Array<TicketVariationOccurence | null >,
  nextToken?: string | null,
};

export type TicketVariationOccurence = {
  __typename: "TicketVariationOccurence",
  id: string,
  eventId?: string | null,
  repeatEventId?: string | null,
  repeatEventOccurenceId?: string | null,
  eventType?: string | null,
  variationRuleId: string,
  isSoldOut?: boolean | null,
  amountSoldCounter?: number | null,
  owners?: Array< string | null > | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelVenueTableConnection = {
  __typename: "ModelVenueTableConnection",
  items:  Array<VenueTable | null >,
  nextToken?: string | null,
};

export type ModelBlogPostEventConnection = {
  __typename: "ModelBlogPostEventConnection",
  items:  Array<BlogPostEvent | null >,
  nextToken?: string | null,
};

export type BlogPostEvent = {
  __typename: "BlogPostEvent",
  id: string,
  eventId: string,
  blogPostId: string,
  summary?: string | null,
  title?: string | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
  blogPost: BlogPost,
  owner?: string | null,
  event: Event,
};

export type ModelRepeatEventConnection = {
  __typename: "ModelRepeatEventConnection",
  items:  Array<RepeatEvent | null >,
  nextToken?: string | null,
};

export type ModelSpecialOfferConnection = {
  __typename: "ModelSpecialOfferConnection",
  items:  Array<SpecialOffer | null >,
  nextToken?: string | null,
};

export type ModelEntityConnection = {
  __typename: "ModelEntityConnection",
  items:  Array<Entity | null >,
  nextToken?: string | null,
};

export type VenueSafetyData = {
  __typename: "VenueSafetyData",
  list?: Array< string | null > | null,
  title?: string | null,
  description?: string | null,
  links?: Array< string | null > | null,
  updated?: string | null,
};

export type CovidMeasures = {
  __typename: "CovidMeasures",
  capacityPercentage?: number | null,
  tableLimit?: number | null,
  masksRequired?: boolean | null,
  bookingsRequired?: boolean | null,
  notes?: string | null,
};

export type MediaChannels = {
  __typename: "MediaChannels",
  youtubeUrl?: string | null,
  spotifyUrl?: string | null,
  deezerUrl?: string | null,
  mixcloudUrl?: string | null,
  amazonMusicUrl?: string | null,
  tidalUrl?: string | null,
  soundcloudUrl?: string | null,
  appleMusicUrl?: string | null,
};

export type PingReview = {
  __typename: "PingReview",
  score?: number | null,
  title?: string | null,
  review?: string | null,
  createdAt?: string | null,
  reviewer?: string | null,
  reviewerPhoto?: string | null,
};

export type PingAward = {
  __typename: "PingAward",
  date?: string | null,
  type?: string | null,
};

export type ModelBookableTableConnection = {
  __typename: "ModelBookableTableConnection",
  items:  Array<BookableTable | null >,
  nextToken?: string | null,
};

export type BookableTable = {
  __typename: "BookableTable",
  id: string,
  tableNumber?: number | null,
  capacity?: number | null,
  area?: string | null,
  entityId: string,
  ttl?: number | null,
  maxPeople?: number | null,
  minPeople?: number | null,
  availability?:  Array<TableAvailability | null > | null,
  createdAt?: number | null,
  updatedAt?: number | null,
  bookings?: ModelBookingsConnection | null,
};

export type TableAvailability = {
  __typename: "TableAvailability",
  startTime?: string | null,
  endTime?: string | null,
};

export type ModelBookingsConnection = {
  __typename: "ModelBookingsConnection",
  items:  Array<Bookings | null >,
  nextToken?: string | null,
};

export type Bookings = {
  __typename: "Bookings",
  id: string,
  userName: string,
  userId: string,
  publisher?: string | null,
  bookingType?: string | null,
  entityId: string,
  venueNotes?: string | null,
  durationInMinutes?: number | null,
  entityTitle?: string | null,
  userEmail?: string | null,
  userPhone?: string | null,
  confirmed?: boolean | null,
  owners: Array< string | null >,
  owner?: string | null,
  dateTime: string,
  tableId: string,
  timeString?: string | null,
  provider?: BookingSystemProvider | null,
  dateString?: string | null,
  dateTimestamp: number,
  people: number,
  artistId?: string | null,
  notes?: string | null,
  status: string,
  ttl?: number | null,
  paymentStatus?: string | null,
  artistBookingNotes?: ArtistBookingNotes | null,
  createdAt: number,
  updatedAt: number,
  entity?: Entity | null,
  user?: User | null,
};

export type ArtistBookingNotes = {
  __typename: "ArtistBookingNotes",
  isEquiptmentProvided?: boolean | null,
  isBirthday?: boolean | null,
  isBrandEvent?: boolean | null,
  BrandDetails?: string | null,
  genresRequired?: string | null,
  equiptmentRequired?: string | null,
};

export type ModelDailyPromotionsConnection = {
  __typename: "ModelDailyPromotionsConnection",
  items:  Array<DailyPromotions | null >,
  nextToken?: string | null,
};

export type DailyPromotions = {
  __typename: "DailyPromotions",
  id: string,
  entityId: string,
  daysAvailable?: Array< number | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  description?: string | null,
  price?: number | null,
  images?: Array< string | null > | null,
  title?: string | null,
  status?: string | null,
  disclaimer?: string | null,
  owner?: string | null,
  subscribersOnly?: boolean | null,
  createdAt: number,
  updatedAt: number,
};

export type UpdateBlogPostInput = {
  id: string,
  postType?: string | null,
  title?: string | null,
  desc?: string | null,
  city?: string | null,
  area?: string | null,
  catgories?: Array< string | null > | null,
  headerImg?: string | null,
  readCount?: number | null,
  shareCount?: number | null,
  metaDesc?: string | null,
  metaTitle?: string | null,
  videoUrl?: string | null,
  instagram?: string | null,
  twitter?: string | null,
  website?: string | null,
  facebook?: string | null,
  userId?: string | null,
  publishStatus?: string | null,
  owners?: Array< string | null > | null,
  ttl?: number | null,
  content?: Array< BlogSectionContentInput | null > | null,
  summary?: Array< BlogSummaryInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteBlogPostInput = {
  id: string,
};

export type CreateBlogPostEntityInput = {
  id?: string | null,
  entityId: string,
  blogPostId: string,
  summary?: string | null,
  title?: string | null,
  order?: number | null,
};

export type ModelBlogPostEntityConditionInput = {
  entityId?: ModelIDInput | null,
  blogPostId?: ModelIDInput | null,
  summary?: ModelStringInput | null,
  title?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBlogPostEntityConditionInput | null > | null,
  or?: Array< ModelBlogPostEntityConditionInput | null > | null,
  not?: ModelBlogPostEntityConditionInput | null,
};

export type UpdateBlogPostEntityInput = {
  id: string,
  entityId?: string | null,
  blogPostId?: string | null,
  summary?: string | null,
  title?: string | null,
  order?: number | null,
};

export type DeleteBlogPostEntityInput = {
  id: string,
};

export type CreateBlogPostEventInput = {
  id?: string | null,
  eventId: string,
  blogPostId: string,
  summary?: string | null,
  title?: string | null,
  order?: number | null,
};

export type ModelBlogPostEventConditionInput = {
  eventId?: ModelIDInput | null,
  blogPostId?: ModelIDInput | null,
  summary?: ModelStringInput | null,
  title?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBlogPostEventConditionInput | null > | null,
  or?: Array< ModelBlogPostEventConditionInput | null > | null,
  not?: ModelBlogPostEventConditionInput | null,
};

export type UpdateBlogPostEventInput = {
  id: string,
  eventId?: string | null,
  blogPostId?: string | null,
  summary?: string | null,
  title?: string | null,
  order?: number | null,
};

export type DeleteBlogPostEventInput = {
  id: string,
};

export type CreateBlogPostRepeatEventInput = {
  id?: string | null,
  repeatEventId: string,
  blogPostId: string,
  summary?: string | null,
  title?: string | null,
  order?: number | null,
};

export type ModelBlogPostRepeatEventConditionInput = {
  repeatEventId?: ModelIDInput | null,
  blogPostId?: ModelIDInput | null,
  summary?: ModelStringInput | null,
  title?: ModelStringInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelBlogPostRepeatEventConditionInput | null > | null,
  or?: Array< ModelBlogPostRepeatEventConditionInput | null > | null,
  not?: ModelBlogPostRepeatEventConditionInput | null,
};

export type UpdateBlogPostRepeatEventInput = {
  id: string,
  repeatEventId?: string | null,
  blogPostId?: string | null,
  summary?: string | null,
  title?: string | null,
  order?: number | null,
};

export type DeleteBlogPostRepeatEventInput = {
  id: string,
};

export type CreateBookableTableInput = {
  id?: string | null,
  tableNumber?: number | null,
  capacity?: number | null,
  area?: string | null,
  entityId: string,
  ttl?: number | null,
  maxPeople?: number | null,
  minPeople?: number | null,
  availability?: Array< TableAvailabilityInput | null > | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type TableAvailabilityInput = {
  startTime?: string | null,
  endTime?: string | null,
};

export type ModelBookableTableConditionInput = {
  tableNumber?: ModelIntInput | null,
  capacity?: ModelIntInput | null,
  area?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  maxPeople?: ModelIntInput | null,
  minPeople?: ModelIntInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelBookableTableConditionInput | null > | null,
  or?: Array< ModelBookableTableConditionInput | null > | null,
  not?: ModelBookableTableConditionInput | null,
};

export type UpdateBookableTableInput = {
  id: string,
  tableNumber?: number | null,
  capacity?: number | null,
  area?: string | null,
  entityId?: string | null,
  ttl?: number | null,
  maxPeople?: number | null,
  minPeople?: number | null,
  availability?: Array< TableAvailabilityInput | null > | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteBookableTableInput = {
  id: string,
};

export type CreateConvoLinkInput = {
  id?: string | null,
  status?: string | null,
  createdAt: number,
  updatedAt: number,
  owners: Array< string | null >,
  publisherId: string,
  entityId: string,
  userId: string,
};

export type ModelConvoLinkConditionInput = {
  status?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  publisherId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelConvoLinkConditionInput | null > | null,
  or?: Array< ModelConvoLinkConditionInput | null > | null,
  not?: ModelConvoLinkConditionInput | null,
};

export type UpdateConvoLinkInput = {
  id: string,
  status?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
  owners?: Array< string | null > | null,
  publisherId?: string | null,
  entityId?: string | null,
  userId?: string | null,
};

export type DeleteConvoLinkInput = {
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  messageConversationId: string,
  content: string,
  contentType?: string | null,
  author: string,
  entity: string,
  receiver: string,
  owners: Array< string | null >,
  status?: string | null,
  createdAt: number,
  updatedAt: number,
};

export type ModelMessageConditionInput = {
  messageConversationId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  contentType?: ModelStringInput | null,
  author?: ModelStringInput | null,
  entity?: ModelStringInput | null,
  receiver?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type UpdateMessageInput = {
  id: string,
  messageConversationId?: string | null,
  content?: string | null,
  contentType?: string | null,
  author?: string | null,
  entity?: string | null,
  receiver?: string | null,
  owners?: Array< string | null > | null,
  status?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type UpdateCheckInInput = {
  id: string,
  userId?: string | null,
  entityId?: string | null,
  fullName?: string | null,
  phone?: string | null,
  partySize?: number | null,
  gender?: string | null,
  dob?: string | null,
  owners?: Array< string | null > | null,
  age?: number | null,
  email?: string | null,
  ttl?: number | null,
  status?: string | null,
  location?: LocationInput | null,
  updatedAt?: number | null,
  createdAt?: number | null,
};

export type ModelCheckInConditionInput = {
  userId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  fullName?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  partySize?: ModelIntInput | null,
  gender?: ModelStringInput | null,
  dob?: ModelStringInput | null,
  age?: ModelIntInput | null,
  email?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  updatedAt?: ModelFloatInput | null,
  createdAt?: ModelFloatInput | null,
  and?: Array< ModelCheckInConditionInput | null > | null,
  or?: Array< ModelCheckInConditionInput | null > | null,
  not?: ModelCheckInConditionInput | null,
};

export type DeleteCheckInInput = {
  id: string,
};

export type CreateCityInput = {
  id?: string | null,
  title: string,
  venueCount?: number | null,
  areas?: Array< AreaInput | null > | null,
  description?: string | null,
  country: string,
  continent?: string | null,
  location?: LocationInput | null,
  timezoneOffset?: number | null,
  currency?: string | null,
  images?: Array< string | null > | null,
  createdAt: number,
  updatedAt: number,
  config?: CityConfigInput | null,
};

export type AreaInput = {
  title?: string | null,
  location?: LocationInput | null,
  parentArea?: string | null,
  images?: Array< string | null > | null,
};

export type CityConfigInput = {
  events?: boolean | null,
  places?: boolean | null,
};

export type ModelCityConditionInput = {
  title?: ModelStringInput | null,
  venueCount?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  country?: ModelStringInput | null,
  continent?: ModelStringInput | null,
  timezoneOffset?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  images?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelCityConditionInput | null > | null,
  or?: Array< ModelCityConditionInput | null > | null,
  not?: ModelCityConditionInput | null,
};

export type City = {
  __typename: "City",
  id: string,
  title: string,
  venueCount?: number | null,
  areas?:  Array<Area | null > | null,
  description?: string | null,
  country: string,
  continent?: string | null,
  location?: Location | null,
  timezoneOffset?: number | null,
  currency?: string | null,
  images?: Array< string | null > | null,
  createdAt: number,
  updatedAt: number,
  config?: CityConfig | null,
};

export type Area = {
  __typename: "Area",
  title?: string | null,
  location?: Location | null,
  parentArea?: string | null,
  images?: Array< string | null > | null,
};

export type CityConfig = {
  __typename: "CityConfig",
  events?: boolean | null,
  places?: boolean | null,
};

export type UpdateCityInput = {
  id: string,
  title?: string | null,
  venueCount?: number | null,
  areas?: Array< AreaInput | null > | null,
  description?: string | null,
  country?: string | null,
  continent?: string | null,
  location?: LocationInput | null,
  timezoneOffset?: number | null,
  currency?: string | null,
  images?: Array< string | null > | null,
  createdAt?: number | null,
  updatedAt?: number | null,
  config?: CityConfigInput | null,
};

export type DeleteCityInput = {
  id: string,
};

export type CreateCouponInput = {
  id?: string | null,
  code: string,
  discountType?: string | null,
  discountAmount?: number | null,
  status?: string | null,
  publisherId: string,
  eventType?: string | null,
  eventId: string,
  repeatEventId: string,
  repeatEventOccurencId: string,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCouponConditionInput = {
  code?: ModelIDInput | null,
  discountType?: ModelStringInput | null,
  discountAmount?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  eventType?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  repeatEventOccurencId?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCouponConditionInput | null > | null,
  or?: Array< ModelCouponConditionInput | null > | null,
  not?: ModelCouponConditionInput | null,
};

export type Coupon = {
  __typename: "Coupon",
  id: string,
  code: string,
  discountType?: string | null,
  discountAmount?: number | null,
  status?: string | null,
  publisherId: string,
  eventType?: string | null,
  eventId: string,
  repeatEventId: string,
  repeatEventOccurencId: string,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateCouponInput = {
  id: string,
  code?: string | null,
  discountType?: string | null,
  discountAmount?: number | null,
  status?: string | null,
  publisherId?: string | null,
  eventType?: string | null,
  eventId?: string | null,
  repeatEventId?: string | null,
  repeatEventOccurencId?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCouponInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  title: string,
  entityId: string,
  logo?: string | null,
  headerVideos?: Array< string | null > | null,
  publisherId: string,
  area?: string | null,
  sponsors?: Array< SponsorInput | null > | null,
  subtitle?: string | null,
  description?: string | null,
  type?: string | null,
  score?: number | null,
  address?: AddressInput | null,
  categories?: Array< string | null > | null,
  dateTime?: number | null,
  startDate?: string | null,
  pageVideos?: Array< VideoDataInput | null > | null,
  cardVideos?: Array< VideoDataInput | null > | null,
  videoData?: Array< VideoDataInput | null > | null,
  endDate?: string | null,
  closingTime?: number | null,
  rules?: string | null,
  discount?: number | null,
  buyTicketUrl?: string | null,
  canBook?: boolean | null,
  isOnlineEvent?: boolean | null,
  featured?: string | null,
  video?: string | null,
  images?: Array< string | null > | null,
  eventFeatures?: Array< EntityFeatureInput | null > | null,
  artistsList?: Array< string | null > | null,
  mapImages?: Array< string | null > | null,
  location?: LocationInput | null,
  price?: string | null,
  city: string,
  features?: Array< FeatureInput | null > | null,
  attending?: number | null,
  extraInfo?: Array< AccordionDataInput | null > | null,
  promoterIds?: Array< string | null > | null,
  ttl?: number | null,
  views?: number | null,
  whatsappNumber?: string | null,
  phone?: string | null,
  status?: string | null,
  owner?: string | null,
  owners?: Array< string | null > | null,
  pushTimestamps?: Array< number | null > | null,
  ownerType?: string | null,
  timelines?: Array< TimelineInput | null > | null,
  checklist?: ChecklistInput | null,
  tablesAvailable?: number | null,
  menuUrlList?: Array< UrlTypeInput | null > | null,
  menuPDFList?: Array< UrlTypeInput | null > | null,
  menuImages?: Array< string | null > | null,
  influencerInfo?: EventInfluencerInfoInput | null,
  username?: string | null,
  menu?: MenuInput | null,
  config?: EventConfigInput | null,
  createdAt: number,
  updatedAt: number,
};

export type SponsorInput = {
  id?: string | null,
  title?: string | null,
  logo?: string | null,
  desc?: string | null,
};

export type VideoDataInput = {
  state?: string | null,
  key?: string | null,
  thumbnail?: string | null,
  duration?: number | null,
  updatedAt?: string | null,
  types?: Array< string | null > | null,
};

export type EntityFeatureInput = {
  images?: Array< string | null > | null,
  title?: string | null,
  description?: string | null,
  bookUrl?: string | null,
  status?: string | null,
};

export type FeatureInput = {
  title?: string | null,
  icon?: Array< string | null > | null,
};

export type AccordionDataInput = {
  title?: string | null,
  content?: string | null,
  url?: string | null,
};

export type TimelineInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  hideTimes?: boolean | null,
  timeslots?: Array< TimelineItemInput | null > | null,
};

export type TimelineItemInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  artistId?: string | null,
  genres?: Array< string | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  image?: string | null,
  location?: LocationInput | null,
};

export type ChecklistInput = {
  title: string,
  listItems?: Array< string | null > | null,
};

export type UrlTypeInput = {
  title?: string | null,
  type?: string | null,
  url?: string | null,
};

export type EventInfluencerInfoInput = {
  hashtags?: Array< string | null > | null,
  tags?: Array< string | null > | null,
  details?: string | null,
};

export type MenuInput = {
  id: string,
  sections?: Array< MenuSectionInput | null > | null,
  title?: string | null,
  status: string,
};

export type MenuSectionInput = {
  id: string,
  description?: string | null,
  title?: string | null,
  type?: string | null,
  icon?: Array< string | null > | null,
  items?: Array< MenuItemInput | null > | null,
  status?: string | null,
};

export type MenuItemInput = {
  id: string,
  title: string,
  description?: string | null,
  price?: number | null,
  image?: string | null,
  offerPrice?: number | null,
  type?: MenuItemType | null,
  status?: string | null,
};

export type EventConfigInput = {
  canBuyTickets?: boolean | null,
  ticketConfig?: TicketConfigInput | null,
};

export type TicketConfigInput = {
  title?: string | null,
  price?: TicketPriceInput | null,
  additionalOptions?: Array< TicketAdditionalOptionsInput | null > | null,
  quantity?: number | null,
  amountSold?: number | null,
  maxPerUser?: number | null,
  images?: Array< string | null > | null,
  tiers?: Array< TicketTierInput | null > | null,
  variations?: Array< TicketVariationInput | null > | null,
  variationSoldCount?: number | null,
  disclaimer?: string | null,
  customEmailMessage?: string | null,
  description?: string | null,
  dateAvailableFrom?: string | null,
  dateAvailableTill?: string | null,
};

export type TicketPriceInput = {
  price?: number | null,
  currency?: CurrencyInput | null,
};

export type TicketAdditionalOptionsInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  images?: Array< string | null > | null,
  options?: Array< AdditionalOptionInput | null > | null,
  required?: boolean | null,
};

export type AdditionalOptionInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  images?: Array< string | null > | null,
};

export type TicketTierInput = {
  title?: string | null,
  startDate?: string | null,
  endDate?: string | null,
};

export type TicketVariationInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  images?: Array< string | null > | null,
  amountSold?: number | null,
  quantity?: number | null,
  price?: number | null,
  additionalOptions?: Array< TicketAdditionalOptionsInput | null > | null,
  status?: string | null,
};

export type ModelEventConditionInput = {
  title?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  logo?: ModelStringInput | null,
  headerVideos?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  area?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  categories?: ModelStringInput | null,
  dateTime?: ModelFloatInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  closingTime?: ModelFloatInput | null,
  rules?: ModelStringInput | null,
  discount?: ModelIntInput | null,
  buyTicketUrl?: ModelStringInput | null,
  canBook?: ModelBooleanInput | null,
  isOnlineEvent?: ModelBooleanInput | null,
  featured?: ModelStringInput | null,
  video?: ModelStringInput | null,
  images?: ModelStringInput | null,
  artistsList?: ModelStringInput | null,
  mapImages?: ModelStringInput | null,
  price?: ModelStringInput | null,
  city?: ModelStringInput | null,
  attending?: ModelIntInput | null,
  promoterIds?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  views?: ModelIntInput | null,
  whatsappNumber?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  status?: ModelStringInput | null,
  pushTimestamps?: ModelFloatInput | null,
  ownerType?: ModelStringInput | null,
  tablesAvailable?: ModelIntInput | null,
  menuImages?: ModelStringInput | null,
  username?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateEventInput = {
  id: string,
  title?: string | null,
  entityId?: string | null,
  logo?: string | null,
  headerVideos?: Array< string | null > | null,
  publisherId?: string | null,
  area?: string | null,
  sponsors?: Array< SponsorInput | null > | null,
  subtitle?: string | null,
  description?: string | null,
  type?: string | null,
  score?: number | null,
  address?: AddressInput | null,
  categories?: Array< string | null > | null,
  dateTime?: number | null,
  startDate?: string | null,
  pageVideos?: Array< VideoDataInput | null > | null,
  cardVideos?: Array< VideoDataInput | null > | null,
  videoData?: Array< VideoDataInput | null > | null,
  endDate?: string | null,
  closingTime?: number | null,
  rules?: string | null,
  discount?: number | null,
  buyTicketUrl?: string | null,
  canBook?: boolean | null,
  isOnlineEvent?: boolean | null,
  featured?: string | null,
  video?: string | null,
  images?: Array< string | null > | null,
  eventFeatures?: Array< EntityFeatureInput | null > | null,
  artistsList?: Array< string | null > | null,
  mapImages?: Array< string | null > | null,
  location?: LocationInput | null,
  price?: string | null,
  city?: string | null,
  features?: Array< FeatureInput | null > | null,
  attending?: number | null,
  extraInfo?: Array< AccordionDataInput | null > | null,
  promoterIds?: Array< string | null > | null,
  ttl?: number | null,
  views?: number | null,
  whatsappNumber?: string | null,
  phone?: string | null,
  status?: string | null,
  owner?: string | null,
  owners?: Array< string | null > | null,
  pushTimestamps?: Array< number | null > | null,
  ownerType?: string | null,
  timelines?: Array< TimelineInput | null > | null,
  checklist?: ChecklistInput | null,
  tablesAvailable?: number | null,
  menuUrlList?: Array< UrlTypeInput | null > | null,
  menuPDFList?: Array< UrlTypeInput | null > | null,
  menuImages?: Array< string | null > | null,
  influencerInfo?: EventInfluencerInfoInput | null,
  username?: string | null,
  menu?: MenuInput | null,
  config?: EventConfigInput | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreateRepeatEventInput = {
  id?: string | null,
  title: string,
  entityId: string,
  area?: string | null,
  headerVideos?: Array< string | null > | null,
  publisherId: string,
  sponsors?: Array< SponsorInput | null > | null,
  logo?: string | null,
  subtitle?: string | null,
  description?: string | null,
  type?: string | null,
  pageVideos?: Array< VideoDataInput | null > | null,
  cardVideos?: Array< VideoDataInput | null > | null,
  videoData?: Array< VideoDataInput | null > | null,
  discount?: number | null,
  isOnlineEvent?: boolean | null,
  score?: number | null,
  address?: AddressInput | null,
  eventFeatures?: Array< EntityFeatureInput | null > | null,
  categories?: Array< string | null > | null,
  closingTime?: number | null,
  dayOfWeek?: number | null,
  daysOfWeek?: Array< number | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  showWeather?: boolean | null,
  buyTicketUrl?: string | null,
  canBook?: boolean | null,
  ttl?: number | null,
  whatsappNumber?: string | null,
  phone?: string | null,
  mapImages?: Array< string | null > | null,
  video?: string | null,
  images?: Array< string | null > | null,
  artistsList?: Array< string | null > | null,
  featured?: string | null,
  features?: Array< FeatureInput | null > | null,
  firstEventDate?: string | null,
  lastEventDate?: string | null,
  location?: LocationInput | null,
  price?: string | null,
  rules?: string | null,
  city?: string | null,
  attending?: number | null,
  status: string,
  ownerType?: string | null,
  owner?: string | null,
  pushTimestamps?: Array< number | null > | null,
  promoterIds?: Array< string | null > | null,
  extraInfo?: Array< AccordionDataInput | null > | null,
  owners?: Array< string | null > | null,
  occurencesRules?: Array< OccurencesRulesInput | null > | null,
  timelines?: Array< TimelineInput | null > | null,
  checklist?: ChecklistInput | null,
  menuUrlList?: Array< UrlTypeInput | null > | null,
  menuPDFList?: Array< UrlTypeInput | null > | null,
  menuImages?: Array< string | null > | null,
  menu?: MenuInput | null,
  username?: string | null,
  influencerInfo?: EventInfluencerInfoInput | null,
  config?: EventConfigInput | null,
  createdAt: number,
  updatedAt: number,
};

export type OccurencesRulesInput = {
  title?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  typeOfRepeat?: string | null,
  repeatInterval?: number | null,
  daysOfWeek?: Array< number | null > | null,
  daysOfMonth?: Array< number | null > | null,
  customDates?: Array< EventDatesInput | null > | null,
  weeksOfMonth?: Array< number | null > | null,
  weeksDaysOfMonth?: Array< number | null > | null,
  occurenceTotalRepeat?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  usesEntityTables?: boolean | null,
  price?: TicketPriceInput | null,
  quantity?: number | null,
};

export type EventDatesInput = {
  startDate?: string | null,
  endDate?: string | null,
};

export type ModelRepeatEventConditionInput = {
  title?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  area?: ModelStringInput | null,
  headerVideos?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  logo?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  discount?: ModelIntInput | null,
  isOnlineEvent?: ModelBooleanInput | null,
  score?: ModelFloatInput | null,
  categories?: ModelStringInput | null,
  closingTime?: ModelFloatInput | null,
  dayOfWeek?: ModelIntInput | null,
  daysOfWeek?: ModelIntInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  showWeather?: ModelBooleanInput | null,
  buyTicketUrl?: ModelStringInput | null,
  canBook?: ModelBooleanInput | null,
  ttl?: ModelFloatInput | null,
  whatsappNumber?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  mapImages?: ModelStringInput | null,
  video?: ModelStringInput | null,
  images?: ModelStringInput | null,
  artistsList?: ModelStringInput | null,
  featured?: ModelStringInput | null,
  firstEventDate?: ModelStringInput | null,
  lastEventDate?: ModelStringInput | null,
  price?: ModelStringInput | null,
  rules?: ModelStringInput | null,
  city?: ModelStringInput | null,
  attending?: ModelIntInput | null,
  status?: ModelStringInput | null,
  ownerType?: ModelStringInput | null,
  pushTimestamps?: ModelFloatInput | null,
  promoterIds?: ModelStringInput | null,
  menuImages?: ModelStringInput | null,
  username?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelRepeatEventConditionInput | null > | null,
  or?: Array< ModelRepeatEventConditionInput | null > | null,
  not?: ModelRepeatEventConditionInput | null,
};

export type UpdateRepeatEventInput = {
  id: string,
  title?: string | null,
  entityId?: string | null,
  area?: string | null,
  headerVideos?: Array< string | null > | null,
  publisherId?: string | null,
  sponsors?: Array< SponsorInput | null > | null,
  logo?: string | null,
  subtitle?: string | null,
  description?: string | null,
  type?: string | null,
  pageVideos?: Array< VideoDataInput | null > | null,
  cardVideos?: Array< VideoDataInput | null > | null,
  videoData?: Array< VideoDataInput | null > | null,
  discount?: number | null,
  isOnlineEvent?: boolean | null,
  score?: number | null,
  address?: AddressInput | null,
  eventFeatures?: Array< EntityFeatureInput | null > | null,
  categories?: Array< string | null > | null,
  closingTime?: number | null,
  dayOfWeek?: number | null,
  daysOfWeek?: Array< number | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  showWeather?: boolean | null,
  buyTicketUrl?: string | null,
  canBook?: boolean | null,
  ttl?: number | null,
  whatsappNumber?: string | null,
  phone?: string | null,
  mapImages?: Array< string | null > | null,
  video?: string | null,
  images?: Array< string | null > | null,
  artistsList?: Array< string | null > | null,
  featured?: string | null,
  features?: Array< FeatureInput | null > | null,
  firstEventDate?: string | null,
  lastEventDate?: string | null,
  location?: LocationInput | null,
  price?: string | null,
  rules?: string | null,
  city?: string | null,
  attending?: number | null,
  status?: string | null,
  ownerType?: string | null,
  owner?: string | null,
  pushTimestamps?: Array< number | null > | null,
  promoterIds?: Array< string | null > | null,
  extraInfo?: Array< AccordionDataInput | null > | null,
  owners?: Array< string | null > | null,
  occurencesRules?: Array< OccurencesRulesInput | null > | null,
  timelines?: Array< TimelineInput | null > | null,
  checklist?: ChecklistInput | null,
  menuUrlList?: Array< UrlTypeInput | null > | null,
  menuPDFList?: Array< UrlTypeInput | null > | null,
  menuImages?: Array< string | null > | null,
  menu?: MenuInput | null,
  username?: string | null,
  influencerInfo?: EventInfluencerInfoInput | null,
  config?: EventConfigInput | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteRepeatEventInput = {
  id: string,
};

export type CreateRepeatEventOccurenceInput = {
  id?: string | null,
  repeatEventId: string,
  startDate?: string | null,
  endDate?: string | null,
  owners?: Array< string | null > | null,
  isSoldOut?: boolean | null,
  totalTicketsSold?: number | null,
  canBuyTickets?: boolean | null,
  ttl?: number | null,
};

export type ModelRepeatEventOccurenceConditionInput = {
  repeatEventId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  owners?: ModelIDInput | null,
  isSoldOut?: ModelBooleanInput | null,
  totalTicketsSold?: ModelFloatInput | null,
  canBuyTickets?: ModelBooleanInput | null,
  ttl?: ModelFloatInput | null,
  and?: Array< ModelRepeatEventOccurenceConditionInput | null > | null,
  or?: Array< ModelRepeatEventOccurenceConditionInput | null > | null,
  not?: ModelRepeatEventOccurenceConditionInput | null,
};

export type UpdateRepeatEventOccurenceInput = {
  id: string,
  repeatEventId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  owners?: Array< string | null > | null,
  isSoldOut?: boolean | null,
  totalTicketsSold?: number | null,
  canBuyTickets?: boolean | null,
  ttl?: number | null,
};

export type DeleteRepeatEventOccurenceInput = {
  id: string,
};

export type CreateReviewInput = {
  id?: string | null,
  review?: string | null,
  score?: number | null,
  feedback?: string | null,
  scores?: ReviewScoresInput | null,
  dateOfVisit?: number | null,
  userId: string,
  entityId: string,
  publisherId: string,
  status?: string | null,
  verified?: boolean | null,
  createdAt: number,
  updatedAt: number,
};

export type ReviewScoresInput = {
  food?: number | null,
  vibe?: number | null,
  service?: number | null,
  value?: number | null,
  overall?: number | null,
};

export type ModelReviewConditionInput = {
  review?: ModelStringInput | null,
  score?: ModelIntInput | null,
  feedback?: ModelStringInput | null,
  dateOfVisit?: ModelFloatInput | null,
  userId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  verified?: ModelBooleanInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
};

export type UpdateReviewInput = {
  id: string,
  review?: string | null,
  score?: number | null,
  feedback?: string | null,
  scores?: ReviewScoresInput | null,
  dateOfVisit?: number | null,
  userId?: string | null,
  entityId?: string | null,
  publisherId?: string | null,
  status?: string | null,
  verified?: boolean | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteReviewInput = {
  id: string,
};

export type CreateUserEventInput = {
  id?: string | null,
  eventDate: number,
  eventId: string,
  userName?: string | null,
  repeatEventId: string,
  repeatEventOccurenceId: string,
  type?: string | null,
  userId: string,
  owners?: Array< string | null > | null,
  status?: string | null,
  checkInDate?: string | null,
  documents?: Array< UrlTypeInput | null > | null,
  tablesBooked?: Array< string | null > | null,
  subscriptionArn?: string | null,
  plusOnesRemaining?: number | null,
  authCode?: string | null,
  subscriptionArns?: Array< string | null > | null,
  pushSubscriptions?: Array< PushSubscriptionInput | null > | null,
  subscriptionActive?: boolean | null,
  title: string,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type PushSubscriptionInput = {
  endpointArn?: string | null,
  deviceEndpointArn?: string | null,
};

export type ModelUserEventConditionInput = {
  eventDate?: ModelFloatInput | null,
  eventId?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  repeatEventId?: ModelIDInput | null,
  repeatEventOccurenceId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  checkInDate?: ModelStringInput | null,
  tablesBooked?: ModelStringInput | null,
  subscriptionArn?: ModelStringInput | null,
  plusOnesRemaining?: ModelIntInput | null,
  authCode?: ModelStringInput | null,
  subscriptionArns?: ModelStringInput | null,
  subscriptionActive?: ModelBooleanInput | null,
  title?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelUserEventConditionInput | null > | null,
  or?: Array< ModelUserEventConditionInput | null > | null,
  not?: ModelUserEventConditionInput | null,
};

export type UpdateUserEventInput = {
  id: string,
  eventDate?: number | null,
  eventId?: string | null,
  userName?: string | null,
  repeatEventId?: string | null,
  repeatEventOccurenceId?: string | null,
  type?: string | null,
  userId?: string | null,
  owners?: Array< string | null > | null,
  status?: string | null,
  checkInDate?: string | null,
  documents?: Array< UrlTypeInput | null > | null,
  tablesBooked?: Array< string | null > | null,
  subscriptionArn?: string | null,
  plusOnesRemaining?: number | null,
  authCode?: string | null,
  subscriptionArns?: Array< string | null > | null,
  pushSubscriptions?: Array< PushSubscriptionInput | null > | null,
  subscriptionActive?: boolean | null,
  title?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteUserEventInput = {
  id: string,
};

export type CreateCardTransactionsInput = {
  currency?: string | null,
  auth?: boolean | null,
  type?: string | null,
  programId?: string | null,
  id?: string | null,
  accountId?: string | null,
  userEntityId?: string | null,
  userPublisherId?: string | null,
  userId?: string | null,
  owner?: string | null,
  owners?: Array< string | null > | null,
  entityId?: string | null,
  publisherId?: string | null,
  created?: string | null,
  authCode?: string | null,
  updated?: string | null,
  amount?: number | null,
  cleared?: boolean | null,
  visits?: number | null,
  wallet?: string | null,
  offer?: string | null,
  ttl?: number | null,
  datetime?: string | null,
  card?: TransactionCardInput | null,
  location?: FidelLocationInput | null,
  brand?: CardBrandDataInput | null,
  identifiers?: FidelTransactionIdentifiersInput | null,
};

export type TransactionCardInput = {
  id?: string | null,
  firstNumbers?: string | null,
  lastNumbers?: string | null,
  scheme?: string | null,
};

export type FidelLocationInput = {
  address?: string | null,
  city?: string | null,
  countryCode?: string | null,
  id?: string | null,
  geolocation?: string | null,
  postcode?: string | null,
  timezone?: string | null,
  metadata?: IFidelMetaDataInput | null,
};

export type IFidelMetaDataInput = {
  userId?: string | null,
  publisherId?: string | null,
  brandId?: string | null,
  entityId?: string | null,
  title?: string | null,
  gender?: string | null,
  dob?: string | null,
  cognitoUsername?: string | null,
};

export type CardBrandDataInput = {
  id?: string | null,
  name?: string | null,
  logoURL?: string | null,
  metadata?: IFidelMetaDataInput | null,
};

export type FidelTransactionIdentifiersInput = {
  amexApprovalCode?: string | null,
  mastercardAuthCode?: string | null,
  mastercardRefNumber?: string | null,
  mastercardTransactionSequenceNumber?: string | null,
  MID?: string | null,
  visaAuthCode?: string | null,
};

export type ModelCardTransactionsConditionInput = {
  currency?: ModelStringInput | null,
  auth?: ModelBooleanInput | null,
  type?: ModelStringInput | null,
  programId?: ModelStringInput | null,
  accountId?: ModelStringInput | null,
  userEntityId?: ModelStringInput | null,
  userPublisherId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  publisherId?: ModelStringInput | null,
  created?: ModelStringInput | null,
  authCode?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  cleared?: ModelBooleanInput | null,
  visits?: ModelFloatInput | null,
  wallet?: ModelStringInput | null,
  offer?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  datetime?: ModelStringInput | null,
  and?: Array< ModelCardTransactionsConditionInput | null > | null,
  or?: Array< ModelCardTransactionsConditionInput | null > | null,
  not?: ModelCardTransactionsConditionInput | null,
};

export type CardTransactions = {
  __typename: "CardTransactions",
  currency?: string | null,
  auth?: boolean | null,
  type?: string | null,
  programId?: string | null,
  id?: string | null,
  accountId?: string | null,
  userEntityId?: string | null,
  userPublisherId?: string | null,
  userId?: string | null,
  owner?: string | null,
  owners?: Array< string | null > | null,
  entityId?: string | null,
  publisherId?: string | null,
  created?: string | null,
  authCode?: string | null,
  updated?: string | null,
  amount?: number | null,
  cleared?: boolean | null,
  visits?: number | null,
  wallet?: string | null,
  offer?: string | null,
  ttl?: number | null,
  datetime?: string | null,
  card?: TransactionCard | null,
  location?: FidelLocation | null,
  brand?: CardBrandData | null,
  identifiers?: FidelTransactionIdentifiers | null,
  createdAt: string,
  updatedAt: string,
  entity?: Entity | null,
};

export type TransactionCard = {
  __typename: "TransactionCard",
  id?: string | null,
  firstNumbers?: string | null,
  lastNumbers?: string | null,
  scheme?: string | null,
};

export type FidelLocation = {
  __typename: "FidelLocation",
  address?: string | null,
  city?: string | null,
  countryCode?: string | null,
  id?: string | null,
  geolocation?: string | null,
  postcode?: string | null,
  timezone?: string | null,
  metadata?: IFidelMetaData | null,
};

export type CardBrandData = {
  __typename: "CardBrandData",
  id?: string | null,
  name?: string | null,
  logoURL?: string | null,
  metadata?: IFidelMetaData | null,
};

export type FidelTransactionIdentifiers = {
  __typename: "FidelTransactionIdentifiers",
  amexApprovalCode?: string | null,
  mastercardAuthCode?: string | null,
  mastercardRefNumber?: string | null,
  mastercardTransactionSequenceNumber?: string | null,
  MID?: string | null,
  visaAuthCode?: string | null,
};

export type UpdateCardTransactionsInput = {
  currency?: string | null,
  auth?: boolean | null,
  type?: string | null,
  programId?: string | null,
  id: string,
  accountId?: string | null,
  userEntityId?: string | null,
  userPublisherId?: string | null,
  userId?: string | null,
  owner?: string | null,
  owners?: Array< string | null > | null,
  entityId?: string | null,
  publisherId?: string | null,
  created?: string | null,
  authCode?: string | null,
  updated?: string | null,
  amount?: number | null,
  cleared?: boolean | null,
  visits?: number | null,
  wallet?: string | null,
  offer?: string | null,
  ttl?: number | null,
  datetime?: string | null,
  card?: TransactionCardInput | null,
  location?: FidelLocationInput | null,
  brand?: CardBrandDataInput | null,
  identifiers?: FidelTransactionIdentifiersInput | null,
};

export type DeleteCardTransactionsInput = {
  id: string,
};

export type CreateLoyaltyUserCountersInput = {
  id?: string | null,
  date?: string | null,
  publisherId: string,
  entityId: string,
  userId: string,
  userDob?: string | null,
  userAge?: number | null,
  visits?: number | null,
  idTypeKey?: string | null,
  userGender?: string | null,
  spend?: number | null,
  ttl?: number | null,
  period?: string | null,
  owners?: Array< string | null > | null,
  owner?: string | null,
  currency?: string | null,
  transactions?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type ModelLoyaltyUserCountersConditionInput = {
  date?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  userDob?: ModelStringInput | null,
  userAge?: ModelIntInput | null,
  visits?: ModelFloatInput | null,
  idTypeKey?: ModelStringInput | null,
  userGender?: ModelStringInput | null,
  spend?: ModelFloatInput | null,
  ttl?: ModelFloatInput | null,
  period?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  transactions?: ModelFloatInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLoyaltyUserCountersConditionInput | null > | null,
  or?: Array< ModelLoyaltyUserCountersConditionInput | null > | null,
  not?: ModelLoyaltyUserCountersConditionInput | null,
};

export type LoyaltyUserCounters = {
  __typename: "LoyaltyUserCounters",
  id: string,
  date?: string | null,
  publisherId: string,
  entityId: string,
  userId: string,
  userDob?: string | null,
  userAge?: number | null,
  visits?: number | null,
  idTypeKey?: string | null,
  userGender?: string | null,
  spend?: number | null,
  ttl?: number | null,
  period?: string | null,
  owners?: Array< string | null > | null,
  owner?: string | null,
  currency?: string | null,
  transactions?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  entity?: Entity | null,
};

export type UpdateLoyaltyUserCountersInput = {
  id: string,
  date?: string | null,
  publisherId?: string | null,
  entityId?: string | null,
  userId?: string | null,
  userDob?: string | null,
  userAge?: number | null,
  visits?: number | null,
  idTypeKey?: string | null,
  userGender?: string | null,
  spend?: number | null,
  ttl?: number | null,
  period?: string | null,
  owners?: Array< string | null > | null,
  owner?: string | null,
  currency?: string | null,
  transactions?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteLoyaltyUserCountersInput = {
  id: string,
};

export type CreateLoyaltyEntityCountersInput = {
  id?: string | null,
  date?: string | null,
  publisherId: string,
  entityId: string,
  spend?: number | null,
  ttl?: number | null,
  idTypeKey?: string | null,
  genderCount?: GenderCountInput | null,
  ageCount?: AgeCountInput | null,
  visits?: number | null,
  currency?: string | null,
  transactions?: number | null,
  paid?: string | null,
  invoice?: string | null,
  owners?: Array< string | null > | null,
  owner?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type GenderCountInput = {
  m?: number | null,
  f?: number | null,
  nb?: number | null,
  t?: number | null,
  o?: number | null,
};

export type AgeCountInput = {
  below18?: number | null,
  age18To25?: number | null,
  age26To30?: number | null,
  age31To35?: number | null,
  age36To40?: number | null,
  age41To45?: number | null,
  Over45?: number | null,
};

export type ModelLoyaltyEntityCountersConditionInput = {
  date?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  spend?: ModelFloatInput | null,
  ttl?: ModelFloatInput | null,
  idTypeKey?: ModelStringInput | null,
  visits?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  transactions?: ModelFloatInput | null,
  paid?: ModelStringInput | null,
  invoice?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLoyaltyEntityCountersConditionInput | null > | null,
  or?: Array< ModelLoyaltyEntityCountersConditionInput | null > | null,
  not?: ModelLoyaltyEntityCountersConditionInput | null,
};

export type LoyaltyEntityCounters = {
  __typename: "LoyaltyEntityCounters",
  id: string,
  date?: string | null,
  publisherId: string,
  entityId: string,
  spend?: number | null,
  ttl?: number | null,
  idTypeKey?: string | null,
  genderCount?: GenderCount | null,
  ageCount?: AgeCount | null,
  visits?: number | null,
  currency?: string | null,
  transactions?: number | null,
  paid?: string | null,
  invoice?: string | null,
  owners?: Array< string | null > | null,
  owner?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  entity?: Entity | null,
};

export type GenderCount = {
  __typename: "GenderCount",
  m?: number | null,
  f?: number | null,
  nb?: number | null,
  t?: number | null,
  o?: number | null,
};

export type AgeCount = {
  __typename: "AgeCount",
  below18?: number | null,
  age18To25?: number | null,
  age26To30?: number | null,
  age31To35?: number | null,
  age36To40?: number | null,
  age41To45?: number | null,
  Over45?: number | null,
};

export type UpdateLoyaltyEntityCountersInput = {
  id: string,
  date?: string | null,
  publisherId?: string | null,
  entityId?: string | null,
  spend?: number | null,
  ttl?: number | null,
  idTypeKey?: string | null,
  genderCount?: GenderCountInput | null,
  ageCount?: AgeCountInput | null,
  visits?: number | null,
  currency?: string | null,
  transactions?: number | null,
  paid?: string | null,
  invoice?: string | null,
  owners?: Array< string | null > | null,
  owner?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteLoyaltyEntityCountersInput = {
  id: string,
};

export type CreateHelpTopicInput = {
  id?: string | null,
  title: string,
  type?: string | null,
  category?: string | null,
  icon?: string | null,
  color?: string | null,
  notes?: string | null,
  subtitle?: string | null,
  authorId?: string | null,
  image?: string | null,
  index?: number | null,
  articles?: Array< HelpArticleInput | null > | null,
  sections?: Array< HelpSectionInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type HelpArticleInput = {
  index?: number | null,
  title?: string | null,
  subtitle?: string | null,
  image?: string | null,
  content?: string | null,
  articles?: Array< HelpArticleInput | null > | null,
};

export type HelpSectionInput = {
  index?: number | null,
  title?: string | null,
  content?: string | null,
  type?: string | null,
};

export type ModelHelpTopicConditionInput = {
  title?: ModelStringInput | null,
  type?: ModelStringInput | null,
  category?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  color?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  authorId?: ModelStringInput | null,
  image?: ModelStringInput | null,
  index?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHelpTopicConditionInput | null > | null,
  or?: Array< ModelHelpTopicConditionInput | null > | null,
  not?: ModelHelpTopicConditionInput | null,
};

export type HelpTopic = {
  __typename: "HelpTopic",
  id: string,
  title: string,
  type?: string | null,
  category?: string | null,
  icon?: string | null,
  color?: string | null,
  notes?: string | null,
  subtitle?: string | null,
  authorId?: string | null,
  image?: string | null,
  index?: number | null,
  articles?:  Array<HelpArticle | null > | null,
  sections?:  Array<HelpSection | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type HelpArticle = {
  __typename: "HelpArticle",
  index?: number | null,
  title?: string | null,
  subtitle?: string | null,
  image?: string | null,
  content?: string | null,
  articles?:  Array<HelpArticle | null > | null,
};

export type HelpSection = {
  __typename: "HelpSection",
  index?: number | null,
  title?: string | null,
  content?: string | null,
  type?: string | null,
};

export type UpdateHelpTopicInput = {
  id: string,
  title?: string | null,
  type?: string | null,
  category?: string | null,
  icon?: string | null,
  color?: string | null,
  notes?: string | null,
  subtitle?: string | null,
  authorId?: string | null,
  image?: string | null,
  index?: number | null,
  articles?: Array< HelpArticleInput | null > | null,
  sections?: Array< HelpSectionInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteHelpTopicInput = {
  id: string,
};

export type CreateSpecialOfferInput = {
  id?: string | null,
  daysAvailable?: Array< number | null > | null,
  startTime?: string | null,
  price?: number | null,
  discount?: number | null,
  endTime?: string | null,
  endDate?: string | null,
  countryCode?: string | null,
  declineReason?: string | null,
  requiresBooking?: string | null,
  startDate?: string | null,
  maxTransactionAmount?: number | null,
  minTransactionAmount?: number | null,
  returnPeriod?: number | null,
  metaData?: IFidelMetaDataInput | null,
  description?: string | null,
  totalLoyaltyPointsRequiredForAccess?: number | null,
  title: string,
  images?: Array< string | null > | null,
  weeks?: number | null,
  entityId: string,
  publisherId: string,
  location?: LocationInput | null,
  fidelId?: string | null,
  spendRequired?: number | null,
  delayMinutes?: number | null,
  delayHours?: number | null,
  currency?: string | null,
  isStudentDiscount?: boolean | null,
  city?: string | null,
  quantity?: number | null,
  status: string,
  loyaltyPointsRequired?: number | null,
  loyaltyPointsValue?: number | null,
  visitsRequired?: number | null,
  redeemCount?: number | null,
  claimCount?: number | null,
  dateAvailable?: string | null,
  allowanceLimit?: number | null,
  refreshDaysPeriod?: string | null,
  type?: string | null,
  rewardType?: string | null,
  subscribersOnly?: boolean | null,
  owner?: string | null,
  disclaimer?: string | null,
  metadata?: IFidelMetaDataInput | null,
  fidelOfferId?: string | null,
  fidelStartDate?: string | null,
  fidelEndDate?: string | null,
  fidelMinTransactionAmount?: number | null,
  fidelMaxTransactionAmount?: number | null,
  fidelReturnPeriod?: number | null,
  fidelDaysOfWeek?: Array< number | null > | null,
  createdAt: number,
  updatedAt: number,
};

export type ModelSpecialOfferConditionInput = {
  daysAvailable?: ModelIntInput | null,
  startTime?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  discount?: ModelIntInput | null,
  endTime?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  declineReason?: ModelStringInput | null,
  requiresBooking?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  maxTransactionAmount?: ModelFloatInput | null,
  minTransactionAmount?: ModelFloatInput | null,
  returnPeriod?: ModelIntInput | null,
  description?: ModelStringInput | null,
  totalLoyaltyPointsRequiredForAccess?: ModelFloatInput | null,
  title?: ModelStringInput | null,
  images?: ModelStringInput | null,
  weeks?: ModelIntInput | null,
  entityId?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  fidelId?: ModelStringInput | null,
  spendRequired?: ModelFloatInput | null,
  delayMinutes?: ModelFloatInput | null,
  delayHours?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  isStudentDiscount?: ModelBooleanInput | null,
  city?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  status?: ModelStringInput | null,
  loyaltyPointsRequired?: ModelFloatInput | null,
  loyaltyPointsValue?: ModelFloatInput | null,
  visitsRequired?: ModelFloatInput | null,
  redeemCount?: ModelIntInput | null,
  claimCount?: ModelIntInput | null,
  dateAvailable?: ModelStringInput | null,
  allowanceLimit?: ModelIntInput | null,
  refreshDaysPeriod?: ModelStringInput | null,
  type?: ModelStringInput | null,
  rewardType?: ModelStringInput | null,
  subscribersOnly?: ModelBooleanInput | null,
  disclaimer?: ModelStringInput | null,
  fidelOfferId?: ModelStringInput | null,
  fidelStartDate?: ModelStringInput | null,
  fidelEndDate?: ModelStringInput | null,
  fidelMinTransactionAmount?: ModelFloatInput | null,
  fidelMaxTransactionAmount?: ModelFloatInput | null,
  fidelReturnPeriod?: ModelFloatInput | null,
  fidelDaysOfWeek?: ModelIntInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelSpecialOfferConditionInput | null > | null,
  or?: Array< ModelSpecialOfferConditionInput | null > | null,
  not?: ModelSpecialOfferConditionInput | null,
};

export type UpdateSpecialOfferInput = {
  id: string,
  daysAvailable?: Array< number | null > | null,
  startTime?: string | null,
  price?: number | null,
  discount?: number | null,
  endTime?: string | null,
  endDate?: string | null,
  countryCode?: string | null,
  declineReason?: string | null,
  requiresBooking?: string | null,
  startDate?: string | null,
  maxTransactionAmount?: number | null,
  minTransactionAmount?: number | null,
  returnPeriod?: number | null,
  metaData?: IFidelMetaDataInput | null,
  description?: string | null,
  totalLoyaltyPointsRequiredForAccess?: number | null,
  title?: string | null,
  images?: Array< string | null > | null,
  weeks?: number | null,
  entityId?: string | null,
  publisherId?: string | null,
  location?: LocationInput | null,
  fidelId?: string | null,
  spendRequired?: number | null,
  delayMinutes?: number | null,
  delayHours?: number | null,
  currency?: string | null,
  isStudentDiscount?: boolean | null,
  city?: string | null,
  quantity?: number | null,
  status?: string | null,
  loyaltyPointsRequired?: number | null,
  loyaltyPointsValue?: number | null,
  visitsRequired?: number | null,
  redeemCount?: number | null,
  claimCount?: number | null,
  dateAvailable?: string | null,
  allowanceLimit?: number | null,
  refreshDaysPeriod?: string | null,
  type?: string | null,
  rewardType?: string | null,
  subscribersOnly?: boolean | null,
  owner?: string | null,
  disclaimer?: string | null,
  metadata?: IFidelMetaDataInput | null,
  fidelOfferId?: string | null,
  fidelStartDate?: string | null,
  fidelEndDate?: string | null,
  fidelMinTransactionAmount?: number | null,
  fidelMaxTransactionAmount?: number | null,
  fidelReturnPeriod?: number | null,
  fidelDaysOfWeek?: Array< number | null > | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteSpecialOfferInput = {
  id: string,
};

export type CreateLoyaltyCardInput = {
  id?: string | null,
  userId: string,
  publisherId: string,
  businessTitle?: string | null,
  currentTier?: string | null,
  entityId?: string | null,
  visits?: number | null,
  businessAssignedTier?: string | null,
  transactions?: number | null,
  totalLoyaltyPointsRequiredForAccess?: number | null,
  subscriptionArns?: Array< string | null > | null,
  pushSubscriptions?: Array< PushSubscriptionInput | null > | null,
  notificationsEnabled?: boolean | null,
  totalSpend?: number | null,
  pendingPoints?: number | null,
  totalPoints?: number | null,
  ownerType?: string | null,
  status?: string | null,
  userFullName?: string | null,
  owner?: string | null,
  owners?: Array< string | null > | null,
  totalSpendablePoints?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelLoyaltyCardConditionInput = {
  userId?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  businessTitle?: ModelStringInput | null,
  currentTier?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  visits?: ModelFloatInput | null,
  businessAssignedTier?: ModelStringInput | null,
  transactions?: ModelFloatInput | null,
  totalLoyaltyPointsRequiredForAccess?: ModelFloatInput | null,
  subscriptionArns?: ModelStringInput | null,
  notificationsEnabled?: ModelBooleanInput | null,
  totalSpend?: ModelFloatInput | null,
  pendingPoints?: ModelFloatInput | null,
  totalPoints?: ModelFloatInput | null,
  ownerType?: ModelStringInput | null,
  status?: ModelStringInput | null,
  userFullName?: ModelStringInput | null,
  totalSpendablePoints?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLoyaltyCardConditionInput | null > | null,
  or?: Array< ModelLoyaltyCardConditionInput | null > | null,
  not?: ModelLoyaltyCardConditionInput | null,
};

export type UpdateLoyaltyCardInput = {
  id: string,
  userId?: string | null,
  publisherId?: string | null,
  businessTitle?: string | null,
  currentTier?: string | null,
  entityId?: string | null,
  visits?: number | null,
  businessAssignedTier?: string | null,
  transactions?: number | null,
  totalLoyaltyPointsRequiredForAccess?: number | null,
  subscriptionArns?: Array< string | null > | null,
  pushSubscriptions?: Array< PushSubscriptionInput | null > | null,
  notificationsEnabled?: boolean | null,
  totalSpend?: number | null,
  pendingPoints?: number | null,
  totalPoints?: number | null,
  ownerType?: string | null,
  status?: string | null,
  userFullName?: string | null,
  owner?: string | null,
  owners?: Array< string | null > | null,
  totalSpendablePoints?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteLoyaltyCardInput = {
  id: string,
};

export type CreateDailyPromotionsInput = {
  id?: string | null,
  entityId: string,
  daysAvailable?: Array< number | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  description?: string | null,
  price?: number | null,
  images?: Array< string | null > | null,
  title?: string | null,
  status?: string | null,
  disclaimer?: string | null,
  owner?: string | null,
  subscribersOnly?: boolean | null,
  createdAt: number,
  updatedAt: number,
};

export type ModelDailyPromotionsConditionInput = {
  entityId?: ModelIDInput | null,
  daysAvailable?: ModelIntInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  images?: ModelStringInput | null,
  title?: ModelStringInput | null,
  status?: ModelStringInput | null,
  disclaimer?: ModelStringInput | null,
  subscribersOnly?: ModelBooleanInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelDailyPromotionsConditionInput | null > | null,
  or?: Array< ModelDailyPromotionsConditionInput | null > | null,
  not?: ModelDailyPromotionsConditionInput | null,
};

export type UpdateDailyPromotionsInput = {
  id: string,
  entityId?: string | null,
  daysAvailable?: Array< number | null > | null,
  startTime?: string | null,
  endTime?: string | null,
  description?: string | null,
  price?: number | null,
  images?: Array< string | null > | null,
  title?: string | null,
  status?: string | null,
  disclaimer?: string | null,
  owner?: string | null,
  subscribersOnly?: boolean | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteDailyPromotionsInput = {
  id: string,
};

export type CreateLoyaltyActivityInput = {
  id?: string | null,
  userPublisherKey?: string | null,
  points?: number | null,
  eventId: string,
  entityId: string,
  publisherId: string,
  repeatEventId: string,
  userId: string,
  adminAllocated?: boolean | null,
  message?: string | null,
  createdAt: number,
  updatedAt: number,
};

export type ModelLoyaltyActivityConditionInput = {
  userPublisherKey?: ModelStringInput | null,
  points?: ModelIntInput | null,
  eventId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  adminAllocated?: ModelBooleanInput | null,
  message?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelLoyaltyActivityConditionInput | null > | null,
  or?: Array< ModelLoyaltyActivityConditionInput | null > | null,
  not?: ModelLoyaltyActivityConditionInput | null,
};

export type LoyaltyActivity = {
  __typename: "LoyaltyActivity",
  id: string,
  userPublisherKey?: string | null,
  points?: number | null,
  eventId: string,
  entityId: string,
  publisherId: string,
  repeatEventId: string,
  userId: string,
  adminAllocated?: boolean | null,
  message?: string | null,
  createdAt: number,
  updatedAt: number,
  event?: Event | null,
  repeatEvent?: RepeatEvent | null,
  entity?: Entity | null,
  publisher?: Publisher | null,
  user?: User | null,
};

export type UpdateLoyaltyActivityInput = {
  id: string,
  userPublisherKey?: string | null,
  points?: number | null,
  eventId?: string | null,
  entityId?: string | null,
  publisherId?: string | null,
  repeatEventId?: string | null,
  userId?: string | null,
  adminAllocated?: boolean | null,
  message?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteLoyaltyActivityInput = {
  id: string,
};

export type CreateEntityInput = {
  id?: string | null,
  title: string,
  shortUrl?: string | null,
  subtitle?: string | null,
  lastAdminCheck?: string | null,
  area?: string | null,
  contacts?: Array< ContactInfoInput | null > | null,
  notes?: string | null,
  summary?: string | null,
  uniqueAspect?: string | null,
  tags?: Array< string | null > | null,
  highlights?: Array< PlaceHighlightInput | null > | null,
  nickname?: string | null,
  hasEmailed?: boolean | null,
  firstName?: string | null,
  hasStudentDiscount?: boolean | null,
  LastName?: string | null,
  config?: EntityConfigInput | null,
  nearbyStations?: Array< EntityClosestStationInput | null > | null,
  outdoorArea?: boolean | null,
  outdoorHeating?: boolean | null,
  description?: string | null,
  stripeSubscriptionItemId?: string | null,
  emojiSummary?: Array< string | null > | null,
  categories?: Array< string | null > | null,
  phone?: string | null,
  internationalPhoneNumber?: string | null,
  logo?: string | null,
  pushTimestamps?: Array< number | null > | null,
  websiteUrl?: string | null,
  orderfromTableUrl?: string | null,
  location?: LocationInput | null,
  entityTitle?: string | null,
  opens?: number | null,
  closes?: number | null,
  asFeaturedOn?: Array< string | null > | null,
  username?: string | null,
  trendingScore?: number | null,
  openHours?: DailyOpeningTimesInput | null,
  status: string,
  address?: AddressInput | null,
  virtualTourUrl?: string | null,
  emailsDisabled?: boolean | null,
  reviewsDisabled?: boolean | null,
  video?: string | null,
  pageVideos?: Array< VideoDataInput | null > | null,
  cardVideos?: Array< VideoDataInput | null > | null,
  sponsors?: Array< SponsorInput | null > | null,
  headerVideos?: Array< string | null > | null,
  videoData?: Array< VideoDataInput | null > | null,
  city: string,
  currency?: CurrencyInput | null,
  features?: Array< FeatureInput | null > | null,
  entityFeatures?: Array< EntityFeatureInput | null > | null,
  rules?: string | null,
  position?: number | null,
  score?: number | null,
  score_before_boost?: number | null,
  boost_multiplier?: number | null,
  boost_until?: string | null,
  boostPurchaseId?: string | null,
  reviewRating?: number | null,
  reviewCount?: number | null,
  canMessage?: boolean | null,
  forceupdate?: boolean | null,
  email?: string | null,
  menuUrlList?: Array< UrlTypeInput | null > | null,
  menuPDFList?: Array< UrlTypeInput | null > | null,
  menuImages?: Array< string | null > | null,
  menu?: MenuInput | null,
  price?: string | null,
  featured?: string | null,
  cuisines?: Array< string | null > | null,
  dietryOptions?: Array< string | null > | null,
  foodDeliveryAppLinks?: Array< FoodDeliveryAppLinkInput | null > | null,
  verified?: boolean | null,
  qrIds?: Array< string | null > | null,
  publisherId: string,
  subscribeOfferId?: string | null,
  subscriberCount?: number | null,
  viewCount?: number | null,
  bookCount?: number | null,
  shareCount?: number | null,
  checkInCount?: number | null,
  addToListCount?: number | null,
  pushTimestamp?: number | null,
  extraInfo?: Array< AccordionDataInput | null > | null,
  images?: Array< string | null > | null,
  googleImages?: Array< GoogleImageInput | null > | null,
  socials?: SocialItemsInput | null,
  minBookingLimit?: number | null,
  maxBookingLimit?: number | null,
  bookingUrl?: string | null,
  createdAt: number,
  offersArray?: Array< string | null > | null,
  whatsappNumber?: string | null,
  updatedAt: number,
  timelines?: Array< TimelineInput | null > | null,
  checklist?: ChecklistInput | null,
  owner?: string | null,
  reviewScores?: ReviewScoresInput | null,
  safetyChecklist?: ChecklistInput | null,
  venueSafetyData?: VenueSafetyDataInput | null,
  capacity?: number | null,
  acceptWalkIns?: boolean | null,
  covidMeasures?: CovidMeasuresInput | null,
  mediaChannels?: MediaChannelsInput | null,
  fidelConfig?: FidelConfigInput | null,
  pingReview?: PingReviewInput | null,
  pingAwards?: Array< PingAwardInput | null > | null,
};

export type ContactInfoInput = {
  title?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  venue?: string | null,
  email?: string | null,
  phone?: string | null,
  position?: string | null,
  notes?: string | null,
};

export type EntityConfigInput = {
  loyaltyEnabled?: boolean | null,
  canMessage?: boolean | null,
  canBook?: boolean | null,
  canCheckin?: boolean | null,
  canCall?: boolean | null,
  canEmail?: boolean | null,
  autoResponseMessage?: string | null,
  enterVenueCheckInEnabled?: boolean | null,
  checkInMessage?: string | null,
  colorTheme?: string | null,
  colourScheme?: EntityColourSchemeInput | null,
  bookingConfig?: EntityBookingConfigInput | null,
  emailsDisabled?: boolean | null,
  reviewsDisabled?: boolean | null,
  enableTrackTrace?: boolean | null,
};

export type EntityColourSchemeInput = {
  primary?: string | null,
  secondary?: string | null,
  text?: string | null,
  buttons?: Array< string | null > | null,
  backgrounds?: Array< string | null > | null,
};

export type EntityBookingConfigInput = {
  type?: string | null,
  bookingProvider?: EntityBookingProvider | null,
  bookingProviderBackup?: string | null,
  bookingProviderUsername?: string | null,
  bookingProviderUsernameBackup?: string | null,
  canAutoBook?: boolean | null,
  styles?: EntityBookingEmailStylesInput | null,
  notes?: string | null,
  bookingDurationMins?: number | null,
  bookingEmails?: Array< string | null > | null,
  bookingPhoneNumber?: string | null,
  serviceCategories?: Array< EntityBookingServiceCategoryInput | null > | null,
  shifts?: Array< EntityBookingShiftsInput | null > | null,
  bookingTypes?: Array< ConfigBookingTypeInput | null > | null,
  areas?: Array< string | null > | null,
};

export type EntityBookingEmailStylesInput = {
  headerBg?: string | null,
  logoWidth?: number | null,
  slideValue?: number | null,
  btnColour?: string | null,
  headerPaddingHeight?: number | null,
  logo?: string | null,
};

export type EntityBookingServiceCategoryInput = {
  title?: string | null,
  color?: string | null,
  status?: string | null,
};

export type EntityBookingShiftsInput = {
  shiftCategory?: EntityBookingServiceCategoryInput | null,
  days?: Array< number | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  maxCovers?: number | null,
  times?: Array< string | null > | null,
  durationPerTable?: Array< EntityBookingShiftDurationInput | null > | null,
  status?: string | null,
};

export type EntityBookingShiftDurationInput = {
  guests?: number | null,
  durationInMinutes?: number | null,
};

export type ConfigBookingTypeInput = {
  title?: string | null,
  desc?: string | null,
};

export type EntityClosestStationInput = {
  title?: string | null,
  distance?: number | null,
  line?: string | null,
  zone?: number | null,
  type?: string | null,
};

export type DailyOpeningTimesInput = {
  monday?: OpenHoursInput | null,
  tuesday?: OpenHoursInput | null,
  wednesday?: OpenHoursInput | null,
  thursday?: OpenHoursInput | null,
  friday?: OpenHoursInput | null,
  saturday?: OpenHoursInput | null,
  sunday?: OpenHoursInput | null,
};

export type OpenHoursInput = {
  open?: number | null,
  close?: number | null,
  fullDay?: boolean | null,
  closed?: boolean | null,
  vibe?: string | null,
  walkInWaitTimeInMins?: number | null,
  perfectFor?: Array< string | null > | null,
};

export type FoodDeliveryAppLinkInput = {
  title?: string | null,
  icon?: string | null,
  url?: string | null,
};

export type GoogleImageInput = {
  html_attributions?: Array< string | null > | null,
  image?: string | null,
};

export type VenueSafetyDataInput = {
  list?: Array< string | null > | null,
  title?: string | null,
  description?: string | null,
  links?: Array< string | null > | null,
  updated?: string | null,
};

export type CovidMeasuresInput = {
  capacityPercentage?: number | null,
  tableLimit?: number | null,
  masksRequired?: boolean | null,
  bookingsRequired?: boolean | null,
  notes?: string | null,
};

export type MediaChannelsInput = {
  youtubeUrl?: string | null,
  spotifyUrl?: string | null,
  deezerUrl?: string | null,
  mixcloudUrl?: string | null,
  amazonMusicUrl?: string | null,
  tidalUrl?: string | null,
  soundcloudUrl?: string | null,
  appleMusicUrl?: string | null,
};

export type FidelConfigInput = {
  merchantId?: string | null,
  brandId?: string | null,
  status?: string | null,
  brandUserId?: string | null,
  locationId?: string | null,
  contractSigned?: string | null,
};

export type PingReviewInput = {
  score?: number | null,
  title?: string | null,
  review?: string | null,
  createdAt?: string | null,
  reviewer?: string | null,
  reviewerPhoto?: string | null,
};

export type PingAwardInput = {
  date?: string | null,
  type?: string | null,
};

export type ModelEntityConditionInput = {
  title?: ModelStringInput | null,
  shortUrl?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  lastAdminCheck?: ModelStringInput | null,
  area?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  uniqueAspect?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  hasEmailed?: ModelBooleanInput | null,
  firstName?: ModelStringInput | null,
  hasStudentDiscount?: ModelBooleanInput | null,
  LastName?: ModelStringInput | null,
  outdoorArea?: ModelBooleanInput | null,
  outdoorHeating?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  stripeSubscriptionItemId?: ModelStringInput | null,
  emojiSummary?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  internationalPhoneNumber?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  pushTimestamps?: ModelFloatInput | null,
  websiteUrl?: ModelStringInput | null,
  orderfromTableUrl?: ModelStringInput | null,
  entityTitle?: ModelStringInput | null,
  opens?: ModelFloatInput | null,
  closes?: ModelFloatInput | null,
  asFeaturedOn?: ModelStringInput | null,
  username?: ModelStringInput | null,
  trendingScore?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  virtualTourUrl?: ModelStringInput | null,
  emailsDisabled?: ModelBooleanInput | null,
  reviewsDisabled?: ModelBooleanInput | null,
  video?: ModelStringInput | null,
  headerVideos?: ModelStringInput | null,
  city?: ModelStringInput | null,
  rules?: ModelStringInput | null,
  position?: ModelFloatInput | null,
  score?: ModelFloatInput | null,
  score_before_boost?: ModelFloatInput | null,
  boost_multiplier?: ModelFloatInput | null,
  boost_until?: ModelStringInput | null,
  boostPurchaseId?: ModelStringInput | null,
  reviewRating?: ModelFloatInput | null,
  reviewCount?: ModelIntInput | null,
  canMessage?: ModelBooleanInput | null,
  forceupdate?: ModelBooleanInput | null,
  email?: ModelStringInput | null,
  menuImages?: ModelStringInput | null,
  price?: ModelStringInput | null,
  featured?: ModelStringInput | null,
  cuisines?: ModelStringInput | null,
  dietryOptions?: ModelStringInput | null,
  verified?: ModelBooleanInput | null,
  qrIds?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  subscribeOfferId?: ModelStringInput | null,
  subscriberCount?: ModelFloatInput | null,
  viewCount?: ModelFloatInput | null,
  bookCount?: ModelFloatInput | null,
  shareCount?: ModelFloatInput | null,
  checkInCount?: ModelFloatInput | null,
  addToListCount?: ModelFloatInput | null,
  pushTimestamp?: ModelFloatInput | null,
  images?: ModelStringInput | null,
  minBookingLimit?: ModelIntInput | null,
  maxBookingLimit?: ModelIntInput | null,
  bookingUrl?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  offersArray?: ModelStringInput | null,
  whatsappNumber?: ModelStringInput | null,
  updatedAt?: ModelFloatInput | null,
  capacity?: ModelIntInput | null,
  acceptWalkIns?: ModelBooleanInput | null,
  and?: Array< ModelEntityConditionInput | null > | null,
  or?: Array< ModelEntityConditionInput | null > | null,
  not?: ModelEntityConditionInput | null,
};

export type UpdateEntityInput = {
  id: string,
  title?: string | null,
  shortUrl?: string | null,
  subtitle?: string | null,
  lastAdminCheck?: string | null,
  area?: string | null,
  contacts?: Array< ContactInfoInput | null > | null,
  notes?: string | null,
  summary?: string | null,
  uniqueAspect?: string | null,
  tags?: Array< string | null > | null,
  highlights?: Array< PlaceHighlightInput | null > | null,
  nickname?: string | null,
  hasEmailed?: boolean | null,
  firstName?: string | null,
  hasStudentDiscount?: boolean | null,
  LastName?: string | null,
  config?: EntityConfigInput | null,
  nearbyStations?: Array< EntityClosestStationInput | null > | null,
  outdoorArea?: boolean | null,
  outdoorHeating?: boolean | null,
  description?: string | null,
  stripeSubscriptionItemId?: string | null,
  emojiSummary?: Array< string | null > | null,
  categories?: Array< string | null > | null,
  phone?: string | null,
  internationalPhoneNumber?: string | null,
  logo?: string | null,
  pushTimestamps?: Array< number | null > | null,
  websiteUrl?: string | null,
  orderfromTableUrl?: string | null,
  location?: LocationInput | null,
  entityTitle?: string | null,
  opens?: number | null,
  closes?: number | null,
  asFeaturedOn?: Array< string | null > | null,
  username?: string | null,
  trendingScore?: number | null,
  openHours?: DailyOpeningTimesInput | null,
  status?: string | null,
  address?: AddressInput | null,
  virtualTourUrl?: string | null,
  emailsDisabled?: boolean | null,
  reviewsDisabled?: boolean | null,
  video?: string | null,
  pageVideos?: Array< VideoDataInput | null > | null,
  cardVideos?: Array< VideoDataInput | null > | null,
  sponsors?: Array< SponsorInput | null > | null,
  headerVideos?: Array< string | null > | null,
  videoData?: Array< VideoDataInput | null > | null,
  city?: string | null,
  currency?: CurrencyInput | null,
  features?: Array< FeatureInput | null > | null,
  entityFeatures?: Array< EntityFeatureInput | null > | null,
  rules?: string | null,
  position?: number | null,
  score?: number | null,
  score_before_boost?: number | null,
  boost_multiplier?: number | null,
  boost_until?: string | null,
  boostPurchaseId?: string | null,
  reviewRating?: number | null,
  reviewCount?: number | null,
  canMessage?: boolean | null,
  forceupdate?: boolean | null,
  email?: string | null,
  menuUrlList?: Array< UrlTypeInput | null > | null,
  menuPDFList?: Array< UrlTypeInput | null > | null,
  menuImages?: Array< string | null > | null,
  menu?: MenuInput | null,
  price?: string | null,
  featured?: string | null,
  cuisines?: Array< string | null > | null,
  dietryOptions?: Array< string | null > | null,
  foodDeliveryAppLinks?: Array< FoodDeliveryAppLinkInput | null > | null,
  verified?: boolean | null,
  qrIds?: Array< string | null > | null,
  publisherId?: string | null,
  subscribeOfferId?: string | null,
  subscriberCount?: number | null,
  viewCount?: number | null,
  bookCount?: number | null,
  shareCount?: number | null,
  checkInCount?: number | null,
  addToListCount?: number | null,
  pushTimestamp?: number | null,
  extraInfo?: Array< AccordionDataInput | null > | null,
  images?: Array< string | null > | null,
  googleImages?: Array< GoogleImageInput | null > | null,
  socials?: SocialItemsInput | null,
  minBookingLimit?: number | null,
  maxBookingLimit?: number | null,
  bookingUrl?: string | null,
  createdAt?: number | null,
  offersArray?: Array< string | null > | null,
  whatsappNumber?: string | null,
  updatedAt?: number | null,
  timelines?: Array< TimelineInput | null > | null,
  checklist?: ChecklistInput | null,
  owner?: string | null,
  reviewScores?: ReviewScoresInput | null,
  safetyChecklist?: ChecklistInput | null,
  venueSafetyData?: VenueSafetyDataInput | null,
  capacity?: number | null,
  acceptWalkIns?: boolean | null,
  covidMeasures?: CovidMeasuresInput | null,
  mediaChannels?: MediaChannelsInput | null,
  fidelConfig?: FidelConfigInput | null,
  pingReview?: PingReviewInput | null,
  pingAwards?: Array< PingAwardInput | null > | null,
};

export type DeleteEntityInput = {
  id: string,
};

export type CreateFeaturedPlaceInput = {
  id?: string | null,
  entityId: string,
  eventId?: string | null,
  location?: LocationInput | null,
  area?: string | null,
  title?: string | null,
  description?: string | null,
  pinToTop?: boolean | null,
  city?: string | null,
  featuredUntil?: string | null,
  coverage?: string | null,
  country?: string | null,
  days_selected?: Array< number | null > | null,
  startDate?: string | null,
  budget?: number | null,
  budget_left?: number | null,
  paid?: number | null,
  viewCount?: number | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  status?: string | null,
  ttl?: number | null,
};

export type ModelFeaturedPlaceConditionInput = {
  entityId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  area?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  pinToTop?: ModelBooleanInput | null,
  city?: ModelStringInput | null,
  featuredUntil?: ModelStringInput | null,
  coverage?: ModelStringInput | null,
  country?: ModelStringInput | null,
  days_selected?: ModelIntInput | null,
  startDate?: ModelStringInput | null,
  budget?: ModelFloatInput | null,
  budget_left?: ModelFloatInput | null,
  paid?: ModelFloatInput | null,
  viewCount?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  and?: Array< ModelFeaturedPlaceConditionInput | null > | null,
  or?: Array< ModelFeaturedPlaceConditionInput | null > | null,
  not?: ModelFeaturedPlaceConditionInput | null,
};

export type FeaturedPlace = {
  __typename: "FeaturedPlace",
  id: string,
  entityId: string,
  eventId?: string | null,
  location?: Location | null,
  area?: string | null,
  title?: string | null,
  description?: string | null,
  pinToTop?: boolean | null,
  city?: string | null,
  featuredUntil?: string | null,
  coverage?: string | null,
  country?: string | null,
  days_selected?: Array< number | null > | null,
  startDate?: string | null,
  budget?: number | null,
  budget_left?: number | null,
  paid?: number | null,
  viewCount?: number | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  status?: string | null,
  ttl?: number | null,
  event?: Event | null,
  entity?: Entity | null,
};

export type UpdateFeaturedPlaceInput = {
  id: string,
  entityId?: string | null,
  eventId?: string | null,
  location?: LocationInput | null,
  area?: string | null,
  title?: string | null,
  description?: string | null,
  pinToTop?: boolean | null,
  city?: string | null,
  featuredUntil?: string | null,
  coverage?: string | null,
  country?: string | null,
  days_selected?: Array< number | null > | null,
  startDate?: string | null,
  budget?: number | null,
  budget_left?: number | null,
  paid?: number | null,
  viewCount?: number | null,
  owner?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  status?: string | null,
  ttl?: number | null,
};

export type DeleteFeaturedPlaceInput = {
  id: string,
};

export type CreateBookingsInput = {
  id?: string | null,
  userName: string,
  userId: string,
  publisher?: string | null,
  bookingType?: string | null,
  entityId: string,
  venueNotes?: string | null,
  durationInMinutes?: number | null,
  entityTitle?: string | null,
  userEmail?: string | null,
  userPhone?: string | null,
  confirmed?: boolean | null,
  owners: Array< string | null >,
  owner?: string | null,
  dateTime: string,
  tableId: string,
  timeString?: string | null,
  provider?: BookingSystemProvider | null,
  dateString?: string | null,
  dateTimestamp: number,
  people: number,
  artistId?: string | null,
  notes?: string | null,
  status: string,
  ttl?: number | null,
  paymentStatus?: string | null,
  artistBookingNotes?: ArtistBookingNotesInput | null,
  createdAt: number,
  updatedAt: number,
};

export type ArtistBookingNotesInput = {
  isEquiptmentProvided?: boolean | null,
  isBirthday?: boolean | null,
  isBrandEvent?: boolean | null,
  BrandDetails?: string | null,
  genresRequired?: string | null,
  equiptmentRequired?: string | null,
};

export type ModelBookingsConditionInput = {
  userName?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  publisher?: ModelStringInput | null,
  bookingType?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  venueNotes?: ModelStringInput | null,
  durationInMinutes?: ModelIntInput | null,
  entityTitle?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  userPhone?: ModelStringInput | null,
  confirmed?: ModelBooleanInput | null,
  dateTime?: ModelStringInput | null,
  tableId?: ModelIDInput | null,
  timeString?: ModelStringInput | null,
  provider?: ModelBookingSystemProviderInput | null,
  dateString?: ModelStringInput | null,
  dateTimestamp?: ModelFloatInput | null,
  people?: ModelIntInput | null,
  artistId?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  status?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  paymentStatus?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelBookingsConditionInput | null > | null,
  or?: Array< ModelBookingsConditionInput | null > | null,
  not?: ModelBookingsConditionInput | null,
};

export type ModelBookingSystemProviderInput = {
  eq?: BookingSystemProvider | null,
  ne?: BookingSystemProvider | null,
};

export type UpdateBookingsInput = {
  id: string,
  userName?: string | null,
  userId?: string | null,
  publisher?: string | null,
  bookingType?: string | null,
  entityId?: string | null,
  venueNotes?: string | null,
  durationInMinutes?: number | null,
  entityTitle?: string | null,
  userEmail?: string | null,
  userPhone?: string | null,
  confirmed?: boolean | null,
  owners?: Array< string | null > | null,
  owner?: string | null,
  dateTime?: string | null,
  tableId?: string | null,
  timeString?: string | null,
  provider?: BookingSystemProvider | null,
  dateString?: string | null,
  dateTimestamp?: number | null,
  people?: number | null,
  artistId?: string | null,
  notes?: string | null,
  status?: string | null,
  ttl?: number | null,
  paymentStatus?: string | null,
  artistBookingNotes?: ArtistBookingNotesInput | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteBookingsInput = {
  id: string,
};

export type CreatePodcastInput = {
  id?: string | null,
  entityId: string,
  status?: string | null,
  embedType?: string | null,
  embedId?: string | null,
  embedUrl?: string | null,
  title?: string | null,
  description?: string | null,
  images?: Array< string | null > | null,
  username?: string | null,
  categories?: Array< string | null > | null,
  tags?: Array< string | null > | null,
  guests?: Array< PodcastGuestInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type PodcastGuestInput = {
  name?: string | null,
  description?: string | null,
  url?: string | null,
  socials?: SocialItemsInput | null,
  details?: string | null,
  image?: string | null,
};

export type ModelPodcastConditionInput = {
  entityId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  embedType?: ModelStringInput | null,
  embedId?: ModelStringInput | null,
  embedUrl?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  images?: ModelStringInput | null,
  username?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPodcastConditionInput | null > | null,
  or?: Array< ModelPodcastConditionInput | null > | null,
  not?: ModelPodcastConditionInput | null,
};

export type Podcast = {
  __typename: "Podcast",
  id: string,
  entityId: string,
  status?: string | null,
  embedType?: string | null,
  embedId?: string | null,
  embedUrl?: string | null,
  title?: string | null,
  description?: string | null,
  images?: Array< string | null > | null,
  username?: string | null,
  categories?: Array< string | null > | null,
  tags?: Array< string | null > | null,
  guests?:  Array<PodcastGuest | null > | null,
  createdAt: string,
  updatedAt: string,
  entity?: Entity | null,
};

export type PodcastGuest = {
  __typename: "PodcastGuest",
  name?: string | null,
  description?: string | null,
  url?: string | null,
  socials?: SocialItems | null,
  details?: string | null,
  image?: string | null,
};

export type UpdatePodcastInput = {
  id: string,
  entityId?: string | null,
  status?: string | null,
  embedType?: string | null,
  embedId?: string | null,
  embedUrl?: string | null,
  title?: string | null,
  description?: string | null,
  images?: Array< string | null > | null,
  username?: string | null,
  categories?: Array< string | null > | null,
  tags?: Array< string | null > | null,
  guests?: Array< PodcastGuestInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeletePodcastInput = {
  id: string,
};

export type CreatePublisherInput = {
  id?: string | null,
  cognitoUsername?: string | null,
  financeEmail?: string | null,
  role?: string | null,
  profilePicture?: string | null,
  bio?: string | null,
  config?: PublisherConfigInput | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  publisherType: PublisherType,
  editors?: Array< string | null > | null,
  owner: string,
  username?: string | null,
  currency?: CurrencyInput | null,
  country?: CountryInput | null,
  pushTimestamps?: Array< number | null > | null,
  emailsDisabled?: boolean | null,
  address?: AddressInput | null,
  timezone?: string | null,
  subscibedToEmails?: boolean | null,
  timezoneOffset?: number | null,
  logo?: string | null,
  businessName?: string | null,
  description?: string | null,
  websiteUrl?: string | null,
  whatsappNumber?: string | null,
  phone?: string | null,
  socials?: SocialItemsInput | null,
  canMessage?: boolean | null,
  subscriberCount?: number | null,
  loyaltyCount?: number | null,
  status?: string | null,
  termsVersionSigned?: number | null,
  activated?: string | null,
  reviewsDisabled?: boolean | null,
  reviewRating?: number | null,
  reviewCount?: number | null,
  activationDate?: string | null,
  stripeConnectId?: string | null,
  contacts?: Array< ContactInfoInput | null > | null,
  stripeCustomerId?: string | null,
  fidelId?: string | null,
  stripeSubscriptions?: Array< StripeCustomerSubscriptionInput | null > | null,
  fidelConfig?: FidelConfigInput | null,
  createdAt: number,
  updatedAt: number,
};

export type PublisherConfigInput = {
  features?: ConfigFeaturesInput | null,
  type?: PublisherType | null,
  isBespoke?: boolean | null,
  standAloneVenue?: boolean | null,
  notificationsEnabled?: boolean | null,
  mailMarketingProvider?: MailMarketingProvider | null,
  paymentProvier?: PaymentProvider | null,
  POSProviders?: POSProvider | null,
  eventsConfig?: ConfigEventsInput | null,
  bookingsConfig?: ConfigBookingsInput | null,
  offersConfig?: ConfigOffersInput | null,
  subscribersConfig?: ConfigSubscribersInput | null,
  loyaltyConfig?: ConfigLoyaltyInput | null,
  menuConfig?: MenuConfigInput | null,
  colourScheme?: EntityColourSchemeInput | null,
};

export type ConfigFeaturesInput = {
  dashboard?: boolean | null,
  events?: boolean | null,
  places?: boolean | null,
  loyalty?: boolean | null,
  messaging?: boolean | null,
  offers?: boolean | null,
  promos?: boolean | null,
  subscribers?: boolean | null,
  updates?: boolean | null,
  reviews?: boolean | null,
  bookings?: boolean | null,
};

export type ConfigEventsInput = {
  usePaymentProvider?: boolean | null,
  tablesEnabled?: boolean | null,
  tablesConfig?: ConfigTablesInput | null,
};

export type ConfigTablesInput = {
  emailsDisabled?: boolean | null,
  integratePOS?: boolean | null,
};

export type ConfigBookingsInput = {
  bookingProvider?: BookingSystemProvider | null,
  bookingTypes?: Array< ConfigBookingTypeInput | null > | null,
  integratePOS?: boolean | null,
  emailsDisabled?: boolean | null,
};

export type ConfigOffersInput = {
  integratePOS?: boolean | null,
};

export type ConfigSubscribersInput = {
  isPaid?: boolean | null,
  subscriptionLength?: number | null,
};

export type ConfigLoyaltyInput = {
  integratePOS?: boolean | null,
  loyaltyEnabled?: boolean | null,
  tiers?: Array< LoyaltyTierInput | null > | null,
  color?: string | null,
  textColor?: string | null,
  loyaltyPeriodInDays?: number | null,
  logo?: string | null,
  listLogo?: string | null,
  cardLogo?: string | null,
  tiersEnabled?: boolean | null,
  type?: LoyaltySystemType | null,
  businessShortName?: string | null,
  loyaltyPointCurrencyValue?: number | null,
  classes?: string | null,
  securityCode?: number | null,
  disabledAutoTierCardColours?: boolean | null,
  useMainLogo?: boolean | null,
  textPosition?: string | null,
  customCardDesignUrl?: string | null,
  text?: string | null,
};

export type LoyaltyTierInput = {
  id: string,
  title?: string | null,
  desc?: string | null,
  pointsRequired?: number | null,
  color?: string | null,
  benefits?: Array< string | null > | null,
  minSpend?: number | null,
  discount?: number | null,
  customerTypeId?: number | null,
  birthdayGift?: string | null,
  logo?: string | null,
  disabledAutoTierCardColours?: boolean | null,
  loyaltyPointsBonusPercentage?: number | null,
  images?: Array< string | null > | null,
  icon?: string | null,
  isPrivate?: boolean | null,
};

export type MenuConfigInput = {
  integratePOS?: boolean | null,
};

export type CountryInput = {
  currencies?: Array< CurrencyInput | null > | null,
  flag?: string | null,
  name?: string | null,
  alpha2Code?: string | null,
  alpha3Code?: string | null,
  callingCodes?: Array< string | null > | null,
  timezones?: Array< string | null > | null,
  region?: string | null,
};

export type StripeCustomerSubscriptionInput = {
  id?: string | null,
  current_period_end?: number | null,
  current_period_start?: number | null,
  quantity?: number | null,
  entitiesIncluded?: Array< string | null > | null,
  items?: Array< StripeCustomerSubscriptionItemInput | null > | null,
  latest_invoice?: string | null,
  status?: string | null,
  created?: number | null,
};

export type StripeCustomerSubscriptionItemInput = {
  id?: string | null,
  priceId?: string | null,
  quantity?: number | null,
};

export type ModelPublisherConditionInput = {
  cognitoUsername?: ModelStringInput | null,
  financeEmail?: ModelStringInput | null,
  role?: ModelStringInput | null,
  profilePicture?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  publisherType?: ModelPublisherTypeInput | null,
  username?: ModelStringInput | null,
  pushTimestamps?: ModelFloatInput | null,
  emailsDisabled?: ModelBooleanInput | null,
  timezone?: ModelStringInput | null,
  subscibedToEmails?: ModelBooleanInput | null,
  timezoneOffset?: ModelIntInput | null,
  logo?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  websiteUrl?: ModelStringInput | null,
  whatsappNumber?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  canMessage?: ModelBooleanInput | null,
  subscriberCount?: ModelFloatInput | null,
  loyaltyCount?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  termsVersionSigned?: ModelFloatInput | null,
  activated?: ModelStringInput | null,
  reviewsDisabled?: ModelBooleanInput | null,
  reviewRating?: ModelFloatInput | null,
  reviewCount?: ModelFloatInput | null,
  activationDate?: ModelStringInput | null,
  stripeConnectId?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  fidelId?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelPublisherConditionInput | null > | null,
  or?: Array< ModelPublisherConditionInput | null > | null,
  not?: ModelPublisherConditionInput | null,
};

export type ModelPublisherTypeInput = {
  eq?: PublisherType | null,
  ne?: PublisherType | null,
};

export type UpdatePublisherInput = {
  id: string,
  cognitoUsername?: string | null,
  financeEmail?: string | null,
  role?: string | null,
  profilePicture?: string | null,
  bio?: string | null,
  config?: PublisherConfigInput | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  publisherType?: PublisherType | null,
  editors?: Array< string | null > | null,
  owner?: string | null,
  username?: string | null,
  currency?: CurrencyInput | null,
  country?: CountryInput | null,
  pushTimestamps?: Array< number | null > | null,
  emailsDisabled?: boolean | null,
  address?: AddressInput | null,
  timezone?: string | null,
  subscibedToEmails?: boolean | null,
  timezoneOffset?: number | null,
  logo?: string | null,
  businessName?: string | null,
  description?: string | null,
  websiteUrl?: string | null,
  whatsappNumber?: string | null,
  phone?: string | null,
  socials?: SocialItemsInput | null,
  canMessage?: boolean | null,
  subscriberCount?: number | null,
  loyaltyCount?: number | null,
  status?: string | null,
  termsVersionSigned?: number | null,
  activated?: string | null,
  reviewsDisabled?: boolean | null,
  reviewRating?: number | null,
  reviewCount?: number | null,
  activationDate?: string | null,
  stripeConnectId?: string | null,
  contacts?: Array< ContactInfoInput | null > | null,
  stripeCustomerId?: string | null,
  fidelId?: string | null,
  stripeSubscriptions?: Array< StripeCustomerSubscriptionInput | null > | null,
  fidelConfig?: FidelConfigInput | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeletePublisherInput = {
  id: string,
};

export type CreateScheduleQueueInput = {
  id?: string | null,
  type?: string | null,
  due?: string | null,
  action?: string | null,
  Payload?: string | null,
};

export type ModelScheduleQueueConditionInput = {
  type?: ModelStringInput | null,
  due?: ModelStringInput | null,
  action?: ModelStringInput | null,
  Payload?: ModelStringInput | null,
  and?: Array< ModelScheduleQueueConditionInput | null > | null,
  or?: Array< ModelScheduleQueueConditionInput | null > | null,
  not?: ModelScheduleQueueConditionInput | null,
};

export type ScheduleQueue = {
  __typename: "ScheduleQueue",
  id: string,
  type?: string | null,
  due?: string | null,
  action?: string | null,
  Payload?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateScheduleQueueInput = {
  id: string,
  type?: string | null,
  due?: string | null,
  action?: string | null,
  Payload?: string | null,
};

export type DeleteScheduleQueueInput = {
  id: string,
};

export type CreateStripePaymentInput = {
  id?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  type?: string | null,
  owner?: string | null,
  amount?: string | null,
  currency?: string | null,
  receipt_url?: string | null,
  invoice_url?: string | null,
  invoice_id?: string | null,
  additionalInvoiceItems?: Array< string | null > | null,
  status?: string | null,
  intentId?: string | null,
  userId?: string | null,
  publisherId?: string | null,
  productId?: string | null,
  metaData?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelStripePaymentConditionInput = {
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  type?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  receipt_url?: ModelStringInput | null,
  invoice_url?: ModelStringInput | null,
  invoice_id?: ModelStringInput | null,
  additionalInvoiceItems?: ModelStringInput | null,
  status?: ModelStringInput | null,
  intentId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  publisherId?: ModelStringInput | null,
  productId?: ModelStringInput | null,
  metaData?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStripePaymentConditionInput | null > | null,
  or?: Array< ModelStripePaymentConditionInput | null > | null,
  not?: ModelStripePaymentConditionInput | null,
};

export type StripePayment = {
  __typename: "StripePayment",
  id: string,
  startDate?: string | null,
  endDate?: string | null,
  type?: string | null,
  owner?: string | null,
  amount?: string | null,
  currency?: string | null,
  receipt_url?: string | null,
  invoice_url?: string | null,
  invoice_id?: string | null,
  additionalInvoiceItems?: Array< string | null > | null,
  status?: string | null,
  intentId?: string | null,
  userId?: string | null,
  publisherId?: string | null,
  productId?: string | null,
  metaData?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateStripePaymentInput = {
  id: string,
  startDate?: string | null,
  endDate?: string | null,
  type?: string | null,
  owner?: string | null,
  amount?: string | null,
  currency?: string | null,
  receipt_url?: string | null,
  invoice_url?: string | null,
  invoice_id?: string | null,
  additionalInvoiceItems?: Array< string | null > | null,
  status?: string | null,
  intentId?: string | null,
  userId?: string | null,
  publisherId?: string | null,
  productId?: string | null,
  metaData?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteStripePaymentInput = {
  id: string,
};

export type CreateCategoriesInput = {
  id?: string | null,
  title: string,
  type?: string | null,
  icon?: string | null,
  desc?: string | null,
  pingEditTitle?: string | null,
  metaTitle?: string | null,
  metaDescription?: string | null,
  priority?: number | null,
  showcaseImage?: string | null,
  categoryCityCount?: string | null,
  isHidden?: boolean | null,
  citiesAvailable?: Array< string | null > | null,
  translateString?: string | null,
  colour: string,
  image: string,
  subCategories?: Array< CategoryInput | null > | null,
};

export type CategoryInput = {
  title: string,
  image: string,
  isAdmin?: boolean | null,
  desc?: string | null,
  showcaseImage?: string | null,
  translateString?: string | null,
  pingEditTitle?: string | null,
  metaTitle?: string | null,
  metaDescription?: string | null,
  categoryCityCount?: string | null,
  isHidden?: boolean | null,
  citiesAvailable?: Array< string | null > | null,
  icon?: string | null,
  colour?: string | null,
};

export type ModelCategoriesConditionInput = {
  title?: ModelStringInput | null,
  type?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  desc?: ModelStringInput | null,
  pingEditTitle?: ModelStringInput | null,
  metaTitle?: ModelStringInput | null,
  metaDescription?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  showcaseImage?: ModelStringInput | null,
  categoryCityCount?: ModelStringInput | null,
  isHidden?: ModelBooleanInput | null,
  citiesAvailable?: ModelStringInput | null,
  translateString?: ModelStringInput | null,
  colour?: ModelStringInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelCategoriesConditionInput | null > | null,
  or?: Array< ModelCategoriesConditionInput | null > | null,
  not?: ModelCategoriesConditionInput | null,
};

export type Categories = {
  __typename: "Categories",
  id: string,
  title: string,
  type?: string | null,
  icon?: string | null,
  desc?: string | null,
  pingEditTitle?: string | null,
  metaTitle?: string | null,
  metaDescription?: string | null,
  priority?: number | null,
  showcaseImage?: string | null,
  categoryCityCount?: string | null,
  isHidden?: boolean | null,
  citiesAvailable?: Array< string | null > | null,
  translateString?: string | null,
  colour: string,
  image: string,
  subCategories?:  Array<Category | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type Category = {
  __typename: "Category",
  title: string,
  image: string,
  isAdmin?: boolean | null,
  desc?: string | null,
  showcaseImage?: string | null,
  translateString?: string | null,
  pingEditTitle?: string | null,
  metaTitle?: string | null,
  metaDescription?: string | null,
  categoryCityCount?: string | null,
  isHidden?: boolean | null,
  citiesAvailable?: Array< string | null > | null,
  icon?: string | null,
  colour?: string | null,
};

export type UpdateCategoriesInput = {
  id: string,
  title?: string | null,
  type?: string | null,
  icon?: string | null,
  desc?: string | null,
  pingEditTitle?: string | null,
  metaTitle?: string | null,
  metaDescription?: string | null,
  priority?: number | null,
  showcaseImage?: string | null,
  categoryCityCount?: string | null,
  isHidden?: boolean | null,
  citiesAvailable?: Array< string | null > | null,
  translateString?: string | null,
  colour?: string | null,
  image?: string | null,
  subCategories?: Array< CategoryInput | null > | null,
};

export type DeleteCategoriesInput = {
  id: string,
};

export type CreateVenueCategoryTopicsInput = {
  id?: string | null,
  title?: string | null,
  subtitle?: string | null,
  image?: string | null,
  URL?: string | null,
  type?: string | null,
  itemId?: string | null,
  content?: string | null,
  pinToTop?: boolean | null,
  tag?: string | null,
  category?: string | null,
  subCategory?: string | null,
  price?: number | null,
  omittedCities?: Array< string | null > | null,
  allowedCities?: Array< string | null > | null,
  orderBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelVenueCategoryTopicsConditionInput = {
  title?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  image?: ModelStringInput | null,
  URL?: ModelStringInput | null,
  type?: ModelStringInput | null,
  itemId?: ModelStringInput | null,
  content?: ModelStringInput | null,
  pinToTop?: ModelBooleanInput | null,
  tag?: ModelStringInput | null,
  category?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  omittedCities?: ModelStringInput | null,
  allowedCities?: ModelStringInput | null,
  orderBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVenueCategoryTopicsConditionInput | null > | null,
  or?: Array< ModelVenueCategoryTopicsConditionInput | null > | null,
  not?: ModelVenueCategoryTopicsConditionInput | null,
};

export type VenueCategoryTopics = {
  __typename: "VenueCategoryTopics",
  id: string,
  title?: string | null,
  subtitle?: string | null,
  image?: string | null,
  URL?: string | null,
  type?: string | null,
  itemId?: string | null,
  content?: string | null,
  pinToTop?: boolean | null,
  tag?: string | null,
  category?: string | null,
  subCategory?: string | null,
  price?: number | null,
  omittedCities?: Array< string | null > | null,
  allowedCities?: Array< string | null > | null,
  orderBy?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVenueCategoryTopicsInput = {
  id: string,
  title?: string | null,
  subtitle?: string | null,
  image?: string | null,
  URL?: string | null,
  type?: string | null,
  itemId?: string | null,
  content?: string | null,
  pinToTop?: boolean | null,
  tag?: string | null,
  category?: string | null,
  subCategory?: string | null,
  price?: number | null,
  omittedCities?: Array< string | null > | null,
  allowedCities?: Array< string | null > | null,
  orderBy?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteVenueCategoryTopicsInput = {
  id: string,
};

export type CreateCountryDataInput = {
  id: string,
  currencies?: Array< CurrencyInput | null > | null,
  flag?: string | null,
  name?: string | null,
  alpha2Code?: string | null,
  alpha3Code?: string | null,
  callingCodes?: Array< string | null > | null,
  timezones?: Array< string | null > | null,
  region?: string | null,
};

export type ModelCountryDataConditionInput = {
  flag?: ModelStringInput | null,
  name?: ModelStringInput | null,
  alpha2Code?: ModelStringInput | null,
  alpha3Code?: ModelStringInput | null,
  callingCodes?: ModelStringInput | null,
  timezones?: ModelStringInput | null,
  region?: ModelStringInput | null,
  and?: Array< ModelCountryDataConditionInput | null > | null,
  or?: Array< ModelCountryDataConditionInput | null > | null,
  not?: ModelCountryDataConditionInput | null,
};

export type CountryData = {
  __typename: "CountryData",
  id: string,
  currencies?:  Array<Currency | null > | null,
  flag?: string | null,
  name?: string | null,
  alpha2Code?: string | null,
  alpha3Code?: string | null,
  callingCodes?: Array< string | null > | null,
  timezones?: Array< string | null > | null,
  region?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCountryDataInput = {
  id: string,
  currencies?: Array< CurrencyInput | null > | null,
  flag?: string | null,
  name?: string | null,
  alpha2Code?: string | null,
  alpha3Code?: string | null,
  callingCodes?: Array< string | null > | null,
  timezones?: Array< string | null > | null,
  region?: string | null,
};

export type DeleteCountryDataInput = {
  id: string,
};

export type CreatePingConfigsInput = {
  id?: string | null,
  type?: string | null,
  token?: string | null,
  data?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelPingConfigsConditionInput = {
  type?: ModelStringInput | null,
  token?: ModelStringInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPingConfigsConditionInput | null > | null,
  or?: Array< ModelPingConfigsConditionInput | null > | null,
  not?: ModelPingConfigsConditionInput | null,
};

export type PingConfigs = {
  __typename: "PingConfigs",
  id: string,
  type?: string | null,
  token?: string | null,
  data?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdatePingConfigsInput = {
  id: string,
  type?: string | null,
  token?: string | null,
  data?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeletePingConfigsInput = {
  id: string,
};

export type CreateUserReportInput = {
  id?: string | null,
  userId: string,
  message?: string | null,
  targetId?: string | null,
  targetType?: string | null,
  createdAt: number,
  updatedAt: number,
};

export type ModelUserReportConditionInput = {
  userId?: ModelStringInput | null,
  message?: ModelStringInput | null,
  targetId?: ModelIDInput | null,
  targetType?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelUserReportConditionInput | null > | null,
  or?: Array< ModelUserReportConditionInput | null > | null,
  not?: ModelUserReportConditionInput | null,
};

export type UserReport = {
  __typename: "UserReport",
  id: string,
  userId: string,
  message?: string | null,
  targetId?: string | null,
  targetType?: string | null,
  createdAt: number,
  updatedAt: number,
};

export type UpdateUserReportInput = {
  id: string,
  userId?: string | null,
  message?: string | null,
  targetId?: string | null,
  targetType?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteUserReportInput = {
  id: string,
};

export type UpdateFeedbackInput = {
  id: string,
  userId?: string | null,
  ttl?: number | null,
  email?: string | null,
  name?: string | null,
  feedback?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type ModelFeedbackConditionInput = {
  userId?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  feedback?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelFeedbackConditionInput | null > | null,
  or?: Array< ModelFeedbackConditionInput | null > | null,
  not?: ModelFeedbackConditionInput | null,
};

export type Feedback = {
  __typename: "Feedback",
  id: string,
  userId?: string | null,
  ttl?: number | null,
  email?: string | null,
  name?: string | null,
  feedback?: string | null,
  createdAt: number,
  updatedAt: number,
  user?: User | null,
};

export type DeleteFeedbackInput = {
  id: string,
};

export type CreateVenueTableInput = {
  id?: string | null,
  capacity?: number | null,
  quantityAvailable?: number | null,
  tablesBooked?: number | null,
  price?: number | null,
  includes?: Array< TableIncludesInput | null > | null,
  disclaimer?: string | null,
  instructions?: string | null,
  parentType?: TableParentType | null,
  entityId: string,
  eventId: string,
  repeatEventId: string,
  status?: string | null,
  title?: string | null,
  owner: string,
  createdAt: number,
  updatedAt: number,
};

export type TableIncludesInput = {
  quantity?: number | null,
  title?: string | null,
  description?: string | null,
};

export type ModelVenueTableConditionInput = {
  capacity?: ModelIntInput | null,
  quantityAvailable?: ModelIntInput | null,
  tablesBooked?: ModelIntInput | null,
  price?: ModelFloatInput | null,
  disclaimer?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  parentType?: ModelTableParentTypeInput | null,
  entityId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  title?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelVenueTableConditionInput | null > | null,
  or?: Array< ModelVenueTableConditionInput | null > | null,
  not?: ModelVenueTableConditionInput | null,
};

export type ModelTableParentTypeInput = {
  eq?: TableParentType | null,
  ne?: TableParentType | null,
};

export type UpdateVenueTableInput = {
  id: string,
  capacity?: number | null,
  quantityAvailable?: number | null,
  tablesBooked?: number | null,
  price?: number | null,
  includes?: Array< TableIncludesInput | null > | null,
  disclaimer?: string | null,
  instructions?: string | null,
  parentType?: TableParentType | null,
  entityId?: string | null,
  eventId?: string | null,
  repeatEventId?: string | null,
  status?: string | null,
  title?: string | null,
  owner?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteVenueTableInput = {
  id: string,
};

export type CreateTableBookingRequestInput = {
  id?: string | null,
  userName?: string | null,
  userId: string,
  paid?: boolean | null,
  amountPaid?: number | null,
  eventId: string,
  status?: string | null,
  owners?: Array< string | null > | null,
  tableId: string,
  message?: string | null,
  createdAt: number,
  updatedAt: number,
  tableBookingRequestTableId?: string | null,
};

export type ModelTableBookingRequestConditionInput = {
  userName?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  paid?: ModelBooleanInput | null,
  amountPaid?: ModelFloatInput | null,
  eventId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  tableId?: ModelIDInput | null,
  message?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelTableBookingRequestConditionInput | null > | null,
  or?: Array< ModelTableBookingRequestConditionInput | null > | null,
  not?: ModelTableBookingRequestConditionInput | null,
};

export type UpdateTableBookingRequestInput = {
  id: string,
  userName?: string | null,
  userId?: string | null,
  paid?: boolean | null,
  amountPaid?: number | null,
  eventId?: string | null,
  status?: string | null,
  owners?: Array< string | null > | null,
  tableId?: string | null,
  message?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
  tableBookingRequestTableId?: string | null,
};

export type DeleteTableBookingRequestInput = {
  id: string,
};

export type CreateTicketInput = {
  id?: string | null,
  userId: string,
  bookingReference: string,
  eventId: string,
  repeatEventId: string,
  repeatEventOccurencId: string,
  eventType?: string | null,
  eventStartTimestamp?: number | null,
  price?: TicketPriceInput | null,
  chargeId?: string | null,
  paymentIntentId?: string | null,
  content?: string | null,
  ticketActivasted?: string | null,
  userName?: string | null,
  additionalOptions?: Array< TicketChosenOptionInput | null > | null,
  tier?: string | null,
  variation?: string | null,
  owners?: Array< string | null > | null,
  status?: string | null,
  ttl?: number | null,
  test?: string | null,
  refunded?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type TicketChosenOptionInput = {
  title?: string | null,
  chosenOption?: string | null,
};

export type ModelTicketConditionInput = {
  userId?: ModelIDInput | null,
  bookingReference?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  repeatEventOccurencId?: ModelIDInput | null,
  eventType?: ModelStringInput | null,
  eventStartTimestamp?: ModelFloatInput | null,
  chargeId?: ModelStringInput | null,
  paymentIntentId?: ModelStringInput | null,
  content?: ModelStringInput | null,
  ticketActivasted?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  tier?: ModelStringInput | null,
  variation?: ModelStringInput | null,
  status?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  test?: ModelStringInput | null,
  refunded?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTicketConditionInput | null > | null,
  or?: Array< ModelTicketConditionInput | null > | null,
  not?: ModelTicketConditionInput | null,
};

export type UpdateTicketInput = {
  id: string,
  userId?: string | null,
  bookingReference?: string | null,
  eventId?: string | null,
  repeatEventId?: string | null,
  repeatEventOccurencId?: string | null,
  eventType?: string | null,
  eventStartTimestamp?: number | null,
  price?: TicketPriceInput | null,
  chargeId?: string | null,
  paymentIntentId?: string | null,
  content?: string | null,
  ticketActivasted?: string | null,
  userName?: string | null,
  additionalOptions?: Array< TicketChosenOptionInput | null > | null,
  tier?: string | null,
  variation?: string | null,
  owners?: Array< string | null > | null,
  status?: string | null,
  ttl?: number | null,
  test?: string | null,
  refunded?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteTicketInput = {
  id: string,
};

export type CreateTicketVariationOccurenceInput = {
  id?: string | null,
  eventId?: string | null,
  repeatEventId?: string | null,
  repeatEventOccurenceId?: string | null,
  eventType?: string | null,
  variationRuleId: string,
  isSoldOut?: boolean | null,
  amountSoldCounter?: number | null,
  owners?: Array< string | null > | null,
  ttl?: number | null,
};

export type ModelTicketVariationOccurenceConditionInput = {
  eventId?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  repeatEventOccurenceId?: ModelIDInput | null,
  eventType?: ModelStringInput | null,
  variationRuleId?: ModelStringInput | null,
  isSoldOut?: ModelBooleanInput | null,
  amountSoldCounter?: ModelFloatInput | null,
  owners?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  and?: Array< ModelTicketVariationOccurenceConditionInput | null > | null,
  or?: Array< ModelTicketVariationOccurenceConditionInput | null > | null,
  not?: ModelTicketVariationOccurenceConditionInput | null,
};

export type UpdateTicketVariationOccurenceInput = {
  id: string,
  eventId?: string | null,
  repeatEventId?: string | null,
  repeatEventOccurenceId?: string | null,
  eventType?: string | null,
  variationRuleId?: string | null,
  isSoldOut?: boolean | null,
  amountSoldCounter?: number | null,
  owners?: Array< string | null > | null,
  ttl?: number | null,
};

export type DeleteTicketVariationOccurenceInput = {
  id: string,
};

export type CreateUpdateInput = {
  id?: string | null,
  title: string,
  content: string,
  parentId: string,
  parentTitle?: string | null,
  isPinned?: boolean | null,
  metadata?: string | null,
  actionCount?: number | null,
  viewCount?: number | null,
  url?: string | null,
  images?: Array< string | null > | null,
  route?: Array< string | null > | null,
  extra?: string | null,
  type?: string | null,
  updatedAt?: number | null,
  createdAt?: number | null,
};

export type ModelUpdateConditionInput = {
  title?: ModelStringInput | null,
  content?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  parentTitle?: ModelStringInput | null,
  isPinned?: ModelBooleanInput | null,
  metadata?: ModelStringInput | null,
  actionCount?: ModelFloatInput | null,
  viewCount?: ModelFloatInput | null,
  url?: ModelStringInput | null,
  images?: ModelStringInput | null,
  route?: ModelStringInput | null,
  extra?: ModelStringInput | null,
  type?: ModelStringInput | null,
  updatedAt?: ModelFloatInput | null,
  createdAt?: ModelFloatInput | null,
  and?: Array< ModelUpdateConditionInput | null > | null,
  or?: Array< ModelUpdateConditionInput | null > | null,
  not?: ModelUpdateConditionInput | null,
};

export type Update = {
  __typename: "Update",
  id: string,
  title: string,
  content: string,
  parentId: string,
  parentTitle?: string | null,
  isPinned?: boolean | null,
  metadata?: string | null,
  actionCount?: number | null,
  viewCount?: number | null,
  url?: string | null,
  images?: Array< string | null > | null,
  route?: Array< string | null > | null,
  extra?: string | null,
  type?: string | null,
  updatedAt?: number | null,
  createdAt?: number | null,
};

export type UpdateUpdateInput = {
  id: string,
  title?: string | null,
  content?: string | null,
  parentId?: string | null,
  parentTitle?: string | null,
  isPinned?: boolean | null,
  metadata?: string | null,
  actionCount?: number | null,
  viewCount?: number | null,
  url?: string | null,
  images?: Array< string | null > | null,
  route?: Array< string | null > | null,
  extra?: string | null,
  type?: string | null,
  updatedAt?: number | null,
  createdAt?: number | null,
};

export type DeleteUpdateInput = {
  id: string,
};

export type CreatePlaceCollectionInput = {
  id?: string | null,
  userId: string,
  title?: string | null,
  description?: string | null,
  area?: string | null,
  saved?: number | null,
  shared?: number | null,
  likes?: number | null,
  venueCount?: number | null,
  headerImg?: string | null,
  videos?: Array< VideoDataInput | null > | null,
  views?: number | null,
  lockCriteria?: string | null,
  score?: number | null,
  areas?: Array< string | null > | null,
  location?: LocationInput | null,
  username?: string | null,
  city?: string | null,
  cities?: Array< string | null > | null,
  status?: string | null,
  isPublic?: string | null,
  dateTime?: string | null,
  securityCode?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelPlaceCollectionConditionInput = {
  userId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  area?: ModelStringInput | null,
  saved?: ModelFloatInput | null,
  shared?: ModelFloatInput | null,
  likes?: ModelFloatInput | null,
  venueCount?: ModelFloatInput | null,
  headerImg?: ModelStringInput | null,
  views?: ModelFloatInput | null,
  lockCriteria?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  areas?: ModelStringInput | null,
  username?: ModelStringInput | null,
  city?: ModelStringInput | null,
  cities?: ModelStringInput | null,
  status?: ModelStringInput | null,
  isPublic?: ModelStringInput | null,
  dateTime?: ModelStringInput | null,
  securityCode?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPlaceCollectionConditionInput | null > | null,
  or?: Array< ModelPlaceCollectionConditionInput | null > | null,
  not?: ModelPlaceCollectionConditionInput | null,
};

export type UpdatePlaceCollectionInput = {
  id: string,
  userId?: string | null,
  title?: string | null,
  description?: string | null,
  area?: string | null,
  saved?: number | null,
  shared?: number | null,
  likes?: number | null,
  venueCount?: number | null,
  headerImg?: string | null,
  videos?: Array< VideoDataInput | null > | null,
  views?: number | null,
  lockCriteria?: string | null,
  score?: number | null,
  areas?: Array< string | null > | null,
  location?: LocationInput | null,
  username?: string | null,
  city?: string | null,
  cities?: Array< string | null > | null,
  status?: string | null,
  isPublic?: string | null,
  dateTime?: string | null,
  securityCode?: string | null,
  owners?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeletePlaceCollectionInput = {
  id: string,
};

export type CreateUserWishListLinkInput = {
  id?: string | null,
  listId: string,
  userId: string,
  remminderSet?: boolean | null,
};

export type ModelUserWishListLinkConditionInput = {
  listId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  remminderSet?: ModelBooleanInput | null,
  and?: Array< ModelUserWishListLinkConditionInput | null > | null,
  or?: Array< ModelUserWishListLinkConditionInput | null > | null,
  not?: ModelUserWishListLinkConditionInput | null,
};

export type UpdateUserWishListLinkInput = {
  id: string,
  listId?: string | null,
  userId?: string | null,
  remminderSet?: boolean | null,
};

export type DeleteUserWishListLinkInput = {
  id: string,
};

export type CreatePlaceCollectionEntityInput = {
  id?: string | null,
  entityId: string,
  listId: string,
  usersVisited?: Array< string | null > | null,
  owners?: Array< string | null > | null,
  orderIndex?: number | null,
  images?: Array< string | null > | null,
};

export type ModelPlaceCollectionEntityConditionInput = {
  entityId?: ModelIDInput | null,
  listId?: ModelIDInput | null,
  usersVisited?: ModelStringInput | null,
  orderIndex?: ModelIntInput | null,
  images?: ModelStringInput | null,
  and?: Array< ModelPlaceCollectionEntityConditionInput | null > | null,
  or?: Array< ModelPlaceCollectionEntityConditionInput | null > | null,
  not?: ModelPlaceCollectionEntityConditionInput | null,
};

export type UpdatePlaceCollectionEntityInput = {
  id: string,
  entityId?: string | null,
  listId?: string | null,
  usersVisited?: Array< string | null > | null,
  owners?: Array< string | null > | null,
  orderIndex?: number | null,
  images?: Array< string | null > | null,
};

export type DeletePlaceCollectionEntityInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  cognitoUsername?: string | null,
  role?: UserRole | null,
  appVersion?: string | null,
  activated?: string | null,
  blocked?: boolean | null,
  firstName?: string | null,
  emailSettings?: UserEmailSettingsInput | null,
  lastName?: string | null,
  currency?: CurrencyInput | null,
  placesAddedToListCount?: number | null,
  bio?: string | null,
  preferredTitle?: string | null,
  country?: CountryInput | null,
  dob?: string | null,
  linkCardRewardClaimed?: string | null,
  email?: string | null,
  stripeConnectId?: string | null,
  stripeCustomerId?: string | null,
  gender?: string | null,
  phone?: string | null,
  city?: string | null,
  allowNotifications?: boolean | null,
  allowLocation?: boolean | null,
  allowPersonalisation?: boolean | null,
  allowCalendar?: boolean | null,
  profilePicture?: string | null,
  subscibedToEmails?: boolean | null,
  pushTokens?: Array< PushTokenInput | null > | null,
  points?: number | null,
  accumulatedPoints?: number | null,
  isStudent?: boolean | null,
  lastLoggedIn?: string | null,
  locale?: string | null,
  isInfluencer?: string | null,
  summary?: string | null,
  categories?: Array< string | null > | null,
  social?: SocialItemsInput | null,
  createdAt: number,
  updatedAt: number,
  termsSigned?: boolean | null,
  termsVersionSigned?: string | null,
  EPOSId?: number | null,
  appConfigs?: string | null,
  disabled?: boolean | null,
  username?: string | null,
  linkedCards?: Array< IUserCardInput | null > | null,
};

export type UserEmailSettingsInput = {
  transactions?: boolean | null,
  offers?: boolean | null,
  loyaltyCard?: boolean | null,
  eventInvites?: boolean | null,
  marketing?: boolean | null,
};

export type PushTokenInput = {
  token?: string | null,
  device?: string | null,
  endpointArn?: string | null,
  parentApp?: string | null,
};

export type IUserCardInput = {
  scheme?: string | null,
  accountId?: string | null,
  countryCode?: string | null,
  created?: string | null,
  expYear?: number | null,
  expDate?: string | null,
  live?: boolean | null,
  lastNumbers?: string | null,
  expMonth?: number | null,
  updated?: string | null,
  programId?: string | null,
  firstNumbers?: string | null,
  id?: string | null,
  type?: string | null,
};

export type ModelUserConditionInput = {
  cognitoUsername?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  appVersion?: ModelStringInput | null,
  activated?: ModelStringInput | null,
  blocked?: ModelBooleanInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  placesAddedToListCount?: ModelFloatInput | null,
  bio?: ModelStringInput | null,
  preferredTitle?: ModelStringInput | null,
  dob?: ModelStringInput | null,
  linkCardRewardClaimed?: ModelStringInput | null,
  email?: ModelStringInput | null,
  stripeConnectId?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  city?: ModelStringInput | null,
  allowNotifications?: ModelBooleanInput | null,
  allowLocation?: ModelBooleanInput | null,
  allowPersonalisation?: ModelBooleanInput | null,
  allowCalendar?: ModelBooleanInput | null,
  profilePicture?: ModelStringInput | null,
  subscibedToEmails?: ModelBooleanInput | null,
  points?: ModelIntInput | null,
  accumulatedPoints?: ModelIntInput | null,
  isStudent?: ModelBooleanInput | null,
  lastLoggedIn?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  isInfluencer?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  termsSigned?: ModelBooleanInput | null,
  termsVersionSigned?: ModelStringInput | null,
  EPOSId?: ModelFloatInput | null,
  appConfigs?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserRoleInput = {
  eq?: UserRole | null,
  ne?: UserRole | null,
};

export type UpdateUserInput = {
  id: string,
  cognitoUsername?: string | null,
  role?: UserRole | null,
  appVersion?: string | null,
  activated?: string | null,
  blocked?: boolean | null,
  firstName?: string | null,
  emailSettings?: UserEmailSettingsInput | null,
  lastName?: string | null,
  currency?: CurrencyInput | null,
  placesAddedToListCount?: number | null,
  bio?: string | null,
  preferredTitle?: string | null,
  country?: CountryInput | null,
  dob?: string | null,
  linkCardRewardClaimed?: string | null,
  email?: string | null,
  stripeConnectId?: string | null,
  stripeCustomerId?: string | null,
  gender?: string | null,
  phone?: string | null,
  city?: string | null,
  allowNotifications?: boolean | null,
  allowLocation?: boolean | null,
  allowPersonalisation?: boolean | null,
  allowCalendar?: boolean | null,
  profilePicture?: string | null,
  subscibedToEmails?: boolean | null,
  pushTokens?: Array< PushTokenInput | null > | null,
  points?: number | null,
  accumulatedPoints?: number | null,
  isStudent?: boolean | null,
  lastLoggedIn?: string | null,
  locale?: string | null,
  isInfluencer?: string | null,
  summary?: string | null,
  categories?: Array< string | null > | null,
  social?: SocialItemsInput | null,
  createdAt?: number | null,
  updatedAt?: number | null,
  termsSigned?: boolean | null,
  termsVersionSigned?: string | null,
  EPOSId?: number | null,
  appConfigs?: string | null,
  disabled?: boolean | null,
  username?: string | null,
  linkedCards?: Array< IUserCardInput | null > | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateClaimedOfferInput = {
  id?: string | null,
  userId: string,
  offerId: string,
  fidelOfferId?: string | null,
  activated?: string | null,
  pointsCosts?: number | null,
  entityId: string,
  metadata?: string | null,
  publisherId: string,
  type?: string | null,
  ttl?: number | null,
  owners?: Array< string | null > | null,
  status: string,
  createdAt: number,
  updatedAt: number,
};

export type ModelClaimedOfferConditionInput = {
  userId?: ModelIDInput | null,
  offerId?: ModelIDInput | null,
  fidelOfferId?: ModelStringInput | null,
  activated?: ModelStringInput | null,
  pointsCosts?: ModelFloatInput | null,
  entityId?: ModelIDInput | null,
  metadata?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelClaimedOfferConditionInput | null > | null,
  or?: Array< ModelClaimedOfferConditionInput | null > | null,
  not?: ModelClaimedOfferConditionInput | null,
};

export type UpdateClaimedOfferInput = {
  id: string,
  userId?: string | null,
  offerId?: string | null,
  fidelOfferId?: string | null,
  activated?: string | null,
  pointsCosts?: number | null,
  entityId?: string | null,
  metadata?: string | null,
  publisherId?: string | null,
  type?: string | null,
  ttl?: number | null,
  owners?: Array< string | null > | null,
  status?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteClaimedOfferInput = {
  id: string,
};

export type CreateUserSavedItemInput = {
  id?: string | null,
  type: string,
  itemId: string,
  title?: string | null,
  image?: string | null,
  authorId?: string | null,
  author?: string | null,
  userId: string,
  createdAt?: string | null,
};

export type ModelUserSavedItemConditionInput = {
  type?: ModelStringInput | null,
  itemId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  image?: ModelStringInput | null,
  authorId?: ModelStringInput | null,
  author?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserSavedItemConditionInput | null > | null,
  or?: Array< ModelUserSavedItemConditionInput | null > | null,
  not?: ModelUserSavedItemConditionInput | null,
};

export type UserSavedItem = {
  __typename: "UserSavedItem",
  id: string,
  type: string,
  itemId: string,
  title?: string | null,
  image?: string | null,
  authorId?: string | null,
  author?: string | null,
  userId: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserSavedItemInput = {
  id: string,
  type?: string | null,
  itemId?: string | null,
  title?: string | null,
  image?: string | null,
  authorId?: string | null,
  author?: string | null,
  userId?: string | null,
  createdAt?: string | null,
};

export type DeleteUserSavedItemInput = {
  id: string,
};

export type CreateSubscriberInput = {
  id?: string | null,
  userId: string,
  entityId: string,
  publisherId: string,
  userFullName?: string | null,
  counters?: FollowerCountersInput | null,
  subscriptionArn?: string | null,
  subscriptionArns?: Array< string | null > | null,
  notificationsEnabled?: boolean | null,
  credit?: number | null,
  loyaltyPoints?: number | null,
  loyaltyStatus?: string | null,
  EPOSId?: string | null,
  type?: string | null,
  status: string,
  createdAt: number,
  updatedAt: number,
};

export type FollowerCountersInput = {
  bookings?: number | null,
  offersRedeemed?: number | null,
  checkins?: number | null,
};

export type ModelSubscriberConditionInput = {
  userId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  userFullName?: ModelStringInput | null,
  subscriptionArn?: ModelStringInput | null,
  subscriptionArns?: ModelStringInput | null,
  notificationsEnabled?: ModelBooleanInput | null,
  credit?: ModelFloatInput | null,
  loyaltyPoints?: ModelFloatInput | null,
  loyaltyStatus?: ModelStringInput | null,
  EPOSId?: ModelStringInput | null,
  type?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelSubscriberConditionInput | null > | null,
  or?: Array< ModelSubscriberConditionInput | null > | null,
  not?: ModelSubscriberConditionInput | null,
};

export type UpdateSubscriberInput = {
  id: string,
  userId?: string | null,
  entityId?: string | null,
  publisherId?: string | null,
  userFullName?: string | null,
  counters?: FollowerCountersInput | null,
  subscriptionArn?: string | null,
  subscriptionArns?: Array< string | null > | null,
  notificationsEnabled?: boolean | null,
  credit?: number | null,
  loyaltyPoints?: number | null,
  loyaltyStatus?: string | null,
  EPOSId?: string | null,
  type?: string | null,
  status?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type DeleteSubscriberInput = {
  id: string,
};

export type CreateTrackingActivityStatInput = {
  id?: string | null,
  action?: string | null,
  itemType?: string | null,
  itemId?: string | null,
  location?: LocationInput | null,
  gender?: string | null,
  city?: string | null,
  owners: Array< string | null >,
  ttl?: number | null,
  createdAt?: string | null,
};

export type CreateCheckInInput = {
  id?: string | null,
  userId: string,
  entityId: string,
  fullName?: string | null,
  phone?: string | null,
  partySize?: number | null,
  gender?: string | null,
  dob?: string | null,
  owners?: Array< string | null > | null,
  age?: number | null,
  email?: string | null,
  ttl?: number | null,
  status?: string | null,
  location?: LocationInput | null,
  updatedAt?: number | null,
  createdAt: number,
};

export type CreateFeedbackInput = {
  id?: string | null,
  userId?: string | null,
  ttl?: number | null,
  email?: string | null,
  name?: string | null,
  feedback?: string | null,
  createdAt: number,
  updatedAt: number,
};

export type PushPayload = {
  type: string,
  topicArn: string,
  title: string,
  body: string,
  route?: Array< string | null > | null,
  extra?: PushPayloadExtraObject | null,
};

export type PushPayloadExtraObject = {
  type?: string | null,
  id?: string | null,
  title?: string | null,
  points?: number | null,
  parentType?: string | null,
  updateId?: string | null,
};

export type UserPushTokenInput = {
  token?: string | null,
  device?: string | null,
  endpointArn?: string | null,
  parentApp?: string | null,
};

export type UserPointsInput = {
  env: string,
  userId: string,
  action: string,
};

export type AddloyaltyPointsResponse = {
  __typename: "AddloyaltyPointsResponse",
  code?: string | null,
  data?: AddloyaltyPointsResponsePayload | null,
};

export type AddloyaltyPointsResponsePayload = {
  __typename: "AddloyaltyPointsResponsePayload",
  id?: string | null,
  userId?: string | null,
  isNewSubscriber?: boolean | null,
  reward?: AddloyaltyPointsResponseRewards | null,
  publisher?: AddloyaltyPointsResponsePublisher | null,
  event?: AddloyaltyPointsResponseEvent | null,
  entity?: AddloyaltyPointsResponseEntity | null,
  totalPoints?: number | null,
  createdAt?: number | null,
  updatedAt?: number | null,
};

export type AddloyaltyPointsResponseRewards = {
  __typename: "AddloyaltyPointsResponseRewards",
  title?: string | null,
  description?: string | null,
  rewardType?: string | null,
  loyaltyPointsRequired?: number | null,
};

export type AddloyaltyPointsResponsePublisher = {
  __typename: "AddloyaltyPointsResponsePublisher",
  title?: string | null,
  logo?: string | null,
  id?: string | null,
};

export type AddloyaltyPointsResponseEvent = {
  __typename: "AddloyaltyPointsResponseEvent",
  id?: string | null,
  location?: Location | null,
  publisherId?: string | null,
  type?: string | null,
  images?: Array< string | null > | null,
  title?: string | null,
  entity?: string | null,
  city?: string | null,
};

export type AddloyaltyPointsResponseEntity = {
  __typename: "AddloyaltyPointsResponseEntity",
  id?: string | null,
  location?: Location | null,
  publisherId?: string | null,
  logo?: string | null,
  images?: Array< string | null > | null,
  title?: string | null,
  city?: string | null,
};

export type SearchCitiesInput = {
  location?: LocationInput | null,
  km?: number | null,
  limit?: number | null,
  nextToken?: number | null,
};

export type CityConnection = {
  __typename: "CityConnection",
  items?:  Array<City | null > | null,
  nextToken?: string | null,
};

export type SearchEventsInput = {
  filter?: SearchEventFilter | null,
  sort?: SearchEventsSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchEventFilter = {
  start?: string | null,
  end?: string | null,
  city?: string | null,
  area?: string | null,
  closingTime?: number | null,
  categories?: Array< string | null > | null,
  location?: LocationInput | null,
  km?: number | null,
};

export type SearchEventsSortInput = {
  field?: SearchEventsSortFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchEventsSortFields {
  id = "id",
  title = "title",
  dateTime = "dateTime",
  location = "location",
  city = "city",
  status = "status",
  startDate = "startDate",
  attending = "attending",
  endDate = "endDate",
  score = "score",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type EventConnection = {
  __typename: "EventConnection",
  items?:  Array<Event | null > | null,
  nextToken?: string | null,
};

export type SearchElasticOffersInput = {
  filter?: SearchOffersFilter | null,
  sort?: SearchOffersSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchOffersFilter = {
  city?: string | null,
  categories?: Array< string | null > | null,
  daysAvailable?: Array< number | null > | null,
  endTime?: string | null,
  price?: string | null,
  startTime?: string | null,
  dateAvailable?: string | null,
  location?: LocationInput | null,
  km?: number | null,
};

export type SearchOffersSortInput = {
  field?: SearchOffersSortFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchOffersSortFields {
  id = "id",
  city = "city",
  location = "location",
  title = "title",
  daysAvailable = "daysAvailable",
  endTime = "endTime",
  price = "price",
  startTime = "startTime",
  dateAvailable = "dateAvailable",
  pointsRequired = "pointsRequired",
  score = "score",
}


export type SpecialOffersConnection = {
  __typename: "SpecialOffersConnection",
  items?:  Array<SpecialOffer | null > | null,
  nextToken?: string | null,
};

export type SearchFeaturedPlacesInput = {
  filter?: SearchFeaturedPlacesFilter | null,
  sort?: SearchFeaturedPlacesSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchFeaturedPlacesFilter = {
  area?: string | null,
  location?: LocationInput | null,
  km?: number | null,
  end?: string | null,
};

export type SearchFeaturedPlacesSortInput = {
  field?: SearchFeaturedPlacesSortFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchFeaturedPlacesSortFields {
  paid = "paid",
  area = "area",
  count = "count",
  location = "location",
}


export type FeaturedEntityConnection = {
  __typename: "FeaturedEntityConnection",
  items?:  Array<FeaturedPlace | null > | null,
  nextToken?: string | null,
};

export type SearchRepeatEventsInput = {
  filter?: SearchRepeatEventFilter | null,
  sort?: SearchRepeatEventsSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchRepeatEventFilter = {
  start?: number | null,
  end?: number | null,
  daysOfWeek?: Array< number | null > | null,
  city?: string | null,
  area?: string | null,
  categories?: Array< string | null > | null,
  closingTime?: number | null,
  location?: LocationInput | null,
  km?: number | null,
};

export type SearchRepeatEventsSortInput = {
  field?: SearchRepeatEventsSortFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchRepeatEventsSortFields {
  id = "id",
  title = "title",
  dayOfWeek = "dayOfWeek",
  daysOfWeek = "daysOfWeek",
  city = "city",
  status = "status",
  startTime = "startTime",
  endTime = "endTime",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type RepeatEventConnection = {
  __typename: "RepeatEventConnection",
  items?:  Array<RepeatEvent | null > | null,
  nextToken?: string | null,
};

export type SearchPlacesInput = {
  filter?: SearchPlacesFilter | null,
  sort?: SearchPlacesSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchPlacesFilter = {
  day?: string | null,
  dayFilter?: SearchPlacesHoursInput | null,
  vibeFilter?: SearchPlacesVibeInput | null,
  studentDiscount?: boolean | null,
  tags?: Array< string | null > | null,
  cuisine?: string | null,
  outdoorArea?: boolean | null,
  outdoorHeating?: boolean | null,
  price?: string | null,
  rewardVenue?: boolean | null,
  acceptWalkIns?: boolean | null,
  area?: string | null,
  city?: string | null,
  categories?: Array< string | null > | null,
  location?: LocationInput | null,
  km?: number | null,
};

export type SearchPlacesHoursInput = {
  day?: string | null,
  gte?: number | null,
  lte?: number | null,
  type?: string | null,
};

export type SearchPlacesVibeInput = {
  type?: string | null,
  day?: string | null,
};

export type SearchPlacesSortInput = {
  field?: SearchPlacesSortFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchPlacesSortFields {
  id = "id",
  title = "title",
  city = "city",
  area = "area",
  price = "price",
  status = "status",
  score = "score",
  location = "location",
  reviewRating = "reviewRating",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type EntityConnection = {
  __typename: "EntityConnection",
  items?:  Array<Entity | null > | null,
  nextToken?: string | null,
};

export type SearchElasticArtistEventPositionFilter = {
  date?: number | null,
  city?: string | null,
  location?: LocationInput | null,
};

export type ArtistEventPositionSearchConnection = {
  __typename: "ArtistEventPositionSearchConnection",
  items?:  Array<ArtistEventPosition | null > | null,
  nextToken?: string | null,
};

export type SearchElasticInfluencersInput = {
  filter?: SearchInfluencersFilter | null,
  sort?: SearchInfluencersSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchInfluencersFilter = {
  city?: string | null,
  location?: LocationInput | null,
  query?: string | null,
};

export type SearchInfluencersSortInput = {
  field?: SearchInfluencersSortFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchInfluencersSortFields {
  id = "id",
  city = "city",
  location = "location",
  saved = "saved",
  venueCount = "venueCount",
  views = "views",
  score = "score",
  dateTime = "dateTime",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  area = "area",
}


export type UserConnection = {
  __typename: "UserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
};

export type SearchElasticInstagramsInput = {
  query?: string | null,
  sort?: SearchInfluencersSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CountInput = {
  id: string,
  field: string,
  parentId: string,
  parentType: string,
  city?: string | null,
  type: string,
  increment: number,
  env: string,
};

export type ActivationPayload = {
  email?: string | null,
  role?: string | null,
  cognitoUsername?: string | null,
  businessName?: string | null,
  financeEmail?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  currency?: CurrencyInput | null,
  country?: CountryInput | null,
};

export type StripeCard = {
  number?: string | null,
  expireMonth?: number | null,
  expireYear?: number | null,
  cvc?: string | null,
};

export type LambdaResponse = {
  __typename: "LambdaResponse",
  code?: string | null,
  message?: string | null,
  body?: string | null,
};

export type SevenRoomsAvailabilityInput = {
  partySize?: number | null,
  date?: string | null,
};

export type SevenRoomsBookingInput = {
  partySize?: number | null,
  user?: SevenRoomsBookingUser | null,
  date?: string | null,
  notes?: string | null,
};

export type SevenRoomsBookingUser = {
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phone?: string | null,
};

export type EmailVenueName = {
  email?: string | null,
  name?: string | null,
  venue?: string | null,
};

export type ModelUserInteractionFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  itemId?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  itemType?: ModelStringInput | null,
  city?: ModelStringInput | null,
  itemTitle?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserInteractionFilterInput | null > | null,
  or?: Array< ModelUserInteractionFilterInput | null > | null,
  not?: ModelUserInteractionFilterInput | null,
};

export type ModelUserInteractionConnection = {
  __typename: "ModelUserInteractionConnection",
  items:  Array<UserInteraction | null >,
  nextToken?: string | null,
};

export type ModelTrackingActivityStatFilterInput = {
  id?: ModelIDInput | null,
  action?: ModelStringInput | null,
  itemType?: ModelStringInput | null,
  itemId?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  city?: ModelStringInput | null,
  owners?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTrackingActivityStatFilterInput | null > | null,
  or?: Array< ModelTrackingActivityStatFilterInput | null > | null,
  not?: ModelTrackingActivityStatFilterInput | null,
};

export type ModelTrackingActivityStatConnection = {
  __typename: "ModelTrackingActivityStatConnection",
  items:  Array<TrackingActivityStat | null >,
  nextToken?: string | null,
};

export type ModelConvoLinkFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  owners?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelConvoLinkFilterInput | null > | null,
  or?: Array< ModelConvoLinkFilterInput | null > | null,
  not?: ModelConvoLinkFilterInput | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  messageConversationId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  contentType?: ModelStringInput | null,
  author?: ModelStringInput | null,
  entity?: ModelStringInput | null,
  receiver?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelCardTransactionsFilterInput = {
  currency?: ModelStringInput | null,
  auth?: ModelBooleanInput | null,
  type?: ModelStringInput | null,
  programId?: ModelStringInput | null,
  id?: ModelStringInput | null,
  accountId?: ModelStringInput | null,
  userEntityId?: ModelStringInput | null,
  userPublisherId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  publisherId?: ModelStringInput | null,
  created?: ModelStringInput | null,
  authCode?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  cleared?: ModelBooleanInput | null,
  visits?: ModelFloatInput | null,
  wallet?: ModelStringInput | null,
  offer?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  datetime?: ModelStringInput | null,
  and?: Array< ModelCardTransactionsFilterInput | null > | null,
  or?: Array< ModelCardTransactionsFilterInput | null > | null,
  not?: ModelCardTransactionsFilterInput | null,
};

export type ModelCardTransactionsConnection = {
  __typename: "ModelCardTransactionsConnection",
  items:  Array<CardTransactions | null >,
  nextToken?: string | null,
};

export type ModelLoyaltyUserCountersFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  userDob?: ModelStringInput | null,
  userAge?: ModelIntInput | null,
  visits?: ModelFloatInput | null,
  idTypeKey?: ModelStringInput | null,
  userGender?: ModelStringInput | null,
  spend?: ModelFloatInput | null,
  ttl?: ModelFloatInput | null,
  period?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  transactions?: ModelFloatInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLoyaltyUserCountersFilterInput | null > | null,
  or?: Array< ModelLoyaltyUserCountersFilterInput | null > | null,
  not?: ModelLoyaltyUserCountersFilterInput | null,
};

export type ModelLoyaltyUserCountersConnection = {
  __typename: "ModelLoyaltyUserCountersConnection",
  items:  Array<LoyaltyUserCounters | null >,
  nextToken?: string | null,
};

export type ModelLoyaltyEntityCountersFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  spend?: ModelFloatInput | null,
  ttl?: ModelFloatInput | null,
  idTypeKey?: ModelStringInput | null,
  visits?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  transactions?: ModelFloatInput | null,
  paid?: ModelStringInput | null,
  invoice?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLoyaltyEntityCountersFilterInput | null > | null,
  or?: Array< ModelLoyaltyEntityCountersFilterInput | null > | null,
  not?: ModelLoyaltyEntityCountersFilterInput | null,
};

export type ModelLoyaltyEntityCountersConnection = {
  __typename: "ModelLoyaltyEntityCountersConnection",
  items:  Array<LoyaltyEntityCounters | null >,
  nextToken?: string | null,
};

export type ModelScheduleQueueFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  due?: ModelStringInput | null,
  action?: ModelStringInput | null,
  Payload?: ModelStringInput | null,
  and?: Array< ModelScheduleQueueFilterInput | null > | null,
  or?: Array< ModelScheduleQueueFilterInput | null > | null,
  not?: ModelScheduleQueueFilterInput | null,
};

export type ModelScheduleQueueConnection = {
  __typename: "ModelScheduleQueueConnection",
  items:  Array<ScheduleQueue | null >,
  nextToken?: string | null,
};

export type ModelStripePaymentFilterInput = {
  id?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  type?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  receipt_url?: ModelStringInput | null,
  invoice_url?: ModelStringInput | null,
  invoice_id?: ModelStringInput | null,
  additionalInvoiceItems?: ModelStringInput | null,
  status?: ModelStringInput | null,
  intentId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  publisherId?: ModelStringInput | null,
  productId?: ModelStringInput | null,
  metaData?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStripePaymentFilterInput | null > | null,
  or?: Array< ModelStripePaymentFilterInput | null > | null,
  not?: ModelStripePaymentFilterInput | null,
};

export type ModelStripePaymentConnection = {
  __typename: "ModelStripePaymentConnection",
  items:  Array<StripePayment | null >,
  nextToken?: string | null,
};

export type ModelCountryDataFilterInput = {
  id?: ModelStringInput | null,
  flag?: ModelStringInput | null,
  name?: ModelStringInput | null,
  alpha2Code?: ModelStringInput | null,
  alpha3Code?: ModelStringInput | null,
  callingCodes?: ModelStringInput | null,
  timezones?: ModelStringInput | null,
  region?: ModelStringInput | null,
  and?: Array< ModelCountryDataFilterInput | null > | null,
  or?: Array< ModelCountryDataFilterInput | null > | null,
  not?: ModelCountryDataFilterInput | null,
};

export type ModelCountryDataConnection = {
  __typename: "ModelCountryDataConnection",
  items:  Array<CountryData | null >,
  nextToken?: string | null,
};

export type ModelUserReportFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  message?: ModelStringInput | null,
  targetId?: ModelIDInput | null,
  targetType?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelUserReportFilterInput | null > | null,
  or?: Array< ModelUserReportFilterInput | null > | null,
  not?: ModelUserReportFilterInput | null,
};

export type ModelUserReportConnection = {
  __typename: "ModelUserReportConnection",
  items:  Array<UserReport | null >,
  nextToken?: string | null,
};

export type ModelTicketFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  bookingReference?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  repeatEventOccurencId?: ModelIDInput | null,
  eventType?: ModelStringInput | null,
  eventStartTimestamp?: ModelFloatInput | null,
  chargeId?: ModelStringInput | null,
  paymentIntentId?: ModelStringInput | null,
  content?: ModelStringInput | null,
  ticketActivasted?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  tier?: ModelStringInput | null,
  variation?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  status?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  test?: ModelStringInput | null,
  refunded?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTicketFilterInput | null > | null,
  or?: Array< ModelTicketFilterInput | null > | null,
  not?: ModelTicketFilterInput | null,
};

export type ModelClaimedOfferFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  offerId?: ModelIDInput | null,
  fidelOfferId?: ModelStringInput | null,
  activated?: ModelStringInput | null,
  pointsCosts?: ModelFloatInput | null,
  entityId?: ModelIDInput | null,
  metadata?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  owners?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelClaimedOfferFilterInput | null > | null,
  or?: Array< ModelClaimedOfferFilterInput | null > | null,
  not?: ModelClaimedOfferFilterInput | null,
};

export type ModelUserSavedItemFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  itemId?: ModelStringInput | null,
  title?: ModelStringInput | null,
  image?: ModelStringInput | null,
  authorId?: ModelStringInput | null,
  author?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserSavedItemFilterInput | null > | null,
  or?: Array< ModelUserSavedItemFilterInput | null > | null,
  not?: ModelUserSavedItemFilterInput | null,
};

export type ModelUserSavedItemConnection = {
  __typename: "ModelUserSavedItemConnection",
  items:  Array<UserSavedItem | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelFloatKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type SearchableStripePaymentFilterInput = {
  id?: SearchableIDFilterInput | null,
  startDate?: SearchableStringFilterInput | null,
  endDate?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  amount?: SearchableStringFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  receipt_url?: SearchableStringFilterInput | null,
  invoice_url?: SearchableStringFilterInput | null,
  invoice_id?: SearchableStringFilterInput | null,
  additionalInvoiceItems?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  intentId?: SearchableStringFilterInput | null,
  userId?: SearchableStringFilterInput | null,
  publisherId?: SearchableStringFilterInput | null,
  productId?: SearchableStringFilterInput | null,
  metaData?: SearchableStringFilterInput | null,
  ttl?: SearchableFloatFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableStripePaymentFilterInput | null > | null,
  or?: Array< SearchableStripePaymentFilterInput | null > | null,
  not?: SearchableStripePaymentFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableStripePaymentSortInput = {
  field?: SearchableStripePaymentSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableStripePaymentSortableFields {
  id = "id",
  startDate = "startDate",
  endDate = "endDate",
  type = "type",
  owner = "owner",
  amount = "amount",
  currency = "currency",
  receipt_url = "receipt_url",
  invoice_url = "invoice_url",
  invoice_id = "invoice_id",
  additionalInvoiceItems = "additionalInvoiceItems",
  status = "status",
  intentId = "intentId",
  userId = "userId",
  publisherId = "publisherId",
  productId = "productId",
  metaData = "metaData",
  ttl = "ttl",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableStripePaymentConnection = {
  __typename: "SearchableStripePaymentConnection",
  items:  Array<StripePayment | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCountersFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  attending?: ModelFloatInput | null,
  views?: ModelFloatInput | null,
  week?: ModelStringInput | null,
  booked?: ModelFloatInput | null,
  transactions?: ModelFloatInput | null,
  idTypeKey?: ModelStringInput | null,
  bookings_clicked?: ModelFloatInput | null,
  subscribed?: ModelFloatInput | null,
  unsubscribed?: ModelFloatInput | null,
  checkins?: ModelFloatInput | null,
  list?: ModelFloatInput | null,
  shared?: ModelFloatInput | null,
  visits?: ModelFloatInput | null,
  reviews?: ModelFloatInput | null,
  offersClaimed?: ModelFloatInput | null,
  offersRedeemed?: ModelFloatInput | null,
  loyaltyPoints?: ModelFloatInput | null,
  loyaltyCard?: ModelFloatInput | null,
  scan?: ModelFloatInput | null,
  score?: ModelFloatInput | null,
  ttl?: ModelFloatInput | null,
  reviewScore?: ModelFloatInput | null,
  date?: ModelStringInput | null,
  updatedAt?: ModelFloatInput | null,
  createdAt?: ModelFloatInput | null,
  and?: Array< ModelCountersFilterInput | null > | null,
  or?: Array< ModelCountersFilterInput | null > | null,
  not?: ModelCountersFilterInput | null,
};

export type ModelCountersConnection = {
  __typename: "ModelCountersConnection",
  items:  Array<Counters | null >,
  nextToken?: string | null,
};

export type ModelDailyCountersFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  attending?: ModelIntInput | null,
  views?: ModelIntInput | null,
  week?: ModelStringInput | null,
  booked?: ModelIntInput | null,
  bookings_clicked?: ModelFloatInput | null,
  subscribed?: ModelIntInput | null,
  unsubscribed?: ModelIntInput | null,
  checkins?: ModelIntInput | null,
  shared?: ModelIntInput | null,
  reviews?: ModelIntInput | null,
  offersClaimed?: ModelFloatInput | null,
  offersRedeemed?: ModelFloatInput | null,
  loyaltyPoints?: ModelFloatInput | null,
  loyaltyCard?: ModelFloatInput | null,
  scan?: ModelIntInput | null,
  ttl?: ModelFloatInput | null,
  date?: ModelStringInput | null,
  updatedAt?: ModelFloatInput | null,
  createdAt?: ModelFloatInput | null,
  and?: Array< ModelDailyCountersFilterInput | null > | null,
  or?: Array< ModelDailyCountersFilterInput | null > | null,
  not?: ModelDailyCountersFilterInput | null,
};

export type ModelDailyCountersConnection = {
  __typename: "ModelDailyCountersConnection",
  items:  Array<DailyCounters | null >,
  nextToken?: string | null,
};

export type ModelArtistFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  country?: ModelStringInput | null,
  spotifyAppUri?: ModelStringInput | null,
  videoUrls?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  rating?: ModelFloatInput | null,
  pingGigsPlayed?: ModelFloatInput | null,
  numReviews?: ModelFloatInput | null,
  logo?: ModelStringInput | null,
  soundcloudAppUri?: ModelStringInput | null,
  appleMusicAppUri?: ModelStringInput | null,
  youtubeUrl?: ModelStringInput | null,
  spotifyUrl?: ModelStringInput | null,
  deezerUrl?: ModelStringInput | null,
  mixcloudUrl?: ModelStringInput | null,
  stripeConnectId?: ModelStringInput | null,
  amazonMusicUrl?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  tidalUrl?: ModelStringInput | null,
  soundcloudUrl?: ModelStringInput | null,
  appleMusicUrl?: ModelStringInput | null,
  description?: ModelStringInput | null,
  genres?: ModelStringInput | null,
  images?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelArtistFilterInput | null > | null,
  or?: Array< ModelArtistFilterInput | null > | null,
  not?: ModelArtistFilterInput | null,
};

export type ModelArtistConnection = {
  __typename: "ModelArtistConnection",
  items:  Array<Artist | null >,
  nextToken?: string | null,
};

export type SearchableArtistFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  country?: SearchableStringFilterInput | null,
  spotifyAppUri?: SearchableStringFilterInput | null,
  videoUrls?: SearchableStringFilterInput | null,
  nickname?: SearchableStringFilterInput | null,
  rating?: SearchableFloatFilterInput | null,
  pingGigsPlayed?: SearchableFloatFilterInput | null,
  numReviews?: SearchableFloatFilterInput | null,
  logo?: SearchableStringFilterInput | null,
  soundcloudAppUri?: SearchableStringFilterInput | null,
  appleMusicAppUri?: SearchableStringFilterInput | null,
  youtubeUrl?: SearchableStringFilterInput | null,
  spotifyUrl?: SearchableStringFilterInput | null,
  deezerUrl?: SearchableStringFilterInput | null,
  mixcloudUrl?: SearchableStringFilterInput | null,
  stripeConnectId?: SearchableStringFilterInput | null,
  amazonMusicUrl?: SearchableStringFilterInput | null,
  stripeCustomerId?: SearchableStringFilterInput | null,
  tidalUrl?: SearchableStringFilterInput | null,
  soundcloudUrl?: SearchableStringFilterInput | null,
  appleMusicUrl?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  genres?: SearchableStringFilterInput | null,
  images?: SearchableStringFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  and?: Array< SearchableArtistFilterInput | null > | null,
  or?: Array< SearchableArtistFilterInput | null > | null,
  not?: SearchableArtistFilterInput | null,
};

export type SearchableArtistSortInput = {
  field?: SearchableArtistSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableArtistSortableFields {
  id = "id",
  title = "title",
  country = "country",
  spotifyAppUri = "spotifyAppUri",
  videoUrls = "videoUrls",
  nickname = "nickname",
  rating = "rating",
  pingGigsPlayed = "pingGigsPlayed",
  numReviews = "numReviews",
  logo = "logo",
  soundcloudAppUri = "soundcloudAppUri",
  appleMusicAppUri = "appleMusicAppUri",
  youtubeUrl = "youtubeUrl",
  spotifyUrl = "spotifyUrl",
  deezerUrl = "deezerUrl",
  mixcloudUrl = "mixcloudUrl",
  stripeConnectId = "stripeConnectId",
  amazonMusicUrl = "amazonMusicUrl",
  stripeCustomerId = "stripeCustomerId",
  tidalUrl = "tidalUrl",
  soundcloudUrl = "soundcloudUrl",
  appleMusicUrl = "appleMusicUrl",
  description = "description",
  genres = "genres",
  images = "images",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableArtistConnection = {
  __typename: "SearchableArtistConnection",
  items:  Array<Artist | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelArtistReviewFilterInput = {
  id?: ModelIDInput | null,
  review?: ModelStringInput | null,
  score?: ModelIntInput | null,
  artistId?: ModelIDInput | null,
  dateOfVisit?: ModelFloatInput | null,
  publisherId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  entityTitle?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelArtistReviewFilterInput | null > | null,
  or?: Array< ModelArtistReviewFilterInput | null > | null,
  not?: ModelArtistReviewFilterInput | null,
};

export type ModelArtistEventPositionFilterInput = {
  id?: ModelIDInput | null,
  eventDate?: ModelFloatInput | null,
  city?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelArtistEventPositionFilterInput | null > | null,
  or?: Array< ModelArtistEventPositionFilterInput | null > | null,
  not?: ModelArtistEventPositionFilterInput | null,
};

export type ModelArtistEventPositionConnection = {
  __typename: "ModelArtistEventPositionConnection",
  items:  Array<ArtistEventPosition | null >,
  nextToken?: string | null,
};

export type SearchableArtistEventPositionFilterInput = {
  id?: SearchableIDFilterInput | null,
  eventDate?: SearchableFloatFilterInput | null,
  city?: SearchableStringFilterInput | null,
  ttl?: SearchableFloatFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  and?: Array< SearchableArtistEventPositionFilterInput | null > | null,
  or?: Array< SearchableArtistEventPositionFilterInput | null > | null,
  not?: SearchableArtistEventPositionFilterInput | null,
};

export type SearchableArtistEventPositionSortInput = {
  field?: SearchableArtistEventPositionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableArtistEventPositionSortableFields {
  id = "id",
  eventDate = "eventDate",
  city = "city",
  ttl = "ttl",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableArtistEventPositionConnection = {
  __typename: "SearchableArtistEventPositionConnection",
  items:  Array<ArtistEventPosition | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBlogPostFilterInput = {
  id?: ModelIDInput | null,
  postType?: ModelStringInput | null,
  title?: ModelStringInput | null,
  desc?: ModelStringInput | null,
  city?: ModelStringInput | null,
  area?: ModelStringInput | null,
  catgories?: ModelStringInput | null,
  headerImg?: ModelStringInput | null,
  readCount?: ModelFloatInput | null,
  shareCount?: ModelFloatInput | null,
  metaDesc?: ModelStringInput | null,
  metaTitle?: ModelStringInput | null,
  videoUrl?: ModelStringInput | null,
  instagram?: ModelStringInput | null,
  twitter?: ModelStringInput | null,
  website?: ModelStringInput | null,
  facebook?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  publishStatus?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBlogPostFilterInput | null > | null,
  or?: Array< ModelBlogPostFilterInput | null > | null,
  not?: ModelBlogPostFilterInput | null,
};

export type ModelBlogPostConnection = {
  __typename: "ModelBlogPostConnection",
  items:  Array<BlogPost | null >,
  nextToken?: string | null,
};

export type SearchableBlogPostFilterInput = {
  id?: SearchableIDFilterInput | null,
  postType?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  desc?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  area?: SearchableStringFilterInput | null,
  catgories?: SearchableStringFilterInput | null,
  headerImg?: SearchableStringFilterInput | null,
  readCount?: SearchableFloatFilterInput | null,
  shareCount?: SearchableFloatFilterInput | null,
  metaDesc?: SearchableStringFilterInput | null,
  metaTitle?: SearchableStringFilterInput | null,
  videoUrl?: SearchableStringFilterInput | null,
  instagram?: SearchableStringFilterInput | null,
  twitter?: SearchableStringFilterInput | null,
  website?: SearchableStringFilterInput | null,
  facebook?: SearchableStringFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  publishStatus?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  ttl?: SearchableFloatFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableBlogPostFilterInput | null > | null,
  or?: Array< SearchableBlogPostFilterInput | null > | null,
  not?: SearchableBlogPostFilterInput | null,
};

export type SearchableBlogPostSortInput = {
  field?: SearchableBlogPostSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBlogPostSortableFields {
  id = "id",
  postType = "postType",
  title = "title",
  desc = "desc",
  city = "city",
  area = "area",
  catgories = "catgories",
  headerImg = "headerImg",
  readCount = "readCount",
  shareCount = "shareCount",
  metaDesc = "metaDesc",
  metaTitle = "metaTitle",
  videoUrl = "videoUrl",
  instagram = "instagram",
  twitter = "twitter",
  website = "website",
  facebook = "facebook",
  userId = "userId",
  publishStatus = "publishStatus",
  owners = "owners",
  ttl = "ttl",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableBlogPostConnection = {
  __typename: "SearchableBlogPostConnection",
  items:  Array<BlogPost | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBookableTableFilterInput = {
  id?: ModelIDInput | null,
  tableNumber?: ModelIntInput | null,
  capacity?: ModelIntInput | null,
  area?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  maxPeople?: ModelIntInput | null,
  minPeople?: ModelIntInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelBookableTableFilterInput | null > | null,
  or?: Array< ModelBookableTableFilterInput | null > | null,
  not?: ModelBookableTableFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelCheckInFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  fullName?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  partySize?: ModelIntInput | null,
  gender?: ModelStringInput | null,
  dob?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  age?: ModelIntInput | null,
  email?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  updatedAt?: ModelFloatInput | null,
  createdAt?: ModelFloatInput | null,
  and?: Array< ModelCheckInFilterInput | null > | null,
  or?: Array< ModelCheckInFilterInput | null > | null,
  not?: ModelCheckInFilterInput | null,
};

export type ModelCityFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  venueCount?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  country?: ModelStringInput | null,
  continent?: ModelStringInput | null,
  timezoneOffset?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  images?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelCityFilterInput | null > | null,
  or?: Array< ModelCityFilterInput | null > | null,
  not?: ModelCityFilterInput | null,
};

export type ModelCityConnection = {
  __typename: "ModelCityConnection",
  items:  Array<City | null >,
  nextToken?: string | null,
};

export type SearchableCityFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  venueCount?: SearchableFloatFilterInput | null,
  description?: SearchableStringFilterInput | null,
  country?: SearchableStringFilterInput | null,
  continent?: SearchableStringFilterInput | null,
  timezoneOffset?: SearchableFloatFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  images?: SearchableStringFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  and?: Array< SearchableCityFilterInput | null > | null,
  or?: Array< SearchableCityFilterInput | null > | null,
  not?: SearchableCityFilterInput | null,
};

export type SearchableCitySortInput = {
  field?: SearchableCitySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCitySortableFields {
  id = "id",
  title = "title",
  venueCount = "venueCount",
  description = "description",
  country = "country",
  continent = "continent",
  timezoneOffset = "timezoneOffset",
  currency = "currency",
  images = "images",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableCityConnection = {
  __typename: "SearchableCityConnection",
  items:  Array<City | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCouponFilterInput = {
  id?: ModelIDInput | null,
  code?: ModelIDInput | null,
  discountType?: ModelStringInput | null,
  discountAmount?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  eventType?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  repeatEventOccurencId?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCouponFilterInput | null > | null,
  or?: Array< ModelCouponFilterInput | null > | null,
  not?: ModelCouponFilterInput | null,
};

export type ModelCouponConnection = {
  __typename: "ModelCouponConnection",
  items:  Array<Coupon | null >,
  nextToken?: string | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  logo?: ModelStringInput | null,
  headerVideos?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  area?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  categories?: ModelStringInput | null,
  dateTime?: ModelFloatInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  closingTime?: ModelFloatInput | null,
  rules?: ModelStringInput | null,
  discount?: ModelIntInput | null,
  buyTicketUrl?: ModelStringInput | null,
  canBook?: ModelBooleanInput | null,
  isOnlineEvent?: ModelBooleanInput | null,
  featured?: ModelStringInput | null,
  video?: ModelStringInput | null,
  images?: ModelStringInput | null,
  artistsList?: ModelStringInput | null,
  mapImages?: ModelStringInput | null,
  price?: ModelStringInput | null,
  city?: ModelStringInput | null,
  attending?: ModelIntInput | null,
  promoterIds?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  views?: ModelIntInput | null,
  whatsappNumber?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  status?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  owners?: ModelIDInput | null,
  pushTimestamps?: ModelFloatInput | null,
  ownerType?: ModelStringInput | null,
  tablesAvailable?: ModelIntInput | null,
  menuImages?: ModelStringInput | null,
  username?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type SearchableEventFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  entityId?: SearchableIDFilterInput | null,
  logo?: SearchableStringFilterInput | null,
  headerVideos?: SearchableStringFilterInput | null,
  publisherId?: SearchableIDFilterInput | null,
  area?: SearchableStringFilterInput | null,
  subtitle?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  score?: SearchableFloatFilterInput | null,
  categories?: SearchableStringFilterInput | null,
  dateTime?: SearchableFloatFilterInput | null,
  startDate?: SearchableStringFilterInput | null,
  endDate?: SearchableStringFilterInput | null,
  closingTime?: SearchableFloatFilterInput | null,
  rules?: SearchableStringFilterInput | null,
  discount?: SearchableIntFilterInput | null,
  buyTicketUrl?: SearchableStringFilterInput | null,
  canBook?: SearchableBooleanFilterInput | null,
  isOnlineEvent?: SearchableBooleanFilterInput | null,
  featured?: SearchableStringFilterInput | null,
  video?: SearchableStringFilterInput | null,
  images?: SearchableStringFilterInput | null,
  artistsList?: SearchableStringFilterInput | null,
  mapImages?: SearchableStringFilterInput | null,
  price?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  attending?: SearchableIntFilterInput | null,
  promoterIds?: SearchableStringFilterInput | null,
  ttl?: SearchableFloatFilterInput | null,
  views?: SearchableIntFilterInput | null,
  whatsappNumber?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  owners?: SearchableIDFilterInput | null,
  pushTimestamps?: SearchableFloatFilterInput | null,
  ownerType?: SearchableStringFilterInput | null,
  tablesAvailable?: SearchableIntFilterInput | null,
  menuImages?: SearchableStringFilterInput | null,
  username?: SearchableStringFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  and?: Array< SearchableEventFilterInput | null > | null,
  or?: Array< SearchableEventFilterInput | null > | null,
  not?: SearchableEventFilterInput | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableEventSortInput = {
  field?: SearchableEventSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableEventSortableFields {
  id = "id",
  title = "title",
  entityId = "entityId",
  logo = "logo",
  headerVideos = "headerVideos",
  publisherId = "publisherId",
  area = "area",
  subtitle = "subtitle",
  description = "description",
  type = "type",
  score = "score",
  categories = "categories",
  dateTime = "dateTime",
  startDate = "startDate",
  endDate = "endDate",
  closingTime = "closingTime",
  rules = "rules",
  discount = "discount",
  buyTicketUrl = "buyTicketUrl",
  canBook = "canBook",
  isOnlineEvent = "isOnlineEvent",
  featured = "featured",
  video = "video",
  images = "images",
  artistsList = "artistsList",
  mapImages = "mapImages",
  price = "price",
  city = "city",
  attending = "attending",
  promoterIds = "promoterIds",
  ttl = "ttl",
  views = "views",
  whatsappNumber = "whatsappNumber",
  phone = "phone",
  status = "status",
  owner = "owner",
  owners = "owners",
  pushTimestamps = "pushTimestamps",
  ownerType = "ownerType",
  tablesAvailable = "tablesAvailable",
  menuImages = "menuImages",
  username = "username",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableEventConnection = {
  __typename: "SearchableEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelRepeatEventFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  area?: ModelStringInput | null,
  headerVideos?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  logo?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  discount?: ModelIntInput | null,
  isOnlineEvent?: ModelBooleanInput | null,
  score?: ModelFloatInput | null,
  categories?: ModelStringInput | null,
  closingTime?: ModelFloatInput | null,
  dayOfWeek?: ModelIntInput | null,
  daysOfWeek?: ModelIntInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  showWeather?: ModelBooleanInput | null,
  buyTicketUrl?: ModelStringInput | null,
  canBook?: ModelBooleanInput | null,
  ttl?: ModelFloatInput | null,
  whatsappNumber?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  mapImages?: ModelStringInput | null,
  video?: ModelStringInput | null,
  images?: ModelStringInput | null,
  artistsList?: ModelStringInput | null,
  featured?: ModelStringInput | null,
  firstEventDate?: ModelStringInput | null,
  lastEventDate?: ModelStringInput | null,
  price?: ModelStringInput | null,
  rules?: ModelStringInput | null,
  city?: ModelStringInput | null,
  attending?: ModelIntInput | null,
  status?: ModelStringInput | null,
  ownerType?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  pushTimestamps?: ModelFloatInput | null,
  promoterIds?: ModelStringInput | null,
  owners?: ModelIDInput | null,
  menuImages?: ModelStringInput | null,
  username?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelRepeatEventFilterInput | null > | null,
  or?: Array< ModelRepeatEventFilterInput | null > | null,
  not?: ModelRepeatEventFilterInput | null,
};

export type SearchableRepeatEventFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  entityId?: SearchableIDFilterInput | null,
  area?: SearchableStringFilterInput | null,
  headerVideos?: SearchableStringFilterInput | null,
  publisherId?: SearchableIDFilterInput | null,
  logo?: SearchableStringFilterInput | null,
  subtitle?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  discount?: SearchableIntFilterInput | null,
  isOnlineEvent?: SearchableBooleanFilterInput | null,
  score?: SearchableFloatFilterInput | null,
  categories?: SearchableStringFilterInput | null,
  closingTime?: SearchableFloatFilterInput | null,
  dayOfWeek?: SearchableIntFilterInput | null,
  daysOfWeek?: SearchableIntFilterInput | null,
  startTime?: SearchableStringFilterInput | null,
  endTime?: SearchableStringFilterInput | null,
  showWeather?: SearchableBooleanFilterInput | null,
  buyTicketUrl?: SearchableStringFilterInput | null,
  canBook?: SearchableBooleanFilterInput | null,
  ttl?: SearchableFloatFilterInput | null,
  whatsappNumber?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  mapImages?: SearchableStringFilterInput | null,
  video?: SearchableStringFilterInput | null,
  images?: SearchableStringFilterInput | null,
  artistsList?: SearchableStringFilterInput | null,
  featured?: SearchableStringFilterInput | null,
  firstEventDate?: SearchableStringFilterInput | null,
  lastEventDate?: SearchableStringFilterInput | null,
  price?: SearchableStringFilterInput | null,
  rules?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  attending?: SearchableIntFilterInput | null,
  status?: SearchableStringFilterInput | null,
  ownerType?: SearchableStringFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  pushTimestamps?: SearchableFloatFilterInput | null,
  promoterIds?: SearchableStringFilterInput | null,
  owners?: SearchableIDFilterInput | null,
  menuImages?: SearchableStringFilterInput | null,
  username?: SearchableStringFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  and?: Array< SearchableRepeatEventFilterInput | null > | null,
  or?: Array< SearchableRepeatEventFilterInput | null > | null,
  not?: SearchableRepeatEventFilterInput | null,
};

export type SearchableRepeatEventSortInput = {
  field?: SearchableRepeatEventSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableRepeatEventSortableFields {
  id = "id",
  title = "title",
  entityId = "entityId",
  area = "area",
  headerVideos = "headerVideos",
  publisherId = "publisherId",
  logo = "logo",
  subtitle = "subtitle",
  description = "description",
  type = "type",
  discount = "discount",
  isOnlineEvent = "isOnlineEvent",
  score = "score",
  categories = "categories",
  closingTime = "closingTime",
  dayOfWeek = "dayOfWeek",
  daysOfWeek = "daysOfWeek",
  startTime = "startTime",
  endTime = "endTime",
  showWeather = "showWeather",
  buyTicketUrl = "buyTicketUrl",
  canBook = "canBook",
  ttl = "ttl",
  whatsappNumber = "whatsappNumber",
  phone = "phone",
  mapImages = "mapImages",
  video = "video",
  images = "images",
  artistsList = "artistsList",
  featured = "featured",
  firstEventDate = "firstEventDate",
  lastEventDate = "lastEventDate",
  price = "price",
  rules = "rules",
  city = "city",
  attending = "attending",
  status = "status",
  ownerType = "ownerType",
  owner = "owner",
  pushTimestamps = "pushTimestamps",
  promoterIds = "promoterIds",
  owners = "owners",
  menuImages = "menuImages",
  username = "username",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableRepeatEventConnection = {
  __typename: "SearchableRepeatEventConnection",
  items:  Array<RepeatEvent | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelRepeatEventOccurenceFilterInput = {
  id?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  owners?: ModelIDInput | null,
  isSoldOut?: ModelBooleanInput | null,
  totalTicketsSold?: ModelFloatInput | null,
  canBuyTickets?: ModelBooleanInput | null,
  ttl?: ModelFloatInput | null,
  and?: Array< ModelRepeatEventOccurenceFilterInput | null > | null,
  or?: Array< ModelRepeatEventOccurenceFilterInput | null > | null,
  not?: ModelRepeatEventOccurenceFilterInput | null,
};

export type ModelReviewFilterInput = {
  id?: ModelIDInput | null,
  review?: ModelStringInput | null,
  score?: ModelIntInput | null,
  feedback?: ModelStringInput | null,
  dateOfVisit?: ModelFloatInput | null,
  userId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  verified?: ModelBooleanInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelReviewFilterInput | null > | null,
  or?: Array< ModelReviewFilterInput | null > | null,
  not?: ModelReviewFilterInput | null,
};

export type ModelUserEventFilterInput = {
  id?: ModelIDInput | null,
  eventDate?: ModelFloatInput | null,
  eventId?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  repeatEventId?: ModelIDInput | null,
  repeatEventOccurenceId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  owners?: ModelStringInput | null,
  status?: ModelStringInput | null,
  checkInDate?: ModelStringInput | null,
  tablesBooked?: ModelStringInput | null,
  subscriptionArn?: ModelStringInput | null,
  plusOnesRemaining?: ModelIntInput | null,
  authCode?: ModelStringInput | null,
  subscriptionArns?: ModelStringInput | null,
  subscriptionActive?: ModelBooleanInput | null,
  title?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelUserEventFilterInput | null > | null,
  or?: Array< ModelUserEventFilterInput | null > | null,
  not?: ModelUserEventFilterInput | null,
};

export type ModelHelpTopicFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  type?: ModelStringInput | null,
  category?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  color?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  authorId?: ModelStringInput | null,
  image?: ModelStringInput | null,
  index?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHelpTopicFilterInput | null > | null,
  or?: Array< ModelHelpTopicFilterInput | null > | null,
  not?: ModelHelpTopicFilterInput | null,
};

export type ModelHelpTopicConnection = {
  __typename: "ModelHelpTopicConnection",
  items:  Array<HelpTopic | null >,
  nextToken?: string | null,
};

export type ModelSpecialOfferFilterInput = {
  id?: ModelIDInput | null,
  daysAvailable?: ModelIntInput | null,
  startTime?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  discount?: ModelIntInput | null,
  endTime?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  declineReason?: ModelStringInput | null,
  requiresBooking?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  maxTransactionAmount?: ModelFloatInput | null,
  minTransactionAmount?: ModelFloatInput | null,
  returnPeriod?: ModelIntInput | null,
  description?: ModelStringInput | null,
  totalLoyaltyPointsRequiredForAccess?: ModelFloatInput | null,
  title?: ModelStringInput | null,
  images?: ModelStringInput | null,
  weeks?: ModelIntInput | null,
  entityId?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  fidelId?: ModelStringInput | null,
  spendRequired?: ModelFloatInput | null,
  delayMinutes?: ModelFloatInput | null,
  delayHours?: ModelFloatInput | null,
  currency?: ModelStringInput | null,
  isStudentDiscount?: ModelBooleanInput | null,
  city?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  status?: ModelStringInput | null,
  loyaltyPointsRequired?: ModelFloatInput | null,
  loyaltyPointsValue?: ModelFloatInput | null,
  visitsRequired?: ModelFloatInput | null,
  redeemCount?: ModelIntInput | null,
  claimCount?: ModelIntInput | null,
  dateAvailable?: ModelStringInput | null,
  allowanceLimit?: ModelIntInput | null,
  refreshDaysPeriod?: ModelStringInput | null,
  type?: ModelStringInput | null,
  rewardType?: ModelStringInput | null,
  subscribersOnly?: ModelBooleanInput | null,
  owner?: ModelIDInput | null,
  disclaimer?: ModelStringInput | null,
  fidelOfferId?: ModelStringInput | null,
  fidelStartDate?: ModelStringInput | null,
  fidelEndDate?: ModelStringInput | null,
  fidelMinTransactionAmount?: ModelFloatInput | null,
  fidelMaxTransactionAmount?: ModelFloatInput | null,
  fidelReturnPeriod?: ModelFloatInput | null,
  fidelDaysOfWeek?: ModelIntInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelSpecialOfferFilterInput | null > | null,
  or?: Array< ModelSpecialOfferFilterInput | null > | null,
  not?: ModelSpecialOfferFilterInput | null,
};

export type SearchableSpecialOfferFilterInput = {
  id?: SearchableIDFilterInput | null,
  daysAvailable?: SearchableIntFilterInput | null,
  startTime?: SearchableStringFilterInput | null,
  price?: SearchableFloatFilterInput | null,
  discount?: SearchableIntFilterInput | null,
  endTime?: SearchableStringFilterInput | null,
  endDate?: SearchableStringFilterInput | null,
  countryCode?: SearchableStringFilterInput | null,
  declineReason?: SearchableStringFilterInput | null,
  requiresBooking?: SearchableStringFilterInput | null,
  startDate?: SearchableStringFilterInput | null,
  maxTransactionAmount?: SearchableFloatFilterInput | null,
  minTransactionAmount?: SearchableFloatFilterInput | null,
  returnPeriod?: SearchableIntFilterInput | null,
  description?: SearchableStringFilterInput | null,
  totalLoyaltyPointsRequiredForAccess?: SearchableFloatFilterInput | null,
  title?: SearchableStringFilterInput | null,
  images?: SearchableStringFilterInput | null,
  weeks?: SearchableIntFilterInput | null,
  entityId?: SearchableIDFilterInput | null,
  publisherId?: SearchableIDFilterInput | null,
  fidelId?: SearchableStringFilterInput | null,
  spendRequired?: SearchableFloatFilterInput | null,
  delayMinutes?: SearchableFloatFilterInput | null,
  delayHours?: SearchableFloatFilterInput | null,
  currency?: SearchableStringFilterInput | null,
  isStudentDiscount?: SearchableBooleanFilterInput | null,
  city?: SearchableStringFilterInput | null,
  quantity?: SearchableIntFilterInput | null,
  status?: SearchableStringFilterInput | null,
  loyaltyPointsRequired?: SearchableFloatFilterInput | null,
  loyaltyPointsValue?: SearchableFloatFilterInput | null,
  visitsRequired?: SearchableFloatFilterInput | null,
  redeemCount?: SearchableIntFilterInput | null,
  claimCount?: SearchableIntFilterInput | null,
  dateAvailable?: SearchableStringFilterInput | null,
  allowanceLimit?: SearchableIntFilterInput | null,
  refreshDaysPeriod?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  rewardType?: SearchableStringFilterInput | null,
  subscribersOnly?: SearchableBooleanFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  disclaimer?: SearchableStringFilterInput | null,
  fidelOfferId?: SearchableStringFilterInput | null,
  fidelStartDate?: SearchableStringFilterInput | null,
  fidelEndDate?: SearchableStringFilterInput | null,
  fidelMinTransactionAmount?: SearchableFloatFilterInput | null,
  fidelMaxTransactionAmount?: SearchableFloatFilterInput | null,
  fidelReturnPeriod?: SearchableFloatFilterInput | null,
  fidelDaysOfWeek?: SearchableIntFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  and?: Array< SearchableSpecialOfferFilterInput | null > | null,
  or?: Array< SearchableSpecialOfferFilterInput | null > | null,
  not?: SearchableSpecialOfferFilterInput | null,
};

export type SearchableSpecialOfferSortInput = {
  field?: SearchableSpecialOfferSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableSpecialOfferSortableFields {
  id = "id",
  daysAvailable = "daysAvailable",
  startTime = "startTime",
  price = "price",
  discount = "discount",
  endTime = "endTime",
  endDate = "endDate",
  countryCode = "countryCode",
  declineReason = "declineReason",
  requiresBooking = "requiresBooking",
  startDate = "startDate",
  maxTransactionAmount = "maxTransactionAmount",
  minTransactionAmount = "minTransactionAmount",
  returnPeriod = "returnPeriod",
  description = "description",
  totalLoyaltyPointsRequiredForAccess = "totalLoyaltyPointsRequiredForAccess",
  title = "title",
  images = "images",
  weeks = "weeks",
  entityId = "entityId",
  publisherId = "publisherId",
  fidelId = "fidelId",
  spendRequired = "spendRequired",
  delayMinutes = "delayMinutes",
  delayHours = "delayHours",
  currency = "currency",
  isStudentDiscount = "isStudentDiscount",
  city = "city",
  quantity = "quantity",
  status = "status",
  loyaltyPointsRequired = "loyaltyPointsRequired",
  loyaltyPointsValue = "loyaltyPointsValue",
  visitsRequired = "visitsRequired",
  redeemCount = "redeemCount",
  claimCount = "claimCount",
  dateAvailable = "dateAvailable",
  allowanceLimit = "allowanceLimit",
  refreshDaysPeriod = "refreshDaysPeriod",
  type = "type",
  rewardType = "rewardType",
  subscribersOnly = "subscribersOnly",
  owner = "owner",
  disclaimer = "disclaimer",
  fidelOfferId = "fidelOfferId",
  fidelStartDate = "fidelStartDate",
  fidelEndDate = "fidelEndDate",
  fidelMinTransactionAmount = "fidelMinTransactionAmount",
  fidelMaxTransactionAmount = "fidelMaxTransactionAmount",
  fidelReturnPeriod = "fidelReturnPeriod",
  fidelDaysOfWeek = "fidelDaysOfWeek",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableSpecialOfferConnection = {
  __typename: "SearchableSpecialOfferConnection",
  items:  Array<SpecialOffer | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelLoyaltyCardFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  businessTitle?: ModelStringInput | null,
  currentTier?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  visits?: ModelFloatInput | null,
  businessAssignedTier?: ModelStringInput | null,
  transactions?: ModelFloatInput | null,
  totalLoyaltyPointsRequiredForAccess?: ModelFloatInput | null,
  subscriptionArns?: ModelStringInput | null,
  notificationsEnabled?: ModelBooleanInput | null,
  totalSpend?: ModelFloatInput | null,
  pendingPoints?: ModelFloatInput | null,
  totalPoints?: ModelFloatInput | null,
  ownerType?: ModelStringInput | null,
  status?: ModelStringInput | null,
  userFullName?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  totalSpendablePoints?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLoyaltyCardFilterInput | null > | null,
  or?: Array< ModelLoyaltyCardFilterInput | null > | null,
  not?: ModelLoyaltyCardFilterInput | null,
};

export type SearchableLoyaltyCardFilterInput = {
  id?: SearchableIDFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  publisherId?: SearchableIDFilterInput | null,
  businessTitle?: SearchableStringFilterInput | null,
  currentTier?: SearchableStringFilterInput | null,
  entityId?: SearchableIDFilterInput | null,
  visits?: SearchableFloatFilterInput | null,
  businessAssignedTier?: SearchableStringFilterInput | null,
  transactions?: SearchableFloatFilterInput | null,
  totalLoyaltyPointsRequiredForAccess?: SearchableFloatFilterInput | null,
  subscriptionArns?: SearchableStringFilterInput | null,
  notificationsEnabled?: SearchableBooleanFilterInput | null,
  totalSpend?: SearchableFloatFilterInput | null,
  pendingPoints?: SearchableFloatFilterInput | null,
  totalPoints?: SearchableFloatFilterInput | null,
  ownerType?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  userFullName?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  totalSpendablePoints?: SearchableFloatFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableLoyaltyCardFilterInput | null > | null,
  or?: Array< SearchableLoyaltyCardFilterInput | null > | null,
  not?: SearchableLoyaltyCardFilterInput | null,
};

export type SearchableLoyaltyCardSortInput = {
  field?: SearchableLoyaltyCardSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableLoyaltyCardSortableFields {
  id = "id",
  userId = "userId",
  publisherId = "publisherId",
  businessTitle = "businessTitle",
  currentTier = "currentTier",
  entityId = "entityId",
  visits = "visits",
  businessAssignedTier = "businessAssignedTier",
  transactions = "transactions",
  totalLoyaltyPointsRequiredForAccess = "totalLoyaltyPointsRequiredForAccess",
  subscriptionArns = "subscriptionArns",
  notificationsEnabled = "notificationsEnabled",
  totalSpend = "totalSpend",
  pendingPoints = "pendingPoints",
  totalPoints = "totalPoints",
  ownerType = "ownerType",
  status = "status",
  userFullName = "userFullName",
  owner = "owner",
  owners = "owners",
  totalSpendablePoints = "totalSpendablePoints",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableLoyaltyCardConnection = {
  __typename: "SearchableLoyaltyCardConnection",
  items:  Array<LoyaltyCard | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelDailyPromotionsFilterInput = {
  id?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  daysAvailable?: ModelIntInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  images?: ModelStringInput | null,
  title?: ModelStringInput | null,
  status?: ModelStringInput | null,
  disclaimer?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  subscribersOnly?: ModelBooleanInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelDailyPromotionsFilterInput | null > | null,
  or?: Array< ModelDailyPromotionsFilterInput | null > | null,
  not?: ModelDailyPromotionsFilterInput | null,
};

export type ModelLoyaltyActivityFilterInput = {
  id?: ModelIDInput | null,
  userPublisherKey?: ModelStringInput | null,
  points?: ModelIntInput | null,
  eventId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  adminAllocated?: ModelBooleanInput | null,
  message?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelLoyaltyActivityFilterInput | null > | null,
  or?: Array< ModelLoyaltyActivityFilterInput | null > | null,
  not?: ModelLoyaltyActivityFilterInput | null,
};

export type ModelLoyaltyActivityConnection = {
  __typename: "ModelLoyaltyActivityConnection",
  items:  Array<LoyaltyActivity | null >,
  nextToken?: string | null,
};

export type ModelEntityFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  shortUrl?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  lastAdminCheck?: ModelStringInput | null,
  area?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  uniqueAspect?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  hasEmailed?: ModelBooleanInput | null,
  firstName?: ModelStringInput | null,
  hasStudentDiscount?: ModelBooleanInput | null,
  LastName?: ModelStringInput | null,
  outdoorArea?: ModelBooleanInput | null,
  outdoorHeating?: ModelBooleanInput | null,
  description?: ModelStringInput | null,
  stripeSubscriptionItemId?: ModelStringInput | null,
  emojiSummary?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  internationalPhoneNumber?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  pushTimestamps?: ModelFloatInput | null,
  websiteUrl?: ModelStringInput | null,
  orderfromTableUrl?: ModelStringInput | null,
  entityTitle?: ModelStringInput | null,
  opens?: ModelFloatInput | null,
  closes?: ModelFloatInput | null,
  asFeaturedOn?: ModelStringInput | null,
  username?: ModelStringInput | null,
  trendingScore?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  virtualTourUrl?: ModelStringInput | null,
  emailsDisabled?: ModelBooleanInput | null,
  reviewsDisabled?: ModelBooleanInput | null,
  video?: ModelStringInput | null,
  headerVideos?: ModelStringInput | null,
  city?: ModelStringInput | null,
  rules?: ModelStringInput | null,
  position?: ModelFloatInput | null,
  score?: ModelFloatInput | null,
  score_before_boost?: ModelFloatInput | null,
  boost_multiplier?: ModelFloatInput | null,
  boost_until?: ModelStringInput | null,
  boostPurchaseId?: ModelStringInput | null,
  reviewRating?: ModelFloatInput | null,
  reviewCount?: ModelIntInput | null,
  canMessage?: ModelBooleanInput | null,
  forceupdate?: ModelBooleanInput | null,
  email?: ModelStringInput | null,
  menuImages?: ModelStringInput | null,
  price?: ModelStringInput | null,
  featured?: ModelStringInput | null,
  cuisines?: ModelStringInput | null,
  dietryOptions?: ModelStringInput | null,
  verified?: ModelBooleanInput | null,
  qrIds?: ModelStringInput | null,
  publisherId?: ModelIDInput | null,
  subscribeOfferId?: ModelStringInput | null,
  subscriberCount?: ModelFloatInput | null,
  viewCount?: ModelFloatInput | null,
  bookCount?: ModelFloatInput | null,
  shareCount?: ModelFloatInput | null,
  checkInCount?: ModelFloatInput | null,
  addToListCount?: ModelFloatInput | null,
  pushTimestamp?: ModelFloatInput | null,
  images?: ModelStringInput | null,
  minBookingLimit?: ModelIntInput | null,
  maxBookingLimit?: ModelIntInput | null,
  bookingUrl?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  offersArray?: ModelStringInput | null,
  whatsappNumber?: ModelStringInput | null,
  updatedAt?: ModelFloatInput | null,
  owner?: ModelStringInput | null,
  capacity?: ModelIntInput | null,
  acceptWalkIns?: ModelBooleanInput | null,
  and?: Array< ModelEntityFilterInput | null > | null,
  or?: Array< ModelEntityFilterInput | null > | null,
  not?: ModelEntityFilterInput | null,
};

export type SearchableEntityFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  shortUrl?: SearchableStringFilterInput | null,
  subtitle?: SearchableStringFilterInput | null,
  lastAdminCheck?: SearchableStringFilterInput | null,
  area?: SearchableStringFilterInput | null,
  notes?: SearchableStringFilterInput | null,
  summary?: SearchableStringFilterInput | null,
  uniqueAspect?: SearchableStringFilterInput | null,
  tags?: SearchableStringFilterInput | null,
  nickname?: SearchableStringFilterInput | null,
  hasEmailed?: SearchableBooleanFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  hasStudentDiscount?: SearchableBooleanFilterInput | null,
  LastName?: SearchableStringFilterInput | null,
  outdoorArea?: SearchableBooleanFilterInput | null,
  outdoorHeating?: SearchableBooleanFilterInput | null,
  description?: SearchableStringFilterInput | null,
  stripeSubscriptionItemId?: SearchableStringFilterInput | null,
  emojiSummary?: SearchableStringFilterInput | null,
  categories?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  internationalPhoneNumber?: SearchableStringFilterInput | null,
  logo?: SearchableStringFilterInput | null,
  pushTimestamps?: SearchableFloatFilterInput | null,
  websiteUrl?: SearchableStringFilterInput | null,
  orderfromTableUrl?: SearchableStringFilterInput | null,
  entityTitle?: SearchableStringFilterInput | null,
  opens?: SearchableFloatFilterInput | null,
  closes?: SearchableFloatFilterInput | null,
  asFeaturedOn?: SearchableStringFilterInput | null,
  username?: SearchableStringFilterInput | null,
  trendingScore?: SearchableFloatFilterInput | null,
  status?: SearchableStringFilterInput | null,
  virtualTourUrl?: SearchableStringFilterInput | null,
  emailsDisabled?: SearchableBooleanFilterInput | null,
  reviewsDisabled?: SearchableBooleanFilterInput | null,
  video?: SearchableStringFilterInput | null,
  headerVideos?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  rules?: SearchableStringFilterInput | null,
  position?: SearchableFloatFilterInput | null,
  score?: SearchableFloatFilterInput | null,
  score_before_boost?: SearchableFloatFilterInput | null,
  boost_multiplier?: SearchableFloatFilterInput | null,
  boost_until?: SearchableStringFilterInput | null,
  boostPurchaseId?: SearchableStringFilterInput | null,
  reviewRating?: SearchableFloatFilterInput | null,
  reviewCount?: SearchableIntFilterInput | null,
  canMessage?: SearchableBooleanFilterInput | null,
  forceupdate?: SearchableBooleanFilterInput | null,
  email?: SearchableStringFilterInput | null,
  menuImages?: SearchableStringFilterInput | null,
  price?: SearchableStringFilterInput | null,
  featured?: SearchableStringFilterInput | null,
  cuisines?: SearchableStringFilterInput | null,
  dietryOptions?: SearchableStringFilterInput | null,
  verified?: SearchableBooleanFilterInput | null,
  qrIds?: SearchableStringFilterInput | null,
  publisherId?: SearchableIDFilterInput | null,
  subscribeOfferId?: SearchableStringFilterInput | null,
  subscriberCount?: SearchableFloatFilterInput | null,
  viewCount?: SearchableFloatFilterInput | null,
  bookCount?: SearchableFloatFilterInput | null,
  shareCount?: SearchableFloatFilterInput | null,
  checkInCount?: SearchableFloatFilterInput | null,
  addToListCount?: SearchableFloatFilterInput | null,
  pushTimestamp?: SearchableFloatFilterInput | null,
  images?: SearchableStringFilterInput | null,
  minBookingLimit?: SearchableIntFilterInput | null,
  maxBookingLimit?: SearchableIntFilterInput | null,
  bookingUrl?: SearchableStringFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  offersArray?: SearchableStringFilterInput | null,
  whatsappNumber?: SearchableStringFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  capacity?: SearchableIntFilterInput | null,
  acceptWalkIns?: SearchableBooleanFilterInput | null,
  and?: Array< SearchableEntityFilterInput | null > | null,
  or?: Array< SearchableEntityFilterInput | null > | null,
  not?: SearchableEntityFilterInput | null,
};

export type SearchableEntitySortInput = {
  field?: SearchableEntitySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableEntitySortableFields {
  id = "id",
  title = "title",
  shortUrl = "shortUrl",
  subtitle = "subtitle",
  lastAdminCheck = "lastAdminCheck",
  area = "area",
  notes = "notes",
  summary = "summary",
  uniqueAspect = "uniqueAspect",
  tags = "tags",
  nickname = "nickname",
  hasEmailed = "hasEmailed",
  firstName = "firstName",
  hasStudentDiscount = "hasStudentDiscount",
  LastName = "LastName",
  outdoorArea = "outdoorArea",
  outdoorHeating = "outdoorHeating",
  description = "description",
  stripeSubscriptionItemId = "stripeSubscriptionItemId",
  emojiSummary = "emojiSummary",
  categories = "categories",
  phone = "phone",
  internationalPhoneNumber = "internationalPhoneNumber",
  logo = "logo",
  pushTimestamps = "pushTimestamps",
  websiteUrl = "websiteUrl",
  orderfromTableUrl = "orderfromTableUrl",
  entityTitle = "entityTitle",
  opens = "opens",
  closes = "closes",
  asFeaturedOn = "asFeaturedOn",
  username = "username",
  trendingScore = "trendingScore",
  status = "status",
  virtualTourUrl = "virtualTourUrl",
  emailsDisabled = "emailsDisabled",
  reviewsDisabled = "reviewsDisabled",
  video = "video",
  headerVideos = "headerVideos",
  city = "city",
  rules = "rules",
  position = "position",
  score = "score",
  score_before_boost = "score_before_boost",
  boost_multiplier = "boost_multiplier",
  boost_until = "boost_until",
  boostPurchaseId = "boostPurchaseId",
  reviewRating = "reviewRating",
  reviewCount = "reviewCount",
  canMessage = "canMessage",
  forceupdate = "forceupdate",
  email = "email",
  menuImages = "menuImages",
  price = "price",
  featured = "featured",
  cuisines = "cuisines",
  dietryOptions = "dietryOptions",
  verified = "verified",
  qrIds = "qrIds",
  publisherId = "publisherId",
  subscribeOfferId = "subscribeOfferId",
  subscriberCount = "subscriberCount",
  viewCount = "viewCount",
  bookCount = "bookCount",
  shareCount = "shareCount",
  checkInCount = "checkInCount",
  addToListCount = "addToListCount",
  pushTimestamp = "pushTimestamp",
  images = "images",
  minBookingLimit = "minBookingLimit",
  maxBookingLimit = "maxBookingLimit",
  bookingUrl = "bookingUrl",
  createdAt = "createdAt",
  offersArray = "offersArray",
  whatsappNumber = "whatsappNumber",
  updatedAt = "updatedAt",
  owner = "owner",
  capacity = "capacity",
  acceptWalkIns = "acceptWalkIns",
}


export type SearchableEntityConnection = {
  __typename: "SearchableEntityConnection",
  items:  Array<Entity | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelFeaturedPlaceFilterInput = {
  id?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  area?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  pinToTop?: ModelBooleanInput | null,
  city?: ModelStringInput | null,
  featuredUntil?: ModelStringInput | null,
  coverage?: ModelStringInput | null,
  country?: ModelStringInput | null,
  days_selected?: ModelIntInput | null,
  startDate?: ModelStringInput | null,
  budget?: ModelFloatInput | null,
  budget_left?: ModelFloatInput | null,
  paid?: ModelFloatInput | null,
  viewCount?: ModelFloatInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  and?: Array< ModelFeaturedPlaceFilterInput | null > | null,
  or?: Array< ModelFeaturedPlaceFilterInput | null > | null,
  not?: ModelFeaturedPlaceFilterInput | null,
};

export type ModelFeaturedPlaceConnection = {
  __typename: "ModelFeaturedPlaceConnection",
  items:  Array<FeaturedPlace | null >,
  nextToken?: string | null,
};

export type ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyConditionInput = {
  eq?: ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyInput | null,
  le?: ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyInput | null,
  lt?: ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyInput | null,
  ge?: ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyInput | null,
  gt?: ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyInput | null,
  between?: Array< ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyInput | null > | null,
  beginsWith?: ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyInput | null,
};

export type ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyInput = {
  paid?: number | null,
  featuredUntil?: string | null,
};

export type SearchableFeaturedPlaceFilterInput = {
  id?: SearchableIDFilterInput | null,
  entityId?: SearchableIDFilterInput | null,
  eventId?: SearchableIDFilterInput | null,
  area?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  pinToTop?: SearchableBooleanFilterInput | null,
  city?: SearchableStringFilterInput | null,
  featuredUntil?: SearchableStringFilterInput | null,
  coverage?: SearchableStringFilterInput | null,
  country?: SearchableStringFilterInput | null,
  days_selected?: SearchableIntFilterInput | null,
  startDate?: SearchableStringFilterInput | null,
  budget?: SearchableFloatFilterInput | null,
  budget_left?: SearchableFloatFilterInput | null,
  paid?: SearchableFloatFilterInput | null,
  viewCount?: SearchableFloatFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  ttl?: SearchableFloatFilterInput | null,
  and?: Array< SearchableFeaturedPlaceFilterInput | null > | null,
  or?: Array< SearchableFeaturedPlaceFilterInput | null > | null,
  not?: SearchableFeaturedPlaceFilterInput | null,
};

export type SearchableFeaturedPlaceSortInput = {
  field?: SearchableFeaturedPlaceSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFeaturedPlaceSortableFields {
  id = "id",
  entityId = "entityId",
  eventId = "eventId",
  area = "area",
  title = "title",
  description = "description",
  pinToTop = "pinToTop",
  city = "city",
  featuredUntil = "featuredUntil",
  coverage = "coverage",
  country = "country",
  days_selected = "days_selected",
  startDate = "startDate",
  budget = "budget",
  budget_left = "budget_left",
  paid = "paid",
  viewCount = "viewCount",
  owner = "owner",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  status = "status",
  ttl = "ttl",
}


export type SearchableFeaturedPlaceConnection = {
  __typename: "SearchableFeaturedPlaceConnection",
  items:  Array<FeaturedPlace | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelBookingsFilterInput = {
  id?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  publisher?: ModelStringInput | null,
  bookingType?: ModelStringInput | null,
  entityId?: ModelIDInput | null,
  venueNotes?: ModelStringInput | null,
  durationInMinutes?: ModelIntInput | null,
  entityTitle?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  userPhone?: ModelStringInput | null,
  confirmed?: ModelBooleanInput | null,
  owners?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  dateTime?: ModelStringInput | null,
  tableId?: ModelIDInput | null,
  timeString?: ModelStringInput | null,
  provider?: ModelBookingSystemProviderInput | null,
  dateString?: ModelStringInput | null,
  dateTimestamp?: ModelFloatInput | null,
  people?: ModelIntInput | null,
  artistId?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  status?: ModelStringInput | null,
  ttl?: ModelFloatInput | null,
  paymentStatus?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelBookingsFilterInput | null > | null,
  or?: Array< ModelBookingsFilterInput | null > | null,
  not?: ModelBookingsFilterInput | null,
};

export type SearchableBookingsFilterInput = {
  id?: SearchableIDFilterInput | null,
  userName?: SearchableStringFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  publisher?: SearchableStringFilterInput | null,
  bookingType?: SearchableStringFilterInput | null,
  entityId?: SearchableIDFilterInput | null,
  venueNotes?: SearchableStringFilterInput | null,
  durationInMinutes?: SearchableIntFilterInput | null,
  entityTitle?: SearchableStringFilterInput | null,
  userEmail?: SearchableStringFilterInput | null,
  userPhone?: SearchableStringFilterInput | null,
  confirmed?: SearchableBooleanFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  dateTime?: SearchableStringFilterInput | null,
  tableId?: SearchableIDFilterInput | null,
  timeString?: SearchableStringFilterInput | null,
  dateString?: SearchableStringFilterInput | null,
  dateTimestamp?: SearchableFloatFilterInput | null,
  people?: SearchableIntFilterInput | null,
  artistId?: SearchableStringFilterInput | null,
  notes?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  ttl?: SearchableFloatFilterInput | null,
  paymentStatus?: SearchableStringFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  and?: Array< SearchableBookingsFilterInput | null > | null,
  or?: Array< SearchableBookingsFilterInput | null > | null,
  not?: SearchableBookingsFilterInput | null,
};

export type SearchableBookingsSortInput = {
  field?: SearchableBookingsSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableBookingsSortableFields {
  id = "id",
  userName = "userName",
  userId = "userId",
  publisher = "publisher",
  bookingType = "bookingType",
  entityId = "entityId",
  venueNotes = "venueNotes",
  durationInMinutes = "durationInMinutes",
  entityTitle = "entityTitle",
  userEmail = "userEmail",
  userPhone = "userPhone",
  confirmed = "confirmed",
  owners = "owners",
  owner = "owner",
  dateTime = "dateTime",
  tableId = "tableId",
  timeString = "timeString",
  dateString = "dateString",
  dateTimestamp = "dateTimestamp",
  people = "people",
  artistId = "artistId",
  notes = "notes",
  status = "status",
  ttl = "ttl",
  paymentStatus = "paymentStatus",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableBookingsConnection = {
  __typename: "SearchableBookingsConnection",
  items:  Array<Bookings | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPodcastFilterInput = {
  id?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  embedType?: ModelStringInput | null,
  embedId?: ModelStringInput | null,
  embedUrl?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  images?: ModelStringInput | null,
  username?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPodcastFilterInput | null > | null,
  or?: Array< ModelPodcastFilterInput | null > | null,
  not?: ModelPodcastFilterInput | null,
};

export type ModelPodcastConnection = {
  __typename: "ModelPodcastConnection",
  items:  Array<Podcast | null >,
  nextToken?: string | null,
};

export type ModelPublisherFilterInput = {
  id?: ModelIDInput | null,
  cognitoUsername?: ModelStringInput | null,
  financeEmail?: ModelStringInput | null,
  role?: ModelStringInput | null,
  profilePicture?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  publisherType?: ModelPublisherTypeInput | null,
  editors?: ModelIDInput | null,
  owner?: ModelIDInput | null,
  username?: ModelStringInput | null,
  pushTimestamps?: ModelFloatInput | null,
  emailsDisabled?: ModelBooleanInput | null,
  timezone?: ModelStringInput | null,
  subscibedToEmails?: ModelBooleanInput | null,
  timezoneOffset?: ModelIntInput | null,
  logo?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  websiteUrl?: ModelStringInput | null,
  whatsappNumber?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  canMessage?: ModelBooleanInput | null,
  subscriberCount?: ModelFloatInput | null,
  loyaltyCount?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  termsVersionSigned?: ModelFloatInput | null,
  activated?: ModelStringInput | null,
  reviewsDisabled?: ModelBooleanInput | null,
  reviewRating?: ModelFloatInput | null,
  reviewCount?: ModelFloatInput | null,
  activationDate?: ModelStringInput | null,
  stripeConnectId?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  fidelId?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelPublisherFilterInput | null > | null,
  or?: Array< ModelPublisherFilterInput | null > | null,
  not?: ModelPublisherFilterInput | null,
};

export type ModelPublisherConnection = {
  __typename: "ModelPublisherConnection",
  items:  Array<Publisher | null >,
  nextToken?: string | null,
};

export type SearchablePublisherFilterInput = {
  id?: SearchableIDFilterInput | null,
  cognitoUsername?: SearchableStringFilterInput | null,
  financeEmail?: SearchableStringFilterInput | null,
  role?: SearchableStringFilterInput | null,
  profilePicture?: SearchableStringFilterInput | null,
  bio?: SearchableStringFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  editors?: SearchableIDFilterInput | null,
  owner?: SearchableIDFilterInput | null,
  username?: SearchableStringFilterInput | null,
  pushTimestamps?: SearchableFloatFilterInput | null,
  emailsDisabled?: SearchableBooleanFilterInput | null,
  timezone?: SearchableStringFilterInput | null,
  subscibedToEmails?: SearchableBooleanFilterInput | null,
  timezoneOffset?: SearchableIntFilterInput | null,
  logo?: SearchableStringFilterInput | null,
  businessName?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  websiteUrl?: SearchableStringFilterInput | null,
  whatsappNumber?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  canMessage?: SearchableBooleanFilterInput | null,
  subscriberCount?: SearchableFloatFilterInput | null,
  loyaltyCount?: SearchableFloatFilterInput | null,
  status?: SearchableStringFilterInput | null,
  termsVersionSigned?: SearchableFloatFilterInput | null,
  activated?: SearchableStringFilterInput | null,
  reviewsDisabled?: SearchableBooleanFilterInput | null,
  reviewRating?: SearchableFloatFilterInput | null,
  reviewCount?: SearchableFloatFilterInput | null,
  activationDate?: SearchableStringFilterInput | null,
  stripeConnectId?: SearchableStringFilterInput | null,
  stripeCustomerId?: SearchableStringFilterInput | null,
  fidelId?: SearchableStringFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  and?: Array< SearchablePublisherFilterInput | null > | null,
  or?: Array< SearchablePublisherFilterInput | null > | null,
  not?: SearchablePublisherFilterInput | null,
};

export type SearchablePublisherSortInput = {
  field?: SearchablePublisherSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePublisherSortableFields {
  id = "id",
  cognitoUsername = "cognitoUsername",
  financeEmail = "financeEmail",
  role = "role",
  profilePicture = "profilePicture",
  bio = "bio",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  editors = "editors",
  owner = "owner",
  username = "username",
  pushTimestamps = "pushTimestamps",
  emailsDisabled = "emailsDisabled",
  timezone = "timezone",
  subscibedToEmails = "subscibedToEmails",
  timezoneOffset = "timezoneOffset",
  logo = "logo",
  businessName = "businessName",
  description = "description",
  websiteUrl = "websiteUrl",
  whatsappNumber = "whatsappNumber",
  phone = "phone",
  canMessage = "canMessage",
  subscriberCount = "subscriberCount",
  loyaltyCount = "loyaltyCount",
  status = "status",
  termsVersionSigned = "termsVersionSigned",
  activated = "activated",
  reviewsDisabled = "reviewsDisabled",
  reviewRating = "reviewRating",
  reviewCount = "reviewCount",
  activationDate = "activationDate",
  stripeConnectId = "stripeConnectId",
  stripeCustomerId = "stripeCustomerId",
  fidelId = "fidelId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePublisherConnection = {
  __typename: "SearchablePublisherConnection",
  items:  Array<Publisher | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCategoriesFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  type?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  desc?: ModelStringInput | null,
  pingEditTitle?: ModelStringInput | null,
  metaTitle?: ModelStringInput | null,
  metaDescription?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  showcaseImage?: ModelStringInput | null,
  categoryCityCount?: ModelStringInput | null,
  isHidden?: ModelBooleanInput | null,
  citiesAvailable?: ModelStringInput | null,
  translateString?: ModelStringInput | null,
  colour?: ModelStringInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelCategoriesFilterInput | null > | null,
  or?: Array< ModelCategoriesFilterInput | null > | null,
  not?: ModelCategoriesFilterInput | null,
};

export type ModelCategoriesConnection = {
  __typename: "ModelCategoriesConnection",
  items:  Array<Categories | null >,
  nextToken?: string | null,
};

export type ModelVenueCategoryTopicsFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  subtitle?: ModelStringInput | null,
  image?: ModelStringInput | null,
  URL?: ModelStringInput | null,
  type?: ModelStringInput | null,
  itemId?: ModelStringInput | null,
  content?: ModelStringInput | null,
  pinToTop?: ModelBooleanInput | null,
  tag?: ModelStringInput | null,
  category?: ModelStringInput | null,
  subCategory?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  omittedCities?: ModelStringInput | null,
  allowedCities?: ModelStringInput | null,
  orderBy?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVenueCategoryTopicsFilterInput | null > | null,
  or?: Array< ModelVenueCategoryTopicsFilterInput | null > | null,
  not?: ModelVenueCategoryTopicsFilterInput | null,
};

export type ModelVenueCategoryTopicsConnection = {
  __typename: "ModelVenueCategoryTopicsConnection",
  items:  Array<VenueCategoryTopics | null >,
  nextToken?: string | null,
};

export type ModelPingConfigsFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  token?: ModelStringInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPingConfigsFilterInput | null > | null,
  or?: Array< ModelPingConfigsFilterInput | null > | null,
  not?: ModelPingConfigsFilterInput | null,
};

export type ModelPingConfigsConnection = {
  __typename: "ModelPingConfigsConnection",
  items:  Array<PingConfigs | null >,
  nextToken?: string | null,
};

export type ModelFeedbackFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  feedback?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelFeedbackFilterInput | null > | null,
  or?: Array< ModelFeedbackFilterInput | null > | null,
  not?: ModelFeedbackFilterInput | null,
};

export type ModelFeedbackConnection = {
  __typename: "ModelFeedbackConnection",
  items:  Array<Feedback | null >,
  nextToken?: string | null,
};

export type ModelVenueTableFilterInput = {
  id?: ModelIDInput | null,
  capacity?: ModelIntInput | null,
  quantityAvailable?: ModelIntInput | null,
  tablesBooked?: ModelIntInput | null,
  price?: ModelFloatInput | null,
  disclaimer?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  parentType?: ModelTableParentTypeInput | null,
  entityId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  title?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelVenueTableFilterInput | null > | null,
  or?: Array< ModelVenueTableFilterInput | null > | null,
  not?: ModelVenueTableFilterInput | null,
};

export type ModelTableBookingRequestFilterInput = {
  id?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  paid?: ModelBooleanInput | null,
  amountPaid?: ModelFloatInput | null,
  eventId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  tableId?: ModelIDInput | null,
  message?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelTableBookingRequestFilterInput | null > | null,
  or?: Array< ModelTableBookingRequestFilterInput | null > | null,
  not?: ModelTableBookingRequestFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelTicketVariationOccurenceFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  repeatEventId?: ModelIDInput | null,
  repeatEventOccurenceId?: ModelIDInput | null,
  eventType?: ModelStringInput | null,
  variationRuleId?: ModelStringInput | null,
  isSoldOut?: ModelBooleanInput | null,
  amountSoldCounter?: ModelFloatInput | null,
  owners?: ModelIDInput | null,
  ttl?: ModelFloatInput | null,
  and?: Array< ModelTicketVariationOccurenceFilterInput | null > | null,
  or?: Array< ModelTicketVariationOccurenceFilterInput | null > | null,
  not?: ModelTicketVariationOccurenceFilterInput | null,
};

export type ModelUpdateFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  content?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  parentTitle?: ModelStringInput | null,
  isPinned?: ModelBooleanInput | null,
  metadata?: ModelStringInput | null,
  actionCount?: ModelFloatInput | null,
  viewCount?: ModelFloatInput | null,
  url?: ModelStringInput | null,
  images?: ModelStringInput | null,
  route?: ModelStringInput | null,
  extra?: ModelStringInput | null,
  type?: ModelStringInput | null,
  updatedAt?: ModelFloatInput | null,
  createdAt?: ModelFloatInput | null,
  and?: Array< ModelUpdateFilterInput | null > | null,
  or?: Array< ModelUpdateFilterInput | null > | null,
  not?: ModelUpdateFilterInput | null,
};

export type ModelUpdateConnection = {
  __typename: "ModelUpdateConnection",
  items:  Array<Update | null >,
  nextToken?: string | null,
};

export type ModelPlaceCollectionFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  area?: ModelStringInput | null,
  saved?: ModelFloatInput | null,
  shared?: ModelFloatInput | null,
  likes?: ModelFloatInput | null,
  venueCount?: ModelFloatInput | null,
  headerImg?: ModelStringInput | null,
  views?: ModelFloatInput | null,
  lockCriteria?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  areas?: ModelStringInput | null,
  username?: ModelStringInput | null,
  city?: ModelStringInput | null,
  cities?: ModelStringInput | null,
  status?: ModelStringInput | null,
  isPublic?: ModelStringInput | null,
  dateTime?: ModelStringInput | null,
  securityCode?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPlaceCollectionFilterInput | null > | null,
  or?: Array< ModelPlaceCollectionFilterInput | null > | null,
  not?: ModelPlaceCollectionFilterInput | null,
};

export type ModelPlaceCollectionConnection = {
  __typename: "ModelPlaceCollectionConnection",
  items:  Array<PlaceCollection | null >,
  nextToken?: string | null,
};

export type SearchablePlaceCollectionFilterInput = {
  id?: SearchableIDFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  area?: SearchableStringFilterInput | null,
  saved?: SearchableFloatFilterInput | null,
  shared?: SearchableFloatFilterInput | null,
  likes?: SearchableFloatFilterInput | null,
  venueCount?: SearchableFloatFilterInput | null,
  headerImg?: SearchableStringFilterInput | null,
  views?: SearchableFloatFilterInput | null,
  lockCriteria?: SearchableStringFilterInput | null,
  score?: SearchableFloatFilterInput | null,
  areas?: SearchableStringFilterInput | null,
  username?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  cities?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  isPublic?: SearchableStringFilterInput | null,
  dateTime?: SearchableStringFilterInput | null,
  securityCode?: SearchableStringFilterInput | null,
  owners?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchablePlaceCollectionFilterInput | null > | null,
  or?: Array< SearchablePlaceCollectionFilterInput | null > | null,
  not?: SearchablePlaceCollectionFilterInput | null,
};

export type SearchablePlaceCollectionSortInput = {
  field?: SearchablePlaceCollectionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePlaceCollectionSortableFields {
  id = "id",
  userId = "userId",
  title = "title",
  description = "description",
  area = "area",
  saved = "saved",
  shared = "shared",
  likes = "likes",
  venueCount = "venueCount",
  headerImg = "headerImg",
  views = "views",
  lockCriteria = "lockCriteria",
  score = "score",
  areas = "areas",
  username = "username",
  city = "city",
  cities = "cities",
  status = "status",
  isPublic = "isPublic",
  dateTime = "dateTime",
  securityCode = "securityCode",
  owners = "owners",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePlaceCollectionConnection = {
  __typename: "SearchablePlaceCollectionConnection",
  items:  Array<PlaceCollection | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  cognitoUsername?: ModelStringInput | null,
  role?: ModelUserRoleInput | null,
  appVersion?: ModelStringInput | null,
  activated?: ModelStringInput | null,
  blocked?: ModelBooleanInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  placesAddedToListCount?: ModelFloatInput | null,
  bio?: ModelStringInput | null,
  preferredTitle?: ModelStringInput | null,
  dob?: ModelStringInput | null,
  linkCardRewardClaimed?: ModelStringInput | null,
  email?: ModelStringInput | null,
  stripeConnectId?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  city?: ModelStringInput | null,
  allowNotifications?: ModelBooleanInput | null,
  allowLocation?: ModelBooleanInput | null,
  allowPersonalisation?: ModelBooleanInput | null,
  allowCalendar?: ModelBooleanInput | null,
  profilePicture?: ModelStringInput | null,
  subscibedToEmails?: ModelBooleanInput | null,
  points?: ModelIntInput | null,
  accumulatedPoints?: ModelIntInput | null,
  isStudent?: ModelBooleanInput | null,
  lastLoggedIn?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  isInfluencer?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  termsSigned?: ModelBooleanInput | null,
  termsVersionSigned?: ModelStringInput | null,
  EPOSId?: ModelFloatInput | null,
  appConfigs?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type SearchableUserFilterInput = {
  id?: SearchableIDFilterInput | null,
  cognitoUsername?: SearchableStringFilterInput | null,
  appVersion?: SearchableStringFilterInput | null,
  activated?: SearchableStringFilterInput | null,
  blocked?: SearchableBooleanFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  placesAddedToListCount?: SearchableFloatFilterInput | null,
  bio?: SearchableStringFilterInput | null,
  preferredTitle?: SearchableStringFilterInput | null,
  dob?: SearchableStringFilterInput | null,
  linkCardRewardClaimed?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  stripeConnectId?: SearchableStringFilterInput | null,
  stripeCustomerId?: SearchableStringFilterInput | null,
  gender?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  allowNotifications?: SearchableBooleanFilterInput | null,
  allowLocation?: SearchableBooleanFilterInput | null,
  allowPersonalisation?: SearchableBooleanFilterInput | null,
  allowCalendar?: SearchableBooleanFilterInput | null,
  profilePicture?: SearchableStringFilterInput | null,
  subscibedToEmails?: SearchableBooleanFilterInput | null,
  points?: SearchableIntFilterInput | null,
  accumulatedPoints?: SearchableIntFilterInput | null,
  isStudent?: SearchableBooleanFilterInput | null,
  lastLoggedIn?: SearchableStringFilterInput | null,
  locale?: SearchableStringFilterInput | null,
  isInfluencer?: SearchableStringFilterInput | null,
  summary?: SearchableStringFilterInput | null,
  categories?: SearchableStringFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  termsSigned?: SearchableBooleanFilterInput | null,
  termsVersionSigned?: SearchableStringFilterInput | null,
  EPOSId?: SearchableFloatFilterInput | null,
  appConfigs?: SearchableStringFilterInput | null,
  disabled?: SearchableBooleanFilterInput | null,
  username?: SearchableStringFilterInput | null,
  and?: Array< SearchableUserFilterInput | null > | null,
  or?: Array< SearchableUserFilterInput | null > | null,
  not?: SearchableUserFilterInput | null,
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableUserSortableFields {
  id = "id",
  cognitoUsername = "cognitoUsername",
  appVersion = "appVersion",
  activated = "activated",
  blocked = "blocked",
  firstName = "firstName",
  lastName = "lastName",
  placesAddedToListCount = "placesAddedToListCount",
  bio = "bio",
  preferredTitle = "preferredTitle",
  dob = "dob",
  linkCardRewardClaimed = "linkCardRewardClaimed",
  email = "email",
  stripeConnectId = "stripeConnectId",
  stripeCustomerId = "stripeCustomerId",
  gender = "gender",
  phone = "phone",
  city = "city",
  allowNotifications = "allowNotifications",
  allowLocation = "allowLocation",
  allowPersonalisation = "allowPersonalisation",
  allowCalendar = "allowCalendar",
  profilePicture = "profilePicture",
  subscibedToEmails = "subscibedToEmails",
  points = "points",
  accumulatedPoints = "accumulatedPoints",
  isStudent = "isStudent",
  lastLoggedIn = "lastLoggedIn",
  locale = "locale",
  isInfluencer = "isInfluencer",
  summary = "summary",
  categories = "categories",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  termsSigned = "termsSigned",
  termsVersionSigned = "termsVersionSigned",
  EPOSId = "EPOSId",
  appConfigs = "appConfigs",
  disabled = "disabled",
  username = "username",
}


export type SearchableUserConnection = {
  __typename: "SearchableUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelSubscriberFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  publisherId?: ModelIDInput | null,
  userFullName?: ModelStringInput | null,
  subscriptionArn?: ModelStringInput | null,
  subscriptionArns?: ModelStringInput | null,
  notificationsEnabled?: ModelBooleanInput | null,
  credit?: ModelFloatInput | null,
  loyaltyPoints?: ModelFloatInput | null,
  loyaltyStatus?: ModelStringInput | null,
  EPOSId?: ModelStringInput | null,
  type?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdAt?: ModelFloatInput | null,
  updatedAt?: ModelFloatInput | null,
  and?: Array< ModelSubscriberFilterInput | null > | null,
  or?: Array< ModelSubscriberFilterInput | null > | null,
  not?: ModelSubscriberFilterInput | null,
};

export type SearchableSubscriberFilterInput = {
  id?: SearchableIDFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  entityId?: SearchableIDFilterInput | null,
  publisherId?: SearchableIDFilterInput | null,
  userFullName?: SearchableStringFilterInput | null,
  subscriptionArn?: SearchableStringFilterInput | null,
  subscriptionArns?: SearchableStringFilterInput | null,
  notificationsEnabled?: SearchableBooleanFilterInput | null,
  credit?: SearchableFloatFilterInput | null,
  loyaltyPoints?: SearchableFloatFilterInput | null,
  loyaltyStatus?: SearchableStringFilterInput | null,
  EPOSId?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  createdAt?: SearchableFloatFilterInput | null,
  updatedAt?: SearchableFloatFilterInput | null,
  and?: Array< SearchableSubscriberFilterInput | null > | null,
  or?: Array< SearchableSubscriberFilterInput | null > | null,
  not?: SearchableSubscriberFilterInput | null,
};

export type SearchableSubscriberSortInput = {
  field?: SearchableSubscriberSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableSubscriberSortableFields {
  id = "id",
  userId = "userId",
  entityId = "entityId",
  publisherId = "publisherId",
  userFullName = "userFullName",
  subscriptionArn = "subscriptionArn",
  subscriptionArns = "subscriptionArns",
  notificationsEnabled = "notificationsEnabled",
  credit = "credit",
  loyaltyPoints = "loyaltyPoints",
  loyaltyStatus = "loyaltyStatus",
  EPOSId = "EPOSId",
  type = "type",
  status = "status",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableSubscriberConnection = {
  __typename: "SearchableSubscriberConnection",
  items:  Array<Subscriber | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type CreateCountersMutationVariables = {
  input: CreateCountersInput,
  condition?: ModelCountersConditionInput | null,
};

export type CreateCountersMutation = {
  createCounters?:  {
    __typename: "Counters",
    id: string,
    parentId: string,
    itemId: string,
    attending?: number | null,
    views?: number | null,
    week?: string | null,
    booked?: number | null,
    transactions?: number | null,
    idTypeKey?: string | null,
    bookings_clicked?: number | null,
    subscribed?: number | null,
    unsubscribed?: number | null,
    checkins?: number | null,
    list?: number | null,
    shared?: number | null,
    visits?: number | null,
    reviews?: number | null,
    offersClaimed?: number | null,
    offersRedeemed?: number | null,
    loyaltyPoints?: number | null,
    loyaltyCard?: number | null,
    scan?: number | null,
    score?: number | null,
    ttl?: number | null,
    reviewScore?: number | null,
    date?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type UpdateCountersMutationVariables = {
  input: UpdateCountersInput,
  condition?: ModelCountersConditionInput | null,
};

export type UpdateCountersMutation = {
  updateCounters?:  {
    __typename: "Counters",
    id: string,
    parentId: string,
    itemId: string,
    attending?: number | null,
    views?: number | null,
    week?: string | null,
    booked?: number | null,
    transactions?: number | null,
    idTypeKey?: string | null,
    bookings_clicked?: number | null,
    subscribed?: number | null,
    unsubscribed?: number | null,
    checkins?: number | null,
    list?: number | null,
    shared?: number | null,
    visits?: number | null,
    reviews?: number | null,
    offersClaimed?: number | null,
    offersRedeemed?: number | null,
    loyaltyPoints?: number | null,
    loyaltyCard?: number | null,
    scan?: number | null,
    score?: number | null,
    ttl?: number | null,
    reviewScore?: number | null,
    date?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type DeleteCountersMutationVariables = {
  input: DeleteCountersInput,
  condition?: ModelCountersConditionInput | null,
};

export type DeleteCountersMutation = {
  deleteCounters?:  {
    __typename: "Counters",
    id: string,
    parentId: string,
    itemId: string,
    attending?: number | null,
    views?: number | null,
    week?: string | null,
    booked?: number | null,
    transactions?: number | null,
    idTypeKey?: string | null,
    bookings_clicked?: number | null,
    subscribed?: number | null,
    unsubscribed?: number | null,
    checkins?: number | null,
    list?: number | null,
    shared?: number | null,
    visits?: number | null,
    reviews?: number | null,
    offersClaimed?: number | null,
    offersRedeemed?: number | null,
    loyaltyPoints?: number | null,
    loyaltyCard?: number | null,
    scan?: number | null,
    score?: number | null,
    ttl?: number | null,
    reviewScore?: number | null,
    date?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type CreateDailyCountersMutationVariables = {
  input: CreateDailyCountersInput,
  condition?: ModelDailyCountersConditionInput | null,
};

export type CreateDailyCountersMutation = {
  createDailyCounters?:  {
    __typename: "DailyCounters",
    id: string,
    parentId: string,
    itemId: string,
    attending?: number | null,
    views?: number | null,
    week?: string | null,
    booked?: number | null,
    bookings_clicked?: number | null,
    subscribed?: number | null,
    unsubscribed?: number | null,
    checkins?: number | null,
    shared?: number | null,
    reviews?: number | null,
    offersClaimed?: number | null,
    offersRedeemed?: number | null,
    loyaltyPoints?: number | null,
    loyaltyCard?: number | null,
    scan?: number | null,
    ttl?: number | null,
    date?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type UpdateDailyCountersMutationVariables = {
  input: UpdateDailyCountersInput,
  condition?: ModelDailyCountersConditionInput | null,
};

export type UpdateDailyCountersMutation = {
  updateDailyCounters?:  {
    __typename: "DailyCounters",
    id: string,
    parentId: string,
    itemId: string,
    attending?: number | null,
    views?: number | null,
    week?: string | null,
    booked?: number | null,
    bookings_clicked?: number | null,
    subscribed?: number | null,
    unsubscribed?: number | null,
    checkins?: number | null,
    shared?: number | null,
    reviews?: number | null,
    offersClaimed?: number | null,
    offersRedeemed?: number | null,
    loyaltyPoints?: number | null,
    loyaltyCard?: number | null,
    scan?: number | null,
    ttl?: number | null,
    date?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type DeleteDailyCountersMutationVariables = {
  input: DeleteDailyCountersInput,
  condition?: ModelDailyCountersConditionInput | null,
};

export type DeleteDailyCountersMutation = {
  deleteDailyCounters?:  {
    __typename: "DailyCounters",
    id: string,
    parentId: string,
    itemId: string,
    attending?: number | null,
    views?: number | null,
    week?: string | null,
    booked?: number | null,
    bookings_clicked?: number | null,
    subscribed?: number | null,
    unsubscribed?: number | null,
    checkins?: number | null,
    shared?: number | null,
    reviews?: number | null,
    offersClaimed?: number | null,
    offersRedeemed?: number | null,
    loyaltyPoints?: number | null,
    loyaltyCard?: number | null,
    scan?: number | null,
    ttl?: number | null,
    date?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type CreateUserInteractionMutationVariables = {
  input: CreateUserInteractionInput,
  condition?: ModelUserInteractionConditionInput | null,
};

export type CreateUserInteractionMutation = {
  createUserInteraction?:  {
    __typename: "UserInteraction",
    id: string,
    type?: string | null,
    itemId?: string | null,
    categories?: Array< string | null > | null,
    itemType?: string | null,
    city?: string | null,
    itemTitle?: string | null,
    userId?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateUserInteractionMutationVariables = {
  input: UpdateUserInteractionInput,
  condition?: ModelUserInteractionConditionInput | null,
};

export type UpdateUserInteractionMutation = {
  updateUserInteraction?:  {
    __typename: "UserInteraction",
    id: string,
    type?: string | null,
    itemId?: string | null,
    categories?: Array< string | null > | null,
    itemType?: string | null,
    city?: string | null,
    itemTitle?: string | null,
    userId?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteUserInteractionMutationVariables = {
  input: DeleteUserInteractionInput,
  condition?: ModelUserInteractionConditionInput | null,
};

export type DeleteUserInteractionMutation = {
  deleteUserInteraction?:  {
    __typename: "UserInteraction",
    id: string,
    type?: string | null,
    itemId?: string | null,
    categories?: Array< string | null > | null,
    itemType?: string | null,
    city?: string | null,
    itemTitle?: string | null,
    userId?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateTrackingActivityStatMutationVariables = {
  input: UpdateTrackingActivityStatInput,
  condition?: ModelTrackingActivityStatConditionInput | null,
};

export type UpdateTrackingActivityStatMutation = {
  updateTrackingActivityStat?:  {
    __typename: "TrackingActivityStat",
    id: string,
    action?: string | null,
    itemType?: string | null,
    itemId?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    gender?: string | null,
    city?: string | null,
    owners: Array< string | null >,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteTrackingActivityStatMutationVariables = {
  input: DeleteTrackingActivityStatInput,
  condition?: ModelTrackingActivityStatConditionInput | null,
};

export type DeleteTrackingActivityStatMutation = {
  deleteTrackingActivityStat?:  {
    __typename: "TrackingActivityStat",
    id: string,
    action?: string | null,
    itemType?: string | null,
    itemId?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    gender?: string | null,
    city?: string | null,
    owners: Array< string | null >,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateArtistMutationVariables = {
  input: CreateArtistInput,
  condition?: ModelArtistConditionInput | null,
};

export type CreateArtistMutation = {
  createArtist?:  {
    __typename: "Artist",
    id: string,
    title: string,
    country?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    spotifyAppUri?: string | null,
    videoUrls?: Array< string | null > | null,
    nickname?: string | null,
    bookingConfig?:  {
      __typename: "ArtistBookingConfig",
      bookingsEnabled?: boolean | null,
      messageAutoResponse?: string | null,
      emailsDisabled?: boolean | null,
      hourlyRate?: number | null,
      flatRate?: number | null,
      tastersAavailable?: number | null,
      tasterDurationInMins?: number | null,
      flatRateDurationInMins?: number | null,
      countriesAvailable?: Array< string | null > | null,
      citiesAvailable?: Array< string | null > | null,
    } | null,
    rating?: number | null,
    pingGigsPlayed?: number | null,
    numReviews?: number | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    logo?: string | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    soundcloudAppUri?: string | null,
    appleMusicAppUri?: string | null,
    youtubeUrl?: string | null,
    spotifyUrl?: string | null,
    deezerUrl?: string | null,
    mixcloudUrl?: string | null,
    stripeConnectId?: string | null,
    amazonMusicUrl?: string | null,
    stripeCustomerId?: string | null,
    tidalUrl?: string | null,
    soundcloudUrl?: string | null,
    appleMusicUrl?: string | null,
    description?: string | null,
    genres?: Array< string | null > | null,
    images?: Array< string | null > | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    reviews?:  {
      __typename: "ModelArtistReviewConnection",
      items:  Array< {
        __typename: "ArtistReview",
        id: string,
        review?: string | null,
        score?: number | null,
        artistId: string,
        dateOfVisit?: number | null,
        publisherId: string,
        entityId: string,
        status?: string | null,
        entityTitle?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateArtistMutationVariables = {
  input: UpdateArtistInput,
  condition?: ModelArtistConditionInput | null,
};

export type UpdateArtistMutation = {
  updateArtist?:  {
    __typename: "Artist",
    id: string,
    title: string,
    country?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    spotifyAppUri?: string | null,
    videoUrls?: Array< string | null > | null,
    nickname?: string | null,
    bookingConfig?:  {
      __typename: "ArtistBookingConfig",
      bookingsEnabled?: boolean | null,
      messageAutoResponse?: string | null,
      emailsDisabled?: boolean | null,
      hourlyRate?: number | null,
      flatRate?: number | null,
      tastersAavailable?: number | null,
      tasterDurationInMins?: number | null,
      flatRateDurationInMins?: number | null,
      countriesAvailable?: Array< string | null > | null,
      citiesAvailable?: Array< string | null > | null,
    } | null,
    rating?: number | null,
    pingGigsPlayed?: number | null,
    numReviews?: number | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    logo?: string | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    soundcloudAppUri?: string | null,
    appleMusicAppUri?: string | null,
    youtubeUrl?: string | null,
    spotifyUrl?: string | null,
    deezerUrl?: string | null,
    mixcloudUrl?: string | null,
    stripeConnectId?: string | null,
    amazonMusicUrl?: string | null,
    stripeCustomerId?: string | null,
    tidalUrl?: string | null,
    soundcloudUrl?: string | null,
    appleMusicUrl?: string | null,
    description?: string | null,
    genres?: Array< string | null > | null,
    images?: Array< string | null > | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    reviews?:  {
      __typename: "ModelArtistReviewConnection",
      items:  Array< {
        __typename: "ArtistReview",
        id: string,
        review?: string | null,
        score?: number | null,
        artistId: string,
        dateOfVisit?: number | null,
        publisherId: string,
        entityId: string,
        status?: string | null,
        entityTitle?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteArtistMutationVariables = {
  input: DeleteArtistInput,
  condition?: ModelArtistConditionInput | null,
};

export type DeleteArtistMutation = {
  deleteArtist?:  {
    __typename: "Artist",
    id: string,
    title: string,
    country?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    spotifyAppUri?: string | null,
    videoUrls?: Array< string | null > | null,
    nickname?: string | null,
    bookingConfig?:  {
      __typename: "ArtistBookingConfig",
      bookingsEnabled?: boolean | null,
      messageAutoResponse?: string | null,
      emailsDisabled?: boolean | null,
      hourlyRate?: number | null,
      flatRate?: number | null,
      tastersAavailable?: number | null,
      tasterDurationInMins?: number | null,
      flatRateDurationInMins?: number | null,
      countriesAvailable?: Array< string | null > | null,
      citiesAvailable?: Array< string | null > | null,
    } | null,
    rating?: number | null,
    pingGigsPlayed?: number | null,
    numReviews?: number | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    logo?: string | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    soundcloudAppUri?: string | null,
    appleMusicAppUri?: string | null,
    youtubeUrl?: string | null,
    spotifyUrl?: string | null,
    deezerUrl?: string | null,
    mixcloudUrl?: string | null,
    stripeConnectId?: string | null,
    amazonMusicUrl?: string | null,
    stripeCustomerId?: string | null,
    tidalUrl?: string | null,
    soundcloudUrl?: string | null,
    appleMusicUrl?: string | null,
    description?: string | null,
    genres?: Array< string | null > | null,
    images?: Array< string | null > | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    reviews?:  {
      __typename: "ModelArtistReviewConnection",
      items:  Array< {
        __typename: "ArtistReview",
        id: string,
        review?: string | null,
        score?: number | null,
        artistId: string,
        dateOfVisit?: number | null,
        publisherId: string,
        entityId: string,
        status?: string | null,
        entityTitle?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateArtistReviewMutationVariables = {
  input: CreateArtistReviewInput,
  condition?: ModelArtistReviewConditionInput | null,
};

export type CreateArtistReviewMutation = {
  createArtistReview?:  {
    __typename: "ArtistReview",
    id: string,
    review?: string | null,
    score?: number | null,
    artistId: string,
    dateOfVisit?: number | null,
    publisherId: string,
    entityId: string,
    status?: string | null,
    entityTitle?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type UpdateArtistReviewMutationVariables = {
  input: UpdateArtistReviewInput,
  condition?: ModelArtistReviewConditionInput | null,
};

export type UpdateArtistReviewMutation = {
  updateArtistReview?:  {
    __typename: "ArtistReview",
    id: string,
    review?: string | null,
    score?: number | null,
    artistId: string,
    dateOfVisit?: number | null,
    publisherId: string,
    entityId: string,
    status?: string | null,
    entityTitle?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type DeleteArtistReviewMutationVariables = {
  input: DeleteArtistReviewInput,
  condition?: ModelArtistReviewConditionInput | null,
};

export type DeleteArtistReviewMutation = {
  deleteArtistReview?:  {
    __typename: "ArtistReview",
    id: string,
    review?: string | null,
    score?: number | null,
    artistId: string,
    dateOfVisit?: number | null,
    publisherId: string,
    entityId: string,
    status?: string | null,
    entityTitle?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type CreateArtistEventPositionMutationVariables = {
  input: CreateArtistEventPositionInput,
  condition?: ModelArtistEventPositionConditionInput | null,
};

export type CreateArtistEventPositionMutation = {
  createArtistEventPosition?:  {
    __typename: "ArtistEventPosition",
    id: string,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    eventDate?: number | null,
    city?: string | null,
    ttl?: number | null,
    createdAt: number,
    updatedAt: number,
    artist?:  {
      __typename: "Artist",
      id: string,
      title: string,
      country?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      spotifyAppUri?: string | null,
      videoUrls?: Array< string | null > | null,
      nickname?: string | null,
      bookingConfig?:  {
        __typename: "ArtistBookingConfig",
        bookingsEnabled?: boolean | null,
        messageAutoResponse?: string | null,
        emailsDisabled?: boolean | null,
        hourlyRate?: number | null,
        flatRate?: number | null,
        tastersAavailable?: number | null,
        tasterDurationInMins?: number | null,
        flatRateDurationInMins?: number | null,
        countriesAvailable?: Array< string | null > | null,
        citiesAvailable?: Array< string | null > | null,
      } | null,
      rating?: number | null,
      pingGigsPlayed?: number | null,
      numReviews?: number | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      logo?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      soundcloudAppUri?: string | null,
      appleMusicAppUri?: string | null,
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      stripeConnectId?: string | null,
      amazonMusicUrl?: string | null,
      stripeCustomerId?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
      description?: string | null,
      genres?: Array< string | null > | null,
      images?: Array< string | null > | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      reviews?:  {
        __typename: "ModelArtistReviewConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateArtistEventPositionMutationVariables = {
  input: UpdateArtistEventPositionInput,
  condition?: ModelArtistEventPositionConditionInput | null,
};

export type UpdateArtistEventPositionMutation = {
  updateArtistEventPosition?:  {
    __typename: "ArtistEventPosition",
    id: string,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    eventDate?: number | null,
    city?: string | null,
    ttl?: number | null,
    createdAt: number,
    updatedAt: number,
    artist?:  {
      __typename: "Artist",
      id: string,
      title: string,
      country?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      spotifyAppUri?: string | null,
      videoUrls?: Array< string | null > | null,
      nickname?: string | null,
      bookingConfig?:  {
        __typename: "ArtistBookingConfig",
        bookingsEnabled?: boolean | null,
        messageAutoResponse?: string | null,
        emailsDisabled?: boolean | null,
        hourlyRate?: number | null,
        flatRate?: number | null,
        tastersAavailable?: number | null,
        tasterDurationInMins?: number | null,
        flatRateDurationInMins?: number | null,
        countriesAvailable?: Array< string | null > | null,
        citiesAvailable?: Array< string | null > | null,
      } | null,
      rating?: number | null,
      pingGigsPlayed?: number | null,
      numReviews?: number | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      logo?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      soundcloudAppUri?: string | null,
      appleMusicAppUri?: string | null,
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      stripeConnectId?: string | null,
      amazonMusicUrl?: string | null,
      stripeCustomerId?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
      description?: string | null,
      genres?: Array< string | null > | null,
      images?: Array< string | null > | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      reviews?:  {
        __typename: "ModelArtistReviewConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteArtistEventPositionMutationVariables = {
  input: DeleteArtistEventPositionInput,
  condition?: ModelArtistEventPositionConditionInput | null,
};

export type DeleteArtistEventPositionMutation = {
  deleteArtistEventPosition?:  {
    __typename: "ArtistEventPosition",
    id: string,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    eventDate?: number | null,
    city?: string | null,
    ttl?: number | null,
    createdAt: number,
    updatedAt: number,
    artist?:  {
      __typename: "Artist",
      id: string,
      title: string,
      country?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      spotifyAppUri?: string | null,
      videoUrls?: Array< string | null > | null,
      nickname?: string | null,
      bookingConfig?:  {
        __typename: "ArtistBookingConfig",
        bookingsEnabled?: boolean | null,
        messageAutoResponse?: string | null,
        emailsDisabled?: boolean | null,
        hourlyRate?: number | null,
        flatRate?: number | null,
        tastersAavailable?: number | null,
        tasterDurationInMins?: number | null,
        flatRateDurationInMins?: number | null,
        countriesAvailable?: Array< string | null > | null,
        citiesAvailable?: Array< string | null > | null,
      } | null,
      rating?: number | null,
      pingGigsPlayed?: number | null,
      numReviews?: number | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      logo?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      soundcloudAppUri?: string | null,
      appleMusicAppUri?: string | null,
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      stripeConnectId?: string | null,
      amazonMusicUrl?: string | null,
      stripeCustomerId?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
      description?: string | null,
      genres?: Array< string | null > | null,
      images?: Array< string | null > | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      reviews?:  {
        __typename: "ModelArtistReviewConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateBlogPostMutationVariables = {
  input: CreateBlogPostInput,
  condition?: ModelBlogPostConditionInput | null,
};

export type CreateBlogPostMutation = {
  createBlogPost?:  {
    __typename: "BlogPost",
    id: string,
    postType?: string | null,
    title?: string | null,
    desc?: string | null,
    city: string,
    area?: string | null,
    catgories?: Array< string | null > | null,
    headerImg?: string | null,
    readCount?: number | null,
    shareCount?: number | null,
    metaDesc?: string | null,
    metaTitle?: string | null,
    videoUrl?: string | null,
    instagram?: string | null,
    twitter?: string | null,
    website?: string | null,
    facebook?: string | null,
    userId?: string | null,
    publishStatus?: string | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    content?:  Array< {
      __typename: "BlogSectionContent",
      title?: string | null,
      image?: string | null,
      content?: string | null,
    } | null > | null,
    summary?:  Array< {
      __typename: "BlogSummary",
      title?: string | null,
      content?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entities?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelBlogPostEventConnection",
      items:  Array< {
        __typename: "BlogPostEvent",
        id: string,
        eventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelBlogPostRepeatEventConnection",
      items:  Array< {
        __typename: "BlogPostRepeatEvent",
        id: string,
        repeatEventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateBlogPostMutationVariables = {
  input: UpdateBlogPostInput,
  condition?: ModelBlogPostConditionInput | null,
};

export type UpdateBlogPostMutation = {
  updateBlogPost?:  {
    __typename: "BlogPost",
    id: string,
    postType?: string | null,
    title?: string | null,
    desc?: string | null,
    city: string,
    area?: string | null,
    catgories?: Array< string | null > | null,
    headerImg?: string | null,
    readCount?: number | null,
    shareCount?: number | null,
    metaDesc?: string | null,
    metaTitle?: string | null,
    videoUrl?: string | null,
    instagram?: string | null,
    twitter?: string | null,
    website?: string | null,
    facebook?: string | null,
    userId?: string | null,
    publishStatus?: string | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    content?:  Array< {
      __typename: "BlogSectionContent",
      title?: string | null,
      image?: string | null,
      content?: string | null,
    } | null > | null,
    summary?:  Array< {
      __typename: "BlogSummary",
      title?: string | null,
      content?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entities?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelBlogPostEventConnection",
      items:  Array< {
        __typename: "BlogPostEvent",
        id: string,
        eventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelBlogPostRepeatEventConnection",
      items:  Array< {
        __typename: "BlogPostRepeatEvent",
        id: string,
        repeatEventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteBlogPostMutationVariables = {
  input: DeleteBlogPostInput,
  condition?: ModelBlogPostConditionInput | null,
};

export type DeleteBlogPostMutation = {
  deleteBlogPost?:  {
    __typename: "BlogPost",
    id: string,
    postType?: string | null,
    title?: string | null,
    desc?: string | null,
    city: string,
    area?: string | null,
    catgories?: Array< string | null > | null,
    headerImg?: string | null,
    readCount?: number | null,
    shareCount?: number | null,
    metaDesc?: string | null,
    metaTitle?: string | null,
    videoUrl?: string | null,
    instagram?: string | null,
    twitter?: string | null,
    website?: string | null,
    facebook?: string | null,
    userId?: string | null,
    publishStatus?: string | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    content?:  Array< {
      __typename: "BlogSectionContent",
      title?: string | null,
      image?: string | null,
      content?: string | null,
    } | null > | null,
    summary?:  Array< {
      __typename: "BlogSummary",
      title?: string | null,
      content?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entities?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelBlogPostEventConnection",
      items:  Array< {
        __typename: "BlogPostEvent",
        id: string,
        eventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelBlogPostRepeatEventConnection",
      items:  Array< {
        __typename: "BlogPostRepeatEvent",
        id: string,
        repeatEventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateBlogPostEntityMutationVariables = {
  input: CreateBlogPostEntityInput,
  condition?: ModelBlogPostEntityConditionInput | null,
};

export type CreateBlogPostEntityMutation = {
  createBlogPostEntity?:  {
    __typename: "BlogPostEntity",
    id: string,
    entityId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateBlogPostEntityMutationVariables = {
  input: UpdateBlogPostEntityInput,
  condition?: ModelBlogPostEntityConditionInput | null,
};

export type UpdateBlogPostEntityMutation = {
  updateBlogPostEntity?:  {
    __typename: "BlogPostEntity",
    id: string,
    entityId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteBlogPostEntityMutationVariables = {
  input: DeleteBlogPostEntityInput,
  condition?: ModelBlogPostEntityConditionInput | null,
};

export type DeleteBlogPostEntityMutation = {
  deleteBlogPostEntity?:  {
    __typename: "BlogPostEntity",
    id: string,
    entityId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateBlogPostEventMutationVariables = {
  input: CreateBlogPostEventInput,
  condition?: ModelBlogPostEventConditionInput | null,
};

export type CreateBlogPostEventMutation = {
  createBlogPostEvent?:  {
    __typename: "BlogPostEvent",
    id: string,
    eventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateBlogPostEventMutationVariables = {
  input: UpdateBlogPostEventInput,
  condition?: ModelBlogPostEventConditionInput | null,
};

export type UpdateBlogPostEventMutation = {
  updateBlogPostEvent?:  {
    __typename: "BlogPostEvent",
    id: string,
    eventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteBlogPostEventMutationVariables = {
  input: DeleteBlogPostEventInput,
  condition?: ModelBlogPostEventConditionInput | null,
};

export type DeleteBlogPostEventMutation = {
  deleteBlogPostEvent?:  {
    __typename: "BlogPostEvent",
    id: string,
    eventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateBlogPostRepeatEventMutationVariables = {
  input: CreateBlogPostRepeatEventInput,
  condition?: ModelBlogPostRepeatEventConditionInput | null,
};

export type CreateBlogPostRepeatEventMutation = {
  createBlogPostRepeatEvent?:  {
    __typename: "BlogPostRepeatEvent",
    id: string,
    repeatEventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    repeatEvent:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateBlogPostRepeatEventMutationVariables = {
  input: UpdateBlogPostRepeatEventInput,
  condition?: ModelBlogPostRepeatEventConditionInput | null,
};

export type UpdateBlogPostRepeatEventMutation = {
  updateBlogPostRepeatEvent?:  {
    __typename: "BlogPostRepeatEvent",
    id: string,
    repeatEventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    repeatEvent:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteBlogPostRepeatEventMutationVariables = {
  input: DeleteBlogPostRepeatEventInput,
  condition?: ModelBlogPostRepeatEventConditionInput | null,
};

export type DeleteBlogPostRepeatEventMutation = {
  deleteBlogPostRepeatEvent?:  {
    __typename: "BlogPostRepeatEvent",
    id: string,
    repeatEventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    repeatEvent:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateBookableTableMutationVariables = {
  input: CreateBookableTableInput,
  condition?: ModelBookableTableConditionInput | null,
};

export type CreateBookableTableMutation = {
  createBookableTable?:  {
    __typename: "BookableTable",
    id: string,
    tableNumber?: number | null,
    capacity?: number | null,
    area?: string | null,
    entityId: string,
    ttl?: number | null,
    maxPeople?: number | null,
    minPeople?: number | null,
    availability?:  Array< {
      __typename: "TableAvailability",
      startTime?: string | null,
      endTime?: string | null,
    } | null > | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    bookings?:  {
      __typename: "ModelBookingsConnection",
      items:  Array< {
        __typename: "Bookings",
        id: string,
        userName: string,
        userId: string,
        publisher?: string | null,
        bookingType?: string | null,
        entityId: string,
        venueNotes?: string | null,
        durationInMinutes?: number | null,
        entityTitle?: string | null,
        userEmail?: string | null,
        userPhone?: string | null,
        confirmed?: boolean | null,
        owners: Array< string | null >,
        owner?: string | null,
        dateTime: string,
        tableId: string,
        timeString?: string | null,
        provider?: BookingSystemProvider | null,
        dateString?: string | null,
        dateTimestamp: number,
        people: number,
        artistId?: string | null,
        notes?: string | null,
        status: string,
        ttl?: number | null,
        paymentStatus?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateBookableTableMutationVariables = {
  input: UpdateBookableTableInput,
  condition?: ModelBookableTableConditionInput | null,
};

export type UpdateBookableTableMutation = {
  updateBookableTable?:  {
    __typename: "BookableTable",
    id: string,
    tableNumber?: number | null,
    capacity?: number | null,
    area?: string | null,
    entityId: string,
    ttl?: number | null,
    maxPeople?: number | null,
    minPeople?: number | null,
    availability?:  Array< {
      __typename: "TableAvailability",
      startTime?: string | null,
      endTime?: string | null,
    } | null > | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    bookings?:  {
      __typename: "ModelBookingsConnection",
      items:  Array< {
        __typename: "Bookings",
        id: string,
        userName: string,
        userId: string,
        publisher?: string | null,
        bookingType?: string | null,
        entityId: string,
        venueNotes?: string | null,
        durationInMinutes?: number | null,
        entityTitle?: string | null,
        userEmail?: string | null,
        userPhone?: string | null,
        confirmed?: boolean | null,
        owners: Array< string | null >,
        owner?: string | null,
        dateTime: string,
        tableId: string,
        timeString?: string | null,
        provider?: BookingSystemProvider | null,
        dateString?: string | null,
        dateTimestamp: number,
        people: number,
        artistId?: string | null,
        notes?: string | null,
        status: string,
        ttl?: number | null,
        paymentStatus?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteBookableTableMutationVariables = {
  input: DeleteBookableTableInput,
  condition?: ModelBookableTableConditionInput | null,
};

export type DeleteBookableTableMutation = {
  deleteBookableTable?:  {
    __typename: "BookableTable",
    id: string,
    tableNumber?: number | null,
    capacity?: number | null,
    area?: string | null,
    entityId: string,
    ttl?: number | null,
    maxPeople?: number | null,
    minPeople?: number | null,
    availability?:  Array< {
      __typename: "TableAvailability",
      startTime?: string | null,
      endTime?: string | null,
    } | null > | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    bookings?:  {
      __typename: "ModelBookingsConnection",
      items:  Array< {
        __typename: "Bookings",
        id: string,
        userName: string,
        userId: string,
        publisher?: string | null,
        bookingType?: string | null,
        entityId: string,
        venueNotes?: string | null,
        durationInMinutes?: number | null,
        entityTitle?: string | null,
        userEmail?: string | null,
        userPhone?: string | null,
        confirmed?: boolean | null,
        owners: Array< string | null >,
        owner?: string | null,
        dateTime: string,
        tableId: string,
        timeString?: string | null,
        provider?: BookingSystemProvider | null,
        dateString?: string | null,
        dateTimestamp: number,
        people: number,
        artistId?: string | null,
        notes?: string | null,
        status: string,
        ttl?: number | null,
        paymentStatus?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateConvoLinkMutationVariables = {
  input: CreateConvoLinkInput,
  condition?: ModelConvoLinkConditionInput | null,
};

export type CreateConvoLinkMutation = {
  createConvoLink?:  {
    __typename: "ConvoLink",
    id: string,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
    owners: Array< string | null >,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        messageConversationId: string,
        content: string,
        contentType?: string | null,
        author: string,
        entity: string,
        receiver: string,
        owners: Array< string | null >,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisherId: string,
    entityId: string,
    userId: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateConvoLinkMutationVariables = {
  input: UpdateConvoLinkInput,
  condition?: ModelConvoLinkConditionInput | null,
};

export type UpdateConvoLinkMutation = {
  updateConvoLink?:  {
    __typename: "ConvoLink",
    id: string,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
    owners: Array< string | null >,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        messageConversationId: string,
        content: string,
        contentType?: string | null,
        author: string,
        entity: string,
        receiver: string,
        owners: Array< string | null >,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisherId: string,
    entityId: string,
    userId: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteConvoLinkMutationVariables = {
  input: DeleteConvoLinkInput,
  condition?: ModelConvoLinkConditionInput | null,
};

export type DeleteConvoLinkMutation = {
  deleteConvoLink?:  {
    __typename: "ConvoLink",
    id: string,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
    owners: Array< string | null >,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        messageConversationId: string,
        content: string,
        contentType?: string | null,
        author: string,
        entity: string,
        receiver: string,
        owners: Array< string | null >,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisherId: string,
    entityId: string,
    userId: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    messageConversationId: string,
    conversation?:  {
      __typename: "ConvoLink",
      id: string,
      status?: string | null,
      createdAt: number,
      updatedAt: number,
      owners: Array< string | null >,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      publisherId: string,
      entityId: string,
      userId: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null,
    content: string,
    contentType?: string | null,
    author: string,
    entity: string,
    receiver: string,
    owners: Array< string | null >,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    messageConversationId: string,
    conversation?:  {
      __typename: "ConvoLink",
      id: string,
      status?: string | null,
      createdAt: number,
      updatedAt: number,
      owners: Array< string | null >,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      publisherId: string,
      entityId: string,
      userId: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null,
    content: string,
    contentType?: string | null,
    author: string,
    entity: string,
    receiver: string,
    owners: Array< string | null >,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    messageConversationId: string,
    conversation?:  {
      __typename: "ConvoLink",
      id: string,
      status?: string | null,
      createdAt: number,
      updatedAt: number,
      owners: Array< string | null >,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      publisherId: string,
      entityId: string,
      userId: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null,
    content: string,
    contentType?: string | null,
    author: string,
    entity: string,
    receiver: string,
    owners: Array< string | null >,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type UpdateCheckInMutationVariables = {
  input: UpdateCheckInInput,
  condition?: ModelCheckInConditionInput | null,
};

export type UpdateCheckInMutation = {
  updateCheckIn?:  {
    __typename: "CheckIn",
    id: string,
    userId: string,
    entityId: string,
    fullName?: string | null,
    phone?: string | null,
    partySize?: number | null,
    gender?: string | null,
    dob?: string | null,
    owners?: Array< string | null > | null,
    age?: number | null,
    email?: string | null,
    ttl?: number | null,
    status?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    updatedAt?: number | null,
    createdAt: number,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteCheckInMutationVariables = {
  input: DeleteCheckInInput,
  condition?: ModelCheckInConditionInput | null,
};

export type DeleteCheckInMutation = {
  deleteCheckIn?:  {
    __typename: "CheckIn",
    id: string,
    userId: string,
    entityId: string,
    fullName?: string | null,
    phone?: string | null,
    partySize?: number | null,
    gender?: string | null,
    dob?: string | null,
    owners?: Array< string | null > | null,
    age?: number | null,
    email?: string | null,
    ttl?: number | null,
    status?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    updatedAt?: number | null,
    createdAt: number,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCityMutationVariables = {
  input: CreateCityInput,
  condition?: ModelCityConditionInput | null,
};

export type CreateCityMutation = {
  createCity?:  {
    __typename: "City",
    id: string,
    title: string,
    venueCount?: number | null,
    areas?:  Array< {
      __typename: "Area",
      title?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      parentArea?: string | null,
      images?: Array< string | null > | null,
    } | null > | null,
    description?: string | null,
    country: string,
    continent?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    timezoneOffset?: number | null,
    currency?: string | null,
    images?: Array< string | null > | null,
    createdAt: number,
    updatedAt: number,
    config?:  {
      __typename: "CityConfig",
      events?: boolean | null,
      places?: boolean | null,
    } | null,
  } | null,
};

export type UpdateCityMutationVariables = {
  input: UpdateCityInput,
  condition?: ModelCityConditionInput | null,
};

export type UpdateCityMutation = {
  updateCity?:  {
    __typename: "City",
    id: string,
    title: string,
    venueCount?: number | null,
    areas?:  Array< {
      __typename: "Area",
      title?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      parentArea?: string | null,
      images?: Array< string | null > | null,
    } | null > | null,
    description?: string | null,
    country: string,
    continent?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    timezoneOffset?: number | null,
    currency?: string | null,
    images?: Array< string | null > | null,
    createdAt: number,
    updatedAt: number,
    config?:  {
      __typename: "CityConfig",
      events?: boolean | null,
      places?: boolean | null,
    } | null,
  } | null,
};

export type DeleteCityMutationVariables = {
  input: DeleteCityInput,
  condition?: ModelCityConditionInput | null,
};

export type DeleteCityMutation = {
  deleteCity?:  {
    __typename: "City",
    id: string,
    title: string,
    venueCount?: number | null,
    areas?:  Array< {
      __typename: "Area",
      title?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      parentArea?: string | null,
      images?: Array< string | null > | null,
    } | null > | null,
    description?: string | null,
    country: string,
    continent?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    timezoneOffset?: number | null,
    currency?: string | null,
    images?: Array< string | null > | null,
    createdAt: number,
    updatedAt: number,
    config?:  {
      __typename: "CityConfig",
      events?: boolean | null,
      places?: boolean | null,
    } | null,
  } | null,
};

export type CreateCouponMutationVariables = {
  input: CreateCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type CreateCouponMutation = {
  createCoupon?:  {
    __typename: "Coupon",
    id: string,
    code: string,
    discountType?: string | null,
    discountAmount?: number | null,
    status?: string | null,
    publisherId: string,
    eventType?: string | null,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCouponMutationVariables = {
  input: UpdateCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type UpdateCouponMutation = {
  updateCoupon?:  {
    __typename: "Coupon",
    id: string,
    code: string,
    discountType?: string | null,
    discountAmount?: number | null,
    status?: string | null,
    publisherId: string,
    eventType?: string | null,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCouponMutationVariables = {
  input: DeleteCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type DeleteCouponMutation = {
  deleteCoupon?:  {
    __typename: "Coupon",
    id: string,
    code: string,
    discountType?: string | null,
    discountAmount?: number | null,
    status?: string | null,
    publisherId: string,
    eventType?: string | null,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    title: string,
    entityId: string,
    logo?: string | null,
    headerVideos?: Array< string | null > | null,
    publisherId: string,
    area?: string | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    subtitle?: string | null,
    description?: string | null,
    type?: string | null,
    score?: number | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    categories?: Array< string | null > | null,
    dateTime?: number | null,
    startDate?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    endDate?: string | null,
    closingTime?: number | null,
    rules?: string | null,
    discount?: number | null,
    buyTicketUrl?: string | null,
    canBook?: boolean | null,
    isOnlineEvent?: boolean | null,
    featured?: string | null,
    video?: string | null,
    images?: Array< string | null > | null,
    eventFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    artistsList?: Array< string | null > | null,
    mapImages?: Array< string | null > | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    price?: string | null,
    city: string,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    attending?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    promoterIds?: Array< string | null > | null,
    ttl?: number | null,
    views?: number | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    status?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    pushTimestamps?: Array< number | null > | null,
    ownerType?: string | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    tablesAvailable?: number | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    influencerInfo?:  {
      __typename: "EventInfluencerInfo",
      hashtags?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      details?: string | null,
    } | null,
    username?: string | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    config?:  {
      __typename: "EventConfig",
      canBuyTickets?: boolean | null,
      ticketConfig?:  {
        __typename: "TicketConfig",
        title?: string | null,
        quantity?: number | null,
        amountSold?: number | null,
        maxPerUser?: number | null,
        images?: Array< string | null > | null,
        variationSoldCount?: number | null,
        disclaimer?: string | null,
        customEmailMessage?: string | null,
        description?: string | null,
        dateAvailableFrom?: string | null,
        dateAvailableTill?: string | null,
      } | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    blogPosts?:  {
      __typename: "ModelBlogPostEventConnection",
      items:  Array< {
        __typename: "BlogPostEvent",
        id: string,
        eventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    variations?:  {
      __typename: "ModelTicketVariationOccurenceConnection",
      items:  Array< {
        __typename: "TicketVariationOccurence",
        id: string,
        eventId?: string | null,
        repeatEventId?: string | null,
        repeatEventOccurenceId?: string | null,
        eventType?: string | null,
        variationRuleId: string,
        isSoldOut?: boolean | null,
        amountSoldCounter?: number | null,
        owners?: Array< string | null > | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    title: string,
    entityId: string,
    logo?: string | null,
    headerVideos?: Array< string | null > | null,
    publisherId: string,
    area?: string | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    subtitle?: string | null,
    description?: string | null,
    type?: string | null,
    score?: number | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    categories?: Array< string | null > | null,
    dateTime?: number | null,
    startDate?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    endDate?: string | null,
    closingTime?: number | null,
    rules?: string | null,
    discount?: number | null,
    buyTicketUrl?: string | null,
    canBook?: boolean | null,
    isOnlineEvent?: boolean | null,
    featured?: string | null,
    video?: string | null,
    images?: Array< string | null > | null,
    eventFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    artistsList?: Array< string | null > | null,
    mapImages?: Array< string | null > | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    price?: string | null,
    city: string,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    attending?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    promoterIds?: Array< string | null > | null,
    ttl?: number | null,
    views?: number | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    status?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    pushTimestamps?: Array< number | null > | null,
    ownerType?: string | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    tablesAvailable?: number | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    influencerInfo?:  {
      __typename: "EventInfluencerInfo",
      hashtags?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      details?: string | null,
    } | null,
    username?: string | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    config?:  {
      __typename: "EventConfig",
      canBuyTickets?: boolean | null,
      ticketConfig?:  {
        __typename: "TicketConfig",
        title?: string | null,
        quantity?: number | null,
        amountSold?: number | null,
        maxPerUser?: number | null,
        images?: Array< string | null > | null,
        variationSoldCount?: number | null,
        disclaimer?: string | null,
        customEmailMessage?: string | null,
        description?: string | null,
        dateAvailableFrom?: string | null,
        dateAvailableTill?: string | null,
      } | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    blogPosts?:  {
      __typename: "ModelBlogPostEventConnection",
      items:  Array< {
        __typename: "BlogPostEvent",
        id: string,
        eventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    variations?:  {
      __typename: "ModelTicketVariationOccurenceConnection",
      items:  Array< {
        __typename: "TicketVariationOccurence",
        id: string,
        eventId?: string | null,
        repeatEventId?: string | null,
        repeatEventOccurenceId?: string | null,
        eventType?: string | null,
        variationRuleId: string,
        isSoldOut?: boolean | null,
        amountSoldCounter?: number | null,
        owners?: Array< string | null > | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    title: string,
    entityId: string,
    logo?: string | null,
    headerVideos?: Array< string | null > | null,
    publisherId: string,
    area?: string | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    subtitle?: string | null,
    description?: string | null,
    type?: string | null,
    score?: number | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    categories?: Array< string | null > | null,
    dateTime?: number | null,
    startDate?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    endDate?: string | null,
    closingTime?: number | null,
    rules?: string | null,
    discount?: number | null,
    buyTicketUrl?: string | null,
    canBook?: boolean | null,
    isOnlineEvent?: boolean | null,
    featured?: string | null,
    video?: string | null,
    images?: Array< string | null > | null,
    eventFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    artistsList?: Array< string | null > | null,
    mapImages?: Array< string | null > | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    price?: string | null,
    city: string,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    attending?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    promoterIds?: Array< string | null > | null,
    ttl?: number | null,
    views?: number | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    status?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    pushTimestamps?: Array< number | null > | null,
    ownerType?: string | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    tablesAvailable?: number | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    influencerInfo?:  {
      __typename: "EventInfluencerInfo",
      hashtags?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      details?: string | null,
    } | null,
    username?: string | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    config?:  {
      __typename: "EventConfig",
      canBuyTickets?: boolean | null,
      ticketConfig?:  {
        __typename: "TicketConfig",
        title?: string | null,
        quantity?: number | null,
        amountSold?: number | null,
        maxPerUser?: number | null,
        images?: Array< string | null > | null,
        variationSoldCount?: number | null,
        disclaimer?: string | null,
        customEmailMessage?: string | null,
        description?: string | null,
        dateAvailableFrom?: string | null,
        dateAvailableTill?: string | null,
      } | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    blogPosts?:  {
      __typename: "ModelBlogPostEventConnection",
      items:  Array< {
        __typename: "BlogPostEvent",
        id: string,
        eventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    variations?:  {
      __typename: "ModelTicketVariationOccurenceConnection",
      items:  Array< {
        __typename: "TicketVariationOccurence",
        id: string,
        eventId?: string | null,
        repeatEventId?: string | null,
        repeatEventOccurenceId?: string | null,
        eventType?: string | null,
        variationRuleId: string,
        isSoldOut?: boolean | null,
        amountSoldCounter?: number | null,
        owners?: Array< string | null > | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateRepeatEventMutationVariables = {
  input: CreateRepeatEventInput,
  condition?: ModelRepeatEventConditionInput | null,
};

export type CreateRepeatEventMutation = {
  createRepeatEvent?:  {
    __typename: "RepeatEvent",
    id: string,
    title: string,
    entityId: string,
    area?: string | null,
    headerVideos?: Array< string | null > | null,
    publisherId: string,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    logo?: string | null,
    subtitle?: string | null,
    description?: string | null,
    type?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    discount?: number | null,
    isOnlineEvent?: boolean | null,
    score?: number | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    eventFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    closingTime?: number | null,
    dayOfWeek?: number | null,
    daysOfWeek?: Array< number | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    showWeather?: boolean | null,
    buyTicketUrl?: string | null,
    canBook?: boolean | null,
    ttl?: number | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    mapImages?: Array< string | null > | null,
    video?: string | null,
    images?: Array< string | null > | null,
    artistsList?: Array< string | null > | null,
    featured?: string | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    firstEventDate?: string | null,
    lastEventDate?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    price?: string | null,
    rules?: string | null,
    city?: string | null,
    attending?: number | null,
    status: string,
    ownerType?: string | null,
    owner?: string | null,
    pushTimestamps?: Array< number | null > | null,
    promoterIds?: Array< string | null > | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    owners?: Array< string | null > | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    occurencesRules?:  Array< {
      __typename: "OccurencesRules",
      title?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      typeOfRepeat?: string | null,
      repeatInterval?: number | null,
      daysOfWeek?: Array< number | null > | null,
      daysOfMonth?: Array< number | null > | null,
      customDates?:  Array< {
        __typename: "EventDates",
        startDate?: string | null,
        endDate?: string | null,
      } | null > | null,
      weeksOfMonth?: Array< number | null > | null,
      weeksDaysOfMonth?: Array< number | null > | null,
      occurenceTotalRepeat?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      usesEntityTables?: boolean | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      quantity?: number | null,
    } | null > | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    username?: string | null,
    influencerInfo?:  {
      __typename: "EventInfluencerInfo",
      hashtags?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      details?: string | null,
    } | null,
    config?:  {
      __typename: "EventConfig",
      canBuyTickets?: boolean | null,
      ticketConfig?:  {
        __typename: "TicketConfig",
        title?: string | null,
        quantity?: number | null,
        amountSold?: number | null,
        maxPerUser?: number | null,
        images?: Array< string | null > | null,
        variationSoldCount?: number | null,
        disclaimer?: string | null,
        customEmailMessage?: string | null,
        description?: string | null,
        dateAvailableFrom?: string | null,
        dateAvailableTill?: string | null,
      } | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    blogPosts?:  {
      __typename: "ModelBlogPostRepeatEventConnection",
      items:  Array< {
        __typename: "BlogPostRepeatEvent",
        id: string,
        repeatEventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    occurences?:  {
      __typename: "ModelRepeatEventOccurenceConnection",
      items:  Array< {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateRepeatEventMutationVariables = {
  input: UpdateRepeatEventInput,
  condition?: ModelRepeatEventConditionInput | null,
};

export type UpdateRepeatEventMutation = {
  updateRepeatEvent?:  {
    __typename: "RepeatEvent",
    id: string,
    title: string,
    entityId: string,
    area?: string | null,
    headerVideos?: Array< string | null > | null,
    publisherId: string,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    logo?: string | null,
    subtitle?: string | null,
    description?: string | null,
    type?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    discount?: number | null,
    isOnlineEvent?: boolean | null,
    score?: number | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    eventFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    closingTime?: number | null,
    dayOfWeek?: number | null,
    daysOfWeek?: Array< number | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    showWeather?: boolean | null,
    buyTicketUrl?: string | null,
    canBook?: boolean | null,
    ttl?: number | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    mapImages?: Array< string | null > | null,
    video?: string | null,
    images?: Array< string | null > | null,
    artistsList?: Array< string | null > | null,
    featured?: string | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    firstEventDate?: string | null,
    lastEventDate?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    price?: string | null,
    rules?: string | null,
    city?: string | null,
    attending?: number | null,
    status: string,
    ownerType?: string | null,
    owner?: string | null,
    pushTimestamps?: Array< number | null > | null,
    promoterIds?: Array< string | null > | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    owners?: Array< string | null > | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    occurencesRules?:  Array< {
      __typename: "OccurencesRules",
      title?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      typeOfRepeat?: string | null,
      repeatInterval?: number | null,
      daysOfWeek?: Array< number | null > | null,
      daysOfMonth?: Array< number | null > | null,
      customDates?:  Array< {
        __typename: "EventDates",
        startDate?: string | null,
        endDate?: string | null,
      } | null > | null,
      weeksOfMonth?: Array< number | null > | null,
      weeksDaysOfMonth?: Array< number | null > | null,
      occurenceTotalRepeat?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      usesEntityTables?: boolean | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      quantity?: number | null,
    } | null > | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    username?: string | null,
    influencerInfo?:  {
      __typename: "EventInfluencerInfo",
      hashtags?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      details?: string | null,
    } | null,
    config?:  {
      __typename: "EventConfig",
      canBuyTickets?: boolean | null,
      ticketConfig?:  {
        __typename: "TicketConfig",
        title?: string | null,
        quantity?: number | null,
        amountSold?: number | null,
        maxPerUser?: number | null,
        images?: Array< string | null > | null,
        variationSoldCount?: number | null,
        disclaimer?: string | null,
        customEmailMessage?: string | null,
        description?: string | null,
        dateAvailableFrom?: string | null,
        dateAvailableTill?: string | null,
      } | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    blogPosts?:  {
      __typename: "ModelBlogPostRepeatEventConnection",
      items:  Array< {
        __typename: "BlogPostRepeatEvent",
        id: string,
        repeatEventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    occurences?:  {
      __typename: "ModelRepeatEventOccurenceConnection",
      items:  Array< {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteRepeatEventMutationVariables = {
  input: DeleteRepeatEventInput,
  condition?: ModelRepeatEventConditionInput | null,
};

export type DeleteRepeatEventMutation = {
  deleteRepeatEvent?:  {
    __typename: "RepeatEvent",
    id: string,
    title: string,
    entityId: string,
    area?: string | null,
    headerVideos?: Array< string | null > | null,
    publisherId: string,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    logo?: string | null,
    subtitle?: string | null,
    description?: string | null,
    type?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    discount?: number | null,
    isOnlineEvent?: boolean | null,
    score?: number | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    eventFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    closingTime?: number | null,
    dayOfWeek?: number | null,
    daysOfWeek?: Array< number | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    showWeather?: boolean | null,
    buyTicketUrl?: string | null,
    canBook?: boolean | null,
    ttl?: number | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    mapImages?: Array< string | null > | null,
    video?: string | null,
    images?: Array< string | null > | null,
    artistsList?: Array< string | null > | null,
    featured?: string | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    firstEventDate?: string | null,
    lastEventDate?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    price?: string | null,
    rules?: string | null,
    city?: string | null,
    attending?: number | null,
    status: string,
    ownerType?: string | null,
    owner?: string | null,
    pushTimestamps?: Array< number | null > | null,
    promoterIds?: Array< string | null > | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    owners?: Array< string | null > | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    occurencesRules?:  Array< {
      __typename: "OccurencesRules",
      title?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      typeOfRepeat?: string | null,
      repeatInterval?: number | null,
      daysOfWeek?: Array< number | null > | null,
      daysOfMonth?: Array< number | null > | null,
      customDates?:  Array< {
        __typename: "EventDates",
        startDate?: string | null,
        endDate?: string | null,
      } | null > | null,
      weeksOfMonth?: Array< number | null > | null,
      weeksDaysOfMonth?: Array< number | null > | null,
      occurenceTotalRepeat?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      usesEntityTables?: boolean | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      quantity?: number | null,
    } | null > | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    username?: string | null,
    influencerInfo?:  {
      __typename: "EventInfluencerInfo",
      hashtags?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      details?: string | null,
    } | null,
    config?:  {
      __typename: "EventConfig",
      canBuyTickets?: boolean | null,
      ticketConfig?:  {
        __typename: "TicketConfig",
        title?: string | null,
        quantity?: number | null,
        amountSold?: number | null,
        maxPerUser?: number | null,
        images?: Array< string | null > | null,
        variationSoldCount?: number | null,
        disclaimer?: string | null,
        customEmailMessage?: string | null,
        description?: string | null,
        dateAvailableFrom?: string | null,
        dateAvailableTill?: string | null,
      } | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    blogPosts?:  {
      __typename: "ModelBlogPostRepeatEventConnection",
      items:  Array< {
        __typename: "BlogPostRepeatEvent",
        id: string,
        repeatEventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    occurences?:  {
      __typename: "ModelRepeatEventOccurenceConnection",
      items:  Array< {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateRepeatEventOccurenceMutationVariables = {
  input: CreateRepeatEventOccurenceInput,
  condition?: ModelRepeatEventOccurenceConditionInput | null,
};

export type CreateRepeatEventOccurenceMutation = {
  createRepeatEventOccurence?:  {
    __typename: "RepeatEventOccurence",
    id: string,
    repeatEventId: string,
    startDate?: string | null,
    endDate?: string | null,
    owners?: Array< string | null > | null,
    isSoldOut?: boolean | null,
    totalTicketsSold?: number | null,
    canBuyTickets?: boolean | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    variations?:  {
      __typename: "ModelTicketVariationOccurenceConnection",
      items:  Array< {
        __typename: "TicketVariationOccurence",
        id: string,
        eventId?: string | null,
        repeatEventId?: string | null,
        repeatEventOccurenceId?: string | null,
        eventType?: string | null,
        variationRuleId: string,
        isSoldOut?: boolean | null,
        amountSoldCounter?: number | null,
        owners?: Array< string | null > | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateRepeatEventOccurenceMutationVariables = {
  input: UpdateRepeatEventOccurenceInput,
  condition?: ModelRepeatEventOccurenceConditionInput | null,
};

export type UpdateRepeatEventOccurenceMutation = {
  updateRepeatEventOccurence?:  {
    __typename: "RepeatEventOccurence",
    id: string,
    repeatEventId: string,
    startDate?: string | null,
    endDate?: string | null,
    owners?: Array< string | null > | null,
    isSoldOut?: boolean | null,
    totalTicketsSold?: number | null,
    canBuyTickets?: boolean | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    variations?:  {
      __typename: "ModelTicketVariationOccurenceConnection",
      items:  Array< {
        __typename: "TicketVariationOccurence",
        id: string,
        eventId?: string | null,
        repeatEventId?: string | null,
        repeatEventOccurenceId?: string | null,
        eventType?: string | null,
        variationRuleId: string,
        isSoldOut?: boolean | null,
        amountSoldCounter?: number | null,
        owners?: Array< string | null > | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteRepeatEventOccurenceMutationVariables = {
  input: DeleteRepeatEventOccurenceInput,
  condition?: ModelRepeatEventOccurenceConditionInput | null,
};

export type DeleteRepeatEventOccurenceMutation = {
  deleteRepeatEventOccurence?:  {
    __typename: "RepeatEventOccurence",
    id: string,
    repeatEventId: string,
    startDate?: string | null,
    endDate?: string | null,
    owners?: Array< string | null > | null,
    isSoldOut?: boolean | null,
    totalTicketsSold?: number | null,
    canBuyTickets?: boolean | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    variations?:  {
      __typename: "ModelTicketVariationOccurenceConnection",
      items:  Array< {
        __typename: "TicketVariationOccurence",
        id: string,
        eventId?: string | null,
        repeatEventId?: string | null,
        repeatEventOccurenceId?: string | null,
        eventType?: string | null,
        variationRuleId: string,
        isSoldOut?: boolean | null,
        amountSoldCounter?: number | null,
        owners?: Array< string | null > | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    id: string,
    review?: string | null,
    score?: number | null,
    feedback?: string | null,
    scores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    dateOfVisit?: number | null,
    userId: string,
    entityId: string,
    publisherId: string,
    status?: string | null,
    verified?: boolean | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    id: string,
    review?: string | null,
    score?: number | null,
    feedback?: string | null,
    scores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    dateOfVisit?: number | null,
    userId: string,
    entityId: string,
    publisherId: string,
    status?: string | null,
    verified?: boolean | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    id: string,
    review?: string | null,
    score?: number | null,
    feedback?: string | null,
    scores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    dateOfVisit?: number | null,
    userId: string,
    entityId: string,
    publisherId: string,
    status?: string | null,
    verified?: boolean | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateUserEventMutationVariables = {
  input: CreateUserEventInput,
  condition?: ModelUserEventConditionInput | null,
};

export type CreateUserEventMutation = {
  createUserEvent?:  {
    __typename: "UserEvent",
    id: string,
    eventDate: number,
    eventId: string,
    userName?: string | null,
    repeatEventId: string,
    repeatEventOccurenceId: string,
    type?: string | null,
    userId: string,
    owners?: Array< string | null > | null,
    status?: string | null,
    checkInDate?: string | null,
    documents?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    tablesBooked?: Array< string | null > | null,
    subscriptionArn?: string | null,
    plusOnesRemaining?: number | null,
    authCode?: string | null,
    subscriptionArns?: Array< string | null > | null,
    pushSubscriptions?:  Array< {
      __typename: "PushSubscription",
      endpointArn?: string | null,
      deviceEndpointArn?: string | null,
    } | null > | null,
    subscriptionActive?: boolean | null,
    title: string,
    createdAt?: number | null,
    updatedAt?: number | null,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateUserEventMutationVariables = {
  input: UpdateUserEventInput,
  condition?: ModelUserEventConditionInput | null,
};

export type UpdateUserEventMutation = {
  updateUserEvent?:  {
    __typename: "UserEvent",
    id: string,
    eventDate: number,
    eventId: string,
    userName?: string | null,
    repeatEventId: string,
    repeatEventOccurenceId: string,
    type?: string | null,
    userId: string,
    owners?: Array< string | null > | null,
    status?: string | null,
    checkInDate?: string | null,
    documents?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    tablesBooked?: Array< string | null > | null,
    subscriptionArn?: string | null,
    plusOnesRemaining?: number | null,
    authCode?: string | null,
    subscriptionArns?: Array< string | null > | null,
    pushSubscriptions?:  Array< {
      __typename: "PushSubscription",
      endpointArn?: string | null,
      deviceEndpointArn?: string | null,
    } | null > | null,
    subscriptionActive?: boolean | null,
    title: string,
    createdAt?: number | null,
    updatedAt?: number | null,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteUserEventMutationVariables = {
  input: DeleteUserEventInput,
  condition?: ModelUserEventConditionInput | null,
};

export type DeleteUserEventMutation = {
  deleteUserEvent?:  {
    __typename: "UserEvent",
    id: string,
    eventDate: number,
    eventId: string,
    userName?: string | null,
    repeatEventId: string,
    repeatEventOccurenceId: string,
    type?: string | null,
    userId: string,
    owners?: Array< string | null > | null,
    status?: string | null,
    checkInDate?: string | null,
    documents?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    tablesBooked?: Array< string | null > | null,
    subscriptionArn?: string | null,
    plusOnesRemaining?: number | null,
    authCode?: string | null,
    subscriptionArns?: Array< string | null > | null,
    pushSubscriptions?:  Array< {
      __typename: "PushSubscription",
      endpointArn?: string | null,
      deviceEndpointArn?: string | null,
    } | null > | null,
    subscriptionActive?: boolean | null,
    title: string,
    createdAt?: number | null,
    updatedAt?: number | null,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCardTransactionsMutationVariables = {
  input: CreateCardTransactionsInput,
  condition?: ModelCardTransactionsConditionInput | null,
};

export type CreateCardTransactionsMutation = {
  createCardTransactions?:  {
    __typename: "CardTransactions",
    currency?: string | null,
    auth?: boolean | null,
    type?: string | null,
    programId?: string | null,
    id?: string | null,
    accountId?: string | null,
    userEntityId?: string | null,
    userPublisherId?: string | null,
    userId?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    entityId?: string | null,
    publisherId?: string | null,
    created?: string | null,
    authCode?: string | null,
    updated?: string | null,
    amount?: number | null,
    cleared?: boolean | null,
    visits?: number | null,
    wallet?: string | null,
    offer?: string | null,
    ttl?: number | null,
    datetime?: string | null,
    card?:  {
      __typename: "TransactionCard",
      id?: string | null,
      firstNumbers?: string | null,
      lastNumbers?: string | null,
      scheme?: string | null,
    } | null,
    location?:  {
      __typename: "FidelLocation",
      address?: string | null,
      city?: string | null,
      countryCode?: string | null,
      id?: string | null,
      geolocation?: string | null,
      postcode?: string | null,
      timezone?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "CardBrandData",
      id?: string | null,
      name?: string | null,
      logoURL?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
    } | null,
    identifiers?:  {
      __typename: "FidelTransactionIdentifiers",
      amexApprovalCode?: string | null,
      mastercardAuthCode?: string | null,
      mastercardRefNumber?: string | null,
      mastercardTransactionSequenceNumber?: string | null,
      MID?: string | null,
      visaAuthCode?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateCardTransactionsMutationVariables = {
  input: UpdateCardTransactionsInput,
  condition?: ModelCardTransactionsConditionInput | null,
};

export type UpdateCardTransactionsMutation = {
  updateCardTransactions?:  {
    __typename: "CardTransactions",
    currency?: string | null,
    auth?: boolean | null,
    type?: string | null,
    programId?: string | null,
    id?: string | null,
    accountId?: string | null,
    userEntityId?: string | null,
    userPublisherId?: string | null,
    userId?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    entityId?: string | null,
    publisherId?: string | null,
    created?: string | null,
    authCode?: string | null,
    updated?: string | null,
    amount?: number | null,
    cleared?: boolean | null,
    visits?: number | null,
    wallet?: string | null,
    offer?: string | null,
    ttl?: number | null,
    datetime?: string | null,
    card?:  {
      __typename: "TransactionCard",
      id?: string | null,
      firstNumbers?: string | null,
      lastNumbers?: string | null,
      scheme?: string | null,
    } | null,
    location?:  {
      __typename: "FidelLocation",
      address?: string | null,
      city?: string | null,
      countryCode?: string | null,
      id?: string | null,
      geolocation?: string | null,
      postcode?: string | null,
      timezone?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "CardBrandData",
      id?: string | null,
      name?: string | null,
      logoURL?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
    } | null,
    identifiers?:  {
      __typename: "FidelTransactionIdentifiers",
      amexApprovalCode?: string | null,
      mastercardAuthCode?: string | null,
      mastercardRefNumber?: string | null,
      mastercardTransactionSequenceNumber?: string | null,
      MID?: string | null,
      visaAuthCode?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteCardTransactionsMutationVariables = {
  input: DeleteCardTransactionsInput,
  condition?: ModelCardTransactionsConditionInput | null,
};

export type DeleteCardTransactionsMutation = {
  deleteCardTransactions?:  {
    __typename: "CardTransactions",
    currency?: string | null,
    auth?: boolean | null,
    type?: string | null,
    programId?: string | null,
    id?: string | null,
    accountId?: string | null,
    userEntityId?: string | null,
    userPublisherId?: string | null,
    userId?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    entityId?: string | null,
    publisherId?: string | null,
    created?: string | null,
    authCode?: string | null,
    updated?: string | null,
    amount?: number | null,
    cleared?: boolean | null,
    visits?: number | null,
    wallet?: string | null,
    offer?: string | null,
    ttl?: number | null,
    datetime?: string | null,
    card?:  {
      __typename: "TransactionCard",
      id?: string | null,
      firstNumbers?: string | null,
      lastNumbers?: string | null,
      scheme?: string | null,
    } | null,
    location?:  {
      __typename: "FidelLocation",
      address?: string | null,
      city?: string | null,
      countryCode?: string | null,
      id?: string | null,
      geolocation?: string | null,
      postcode?: string | null,
      timezone?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "CardBrandData",
      id?: string | null,
      name?: string | null,
      logoURL?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
    } | null,
    identifiers?:  {
      __typename: "FidelTransactionIdentifiers",
      amexApprovalCode?: string | null,
      mastercardAuthCode?: string | null,
      mastercardRefNumber?: string | null,
      mastercardTransactionSequenceNumber?: string | null,
      MID?: string | null,
      visaAuthCode?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateLoyaltyUserCountersMutationVariables = {
  input: CreateLoyaltyUserCountersInput,
  condition?: ModelLoyaltyUserCountersConditionInput | null,
};

export type CreateLoyaltyUserCountersMutation = {
  createLoyaltyUserCounters?:  {
    __typename: "LoyaltyUserCounters",
    id: string,
    date?: string | null,
    publisherId: string,
    entityId: string,
    userId: string,
    userDob?: string | null,
    userAge?: number | null,
    visits?: number | null,
    idTypeKey?: string | null,
    userGender?: string | null,
    spend?: number | null,
    ttl?: number | null,
    period?: string | null,
    owners?: Array< string | null > | null,
    owner?: string | null,
    currency?: string | null,
    transactions?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateLoyaltyUserCountersMutationVariables = {
  input: UpdateLoyaltyUserCountersInput,
  condition?: ModelLoyaltyUserCountersConditionInput | null,
};

export type UpdateLoyaltyUserCountersMutation = {
  updateLoyaltyUserCounters?:  {
    __typename: "LoyaltyUserCounters",
    id: string,
    date?: string | null,
    publisherId: string,
    entityId: string,
    userId: string,
    userDob?: string | null,
    userAge?: number | null,
    visits?: number | null,
    idTypeKey?: string | null,
    userGender?: string | null,
    spend?: number | null,
    ttl?: number | null,
    period?: string | null,
    owners?: Array< string | null > | null,
    owner?: string | null,
    currency?: string | null,
    transactions?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteLoyaltyUserCountersMutationVariables = {
  input: DeleteLoyaltyUserCountersInput,
  condition?: ModelLoyaltyUserCountersConditionInput | null,
};

export type DeleteLoyaltyUserCountersMutation = {
  deleteLoyaltyUserCounters?:  {
    __typename: "LoyaltyUserCounters",
    id: string,
    date?: string | null,
    publisherId: string,
    entityId: string,
    userId: string,
    userDob?: string | null,
    userAge?: number | null,
    visits?: number | null,
    idTypeKey?: string | null,
    userGender?: string | null,
    spend?: number | null,
    ttl?: number | null,
    period?: string | null,
    owners?: Array< string | null > | null,
    owner?: string | null,
    currency?: string | null,
    transactions?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateLoyaltyEntityCountersMutationVariables = {
  input: CreateLoyaltyEntityCountersInput,
  condition?: ModelLoyaltyEntityCountersConditionInput | null,
};

export type CreateLoyaltyEntityCountersMutation = {
  createLoyaltyEntityCounters?:  {
    __typename: "LoyaltyEntityCounters",
    id: string,
    date?: string | null,
    publisherId: string,
    entityId: string,
    spend?: number | null,
    ttl?: number | null,
    idTypeKey?: string | null,
    genderCount?:  {
      __typename: "GenderCount",
      m?: number | null,
      f?: number | null,
      nb?: number | null,
      t?: number | null,
      o?: number | null,
    } | null,
    ageCount?:  {
      __typename: "AgeCount",
      below18?: number | null,
      age18To25?: number | null,
      age26To30?: number | null,
      age31To35?: number | null,
      age36To40?: number | null,
      age41To45?: number | null,
      Over45?: number | null,
    } | null,
    visits?: number | null,
    currency?: string | null,
    transactions?: number | null,
    paid?: string | null,
    invoice?: string | null,
    owners?: Array< string | null > | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateLoyaltyEntityCountersMutationVariables = {
  input: UpdateLoyaltyEntityCountersInput,
  condition?: ModelLoyaltyEntityCountersConditionInput | null,
};

export type UpdateLoyaltyEntityCountersMutation = {
  updateLoyaltyEntityCounters?:  {
    __typename: "LoyaltyEntityCounters",
    id: string,
    date?: string | null,
    publisherId: string,
    entityId: string,
    spend?: number | null,
    ttl?: number | null,
    idTypeKey?: string | null,
    genderCount?:  {
      __typename: "GenderCount",
      m?: number | null,
      f?: number | null,
      nb?: number | null,
      t?: number | null,
      o?: number | null,
    } | null,
    ageCount?:  {
      __typename: "AgeCount",
      below18?: number | null,
      age18To25?: number | null,
      age26To30?: number | null,
      age31To35?: number | null,
      age36To40?: number | null,
      age41To45?: number | null,
      Over45?: number | null,
    } | null,
    visits?: number | null,
    currency?: string | null,
    transactions?: number | null,
    paid?: string | null,
    invoice?: string | null,
    owners?: Array< string | null > | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteLoyaltyEntityCountersMutationVariables = {
  input: DeleteLoyaltyEntityCountersInput,
  condition?: ModelLoyaltyEntityCountersConditionInput | null,
};

export type DeleteLoyaltyEntityCountersMutation = {
  deleteLoyaltyEntityCounters?:  {
    __typename: "LoyaltyEntityCounters",
    id: string,
    date?: string | null,
    publisherId: string,
    entityId: string,
    spend?: number | null,
    ttl?: number | null,
    idTypeKey?: string | null,
    genderCount?:  {
      __typename: "GenderCount",
      m?: number | null,
      f?: number | null,
      nb?: number | null,
      t?: number | null,
      o?: number | null,
    } | null,
    ageCount?:  {
      __typename: "AgeCount",
      below18?: number | null,
      age18To25?: number | null,
      age26To30?: number | null,
      age31To35?: number | null,
      age36To40?: number | null,
      age41To45?: number | null,
      Over45?: number | null,
    } | null,
    visits?: number | null,
    currency?: string | null,
    transactions?: number | null,
    paid?: string | null,
    invoice?: string | null,
    owners?: Array< string | null > | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateHelpTopicMutationVariables = {
  input: CreateHelpTopicInput,
  condition?: ModelHelpTopicConditionInput | null,
};

export type CreateHelpTopicMutation = {
  createHelpTopic?:  {
    __typename: "HelpTopic",
    id: string,
    title: string,
    type?: string | null,
    category?: string | null,
    icon?: string | null,
    color?: string | null,
    notes?: string | null,
    subtitle?: string | null,
    authorId?: string | null,
    image?: string | null,
    index?: number | null,
    articles?:  Array< {
      __typename: "HelpArticle",
      index?: number | null,
      title?: string | null,
      subtitle?: string | null,
      image?: string | null,
      content?: string | null,
      articles?:  Array< {
        __typename: "HelpArticle",
        index?: number | null,
        title?: string | null,
        subtitle?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
    } | null > | null,
    sections?:  Array< {
      __typename: "HelpSection",
      index?: number | null,
      title?: string | null,
      content?: string | null,
      type?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateHelpTopicMutationVariables = {
  input: UpdateHelpTopicInput,
  condition?: ModelHelpTopicConditionInput | null,
};

export type UpdateHelpTopicMutation = {
  updateHelpTopic?:  {
    __typename: "HelpTopic",
    id: string,
    title: string,
    type?: string | null,
    category?: string | null,
    icon?: string | null,
    color?: string | null,
    notes?: string | null,
    subtitle?: string | null,
    authorId?: string | null,
    image?: string | null,
    index?: number | null,
    articles?:  Array< {
      __typename: "HelpArticle",
      index?: number | null,
      title?: string | null,
      subtitle?: string | null,
      image?: string | null,
      content?: string | null,
      articles?:  Array< {
        __typename: "HelpArticle",
        index?: number | null,
        title?: string | null,
        subtitle?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
    } | null > | null,
    sections?:  Array< {
      __typename: "HelpSection",
      index?: number | null,
      title?: string | null,
      content?: string | null,
      type?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteHelpTopicMutationVariables = {
  input: DeleteHelpTopicInput,
  condition?: ModelHelpTopicConditionInput | null,
};

export type DeleteHelpTopicMutation = {
  deleteHelpTopic?:  {
    __typename: "HelpTopic",
    id: string,
    title: string,
    type?: string | null,
    category?: string | null,
    icon?: string | null,
    color?: string | null,
    notes?: string | null,
    subtitle?: string | null,
    authorId?: string | null,
    image?: string | null,
    index?: number | null,
    articles?:  Array< {
      __typename: "HelpArticle",
      index?: number | null,
      title?: string | null,
      subtitle?: string | null,
      image?: string | null,
      content?: string | null,
      articles?:  Array< {
        __typename: "HelpArticle",
        index?: number | null,
        title?: string | null,
        subtitle?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
    } | null > | null,
    sections?:  Array< {
      __typename: "HelpSection",
      index?: number | null,
      title?: string | null,
      content?: string | null,
      type?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateSpecialOfferMutationVariables = {
  input: CreateSpecialOfferInput,
  condition?: ModelSpecialOfferConditionInput | null,
};

export type CreateSpecialOfferMutation = {
  createSpecialOffer?:  {
    __typename: "SpecialOffer",
    id: string,
    daysAvailable?: Array< number | null > | null,
    startTime?: string | null,
    price?: number | null,
    discount?: number | null,
    endTime?: string | null,
    endDate?: string | null,
    countryCode?: string | null,
    declineReason?: string | null,
    requiresBooking?: string | null,
    startDate?: string | null,
    maxTransactionAmount?: number | null,
    minTransactionAmount?: number | null,
    returnPeriod?: number | null,
    metaData?:  {
      __typename: "IFidelMetaData",
      userId?: string | null,
      publisherId?: string | null,
      brandId?: string | null,
      entityId?: string | null,
      title?: string | null,
      gender?: string | null,
      dob?: string | null,
      cognitoUsername?: string | null,
    } | null,
    description?: string | null,
    totalLoyaltyPointsRequiredForAccess?: number | null,
    title: string,
    images?: Array< string | null > | null,
    weeks?: number | null,
    entityId: string,
    publisherId: string,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    fidelId?: string | null,
    spendRequired?: number | null,
    delayMinutes?: number | null,
    delayHours?: number | null,
    currency?: string | null,
    isStudentDiscount?: boolean | null,
    city?: string | null,
    quantity?: number | null,
    status: string,
    loyaltyPointsRequired?: number | null,
    loyaltyPointsValue?: number | null,
    visitsRequired?: number | null,
    redeemCount?: number | null,
    claimCount?: number | null,
    dateAvailable?: string | null,
    allowanceLimit?: number | null,
    refreshDaysPeriod?: string | null,
    type?: string | null,
    rewardType?: string | null,
    subscribersOnly?: boolean | null,
    owner?: string | null,
    disclaimer?: string | null,
    metadata?:  {
      __typename: "IFidelMetaData",
      userId?: string | null,
      publisherId?: string | null,
      brandId?: string | null,
      entityId?: string | null,
      title?: string | null,
      gender?: string | null,
      dob?: string | null,
      cognitoUsername?: string | null,
    } | null,
    fidelOfferId?: string | null,
    fidelStartDate?: string | null,
    fidelEndDate?: string | null,
    fidelMinTransactionAmount?: number | null,
    fidelMaxTransactionAmount?: number | null,
    fidelReturnPeriod?: number | null,
    fidelDaysOfWeek?: Array< number | null > | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSpecialOfferMutationVariables = {
  input: UpdateSpecialOfferInput,
  condition?: ModelSpecialOfferConditionInput | null,
};

export type UpdateSpecialOfferMutation = {
  updateSpecialOffer?:  {
    __typename: "SpecialOffer",
    id: string,
    daysAvailable?: Array< number | null > | null,
    startTime?: string | null,
    price?: number | null,
    discount?: number | null,
    endTime?: string | null,
    endDate?: string | null,
    countryCode?: string | null,
    declineReason?: string | null,
    requiresBooking?: string | null,
    startDate?: string | null,
    maxTransactionAmount?: number | null,
    minTransactionAmount?: number | null,
    returnPeriod?: number | null,
    metaData?:  {
      __typename: "IFidelMetaData",
      userId?: string | null,
      publisherId?: string | null,
      brandId?: string | null,
      entityId?: string | null,
      title?: string | null,
      gender?: string | null,
      dob?: string | null,
      cognitoUsername?: string | null,
    } | null,
    description?: string | null,
    totalLoyaltyPointsRequiredForAccess?: number | null,
    title: string,
    images?: Array< string | null > | null,
    weeks?: number | null,
    entityId: string,
    publisherId: string,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    fidelId?: string | null,
    spendRequired?: number | null,
    delayMinutes?: number | null,
    delayHours?: number | null,
    currency?: string | null,
    isStudentDiscount?: boolean | null,
    city?: string | null,
    quantity?: number | null,
    status: string,
    loyaltyPointsRequired?: number | null,
    loyaltyPointsValue?: number | null,
    visitsRequired?: number | null,
    redeemCount?: number | null,
    claimCount?: number | null,
    dateAvailable?: string | null,
    allowanceLimit?: number | null,
    refreshDaysPeriod?: string | null,
    type?: string | null,
    rewardType?: string | null,
    subscribersOnly?: boolean | null,
    owner?: string | null,
    disclaimer?: string | null,
    metadata?:  {
      __typename: "IFidelMetaData",
      userId?: string | null,
      publisherId?: string | null,
      brandId?: string | null,
      entityId?: string | null,
      title?: string | null,
      gender?: string | null,
      dob?: string | null,
      cognitoUsername?: string | null,
    } | null,
    fidelOfferId?: string | null,
    fidelStartDate?: string | null,
    fidelEndDate?: string | null,
    fidelMinTransactionAmount?: number | null,
    fidelMaxTransactionAmount?: number | null,
    fidelReturnPeriod?: number | null,
    fidelDaysOfWeek?: Array< number | null > | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSpecialOfferMutationVariables = {
  input: DeleteSpecialOfferInput,
  condition?: ModelSpecialOfferConditionInput | null,
};

export type DeleteSpecialOfferMutation = {
  deleteSpecialOffer?:  {
    __typename: "SpecialOffer",
    id: string,
    daysAvailable?: Array< number | null > | null,
    startTime?: string | null,
    price?: number | null,
    discount?: number | null,
    endTime?: string | null,
    endDate?: string | null,
    countryCode?: string | null,
    declineReason?: string | null,
    requiresBooking?: string | null,
    startDate?: string | null,
    maxTransactionAmount?: number | null,
    minTransactionAmount?: number | null,
    returnPeriod?: number | null,
    metaData?:  {
      __typename: "IFidelMetaData",
      userId?: string | null,
      publisherId?: string | null,
      brandId?: string | null,
      entityId?: string | null,
      title?: string | null,
      gender?: string | null,
      dob?: string | null,
      cognitoUsername?: string | null,
    } | null,
    description?: string | null,
    totalLoyaltyPointsRequiredForAccess?: number | null,
    title: string,
    images?: Array< string | null > | null,
    weeks?: number | null,
    entityId: string,
    publisherId: string,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    fidelId?: string | null,
    spendRequired?: number | null,
    delayMinutes?: number | null,
    delayHours?: number | null,
    currency?: string | null,
    isStudentDiscount?: boolean | null,
    city?: string | null,
    quantity?: number | null,
    status: string,
    loyaltyPointsRequired?: number | null,
    loyaltyPointsValue?: number | null,
    visitsRequired?: number | null,
    redeemCount?: number | null,
    claimCount?: number | null,
    dateAvailable?: string | null,
    allowanceLimit?: number | null,
    refreshDaysPeriod?: string | null,
    type?: string | null,
    rewardType?: string | null,
    subscribersOnly?: boolean | null,
    owner?: string | null,
    disclaimer?: string | null,
    metadata?:  {
      __typename: "IFidelMetaData",
      userId?: string | null,
      publisherId?: string | null,
      brandId?: string | null,
      entityId?: string | null,
      title?: string | null,
      gender?: string | null,
      dob?: string | null,
      cognitoUsername?: string | null,
    } | null,
    fidelOfferId?: string | null,
    fidelStartDate?: string | null,
    fidelEndDate?: string | null,
    fidelMinTransactionAmount?: number | null,
    fidelMaxTransactionAmount?: number | null,
    fidelReturnPeriod?: number | null,
    fidelDaysOfWeek?: Array< number | null > | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateLoyaltyCardMutationVariables = {
  input: CreateLoyaltyCardInput,
  condition?: ModelLoyaltyCardConditionInput | null,
};

export type CreateLoyaltyCardMutation = {
  createLoyaltyCard?:  {
    __typename: "LoyaltyCard",
    id: string,
    userId: string,
    publisherId: string,
    businessTitle?: string | null,
    currentTier?: string | null,
    entityId?: string | null,
    visits?: number | null,
    businessAssignedTier?: string | null,
    transactions?: number | null,
    totalLoyaltyPointsRequiredForAccess?: number | null,
    subscriptionArns?: Array< string | null > | null,
    pushSubscriptions?:  Array< {
      __typename: "PushSubscription",
      endpointArn?: string | null,
      deviceEndpointArn?: string | null,
    } | null > | null,
    notificationsEnabled?: boolean | null,
    totalSpend?: number | null,
    pendingPoints?: number | null,
    totalPoints?: number | null,
    ownerType?: string | null,
    status?: string | null,
    userFullName?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    totalSpendablePoints?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateLoyaltyCardMutationVariables = {
  input: UpdateLoyaltyCardInput,
  condition?: ModelLoyaltyCardConditionInput | null,
};

export type UpdateLoyaltyCardMutation = {
  updateLoyaltyCard?:  {
    __typename: "LoyaltyCard",
    id: string,
    userId: string,
    publisherId: string,
    businessTitle?: string | null,
    currentTier?: string | null,
    entityId?: string | null,
    visits?: number | null,
    businessAssignedTier?: string | null,
    transactions?: number | null,
    totalLoyaltyPointsRequiredForAccess?: number | null,
    subscriptionArns?: Array< string | null > | null,
    pushSubscriptions?:  Array< {
      __typename: "PushSubscription",
      endpointArn?: string | null,
      deviceEndpointArn?: string | null,
    } | null > | null,
    notificationsEnabled?: boolean | null,
    totalSpend?: number | null,
    pendingPoints?: number | null,
    totalPoints?: number | null,
    ownerType?: string | null,
    status?: string | null,
    userFullName?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    totalSpendablePoints?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteLoyaltyCardMutationVariables = {
  input: DeleteLoyaltyCardInput,
  condition?: ModelLoyaltyCardConditionInput | null,
};

export type DeleteLoyaltyCardMutation = {
  deleteLoyaltyCard?:  {
    __typename: "LoyaltyCard",
    id: string,
    userId: string,
    publisherId: string,
    businessTitle?: string | null,
    currentTier?: string | null,
    entityId?: string | null,
    visits?: number | null,
    businessAssignedTier?: string | null,
    transactions?: number | null,
    totalLoyaltyPointsRequiredForAccess?: number | null,
    subscriptionArns?: Array< string | null > | null,
    pushSubscriptions?:  Array< {
      __typename: "PushSubscription",
      endpointArn?: string | null,
      deviceEndpointArn?: string | null,
    } | null > | null,
    notificationsEnabled?: boolean | null,
    totalSpend?: number | null,
    pendingPoints?: number | null,
    totalPoints?: number | null,
    ownerType?: string | null,
    status?: string | null,
    userFullName?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    totalSpendablePoints?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateDailyPromotionsMutationVariables = {
  input: CreateDailyPromotionsInput,
  condition?: ModelDailyPromotionsConditionInput | null,
};

export type CreateDailyPromotionsMutation = {
  createDailyPromotions?:  {
    __typename: "DailyPromotions",
    id: string,
    entityId: string,
    daysAvailable?: Array< number | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    description?: string | null,
    price?: number | null,
    images?: Array< string | null > | null,
    title?: string | null,
    status?: string | null,
    disclaimer?: string | null,
    owner?: string | null,
    subscribersOnly?: boolean | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type UpdateDailyPromotionsMutationVariables = {
  input: UpdateDailyPromotionsInput,
  condition?: ModelDailyPromotionsConditionInput | null,
};

export type UpdateDailyPromotionsMutation = {
  updateDailyPromotions?:  {
    __typename: "DailyPromotions",
    id: string,
    entityId: string,
    daysAvailable?: Array< number | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    description?: string | null,
    price?: number | null,
    images?: Array< string | null > | null,
    title?: string | null,
    status?: string | null,
    disclaimer?: string | null,
    owner?: string | null,
    subscribersOnly?: boolean | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type DeleteDailyPromotionsMutationVariables = {
  input: DeleteDailyPromotionsInput,
  condition?: ModelDailyPromotionsConditionInput | null,
};

export type DeleteDailyPromotionsMutation = {
  deleteDailyPromotions?:  {
    __typename: "DailyPromotions",
    id: string,
    entityId: string,
    daysAvailable?: Array< number | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    description?: string | null,
    price?: number | null,
    images?: Array< string | null > | null,
    title?: string | null,
    status?: string | null,
    disclaimer?: string | null,
    owner?: string | null,
    subscribersOnly?: boolean | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type CreateLoyaltyActivityMutationVariables = {
  input: CreateLoyaltyActivityInput,
  condition?: ModelLoyaltyActivityConditionInput | null,
};

export type CreateLoyaltyActivityMutation = {
  createLoyaltyActivity?:  {
    __typename: "LoyaltyActivity",
    id: string,
    userPublisherKey?: string | null,
    points?: number | null,
    eventId: string,
    entityId: string,
    publisherId: string,
    repeatEventId: string,
    userId: string,
    adminAllocated?: boolean | null,
    message?: string | null,
    createdAt: number,
    updatedAt: number,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateLoyaltyActivityMutationVariables = {
  input: UpdateLoyaltyActivityInput,
  condition?: ModelLoyaltyActivityConditionInput | null,
};

export type UpdateLoyaltyActivityMutation = {
  updateLoyaltyActivity?:  {
    __typename: "LoyaltyActivity",
    id: string,
    userPublisherKey?: string | null,
    points?: number | null,
    eventId: string,
    entityId: string,
    publisherId: string,
    repeatEventId: string,
    userId: string,
    adminAllocated?: boolean | null,
    message?: string | null,
    createdAt: number,
    updatedAt: number,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteLoyaltyActivityMutationVariables = {
  input: DeleteLoyaltyActivityInput,
  condition?: ModelLoyaltyActivityConditionInput | null,
};

export type DeleteLoyaltyActivityMutation = {
  deleteLoyaltyActivity?:  {
    __typename: "LoyaltyActivity",
    id: string,
    userPublisherKey?: string | null,
    points?: number | null,
    eventId: string,
    entityId: string,
    publisherId: string,
    repeatEventId: string,
    userId: string,
    adminAllocated?: boolean | null,
    message?: string | null,
    createdAt: number,
    updatedAt: number,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateEntityMutationVariables = {
  input: CreateEntityInput,
  condition?: ModelEntityConditionInput | null,
};

export type CreateEntityMutation = {
  createEntity?:  {
    __typename: "Entity",
    id: string,
    title: string,
    shortUrl?: string | null,
    subtitle?: string | null,
    lastAdminCheck?: string | null,
    area?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    notes?: string | null,
    summary?: string | null,
    uniqueAspect?: string | null,
    tags?: Array< string | null > | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    nickname?: string | null,
    hasEmailed?: boolean | null,
    firstName?: string | null,
    hasStudentDiscount?: boolean | null,
    LastName?: string | null,
    config?:  {
      __typename: "EntityConfig",
      loyaltyEnabled?: boolean | null,
      canMessage?: boolean | null,
      canBook?: boolean | null,
      canCheckin?: boolean | null,
      canCall?: boolean | null,
      canEmail?: boolean | null,
      autoResponseMessage?: string | null,
      enterVenueCheckInEnabled?: boolean | null,
      checkInMessage?: string | null,
      colorTheme?: string | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
      bookingConfig?:  {
        __typename: "EntityBookingConfig",
        type?: string | null,
        bookingProvider?: EntityBookingProvider | null,
        bookingProviderBackup?: string | null,
        bookingProviderUsername?: string | null,
        bookingProviderUsernameBackup?: string | null,
        canAutoBook?: boolean | null,
        notes?: string | null,
        bookingDurationMins?: number | null,
        bookingEmails?: Array< string | null > | null,
        bookingPhoneNumber?: string | null,
        areas?: Array< string | null > | null,
      } | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      enableTrackTrace?: boolean | null,
    } | null,
    nearbyStations?:  Array< {
      __typename: "EntityClosestStation",
      title?: string | null,
      distance?: number | null,
      line?: string | null,
      zone?: number | null,
      type?: string | null,
    } | null > | null,
    outdoorArea?: boolean | null,
    outdoorHeating?: boolean | null,
    description?: string | null,
    stripeSubscriptionItemId?: string | null,
    emojiSummary?: Array< string | null > | null,
    categories?: Array< string | null > | null,
    phone?: string | null,
    internationalPhoneNumber?: string | null,
    logo?: string | null,
    pushTimestamps?: Array< number | null > | null,
    websiteUrl?: string | null,
    orderfromTableUrl?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    entityTitle?: string | null,
    opens?: number | null,
    closes?: number | null,
    asFeaturedOn?: Array< string | null > | null,
    username?: string | null,
    trendingScore?: number | null,
    openHours?:  {
      __typename: "DailyOpeningTimes",
      monday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      tuesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      wednesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      thursday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      friday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      saturday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      sunday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
    } | null,
    status: string,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    virtualTourUrl?: string | null,
    emailsDisabled?: boolean | null,
    reviewsDisabled?: boolean | null,
    video?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    headerVideos?: Array< string | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    city: string,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    entityFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    rules?: string | null,
    position?: number | null,
    score?: number | null,
    score_before_boost?: number | null,
    boost_multiplier?: number | null,
    boost_until?: string | null,
    boostPurchaseId?: string | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    canMessage?: boolean | null,
    forceupdate?: boolean | null,
    email?: string | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    price?: string | null,
    featured?: string | null,
    cuisines?: Array< string | null > | null,
    dietryOptions?: Array< string | null > | null,
    foodDeliveryAppLinks?:  Array< {
      __typename: "FoodDeliveryAppLink",
      title?: string | null,
      icon?: string | null,
      url?: string | null,
    } | null > | null,
    verified?: boolean | null,
    qrIds?: Array< string | null > | null,
    publisherId: string,
    subscribeOfferId?: string | null,
    subscriberCount?: number | null,
    viewCount?: number | null,
    bookCount?: number | null,
    shareCount?: number | null,
    checkInCount?: number | null,
    addToListCount?: number | null,
    pushTimestamp?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    images?: Array< string | null > | null,
    googleImages?:  Array< {
      __typename: "GoogleImage",
      html_attributions?: Array< string | null > | null,
      image?: string | null,
    } | null > | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    minBookingLimit?: number | null,
    maxBookingLimit?: number | null,
    bookingUrl?: string | null,
    createdAt: number,
    offersArray?: Array< string | null > | null,
    whatsappNumber?: string | null,
    updatedAt: number,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    owner?: string | null,
    reviewScores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    safetyChecklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    venueSafetyData?:  {
      __typename: "VenueSafetyData",
      list?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      links?: Array< string | null > | null,
      updated?: string | null,
    } | null,
    capacity?: number | null,
    acceptWalkIns?: boolean | null,
    covidMeasures?:  {
      __typename: "CovidMeasures",
      capacityPercentage?: number | null,
      tableLimit?: number | null,
      masksRequired?: boolean | null,
      bookingsRequired?: boolean | null,
      notes?: string | null,
    } | null,
    mediaChannels?:  {
      __typename: "MediaChannels",
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      amazonMusicUrl?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    pingReview?:  {
      __typename: "PingReview",
      score?: number | null,
      title?: string | null,
      review?: string | null,
      createdAt?: string | null,
      reviewer?: string | null,
      reviewerPhoto?: string | null,
    } | null,
    pingAwards?:  Array< {
      __typename: "PingAward",
      date?: string | null,
      type?: string | null,
    } | null > | null,
    blogPosts?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bookingTables?:  {
      __typename: "ModelBookableTableConnection",
      items:  Array< {
        __typename: "BookableTable",
        id: string,
        tableNumber?: number | null,
        capacity?: number | null,
        area?: string | null,
        entityId: string,
        ttl?: number | null,
        maxPeople?: number | null,
        minPeople?: number | null,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dailyPromotions?:  {
      __typename: "ModelDailyPromotionsConnection",
      items:  Array< {
        __typename: "DailyPromotions",
        id: string,
        entityId: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        description?: string | null,
        price?: number | null,
        images?: Array< string | null > | null,
        title?: string | null,
        status?: string | null,
        disclaimer?: string | null,
        owner?: string | null,
        subscribersOnly?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    collection?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateEntityMutationVariables = {
  input: UpdateEntityInput,
  condition?: ModelEntityConditionInput | null,
};

export type UpdateEntityMutation = {
  updateEntity?:  {
    __typename: "Entity",
    id: string,
    title: string,
    shortUrl?: string | null,
    subtitle?: string | null,
    lastAdminCheck?: string | null,
    area?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    notes?: string | null,
    summary?: string | null,
    uniqueAspect?: string | null,
    tags?: Array< string | null > | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    nickname?: string | null,
    hasEmailed?: boolean | null,
    firstName?: string | null,
    hasStudentDiscount?: boolean | null,
    LastName?: string | null,
    config?:  {
      __typename: "EntityConfig",
      loyaltyEnabled?: boolean | null,
      canMessage?: boolean | null,
      canBook?: boolean | null,
      canCheckin?: boolean | null,
      canCall?: boolean | null,
      canEmail?: boolean | null,
      autoResponseMessage?: string | null,
      enterVenueCheckInEnabled?: boolean | null,
      checkInMessage?: string | null,
      colorTheme?: string | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
      bookingConfig?:  {
        __typename: "EntityBookingConfig",
        type?: string | null,
        bookingProvider?: EntityBookingProvider | null,
        bookingProviderBackup?: string | null,
        bookingProviderUsername?: string | null,
        bookingProviderUsernameBackup?: string | null,
        canAutoBook?: boolean | null,
        notes?: string | null,
        bookingDurationMins?: number | null,
        bookingEmails?: Array< string | null > | null,
        bookingPhoneNumber?: string | null,
        areas?: Array< string | null > | null,
      } | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      enableTrackTrace?: boolean | null,
    } | null,
    nearbyStations?:  Array< {
      __typename: "EntityClosestStation",
      title?: string | null,
      distance?: number | null,
      line?: string | null,
      zone?: number | null,
      type?: string | null,
    } | null > | null,
    outdoorArea?: boolean | null,
    outdoorHeating?: boolean | null,
    description?: string | null,
    stripeSubscriptionItemId?: string | null,
    emojiSummary?: Array< string | null > | null,
    categories?: Array< string | null > | null,
    phone?: string | null,
    internationalPhoneNumber?: string | null,
    logo?: string | null,
    pushTimestamps?: Array< number | null > | null,
    websiteUrl?: string | null,
    orderfromTableUrl?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    entityTitle?: string | null,
    opens?: number | null,
    closes?: number | null,
    asFeaturedOn?: Array< string | null > | null,
    username?: string | null,
    trendingScore?: number | null,
    openHours?:  {
      __typename: "DailyOpeningTimes",
      monday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      tuesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      wednesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      thursday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      friday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      saturday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      sunday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
    } | null,
    status: string,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    virtualTourUrl?: string | null,
    emailsDisabled?: boolean | null,
    reviewsDisabled?: boolean | null,
    video?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    headerVideos?: Array< string | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    city: string,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    entityFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    rules?: string | null,
    position?: number | null,
    score?: number | null,
    score_before_boost?: number | null,
    boost_multiplier?: number | null,
    boost_until?: string | null,
    boostPurchaseId?: string | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    canMessage?: boolean | null,
    forceupdate?: boolean | null,
    email?: string | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    price?: string | null,
    featured?: string | null,
    cuisines?: Array< string | null > | null,
    dietryOptions?: Array< string | null > | null,
    foodDeliveryAppLinks?:  Array< {
      __typename: "FoodDeliveryAppLink",
      title?: string | null,
      icon?: string | null,
      url?: string | null,
    } | null > | null,
    verified?: boolean | null,
    qrIds?: Array< string | null > | null,
    publisherId: string,
    subscribeOfferId?: string | null,
    subscriberCount?: number | null,
    viewCount?: number | null,
    bookCount?: number | null,
    shareCount?: number | null,
    checkInCount?: number | null,
    addToListCount?: number | null,
    pushTimestamp?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    images?: Array< string | null > | null,
    googleImages?:  Array< {
      __typename: "GoogleImage",
      html_attributions?: Array< string | null > | null,
      image?: string | null,
    } | null > | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    minBookingLimit?: number | null,
    maxBookingLimit?: number | null,
    bookingUrl?: string | null,
    createdAt: number,
    offersArray?: Array< string | null > | null,
    whatsappNumber?: string | null,
    updatedAt: number,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    owner?: string | null,
    reviewScores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    safetyChecklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    venueSafetyData?:  {
      __typename: "VenueSafetyData",
      list?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      links?: Array< string | null > | null,
      updated?: string | null,
    } | null,
    capacity?: number | null,
    acceptWalkIns?: boolean | null,
    covidMeasures?:  {
      __typename: "CovidMeasures",
      capacityPercentage?: number | null,
      tableLimit?: number | null,
      masksRequired?: boolean | null,
      bookingsRequired?: boolean | null,
      notes?: string | null,
    } | null,
    mediaChannels?:  {
      __typename: "MediaChannels",
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      amazonMusicUrl?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    pingReview?:  {
      __typename: "PingReview",
      score?: number | null,
      title?: string | null,
      review?: string | null,
      createdAt?: string | null,
      reviewer?: string | null,
      reviewerPhoto?: string | null,
    } | null,
    pingAwards?:  Array< {
      __typename: "PingAward",
      date?: string | null,
      type?: string | null,
    } | null > | null,
    blogPosts?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bookingTables?:  {
      __typename: "ModelBookableTableConnection",
      items:  Array< {
        __typename: "BookableTable",
        id: string,
        tableNumber?: number | null,
        capacity?: number | null,
        area?: string | null,
        entityId: string,
        ttl?: number | null,
        maxPeople?: number | null,
        minPeople?: number | null,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dailyPromotions?:  {
      __typename: "ModelDailyPromotionsConnection",
      items:  Array< {
        __typename: "DailyPromotions",
        id: string,
        entityId: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        description?: string | null,
        price?: number | null,
        images?: Array< string | null > | null,
        title?: string | null,
        status?: string | null,
        disclaimer?: string | null,
        owner?: string | null,
        subscribersOnly?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    collection?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteEntityMutationVariables = {
  input: DeleteEntityInput,
  condition?: ModelEntityConditionInput | null,
};

export type DeleteEntityMutation = {
  deleteEntity?:  {
    __typename: "Entity",
    id: string,
    title: string,
    shortUrl?: string | null,
    subtitle?: string | null,
    lastAdminCheck?: string | null,
    area?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    notes?: string | null,
    summary?: string | null,
    uniqueAspect?: string | null,
    tags?: Array< string | null > | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    nickname?: string | null,
    hasEmailed?: boolean | null,
    firstName?: string | null,
    hasStudentDiscount?: boolean | null,
    LastName?: string | null,
    config?:  {
      __typename: "EntityConfig",
      loyaltyEnabled?: boolean | null,
      canMessage?: boolean | null,
      canBook?: boolean | null,
      canCheckin?: boolean | null,
      canCall?: boolean | null,
      canEmail?: boolean | null,
      autoResponseMessage?: string | null,
      enterVenueCheckInEnabled?: boolean | null,
      checkInMessage?: string | null,
      colorTheme?: string | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
      bookingConfig?:  {
        __typename: "EntityBookingConfig",
        type?: string | null,
        bookingProvider?: EntityBookingProvider | null,
        bookingProviderBackup?: string | null,
        bookingProviderUsername?: string | null,
        bookingProviderUsernameBackup?: string | null,
        canAutoBook?: boolean | null,
        notes?: string | null,
        bookingDurationMins?: number | null,
        bookingEmails?: Array< string | null > | null,
        bookingPhoneNumber?: string | null,
        areas?: Array< string | null > | null,
      } | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      enableTrackTrace?: boolean | null,
    } | null,
    nearbyStations?:  Array< {
      __typename: "EntityClosestStation",
      title?: string | null,
      distance?: number | null,
      line?: string | null,
      zone?: number | null,
      type?: string | null,
    } | null > | null,
    outdoorArea?: boolean | null,
    outdoorHeating?: boolean | null,
    description?: string | null,
    stripeSubscriptionItemId?: string | null,
    emojiSummary?: Array< string | null > | null,
    categories?: Array< string | null > | null,
    phone?: string | null,
    internationalPhoneNumber?: string | null,
    logo?: string | null,
    pushTimestamps?: Array< number | null > | null,
    websiteUrl?: string | null,
    orderfromTableUrl?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    entityTitle?: string | null,
    opens?: number | null,
    closes?: number | null,
    asFeaturedOn?: Array< string | null > | null,
    username?: string | null,
    trendingScore?: number | null,
    openHours?:  {
      __typename: "DailyOpeningTimes",
      monday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      tuesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      wednesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      thursday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      friday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      saturday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      sunday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
    } | null,
    status: string,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    virtualTourUrl?: string | null,
    emailsDisabled?: boolean | null,
    reviewsDisabled?: boolean | null,
    video?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    headerVideos?: Array< string | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    city: string,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    entityFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    rules?: string | null,
    position?: number | null,
    score?: number | null,
    score_before_boost?: number | null,
    boost_multiplier?: number | null,
    boost_until?: string | null,
    boostPurchaseId?: string | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    canMessage?: boolean | null,
    forceupdate?: boolean | null,
    email?: string | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    price?: string | null,
    featured?: string | null,
    cuisines?: Array< string | null > | null,
    dietryOptions?: Array< string | null > | null,
    foodDeliveryAppLinks?:  Array< {
      __typename: "FoodDeliveryAppLink",
      title?: string | null,
      icon?: string | null,
      url?: string | null,
    } | null > | null,
    verified?: boolean | null,
    qrIds?: Array< string | null > | null,
    publisherId: string,
    subscribeOfferId?: string | null,
    subscriberCount?: number | null,
    viewCount?: number | null,
    bookCount?: number | null,
    shareCount?: number | null,
    checkInCount?: number | null,
    addToListCount?: number | null,
    pushTimestamp?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    images?: Array< string | null > | null,
    googleImages?:  Array< {
      __typename: "GoogleImage",
      html_attributions?: Array< string | null > | null,
      image?: string | null,
    } | null > | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    minBookingLimit?: number | null,
    maxBookingLimit?: number | null,
    bookingUrl?: string | null,
    createdAt: number,
    offersArray?: Array< string | null > | null,
    whatsappNumber?: string | null,
    updatedAt: number,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    owner?: string | null,
    reviewScores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    safetyChecklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    venueSafetyData?:  {
      __typename: "VenueSafetyData",
      list?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      links?: Array< string | null > | null,
      updated?: string | null,
    } | null,
    capacity?: number | null,
    acceptWalkIns?: boolean | null,
    covidMeasures?:  {
      __typename: "CovidMeasures",
      capacityPercentage?: number | null,
      tableLimit?: number | null,
      masksRequired?: boolean | null,
      bookingsRequired?: boolean | null,
      notes?: string | null,
    } | null,
    mediaChannels?:  {
      __typename: "MediaChannels",
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      amazonMusicUrl?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    pingReview?:  {
      __typename: "PingReview",
      score?: number | null,
      title?: string | null,
      review?: string | null,
      createdAt?: string | null,
      reviewer?: string | null,
      reviewerPhoto?: string | null,
    } | null,
    pingAwards?:  Array< {
      __typename: "PingAward",
      date?: string | null,
      type?: string | null,
    } | null > | null,
    blogPosts?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bookingTables?:  {
      __typename: "ModelBookableTableConnection",
      items:  Array< {
        __typename: "BookableTable",
        id: string,
        tableNumber?: number | null,
        capacity?: number | null,
        area?: string | null,
        entityId: string,
        ttl?: number | null,
        maxPeople?: number | null,
        minPeople?: number | null,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dailyPromotions?:  {
      __typename: "ModelDailyPromotionsConnection",
      items:  Array< {
        __typename: "DailyPromotions",
        id: string,
        entityId: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        description?: string | null,
        price?: number | null,
        images?: Array< string | null > | null,
        title?: string | null,
        status?: string | null,
        disclaimer?: string | null,
        owner?: string | null,
        subscribersOnly?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    collection?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateFeaturedPlaceMutationVariables = {
  input: CreateFeaturedPlaceInput,
  condition?: ModelFeaturedPlaceConditionInput | null,
};

export type CreateFeaturedPlaceMutation = {
  createFeaturedPlace?:  {
    __typename: "FeaturedPlace",
    id: string,
    entityId: string,
    eventId?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    area?: string | null,
    title?: string | null,
    description?: string | null,
    pinToTop?: boolean | null,
    city?: string | null,
    featuredUntil?: string | null,
    coverage?: string | null,
    country?: string | null,
    days_selected?: Array< number | null > | null,
    startDate?: string | null,
    budget?: number | null,
    budget_left?: number | null,
    paid?: number | null,
    viewCount?: number | null,
    owner?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    status?: string | null,
    ttl?: number | null,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateFeaturedPlaceMutationVariables = {
  input: UpdateFeaturedPlaceInput,
  condition?: ModelFeaturedPlaceConditionInput | null,
};

export type UpdateFeaturedPlaceMutation = {
  updateFeaturedPlace?:  {
    __typename: "FeaturedPlace",
    id: string,
    entityId: string,
    eventId?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    area?: string | null,
    title?: string | null,
    description?: string | null,
    pinToTop?: boolean | null,
    city?: string | null,
    featuredUntil?: string | null,
    coverage?: string | null,
    country?: string | null,
    days_selected?: Array< number | null > | null,
    startDate?: string | null,
    budget?: number | null,
    budget_left?: number | null,
    paid?: number | null,
    viewCount?: number | null,
    owner?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    status?: string | null,
    ttl?: number | null,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteFeaturedPlaceMutationVariables = {
  input: DeleteFeaturedPlaceInput,
  condition?: ModelFeaturedPlaceConditionInput | null,
};

export type DeleteFeaturedPlaceMutation = {
  deleteFeaturedPlace?:  {
    __typename: "FeaturedPlace",
    id: string,
    entityId: string,
    eventId?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    area?: string | null,
    title?: string | null,
    description?: string | null,
    pinToTop?: boolean | null,
    city?: string | null,
    featuredUntil?: string | null,
    coverage?: string | null,
    country?: string | null,
    days_selected?: Array< number | null > | null,
    startDate?: string | null,
    budget?: number | null,
    budget_left?: number | null,
    paid?: number | null,
    viewCount?: number | null,
    owner?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    status?: string | null,
    ttl?: number | null,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateBookingsMutationVariables = {
  input: CreateBookingsInput,
  condition?: ModelBookingsConditionInput | null,
};

export type CreateBookingsMutation = {
  createBookings?:  {
    __typename: "Bookings",
    id: string,
    userName: string,
    userId: string,
    publisher?: string | null,
    bookingType?: string | null,
    entityId: string,
    venueNotes?: string | null,
    durationInMinutes?: number | null,
    entityTitle?: string | null,
    userEmail?: string | null,
    userPhone?: string | null,
    confirmed?: boolean | null,
    owners: Array< string | null >,
    owner?: string | null,
    dateTime: string,
    tableId: string,
    timeString?: string | null,
    provider?: BookingSystemProvider | null,
    dateString?: string | null,
    dateTimestamp: number,
    people: number,
    artistId?: string | null,
    notes?: string | null,
    status: string,
    ttl?: number | null,
    paymentStatus?: string | null,
    artistBookingNotes?:  {
      __typename: "ArtistBookingNotes",
      isEquiptmentProvided?: boolean | null,
      isBirthday?: boolean | null,
      isBrandEvent?: boolean | null,
      BrandDetails?: string | null,
      genresRequired?: string | null,
      equiptmentRequired?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateBookingsMutationVariables = {
  input: UpdateBookingsInput,
  condition?: ModelBookingsConditionInput | null,
};

export type UpdateBookingsMutation = {
  updateBookings?:  {
    __typename: "Bookings",
    id: string,
    userName: string,
    userId: string,
    publisher?: string | null,
    bookingType?: string | null,
    entityId: string,
    venueNotes?: string | null,
    durationInMinutes?: number | null,
    entityTitle?: string | null,
    userEmail?: string | null,
    userPhone?: string | null,
    confirmed?: boolean | null,
    owners: Array< string | null >,
    owner?: string | null,
    dateTime: string,
    tableId: string,
    timeString?: string | null,
    provider?: BookingSystemProvider | null,
    dateString?: string | null,
    dateTimestamp: number,
    people: number,
    artistId?: string | null,
    notes?: string | null,
    status: string,
    ttl?: number | null,
    paymentStatus?: string | null,
    artistBookingNotes?:  {
      __typename: "ArtistBookingNotes",
      isEquiptmentProvided?: boolean | null,
      isBirthday?: boolean | null,
      isBrandEvent?: boolean | null,
      BrandDetails?: string | null,
      genresRequired?: string | null,
      equiptmentRequired?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteBookingsMutationVariables = {
  input: DeleteBookingsInput,
  condition?: ModelBookingsConditionInput | null,
};

export type DeleteBookingsMutation = {
  deleteBookings?:  {
    __typename: "Bookings",
    id: string,
    userName: string,
    userId: string,
    publisher?: string | null,
    bookingType?: string | null,
    entityId: string,
    venueNotes?: string | null,
    durationInMinutes?: number | null,
    entityTitle?: string | null,
    userEmail?: string | null,
    userPhone?: string | null,
    confirmed?: boolean | null,
    owners: Array< string | null >,
    owner?: string | null,
    dateTime: string,
    tableId: string,
    timeString?: string | null,
    provider?: BookingSystemProvider | null,
    dateString?: string | null,
    dateTimestamp: number,
    people: number,
    artistId?: string | null,
    notes?: string | null,
    status: string,
    ttl?: number | null,
    paymentStatus?: string | null,
    artistBookingNotes?:  {
      __typename: "ArtistBookingNotes",
      isEquiptmentProvided?: boolean | null,
      isBirthday?: boolean | null,
      isBrandEvent?: boolean | null,
      BrandDetails?: string | null,
      genresRequired?: string | null,
      equiptmentRequired?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreatePodcastMutationVariables = {
  input: CreatePodcastInput,
  condition?: ModelPodcastConditionInput | null,
};

export type CreatePodcastMutation = {
  createPodcast?:  {
    __typename: "Podcast",
    id: string,
    entityId: string,
    status?: string | null,
    embedType?: string | null,
    embedId?: string | null,
    embedUrl?: string | null,
    title?: string | null,
    description?: string | null,
    images?: Array< string | null > | null,
    username?: string | null,
    categories?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    guests?:  Array< {
      __typename: "PodcastGuest",
      name?: string | null,
      description?: string | null,
      url?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      details?: string | null,
      image?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdatePodcastMutationVariables = {
  input: UpdatePodcastInput,
  condition?: ModelPodcastConditionInput | null,
};

export type UpdatePodcastMutation = {
  updatePodcast?:  {
    __typename: "Podcast",
    id: string,
    entityId: string,
    status?: string | null,
    embedType?: string | null,
    embedId?: string | null,
    embedUrl?: string | null,
    title?: string | null,
    description?: string | null,
    images?: Array< string | null > | null,
    username?: string | null,
    categories?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    guests?:  Array< {
      __typename: "PodcastGuest",
      name?: string | null,
      description?: string | null,
      url?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      details?: string | null,
      image?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeletePodcastMutationVariables = {
  input: DeletePodcastInput,
  condition?: ModelPodcastConditionInput | null,
};

export type DeletePodcastMutation = {
  deletePodcast?:  {
    __typename: "Podcast",
    id: string,
    entityId: string,
    status?: string | null,
    embedType?: string | null,
    embedId?: string | null,
    embedUrl?: string | null,
    title?: string | null,
    description?: string | null,
    images?: Array< string | null > | null,
    username?: string | null,
    categories?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    guests?:  Array< {
      __typename: "PodcastGuest",
      name?: string | null,
      description?: string | null,
      url?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      details?: string | null,
      image?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreatePublisherMutationVariables = {
  input: CreatePublisherInput,
  condition?: ModelPublisherConditionInput | null,
};

export type CreatePublisherMutation = {
  createPublisher?:  {
    __typename: "Publisher",
    id: string,
    cognitoUsername?: string | null,
    financeEmail?: string | null,
    role?: string | null,
    profilePicture?: string | null,
    bio?: string | null,
    config?:  {
      __typename: "PublisherConfig",
      features?:  {
        __typename: "ConfigFeatures",
        dashboard?: boolean | null,
        events?: boolean | null,
        places?: boolean | null,
        loyalty?: boolean | null,
        messaging?: boolean | null,
        offers?: boolean | null,
        promos?: boolean | null,
        subscribers?: boolean | null,
        updates?: boolean | null,
        reviews?: boolean | null,
        bookings?: boolean | null,
      } | null,
      type?: PublisherType | null,
      isBespoke?: boolean | null,
      standAloneVenue?: boolean | null,
      notificationsEnabled?: boolean | null,
      mailMarketingProvider?: MailMarketingProvider | null,
      paymentProvier?: PaymentProvider | null,
      POSProviders?: POSProvider | null,
      eventsConfig?:  {
        __typename: "ConfigEvents",
        usePaymentProvider?: boolean | null,
        tablesEnabled?: boolean | null,
      } | null,
      bookingsConfig?:  {
        __typename: "ConfigBookings",
        bookingProvider?: BookingSystemProvider | null,
        integratePOS?: boolean | null,
        emailsDisabled?: boolean | null,
      } | null,
      offersConfig?:  {
        __typename: "ConfigOffers",
        integratePOS?: boolean | null,
      } | null,
      subscribersConfig?:  {
        __typename: "ConfigSubscribers",
        isPaid?: boolean | null,
        subscriptionLength?: number | null,
      } | null,
      loyaltyConfig?:  {
        __typename: "ConfigLoyalty",
        integratePOS?: boolean | null,
        loyaltyEnabled?: boolean | null,
        color?: string | null,
        textColor?: string | null,
        loyaltyPeriodInDays?: number | null,
        logo?: string | null,
        listLogo?: string | null,
        cardLogo?: string | null,
        tiersEnabled?: boolean | null,
        type?: LoyaltySystemType | null,
        businessShortName?: string | null,
        loyaltyPointCurrencyValue?: number | null,
        classes?: string | null,
        securityCode?: number | null,
        disabledAutoTierCardColours?: boolean | null,
        useMainLogo?: boolean | null,
        textPosition?: string | null,
        customCardDesignUrl?: string | null,
        text?: string | null,
      } | null,
      menuConfig?:  {
        __typename: "MenuConfig",
        integratePOS?: boolean | null,
      } | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    publisherType: PublisherType,
    editors?: Array< string | null > | null,
    owner: string,
    username?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    country?:  {
      __typename: "Country",
      currencies?:  Array< {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null > | null,
      flag?: string | null,
      name?: string | null,
      alpha2Code?: string | null,
      alpha3Code?: string | null,
      callingCodes?: Array< string | null > | null,
      timezones?: Array< string | null > | null,
      region?: string | null,
    } | null,
    pushTimestamps?: Array< number | null > | null,
    emailsDisabled?: boolean | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    timezone?: string | null,
    subscibedToEmails?: boolean | null,
    timezoneOffset?: number | null,
    logo?: string | null,
    businessName?: string | null,
    description?: string | null,
    websiteUrl?: string | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    canMessage?: boolean | null,
    subscriberCount?: number | null,
    loyaltyCount?: number | null,
    status?: string | null,
    termsVersionSigned?: number | null,
    activated?: string | null,
    reviewsDisabled?: boolean | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    activationDate?: string | null,
    stripeConnectId?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    stripeCustomerId?: string | null,
    fidelId?: string | null,
    stripeSubscriptions?:  Array< {
      __typename: "StripeCustomerSubscription",
      id?: string | null,
      current_period_end?: number | null,
      current_period_start?: number | null,
      quantity?: number | null,
      entitiesIncluded?: Array< string | null > | null,
      items?:  Array< {
        __typename: "StripeCustomerSubscriptionItem",
        id?: string | null,
        priceId?: string | null,
        quantity?: number | null,
      } | null > | null,
      latest_invoice?: string | null,
      status?: string | null,
      created?: number | null,
    } | null > | null,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    loyaltyCards?:  {
      __typename: "ModelLoyaltyCardConnection",
      items:  Array< {
        __typename: "LoyaltyCard",
        id: string,
        userId: string,
        publisherId: string,
        businessTitle?: string | null,
        currentTier?: string | null,
        entityId?: string | null,
        visits?: number | null,
        businessAssignedTier?: string | null,
        transactions?: number | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        totalSpend?: number | null,
        pendingPoints?: number | null,
        totalPoints?: number | null,
        ownerType?: string | null,
        status?: string | null,
        userFullName?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        totalSpendablePoints?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entities?:  {
      __typename: "ModelEntityConnection",
      items:  Array< {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdatePublisherMutationVariables = {
  input: UpdatePublisherInput,
  condition?: ModelPublisherConditionInput | null,
};

export type UpdatePublisherMutation = {
  updatePublisher?:  {
    __typename: "Publisher",
    id: string,
    cognitoUsername?: string | null,
    financeEmail?: string | null,
    role?: string | null,
    profilePicture?: string | null,
    bio?: string | null,
    config?:  {
      __typename: "PublisherConfig",
      features?:  {
        __typename: "ConfigFeatures",
        dashboard?: boolean | null,
        events?: boolean | null,
        places?: boolean | null,
        loyalty?: boolean | null,
        messaging?: boolean | null,
        offers?: boolean | null,
        promos?: boolean | null,
        subscribers?: boolean | null,
        updates?: boolean | null,
        reviews?: boolean | null,
        bookings?: boolean | null,
      } | null,
      type?: PublisherType | null,
      isBespoke?: boolean | null,
      standAloneVenue?: boolean | null,
      notificationsEnabled?: boolean | null,
      mailMarketingProvider?: MailMarketingProvider | null,
      paymentProvier?: PaymentProvider | null,
      POSProviders?: POSProvider | null,
      eventsConfig?:  {
        __typename: "ConfigEvents",
        usePaymentProvider?: boolean | null,
        tablesEnabled?: boolean | null,
      } | null,
      bookingsConfig?:  {
        __typename: "ConfigBookings",
        bookingProvider?: BookingSystemProvider | null,
        integratePOS?: boolean | null,
        emailsDisabled?: boolean | null,
      } | null,
      offersConfig?:  {
        __typename: "ConfigOffers",
        integratePOS?: boolean | null,
      } | null,
      subscribersConfig?:  {
        __typename: "ConfigSubscribers",
        isPaid?: boolean | null,
        subscriptionLength?: number | null,
      } | null,
      loyaltyConfig?:  {
        __typename: "ConfigLoyalty",
        integratePOS?: boolean | null,
        loyaltyEnabled?: boolean | null,
        color?: string | null,
        textColor?: string | null,
        loyaltyPeriodInDays?: number | null,
        logo?: string | null,
        listLogo?: string | null,
        cardLogo?: string | null,
        tiersEnabled?: boolean | null,
        type?: LoyaltySystemType | null,
        businessShortName?: string | null,
        loyaltyPointCurrencyValue?: number | null,
        classes?: string | null,
        securityCode?: number | null,
        disabledAutoTierCardColours?: boolean | null,
        useMainLogo?: boolean | null,
        textPosition?: string | null,
        customCardDesignUrl?: string | null,
        text?: string | null,
      } | null,
      menuConfig?:  {
        __typename: "MenuConfig",
        integratePOS?: boolean | null,
      } | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    publisherType: PublisherType,
    editors?: Array< string | null > | null,
    owner: string,
    username?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    country?:  {
      __typename: "Country",
      currencies?:  Array< {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null > | null,
      flag?: string | null,
      name?: string | null,
      alpha2Code?: string | null,
      alpha3Code?: string | null,
      callingCodes?: Array< string | null > | null,
      timezones?: Array< string | null > | null,
      region?: string | null,
    } | null,
    pushTimestamps?: Array< number | null > | null,
    emailsDisabled?: boolean | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    timezone?: string | null,
    subscibedToEmails?: boolean | null,
    timezoneOffset?: number | null,
    logo?: string | null,
    businessName?: string | null,
    description?: string | null,
    websiteUrl?: string | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    canMessage?: boolean | null,
    subscriberCount?: number | null,
    loyaltyCount?: number | null,
    status?: string | null,
    termsVersionSigned?: number | null,
    activated?: string | null,
    reviewsDisabled?: boolean | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    activationDate?: string | null,
    stripeConnectId?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    stripeCustomerId?: string | null,
    fidelId?: string | null,
    stripeSubscriptions?:  Array< {
      __typename: "StripeCustomerSubscription",
      id?: string | null,
      current_period_end?: number | null,
      current_period_start?: number | null,
      quantity?: number | null,
      entitiesIncluded?: Array< string | null > | null,
      items?:  Array< {
        __typename: "StripeCustomerSubscriptionItem",
        id?: string | null,
        priceId?: string | null,
        quantity?: number | null,
      } | null > | null,
      latest_invoice?: string | null,
      status?: string | null,
      created?: number | null,
    } | null > | null,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    loyaltyCards?:  {
      __typename: "ModelLoyaltyCardConnection",
      items:  Array< {
        __typename: "LoyaltyCard",
        id: string,
        userId: string,
        publisherId: string,
        businessTitle?: string | null,
        currentTier?: string | null,
        entityId?: string | null,
        visits?: number | null,
        businessAssignedTier?: string | null,
        transactions?: number | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        totalSpend?: number | null,
        pendingPoints?: number | null,
        totalPoints?: number | null,
        ownerType?: string | null,
        status?: string | null,
        userFullName?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        totalSpendablePoints?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entities?:  {
      __typename: "ModelEntityConnection",
      items:  Array< {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeletePublisherMutationVariables = {
  input: DeletePublisherInput,
  condition?: ModelPublisherConditionInput | null,
};

export type DeletePublisherMutation = {
  deletePublisher?:  {
    __typename: "Publisher",
    id: string,
    cognitoUsername?: string | null,
    financeEmail?: string | null,
    role?: string | null,
    profilePicture?: string | null,
    bio?: string | null,
    config?:  {
      __typename: "PublisherConfig",
      features?:  {
        __typename: "ConfigFeatures",
        dashboard?: boolean | null,
        events?: boolean | null,
        places?: boolean | null,
        loyalty?: boolean | null,
        messaging?: boolean | null,
        offers?: boolean | null,
        promos?: boolean | null,
        subscribers?: boolean | null,
        updates?: boolean | null,
        reviews?: boolean | null,
        bookings?: boolean | null,
      } | null,
      type?: PublisherType | null,
      isBespoke?: boolean | null,
      standAloneVenue?: boolean | null,
      notificationsEnabled?: boolean | null,
      mailMarketingProvider?: MailMarketingProvider | null,
      paymentProvier?: PaymentProvider | null,
      POSProviders?: POSProvider | null,
      eventsConfig?:  {
        __typename: "ConfigEvents",
        usePaymentProvider?: boolean | null,
        tablesEnabled?: boolean | null,
      } | null,
      bookingsConfig?:  {
        __typename: "ConfigBookings",
        bookingProvider?: BookingSystemProvider | null,
        integratePOS?: boolean | null,
        emailsDisabled?: boolean | null,
      } | null,
      offersConfig?:  {
        __typename: "ConfigOffers",
        integratePOS?: boolean | null,
      } | null,
      subscribersConfig?:  {
        __typename: "ConfigSubscribers",
        isPaid?: boolean | null,
        subscriptionLength?: number | null,
      } | null,
      loyaltyConfig?:  {
        __typename: "ConfigLoyalty",
        integratePOS?: boolean | null,
        loyaltyEnabled?: boolean | null,
        color?: string | null,
        textColor?: string | null,
        loyaltyPeriodInDays?: number | null,
        logo?: string | null,
        listLogo?: string | null,
        cardLogo?: string | null,
        tiersEnabled?: boolean | null,
        type?: LoyaltySystemType | null,
        businessShortName?: string | null,
        loyaltyPointCurrencyValue?: number | null,
        classes?: string | null,
        securityCode?: number | null,
        disabledAutoTierCardColours?: boolean | null,
        useMainLogo?: boolean | null,
        textPosition?: string | null,
        customCardDesignUrl?: string | null,
        text?: string | null,
      } | null,
      menuConfig?:  {
        __typename: "MenuConfig",
        integratePOS?: boolean | null,
      } | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    publisherType: PublisherType,
    editors?: Array< string | null > | null,
    owner: string,
    username?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    country?:  {
      __typename: "Country",
      currencies?:  Array< {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null > | null,
      flag?: string | null,
      name?: string | null,
      alpha2Code?: string | null,
      alpha3Code?: string | null,
      callingCodes?: Array< string | null > | null,
      timezones?: Array< string | null > | null,
      region?: string | null,
    } | null,
    pushTimestamps?: Array< number | null > | null,
    emailsDisabled?: boolean | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    timezone?: string | null,
    subscibedToEmails?: boolean | null,
    timezoneOffset?: number | null,
    logo?: string | null,
    businessName?: string | null,
    description?: string | null,
    websiteUrl?: string | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    canMessage?: boolean | null,
    subscriberCount?: number | null,
    loyaltyCount?: number | null,
    status?: string | null,
    termsVersionSigned?: number | null,
    activated?: string | null,
    reviewsDisabled?: boolean | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    activationDate?: string | null,
    stripeConnectId?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    stripeCustomerId?: string | null,
    fidelId?: string | null,
    stripeSubscriptions?:  Array< {
      __typename: "StripeCustomerSubscription",
      id?: string | null,
      current_period_end?: number | null,
      current_period_start?: number | null,
      quantity?: number | null,
      entitiesIncluded?: Array< string | null > | null,
      items?:  Array< {
        __typename: "StripeCustomerSubscriptionItem",
        id?: string | null,
        priceId?: string | null,
        quantity?: number | null,
      } | null > | null,
      latest_invoice?: string | null,
      status?: string | null,
      created?: number | null,
    } | null > | null,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    loyaltyCards?:  {
      __typename: "ModelLoyaltyCardConnection",
      items:  Array< {
        __typename: "LoyaltyCard",
        id: string,
        userId: string,
        publisherId: string,
        businessTitle?: string | null,
        currentTier?: string | null,
        entityId?: string | null,
        visits?: number | null,
        businessAssignedTier?: string | null,
        transactions?: number | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        totalSpend?: number | null,
        pendingPoints?: number | null,
        totalPoints?: number | null,
        ownerType?: string | null,
        status?: string | null,
        userFullName?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        totalSpendablePoints?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entities?:  {
      __typename: "ModelEntityConnection",
      items:  Array< {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateScheduleQueueMutationVariables = {
  input: CreateScheduleQueueInput,
  condition?: ModelScheduleQueueConditionInput | null,
};

export type CreateScheduleQueueMutation = {
  createScheduleQueue?:  {
    __typename: "ScheduleQueue",
    id: string,
    type?: string | null,
    due?: string | null,
    action?: string | null,
    Payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateScheduleQueueMutationVariables = {
  input: UpdateScheduleQueueInput,
  condition?: ModelScheduleQueueConditionInput | null,
};

export type UpdateScheduleQueueMutation = {
  updateScheduleQueue?:  {
    __typename: "ScheduleQueue",
    id: string,
    type?: string | null,
    due?: string | null,
    action?: string | null,
    Payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteScheduleQueueMutationVariables = {
  input: DeleteScheduleQueueInput,
  condition?: ModelScheduleQueueConditionInput | null,
};

export type DeleteScheduleQueueMutation = {
  deleteScheduleQueue?:  {
    __typename: "ScheduleQueue",
    id: string,
    type?: string | null,
    due?: string | null,
    action?: string | null,
    Payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStripePaymentMutationVariables = {
  input: CreateStripePaymentInput,
  condition?: ModelStripePaymentConditionInput | null,
};

export type CreateStripePaymentMutation = {
  createStripePayment?:  {
    __typename: "StripePayment",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    type?: string | null,
    owner?: string | null,
    amount?: string | null,
    currency?: string | null,
    receipt_url?: string | null,
    invoice_url?: string | null,
    invoice_id?: string | null,
    additionalInvoiceItems?: Array< string | null > | null,
    status?: string | null,
    intentId?: string | null,
    userId?: string | null,
    publisherId?: string | null,
    productId?: string | null,
    metaData?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateStripePaymentMutationVariables = {
  input: UpdateStripePaymentInput,
  condition?: ModelStripePaymentConditionInput | null,
};

export type UpdateStripePaymentMutation = {
  updateStripePayment?:  {
    __typename: "StripePayment",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    type?: string | null,
    owner?: string | null,
    amount?: string | null,
    currency?: string | null,
    receipt_url?: string | null,
    invoice_url?: string | null,
    invoice_id?: string | null,
    additionalInvoiceItems?: Array< string | null > | null,
    status?: string | null,
    intentId?: string | null,
    userId?: string | null,
    publisherId?: string | null,
    productId?: string | null,
    metaData?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteStripePaymentMutationVariables = {
  input: DeleteStripePaymentInput,
  condition?: ModelStripePaymentConditionInput | null,
};

export type DeleteStripePaymentMutation = {
  deleteStripePayment?:  {
    __typename: "StripePayment",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    type?: string | null,
    owner?: string | null,
    amount?: string | null,
    currency?: string | null,
    receipt_url?: string | null,
    invoice_url?: string | null,
    invoice_id?: string | null,
    additionalInvoiceItems?: Array< string | null > | null,
    status?: string | null,
    intentId?: string | null,
    userId?: string | null,
    publisherId?: string | null,
    productId?: string | null,
    metaData?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateCategoriesMutationVariables = {
  input: CreateCategoriesInput,
  condition?: ModelCategoriesConditionInput | null,
};

export type CreateCategoriesMutation = {
  createCategories?:  {
    __typename: "Categories",
    id: string,
    title: string,
    type?: string | null,
    icon?: string | null,
    desc?: string | null,
    pingEditTitle?: string | null,
    metaTitle?: string | null,
    metaDescription?: string | null,
    priority?: number | null,
    showcaseImage?: string | null,
    categoryCityCount?: string | null,
    isHidden?: boolean | null,
    citiesAvailable?: Array< string | null > | null,
    translateString?: string | null,
    colour: string,
    image: string,
    subCategories?:  Array< {
      __typename: "Category",
      title: string,
      image: string,
      isAdmin?: boolean | null,
      desc?: string | null,
      showcaseImage?: string | null,
      translateString?: string | null,
      pingEditTitle?: string | null,
      metaTitle?: string | null,
      metaDescription?: string | null,
      categoryCityCount?: string | null,
      isHidden?: boolean | null,
      citiesAvailable?: Array< string | null > | null,
      icon?: string | null,
      colour?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCategoriesMutationVariables = {
  input: UpdateCategoriesInput,
  condition?: ModelCategoriesConditionInput | null,
};

export type UpdateCategoriesMutation = {
  updateCategories?:  {
    __typename: "Categories",
    id: string,
    title: string,
    type?: string | null,
    icon?: string | null,
    desc?: string | null,
    pingEditTitle?: string | null,
    metaTitle?: string | null,
    metaDescription?: string | null,
    priority?: number | null,
    showcaseImage?: string | null,
    categoryCityCount?: string | null,
    isHidden?: boolean | null,
    citiesAvailable?: Array< string | null > | null,
    translateString?: string | null,
    colour: string,
    image: string,
    subCategories?:  Array< {
      __typename: "Category",
      title: string,
      image: string,
      isAdmin?: boolean | null,
      desc?: string | null,
      showcaseImage?: string | null,
      translateString?: string | null,
      pingEditTitle?: string | null,
      metaTitle?: string | null,
      metaDescription?: string | null,
      categoryCityCount?: string | null,
      isHidden?: boolean | null,
      citiesAvailable?: Array< string | null > | null,
      icon?: string | null,
      colour?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCategoriesMutationVariables = {
  input: DeleteCategoriesInput,
  condition?: ModelCategoriesConditionInput | null,
};

export type DeleteCategoriesMutation = {
  deleteCategories?:  {
    __typename: "Categories",
    id: string,
    title: string,
    type?: string | null,
    icon?: string | null,
    desc?: string | null,
    pingEditTitle?: string | null,
    metaTitle?: string | null,
    metaDescription?: string | null,
    priority?: number | null,
    showcaseImage?: string | null,
    categoryCityCount?: string | null,
    isHidden?: boolean | null,
    citiesAvailable?: Array< string | null > | null,
    translateString?: string | null,
    colour: string,
    image: string,
    subCategories?:  Array< {
      __typename: "Category",
      title: string,
      image: string,
      isAdmin?: boolean | null,
      desc?: string | null,
      showcaseImage?: string | null,
      translateString?: string | null,
      pingEditTitle?: string | null,
      metaTitle?: string | null,
      metaDescription?: string | null,
      categoryCityCount?: string | null,
      isHidden?: boolean | null,
      citiesAvailable?: Array< string | null > | null,
      icon?: string | null,
      colour?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVenueCategoryTopicsMutationVariables = {
  input: CreateVenueCategoryTopicsInput,
  condition?: ModelVenueCategoryTopicsConditionInput | null,
};

export type CreateVenueCategoryTopicsMutation = {
  createVenueCategoryTopics?:  {
    __typename: "VenueCategoryTopics",
    id: string,
    title?: string | null,
    subtitle?: string | null,
    image?: string | null,
    URL?: string | null,
    type?: string | null,
    itemId?: string | null,
    content?: string | null,
    pinToTop?: boolean | null,
    tag?: string | null,
    category?: string | null,
    subCategory?: string | null,
    price?: number | null,
    omittedCities?: Array< string | null > | null,
    allowedCities?: Array< string | null > | null,
    orderBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVenueCategoryTopicsMutationVariables = {
  input: UpdateVenueCategoryTopicsInput,
  condition?: ModelVenueCategoryTopicsConditionInput | null,
};

export type UpdateVenueCategoryTopicsMutation = {
  updateVenueCategoryTopics?:  {
    __typename: "VenueCategoryTopics",
    id: string,
    title?: string | null,
    subtitle?: string | null,
    image?: string | null,
    URL?: string | null,
    type?: string | null,
    itemId?: string | null,
    content?: string | null,
    pinToTop?: boolean | null,
    tag?: string | null,
    category?: string | null,
    subCategory?: string | null,
    price?: number | null,
    omittedCities?: Array< string | null > | null,
    allowedCities?: Array< string | null > | null,
    orderBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVenueCategoryTopicsMutationVariables = {
  input: DeleteVenueCategoryTopicsInput,
  condition?: ModelVenueCategoryTopicsConditionInput | null,
};

export type DeleteVenueCategoryTopicsMutation = {
  deleteVenueCategoryTopics?:  {
    __typename: "VenueCategoryTopics",
    id: string,
    title?: string | null,
    subtitle?: string | null,
    image?: string | null,
    URL?: string | null,
    type?: string | null,
    itemId?: string | null,
    content?: string | null,
    pinToTop?: boolean | null,
    tag?: string | null,
    category?: string | null,
    subCategory?: string | null,
    price?: number | null,
    omittedCities?: Array< string | null > | null,
    allowedCities?: Array< string | null > | null,
    orderBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCountryDataMutationVariables = {
  input: CreateCountryDataInput,
  condition?: ModelCountryDataConditionInput | null,
};

export type CreateCountryDataMutation = {
  createCountryData?:  {
    __typename: "CountryData",
    id: string,
    currencies?:  Array< {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null > | null,
    flag?: string | null,
    name?: string | null,
    alpha2Code?: string | null,
    alpha3Code?: string | null,
    callingCodes?: Array< string | null > | null,
    timezones?: Array< string | null > | null,
    region?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCountryDataMutationVariables = {
  input: UpdateCountryDataInput,
  condition?: ModelCountryDataConditionInput | null,
};

export type UpdateCountryDataMutation = {
  updateCountryData?:  {
    __typename: "CountryData",
    id: string,
    currencies?:  Array< {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null > | null,
    flag?: string | null,
    name?: string | null,
    alpha2Code?: string | null,
    alpha3Code?: string | null,
    callingCodes?: Array< string | null > | null,
    timezones?: Array< string | null > | null,
    region?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCountryDataMutationVariables = {
  input: DeleteCountryDataInput,
  condition?: ModelCountryDataConditionInput | null,
};

export type DeleteCountryDataMutation = {
  deleteCountryData?:  {
    __typename: "CountryData",
    id: string,
    currencies?:  Array< {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null > | null,
    flag?: string | null,
    name?: string | null,
    alpha2Code?: string | null,
    alpha3Code?: string | null,
    callingCodes?: Array< string | null > | null,
    timezones?: Array< string | null > | null,
    region?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePingConfigsMutationVariables = {
  input: CreatePingConfigsInput,
  condition?: ModelPingConfigsConditionInput | null,
};

export type CreatePingConfigsMutation = {
  createPingConfigs?:  {
    __typename: "PingConfigs",
    id: string,
    type?: string | null,
    token?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdatePingConfigsMutationVariables = {
  input: UpdatePingConfigsInput,
  condition?: ModelPingConfigsConditionInput | null,
};

export type UpdatePingConfigsMutation = {
  updatePingConfigs?:  {
    __typename: "PingConfigs",
    id: string,
    type?: string | null,
    token?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeletePingConfigsMutationVariables = {
  input: DeletePingConfigsInput,
  condition?: ModelPingConfigsConditionInput | null,
};

export type DeletePingConfigsMutation = {
  deletePingConfigs?:  {
    __typename: "PingConfigs",
    id: string,
    type?: string | null,
    token?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateUserReportMutationVariables = {
  input: CreateUserReportInput,
  condition?: ModelUserReportConditionInput | null,
};

export type CreateUserReportMutation = {
  createUserReport?:  {
    __typename: "UserReport",
    id: string,
    userId: string,
    message?: string | null,
    targetId?: string | null,
    targetType?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type UpdateUserReportMutationVariables = {
  input: UpdateUserReportInput,
  condition?: ModelUserReportConditionInput | null,
};

export type UpdateUserReportMutation = {
  updateUserReport?:  {
    __typename: "UserReport",
    id: string,
    userId: string,
    message?: string | null,
    targetId?: string | null,
    targetType?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type DeleteUserReportMutationVariables = {
  input: DeleteUserReportInput,
  condition?: ModelUserReportConditionInput | null,
};

export type DeleteUserReportMutation = {
  deleteUserReport?:  {
    __typename: "UserReport",
    id: string,
    userId: string,
    message?: string | null,
    targetId?: string | null,
    targetType?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type UpdateFeedbackMutationVariables = {
  input: UpdateFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type UpdateFeedbackMutation = {
  updateFeedback?:  {
    __typename: "Feedback",
    id: string,
    userId?: string | null,
    ttl?: number | null,
    email?: string | null,
    name?: string | null,
    feedback?: string | null,
    createdAt: number,
    updatedAt: number,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteFeedbackMutationVariables = {
  input: DeleteFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type DeleteFeedbackMutation = {
  deleteFeedback?:  {
    __typename: "Feedback",
    id: string,
    userId?: string | null,
    ttl?: number | null,
    email?: string | null,
    name?: string | null,
    feedback?: string | null,
    createdAt: number,
    updatedAt: number,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateVenueTableMutationVariables = {
  input: CreateVenueTableInput,
  condition?: ModelVenueTableConditionInput | null,
};

export type CreateVenueTableMutation = {
  createVenueTable?:  {
    __typename: "VenueTable",
    id: string,
    capacity?: number | null,
    quantityAvailable?: number | null,
    tablesBooked?: number | null,
    price?: number | null,
    includes?:  Array< {
      __typename: "TableIncludes",
      quantity?: number | null,
      title?: string | null,
      description?: string | null,
    } | null > | null,
    disclaimer?: string | null,
    instructions?: string | null,
    parentType?: TableParentType | null,
    entityId: string,
    eventId: string,
    repeatEventId: string,
    status?: string | null,
    title?: string | null,
    owner: string,
    createdAt: number,
    updatedAt: number,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateVenueTableMutationVariables = {
  input: UpdateVenueTableInput,
  condition?: ModelVenueTableConditionInput | null,
};

export type UpdateVenueTableMutation = {
  updateVenueTable?:  {
    __typename: "VenueTable",
    id: string,
    capacity?: number | null,
    quantityAvailable?: number | null,
    tablesBooked?: number | null,
    price?: number | null,
    includes?:  Array< {
      __typename: "TableIncludes",
      quantity?: number | null,
      title?: string | null,
      description?: string | null,
    } | null > | null,
    disclaimer?: string | null,
    instructions?: string | null,
    parentType?: TableParentType | null,
    entityId: string,
    eventId: string,
    repeatEventId: string,
    status?: string | null,
    title?: string | null,
    owner: string,
    createdAt: number,
    updatedAt: number,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteVenueTableMutationVariables = {
  input: DeleteVenueTableInput,
  condition?: ModelVenueTableConditionInput | null,
};

export type DeleteVenueTableMutation = {
  deleteVenueTable?:  {
    __typename: "VenueTable",
    id: string,
    capacity?: number | null,
    quantityAvailable?: number | null,
    tablesBooked?: number | null,
    price?: number | null,
    includes?:  Array< {
      __typename: "TableIncludes",
      quantity?: number | null,
      title?: string | null,
      description?: string | null,
    } | null > | null,
    disclaimer?: string | null,
    instructions?: string | null,
    parentType?: TableParentType | null,
    entityId: string,
    eventId: string,
    repeatEventId: string,
    status?: string | null,
    title?: string | null,
    owner: string,
    createdAt: number,
    updatedAt: number,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateTableBookingRequestMutationVariables = {
  input: CreateTableBookingRequestInput,
  condition?: ModelTableBookingRequestConditionInput | null,
};

export type CreateTableBookingRequestMutation = {
  createTableBookingRequest?:  {
    __typename: "TableBookingRequest",
    id: string,
    userName?: string | null,
    userId: string,
    paid?: boolean | null,
    amountPaid?: number | null,
    eventId: string,
    status?: string | null,
    owners?: Array< string | null > | null,
    tableId: string,
    message?: string | null,
    createdAt: number,
    updatedAt: number,
    table?:  {
      __typename: "VenueTable",
      id: string,
      capacity?: number | null,
      quantityAvailable?: number | null,
      tablesBooked?: number | null,
      price?: number | null,
      includes?:  Array< {
        __typename: "TableIncludes",
        quantity?: number | null,
        title?: string | null,
        description?: string | null,
      } | null > | null,
      disclaimer?: string | null,
      instructions?: string | null,
      parentType?: TableParentType | null,
      entityId: string,
      eventId: string,
      repeatEventId: string,
      status?: string | null,
      title?: string | null,
      owner: string,
      createdAt: number,
      updatedAt: number,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateTableBookingRequestMutationVariables = {
  input: UpdateTableBookingRequestInput,
  condition?: ModelTableBookingRequestConditionInput | null,
};

export type UpdateTableBookingRequestMutation = {
  updateTableBookingRequest?:  {
    __typename: "TableBookingRequest",
    id: string,
    userName?: string | null,
    userId: string,
    paid?: boolean | null,
    amountPaid?: number | null,
    eventId: string,
    status?: string | null,
    owners?: Array< string | null > | null,
    tableId: string,
    message?: string | null,
    createdAt: number,
    updatedAt: number,
    table?:  {
      __typename: "VenueTable",
      id: string,
      capacity?: number | null,
      quantityAvailable?: number | null,
      tablesBooked?: number | null,
      price?: number | null,
      includes?:  Array< {
        __typename: "TableIncludes",
        quantity?: number | null,
        title?: string | null,
        description?: string | null,
      } | null > | null,
      disclaimer?: string | null,
      instructions?: string | null,
      parentType?: TableParentType | null,
      entityId: string,
      eventId: string,
      repeatEventId: string,
      status?: string | null,
      title?: string | null,
      owner: string,
      createdAt: number,
      updatedAt: number,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteTableBookingRequestMutationVariables = {
  input: DeleteTableBookingRequestInput,
  condition?: ModelTableBookingRequestConditionInput | null,
};

export type DeleteTableBookingRequestMutation = {
  deleteTableBookingRequest?:  {
    __typename: "TableBookingRequest",
    id: string,
    userName?: string | null,
    userId: string,
    paid?: boolean | null,
    amountPaid?: number | null,
    eventId: string,
    status?: string | null,
    owners?: Array< string | null > | null,
    tableId: string,
    message?: string | null,
    createdAt: number,
    updatedAt: number,
    table?:  {
      __typename: "VenueTable",
      id: string,
      capacity?: number | null,
      quantityAvailable?: number | null,
      tablesBooked?: number | null,
      price?: number | null,
      includes?:  Array< {
        __typename: "TableIncludes",
        quantity?: number | null,
        title?: string | null,
        description?: string | null,
      } | null > | null,
      disclaimer?: string | null,
      instructions?: string | null,
      parentType?: TableParentType | null,
      entityId: string,
      eventId: string,
      repeatEventId: string,
      status?: string | null,
      title?: string | null,
      owner: string,
      createdAt: number,
      updatedAt: number,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateTicketMutationVariables = {
  input: CreateTicketInput,
  condition?: ModelTicketConditionInput | null,
};

export type CreateTicketMutation = {
  createTicket?:  {
    __typename: "Ticket",
    id: string,
    userId: string,
    bookingReference: string,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    eventType?: string | null,
    eventStartTimestamp?: number | null,
    price?:  {
      __typename: "TicketPrice",
      price?: number | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
    } | null,
    chargeId?: string | null,
    paymentIntentId?: string | null,
    content?: string | null,
    ticketActivasted?: string | null,
    userName?: string | null,
    additionalOptions?:  Array< {
      __typename: "TicketChosenOption",
      title?: string | null,
      chosenOption?: string | null,
    } | null > | null,
    tier?: string | null,
    variation?: string | null,
    owners?: Array< string | null > | null,
    status?: string | null,
    ttl?: number | null,
    test?: string | null,
    refunded?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEventOccurence?:  {
      __typename: "RepeatEventOccurence",
      id: string,
      repeatEventId: string,
      startDate?: string | null,
      endDate?: string | null,
      owners?: Array< string | null > | null,
      isSoldOut?: boolean | null,
      totalTicketsSold?: number | null,
      canBuyTickets?: boolean | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateTicketMutationVariables = {
  input: UpdateTicketInput,
  condition?: ModelTicketConditionInput | null,
};

export type UpdateTicketMutation = {
  updateTicket?:  {
    __typename: "Ticket",
    id: string,
    userId: string,
    bookingReference: string,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    eventType?: string | null,
    eventStartTimestamp?: number | null,
    price?:  {
      __typename: "TicketPrice",
      price?: number | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
    } | null,
    chargeId?: string | null,
    paymentIntentId?: string | null,
    content?: string | null,
    ticketActivasted?: string | null,
    userName?: string | null,
    additionalOptions?:  Array< {
      __typename: "TicketChosenOption",
      title?: string | null,
      chosenOption?: string | null,
    } | null > | null,
    tier?: string | null,
    variation?: string | null,
    owners?: Array< string | null > | null,
    status?: string | null,
    ttl?: number | null,
    test?: string | null,
    refunded?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEventOccurence?:  {
      __typename: "RepeatEventOccurence",
      id: string,
      repeatEventId: string,
      startDate?: string | null,
      endDate?: string | null,
      owners?: Array< string | null > | null,
      isSoldOut?: boolean | null,
      totalTicketsSold?: number | null,
      canBuyTickets?: boolean | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteTicketMutationVariables = {
  input: DeleteTicketInput,
  condition?: ModelTicketConditionInput | null,
};

export type DeleteTicketMutation = {
  deleteTicket?:  {
    __typename: "Ticket",
    id: string,
    userId: string,
    bookingReference: string,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    eventType?: string | null,
    eventStartTimestamp?: number | null,
    price?:  {
      __typename: "TicketPrice",
      price?: number | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
    } | null,
    chargeId?: string | null,
    paymentIntentId?: string | null,
    content?: string | null,
    ticketActivasted?: string | null,
    userName?: string | null,
    additionalOptions?:  Array< {
      __typename: "TicketChosenOption",
      title?: string | null,
      chosenOption?: string | null,
    } | null > | null,
    tier?: string | null,
    variation?: string | null,
    owners?: Array< string | null > | null,
    status?: string | null,
    ttl?: number | null,
    test?: string | null,
    refunded?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEventOccurence?:  {
      __typename: "RepeatEventOccurence",
      id: string,
      repeatEventId: string,
      startDate?: string | null,
      endDate?: string | null,
      owners?: Array< string | null > | null,
      isSoldOut?: boolean | null,
      totalTicketsSold?: number | null,
      canBuyTickets?: boolean | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateTicketVariationOccurenceMutationVariables = {
  input: CreateTicketVariationOccurenceInput,
  condition?: ModelTicketVariationOccurenceConditionInput | null,
};

export type CreateTicketVariationOccurenceMutation = {
  createTicketVariationOccurence?:  {
    __typename: "TicketVariationOccurence",
    id: string,
    eventId?: string | null,
    repeatEventId?: string | null,
    repeatEventOccurenceId?: string | null,
    eventType?: string | null,
    variationRuleId: string,
    isSoldOut?: boolean | null,
    amountSoldCounter?: number | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateTicketVariationOccurenceMutationVariables = {
  input: UpdateTicketVariationOccurenceInput,
  condition?: ModelTicketVariationOccurenceConditionInput | null,
};

export type UpdateTicketVariationOccurenceMutation = {
  updateTicketVariationOccurence?:  {
    __typename: "TicketVariationOccurence",
    id: string,
    eventId?: string | null,
    repeatEventId?: string | null,
    repeatEventOccurenceId?: string | null,
    eventType?: string | null,
    variationRuleId: string,
    isSoldOut?: boolean | null,
    amountSoldCounter?: number | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteTicketVariationOccurenceMutationVariables = {
  input: DeleteTicketVariationOccurenceInput,
  condition?: ModelTicketVariationOccurenceConditionInput | null,
};

export type DeleteTicketVariationOccurenceMutation = {
  deleteTicketVariationOccurence?:  {
    __typename: "TicketVariationOccurence",
    id: string,
    eventId?: string | null,
    repeatEventId?: string | null,
    repeatEventOccurenceId?: string | null,
    eventType?: string | null,
    variationRuleId: string,
    isSoldOut?: boolean | null,
    amountSoldCounter?: number | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUpdateMutationVariables = {
  input: CreateUpdateInput,
  condition?: ModelUpdateConditionInput | null,
};

export type CreateUpdateMutation = {
  createUpdate?:  {
    __typename: "Update",
    id: string,
    title: string,
    content: string,
    parentId: string,
    parentTitle?: string | null,
    isPinned?: boolean | null,
    metadata?: string | null,
    actionCount?: number | null,
    viewCount?: number | null,
    url?: string | null,
    images?: Array< string | null > | null,
    route?: Array< string | null > | null,
    extra?: string | null,
    type?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type UpdateUpdateMutationVariables = {
  input: UpdateUpdateInput,
  condition?: ModelUpdateConditionInput | null,
};

export type UpdateUpdateMutation = {
  updateUpdate?:  {
    __typename: "Update",
    id: string,
    title: string,
    content: string,
    parentId: string,
    parentTitle?: string | null,
    isPinned?: boolean | null,
    metadata?: string | null,
    actionCount?: number | null,
    viewCount?: number | null,
    url?: string | null,
    images?: Array< string | null > | null,
    route?: Array< string | null > | null,
    extra?: string | null,
    type?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type DeleteUpdateMutationVariables = {
  input: DeleteUpdateInput,
  condition?: ModelUpdateConditionInput | null,
};

export type DeleteUpdateMutation = {
  deleteUpdate?:  {
    __typename: "Update",
    id: string,
    title: string,
    content: string,
    parentId: string,
    parentTitle?: string | null,
    isPinned?: boolean | null,
    metadata?: string | null,
    actionCount?: number | null,
    viewCount?: number | null,
    url?: string | null,
    images?: Array< string | null > | null,
    route?: Array< string | null > | null,
    extra?: string | null,
    type?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type CreatePlaceCollectionMutationVariables = {
  input: CreatePlaceCollectionInput,
  condition?: ModelPlaceCollectionConditionInput | null,
};

export type CreatePlaceCollectionMutation = {
  createPlaceCollection?:  {
    __typename: "PlaceCollection",
    id: string,
    userId: string,
    title?: string | null,
    description?: string | null,
    area?: string | null,
    saved?: number | null,
    shared?: number | null,
    likes?: number | null,
    venueCount?: number | null,
    headerImg?: string | null,
    videos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    views?: number | null,
    lockCriteria?: string | null,
    score?: number | null,
    areas?: Array< string | null > | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    username?: string | null,
    city?: string | null,
    cities?: Array< string | null > | null,
    status?: string | null,
    isPublic?: string | null,
    dateTime?: string | null,
    securityCode?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    users?:  {
      __typename: "ModelUserWishListLinkConnection",
      items:  Array< {
        __typename: "UserWishListLink",
        id: string,
        listId: string,
        userId: string,
        remminderSet?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collectionLink?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdatePlaceCollectionMutationVariables = {
  input: UpdatePlaceCollectionInput,
  condition?: ModelPlaceCollectionConditionInput | null,
};

export type UpdatePlaceCollectionMutation = {
  updatePlaceCollection?:  {
    __typename: "PlaceCollection",
    id: string,
    userId: string,
    title?: string | null,
    description?: string | null,
    area?: string | null,
    saved?: number | null,
    shared?: number | null,
    likes?: number | null,
    venueCount?: number | null,
    headerImg?: string | null,
    videos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    views?: number | null,
    lockCriteria?: string | null,
    score?: number | null,
    areas?: Array< string | null > | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    username?: string | null,
    city?: string | null,
    cities?: Array< string | null > | null,
    status?: string | null,
    isPublic?: string | null,
    dateTime?: string | null,
    securityCode?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    users?:  {
      __typename: "ModelUserWishListLinkConnection",
      items:  Array< {
        __typename: "UserWishListLink",
        id: string,
        listId: string,
        userId: string,
        remminderSet?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collectionLink?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeletePlaceCollectionMutationVariables = {
  input: DeletePlaceCollectionInput,
  condition?: ModelPlaceCollectionConditionInput | null,
};

export type DeletePlaceCollectionMutation = {
  deletePlaceCollection?:  {
    __typename: "PlaceCollection",
    id: string,
    userId: string,
    title?: string | null,
    description?: string | null,
    area?: string | null,
    saved?: number | null,
    shared?: number | null,
    likes?: number | null,
    venueCount?: number | null,
    headerImg?: string | null,
    videos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    views?: number | null,
    lockCriteria?: string | null,
    score?: number | null,
    areas?: Array< string | null > | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    username?: string | null,
    city?: string | null,
    cities?: Array< string | null > | null,
    status?: string | null,
    isPublic?: string | null,
    dateTime?: string | null,
    securityCode?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    users?:  {
      __typename: "ModelUserWishListLinkConnection",
      items:  Array< {
        __typename: "UserWishListLink",
        id: string,
        listId: string,
        userId: string,
        remminderSet?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collectionLink?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserWishListLinkMutationVariables = {
  input: CreateUserWishListLinkInput,
  condition?: ModelUserWishListLinkConditionInput | null,
};

export type CreateUserWishListLinkMutation = {
  createUserWishListLink?:  {
    __typename: "UserWishListLink",
    id: string,
    listId: string,
    userId: string,
    remminderSet?: boolean | null,
    createdAt: string,
    updatedAt: string,
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUserWishListLinkMutationVariables = {
  input: UpdateUserWishListLinkInput,
  condition?: ModelUserWishListLinkConditionInput | null,
};

export type UpdateUserWishListLinkMutation = {
  updateUserWishListLink?:  {
    __typename: "UserWishListLink",
    id: string,
    listId: string,
    userId: string,
    remminderSet?: boolean | null,
    createdAt: string,
    updatedAt: string,
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteUserWishListLinkMutationVariables = {
  input: DeleteUserWishListLinkInput,
  condition?: ModelUserWishListLinkConditionInput | null,
};

export type DeleteUserWishListLinkMutation = {
  deleteUserWishListLink?:  {
    __typename: "UserWishListLink",
    id: string,
    listId: string,
    userId: string,
    remminderSet?: boolean | null,
    createdAt: string,
    updatedAt: string,
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreatePlaceCollectionEntityMutationVariables = {
  input: CreatePlaceCollectionEntityInput,
  condition?: ModelPlaceCollectionEntityConditionInput | null,
};

export type CreatePlaceCollectionEntityMutation = {
  createPlaceCollectionEntity?:  {
    __typename: "PlaceCollectionEntity",
    id: string,
    entityId: string,
    listId: string,
    usersVisited?: Array< string | null > | null,
    owners?: Array< string | null > | null,
    orderIndex?: number | null,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type UpdatePlaceCollectionEntityMutationVariables = {
  input: UpdatePlaceCollectionEntityInput,
  condition?: ModelPlaceCollectionEntityConditionInput | null,
};

export type UpdatePlaceCollectionEntityMutation = {
  updatePlaceCollectionEntity?:  {
    __typename: "PlaceCollectionEntity",
    id: string,
    entityId: string,
    listId: string,
    usersVisited?: Array< string | null > | null,
    owners?: Array< string | null > | null,
    orderIndex?: number | null,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type DeletePlaceCollectionEntityMutationVariables = {
  input: DeletePlaceCollectionEntityInput,
  condition?: ModelPlaceCollectionEntityConditionInput | null,
};

export type DeletePlaceCollectionEntityMutation = {
  deletePlaceCollectionEntity?:  {
    __typename: "PlaceCollectionEntity",
    id: string,
    entityId: string,
    listId: string,
    usersVisited?: Array< string | null > | null,
    owners?: Array< string | null > | null,
    orderIndex?: number | null,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    cognitoUsername?: string | null,
    role?: UserRole | null,
    appVersion?: string | null,
    activated?: string | null,
    blocked?: boolean | null,
    firstName?: string | null,
    emailSettings?:  {
      __typename: "UserEmailSettings",
      transactions?: boolean | null,
      offers?: boolean | null,
      loyaltyCard?: boolean | null,
      eventInvites?: boolean | null,
      marketing?: boolean | null,
    } | null,
    lastName?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    placesAddedToListCount?: number | null,
    bio?: string | null,
    preferredTitle?: string | null,
    country?:  {
      __typename: "Country",
      currencies?:  Array< {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null > | null,
      flag?: string | null,
      name?: string | null,
      alpha2Code?: string | null,
      alpha3Code?: string | null,
      callingCodes?: Array< string | null > | null,
      timezones?: Array< string | null > | null,
      region?: string | null,
    } | null,
    dob?: string | null,
    linkCardRewardClaimed?: string | null,
    email?: string | null,
    stripeConnectId?: string | null,
    stripeCustomerId?: string | null,
    gender?: string | null,
    phone?: string | null,
    city?: string | null,
    allowNotifications?: boolean | null,
    allowLocation?: boolean | null,
    allowPersonalisation?: boolean | null,
    allowCalendar?: boolean | null,
    profilePicture?: string | null,
    subscibedToEmails?: boolean | null,
    pushTokens?:  Array< {
      __typename: "PushToken",
      token?: string | null,
      device?: string | null,
      endpointArn?: string | null,
      parentApp?: string | null,
    } | null > | null,
    points?: number | null,
    accumulatedPoints?: number | null,
    isStudent?: boolean | null,
    lastLoggedIn?: string | null,
    locale?: string | null,
    isInfluencer?: string | null,
    summary?: string | null,
    categories?: Array< string | null > | null,
    social?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    termsSigned?: boolean | null,
    termsVersionSigned?: string | null,
    EPOSId?: number | null,
    appConfigs?: string | null,
    disabled?: boolean | null,
    username?: string | null,
    offers?:  {
      __typename: "ModelClaimedOfferConnection",
      items:  Array< {
        __typename: "ClaimedOffer",
        id: string,
        userId: string,
        offerId: string,
        fidelOfferId?: string | null,
        activated?: string | null,
        pointsCosts?: number | null,
        entityId: string,
        metadata?: string | null,
        publisherId: string,
        type?: string | null,
        ttl?: number | null,
        owners?: Array< string | null > | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedCards?:  Array< {
      __typename: "IUserCard",
      scheme?: string | null,
      accountId?: string | null,
      countryCode?: string | null,
      created?: string | null,
      expYear?: number | null,
      expDate?: string | null,
      live?: boolean | null,
      lastNumbers?: string | null,
      expMonth?: number | null,
      updated?: string | null,
      programId?: string | null,
      firstNumbers?: string | null,
      id?: string | null,
      type?: string | null,
    } | null > | null,
    checkIns?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    userEvents?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    loyaltyCards?:  {
      __typename: "ModelLoyaltyCardConnection",
      items:  Array< {
        __typename: "LoyaltyCard",
        id: string,
        userId: string,
        publisherId: string,
        businessTitle?: string | null,
        currentTier?: string | null,
        entityId?: string | null,
        visits?: number | null,
        businessAssignedTier?: string | null,
        transactions?: number | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        totalSpend?: number | null,
        pendingPoints?: number | null,
        totalPoints?: number | null,
        ownerType?: string | null,
        status?: string | null,
        userFullName?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        totalSpendablePoints?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tableBookings?:  {
      __typename: "ModelTableBookingRequestConnection",
      items:  Array< {
        __typename: "TableBookingRequest",
        id: string,
        userName?: string | null,
        userId: string,
        paid?: boolean | null,
        amountPaid?: number | null,
        eventId: string,
        status?: string | null,
        owners?: Array< string | null > | null,
        tableId: string,
        message?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    lists?:  {
      __typename: "ModelUserWishListLinkConnection",
      items:  Array< {
        __typename: "UserWishListLink",
        id: string,
        listId: string,
        userId: string,
        remminderSet?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscriptions?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    cognitoUsername?: string | null,
    role?: UserRole | null,
    appVersion?: string | null,
    activated?: string | null,
    blocked?: boolean | null,
    firstName?: string | null,
    emailSettings?:  {
      __typename: "UserEmailSettings",
      transactions?: boolean | null,
      offers?: boolean | null,
      loyaltyCard?: boolean | null,
      eventInvites?: boolean | null,
      marketing?: boolean | null,
    } | null,
    lastName?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    placesAddedToListCount?: number | null,
    bio?: string | null,
    preferredTitle?: string | null,
    country?:  {
      __typename: "Country",
      currencies?:  Array< {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null > | null,
      flag?: string | null,
      name?: string | null,
      alpha2Code?: string | null,
      alpha3Code?: string | null,
      callingCodes?: Array< string | null > | null,
      timezones?: Array< string | null > | null,
      region?: string | null,
    } | null,
    dob?: string | null,
    linkCardRewardClaimed?: string | null,
    email?: string | null,
    stripeConnectId?: string | null,
    stripeCustomerId?: string | null,
    gender?: string | null,
    phone?: string | null,
    city?: string | null,
    allowNotifications?: boolean | null,
    allowLocation?: boolean | null,
    allowPersonalisation?: boolean | null,
    allowCalendar?: boolean | null,
    profilePicture?: string | null,
    subscibedToEmails?: boolean | null,
    pushTokens?:  Array< {
      __typename: "PushToken",
      token?: string | null,
      device?: string | null,
      endpointArn?: string | null,
      parentApp?: string | null,
    } | null > | null,
    points?: number | null,
    accumulatedPoints?: number | null,
    isStudent?: boolean | null,
    lastLoggedIn?: string | null,
    locale?: string | null,
    isInfluencer?: string | null,
    summary?: string | null,
    categories?: Array< string | null > | null,
    social?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    termsSigned?: boolean | null,
    termsVersionSigned?: string | null,
    EPOSId?: number | null,
    appConfigs?: string | null,
    disabled?: boolean | null,
    username?: string | null,
    offers?:  {
      __typename: "ModelClaimedOfferConnection",
      items:  Array< {
        __typename: "ClaimedOffer",
        id: string,
        userId: string,
        offerId: string,
        fidelOfferId?: string | null,
        activated?: string | null,
        pointsCosts?: number | null,
        entityId: string,
        metadata?: string | null,
        publisherId: string,
        type?: string | null,
        ttl?: number | null,
        owners?: Array< string | null > | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedCards?:  Array< {
      __typename: "IUserCard",
      scheme?: string | null,
      accountId?: string | null,
      countryCode?: string | null,
      created?: string | null,
      expYear?: number | null,
      expDate?: string | null,
      live?: boolean | null,
      lastNumbers?: string | null,
      expMonth?: number | null,
      updated?: string | null,
      programId?: string | null,
      firstNumbers?: string | null,
      id?: string | null,
      type?: string | null,
    } | null > | null,
    checkIns?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    userEvents?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    loyaltyCards?:  {
      __typename: "ModelLoyaltyCardConnection",
      items:  Array< {
        __typename: "LoyaltyCard",
        id: string,
        userId: string,
        publisherId: string,
        businessTitle?: string | null,
        currentTier?: string | null,
        entityId?: string | null,
        visits?: number | null,
        businessAssignedTier?: string | null,
        transactions?: number | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        totalSpend?: number | null,
        pendingPoints?: number | null,
        totalPoints?: number | null,
        ownerType?: string | null,
        status?: string | null,
        userFullName?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        totalSpendablePoints?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tableBookings?:  {
      __typename: "ModelTableBookingRequestConnection",
      items:  Array< {
        __typename: "TableBookingRequest",
        id: string,
        userName?: string | null,
        userId: string,
        paid?: boolean | null,
        amountPaid?: number | null,
        eventId: string,
        status?: string | null,
        owners?: Array< string | null > | null,
        tableId: string,
        message?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    lists?:  {
      __typename: "ModelUserWishListLinkConnection",
      items:  Array< {
        __typename: "UserWishListLink",
        id: string,
        listId: string,
        userId: string,
        remminderSet?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscriptions?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    cognitoUsername?: string | null,
    role?: UserRole | null,
    appVersion?: string | null,
    activated?: string | null,
    blocked?: boolean | null,
    firstName?: string | null,
    emailSettings?:  {
      __typename: "UserEmailSettings",
      transactions?: boolean | null,
      offers?: boolean | null,
      loyaltyCard?: boolean | null,
      eventInvites?: boolean | null,
      marketing?: boolean | null,
    } | null,
    lastName?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    placesAddedToListCount?: number | null,
    bio?: string | null,
    preferredTitle?: string | null,
    country?:  {
      __typename: "Country",
      currencies?:  Array< {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null > | null,
      flag?: string | null,
      name?: string | null,
      alpha2Code?: string | null,
      alpha3Code?: string | null,
      callingCodes?: Array< string | null > | null,
      timezones?: Array< string | null > | null,
      region?: string | null,
    } | null,
    dob?: string | null,
    linkCardRewardClaimed?: string | null,
    email?: string | null,
    stripeConnectId?: string | null,
    stripeCustomerId?: string | null,
    gender?: string | null,
    phone?: string | null,
    city?: string | null,
    allowNotifications?: boolean | null,
    allowLocation?: boolean | null,
    allowPersonalisation?: boolean | null,
    allowCalendar?: boolean | null,
    profilePicture?: string | null,
    subscibedToEmails?: boolean | null,
    pushTokens?:  Array< {
      __typename: "PushToken",
      token?: string | null,
      device?: string | null,
      endpointArn?: string | null,
      parentApp?: string | null,
    } | null > | null,
    points?: number | null,
    accumulatedPoints?: number | null,
    isStudent?: boolean | null,
    lastLoggedIn?: string | null,
    locale?: string | null,
    isInfluencer?: string | null,
    summary?: string | null,
    categories?: Array< string | null > | null,
    social?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    termsSigned?: boolean | null,
    termsVersionSigned?: string | null,
    EPOSId?: number | null,
    appConfigs?: string | null,
    disabled?: boolean | null,
    username?: string | null,
    offers?:  {
      __typename: "ModelClaimedOfferConnection",
      items:  Array< {
        __typename: "ClaimedOffer",
        id: string,
        userId: string,
        offerId: string,
        fidelOfferId?: string | null,
        activated?: string | null,
        pointsCosts?: number | null,
        entityId: string,
        metadata?: string | null,
        publisherId: string,
        type?: string | null,
        ttl?: number | null,
        owners?: Array< string | null > | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedCards?:  Array< {
      __typename: "IUserCard",
      scheme?: string | null,
      accountId?: string | null,
      countryCode?: string | null,
      created?: string | null,
      expYear?: number | null,
      expDate?: string | null,
      live?: boolean | null,
      lastNumbers?: string | null,
      expMonth?: number | null,
      updated?: string | null,
      programId?: string | null,
      firstNumbers?: string | null,
      id?: string | null,
      type?: string | null,
    } | null > | null,
    checkIns?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    userEvents?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    loyaltyCards?:  {
      __typename: "ModelLoyaltyCardConnection",
      items:  Array< {
        __typename: "LoyaltyCard",
        id: string,
        userId: string,
        publisherId: string,
        businessTitle?: string | null,
        currentTier?: string | null,
        entityId?: string | null,
        visits?: number | null,
        businessAssignedTier?: string | null,
        transactions?: number | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        totalSpend?: number | null,
        pendingPoints?: number | null,
        totalPoints?: number | null,
        ownerType?: string | null,
        status?: string | null,
        userFullName?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        totalSpendablePoints?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tableBookings?:  {
      __typename: "ModelTableBookingRequestConnection",
      items:  Array< {
        __typename: "TableBookingRequest",
        id: string,
        userName?: string | null,
        userId: string,
        paid?: boolean | null,
        amountPaid?: number | null,
        eventId: string,
        status?: string | null,
        owners?: Array< string | null > | null,
        tableId: string,
        message?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    lists?:  {
      __typename: "ModelUserWishListLinkConnection",
      items:  Array< {
        __typename: "UserWishListLink",
        id: string,
        listId: string,
        userId: string,
        remminderSet?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscriptions?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateClaimedOfferMutationVariables = {
  input: CreateClaimedOfferInput,
  condition?: ModelClaimedOfferConditionInput | null,
};

export type CreateClaimedOfferMutation = {
  createClaimedOffer?:  {
    __typename: "ClaimedOffer",
    id: string,
    userId: string,
    offerId: string,
    fidelOfferId?: string | null,
    activated?: string | null,
    pointsCosts?: number | null,
    entityId: string,
    metadata?: string | null,
    publisherId: string,
    type?: string | null,
    ttl?: number | null,
    owners?: Array< string | null > | null,
    status: string,
    createdAt: number,
    updatedAt: number,
    offer?:  {
      __typename: "SpecialOffer",
      id: string,
      daysAvailable?: Array< number | null > | null,
      startTime?: string | null,
      price?: number | null,
      discount?: number | null,
      endTime?: string | null,
      endDate?: string | null,
      countryCode?: string | null,
      declineReason?: string | null,
      requiresBooking?: string | null,
      startDate?: string | null,
      maxTransactionAmount?: number | null,
      minTransactionAmount?: number | null,
      returnPeriod?: number | null,
      metaData?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      description?: string | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      title: string,
      images?: Array< string | null > | null,
      weeks?: number | null,
      entityId: string,
      publisherId: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      fidelId?: string | null,
      spendRequired?: number | null,
      delayMinutes?: number | null,
      delayHours?: number | null,
      currency?: string | null,
      isStudentDiscount?: boolean | null,
      city?: string | null,
      quantity?: number | null,
      status: string,
      loyaltyPointsRequired?: number | null,
      loyaltyPointsValue?: number | null,
      visitsRequired?: number | null,
      redeemCount?: number | null,
      claimCount?: number | null,
      dateAvailable?: string | null,
      allowanceLimit?: number | null,
      refreshDaysPeriod?: string | null,
      type?: string | null,
      rewardType?: string | null,
      subscribersOnly?: boolean | null,
      owner?: string | null,
      disclaimer?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      fidelOfferId?: string | null,
      fidelStartDate?: string | null,
      fidelEndDate?: string | null,
      fidelMinTransactionAmount?: number | null,
      fidelMaxTransactionAmount?: number | null,
      fidelReturnPeriod?: number | null,
      fidelDaysOfWeek?: Array< number | null > | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateClaimedOfferMutationVariables = {
  input: UpdateClaimedOfferInput,
  condition?: ModelClaimedOfferConditionInput | null,
};

export type UpdateClaimedOfferMutation = {
  updateClaimedOffer?:  {
    __typename: "ClaimedOffer",
    id: string,
    userId: string,
    offerId: string,
    fidelOfferId?: string | null,
    activated?: string | null,
    pointsCosts?: number | null,
    entityId: string,
    metadata?: string | null,
    publisherId: string,
    type?: string | null,
    ttl?: number | null,
    owners?: Array< string | null > | null,
    status: string,
    createdAt: number,
    updatedAt: number,
    offer?:  {
      __typename: "SpecialOffer",
      id: string,
      daysAvailable?: Array< number | null > | null,
      startTime?: string | null,
      price?: number | null,
      discount?: number | null,
      endTime?: string | null,
      endDate?: string | null,
      countryCode?: string | null,
      declineReason?: string | null,
      requiresBooking?: string | null,
      startDate?: string | null,
      maxTransactionAmount?: number | null,
      minTransactionAmount?: number | null,
      returnPeriod?: number | null,
      metaData?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      description?: string | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      title: string,
      images?: Array< string | null > | null,
      weeks?: number | null,
      entityId: string,
      publisherId: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      fidelId?: string | null,
      spendRequired?: number | null,
      delayMinutes?: number | null,
      delayHours?: number | null,
      currency?: string | null,
      isStudentDiscount?: boolean | null,
      city?: string | null,
      quantity?: number | null,
      status: string,
      loyaltyPointsRequired?: number | null,
      loyaltyPointsValue?: number | null,
      visitsRequired?: number | null,
      redeemCount?: number | null,
      claimCount?: number | null,
      dateAvailable?: string | null,
      allowanceLimit?: number | null,
      refreshDaysPeriod?: string | null,
      type?: string | null,
      rewardType?: string | null,
      subscribersOnly?: boolean | null,
      owner?: string | null,
      disclaimer?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      fidelOfferId?: string | null,
      fidelStartDate?: string | null,
      fidelEndDate?: string | null,
      fidelMinTransactionAmount?: number | null,
      fidelMaxTransactionAmount?: number | null,
      fidelReturnPeriod?: number | null,
      fidelDaysOfWeek?: Array< number | null > | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteClaimedOfferMutationVariables = {
  input: DeleteClaimedOfferInput,
  condition?: ModelClaimedOfferConditionInput | null,
};

export type DeleteClaimedOfferMutation = {
  deleteClaimedOffer?:  {
    __typename: "ClaimedOffer",
    id: string,
    userId: string,
    offerId: string,
    fidelOfferId?: string | null,
    activated?: string | null,
    pointsCosts?: number | null,
    entityId: string,
    metadata?: string | null,
    publisherId: string,
    type?: string | null,
    ttl?: number | null,
    owners?: Array< string | null > | null,
    status: string,
    createdAt: number,
    updatedAt: number,
    offer?:  {
      __typename: "SpecialOffer",
      id: string,
      daysAvailable?: Array< number | null > | null,
      startTime?: string | null,
      price?: number | null,
      discount?: number | null,
      endTime?: string | null,
      endDate?: string | null,
      countryCode?: string | null,
      declineReason?: string | null,
      requiresBooking?: string | null,
      startDate?: string | null,
      maxTransactionAmount?: number | null,
      minTransactionAmount?: number | null,
      returnPeriod?: number | null,
      metaData?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      description?: string | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      title: string,
      images?: Array< string | null > | null,
      weeks?: number | null,
      entityId: string,
      publisherId: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      fidelId?: string | null,
      spendRequired?: number | null,
      delayMinutes?: number | null,
      delayHours?: number | null,
      currency?: string | null,
      isStudentDiscount?: boolean | null,
      city?: string | null,
      quantity?: number | null,
      status: string,
      loyaltyPointsRequired?: number | null,
      loyaltyPointsValue?: number | null,
      visitsRequired?: number | null,
      redeemCount?: number | null,
      claimCount?: number | null,
      dateAvailable?: string | null,
      allowanceLimit?: number | null,
      refreshDaysPeriod?: string | null,
      type?: string | null,
      rewardType?: string | null,
      subscribersOnly?: boolean | null,
      owner?: string | null,
      disclaimer?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      fidelOfferId?: string | null,
      fidelStartDate?: string | null,
      fidelEndDate?: string | null,
      fidelMinTransactionAmount?: number | null,
      fidelMaxTransactionAmount?: number | null,
      fidelReturnPeriod?: number | null,
      fidelDaysOfWeek?: Array< number | null > | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateUserSavedItemMutationVariables = {
  input: CreateUserSavedItemInput,
  condition?: ModelUserSavedItemConditionInput | null,
};

export type CreateUserSavedItemMutation = {
  createUserSavedItem?:  {
    __typename: "UserSavedItem",
    id: string,
    type: string,
    itemId: string,
    title?: string | null,
    image?: string | null,
    authorId?: string | null,
    author?: string | null,
    userId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserSavedItemMutationVariables = {
  input: UpdateUserSavedItemInput,
  condition?: ModelUserSavedItemConditionInput | null,
};

export type UpdateUserSavedItemMutation = {
  updateUserSavedItem?:  {
    __typename: "UserSavedItem",
    id: string,
    type: string,
    itemId: string,
    title?: string | null,
    image?: string | null,
    authorId?: string | null,
    author?: string | null,
    userId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserSavedItemMutationVariables = {
  input: DeleteUserSavedItemInput,
  condition?: ModelUserSavedItemConditionInput | null,
};

export type DeleteUserSavedItemMutation = {
  deleteUserSavedItem?:  {
    __typename: "UserSavedItem",
    id: string,
    type: string,
    itemId: string,
    title?: string | null,
    image?: string | null,
    authorId?: string | null,
    author?: string | null,
    userId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSubscriberMutationVariables = {
  input: CreateSubscriberInput,
  condition?: ModelSubscriberConditionInput | null,
};

export type CreateSubscriberMutation = {
  createSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    userId: string,
    entityId: string,
    publisherId: string,
    userFullName?: string | null,
    counters?:  {
      __typename: "FollowerCounters",
      bookings?: number | null,
      offersRedeemed?: number | null,
      checkins?: number | null,
    } | null,
    subscriptionArn?: string | null,
    subscriptionArns?: Array< string | null > | null,
    notificationsEnabled?: boolean | null,
    credit?: number | null,
    loyaltyPoints?: number | null,
    loyaltyStatus?: string | null,
    EPOSId?: string | null,
    type?: string | null,
    status: string,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSubscriberMutationVariables = {
  input: UpdateSubscriberInput,
  condition?: ModelSubscriberConditionInput | null,
};

export type UpdateSubscriberMutation = {
  updateSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    userId: string,
    entityId: string,
    publisherId: string,
    userFullName?: string | null,
    counters?:  {
      __typename: "FollowerCounters",
      bookings?: number | null,
      offersRedeemed?: number | null,
      checkins?: number | null,
    } | null,
    subscriptionArn?: string | null,
    subscriptionArns?: Array< string | null > | null,
    notificationsEnabled?: boolean | null,
    credit?: number | null,
    loyaltyPoints?: number | null,
    loyaltyStatus?: string | null,
    EPOSId?: string | null,
    type?: string | null,
    status: string,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSubscriberMutationVariables = {
  input: DeleteSubscriberInput,
  condition?: ModelSubscriberConditionInput | null,
};

export type DeleteSubscriberMutation = {
  deleteSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    userId: string,
    entityId: string,
    publisherId: string,
    userFullName?: string | null,
    counters?:  {
      __typename: "FollowerCounters",
      bookings?: number | null,
      offersRedeemed?: number | null,
      checkins?: number | null,
    } | null,
    subscriptionArn?: string | null,
    subscriptionArns?: Array< string | null > | null,
    notificationsEnabled?: boolean | null,
    credit?: number | null,
    loyaltyPoints?: number | null,
    loyaltyStatus?: string | null,
    EPOSId?: string | null,
    type?: string | null,
    status: string,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateTrackingActivityStatMutationVariables = {
  input: CreateTrackingActivityStatInput,
  condition?: ModelTrackingActivityStatConditionInput | null,
};

export type CreateTrackingActivityStatMutation = {
  createTrackingActivityStat?:  {
    __typename: "TrackingActivityStat",
    id: string,
    action?: string | null,
    itemType?: string | null,
    itemId?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    gender?: string | null,
    city?: string | null,
    owners: Array< string | null >,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateCheckInMutationVariables = {
  input: CreateCheckInInput,
  condition?: ModelCheckInConditionInput | null,
};

export type CreateCheckInMutation = {
  createCheckIn?:  {
    __typename: "CheckIn",
    id: string,
    userId: string,
    entityId: string,
    fullName?: string | null,
    phone?: string | null,
    partySize?: number | null,
    gender?: string | null,
    dob?: string | null,
    owners?: Array< string | null > | null,
    age?: number | null,
    email?: string | null,
    ttl?: number | null,
    status?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    updatedAt?: number | null,
    createdAt: number,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateFeedbackMutationVariables = {
  input: CreateFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type CreateFeedbackMutation = {
  createFeedback?:  {
    __typename: "Feedback",
    id: string,
    userId?: string | null,
    ttl?: number | null,
    email?: string | null,
    name?: string | null,
    feedback?: string | null,
    createdAt: number,
    updatedAt: number,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type SendSNSToTopicQueryVariables = {
  payload: PushPayload,
  env?: string | null,
};

export type SendSNSToTopicQuery = {
  sendSNSToTopic?: string | null,
};

export type UnsubscribeFromTopicQueryVariables = {
  endpointArns?: Array< string | null > | null,
  env?: string | null,
};

export type UnsubscribeFromTopicQuery = {
  unsubscribeFromTopic?: string | null,
};

export type ToggleUserEventSubscriptionQueryVariables = {
  input?: string | null,
};

export type ToggleUserEventSubscriptionQuery = {
  toggleUserEventSubscription?: string | null,
};

export type SubscribeToFromQueryVariables = {
  parentId?: string | null,
  subscribe?: boolean | null,
  type?: string | null,
  userId?: string | null,
  cognitoUsername?: string | null,
  userFullName?: string | null,
  tokens?: Array< UserPushTokenInput | null > | null,
  onlySNS?: boolean | null,
  env?: string | null,
};

export type SubscribeToFromQuery = {
  subscribeToFrom?: string | null,
};

export type AddUserPointsQueryVariables = {
  input?: UserPointsInput | null,
  env?: string | null,
};

export type AddUserPointsQuery = {
  addUserPoints?: string | null,
};

export type AddUserToEventQueryVariables = {
  userId?: string | null,
  eventId?: string | null,
  attending?: boolean | null,
  tokens?: Array< UserPushTokenInput | null > | null,
  type?: string | null,
  cognitoUsername?: string | null,
  publisherCognitoUsername?: string | null,
  eventDate?: number | null,
  status?: string | null,
  env?: string | null,
  repeatEventOccurencId?: string | null,
};

export type AddUserToEventQuery = {
  addUserToEvent?: string | null,
};

export type AddLoyaltyPointsQueryVariables = {
  parentId?: string | null,
  type: string,
  userId: string,
  userFullName?: string | null,
  cognitoUsername?: string | null,
  points: number,
  env?: string | null,
};

export type AddLoyaltyPointsQuery = {
  addLoyaltyPoints?:  {
    __typename: "AddloyaltyPointsResponse",
    code?: string | null,
    data?:  {
      __typename: "AddloyaltyPointsResponsePayload",
      id?: string | null,
      userId?: string | null,
      isNewSubscriber?: boolean | null,
      reward?:  {
        __typename: "AddloyaltyPointsResponseRewards",
        title?: string | null,
        description?: string | null,
        rewardType?: string | null,
        loyaltyPointsRequired?: number | null,
      } | null,
      publisher?:  {
        __typename: "AddloyaltyPointsResponsePublisher",
        title?: string | null,
        logo?: string | null,
        id?: string | null,
      } | null,
      event?:  {
        __typename: "AddloyaltyPointsResponseEvent",
        id?: string | null,
        publisherId?: string | null,
        type?: string | null,
        images?: Array< string | null > | null,
        title?: string | null,
        entity?: string | null,
        city?: string | null,
      } | null,
      entity?:  {
        __typename: "AddloyaltyPointsResponseEntity",
        id?: string | null,
        publisherId?: string | null,
        logo?: string | null,
        images?: Array< string | null > | null,
        title?: string | null,
        city?: string | null,
      } | null,
      totalPoints?: number | null,
      createdAt?: number | null,
      updatedAt?: number | null,
    } | null,
  } | null,
};

export type DeleteTopicQueryVariables = {
  type: string,
  id: string,
  env?: string | null,
};

export type DeleteTopicQuery = {
  deleteTopic?: string | null,
};

export type CreateNewSNSApplicationEndpointQueryVariables = {
  deviceType: string,
  deviceToken: string,
  env: string,
  deviceName?: string | null,
};

export type CreateNewSNSApplicationEndpointQuery = {
  createNewSNSApplicationEndpoint?: string | null,
};

export type UpdatePushTokenQueryVariables = {
  endpointArn: string,
  deviceToken: string,
  enabled: boolean,
  env?: string | null,
};

export type UpdatePushTokenQuery = {
  updatePushToken?: string | null,
};

export type CitiesByLocationQueryVariables = {
  input?: SearchCitiesInput | null,
  env?: string | null,
};

export type CitiesByLocationQuery = {
  citiesByLocation?:  {
    __typename: "CityConnection",
    items?:  Array< {
      __typename: "City",
      id: string,
      title: string,
      venueCount?: number | null,
      areas?:  Array< {
        __typename: "Area",
        title?: string | null,
        parentArea?: string | null,
        images?: Array< string | null > | null,
      } | null > | null,
      description?: string | null,
      country: string,
      continent?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      timezoneOffset?: number | null,
      currency?: string | null,
      images?: Array< string | null > | null,
      createdAt: number,
      updatedAt: number,
      config?:  {
        __typename: "CityConfig",
        events?: boolean | null,
        places?: boolean | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchElasticEventsQueryVariables = {
  input?: SearchEventsInput | null,
  env?: string | null,
};

export type SearchElasticEventsQuery = {
  searchElasticEvents?:  {
    __typename: "EventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchElasticSpecialOffersQueryVariables = {
  input?: SearchElasticOffersInput | null,
  env?: string | null,
};

export type SearchElasticSpecialOffersQuery = {
  searchElasticSpecialOffers?:  {
    __typename: "SpecialOffersConnection",
    items?:  Array< {
      __typename: "SpecialOffer",
      id: string,
      daysAvailable?: Array< number | null > | null,
      startTime?: string | null,
      price?: number | null,
      discount?: number | null,
      endTime?: string | null,
      endDate?: string | null,
      countryCode?: string | null,
      declineReason?: string | null,
      requiresBooking?: string | null,
      startDate?: string | null,
      maxTransactionAmount?: number | null,
      minTransactionAmount?: number | null,
      returnPeriod?: number | null,
      metaData?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      description?: string | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      title: string,
      images?: Array< string | null > | null,
      weeks?: number | null,
      entityId: string,
      publisherId: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      fidelId?: string | null,
      spendRequired?: number | null,
      delayMinutes?: number | null,
      delayHours?: number | null,
      currency?: string | null,
      isStudentDiscount?: boolean | null,
      city?: string | null,
      quantity?: number | null,
      status: string,
      loyaltyPointsRequired?: number | null,
      loyaltyPointsValue?: number | null,
      visitsRequired?: number | null,
      redeemCount?: number | null,
      claimCount?: number | null,
      dateAvailable?: string | null,
      allowanceLimit?: number | null,
      refreshDaysPeriod?: string | null,
      type?: string | null,
      rewardType?: string | null,
      subscribersOnly?: boolean | null,
      owner?: string | null,
      disclaimer?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      fidelOfferId?: string | null,
      fidelStartDate?: string | null,
      fidelEndDate?: string | null,
      fidelMinTransactionAmount?: number | null,
      fidelMaxTransactionAmount?: number | null,
      fidelReturnPeriod?: number | null,
      fidelDaysOfWeek?: Array< number | null > | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchElasticFeaturedPlacesQueryVariables = {
  input?: SearchFeaturedPlacesInput | null,
  env?: string | null,
};

export type SearchElasticFeaturedPlacesQuery = {
  searchElasticFeaturedPlaces?:  {
    __typename: "FeaturedEntityConnection",
    items?:  Array< {
      __typename: "FeaturedPlace",
      id: string,
      entityId: string,
      eventId?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      area?: string | null,
      title?: string | null,
      description?: string | null,
      pinToTop?: boolean | null,
      city?: string | null,
      featuredUntil?: string | null,
      coverage?: string | null,
      country?: string | null,
      days_selected?: Array< number | null > | null,
      startDate?: string | null,
      budget?: number | null,
      budget_left?: number | null,
      paid?: number | null,
      viewCount?: number | null,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      status?: string | null,
      ttl?: number | null,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchElasticRepeatedEventsQueryVariables = {
  input?: SearchRepeatEventsInput | null,
  env?: string | null,
};

export type SearchElasticRepeatedEventsQuery = {
  searchElasticRepeatedEvents?:  {
    __typename: "RepeatEventConnection",
    items?:  Array< {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchElasticPlacesQueryVariables = {
  input?: SearchPlacesInput | null,
  env?: string | null,
};

export type SearchElasticPlacesQuery = {
  searchElasticPlaces?:  {
    __typename: "EntityConnection",
    items?:  Array< {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchElasticartistEventLocationQueryVariables = {
  filter?: SearchElasticArtistEventPositionFilter | null,
  km?: number | null,
  limit?: number | null,
  nextToken?: string | null,
  env?: string | null,
};

export type SearchElasticartistEventLocationQuery = {
  searchElasticartistEventLocation?:  {
    __typename: "ArtistEventPositionSearchConnection",
    items?:  Array< {
      __typename: "ArtistEventPosition",
      id: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      eventDate?: number | null,
      city?: string | null,
      ttl?: number | null,
      createdAt: number,
      updatedAt: number,
      artist?:  {
        __typename: "Artist",
        id: string,
        title: string,
        country?: string | null,
        spotifyAppUri?: string | null,
        videoUrls?: Array< string | null > | null,
        nickname?: string | null,
        rating?: number | null,
        pingGigsPlayed?: number | null,
        numReviews?: number | null,
        logo?: string | null,
        soundcloudAppUri?: string | null,
        appleMusicAppUri?: string | null,
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        stripeConnectId?: string | null,
        amazonMusicUrl?: string | null,
        stripeCustomerId?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
        description?: string | null,
        genres?: Array< string | null > | null,
        images?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchElasticInfluencersQueryVariables = {
  input?: SearchElasticInfluencersInput | null,
  env?: string | null,
};

export type SearchElasticInfluencersQuery = {
  searchElasticInfluencers?:  {
    __typename: "UserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SearchElasticInstagramsQueryVariables = {
  input?: SearchElasticInstagramsInput | null,
  env?: string | null,
};

export type SearchElasticInstagramsQuery = {
  searchElasticInstagrams?:  {
    __typename: "UserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type IncrementCountQueryVariables = {
  input?: CountInput | null,
};

export type IncrementCountQuery = {
  incrementCount: string,
};

export type ActivatePublisherProfileQueryVariables = {
  input?: ActivationPayload | null,
  email?: string | null,
  name?: string | null,
  entityIds?: Array< string | null > | null,
  env?: string | null,
};

export type ActivatePublisherProfileQuery = {
  activatePublisherProfile?: string | null,
};

export type StripeCreateCardTokenQueryVariables = {
  card?: StripeCard | null,
  id?: string | null,
  name?: string | null,
  email?: string | null,
  env?: string | null,
};

export type StripeCreateCardTokenQuery = {
  stripeCreateCardToken?:  {
    __typename: "LambdaResponse",
    code?: string | null,
    message?: string | null,
    body?: string | null,
  } | null,
};

export type EposCustomerAPIQueryVariables = {
  requestType?: string | null,
  data?: string | null,
};

export type EposCustomerAPIQuery = {
  eposCustomerAPI?: string | null,
};

export type EposCustomerTypeAPIQueryVariables = {
  requestType?: string | null,
  data?: string | null,
};

export type EposCustomerTypeAPIQuery = {
  eposCustomerTypeAPI?: string | null,
};

export type SevenRoomsAvailabilityQueryVariables = {
  input: SevenRoomsAvailabilityInput,
};

export type SevenRoomsAvailabilityQuery = {
  sevenRoomsAvailability?: string | null,
};

export type SevenRoomsCreateBookingQueryVariables = {
  input: SevenRoomsBookingInput,
};

export type SevenRoomsCreateBookingQuery = {
  sevenRoomsCreateBooking?: string | null,
};

export type StripeCreateConnectAccountQueryVariables = {
  arguments?: string | null,
};

export type StripeCreateConnectAccountQuery = {
  stripeCreateConnectAccount?: string | null,
};

export type StripeGetConnectSignupUrlQueryVariables = {
  connectAccountId?: string | null,
  env?: string | null,
};

export type StripeGetConnectSignupUrlQuery = {
  stripeGetConnectSignupUrl?: string | null,
};

export type StripeGetConnectAccountQueryVariables = {
  arguments?: string | null,
};

export type StripeGetConnectAccountQuery = {
  stripeGetConnectAccount?: string | null,
};

export type StripeCreatePaymentIntentQueryVariables = {
  arguments?: string | null,
};

export type StripeCreatePaymentIntentQuery = {
  stripeCreatePaymentIntent?: string | null,
};

export type StripeConfirmCardPaymentQueryVariables = {
  arguments?: string | null,
};

export type StripeConfirmCardPaymentQuery = {
  stripeConfirmCardPayment?: string | null,
};

export type CreateEventTicketQueryVariables = {
  arguments?: string | null,
};

export type CreateEventTicketQuery = {
  createEventTicket?: string | null,
};

export type StripeRefundTicketQueryVariables = {
  arguments?: string | null,
};

export type StripeRefundTicketQuery = {
  stripeRefundTicket?: string | null,
};

export type UpdateEventTicketCounterQueryVariables = {
  eventId?: string | null,
  increment?: number | null,
  env?: string | null,
};

export type UpdateEventTicketCounterQuery = {
  updateEventTicketCounter?: string | null,
};

export type DeleteHihglightVideosQueryVariables = {
  url?: string | null,
  env?: string | null,
};

export type DeleteHihglightVideosQuery = {
  deleteHihglightVideos?: string | null,
};

export type AdminProspectingEmailFunctionQueryVariables = {
  email?: string | null,
  entity?: string | null,
  env?: string | null,
};

export type AdminProspectingEmailFunctionQuery = {
  adminProspectingEmailFunction?: string | null,
};

export type UpdateFollowerAggregationQueryVariables = {
  subscriptionId?: string | null,
  increment?: number | null,
  type?: string | null,
  env?: string | null,
};

export type UpdateFollowerAggregationQuery = {
  updateFollowerAggregation?: string | null,
};

export type BroadcastNotficationQueryVariables = {
  notificationData?: string | null,
};

export type BroadcastNotficationQuery = {
  broadcastNotfication?: string | null,
};

export type SendEventInviteQueryVariables = {
  arguments?: string | null,
};

export type SendEventInviteQuery = {
  sendEventInvite?: string | null,
};

export type ActivateUserAccountQueryVariables = {
  arguments?: string | null,
};

export type ActivateUserAccountQuery = {
  activateUserAccount?: string | null,
};

export type DeletePublisherAccountQueryVariables = {
  arguments?: string | null,
};

export type DeletePublisherAccountQuery = {
  deletePublisherAccount?: string | null,
};

export type SendUserGiftQueryVariables = {
  giftData?: string | null,
};

export type SendUserGiftQuery = {
  sendUserGift?: string | null,
};

export type StripeSendInvoiceQueryVariables = {
  arguments?: string | null,
};

export type StripeSendInvoiceQuery = {
  stripeSendInvoice?: string | null,
};

export type SendTicketEmailQueryVariables = {
  arguments?: string | null,
};

export type SendTicketEmailQuery = {
  sendTicketEmail?: string | null,
};

export type EventCrawlerQueryVariables = {
  arguments?: string | null,
};

export type EventCrawlerQuery = {
  eventCrawler?: string | null,
};

export type SendContactFormEmailQueryVariables = {
  arguments?: string | null,
};

export type SendContactFormEmailQuery = {
  sendContactFormEmail?: string | null,
};

export type DeleteUserAccountQueryVariables = {
  arguments?: string | null,
};

export type DeleteUserAccountQuery = {
  deleteUserAccount?: string | null,
};

export type EmailerScraperQueryVariables = {
  website?: string | null,
};

export type EmailerScraperQuery = {
  emailerScraper?: Array< string | null > | null,
};

export type StripeCreateCustomerQueryVariables = {
  arguments?: string | null,
};

export type StripeCreateCustomerQuery = {
  stripeCreateCustomer?: string | null,
};

export type StripeRemoveCustomerQueryVariables = {
  arguments?: string | null,
};

export type StripeRemoveCustomerQuery = {
  stripeRemoveCustomer?: string | null,
};

export type StripeCreateCustomerPaymentIntentQueryVariables = {
  input?: string | null,
};

export type StripeCreateCustomerPaymentIntentQuery = {
  StripeCreateCustomerPaymentIntent?: string | null,
};

export type StripeListCustomerPaymentMethodsQueryVariables = {
  input?: string | null,
};

export type StripeListCustomerPaymentMethodsQuery = {
  stripeListCustomerPaymentMethods?: string | null,
};

export type StripeRemovePaymentMethodQueryVariables = {
  paymentMethodId?: string | null,
  env?: string | null,
};

export type StripeRemovePaymentMethodQuery = {
  stripeRemovePaymentMethod?: string | null,
};

export type UpdateCounterQueryVariables = {
  id?: string | null,
  increment?: number | null,
  env?: string | null,
};

export type UpdateCounterQuery = {
  updateCounter?: string | null,
};

export type AddUserToEntityListOwnersQueryVariables = {
  id?: string | null,
  code?: string | null,
  userId?: string | null,
  cognitoUsername?: string | null,
  placeFirst?: boolean | null,
  env?: string | null,
};

export type AddUserToEntityListOwnersQuery = {
  addUserToEntityListOwners?: string | null,
};

export type DeleteSharedUserListQueryVariables = {
  id?: string | null,
  userId?: string | null,
  cognitoUsername?: string | null,
  env?: string | null,
};

export type DeleteSharedUserListQuery = {
  deleteSharedUserList?: string | null,
};

export type SendEmailsWithTemplateQueryVariables = {
  template?: string | null,
  subject?: string | null,
  emails?: Array< EmailVenueName | null > | null,
  sender?: string | null,
  env?: string | null,
};

export type SendEmailsWithTemplateQuery = {
  sendEmailsWithTemplate?: string | null,
};

export type StripeSubscriptionFunctionQueryVariables = {
  input?: string | null,
};

export type StripeSubscriptionFunctionQuery = {
  stripeSubscriptionFunction?: string | null,
};

export type StripeListAllProductsQueryVariables = {
  ids?: Array< string | null > | null,
  env?: string | null,
};

export type StripeListAllProductsQuery = {
  stripeListAllProducts?: string | null,
};

export type FidelBrandFunctionQueryVariables = {
  input?: string | null,
};

export type FidelBrandFunctionQuery = {
  FidelBrandFunction?: string | null,
};

export type FideLocationFunctionQueryVariables = {
  input?: string | null,
};

export type FideLocationFunctionQuery = {
  fideLocationFunction?: string | null,
};

export type ClaimLoyaltyRewardQueryVariables = {
  input?: string | null,
};

export type ClaimLoyaltyRewardQuery = {
  claimLoyaltyReward?: string | null,
};

export type ToggleLoyaltyCardSNSQueryVariables = {
  input?: string | null,
};

export type ToggleLoyaltyCardSNSQuery = {
  toggleLoyaltyCardSNS?: string | null,
};

export type FidelRemoveUserCardQueryVariables = {
  input?: string | null,
};

export type FidelRemoveUserCardQuery = {
  fidelRemoveUserCard?: string | null,
};

export type GooglePlacesSearchQueryVariables = {
  query?: string | null,
};

export type GooglePlacesSearchQuery = {
  googlePlacesSearch?: string | null,
};

export type AddTotaskSchedulerQueryVariables = {
  input?: string | null,
};

export type AddTotaskSchedulerQuery = {
  addTotaskScheduler?: string | null,
};

export type FidelBrandUserQueryVariables = {
  input?: string | null,
};

export type FidelBrandUserQuery = {
  fidelBrandUser?: string | null,
};

export type FidelOfferFunctionQueryVariables = {
  input?: string | null,
};

export type FidelOfferFunctionQuery = {
  FidelOfferFunction?: string | null,
};

export type AddViewCountQueryVariables = {
  input?: string | null,
};

export type AddViewCountQuery = {
  addViewCount?: string | null,
};

export type SubscribeToEmailDatabaseQueryVariables = {
  input?: string | null,
};

export type SubscribeToEmailDatabaseQuery = {
  subscribeToEmailDatabase?: string | null,
};

export type GetUserInteractionQueryVariables = {
  id: string,
};

export type GetUserInteractionQuery = {
  getUserInteraction?:  {
    __typename: "UserInteraction",
    id: string,
    type?: string | null,
    itemId?: string | null,
    categories?: Array< string | null > | null,
    itemType?: string | null,
    city?: string | null,
    itemTitle?: string | null,
    userId?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListUserInteractionsQueryVariables = {
  filter?: ModelUserInteractionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserInteractionsQuery = {
  listUserInteractions?:  {
    __typename: "ModelUserInteractionConnection",
    items:  Array< {
      __typename: "UserInteraction",
      id: string,
      type?: string | null,
      itemId?: string | null,
      categories?: Array< string | null > | null,
      itemType?: string | null,
      city?: string | null,
      itemTitle?: string | null,
      userId?: string | null,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTrackingActivityStatQueryVariables = {
  id: string,
};

export type GetTrackingActivityStatQuery = {
  getTrackingActivityStat?:  {
    __typename: "TrackingActivityStat",
    id: string,
    action?: string | null,
    itemType?: string | null,
    itemId?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    gender?: string | null,
    city?: string | null,
    owners: Array< string | null >,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListTrackingActivityStatsQueryVariables = {
  filter?: ModelTrackingActivityStatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTrackingActivityStatsQuery = {
  listTrackingActivityStats?:  {
    __typename: "ModelTrackingActivityStatConnection",
    items:  Array< {
      __typename: "TrackingActivityStat",
      id: string,
      action?: string | null,
      itemType?: string | null,
      itemId?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      gender?: string | null,
      city?: string | null,
      owners: Array< string | null >,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConvoLinkQueryVariables = {
  id: string,
};

export type GetConvoLinkQuery = {
  getConvoLink?:  {
    __typename: "ConvoLink",
    id: string,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
    owners: Array< string | null >,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        messageConversationId: string,
        content: string,
        contentType?: string | null,
        author: string,
        entity: string,
        receiver: string,
        owners: Array< string | null >,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisherId: string,
    entityId: string,
    userId: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListConvoLinksQueryVariables = {
  filter?: ModelConvoLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConvoLinksQuery = {
  listConvoLinks?:  {
    __typename: "ModelConvoLinkConnection",
    items:  Array< {
      __typename: "ConvoLink",
      id: string,
      status?: string | null,
      createdAt: number,
      updatedAt: number,
      owners: Array< string | null >,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      publisherId: string,
      entityId: string,
      userId: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    messageConversationId: string,
    conversation?:  {
      __typename: "ConvoLink",
      id: string,
      status?: string | null,
      createdAt: number,
      updatedAt: number,
      owners: Array< string | null >,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      publisherId: string,
      entityId: string,
      userId: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null,
    content: string,
    contentType?: string | null,
    author: string,
    entity: string,
    receiver: string,
    owners: Array< string | null >,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      messageConversationId: string,
      conversation?:  {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null,
      content: string,
      contentType?: string | null,
      author: string,
      entity: string,
      receiver: string,
      owners: Array< string | null >,
      status?: string | null,
      createdAt: number,
      updatedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCardTransactionsQueryVariables = {
  id: string,
};

export type GetCardTransactionsQuery = {
  getCardTransactions?:  {
    __typename: "CardTransactions",
    currency?: string | null,
    auth?: boolean | null,
    type?: string | null,
    programId?: string | null,
    id?: string | null,
    accountId?: string | null,
    userEntityId?: string | null,
    userPublisherId?: string | null,
    userId?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    entityId?: string | null,
    publisherId?: string | null,
    created?: string | null,
    authCode?: string | null,
    updated?: string | null,
    amount?: number | null,
    cleared?: boolean | null,
    visits?: number | null,
    wallet?: string | null,
    offer?: string | null,
    ttl?: number | null,
    datetime?: string | null,
    card?:  {
      __typename: "TransactionCard",
      id?: string | null,
      firstNumbers?: string | null,
      lastNumbers?: string | null,
      scheme?: string | null,
    } | null,
    location?:  {
      __typename: "FidelLocation",
      address?: string | null,
      city?: string | null,
      countryCode?: string | null,
      id?: string | null,
      geolocation?: string | null,
      postcode?: string | null,
      timezone?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
    } | null,
    brand?:  {
      __typename: "CardBrandData",
      id?: string | null,
      name?: string | null,
      logoURL?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
    } | null,
    identifiers?:  {
      __typename: "FidelTransactionIdentifiers",
      amexApprovalCode?: string | null,
      mastercardAuthCode?: string | null,
      mastercardRefNumber?: string | null,
      mastercardTransactionSequenceNumber?: string | null,
      MID?: string | null,
      visaAuthCode?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListCardTransactionssQueryVariables = {
  filter?: ModelCardTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCardTransactionssQuery = {
  listCardTransactionss?:  {
    __typename: "ModelCardTransactionsConnection",
    items:  Array< {
      __typename: "CardTransactions",
      currency?: string | null,
      auth?: boolean | null,
      type?: string | null,
      programId?: string | null,
      id?: string | null,
      accountId?: string | null,
      userEntityId?: string | null,
      userPublisherId?: string | null,
      userId?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      entityId?: string | null,
      publisherId?: string | null,
      created?: string | null,
      authCode?: string | null,
      updated?: string | null,
      amount?: number | null,
      cleared?: boolean | null,
      visits?: number | null,
      wallet?: string | null,
      offer?: string | null,
      ttl?: number | null,
      datetime?: string | null,
      card?:  {
        __typename: "TransactionCard",
        id?: string | null,
        firstNumbers?: string | null,
        lastNumbers?: string | null,
        scheme?: string | null,
      } | null,
      location?:  {
        __typename: "FidelLocation",
        address?: string | null,
        city?: string | null,
        countryCode?: string | null,
        id?: string | null,
        geolocation?: string | null,
        postcode?: string | null,
        timezone?: string | null,
      } | null,
      brand?:  {
        __typename: "CardBrandData",
        id?: string | null,
        name?: string | null,
        logoURL?: string | null,
      } | null,
      identifiers?:  {
        __typename: "FidelTransactionIdentifiers",
        amexApprovalCode?: string | null,
        mastercardAuthCode?: string | null,
        mastercardRefNumber?: string | null,
        mastercardTransactionSequenceNumber?: string | null,
        MID?: string | null,
        visaAuthCode?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLoyaltyUserCountersQueryVariables = {
  id: string,
};

export type GetLoyaltyUserCountersQuery = {
  getLoyaltyUserCounters?:  {
    __typename: "LoyaltyUserCounters",
    id: string,
    date?: string | null,
    publisherId: string,
    entityId: string,
    userId: string,
    userDob?: string | null,
    userAge?: number | null,
    visits?: number | null,
    idTypeKey?: string | null,
    userGender?: string | null,
    spend?: number | null,
    ttl?: number | null,
    period?: string | null,
    owners?: Array< string | null > | null,
    owner?: string | null,
    currency?: string | null,
    transactions?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListLoyaltyUserCounterssQueryVariables = {
  filter?: ModelLoyaltyUserCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLoyaltyUserCounterssQuery = {
  listLoyaltyUserCounterss?:  {
    __typename: "ModelLoyaltyUserCountersConnection",
    items:  Array< {
      __typename: "LoyaltyUserCounters",
      id: string,
      date?: string | null,
      publisherId: string,
      entityId: string,
      userId: string,
      userDob?: string | null,
      userAge?: number | null,
      visits?: number | null,
      idTypeKey?: string | null,
      userGender?: string | null,
      spend?: number | null,
      ttl?: number | null,
      period?: string | null,
      owners?: Array< string | null > | null,
      owner?: string | null,
      currency?: string | null,
      transactions?: number | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLoyaltyEntityCountersQueryVariables = {
  id: string,
};

export type GetLoyaltyEntityCountersQuery = {
  getLoyaltyEntityCounters?:  {
    __typename: "LoyaltyEntityCounters",
    id: string,
    date?: string | null,
    publisherId: string,
    entityId: string,
    spend?: number | null,
    ttl?: number | null,
    idTypeKey?: string | null,
    genderCount?:  {
      __typename: "GenderCount",
      m?: number | null,
      f?: number | null,
      nb?: number | null,
      t?: number | null,
      o?: number | null,
    } | null,
    ageCount?:  {
      __typename: "AgeCount",
      below18?: number | null,
      age18To25?: number | null,
      age26To30?: number | null,
      age31To35?: number | null,
      age36To40?: number | null,
      age41To45?: number | null,
      Over45?: number | null,
    } | null,
    visits?: number | null,
    currency?: string | null,
    transactions?: number | null,
    paid?: string | null,
    invoice?: string | null,
    owners?: Array< string | null > | null,
    owner?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListLoyaltyEntityCounterssQueryVariables = {
  filter?: ModelLoyaltyEntityCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLoyaltyEntityCounterssQuery = {
  listLoyaltyEntityCounterss?:  {
    __typename: "ModelLoyaltyEntityCountersConnection",
    items:  Array< {
      __typename: "LoyaltyEntityCounters",
      id: string,
      date?: string | null,
      publisherId: string,
      entityId: string,
      spend?: number | null,
      ttl?: number | null,
      idTypeKey?: string | null,
      genderCount?:  {
        __typename: "GenderCount",
        m?: number | null,
        f?: number | null,
        nb?: number | null,
        t?: number | null,
        o?: number | null,
      } | null,
      ageCount?:  {
        __typename: "AgeCount",
        below18?: number | null,
        age18To25?: number | null,
        age26To30?: number | null,
        age31To35?: number | null,
        age36To40?: number | null,
        age41To45?: number | null,
        Over45?: number | null,
      } | null,
      visits?: number | null,
      currency?: string | null,
      transactions?: number | null,
      paid?: string | null,
      invoice?: string | null,
      owners?: Array< string | null > | null,
      owner?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetScheduleQueueQueryVariables = {
  id: string,
};

export type GetScheduleQueueQuery = {
  getScheduleQueue?:  {
    __typename: "ScheduleQueue",
    id: string,
    type?: string | null,
    due?: string | null,
    action?: string | null,
    Payload?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListScheduleQueuesQueryVariables = {
  filter?: ModelScheduleQueueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListScheduleQueuesQuery = {
  listScheduleQueues?:  {
    __typename: "ModelScheduleQueueConnection",
    items:  Array< {
      __typename: "ScheduleQueue",
      id: string,
      type?: string | null,
      due?: string | null,
      action?: string | null,
      Payload?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStripePaymentQueryVariables = {
  id: string,
};

export type GetStripePaymentQuery = {
  getStripePayment?:  {
    __typename: "StripePayment",
    id: string,
    startDate?: string | null,
    endDate?: string | null,
    type?: string | null,
    owner?: string | null,
    amount?: string | null,
    currency?: string | null,
    receipt_url?: string | null,
    invoice_url?: string | null,
    invoice_id?: string | null,
    additionalInvoiceItems?: Array< string | null > | null,
    status?: string | null,
    intentId?: string | null,
    userId?: string | null,
    publisherId?: string | null,
    productId?: string | null,
    metaData?: string | null,
    ttl?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListStripePaymentsQueryVariables = {
  filter?: ModelStripePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStripePaymentsQuery = {
  listStripePayments?:  {
    __typename: "ModelStripePaymentConnection",
    items:  Array< {
      __typename: "StripePayment",
      id: string,
      startDate?: string | null,
      endDate?: string | null,
      type?: string | null,
      owner?: string | null,
      amount?: string | null,
      currency?: string | null,
      receipt_url?: string | null,
      invoice_url?: string | null,
      invoice_id?: string | null,
      additionalInvoiceItems?: Array< string | null > | null,
      status?: string | null,
      intentId?: string | null,
      userId?: string | null,
      publisherId?: string | null,
      productId?: string | null,
      metaData?: string | null,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCountryDataQueryVariables = {
  id: string,
};

export type GetCountryDataQuery = {
  getCountryData?:  {
    __typename: "CountryData",
    id: string,
    currencies?:  Array< {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null > | null,
    flag?: string | null,
    name?: string | null,
    alpha2Code?: string | null,
    alpha3Code?: string | null,
    callingCodes?: Array< string | null > | null,
    timezones?: Array< string | null > | null,
    region?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCountryDatasQueryVariables = {
  filter?: ModelCountryDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCountryDatasQuery = {
  listCountryDatas?:  {
    __typename: "ModelCountryDataConnection",
    items:  Array< {
      __typename: "CountryData",
      id: string,
      currencies?:  Array< {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null > | null,
      flag?: string | null,
      name?: string | null,
      alpha2Code?: string | null,
      alpha3Code?: string | null,
      callingCodes?: Array< string | null > | null,
      timezones?: Array< string | null > | null,
      region?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserReportQueryVariables = {
  id: string,
};

export type GetUserReportQuery = {
  getUserReport?:  {
    __typename: "UserReport",
    id: string,
    userId: string,
    message?: string | null,
    targetId?: string | null,
    targetType?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type ListUserReportsQueryVariables = {
  filter?: ModelUserReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserReportsQuery = {
  listUserReports?:  {
    __typename: "ModelUserReportConnection",
    items:  Array< {
      __typename: "UserReport",
      id: string,
      userId: string,
      message?: string | null,
      targetId?: string | null,
      targetType?: string | null,
      createdAt: number,
      updatedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTicketQueryVariables = {
  id: string,
};

export type GetTicketQuery = {
  getTicket?:  {
    __typename: "Ticket",
    id: string,
    userId: string,
    bookingReference: string,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    eventType?: string | null,
    eventStartTimestamp?: number | null,
    price?:  {
      __typename: "TicketPrice",
      price?: number | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
    } | null,
    chargeId?: string | null,
    paymentIntentId?: string | null,
    content?: string | null,
    ticketActivasted?: string | null,
    userName?: string | null,
    additionalOptions?:  Array< {
      __typename: "TicketChosenOption",
      title?: string | null,
      chosenOption?: string | null,
    } | null > | null,
    tier?: string | null,
    variation?: string | null,
    owners?: Array< string | null > | null,
    status?: string | null,
    ttl?: number | null,
    test?: string | null,
    refunded?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEventOccurence?:  {
      __typename: "RepeatEventOccurence",
      id: string,
      repeatEventId: string,
      startDate?: string | null,
      endDate?: string | null,
      owners?: Array< string | null > | null,
      isSoldOut?: boolean | null,
      totalTicketsSold?: number | null,
      canBuyTickets?: boolean | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListTicketsQueryVariables = {
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketsQuery = {
  listTickets?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      userId: string,
      bookingReference: string,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      eventType?: string | null,
      eventStartTimestamp?: number | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      chargeId?: string | null,
      paymentIntentId?: string | null,
      content?: string | null,
      ticketActivasted?: string | null,
      userName?: string | null,
      additionalOptions?:  Array< {
        __typename: "TicketChosenOption",
        title?: string | null,
        chosenOption?: string | null,
      } | null > | null,
      tier?: string | null,
      variation?: string | null,
      owners?: Array< string | null > | null,
      status?: string | null,
      ttl?: number | null,
      test?: string | null,
      refunded?: string | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEventOccurence?:  {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClaimedOfferQueryVariables = {
  id: string,
};

export type GetClaimedOfferQuery = {
  getClaimedOffer?:  {
    __typename: "ClaimedOffer",
    id: string,
    userId: string,
    offerId: string,
    fidelOfferId?: string | null,
    activated?: string | null,
    pointsCosts?: number | null,
    entityId: string,
    metadata?: string | null,
    publisherId: string,
    type?: string | null,
    ttl?: number | null,
    owners?: Array< string | null > | null,
    status: string,
    createdAt: number,
    updatedAt: number,
    offer?:  {
      __typename: "SpecialOffer",
      id: string,
      daysAvailable?: Array< number | null > | null,
      startTime?: string | null,
      price?: number | null,
      discount?: number | null,
      endTime?: string | null,
      endDate?: string | null,
      countryCode?: string | null,
      declineReason?: string | null,
      requiresBooking?: string | null,
      startDate?: string | null,
      maxTransactionAmount?: number | null,
      minTransactionAmount?: number | null,
      returnPeriod?: number | null,
      metaData?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      description?: string | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      title: string,
      images?: Array< string | null > | null,
      weeks?: number | null,
      entityId: string,
      publisherId: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      fidelId?: string | null,
      spendRequired?: number | null,
      delayMinutes?: number | null,
      delayHours?: number | null,
      currency?: string | null,
      isStudentDiscount?: boolean | null,
      city?: string | null,
      quantity?: number | null,
      status: string,
      loyaltyPointsRequired?: number | null,
      loyaltyPointsValue?: number | null,
      visitsRequired?: number | null,
      redeemCount?: number | null,
      claimCount?: number | null,
      dateAvailable?: string | null,
      allowanceLimit?: number | null,
      refreshDaysPeriod?: string | null,
      type?: string | null,
      rewardType?: string | null,
      subscribersOnly?: boolean | null,
      owner?: string | null,
      disclaimer?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      fidelOfferId?: string | null,
      fidelStartDate?: string | null,
      fidelEndDate?: string | null,
      fidelMinTransactionAmount?: number | null,
      fidelMaxTransactionAmount?: number | null,
      fidelReturnPeriod?: number | null,
      fidelDaysOfWeek?: Array< number | null > | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListClaimedOffersQueryVariables = {
  filter?: ModelClaimedOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClaimedOffersQuery = {
  listClaimedOffers?:  {
    __typename: "ModelClaimedOfferConnection",
    items:  Array< {
      __typename: "ClaimedOffer",
      id: string,
      userId: string,
      offerId: string,
      fidelOfferId?: string | null,
      activated?: string | null,
      pointsCosts?: number | null,
      entityId: string,
      metadata?: string | null,
      publisherId: string,
      type?: string | null,
      ttl?: number | null,
      owners?: Array< string | null > | null,
      status: string,
      createdAt: number,
      updatedAt: number,
      offer?:  {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserSavedItemQueryVariables = {
  id: string,
};

export type GetUserSavedItemQuery = {
  getUserSavedItem?:  {
    __typename: "UserSavedItem",
    id: string,
    type: string,
    itemId: string,
    title?: string | null,
    image?: string | null,
    authorId?: string | null,
    author?: string | null,
    userId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserSavedItemsQueryVariables = {
  filter?: ModelUserSavedItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserSavedItemsQuery = {
  listUserSavedItems?:  {
    __typename: "ModelUserSavedItemConnection",
    items:  Array< {
      __typename: "UserSavedItem",
      id: string,
      type: string,
      itemId: string,
      title?: string | null,
      image?: string | null,
      authorId?: string | null,
      author?: string | null,
      userId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListtrackingActivityByDateQueryVariables = {
  itemId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTrackingActivityStatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListtrackingActivityByDateQuery = {
  listtrackingActivityByDate?:  {
    __typename: "ModelTrackingActivityStatConnection",
    items:  Array< {
      __typename: "TrackingActivityStat",
      id: string,
      action?: string | null,
      itemType?: string | null,
      itemId?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      gender?: string | null,
      city?: string | null,
      owners: Array< string | null >,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCardTransactionsByUserQueryVariables = {
  userId?: string | null,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCardTransactionsByUserQuery = {
  listCardTransactionsByUser?:  {
    __typename: "ModelCardTransactionsConnection",
    items:  Array< {
      __typename: "CardTransactions",
      currency?: string | null,
      auth?: boolean | null,
      type?: string | null,
      programId?: string | null,
      id?: string | null,
      accountId?: string | null,
      userEntityId?: string | null,
      userPublisherId?: string | null,
      userId?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      entityId?: string | null,
      publisherId?: string | null,
      created?: string | null,
      authCode?: string | null,
      updated?: string | null,
      amount?: number | null,
      cleared?: boolean | null,
      visits?: number | null,
      wallet?: string | null,
      offer?: string | null,
      ttl?: number | null,
      datetime?: string | null,
      card?:  {
        __typename: "TransactionCard",
        id?: string | null,
        firstNumbers?: string | null,
        lastNumbers?: string | null,
        scheme?: string | null,
      } | null,
      location?:  {
        __typename: "FidelLocation",
        address?: string | null,
        city?: string | null,
        countryCode?: string | null,
        id?: string | null,
        geolocation?: string | null,
        postcode?: string | null,
        timezone?: string | null,
      } | null,
      brand?:  {
        __typename: "CardBrandData",
        id?: string | null,
        name?: string | null,
        logoURL?: string | null,
      } | null,
      identifiers?:  {
        __typename: "FidelTransactionIdentifiers",
        amexApprovalCode?: string | null,
        mastercardAuthCode?: string | null,
        mastercardRefNumber?: string | null,
        mastercardTransactionSequenceNumber?: string | null,
        MID?: string | null,
        visaAuthCode?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCardTransactionsByPublisherQueryVariables = {
  publisherId?: string | null,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCardTransactionsByPublisherQuery = {
  listCardTransactionsByPublisher?:  {
    __typename: "ModelCardTransactionsConnection",
    items:  Array< {
      __typename: "CardTransactions",
      currency?: string | null,
      auth?: boolean | null,
      type?: string | null,
      programId?: string | null,
      id?: string | null,
      accountId?: string | null,
      userEntityId?: string | null,
      userPublisherId?: string | null,
      userId?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      entityId?: string | null,
      publisherId?: string | null,
      created?: string | null,
      authCode?: string | null,
      updated?: string | null,
      amount?: number | null,
      cleared?: boolean | null,
      visits?: number | null,
      wallet?: string | null,
      offer?: string | null,
      ttl?: number | null,
      datetime?: string | null,
      card?:  {
        __typename: "TransactionCard",
        id?: string | null,
        firstNumbers?: string | null,
        lastNumbers?: string | null,
        scheme?: string | null,
      } | null,
      location?:  {
        __typename: "FidelLocation",
        address?: string | null,
        city?: string | null,
        countryCode?: string | null,
        id?: string | null,
        geolocation?: string | null,
        postcode?: string | null,
        timezone?: string | null,
      } | null,
      brand?:  {
        __typename: "CardBrandData",
        id?: string | null,
        name?: string | null,
        logoURL?: string | null,
      } | null,
      identifiers?:  {
        __typename: "FidelTransactionIdentifiers",
        amexApprovalCode?: string | null,
        mastercardAuthCode?: string | null,
        mastercardRefNumber?: string | null,
        mastercardTransactionSequenceNumber?: string | null,
        MID?: string | null,
        visaAuthCode?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCardTransactionsByEntityQueryVariables = {
  entityId?: string | null,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCardTransactionsByEntityQuery = {
  listCardTransactionsByEntity?:  {
    __typename: "ModelCardTransactionsConnection",
    items:  Array< {
      __typename: "CardTransactions",
      currency?: string | null,
      auth?: boolean | null,
      type?: string | null,
      programId?: string | null,
      id?: string | null,
      accountId?: string | null,
      userEntityId?: string | null,
      userPublisherId?: string | null,
      userId?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      entityId?: string | null,
      publisherId?: string | null,
      created?: string | null,
      authCode?: string | null,
      updated?: string | null,
      amount?: number | null,
      cleared?: boolean | null,
      visits?: number | null,
      wallet?: string | null,
      offer?: string | null,
      ttl?: number | null,
      datetime?: string | null,
      card?:  {
        __typename: "TransactionCard",
        id?: string | null,
        firstNumbers?: string | null,
        lastNumbers?: string | null,
        scheme?: string | null,
      } | null,
      location?:  {
        __typename: "FidelLocation",
        address?: string | null,
        city?: string | null,
        countryCode?: string | null,
        id?: string | null,
        geolocation?: string | null,
        postcode?: string | null,
        timezone?: string | null,
      } | null,
      brand?:  {
        __typename: "CardBrandData",
        id?: string | null,
        name?: string | null,
        logoURL?: string | null,
      } | null,
      identifiers?:  {
        __typename: "FidelTransactionIdentifiers",
        amexApprovalCode?: string | null,
        mastercardAuthCode?: string | null,
        mastercardRefNumber?: string | null,
        mastercardTransactionSequenceNumber?: string | null,
        MID?: string | null,
        visaAuthCode?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListcardTransactionsByUserAndPublisherQueryVariables = {
  userPublisherId?: string | null,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListcardTransactionsByUserAndPublisherQuery = {
  listcardTransactionsByUserAndPublisher?:  {
    __typename: "ModelCardTransactionsConnection",
    items:  Array< {
      __typename: "CardTransactions",
      currency?: string | null,
      auth?: boolean | null,
      type?: string | null,
      programId?: string | null,
      id?: string | null,
      accountId?: string | null,
      userEntityId?: string | null,
      userPublisherId?: string | null,
      userId?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      entityId?: string | null,
      publisherId?: string | null,
      created?: string | null,
      authCode?: string | null,
      updated?: string | null,
      amount?: number | null,
      cleared?: boolean | null,
      visits?: number | null,
      wallet?: string | null,
      offer?: string | null,
      ttl?: number | null,
      datetime?: string | null,
      card?:  {
        __typename: "TransactionCard",
        id?: string | null,
        firstNumbers?: string | null,
        lastNumbers?: string | null,
        scheme?: string | null,
      } | null,
      location?:  {
        __typename: "FidelLocation",
        address?: string | null,
        city?: string | null,
        countryCode?: string | null,
        id?: string | null,
        geolocation?: string | null,
        postcode?: string | null,
        timezone?: string | null,
      } | null,
      brand?:  {
        __typename: "CardBrandData",
        id?: string | null,
        name?: string | null,
        logoURL?: string | null,
      } | null,
      identifiers?:  {
        __typename: "FidelTransactionIdentifiers",
        amexApprovalCode?: string | null,
        mastercardAuthCode?: string | null,
        mastercardRefNumber?: string | null,
        mastercardTransactionSequenceNumber?: string | null,
        MID?: string | null,
        visaAuthCode?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListcardTransactionsByUserAndEntityQueryVariables = {
  userEntityId?: string | null,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCardTransactionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListcardTransactionsByUserAndEntityQuery = {
  listcardTransactionsByUserAndEntity?:  {
    __typename: "ModelCardTransactionsConnection",
    items:  Array< {
      __typename: "CardTransactions",
      currency?: string | null,
      auth?: boolean | null,
      type?: string | null,
      programId?: string | null,
      id?: string | null,
      accountId?: string | null,
      userEntityId?: string | null,
      userPublisherId?: string | null,
      userId?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      entityId?: string | null,
      publisherId?: string | null,
      created?: string | null,
      authCode?: string | null,
      updated?: string | null,
      amount?: number | null,
      cleared?: boolean | null,
      visits?: number | null,
      wallet?: string | null,
      offer?: string | null,
      ttl?: number | null,
      datetime?: string | null,
      card?:  {
        __typename: "TransactionCard",
        id?: string | null,
        firstNumbers?: string | null,
        lastNumbers?: string | null,
        scheme?: string | null,
      } | null,
      location?:  {
        __typename: "FidelLocation",
        address?: string | null,
        city?: string | null,
        countryCode?: string | null,
        id?: string | null,
        geolocation?: string | null,
        postcode?: string | null,
        timezone?: string | null,
      } | null,
      brand?:  {
        __typename: "CardBrandData",
        id?: string | null,
        name?: string | null,
        logoURL?: string | null,
      } | null,
      identifiers?:  {
        __typename: "FidelTransactionIdentifiers",
        amexApprovalCode?: string | null,
        mastercardAuthCode?: string | null,
        mastercardRefNumber?: string | null,
        mastercardTransactionSequenceNumber?: string | null,
        MID?: string | null,
        visaAuthCode?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListbyLoyaltyCounterPublisherQueryVariables = {
  publisherId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyUserCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListbyLoyaltyCounterPublisherQuery = {
  listbyLoyaltyCounterPublisher?:  {
    __typename: "ModelLoyaltyUserCountersConnection",
    items:  Array< {
      __typename: "LoyaltyUserCounters",
      id: string,
      date?: string | null,
      publisherId: string,
      entityId: string,
      userId: string,
      userDob?: string | null,
      userAge?: number | null,
      visits?: number | null,
      idTypeKey?: string | null,
      userGender?: string | null,
      spend?: number | null,
      ttl?: number | null,
      period?: string | null,
      owners?: Array< string | null > | null,
      owner?: string | null,
      currency?: string | null,
      transactions?: number | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListbyLoyaltyCounterEntityQueryVariables = {
  entityId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyUserCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListbyLoyaltyCounterEntityQuery = {
  listbyLoyaltyCounterEntity?:  {
    __typename: "ModelLoyaltyUserCountersConnection",
    items:  Array< {
      __typename: "LoyaltyUserCounters",
      id: string,
      date?: string | null,
      publisherId: string,
      entityId: string,
      userId: string,
      userDob?: string | null,
      userAge?: number | null,
      visits?: number | null,
      idTypeKey?: string | null,
      userGender?: string | null,
      spend?: number | null,
      ttl?: number | null,
      period?: string | null,
      owners?: Array< string | null > | null,
      owner?: string | null,
      currency?: string | null,
      transactions?: number | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListbyLoyaltyCounterUserQueryVariables = {
  userId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyUserCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListbyLoyaltyCounterUserQuery = {
  listbyLoyaltyCounterUser?:  {
    __typename: "ModelLoyaltyUserCountersConnection",
    items:  Array< {
      __typename: "LoyaltyUserCounters",
      id: string,
      date?: string | null,
      publisherId: string,
      entityId: string,
      userId: string,
      userDob?: string | null,
      userAge?: number | null,
      visits?: number | null,
      idTypeKey?: string | null,
      userGender?: string | null,
      spend?: number | null,
      ttl?: number | null,
      period?: string | null,
      owners?: Array< string | null > | null,
      owner?: string | null,
      currency?: string | null,
      transactions?: number | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLoyaltyCountersByTypeQueryVariables = {
  idTypeKey?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyUserCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLoyaltyCountersByTypeQuery = {
  listLoyaltyCountersByType?:  {
    __typename: "ModelLoyaltyUserCountersConnection",
    items:  Array< {
      __typename: "LoyaltyUserCounters",
      id: string,
      date?: string | null,
      publisherId: string,
      entityId: string,
      userId: string,
      userDob?: string | null,
      userAge?: number | null,
      visits?: number | null,
      idTypeKey?: string | null,
      userGender?: string | null,
      spend?: number | null,
      ttl?: number | null,
      period?: string | null,
      owners?: Array< string | null > | null,
      owner?: string | null,
      currency?: string | null,
      transactions?: number | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListyLoyaltyEntityCountersPublisherQueryVariables = {
  publisherId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyEntityCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListyLoyaltyEntityCountersPublisherQuery = {
  listyLoyaltyEntityCountersPublisher?:  {
    __typename: "ModelLoyaltyEntityCountersConnection",
    items:  Array< {
      __typename: "LoyaltyEntityCounters",
      id: string,
      date?: string | null,
      publisherId: string,
      entityId: string,
      spend?: number | null,
      ttl?: number | null,
      idTypeKey?: string | null,
      genderCount?:  {
        __typename: "GenderCount",
        m?: number | null,
        f?: number | null,
        nb?: number | null,
        t?: number | null,
        o?: number | null,
      } | null,
      ageCount?:  {
        __typename: "AgeCount",
        below18?: number | null,
        age18To25?: number | null,
        age26To30?: number | null,
        age31To35?: number | null,
        age36To40?: number | null,
        age41To45?: number | null,
        Over45?: number | null,
      } | null,
      visits?: number | null,
      currency?: string | null,
      transactions?: number | null,
      paid?: string | null,
      invoice?: string | null,
      owners?: Array< string | null > | null,
      owner?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListyLoyaltyEntityCountersByDatePublisherQueryVariables = {
  publisherId?: string | null,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyEntityCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListyLoyaltyEntityCountersByDatePublisherQuery = {
  listyLoyaltyEntityCountersByDatePublisher?:  {
    __typename: "ModelLoyaltyEntityCountersConnection",
    items:  Array< {
      __typename: "LoyaltyEntityCounters",
      id: string,
      date?: string | null,
      publisherId: string,
      entityId: string,
      spend?: number | null,
      ttl?: number | null,
      idTypeKey?: string | null,
      genderCount?:  {
        __typename: "GenderCount",
        m?: number | null,
        f?: number | null,
        nb?: number | null,
        t?: number | null,
        o?: number | null,
      } | null,
      ageCount?:  {
        __typename: "AgeCount",
        below18?: number | null,
        age18To25?: number | null,
        age26To30?: number | null,
        age31To35?: number | null,
        age36To40?: number | null,
        age41To45?: number | null,
        Over45?: number | null,
      } | null,
      visits?: number | null,
      currency?: string | null,
      transactions?: number | null,
      paid?: string | null,
      invoice?: string | null,
      owners?: Array< string | null > | null,
      owner?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListbyLoyaltyEntityCountersEntityQueryVariables = {
  entityId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyEntityCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListbyLoyaltyEntityCountersEntityQuery = {
  listbyLoyaltyEntityCountersEntity?:  {
    __typename: "ModelLoyaltyEntityCountersConnection",
    items:  Array< {
      __typename: "LoyaltyEntityCounters",
      id: string,
      date?: string | null,
      publisherId: string,
      entityId: string,
      spend?: number | null,
      ttl?: number | null,
      idTypeKey?: string | null,
      genderCount?:  {
        __typename: "GenderCount",
        m?: number | null,
        f?: number | null,
        nb?: number | null,
        t?: number | null,
        o?: number | null,
      } | null,
      ageCount?:  {
        __typename: "AgeCount",
        below18?: number | null,
        age18To25?: number | null,
        age26To30?: number | null,
        age31To35?: number | null,
        age36To40?: number | null,
        age41To45?: number | null,
        Over45?: number | null,
      } | null,
      visits?: number | null,
      currency?: string | null,
      transactions?: number | null,
      paid?: string | null,
      invoice?: string | null,
      owners?: Array< string | null > | null,
      owner?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLoyaltyEntityCountersByTypeQueryVariables = {
  idTypeKey?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyEntityCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLoyaltyEntityCountersByTypeQuery = {
  listLoyaltyEntityCountersByType?:  {
    __typename: "ModelLoyaltyEntityCountersConnection",
    items:  Array< {
      __typename: "LoyaltyEntityCounters",
      id: string,
      date?: string | null,
      publisherId: string,
      entityId: string,
      spend?: number | null,
      ttl?: number | null,
      idTypeKey?: string | null,
      genderCount?:  {
        __typename: "GenderCount",
        m?: number | null,
        f?: number | null,
        nb?: number | null,
        t?: number | null,
        o?: number | null,
      } | null,
      ageCount?:  {
        __typename: "AgeCount",
        below18?: number | null,
        age18To25?: number | null,
        age26To30?: number | null,
        age31To35?: number | null,
        age36To40?: number | null,
        age41To45?: number | null,
        Over45?: number | null,
      } | null,
      visits?: number | null,
      currency?: string | null,
      transactions?: number | null,
      paid?: string | null,
      invoice?: string | null,
      owners?: Array< string | null > | null,
      owner?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStripePaymentsByUserQueryVariables = {
  userId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStripePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStripePaymentsByUserQuery = {
  getStripePaymentsByUser?:  {
    __typename: "ModelStripePaymentConnection",
    items:  Array< {
      __typename: "StripePayment",
      id: string,
      startDate?: string | null,
      endDate?: string | null,
      type?: string | null,
      owner?: string | null,
      amount?: string | null,
      currency?: string | null,
      receipt_url?: string | null,
      invoice_url?: string | null,
      invoice_id?: string | null,
      additionalInvoiceItems?: Array< string | null > | null,
      status?: string | null,
      intentId?: string | null,
      userId?: string | null,
      publisherId?: string | null,
      productId?: string | null,
      metaData?: string | null,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStripePaymentsByPublisherQueryVariables = {
  publisherId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStripePaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStripePaymentsByPublisherQuery = {
  getStripePaymentsByPublisher?:  {
    __typename: "ModelStripePaymentConnection",
    items:  Array< {
      __typename: "StripePayment",
      id: string,
      startDate?: string | null,
      endDate?: string | null,
      type?: string | null,
      owner?: string | null,
      amount?: string | null,
      currency?: string | null,
      receipt_url?: string | null,
      invoice_url?: string | null,
      invoice_id?: string | null,
      additionalInvoiceItems?: Array< string | null > | null,
      status?: string | null,
      intentId?: string | null,
      userId?: string | null,
      publisherId?: string | null,
      productId?: string | null,
      metaData?: string | null,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCountriesByNameQueryVariables = {
  name?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCountryDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCountriesByNameQuery = {
  listCountriesByName?:  {
    __typename: "ModelCountryDataConnection",
    items:  Array< {
      __typename: "CountryData",
      id: string,
      currencies?:  Array< {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null > | null,
      flag?: string | null,
      name?: string | null,
      alpha2Code?: string | null,
      alpha3Code?: string | null,
      callingCodes?: Array< string | null > | null,
      timezones?: Array< string | null > | null,
      region?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReportsByDateQueryVariables = {
  createdAt?: number | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsByDateQuery = {
  listReportsByDate?:  {
    __typename: "ModelUserReportConnection",
    items:  Array< {
      __typename: "UserReport",
      id: string,
      userId: string,
      message?: string | null,
      targetId?: string | null,
      targetType?: string | null,
      createdAt: number,
      updatedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEventTicketsQueryVariables = {
  eventId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventTicketsQuery = {
  listEventTickets?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      userId: string,
      bookingReference: string,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      eventType?: string | null,
      eventStartTimestamp?: number | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      chargeId?: string | null,
      paymentIntentId?: string | null,
      content?: string | null,
      ticketActivasted?: string | null,
      userName?: string | null,
      additionalOptions?:  Array< {
        __typename: "TicketChosenOption",
        title?: string | null,
        chosenOption?: string | null,
      } | null > | null,
      tier?: string | null,
      variation?: string | null,
      owners?: Array< string | null > | null,
      status?: string | null,
      ttl?: number | null,
      test?: string | null,
      refunded?: string | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEventOccurence?:  {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRepeatEventTicketsQueryVariables = {
  repeatEventId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRepeatEventTicketsQuery = {
  listRepeatEventTickets?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      userId: string,
      bookingReference: string,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      eventType?: string | null,
      eventStartTimestamp?: number | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      chargeId?: string | null,
      paymentIntentId?: string | null,
      content?: string | null,
      ticketActivasted?: string | null,
      userName?: string | null,
      additionalOptions?:  Array< {
        __typename: "TicketChosenOption",
        title?: string | null,
        chosenOption?: string | null,
      } | null > | null,
      tier?: string | null,
      variation?: string | null,
      owners?: Array< string | null > | null,
      status?: string | null,
      ttl?: number | null,
      test?: string | null,
      refunded?: string | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEventOccurence?:  {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserTicketsQueryVariables = {
  userId?: string | null,
  eventStartTimestamp?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserTicketsQuery = {
  listUserTickets?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      userId: string,
      bookingReference: string,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      eventType?: string | null,
      eventStartTimestamp?: number | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      chargeId?: string | null,
      paymentIntentId?: string | null,
      content?: string | null,
      ticketActivasted?: string | null,
      userName?: string | null,
      additionalOptions?:  Array< {
        __typename: "TicketChosenOption",
        title?: string | null,
        chosenOption?: string | null,
      } | null > | null,
      tier?: string | null,
      variation?: string | null,
      owners?: Array< string | null > | null,
      status?: string | null,
      ttl?: number | null,
      test?: string | null,
      refunded?: string | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEventOccurence?:  {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListChargeIdTicketsQueryVariables = {
  chargeId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChargeIdTicketsQuery = {
  listChargeIdTickets?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      userId: string,
      bookingReference: string,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      eventType?: string | null,
      eventStartTimestamp?: number | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      chargeId?: string | null,
      paymentIntentId?: string | null,
      content?: string | null,
      ticketActivasted?: string | null,
      userName?: string | null,
      additionalOptions?:  Array< {
        __typename: "TicketChosenOption",
        title?: string | null,
        chosenOption?: string | null,
      } | null > | null,
      tier?: string | null,
      variation?: string | null,
      owners?: Array< string | null > | null,
      status?: string | null,
      ttl?: number | null,
      test?: string | null,
      refunded?: string | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEventOccurence?:  {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTicketsByNameQueryVariables = {
  userName?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketsByNameQuery = {
  listTicketsByName?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      userId: string,
      bookingReference: string,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      eventType?: string | null,
      eventStartTimestamp?: number | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      chargeId?: string | null,
      paymentIntentId?: string | null,
      content?: string | null,
      ticketActivasted?: string | null,
      userName?: string | null,
      additionalOptions?:  Array< {
        __typename: "TicketChosenOption",
        title?: string | null,
        chosenOption?: string | null,
      } | null > | null,
      tier?: string | null,
      variation?: string | null,
      owners?: Array< string | null > | null,
      status?: string | null,
      ttl?: number | null,
      test?: string | null,
      refunded?: string | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEventOccurence?:  {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTicketsByPaymentIntentQueryVariables = {
  paymentIntentId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketsByPaymentIntentQuery = {
  listTicketsByPaymentIntent?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      userId: string,
      bookingReference: string,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      eventType?: string | null,
      eventStartTimestamp?: number | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      chargeId?: string | null,
      paymentIntentId?: string | null,
      content?: string | null,
      ticketActivasted?: string | null,
      userName?: string | null,
      additionalOptions?:  Array< {
        __typename: "TicketChosenOption",
        title?: string | null,
        chosenOption?: string | null,
      } | null > | null,
      tier?: string | null,
      variation?: string | null,
      owners?: Array< string | null > | null,
      status?: string | null,
      ttl?: number | null,
      test?: string | null,
      refunded?: string | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEventOccurence?:  {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTicketsByBookingReferenceQueryVariables = {
  bookingReference?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketsByBookingReferenceQuery = {
  listTicketsByBookingReference?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      userId: string,
      bookingReference: string,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      eventType?: string | null,
      eventStartTimestamp?: number | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      chargeId?: string | null,
      paymentIntentId?: string | null,
      content?: string | null,
      ticketActivasted?: string | null,
      userName?: string | null,
      additionalOptions?:  Array< {
        __typename: "TicketChosenOption",
        title?: string | null,
        chosenOption?: string | null,
      } | null > | null,
      tier?: string | null,
      variation?: string | null,
      owners?: Array< string | null > | null,
      status?: string | null,
      ttl?: number | null,
      test?: string | null,
      refunded?: string | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEventOccurence?:  {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTicketsByVariationQueryVariables = {
  variation?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketsByVariationQuery = {
  listTicketsByVariation?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      userId: string,
      bookingReference: string,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      eventType?: string | null,
      eventStartTimestamp?: number | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      chargeId?: string | null,
      paymentIntentId?: string | null,
      content?: string | null,
      ticketActivasted?: string | null,
      userName?: string | null,
      additionalOptions?:  Array< {
        __typename: "TicketChosenOption",
        title?: string | null,
        chosenOption?: string | null,
      } | null > | null,
      tier?: string | null,
      variation?: string | null,
      owners?: Array< string | null > | null,
      status?: string | null,
      ttl?: number | null,
      test?: string | null,
      refunded?: string | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEventOccurence?:  {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListpublisherClaimedOfferKeyQueryVariables = {
  publisherId?: string | null,
  createdAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClaimedOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListpublisherClaimedOfferKeyQuery = {
  listpublisherClaimedOfferKey?:  {
    __typename: "ModelClaimedOfferConnection",
    items:  Array< {
      __typename: "ClaimedOffer",
      id: string,
      userId: string,
      offerId: string,
      fidelOfferId?: string | null,
      activated?: string | null,
      pointsCosts?: number | null,
      entityId: string,
      metadata?: string | null,
      publisherId: string,
      type?: string | null,
      ttl?: number | null,
      owners?: Array< string | null > | null,
      status: string,
      createdAt: number,
      updatedAt: number,
      offer?:  {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListentityClaimedOfferKeyQueryVariables = {
  entityId?: string | null,
  createdAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClaimedOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListentityClaimedOfferKeyQuery = {
  listentityClaimedOfferKey?:  {
    __typename: "ModelClaimedOfferConnection",
    items:  Array< {
      __typename: "ClaimedOffer",
      id: string,
      userId: string,
      offerId: string,
      fidelOfferId?: string | null,
      activated?: string | null,
      pointsCosts?: number | null,
      entityId: string,
      metadata?: string | null,
      publisherId: string,
      type?: string | null,
      ttl?: number | null,
      owners?: Array< string | null > | null,
      status: string,
      createdAt: number,
      updatedAt: number,
      offer?:  {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserClaimedOffersBetweenMonthsQueryVariables = {
  userId?: string | null,
  createdAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClaimedOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserClaimedOffersBetweenMonthsQuery = {
  userClaimedOffersBetweenMonths?:  {
    __typename: "ModelClaimedOfferConnection",
    items:  Array< {
      __typename: "ClaimedOffer",
      id: string,
      userId: string,
      offerId: string,
      fidelOfferId?: string | null,
      activated?: string | null,
      pointsCosts?: number | null,
      entityId: string,
      metadata?: string | null,
      publisherId: string,
      type?: string | null,
      ttl?: number | null,
      owners?: Array< string | null > | null,
      status: string,
      createdAt: number,
      updatedAt: number,
      offer?:  {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserSavedItemsQueryQueryVariables = {
  userId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserSavedItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserSavedItemsQueryQuery = {
  listUserSavedItemsQuery?:  {
    __typename: "ModelUserSavedItemConnection",
    items:  Array< {
      __typename: "UserSavedItem",
      id: string,
      type: string,
      itemId: string,
      title?: string | null,
      image?: string | null,
      authorId?: string | null,
      author?: string | null,
      userId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchStripePaymentsQueryVariables = {
  filter?: SearchableStripePaymentFilterInput | null,
  sort?: SearchableStripePaymentSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchStripePaymentsQuery = {
  searchStripePayments?:  {
    __typename: "SearchableStripePaymentConnection",
    items:  Array< {
      __typename: "StripePayment",
      id: string,
      startDate?: string | null,
      endDate?: string | null,
      type?: string | null,
      owner?: string | null,
      amount?: string | null,
      currency?: string | null,
      receipt_url?: string | null,
      invoice_url?: string | null,
      invoice_id?: string | null,
      additionalInvoiceItems?: Array< string | null > | null,
      status?: string | null,
      intentId?: string | null,
      userId?: string | null,
      publisherId?: string | null,
      productId?: string | null,
      metaData?: string | null,
      ttl?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCountersQueryVariables = {
  id: string,
};

export type GetCountersQuery = {
  getCounters?:  {
    __typename: "Counters",
    id: string,
    parentId: string,
    itemId: string,
    attending?: number | null,
    views?: number | null,
    week?: string | null,
    booked?: number | null,
    transactions?: number | null,
    idTypeKey?: string | null,
    bookings_clicked?: number | null,
    subscribed?: number | null,
    unsubscribed?: number | null,
    checkins?: number | null,
    list?: number | null,
    shared?: number | null,
    visits?: number | null,
    reviews?: number | null,
    offersClaimed?: number | null,
    offersRedeemed?: number | null,
    loyaltyPoints?: number | null,
    loyaltyCard?: number | null,
    scan?: number | null,
    score?: number | null,
    ttl?: number | null,
    reviewScore?: number | null,
    date?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type ListCounterssQueryVariables = {
  filter?: ModelCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCounterssQuery = {
  listCounterss?:  {
    __typename: "ModelCountersConnection",
    items:  Array< {
      __typename: "Counters",
      id: string,
      parentId: string,
      itemId: string,
      attending?: number | null,
      views?: number | null,
      week?: string | null,
      booked?: number | null,
      transactions?: number | null,
      idTypeKey?: string | null,
      bookings_clicked?: number | null,
      subscribed?: number | null,
      unsubscribed?: number | null,
      checkins?: number | null,
      list?: number | null,
      shared?: number | null,
      visits?: number | null,
      reviews?: number | null,
      offersClaimed?: number | null,
      offersRedeemed?: number | null,
      loyaltyPoints?: number | null,
      loyaltyCard?: number | null,
      scan?: number | null,
      score?: number | null,
      ttl?: number | null,
      reviewScore?: number | null,
      date?: string | null,
      updatedAt?: number | null,
      createdAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCountersByParentQueryVariables = {
  parentId?: string | null,
  updatedAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCountersByParentQuery = {
  listCountersByParent?:  {
    __typename: "ModelCountersConnection",
    items:  Array< {
      __typename: "Counters",
      id: string,
      parentId: string,
      itemId: string,
      attending?: number | null,
      views?: number | null,
      week?: string | null,
      booked?: number | null,
      transactions?: number | null,
      idTypeKey?: string | null,
      bookings_clicked?: number | null,
      subscribed?: number | null,
      unsubscribed?: number | null,
      checkins?: number | null,
      list?: number | null,
      shared?: number | null,
      visits?: number | null,
      reviews?: number | null,
      offersClaimed?: number | null,
      offersRedeemed?: number | null,
      loyaltyPoints?: number | null,
      loyaltyCard?: number | null,
      scan?: number | null,
      score?: number | null,
      ttl?: number | null,
      reviewScore?: number | null,
      date?: string | null,
      updatedAt?: number | null,
      createdAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCountersByItemQueryVariables = {
  itemId?: string | null,
  updatedAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCountersByItemQuery = {
  listCountersByItem?:  {
    __typename: "ModelCountersConnection",
    items:  Array< {
      __typename: "Counters",
      id: string,
      parentId: string,
      itemId: string,
      attending?: number | null,
      views?: number | null,
      week?: string | null,
      booked?: number | null,
      transactions?: number | null,
      idTypeKey?: string | null,
      bookings_clicked?: number | null,
      subscribed?: number | null,
      unsubscribed?: number | null,
      checkins?: number | null,
      list?: number | null,
      shared?: number | null,
      visits?: number | null,
      reviews?: number | null,
      offersClaimed?: number | null,
      offersRedeemed?: number | null,
      loyaltyPoints?: number | null,
      loyaltyCard?: number | null,
      scan?: number | null,
      score?: number | null,
      ttl?: number | null,
      reviewScore?: number | null,
      date?: string | null,
      updatedAt?: number | null,
      createdAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCountersByTypeQueryVariables = {
  idTypeKey?: string | null,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCountersByTypeQuery = {
  listCountersByType?:  {
    __typename: "ModelCountersConnection",
    items:  Array< {
      __typename: "Counters",
      id: string,
      parentId: string,
      itemId: string,
      attending?: number | null,
      views?: number | null,
      week?: string | null,
      booked?: number | null,
      transactions?: number | null,
      idTypeKey?: string | null,
      bookings_clicked?: number | null,
      subscribed?: number | null,
      unsubscribed?: number | null,
      checkins?: number | null,
      list?: number | null,
      shared?: number | null,
      visits?: number | null,
      reviews?: number | null,
      offersClaimed?: number | null,
      offersRedeemed?: number | null,
      loyaltyPoints?: number | null,
      loyaltyCard?: number | null,
      scan?: number | null,
      score?: number | null,
      ttl?: number | null,
      reviewScore?: number | null,
      date?: string | null,
      updatedAt?: number | null,
      createdAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDailyCountersQueryVariables = {
  id: string,
};

export type GetDailyCountersQuery = {
  getDailyCounters?:  {
    __typename: "DailyCounters",
    id: string,
    parentId: string,
    itemId: string,
    attending?: number | null,
    views?: number | null,
    week?: string | null,
    booked?: number | null,
    bookings_clicked?: number | null,
    subscribed?: number | null,
    unsubscribed?: number | null,
    checkins?: number | null,
    shared?: number | null,
    reviews?: number | null,
    offersClaimed?: number | null,
    offersRedeemed?: number | null,
    loyaltyPoints?: number | null,
    loyaltyCard?: number | null,
    scan?: number | null,
    ttl?: number | null,
    date?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type ListDailyCounterssQueryVariables = {
  filter?: ModelDailyCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDailyCounterssQuery = {
  listDailyCounterss?:  {
    __typename: "ModelDailyCountersConnection",
    items:  Array< {
      __typename: "DailyCounters",
      id: string,
      parentId: string,
      itemId: string,
      attending?: number | null,
      views?: number | null,
      week?: string | null,
      booked?: number | null,
      bookings_clicked?: number | null,
      subscribed?: number | null,
      unsubscribed?: number | null,
      checkins?: number | null,
      shared?: number | null,
      reviews?: number | null,
      offersClaimed?: number | null,
      offersRedeemed?: number | null,
      loyaltyPoints?: number | null,
      loyaltyCard?: number | null,
      scan?: number | null,
      ttl?: number | null,
      date?: string | null,
      updatedAt?: number | null,
      createdAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDailyCountersByParentQueryVariables = {
  parentId?: string | null,
  updatedAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDailyCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDailyCountersByParentQuery = {
  listDailyCountersByParent?:  {
    __typename: "ModelDailyCountersConnection",
    items:  Array< {
      __typename: "DailyCounters",
      id: string,
      parentId: string,
      itemId: string,
      attending?: number | null,
      views?: number | null,
      week?: string | null,
      booked?: number | null,
      bookings_clicked?: number | null,
      subscribed?: number | null,
      unsubscribed?: number | null,
      checkins?: number | null,
      shared?: number | null,
      reviews?: number | null,
      offersClaimed?: number | null,
      offersRedeemed?: number | null,
      loyaltyPoints?: number | null,
      loyaltyCard?: number | null,
      scan?: number | null,
      ttl?: number | null,
      date?: string | null,
      updatedAt?: number | null,
      createdAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDailyCountersByItemQueryVariables = {
  itemId?: string | null,
  updatedAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDailyCountersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDailyCountersByItemQuery = {
  listDailyCountersByItem?:  {
    __typename: "ModelDailyCountersConnection",
    items:  Array< {
      __typename: "DailyCounters",
      id: string,
      parentId: string,
      itemId: string,
      attending?: number | null,
      views?: number | null,
      week?: string | null,
      booked?: number | null,
      bookings_clicked?: number | null,
      subscribed?: number | null,
      unsubscribed?: number | null,
      checkins?: number | null,
      shared?: number | null,
      reviews?: number | null,
      offersClaimed?: number | null,
      offersRedeemed?: number | null,
      loyaltyPoints?: number | null,
      loyaltyCard?: number | null,
      scan?: number | null,
      ttl?: number | null,
      date?: string | null,
      updatedAt?: number | null,
      createdAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetArtistQueryVariables = {
  id: string,
};

export type GetArtistQuery = {
  getArtist?:  {
    __typename: "Artist",
    id: string,
    title: string,
    country?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    spotifyAppUri?: string | null,
    videoUrls?: Array< string | null > | null,
    nickname?: string | null,
    bookingConfig?:  {
      __typename: "ArtistBookingConfig",
      bookingsEnabled?: boolean | null,
      messageAutoResponse?: string | null,
      emailsDisabled?: boolean | null,
      hourlyRate?: number | null,
      flatRate?: number | null,
      tastersAavailable?: number | null,
      tasterDurationInMins?: number | null,
      flatRateDurationInMins?: number | null,
      countriesAvailable?: Array< string | null > | null,
      citiesAvailable?: Array< string | null > | null,
    } | null,
    rating?: number | null,
    pingGigsPlayed?: number | null,
    numReviews?: number | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    logo?: string | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    soundcloudAppUri?: string | null,
    appleMusicAppUri?: string | null,
    youtubeUrl?: string | null,
    spotifyUrl?: string | null,
    deezerUrl?: string | null,
    mixcloudUrl?: string | null,
    stripeConnectId?: string | null,
    amazonMusicUrl?: string | null,
    stripeCustomerId?: string | null,
    tidalUrl?: string | null,
    soundcloudUrl?: string | null,
    appleMusicUrl?: string | null,
    description?: string | null,
    genres?: Array< string | null > | null,
    images?: Array< string | null > | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    reviews?:  {
      __typename: "ModelArtistReviewConnection",
      items:  Array< {
        __typename: "ArtistReview",
        id: string,
        review?: string | null,
        score?: number | null,
        artistId: string,
        dateOfVisit?: number | null,
        publisherId: string,
        entityId: string,
        status?: string | null,
        entityTitle?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListArtistsQueryVariables = {
  filter?: ModelArtistFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArtistsQuery = {
  listArtists?:  {
    __typename: "ModelArtistConnection",
    items:  Array< {
      __typename: "Artist",
      id: string,
      title: string,
      country?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      spotifyAppUri?: string | null,
      videoUrls?: Array< string | null > | null,
      nickname?: string | null,
      bookingConfig?:  {
        __typename: "ArtistBookingConfig",
        bookingsEnabled?: boolean | null,
        messageAutoResponse?: string | null,
        emailsDisabled?: boolean | null,
        hourlyRate?: number | null,
        flatRate?: number | null,
        tastersAavailable?: number | null,
        tasterDurationInMins?: number | null,
        flatRateDurationInMins?: number | null,
        countriesAvailable?: Array< string | null > | null,
        citiesAvailable?: Array< string | null > | null,
      } | null,
      rating?: number | null,
      pingGigsPlayed?: number | null,
      numReviews?: number | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      logo?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      soundcloudAppUri?: string | null,
      appleMusicAppUri?: string | null,
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      stripeConnectId?: string | null,
      amazonMusicUrl?: string | null,
      stripeCustomerId?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
      description?: string | null,
      genres?: Array< string | null > | null,
      images?: Array< string | null > | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      reviews?:  {
        __typename: "ModelArtistReviewConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchArtistsQueryVariables = {
  filter?: SearchableArtistFilterInput | null,
  sort?: SearchableArtistSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchArtistsQuery = {
  searchArtists?:  {
    __typename: "SearchableArtistConnection",
    items:  Array< {
      __typename: "Artist",
      id: string,
      title: string,
      country?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      spotifyAppUri?: string | null,
      videoUrls?: Array< string | null > | null,
      nickname?: string | null,
      bookingConfig?:  {
        __typename: "ArtistBookingConfig",
        bookingsEnabled?: boolean | null,
        messageAutoResponse?: string | null,
        emailsDisabled?: boolean | null,
        hourlyRate?: number | null,
        flatRate?: number | null,
        tastersAavailable?: number | null,
        tasterDurationInMins?: number | null,
        flatRateDurationInMins?: number | null,
        countriesAvailable?: Array< string | null > | null,
        citiesAvailable?: Array< string | null > | null,
      } | null,
      rating?: number | null,
      pingGigsPlayed?: number | null,
      numReviews?: number | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      logo?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      soundcloudAppUri?: string | null,
      appleMusicAppUri?: string | null,
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      stripeConnectId?: string | null,
      amazonMusicUrl?: string | null,
      stripeCustomerId?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
      description?: string | null,
      genres?: Array< string | null > | null,
      images?: Array< string | null > | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      reviews?:  {
        __typename: "ModelArtistReviewConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetArtistReviewQueryVariables = {
  id: string,
};

export type GetArtistReviewQuery = {
  getArtistReview?:  {
    __typename: "ArtistReview",
    id: string,
    review?: string | null,
    score?: number | null,
    artistId: string,
    dateOfVisit?: number | null,
    publisherId: string,
    entityId: string,
    status?: string | null,
    entityTitle?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type ListArtistReviewsQueryVariables = {
  filter?: ModelArtistReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArtistReviewsQuery = {
  listArtistReviews?:  {
    __typename: "ModelArtistReviewConnection",
    items:  Array< {
      __typename: "ArtistReview",
      id: string,
      review?: string | null,
      score?: number | null,
      artistId: string,
      dateOfVisit?: number | null,
      publisherId: string,
      entityId: string,
      status?: string | null,
      entityTitle?: string | null,
      createdAt: number,
      updatedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetArtistEventPositionQueryVariables = {
  id: string,
};

export type GetArtistEventPositionQuery = {
  getArtistEventPosition?:  {
    __typename: "ArtistEventPosition",
    id: string,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    eventDate?: number | null,
    city?: string | null,
    ttl?: number | null,
    createdAt: number,
    updatedAt: number,
    artist?:  {
      __typename: "Artist",
      id: string,
      title: string,
      country?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      spotifyAppUri?: string | null,
      videoUrls?: Array< string | null > | null,
      nickname?: string | null,
      bookingConfig?:  {
        __typename: "ArtistBookingConfig",
        bookingsEnabled?: boolean | null,
        messageAutoResponse?: string | null,
        emailsDisabled?: boolean | null,
        hourlyRate?: number | null,
        flatRate?: number | null,
        tastersAavailable?: number | null,
        tasterDurationInMins?: number | null,
        flatRateDurationInMins?: number | null,
        countriesAvailable?: Array< string | null > | null,
        citiesAvailable?: Array< string | null > | null,
      } | null,
      rating?: number | null,
      pingGigsPlayed?: number | null,
      numReviews?: number | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      logo?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      soundcloudAppUri?: string | null,
      appleMusicAppUri?: string | null,
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      stripeConnectId?: string | null,
      amazonMusicUrl?: string | null,
      stripeCustomerId?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
      description?: string | null,
      genres?: Array< string | null > | null,
      images?: Array< string | null > | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      reviews?:  {
        __typename: "ModelArtistReviewConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListArtistEventPositionsQueryVariables = {
  filter?: ModelArtistEventPositionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArtistEventPositionsQuery = {
  listArtistEventPositions?:  {
    __typename: "ModelArtistEventPositionConnection",
    items:  Array< {
      __typename: "ArtistEventPosition",
      id: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      eventDate?: number | null,
      city?: string | null,
      ttl?: number | null,
      createdAt: number,
      updatedAt: number,
      artist?:  {
        __typename: "Artist",
        id: string,
        title: string,
        country?: string | null,
        spotifyAppUri?: string | null,
        videoUrls?: Array< string | null > | null,
        nickname?: string | null,
        rating?: number | null,
        pingGigsPlayed?: number | null,
        numReviews?: number | null,
        logo?: string | null,
        soundcloudAppUri?: string | null,
        appleMusicAppUri?: string | null,
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        stripeConnectId?: string | null,
        amazonMusicUrl?: string | null,
        stripeCustomerId?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
        description?: string | null,
        genres?: Array< string | null > | null,
        images?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchArtistEventPositionsQueryVariables = {
  filter?: SearchableArtistEventPositionFilterInput | null,
  sort?: SearchableArtistEventPositionSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchArtistEventPositionsQuery = {
  searchArtistEventPositions?:  {
    __typename: "SearchableArtistEventPositionConnection",
    items:  Array< {
      __typename: "ArtistEventPosition",
      id: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      eventDate?: number | null,
      city?: string | null,
      ttl?: number | null,
      createdAt: number,
      updatedAt: number,
      artist?:  {
        __typename: "Artist",
        id: string,
        title: string,
        country?: string | null,
        spotifyAppUri?: string | null,
        videoUrls?: Array< string | null > | null,
        nickname?: string | null,
        rating?: number | null,
        pingGigsPlayed?: number | null,
        numReviews?: number | null,
        logo?: string | null,
        soundcloudAppUri?: string | null,
        appleMusicAppUri?: string | null,
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        stripeConnectId?: string | null,
        amazonMusicUrl?: string | null,
        stripeCustomerId?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
        description?: string | null,
        genres?: Array< string | null > | null,
        images?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBlogPostQueryVariables = {
  id: string,
};

export type GetBlogPostQuery = {
  getBlogPost?:  {
    __typename: "BlogPost",
    id: string,
    postType?: string | null,
    title?: string | null,
    desc?: string | null,
    city: string,
    area?: string | null,
    catgories?: Array< string | null > | null,
    headerImg?: string | null,
    readCount?: number | null,
    shareCount?: number | null,
    metaDesc?: string | null,
    metaTitle?: string | null,
    videoUrl?: string | null,
    instagram?: string | null,
    twitter?: string | null,
    website?: string | null,
    facebook?: string | null,
    userId?: string | null,
    publishStatus?: string | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    content?:  Array< {
      __typename: "BlogSectionContent",
      title?: string | null,
      image?: string | null,
      content?: string | null,
    } | null > | null,
    summary?:  Array< {
      __typename: "BlogSummary",
      title?: string | null,
      content?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entities?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelBlogPostEventConnection",
      items:  Array< {
        __typename: "BlogPostEvent",
        id: string,
        eventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelBlogPostRepeatEventConnection",
      items:  Array< {
        __typename: "BlogPostRepeatEvent",
        id: string,
        repeatEventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    user?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListBlogPostsQueryVariables = {
  filter?: ModelBlogPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBlogPostsQuery = {
  listBlogPosts?:  {
    __typename: "ModelBlogPostConnection",
    items:  Array< {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBlogPostByStatusQueryVariables = {
  publishStatus?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBlogPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBlogPostByStatusQuery = {
  listBlogPostByStatus?:  {
    __typename: "ModelBlogPostConnection",
    items:  Array< {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BlogPostsByCityQueryQueryVariables = {
  city?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBlogPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BlogPostsByCityQueryQuery = {
  blogPostsByCityQuery?:  {
    __typename: "ModelBlogPostConnection",
    items:  Array< {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BlogPostsByReadCountQueryQueryVariables = {
  city?: string | null,
  readCount?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBlogPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BlogPostsByReadCountQueryQuery = {
  blogPostsByReadCountQuery?:  {
    __typename: "ModelBlogPostConnection",
    items:  Array< {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BlogPostsByAreaQueryQueryVariables = {
  area?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBlogPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BlogPostsByAreaQueryQuery = {
  blogPostsByAreaQuery?:  {
    __typename: "ModelBlogPostConnection",
    items:  Array< {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchBlogPostsQueryVariables = {
  filter?: SearchableBlogPostFilterInput | null,
  sort?: SearchableBlogPostSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchBlogPostsQuery = {
  searchBlogPosts?:  {
    __typename: "SearchableBlogPostConnection",
    items:  Array< {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBookableTableQueryVariables = {
  id: string,
};

export type GetBookableTableQuery = {
  getBookableTable?:  {
    __typename: "BookableTable",
    id: string,
    tableNumber?: number | null,
    capacity?: number | null,
    area?: string | null,
    entityId: string,
    ttl?: number | null,
    maxPeople?: number | null,
    minPeople?: number | null,
    availability?:  Array< {
      __typename: "TableAvailability",
      startTime?: string | null,
      endTime?: string | null,
    } | null > | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    bookings?:  {
      __typename: "ModelBookingsConnection",
      items:  Array< {
        __typename: "Bookings",
        id: string,
        userName: string,
        userId: string,
        publisher?: string | null,
        bookingType?: string | null,
        entityId: string,
        venueNotes?: string | null,
        durationInMinutes?: number | null,
        entityTitle?: string | null,
        userEmail?: string | null,
        userPhone?: string | null,
        confirmed?: boolean | null,
        owners: Array< string | null >,
        owner?: string | null,
        dateTime: string,
        tableId: string,
        timeString?: string | null,
        provider?: BookingSystemProvider | null,
        dateString?: string | null,
        dateTimestamp: number,
        people: number,
        artistId?: string | null,
        notes?: string | null,
        status: string,
        ttl?: number | null,
        paymentStatus?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListBookableTablesQueryVariables = {
  filter?: ModelBookableTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookableTablesQuery = {
  listBookableTables?:  {
    __typename: "ModelBookableTableConnection",
    items:  Array< {
      __typename: "BookableTable",
      id: string,
      tableNumber?: number | null,
      capacity?: number | null,
      area?: string | null,
      entityId: string,
      ttl?: number | null,
      maxPeople?: number | null,
      minPeople?: number | null,
      availability?:  Array< {
        __typename: "TableAvailability",
        startTime?: string | null,
        endTime?: string | null,
      } | null > | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      bookings?:  {
        __typename: "ModelBookingsConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEntityBookingTablesByMaxPeopleQueryVariables = {
  entityId?: string | null,
  maxPeople?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookableTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityBookingTablesByMaxPeopleQuery = {
  listEntityBookingTablesByMaxPeople?:  {
    __typename: "ModelBookableTableConnection",
    items:  Array< {
      __typename: "BookableTable",
      id: string,
      tableNumber?: number | null,
      capacity?: number | null,
      area?: string | null,
      entityId: string,
      ttl?: number | null,
      maxPeople?: number | null,
      minPeople?: number | null,
      availability?:  Array< {
        __typename: "TableAvailability",
        startTime?: string | null,
        endTime?: string | null,
      } | null > | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      bookings?:  {
        __typename: "ModelBookingsConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEntityBookingTablesByCapacityQueryVariables = {
  entityId?: string | null,
  minPeople?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookableTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityBookingTablesByCapacityQuery = {
  listEntityBookingTablesByCapacity?:  {
    __typename: "ModelBookableTableConnection",
    items:  Array< {
      __typename: "BookableTable",
      id: string,
      tableNumber?: number | null,
      capacity?: number | null,
      area?: string | null,
      entityId: string,
      ttl?: number | null,
      maxPeople?: number | null,
      minPeople?: number | null,
      availability?:  Array< {
        __typename: "TableAvailability",
        startTime?: string | null,
        endTime?: string | null,
      } | null > | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      bookings?:  {
        __typename: "ModelBookingsConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCheckInQueryVariables = {
  id: string,
};

export type GetCheckInQuery = {
  getCheckIn?:  {
    __typename: "CheckIn",
    id: string,
    userId: string,
    entityId: string,
    fullName?: string | null,
    phone?: string | null,
    partySize?: number | null,
    gender?: string | null,
    dob?: string | null,
    owners?: Array< string | null > | null,
    age?: number | null,
    email?: string | null,
    ttl?: number | null,
    status?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    updatedAt?: number | null,
    createdAt: number,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListCheckInsQueryVariables = {
  filter?: ModelCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCheckInsQuery = {
  listCheckIns?:  {
    __typename: "ModelCheckInConnection",
    items:  Array< {
      __typename: "CheckIn",
      id: string,
      userId: string,
      entityId: string,
      fullName?: string | null,
      phone?: string | null,
      partySize?: number | null,
      gender?: string | null,
      dob?: string | null,
      owners?: Array< string | null > | null,
      age?: number | null,
      email?: string | null,
      ttl?: number | null,
      status?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      updatedAt?: number | null,
      createdAt: number,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersCurrentlyAtVenueQueryVariables = {
  entityId?: string | null,
  updatedAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersCurrentlyAtVenueQuery = {
  ListUsersCurrentlyAtVenue?:  {
    __typename: "ModelCheckInConnection",
    items:  Array< {
      __typename: "CheckIn",
      id: string,
      userId: string,
      entityId: string,
      fullName?: string | null,
      phone?: string | null,
      partySize?: number | null,
      gender?: string | null,
      dob?: string | null,
      owners?: Array< string | null > | null,
      age?: number | null,
      email?: string | null,
      ttl?: number | null,
      status?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      updatedAt?: number | null,
      createdAt: number,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCityQueryVariables = {
  id: string,
};

export type GetCityQuery = {
  getCity?:  {
    __typename: "City",
    id: string,
    title: string,
    venueCount?: number | null,
    areas?:  Array< {
      __typename: "Area",
      title?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      parentArea?: string | null,
      images?: Array< string | null > | null,
    } | null > | null,
    description?: string | null,
    country: string,
    continent?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    timezoneOffset?: number | null,
    currency?: string | null,
    images?: Array< string | null > | null,
    createdAt: number,
    updatedAt: number,
    config?:  {
      __typename: "CityConfig",
      events?: boolean | null,
      places?: boolean | null,
    } | null,
  } | null,
};

export type ListCitysQueryVariables = {
  filter?: ModelCityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCitysQuery = {
  listCitys?:  {
    __typename: "ModelCityConnection",
    items:  Array< {
      __typename: "City",
      id: string,
      title: string,
      venueCount?: number | null,
      areas?:  Array< {
        __typename: "Area",
        title?: string | null,
        parentArea?: string | null,
        images?: Array< string | null > | null,
      } | null > | null,
      description?: string | null,
      country: string,
      continent?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      timezoneOffset?: number | null,
      currency?: string | null,
      images?: Array< string | null > | null,
      createdAt: number,
      updatedAt: number,
      config?:  {
        __typename: "CityConfig",
        events?: boolean | null,
        places?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FindCityByNameQueryVariables = {
  title?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindCityByNameQuery = {
  findCityByName?:  {
    __typename: "ModelCityConnection",
    items:  Array< {
      __typename: "City",
      id: string,
      title: string,
      venueCount?: number | null,
      areas?:  Array< {
        __typename: "Area",
        title?: string | null,
        parentArea?: string | null,
        images?: Array< string | null > | null,
      } | null > | null,
      description?: string | null,
      country: string,
      continent?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      timezoneOffset?: number | null,
      currency?: string | null,
      images?: Array< string | null > | null,
      createdAt: number,
      updatedAt: number,
      config?:  {
        __typename: "CityConfig",
        events?: boolean | null,
        places?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCitiesByCountryQueryVariables = {
  title?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCitiesByCountryQuery = {
  listCitiesByCountry?:  {
    __typename: "ModelCityConnection",
    items:  Array< {
      __typename: "City",
      id: string,
      title: string,
      venueCount?: number | null,
      areas?:  Array< {
        __typename: "Area",
        title?: string | null,
        parentArea?: string | null,
        images?: Array< string | null > | null,
      } | null > | null,
      description?: string | null,
      country: string,
      continent?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      timezoneOffset?: number | null,
      currency?: string | null,
      images?: Array< string | null > | null,
      createdAt: number,
      updatedAt: number,
      config?:  {
        __typename: "CityConfig",
        events?: boolean | null,
        places?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCitysQueryVariables = {
  filter?: SearchableCityFilterInput | null,
  sort?: SearchableCitySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCitysQuery = {
  searchCitys?:  {
    __typename: "SearchableCityConnection",
    items:  Array< {
      __typename: "City",
      id: string,
      title: string,
      venueCount?: number | null,
      areas?:  Array< {
        __typename: "Area",
        title?: string | null,
        parentArea?: string | null,
        images?: Array< string | null > | null,
      } | null > | null,
      description?: string | null,
      country: string,
      continent?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      timezoneOffset?: number | null,
      currency?: string | null,
      images?: Array< string | null > | null,
      createdAt: number,
      updatedAt: number,
      config?:  {
        __typename: "CityConfig",
        events?: boolean | null,
        places?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCouponQueryVariables = {
  id: string,
};

export type GetCouponQuery = {
  getCoupon?:  {
    __typename: "Coupon",
    id: string,
    code: string,
    discountType?: string | null,
    discountAmount?: number | null,
    status?: string | null,
    publisherId: string,
    eventType?: string | null,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCouponsQueryVariables = {
  filter?: ModelCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCouponsQuery = {
  listCoupons?:  {
    __typename: "ModelCouponConnection",
    items:  Array< {
      __typename: "Coupon",
      id: string,
      code: string,
      discountType?: string | null,
      discountAmount?: number | null,
      status?: string | null,
      publisherId: string,
      eventType?: string | null,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCouponsByCodeQueryVariables = {
  code?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCouponsByCodeQuery = {
  listCouponsByCode?:  {
    __typename: "ModelCouponConnection",
    items:  Array< {
      __typename: "Coupon",
      id: string,
      code: string,
      discountType?: string | null,
      discountAmount?: number | null,
      status?: string | null,
      publisherId: string,
      eventType?: string | null,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEventCouponsQueryVariables = {
  eventId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventCouponsQuery = {
  listEventCoupons?:  {
    __typename: "ModelCouponConnection",
    items:  Array< {
      __typename: "Coupon",
      id: string,
      code: string,
      discountType?: string | null,
      discountAmount?: number | null,
      status?: string | null,
      publisherId: string,
      eventType?: string | null,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPublisherCouponsQueryVariables = {
  publisherId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPublisherCouponsQuery = {
  listPublisherCoupons?:  {
    __typename: "ModelCouponConnection",
    items:  Array< {
      __typename: "Coupon",
      id: string,
      code: string,
      discountType?: string | null,
      discountAmount?: number | null,
      status?: string | null,
      publisherId: string,
      eventType?: string | null,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRepeatEventCouponsQueryVariables = {
  repeatEventId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRepeatEventCouponsQuery = {
  listRepeatEventCoupons?:  {
    __typename: "ModelCouponConnection",
    items:  Array< {
      __typename: "Coupon",
      id: string,
      code: string,
      discountType?: string | null,
      discountAmount?: number | null,
      status?: string | null,
      publisherId: string,
      eventType?: string | null,
      eventId: string,
      repeatEventId: string,
      repeatEventOccurencId: string,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    title: string,
    entityId: string,
    logo?: string | null,
    headerVideos?: Array< string | null > | null,
    publisherId: string,
    area?: string | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    subtitle?: string | null,
    description?: string | null,
    type?: string | null,
    score?: number | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    categories?: Array< string | null > | null,
    dateTime?: number | null,
    startDate?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    endDate?: string | null,
    closingTime?: number | null,
    rules?: string | null,
    discount?: number | null,
    buyTicketUrl?: string | null,
    canBook?: boolean | null,
    isOnlineEvent?: boolean | null,
    featured?: string | null,
    video?: string | null,
    images?: Array< string | null > | null,
    eventFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    artistsList?: Array< string | null > | null,
    mapImages?: Array< string | null > | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    price?: string | null,
    city: string,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    attending?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    promoterIds?: Array< string | null > | null,
    ttl?: number | null,
    views?: number | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    status?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    pushTimestamps?: Array< number | null > | null,
    ownerType?: string | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    tablesAvailable?: number | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    influencerInfo?:  {
      __typename: "EventInfluencerInfo",
      hashtags?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      details?: string | null,
    } | null,
    username?: string | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    config?:  {
      __typename: "EventConfig",
      canBuyTickets?: boolean | null,
      ticketConfig?:  {
        __typename: "TicketConfig",
        title?: string | null,
        quantity?: number | null,
        amountSold?: number | null,
        maxPerUser?: number | null,
        images?: Array< string | null > | null,
        variationSoldCount?: number | null,
        disclaimer?: string | null,
        customEmailMessage?: string | null,
        description?: string | null,
        dateAvailableFrom?: string | null,
        dateAvailableTill?: string | null,
      } | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    blogPosts?:  {
      __typename: "ModelBlogPostEventConnection",
      items:  Array< {
        __typename: "BlogPostEvent",
        id: string,
        eventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    variations?:  {
      __typename: "ModelTicketVariationOccurenceConnection",
      items:  Array< {
        __typename: "TicketVariationOccurence",
        id: string,
        eventId?: string | null,
        repeatEventId?: string | null,
        repeatEventOccurenceId?: string | null,
        eventType?: string | null,
        variationRuleId: string,
        isSoldOut?: boolean | null,
        amountSoldCounter?: number | null,
        owners?: Array< string | null > | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventShortUrlQueryQueryVariables = {
  username?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventShortUrlQueryQuery = {
  eventShortUrlQuery?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchEventsQueryVariables = {
  filter?: SearchableEventFilterInput | null,
  sort?: SearchableEventSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchEventsQuery = {
  searchEvents?:  {
    __typename: "SearchableEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetRepeatEventQueryVariables = {
  id: string,
};

export type GetRepeatEventQuery = {
  getRepeatEvent?:  {
    __typename: "RepeatEvent",
    id: string,
    title: string,
    entityId: string,
    area?: string | null,
    headerVideos?: Array< string | null > | null,
    publisherId: string,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    logo?: string | null,
    subtitle?: string | null,
    description?: string | null,
    type?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    discount?: number | null,
    isOnlineEvent?: boolean | null,
    score?: number | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    eventFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    closingTime?: number | null,
    dayOfWeek?: number | null,
    daysOfWeek?: Array< number | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    showWeather?: boolean | null,
    buyTicketUrl?: string | null,
    canBook?: boolean | null,
    ttl?: number | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    mapImages?: Array< string | null > | null,
    video?: string | null,
    images?: Array< string | null > | null,
    artistsList?: Array< string | null > | null,
    featured?: string | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    firstEventDate?: string | null,
    lastEventDate?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    price?: string | null,
    rules?: string | null,
    city?: string | null,
    attending?: number | null,
    status: string,
    ownerType?: string | null,
    owner?: string | null,
    pushTimestamps?: Array< number | null > | null,
    promoterIds?: Array< string | null > | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    owners?: Array< string | null > | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    occurencesRules?:  Array< {
      __typename: "OccurencesRules",
      title?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      typeOfRepeat?: string | null,
      repeatInterval?: number | null,
      daysOfWeek?: Array< number | null > | null,
      daysOfMonth?: Array< number | null > | null,
      customDates?:  Array< {
        __typename: "EventDates",
        startDate?: string | null,
        endDate?: string | null,
      } | null > | null,
      weeksOfMonth?: Array< number | null > | null,
      weeksDaysOfMonth?: Array< number | null > | null,
      occurenceTotalRepeat?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      usesEntityTables?: boolean | null,
      price?:  {
        __typename: "TicketPrice",
        price?: number | null,
      } | null,
      quantity?: number | null,
    } | null > | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    username?: string | null,
    influencerInfo?:  {
      __typename: "EventInfluencerInfo",
      hashtags?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      details?: string | null,
    } | null,
    config?:  {
      __typename: "EventConfig",
      canBuyTickets?: boolean | null,
      ticketConfig?:  {
        __typename: "TicketConfig",
        title?: string | null,
        quantity?: number | null,
        amountSold?: number | null,
        maxPerUser?: number | null,
        images?: Array< string | null > | null,
        variationSoldCount?: number | null,
        disclaimer?: string | null,
        customEmailMessage?: string | null,
        description?: string | null,
        dateAvailableFrom?: string | null,
        dateAvailableTill?: string | null,
      } | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    blogPosts?:  {
      __typename: "ModelBlogPostRepeatEventConnection",
      items:  Array< {
        __typename: "BlogPostRepeatEvent",
        id: string,
        repeatEventId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    occurences?:  {
      __typename: "ModelRepeatEventOccurenceConnection",
      items:  Array< {
        __typename: "RepeatEventOccurence",
        id: string,
        repeatEventId: string,
        startDate?: string | null,
        endDate?: string | null,
        owners?: Array< string | null > | null,
        isSoldOut?: boolean | null,
        totalTicketsSold?: number | null,
        canBuyTickets?: boolean | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListRepeatEventsQueryVariables = {
  filter?: ModelRepeatEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRepeatEventsQuery = {
  listRepeatEvents?:  {
    __typename: "ModelRepeatEventConnection",
    items:  Array< {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RepeatEventShortUrlQueryQueryVariables = {
  username?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRepeatEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RepeatEventShortUrlQueryQuery = {
  repeatEventShortUrlQuery?:  {
    __typename: "ModelRepeatEventConnection",
    items:  Array< {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchRepeatEventsQueryVariables = {
  filter?: SearchableRepeatEventFilterInput | null,
  sort?: SearchableRepeatEventSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchRepeatEventsQuery = {
  searchRepeatEvents?:  {
    __typename: "SearchableRepeatEventConnection",
    items:  Array< {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetRepeatEventOccurenceQueryVariables = {
  id: string,
};

export type GetRepeatEventOccurenceQuery = {
  getRepeatEventOccurence?:  {
    __typename: "RepeatEventOccurence",
    id: string,
    repeatEventId: string,
    startDate?: string | null,
    endDate?: string | null,
    owners?: Array< string | null > | null,
    isSoldOut?: boolean | null,
    totalTicketsSold?: number | null,
    canBuyTickets?: boolean | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    attendees?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    variations?:  {
      __typename: "ModelTicketVariationOccurenceConnection",
      items:  Array< {
        __typename: "TicketVariationOccurence",
        id: string,
        eventId?: string | null,
        repeatEventId?: string | null,
        repeatEventOccurenceId?: string | null,
        eventType?: string | null,
        variationRuleId: string,
        isSoldOut?: boolean | null,
        amountSoldCounter?: number | null,
        owners?: Array< string | null > | null,
        ttl?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListRepeatEventOccurencesQueryVariables = {
  filter?: ModelRepeatEventOccurenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRepeatEventOccurencesQuery = {
  listRepeatEventOccurences?:  {
    __typename: "ModelRepeatEventOccurenceConnection",
    items:  Array< {
      __typename: "RepeatEventOccurence",
      id: string,
      repeatEventId: string,
      startDate?: string | null,
      endDate?: string | null,
      owners?: Array< string | null > | null,
      isSoldOut?: boolean | null,
      totalTicketsSold?: number | null,
      canBuyTickets?: boolean | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReviewQueryVariables = {
  id: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    id: string,
    review?: string | null,
    score?: number | null,
    feedback?: string | null,
    scores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    dateOfVisit?: number | null,
    userId: string,
    entityId: string,
    publisherId: string,
    status?: string | null,
    verified?: boolean | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsQuery = {
  listReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      review?: string | null,
      score?: number | null,
      feedback?: string | null,
      scores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      dateOfVisit?: number | null,
      userId: string,
      entityId: string,
      publisherId: string,
      status?: string | null,
      verified?: boolean | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserEventQueryVariables = {
  id: string,
};

export type GetUserEventQuery = {
  getUserEvent?:  {
    __typename: "UserEvent",
    id: string,
    eventDate: number,
    eventId: string,
    userName?: string | null,
    repeatEventId: string,
    repeatEventOccurenceId: string,
    type?: string | null,
    userId: string,
    owners?: Array< string | null > | null,
    status?: string | null,
    checkInDate?: string | null,
    documents?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    tablesBooked?: Array< string | null > | null,
    subscriptionArn?: string | null,
    plusOnesRemaining?: number | null,
    authCode?: string | null,
    subscriptionArns?: Array< string | null > | null,
    pushSubscriptions?:  Array< {
      __typename: "PushSubscription",
      endpointArn?: string | null,
      deviceEndpointArn?: string | null,
    } | null > | null,
    subscriptionActive?: boolean | null,
    title: string,
    createdAt?: number | null,
    updatedAt?: number | null,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListUserEventsQueryVariables = {
  filter?: ModelUserEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserEventsQuery = {
  listUserEvents?:  {
    __typename: "ModelUserEventConnection",
    items:  Array< {
      __typename: "UserEvent",
      id: string,
      eventDate: number,
      eventId: string,
      userName?: string | null,
      repeatEventId: string,
      repeatEventOccurenceId: string,
      type?: string | null,
      userId: string,
      owners?: Array< string | null > | null,
      status?: string | null,
      checkInDate?: string | null,
      documents?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      tablesBooked?: Array< string | null > | null,
      subscriptionArn?: string | null,
      plusOnesRemaining?: number | null,
      authCode?: string | null,
      subscriptionArns?: Array< string | null > | null,
      pushSubscriptions?:  Array< {
        __typename: "PushSubscription",
        endpointArn?: string | null,
        deviceEndpointArn?: string | null,
      } | null > | null,
      subscriptionActive?: boolean | null,
      title: string,
      createdAt?: number | null,
      updatedAt?: number | null,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHelpTopicQueryVariables = {
  id: string,
};

export type GetHelpTopicQuery = {
  getHelpTopic?:  {
    __typename: "HelpTopic",
    id: string,
    title: string,
    type?: string | null,
    category?: string | null,
    icon?: string | null,
    color?: string | null,
    notes?: string | null,
    subtitle?: string | null,
    authorId?: string | null,
    image?: string | null,
    index?: number | null,
    articles?:  Array< {
      __typename: "HelpArticle",
      index?: number | null,
      title?: string | null,
      subtitle?: string | null,
      image?: string | null,
      content?: string | null,
      articles?:  Array< {
        __typename: "HelpArticle",
        index?: number | null,
        title?: string | null,
        subtitle?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
    } | null > | null,
    sections?:  Array< {
      __typename: "HelpSection",
      index?: number | null,
      title?: string | null,
      content?: string | null,
      type?: string | null,
    } | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListHelpTopicsQueryVariables = {
  filter?: ModelHelpTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHelpTopicsQuery = {
  listHelpTopics?:  {
    __typename: "ModelHelpTopicConnection",
    items:  Array< {
      __typename: "HelpTopic",
      id: string,
      title: string,
      type?: string | null,
      category?: string | null,
      icon?: string | null,
      color?: string | null,
      notes?: string | null,
      subtitle?: string | null,
      authorId?: string | null,
      image?: string | null,
      index?: number | null,
      articles?:  Array< {
        __typename: "HelpArticle",
        index?: number | null,
        title?: string | null,
        subtitle?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      sections?:  Array< {
        __typename: "HelpSection",
        index?: number | null,
        title?: string | null,
        content?: string | null,
        type?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListHelpTopicByCategoryQueryVariables = {
  category?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHelpTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHelpTopicByCategoryQuery = {
  ListHelpTopicByCategory?:  {
    __typename: "ModelHelpTopicConnection",
    items:  Array< {
      __typename: "HelpTopic",
      id: string,
      title: string,
      type?: string | null,
      category?: string | null,
      icon?: string | null,
      color?: string | null,
      notes?: string | null,
      subtitle?: string | null,
      authorId?: string | null,
      image?: string | null,
      index?: number | null,
      articles?:  Array< {
        __typename: "HelpArticle",
        index?: number | null,
        title?: string | null,
        subtitle?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      sections?:  Array< {
        __typename: "HelpSection",
        index?: number | null,
        title?: string | null,
        content?: string | null,
        type?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListhelpTopicByTypeQueryVariables = {
  type?: string | null,
  index?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHelpTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListhelpTopicByTypeQuery = {
  ListhelpTopicByType?:  {
    __typename: "ModelHelpTopicConnection",
    items:  Array< {
      __typename: "HelpTopic",
      id: string,
      title: string,
      type?: string | null,
      category?: string | null,
      icon?: string | null,
      color?: string | null,
      notes?: string | null,
      subtitle?: string | null,
      authorId?: string | null,
      image?: string | null,
      index?: number | null,
      articles?:  Array< {
        __typename: "HelpArticle",
        index?: number | null,
        title?: string | null,
        subtitle?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      sections?:  Array< {
        __typename: "HelpSection",
        index?: number | null,
        title?: string | null,
        content?: string | null,
        type?: string | null,
      } | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSpecialOfferQueryVariables = {
  id: string,
};

export type GetSpecialOfferQuery = {
  getSpecialOffer?:  {
    __typename: "SpecialOffer",
    id: string,
    daysAvailable?: Array< number | null > | null,
    startTime?: string | null,
    price?: number | null,
    discount?: number | null,
    endTime?: string | null,
    endDate?: string | null,
    countryCode?: string | null,
    declineReason?: string | null,
    requiresBooking?: string | null,
    startDate?: string | null,
    maxTransactionAmount?: number | null,
    minTransactionAmount?: number | null,
    returnPeriod?: number | null,
    metaData?:  {
      __typename: "IFidelMetaData",
      userId?: string | null,
      publisherId?: string | null,
      brandId?: string | null,
      entityId?: string | null,
      title?: string | null,
      gender?: string | null,
      dob?: string | null,
      cognitoUsername?: string | null,
    } | null,
    description?: string | null,
    totalLoyaltyPointsRequiredForAccess?: number | null,
    title: string,
    images?: Array< string | null > | null,
    weeks?: number | null,
    entityId: string,
    publisherId: string,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    fidelId?: string | null,
    spendRequired?: number | null,
    delayMinutes?: number | null,
    delayHours?: number | null,
    currency?: string | null,
    isStudentDiscount?: boolean | null,
    city?: string | null,
    quantity?: number | null,
    status: string,
    loyaltyPointsRequired?: number | null,
    loyaltyPointsValue?: number | null,
    visitsRequired?: number | null,
    redeemCount?: number | null,
    claimCount?: number | null,
    dateAvailable?: string | null,
    allowanceLimit?: number | null,
    refreshDaysPeriod?: string | null,
    type?: string | null,
    rewardType?: string | null,
    subscribersOnly?: boolean | null,
    owner?: string | null,
    disclaimer?: string | null,
    metadata?:  {
      __typename: "IFidelMetaData",
      userId?: string | null,
      publisherId?: string | null,
      brandId?: string | null,
      entityId?: string | null,
      title?: string | null,
      gender?: string | null,
      dob?: string | null,
      cognitoUsername?: string | null,
    } | null,
    fidelOfferId?: string | null,
    fidelStartDate?: string | null,
    fidelEndDate?: string | null,
    fidelMinTransactionAmount?: number | null,
    fidelMaxTransactionAmount?: number | null,
    fidelReturnPeriod?: number | null,
    fidelDaysOfWeek?: Array< number | null > | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSpecialOffersQueryVariables = {
  filter?: ModelSpecialOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpecialOffersQuery = {
  listSpecialOffers?:  {
    __typename: "ModelSpecialOfferConnection",
    items:  Array< {
      __typename: "SpecialOffer",
      id: string,
      daysAvailable?: Array< number | null > | null,
      startTime?: string | null,
      price?: number | null,
      discount?: number | null,
      endTime?: string | null,
      endDate?: string | null,
      countryCode?: string | null,
      declineReason?: string | null,
      requiresBooking?: string | null,
      startDate?: string | null,
      maxTransactionAmount?: number | null,
      minTransactionAmount?: number | null,
      returnPeriod?: number | null,
      metaData?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      description?: string | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      title: string,
      images?: Array< string | null > | null,
      weeks?: number | null,
      entityId: string,
      publisherId: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      fidelId?: string | null,
      spendRequired?: number | null,
      delayMinutes?: number | null,
      delayHours?: number | null,
      currency?: string | null,
      isStudentDiscount?: boolean | null,
      city?: string | null,
      quantity?: number | null,
      status: string,
      loyaltyPointsRequired?: number | null,
      loyaltyPointsValue?: number | null,
      visitsRequired?: number | null,
      redeemCount?: number | null,
      claimCount?: number | null,
      dateAvailable?: string | null,
      allowanceLimit?: number | null,
      refreshDaysPeriod?: string | null,
      type?: string | null,
      rewardType?: string | null,
      subscribersOnly?: boolean | null,
      owner?: string | null,
      disclaimer?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      fidelOfferId?: string | null,
      fidelStartDate?: string | null,
      fidelEndDate?: string | null,
      fidelMinTransactionAmount?: number | null,
      fidelMaxTransactionAmount?: number | null,
      fidelReturnPeriod?: number | null,
      fidelDaysOfWeek?: Array< number | null > | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SpecialOffersByTypeQueryVariables = {
  type?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpecialOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpecialOffersByTypeQuery = {
  specialOffersByType?:  {
    __typename: "ModelSpecialOfferConnection",
    items:  Array< {
      __typename: "SpecialOffer",
      id: string,
      daysAvailable?: Array< number | null > | null,
      startTime?: string | null,
      price?: number | null,
      discount?: number | null,
      endTime?: string | null,
      endDate?: string | null,
      countryCode?: string | null,
      declineReason?: string | null,
      requiresBooking?: string | null,
      startDate?: string | null,
      maxTransactionAmount?: number | null,
      minTransactionAmount?: number | null,
      returnPeriod?: number | null,
      metaData?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      description?: string | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      title: string,
      images?: Array< string | null > | null,
      weeks?: number | null,
      entityId: string,
      publisherId: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      fidelId?: string | null,
      spendRequired?: number | null,
      delayMinutes?: number | null,
      delayHours?: number | null,
      currency?: string | null,
      isStudentDiscount?: boolean | null,
      city?: string | null,
      quantity?: number | null,
      status: string,
      loyaltyPointsRequired?: number | null,
      loyaltyPointsValue?: number | null,
      visitsRequired?: number | null,
      redeemCount?: number | null,
      claimCount?: number | null,
      dateAvailable?: string | null,
      allowanceLimit?: number | null,
      refreshDaysPeriod?: string | null,
      type?: string | null,
      rewardType?: string | null,
      subscribersOnly?: boolean | null,
      owner?: string | null,
      disclaimer?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      fidelOfferId?: string | null,
      fidelStartDate?: string | null,
      fidelEndDate?: string | null,
      fidelMinTransactionAmount?: number | null,
      fidelMaxTransactionAmount?: number | null,
      fidelReturnPeriod?: number | null,
      fidelDaysOfWeek?: Array< number | null > | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListoffersByStatusKeyQueryVariables = {
  status?: string | null,
  createdAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpecialOfferFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListoffersByStatusKeyQuery = {
  listoffersByStatusKey?:  {
    __typename: "ModelSpecialOfferConnection",
    items:  Array< {
      __typename: "SpecialOffer",
      id: string,
      daysAvailable?: Array< number | null > | null,
      startTime?: string | null,
      price?: number | null,
      discount?: number | null,
      endTime?: string | null,
      endDate?: string | null,
      countryCode?: string | null,
      declineReason?: string | null,
      requiresBooking?: string | null,
      startDate?: string | null,
      maxTransactionAmount?: number | null,
      minTransactionAmount?: number | null,
      returnPeriod?: number | null,
      metaData?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      description?: string | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      title: string,
      images?: Array< string | null > | null,
      weeks?: number | null,
      entityId: string,
      publisherId: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      fidelId?: string | null,
      spendRequired?: number | null,
      delayMinutes?: number | null,
      delayHours?: number | null,
      currency?: string | null,
      isStudentDiscount?: boolean | null,
      city?: string | null,
      quantity?: number | null,
      status: string,
      loyaltyPointsRequired?: number | null,
      loyaltyPointsValue?: number | null,
      visitsRequired?: number | null,
      redeemCount?: number | null,
      claimCount?: number | null,
      dateAvailable?: string | null,
      allowanceLimit?: number | null,
      refreshDaysPeriod?: string | null,
      type?: string | null,
      rewardType?: string | null,
      subscribersOnly?: boolean | null,
      owner?: string | null,
      disclaimer?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      fidelOfferId?: string | null,
      fidelStartDate?: string | null,
      fidelEndDate?: string | null,
      fidelMinTransactionAmount?: number | null,
      fidelMaxTransactionAmount?: number | null,
      fidelReturnPeriod?: number | null,
      fidelDaysOfWeek?: Array< number | null > | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchSpecialOffersQueryVariables = {
  filter?: SearchableSpecialOfferFilterInput | null,
  sort?: SearchableSpecialOfferSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchSpecialOffersQuery = {
  searchSpecialOffers?:  {
    __typename: "SearchableSpecialOfferConnection",
    items:  Array< {
      __typename: "SpecialOffer",
      id: string,
      daysAvailable?: Array< number | null > | null,
      startTime?: string | null,
      price?: number | null,
      discount?: number | null,
      endTime?: string | null,
      endDate?: string | null,
      countryCode?: string | null,
      declineReason?: string | null,
      requiresBooking?: string | null,
      startDate?: string | null,
      maxTransactionAmount?: number | null,
      minTransactionAmount?: number | null,
      returnPeriod?: number | null,
      metaData?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      description?: string | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      title: string,
      images?: Array< string | null > | null,
      weeks?: number | null,
      entityId: string,
      publisherId: string,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      fidelId?: string | null,
      spendRequired?: number | null,
      delayMinutes?: number | null,
      delayHours?: number | null,
      currency?: string | null,
      isStudentDiscount?: boolean | null,
      city?: string | null,
      quantity?: number | null,
      status: string,
      loyaltyPointsRequired?: number | null,
      loyaltyPointsValue?: number | null,
      visitsRequired?: number | null,
      redeemCount?: number | null,
      claimCount?: number | null,
      dateAvailable?: string | null,
      allowanceLimit?: number | null,
      refreshDaysPeriod?: string | null,
      type?: string | null,
      rewardType?: string | null,
      subscribersOnly?: boolean | null,
      owner?: string | null,
      disclaimer?: string | null,
      metadata?:  {
        __typename: "IFidelMetaData",
        userId?: string | null,
        publisherId?: string | null,
        brandId?: string | null,
        entityId?: string | null,
        title?: string | null,
        gender?: string | null,
        dob?: string | null,
        cognitoUsername?: string | null,
      } | null,
      fidelOfferId?: string | null,
      fidelStartDate?: string | null,
      fidelEndDate?: string | null,
      fidelMinTransactionAmount?: number | null,
      fidelMaxTransactionAmount?: number | null,
      fidelReturnPeriod?: number | null,
      fidelDaysOfWeek?: Array< number | null > | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetLoyaltyCardQueryVariables = {
  id: string,
};

export type GetLoyaltyCardQuery = {
  getLoyaltyCard?:  {
    __typename: "LoyaltyCard",
    id: string,
    userId: string,
    publisherId: string,
    businessTitle?: string | null,
    currentTier?: string | null,
    entityId?: string | null,
    visits?: number | null,
    businessAssignedTier?: string | null,
    transactions?: number | null,
    totalLoyaltyPointsRequiredForAccess?: number | null,
    subscriptionArns?: Array< string | null > | null,
    pushSubscriptions?:  Array< {
      __typename: "PushSubscription",
      endpointArn?: string | null,
      deviceEndpointArn?: string | null,
    } | null > | null,
    notificationsEnabled?: boolean | null,
    totalSpend?: number | null,
    pendingPoints?: number | null,
    totalPoints?: number | null,
    ownerType?: string | null,
    status?: string | null,
    userFullName?: string | null,
    owner?: string | null,
    owners?: Array< string | null > | null,
    totalSpendablePoints?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListLoyaltyCardsQueryVariables = {
  id?: string | null,
  filter?: ModelLoyaltyCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLoyaltyCardsQuery = {
  listLoyaltyCards?:  {
    __typename: "ModelLoyaltyCardConnection",
    items:  Array< {
      __typename: "LoyaltyCard",
      id: string,
      userId: string,
      publisherId: string,
      businessTitle?: string | null,
      currentTier?: string | null,
      entityId?: string | null,
      visits?: number | null,
      businessAssignedTier?: string | null,
      transactions?: number | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      subscriptionArns?: Array< string | null > | null,
      pushSubscriptions?:  Array< {
        __typename: "PushSubscription",
        endpointArn?: string | null,
        deviceEndpointArn?: string | null,
      } | null > | null,
      notificationsEnabled?: boolean | null,
      totalSpend?: number | null,
      pendingPoints?: number | null,
      totalPoints?: number | null,
      ownerType?: string | null,
      status?: string | null,
      userFullName?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      totalSpendablePoints?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserLoyaltyCardsQueryVariables = {
  userId?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserLoyaltyCardsQuery = {
  listUserLoyaltyCards?:  {
    __typename: "ModelLoyaltyCardConnection",
    items:  Array< {
      __typename: "LoyaltyCard",
      id: string,
      userId: string,
      publisherId: string,
      businessTitle?: string | null,
      currentTier?: string | null,
      entityId?: string | null,
      visits?: number | null,
      businessAssignedTier?: string | null,
      transactions?: number | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      subscriptionArns?: Array< string | null > | null,
      pushSubscriptions?:  Array< {
        __typename: "PushSubscription",
        endpointArn?: string | null,
        deviceEndpointArn?: string | null,
      } | null > | null,
      notificationsEnabled?: boolean | null,
      totalSpend?: number | null,
      pendingPoints?: number | null,
      totalPoints?: number | null,
      ownerType?: string | null,
      status?: string | null,
      userFullName?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      totalSpendablePoints?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPublisherLoyaltyCardsQueryVariables = {
  publisherId?: string | null,
  totalPoints?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPublisherLoyaltyCardsQuery = {
  listPublisherLoyaltyCards?:  {
    __typename: "ModelLoyaltyCardConnection",
    items:  Array< {
      __typename: "LoyaltyCard",
      id: string,
      userId: string,
      publisherId: string,
      businessTitle?: string | null,
      currentTier?: string | null,
      entityId?: string | null,
      visits?: number | null,
      businessAssignedTier?: string | null,
      transactions?: number | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      subscriptionArns?: Array< string | null > | null,
      pushSubscriptions?:  Array< {
        __typename: "PushSubscription",
        endpointArn?: string | null,
        deviceEndpointArn?: string | null,
      } | null > | null,
      notificationsEnabled?: boolean | null,
      totalSpend?: number | null,
      pendingPoints?: number | null,
      totalPoints?: number | null,
      ownerType?: string | null,
      status?: string | null,
      userFullName?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      totalSpendablePoints?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchLoyaltyCardsQueryVariables = {
  filter?: SearchableLoyaltyCardFilterInput | null,
  sort?: SearchableLoyaltyCardSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchLoyaltyCardsQuery = {
  searchLoyaltyCards?:  {
    __typename: "SearchableLoyaltyCardConnection",
    items:  Array< {
      __typename: "LoyaltyCard",
      id: string,
      userId: string,
      publisherId: string,
      businessTitle?: string | null,
      currentTier?: string | null,
      entityId?: string | null,
      visits?: number | null,
      businessAssignedTier?: string | null,
      transactions?: number | null,
      totalLoyaltyPointsRequiredForAccess?: number | null,
      subscriptionArns?: Array< string | null > | null,
      pushSubscriptions?:  Array< {
        __typename: "PushSubscription",
        endpointArn?: string | null,
        deviceEndpointArn?: string | null,
      } | null > | null,
      notificationsEnabled?: boolean | null,
      totalSpend?: number | null,
      pendingPoints?: number | null,
      totalPoints?: number | null,
      ownerType?: string | null,
      status?: string | null,
      userFullName?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      totalSpendablePoints?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetDailyPromotionsQueryVariables = {
  id: string,
};

export type GetDailyPromotionsQuery = {
  getDailyPromotions?:  {
    __typename: "DailyPromotions",
    id: string,
    entityId: string,
    daysAvailable?: Array< number | null > | null,
    startTime?: string | null,
    endTime?: string | null,
    description?: string | null,
    price?: number | null,
    images?: Array< string | null > | null,
    title?: string | null,
    status?: string | null,
    disclaimer?: string | null,
    owner?: string | null,
    subscribersOnly?: boolean | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type ListDailyPromotionssQueryVariables = {
  filter?: ModelDailyPromotionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDailyPromotionssQuery = {
  listDailyPromotionss?:  {
    __typename: "ModelDailyPromotionsConnection",
    items:  Array< {
      __typename: "DailyPromotions",
      id: string,
      entityId: string,
      daysAvailable?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      description?: string | null,
      price?: number | null,
      images?: Array< string | null > | null,
      title?: string | null,
      status?: string | null,
      disclaimer?: string | null,
      owner?: string | null,
      subscribersOnly?: boolean | null,
      createdAt: number,
      updatedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLoyaltyActivityQueryVariables = {
  id: string,
};

export type GetLoyaltyActivityQuery = {
  getLoyaltyActivity?:  {
    __typename: "LoyaltyActivity",
    id: string,
    userPublisherKey?: string | null,
    points?: number | null,
    eventId: string,
    entityId: string,
    publisherId: string,
    repeatEventId: string,
    userId: string,
    adminAllocated?: boolean | null,
    message?: string | null,
    createdAt: number,
    updatedAt: number,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListLoyaltyActivitysQueryVariables = {
  filter?: ModelLoyaltyActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLoyaltyActivitysQuery = {
  listLoyaltyActivitys?:  {
    __typename: "ModelLoyaltyActivityConnection",
    items:  Array< {
      __typename: "LoyaltyActivity",
      id: string,
      userPublisherKey?: string | null,
      points?: number | null,
      eventId: string,
      entityId: string,
      publisherId: string,
      repeatEventId: string,
      userId: string,
      adminAllocated?: boolean | null,
      message?: string | null,
      createdAt: number,
      updatedAt: number,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserLoyaltyActivityQueryVariables = {
  userId?: string | null,
  createdAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserLoyaltyActivityQuery = {
  listUserLoyaltyActivity?:  {
    __typename: "ModelLoyaltyActivityConnection",
    items:  Array< {
      __typename: "LoyaltyActivity",
      id: string,
      userPublisherKey?: string | null,
      points?: number | null,
      eventId: string,
      entityId: string,
      publisherId: string,
      repeatEventId: string,
      userId: string,
      adminAllocated?: boolean | null,
      message?: string | null,
      createdAt: number,
      updatedAt: number,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserPublisherLoyaltyActivityQueryVariables = {
  userPublisherKey?: string | null,
  createdAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserPublisherLoyaltyActivityQuery = {
  listUserPublisherLoyaltyActivity?:  {
    __typename: "ModelLoyaltyActivityConnection",
    items:  Array< {
      __typename: "LoyaltyActivity",
      id: string,
      userPublisherKey?: string | null,
      points?: number | null,
      eventId: string,
      entityId: string,
      publisherId: string,
      repeatEventId: string,
      userId: string,
      adminAllocated?: boolean | null,
      message?: string | null,
      createdAt: number,
      updatedAt: number,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEntityLoyaltyActivityQueryVariables = {
  entityId?: string | null,
  createdAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntityLoyaltyActivityQuery = {
  listEntityLoyaltyActivity?:  {
    __typename: "ModelLoyaltyActivityConnection",
    items:  Array< {
      __typename: "LoyaltyActivity",
      id: string,
      userPublisherKey?: string | null,
      points?: number | null,
      eventId: string,
      entityId: string,
      publisherId: string,
      repeatEventId: string,
      userId: string,
      adminAllocated?: boolean | null,
      message?: string | null,
      createdAt: number,
      updatedAt: number,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPublisherLoyaltyActivityQueryVariables = {
  publisherId?: string | null,
  createdAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPublisherLoyaltyActivityQuery = {
  listPublisherLoyaltyActivity?:  {
    __typename: "ModelLoyaltyActivityConnection",
    items:  Array< {
      __typename: "LoyaltyActivity",
      id: string,
      userPublisherKey?: string | null,
      points?: number | null,
      eventId: string,
      entityId: string,
      publisherId: string,
      repeatEventId: string,
      userId: string,
      adminAllocated?: boolean | null,
      message?: string | null,
      createdAt: number,
      updatedAt: number,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEntityQueryVariables = {
  id: string,
};

export type GetEntityQuery = {
  getEntity?:  {
    __typename: "Entity",
    id: string,
    title: string,
    shortUrl?: string | null,
    subtitle?: string | null,
    lastAdminCheck?: string | null,
    area?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    notes?: string | null,
    summary?: string | null,
    uniqueAspect?: string | null,
    tags?: Array< string | null > | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    nickname?: string | null,
    hasEmailed?: boolean | null,
    firstName?: string | null,
    hasStudentDiscount?: boolean | null,
    LastName?: string | null,
    config?:  {
      __typename: "EntityConfig",
      loyaltyEnabled?: boolean | null,
      canMessage?: boolean | null,
      canBook?: boolean | null,
      canCheckin?: boolean | null,
      canCall?: boolean | null,
      canEmail?: boolean | null,
      autoResponseMessage?: string | null,
      enterVenueCheckInEnabled?: boolean | null,
      checkInMessage?: string | null,
      colorTheme?: string | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
      bookingConfig?:  {
        __typename: "EntityBookingConfig",
        type?: string | null,
        bookingProvider?: EntityBookingProvider | null,
        bookingProviderBackup?: string | null,
        bookingProviderUsername?: string | null,
        bookingProviderUsernameBackup?: string | null,
        canAutoBook?: boolean | null,
        notes?: string | null,
        bookingDurationMins?: number | null,
        bookingEmails?: Array< string | null > | null,
        bookingPhoneNumber?: string | null,
        areas?: Array< string | null > | null,
      } | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      enableTrackTrace?: boolean | null,
    } | null,
    nearbyStations?:  Array< {
      __typename: "EntityClosestStation",
      title?: string | null,
      distance?: number | null,
      line?: string | null,
      zone?: number | null,
      type?: string | null,
    } | null > | null,
    outdoorArea?: boolean | null,
    outdoorHeating?: boolean | null,
    description?: string | null,
    stripeSubscriptionItemId?: string | null,
    emojiSummary?: Array< string | null > | null,
    categories?: Array< string | null > | null,
    phone?: string | null,
    internationalPhoneNumber?: string | null,
    logo?: string | null,
    pushTimestamps?: Array< number | null > | null,
    websiteUrl?: string | null,
    orderfromTableUrl?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    entityTitle?: string | null,
    opens?: number | null,
    closes?: number | null,
    asFeaturedOn?: Array< string | null > | null,
    username?: string | null,
    trendingScore?: number | null,
    openHours?:  {
      __typename: "DailyOpeningTimes",
      monday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      tuesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      wednesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      thursday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      friday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      saturday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      sunday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
    } | null,
    status: string,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    virtualTourUrl?: string | null,
    emailsDisabled?: boolean | null,
    reviewsDisabled?: boolean | null,
    video?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    headerVideos?: Array< string | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    city: string,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    entityFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    rules?: string | null,
    position?: number | null,
    score?: number | null,
    score_before_boost?: number | null,
    boost_multiplier?: number | null,
    boost_until?: string | null,
    boostPurchaseId?: string | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    canMessage?: boolean | null,
    forceupdate?: boolean | null,
    email?: string | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    price?: string | null,
    featured?: string | null,
    cuisines?: Array< string | null > | null,
    dietryOptions?: Array< string | null > | null,
    foodDeliveryAppLinks?:  Array< {
      __typename: "FoodDeliveryAppLink",
      title?: string | null,
      icon?: string | null,
      url?: string | null,
    } | null > | null,
    verified?: boolean | null,
    qrIds?: Array< string | null > | null,
    publisherId: string,
    subscribeOfferId?: string | null,
    subscriberCount?: number | null,
    viewCount?: number | null,
    bookCount?: number | null,
    shareCount?: number | null,
    checkInCount?: number | null,
    addToListCount?: number | null,
    pushTimestamp?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    images?: Array< string | null > | null,
    googleImages?:  Array< {
      __typename: "GoogleImage",
      html_attributions?: Array< string | null > | null,
      image?: string | null,
    } | null > | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    minBookingLimit?: number | null,
    maxBookingLimit?: number | null,
    bookingUrl?: string | null,
    createdAt: number,
    offersArray?: Array< string | null > | null,
    whatsappNumber?: string | null,
    updatedAt: number,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    owner?: string | null,
    reviewScores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    safetyChecklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    venueSafetyData?:  {
      __typename: "VenueSafetyData",
      list?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      links?: Array< string | null > | null,
      updated?: string | null,
    } | null,
    capacity?: number | null,
    acceptWalkIns?: boolean | null,
    covidMeasures?:  {
      __typename: "CovidMeasures",
      capacityPercentage?: number | null,
      tableLimit?: number | null,
      masksRequired?: boolean | null,
      bookingsRequired?: boolean | null,
      notes?: string | null,
    } | null,
    mediaChannels?:  {
      __typename: "MediaChannels",
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      amazonMusicUrl?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    pingReview?:  {
      __typename: "PingReview",
      score?: number | null,
      title?: string | null,
      review?: string | null,
      createdAt?: string | null,
      reviewer?: string | null,
      reviewerPhoto?: string | null,
    } | null,
    pingAwards?:  Array< {
      __typename: "PingAward",
      date?: string | null,
      type?: string | null,
    } | null > | null,
    blogPosts?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bookingTables?:  {
      __typename: "ModelBookableTableConnection",
      items:  Array< {
        __typename: "BookableTable",
        id: string,
        tableNumber?: number | null,
        capacity?: number | null,
        area?: string | null,
        entityId: string,
        ttl?: number | null,
        maxPeople?: number | null,
        minPeople?: number | null,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dailyPromotions?:  {
      __typename: "ModelDailyPromotionsConnection",
      items:  Array< {
        __typename: "DailyPromotions",
        id: string,
        entityId: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        description?: string | null,
        price?: number | null,
        images?: Array< string | null > | null,
        title?: string | null,
        status?: string | null,
        disclaimer?: string | null,
        owner?: string | null,
        subscribersOnly?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    collection?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListEntitysQueryVariables = {
  filter?: ModelEntityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntitysQuery = {
  listEntitys?:  {
    __typename: "ModelEntityConnection",
    items:  Array< {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEntitiesByAreaScoreQueryVariables = {
  area?: string | null,
  score?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEntityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntitiesByAreaScoreQuery = {
  listEntitiesByAreaScore?:  {
    __typename: "ModelEntityConnection",
    items:  Array< {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEntitiesByCityScoreQueryVariables = {
  city?: string | null,
  score?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEntityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntitiesByCityScoreQuery = {
  listEntitiesByCityScore?:  {
    __typename: "ModelEntityConnection",
    items:  Array< {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EntityShortUrlQueryQueryVariables = {
  username?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEntityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EntityShortUrlQueryQuery = {
  entityShortUrlQuery?:  {
    __typename: "ModelEntityConnection",
    items:  Array< {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchEntitysQueryVariables = {
  filter?: SearchableEntityFilterInput | null,
  sort?: SearchableEntitySortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchEntitysQuery = {
  searchEntitys?:  {
    __typename: "SearchableEntityConnection",
    items:  Array< {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetFeaturedPlaceQueryVariables = {
  id: string,
};

export type GetFeaturedPlaceQuery = {
  getFeaturedPlace?:  {
    __typename: "FeaturedPlace",
    id: string,
    entityId: string,
    eventId?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    area?: string | null,
    title?: string | null,
    description?: string | null,
    pinToTop?: boolean | null,
    city?: string | null,
    featuredUntil?: string | null,
    coverage?: string | null,
    country?: string | null,
    days_selected?: Array< number | null > | null,
    startDate?: string | null,
    budget?: number | null,
    budget_left?: number | null,
    paid?: number | null,
    viewCount?: number | null,
    owner?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    status?: string | null,
    ttl?: number | null,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListFeaturedPlacesQueryVariables = {
  filter?: ModelFeaturedPlaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturedPlacesQuery = {
  listFeaturedPlaces?:  {
    __typename: "ModelFeaturedPlaceConnection",
    items:  Array< {
      __typename: "FeaturedPlace",
      id: string,
      entityId: string,
      eventId?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      area?: string | null,
      title?: string | null,
      description?: string | null,
      pinToTop?: boolean | null,
      city?: string | null,
      featuredUntil?: string | null,
      coverage?: string | null,
      country?: string | null,
      days_selected?: Array< number | null > | null,
      startDate?: string | null,
      budget?: number | null,
      budget_left?: number | null,
      paid?: number | null,
      viewCount?: number | null,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      status?: string | null,
      ttl?: number | null,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFeaturedPlacesByAreaQueryVariables = {
  area?: string | null,
  paidFeaturedUntil?: ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeaturedPlaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturedPlacesByAreaQuery = {
  listFeaturedPlacesByArea?:  {
    __typename: "ModelFeaturedPlaceConnection",
    items:  Array< {
      __typename: "FeaturedPlace",
      id: string,
      entityId: string,
      eventId?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      area?: string | null,
      title?: string | null,
      description?: string | null,
      pinToTop?: boolean | null,
      city?: string | null,
      featuredUntil?: string | null,
      coverage?: string | null,
      country?: string | null,
      days_selected?: Array< number | null > | null,
      startDate?: string | null,
      budget?: number | null,
      budget_left?: number | null,
      paid?: number | null,
      viewCount?: number | null,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      status?: string | null,
      ttl?: number | null,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFeaturedPostInDateOrderQueryVariables = {
  city?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeaturedPlaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturedPostInDateOrderQuery = {
  listFeaturedPostInDateOrder?:  {
    __typename: "ModelFeaturedPlaceConnection",
    items:  Array< {
      __typename: "FeaturedPlace",
      id: string,
      entityId: string,
      eventId?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      area?: string | null,
      title?: string | null,
      description?: string | null,
      pinToTop?: boolean | null,
      city?: string | null,
      featuredUntil?: string | null,
      coverage?: string | null,
      country?: string | null,
      days_selected?: Array< number | null > | null,
      startDate?: string | null,
      budget?: number | null,
      budget_left?: number | null,
      paid?: number | null,
      viewCount?: number | null,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      status?: string | null,
      ttl?: number | null,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFeaturedPlaceByBudgetQueryVariables = {
  entityId?: string | null,
  budget_left?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeaturedPlaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturedPlaceByBudgetQuery = {
  listFeaturedPlaceByBudget?:  {
    __typename: "ModelFeaturedPlaceConnection",
    items:  Array< {
      __typename: "FeaturedPlace",
      id: string,
      entityId: string,
      eventId?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      area?: string | null,
      title?: string | null,
      description?: string | null,
      pinToTop?: boolean | null,
      city?: string | null,
      featuredUntil?: string | null,
      coverage?: string | null,
      country?: string | null,
      days_selected?: Array< number | null > | null,
      startDate?: string | null,
      budget?: number | null,
      budget_left?: number | null,
      paid?: number | null,
      viewCount?: number | null,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      status?: string | null,
      ttl?: number | null,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchFeaturedPlacesQueryVariables = {
  filter?: SearchableFeaturedPlaceFilterInput | null,
  sort?: SearchableFeaturedPlaceSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchFeaturedPlacesQuery = {
  searchFeaturedPlaces?:  {
    __typename: "SearchableFeaturedPlaceConnection",
    items:  Array< {
      __typename: "FeaturedPlace",
      id: string,
      entityId: string,
      eventId?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      area?: string | null,
      title?: string | null,
      description?: string | null,
      pinToTop?: boolean | null,
      city?: string | null,
      featuredUntil?: string | null,
      coverage?: string | null,
      country?: string | null,
      days_selected?: Array< number | null > | null,
      startDate?: string | null,
      budget?: number | null,
      budget_left?: number | null,
      paid?: number | null,
      viewCount?: number | null,
      owner?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      status?: string | null,
      ttl?: number | null,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetBookingsQueryVariables = {
  id: string,
};

export type GetBookingsQuery = {
  getBookings?:  {
    __typename: "Bookings",
    id: string,
    userName: string,
    userId: string,
    publisher?: string | null,
    bookingType?: string | null,
    entityId: string,
    venueNotes?: string | null,
    durationInMinutes?: number | null,
    entityTitle?: string | null,
    userEmail?: string | null,
    userPhone?: string | null,
    confirmed?: boolean | null,
    owners: Array< string | null >,
    owner?: string | null,
    dateTime: string,
    tableId: string,
    timeString?: string | null,
    provider?: BookingSystemProvider | null,
    dateString?: string | null,
    dateTimestamp: number,
    people: number,
    artistId?: string | null,
    notes?: string | null,
    status: string,
    ttl?: number | null,
    paymentStatus?: string | null,
    artistBookingNotes?:  {
      __typename: "ArtistBookingNotes",
      isEquiptmentProvided?: boolean | null,
      isBirthday?: boolean | null,
      isBrandEvent?: boolean | null,
      BrandDetails?: string | null,
      genresRequired?: string | null,
      equiptmentRequired?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListBookingssQueryVariables = {
  filter?: ModelBookingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookingssQuery = {
  listBookingss?:  {
    __typename: "ModelBookingsConnection",
    items:  Array< {
      __typename: "Bookings",
      id: string,
      userName: string,
      userId: string,
      publisher?: string | null,
      bookingType?: string | null,
      entityId: string,
      venueNotes?: string | null,
      durationInMinutes?: number | null,
      entityTitle?: string | null,
      userEmail?: string | null,
      userPhone?: string | null,
      confirmed?: boolean | null,
      owners: Array< string | null >,
      owner?: string | null,
      dateTime: string,
      tableId: string,
      timeString?: string | null,
      provider?: BookingSystemProvider | null,
      dateString?: string | null,
      dateTimestamp: number,
      people: number,
      artistId?: string | null,
      notes?: string | null,
      status: string,
      ttl?: number | null,
      paymentStatus?: string | null,
      artistBookingNotes?:  {
        __typename: "ArtistBookingNotes",
        isEquiptmentProvided?: boolean | null,
        isBirthday?: boolean | null,
        isBrandEvent?: boolean | null,
        BrandDetails?: string | null,
        genresRequired?: string | null,
        equiptmentRequired?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListBookingByBookDateQueryVariables = {
  entityId?: string | null,
  dateTimestamp?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookingByBookDateQuery = {
  listBookingByBookDate?:  {
    __typename: "ModelBookingsConnection",
    items:  Array< {
      __typename: "Bookings",
      id: string,
      userName: string,
      userId: string,
      publisher?: string | null,
      bookingType?: string | null,
      entityId: string,
      venueNotes?: string | null,
      durationInMinutes?: number | null,
      entityTitle?: string | null,
      userEmail?: string | null,
      userPhone?: string | null,
      confirmed?: boolean | null,
      owners: Array< string | null >,
      owner?: string | null,
      dateTime: string,
      tableId: string,
      timeString?: string | null,
      provider?: BookingSystemProvider | null,
      dateString?: string | null,
      dateTimestamp: number,
      people: number,
      artistId?: string | null,
      notes?: string | null,
      status: string,
      ttl?: number | null,
      paymentStatus?: string | null,
      artistBookingNotes?:  {
        __typename: "ArtistBookingNotes",
        isEquiptmentProvided?: boolean | null,
        isBirthday?: boolean | null,
        isBrandEvent?: boolean | null,
        BrandDetails?: string | null,
        genresRequired?: string | null,
        equiptmentRequired?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserBookingsQueryVariables = {
  userId?: string | null,
  dateTimestamp?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserBookingsQuery = {
  listUserBookings?:  {
    __typename: "ModelBookingsConnection",
    items:  Array< {
      __typename: "Bookings",
      id: string,
      userName: string,
      userId: string,
      publisher?: string | null,
      bookingType?: string | null,
      entityId: string,
      venueNotes?: string | null,
      durationInMinutes?: number | null,
      entityTitle?: string | null,
      userEmail?: string | null,
      userPhone?: string | null,
      confirmed?: boolean | null,
      owners: Array< string | null >,
      owner?: string | null,
      dateTime: string,
      tableId: string,
      timeString?: string | null,
      provider?: BookingSystemProvider | null,
      dateString?: string | null,
      dateTimestamp: number,
      people: number,
      artistId?: string | null,
      notes?: string | null,
      status: string,
      ttl?: number | null,
      paymentStatus?: string | null,
      artistBookingNotes?:  {
        __typename: "ArtistBookingNotes",
        isEquiptmentProvided?: boolean | null,
        isBirthday?: boolean | null,
        isBrandEvent?: boolean | null,
        BrandDetails?: string | null,
        genresRequired?: string | null,
        equiptmentRequired?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListbookingsByStatusDateKeyQueryVariables = {
  status?: string | null,
  dateTimestamp?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListbookingsByStatusDateKeyQuery = {
  listbookingsByStatusDateKey?:  {
    __typename: "ModelBookingsConnection",
    items:  Array< {
      __typename: "Bookings",
      id: string,
      userName: string,
      userId: string,
      publisher?: string | null,
      bookingType?: string | null,
      entityId: string,
      venueNotes?: string | null,
      durationInMinutes?: number | null,
      entityTitle?: string | null,
      userEmail?: string | null,
      userPhone?: string | null,
      confirmed?: boolean | null,
      owners: Array< string | null >,
      owner?: string | null,
      dateTime: string,
      tableId: string,
      timeString?: string | null,
      provider?: BookingSystemProvider | null,
      dateString?: string | null,
      dateTimestamp: number,
      people: number,
      artistId?: string | null,
      notes?: string | null,
      status: string,
      ttl?: number | null,
      paymentStatus?: string | null,
      artistBookingNotes?:  {
        __typename: "ArtistBookingNotes",
        isEquiptmentProvided?: boolean | null,
        isBirthday?: boolean | null,
        isBrandEvent?: boolean | null,
        BrandDetails?: string | null,
        genresRequired?: string | null,
        equiptmentRequired?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListArtistBookingsQueryVariables = {
  artistId?: string | null,
  dateTimestamp?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArtistBookingsQuery = {
  listArtistBookings?:  {
    __typename: "ModelBookingsConnection",
    items:  Array< {
      __typename: "Bookings",
      id: string,
      userName: string,
      userId: string,
      publisher?: string | null,
      bookingType?: string | null,
      entityId: string,
      venueNotes?: string | null,
      durationInMinutes?: number | null,
      entityTitle?: string | null,
      userEmail?: string | null,
      userPhone?: string | null,
      confirmed?: boolean | null,
      owners: Array< string | null >,
      owner?: string | null,
      dateTime: string,
      tableId: string,
      timeString?: string | null,
      provider?: BookingSystemProvider | null,
      dateString?: string | null,
      dateTimestamp: number,
      people: number,
      artistId?: string | null,
      notes?: string | null,
      status: string,
      ttl?: number | null,
      paymentStatus?: string | null,
      artistBookingNotes?:  {
        __typename: "ArtistBookingNotes",
        isEquiptmentProvided?: boolean | null,
        isBirthday?: boolean | null,
        isBrandEvent?: boolean | null,
        BrandDetails?: string | null,
        genresRequired?: string | null,
        equiptmentRequired?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchBookingssQueryVariables = {
  filter?: SearchableBookingsFilterInput | null,
  sort?: SearchableBookingsSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchBookingssQuery = {
  searchBookingss?:  {
    __typename: "SearchableBookingsConnection",
    items:  Array< {
      __typename: "Bookings",
      id: string,
      userName: string,
      userId: string,
      publisher?: string | null,
      bookingType?: string | null,
      entityId: string,
      venueNotes?: string | null,
      durationInMinutes?: number | null,
      entityTitle?: string | null,
      userEmail?: string | null,
      userPhone?: string | null,
      confirmed?: boolean | null,
      owners: Array< string | null >,
      owner?: string | null,
      dateTime: string,
      tableId: string,
      timeString?: string | null,
      provider?: BookingSystemProvider | null,
      dateString?: string | null,
      dateTimestamp: number,
      people: number,
      artistId?: string | null,
      notes?: string | null,
      status: string,
      ttl?: number | null,
      paymentStatus?: string | null,
      artistBookingNotes?:  {
        __typename: "ArtistBookingNotes",
        isEquiptmentProvided?: boolean | null,
        isBirthday?: boolean | null,
        isBrandEvent?: boolean | null,
        BrandDetails?: string | null,
        genresRequired?: string | null,
        equiptmentRequired?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetPodcastQueryVariables = {
  id: string,
};

export type GetPodcastQuery = {
  getPodcast?:  {
    __typename: "Podcast",
    id: string,
    entityId: string,
    status?: string | null,
    embedType?: string | null,
    embedId?: string | null,
    embedUrl?: string | null,
    title?: string | null,
    description?: string | null,
    images?: Array< string | null > | null,
    username?: string | null,
    categories?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    guests?:  Array< {
      __typename: "PodcastGuest",
      name?: string | null,
      description?: string | null,
      url?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      details?: string | null,
      image?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListPodcastsQueryVariables = {
  filter?: ModelPodcastFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPodcastsQuery = {
  listPodcasts?:  {
    __typename: "ModelPodcastConnection",
    items:  Array< {
      __typename: "Podcast",
      id: string,
      entityId: string,
      status?: string | null,
      embedType?: string | null,
      embedId?: string | null,
      embedUrl?: string | null,
      title?: string | null,
      description?: string | null,
      images?: Array< string | null > | null,
      username?: string | null,
      categories?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      guests?:  Array< {
        __typename: "PodcastGuest",
        name?: string | null,
        description?: string | null,
        url?: string | null,
        details?: string | null,
        image?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPodcastsByEntityQueryVariables = {
  entityId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPodcastFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPodcastsByEntityQuery = {
  listPodcastsByEntity?:  {
    __typename: "ModelPodcastConnection",
    items:  Array< {
      __typename: "Podcast",
      id: string,
      entityId: string,
      status?: string | null,
      embedType?: string | null,
      embedId?: string | null,
      embedUrl?: string | null,
      title?: string | null,
      description?: string | null,
      images?: Array< string | null > | null,
      username?: string | null,
      categories?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      guests?:  Array< {
        __typename: "PodcastGuest",
        name?: string | null,
        description?: string | null,
        url?: string | null,
        details?: string | null,
        image?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPodcastsByUsernameQueryVariables = {
  username?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPodcastFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPodcastsByUsernameQuery = {
  listPodcastsByUsername?:  {
    __typename: "ModelPodcastConnection",
    items:  Array< {
      __typename: "Podcast",
      id: string,
      entityId: string,
      status?: string | null,
      embedType?: string | null,
      embedId?: string | null,
      embedUrl?: string | null,
      title?: string | null,
      description?: string | null,
      images?: Array< string | null > | null,
      username?: string | null,
      categories?: Array< string | null > | null,
      tags?: Array< string | null > | null,
      guests?:  Array< {
        __typename: "PodcastGuest",
        name?: string | null,
        description?: string | null,
        url?: string | null,
        details?: string | null,
        image?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPublisherQueryVariables = {
  id: string,
};

export type GetPublisherQuery = {
  getPublisher?:  {
    __typename: "Publisher",
    id: string,
    cognitoUsername?: string | null,
    financeEmail?: string | null,
    role?: string | null,
    profilePicture?: string | null,
    bio?: string | null,
    config?:  {
      __typename: "PublisherConfig",
      features?:  {
        __typename: "ConfigFeatures",
        dashboard?: boolean | null,
        events?: boolean | null,
        places?: boolean | null,
        loyalty?: boolean | null,
        messaging?: boolean | null,
        offers?: boolean | null,
        promos?: boolean | null,
        subscribers?: boolean | null,
        updates?: boolean | null,
        reviews?: boolean | null,
        bookings?: boolean | null,
      } | null,
      type?: PublisherType | null,
      isBespoke?: boolean | null,
      standAloneVenue?: boolean | null,
      notificationsEnabled?: boolean | null,
      mailMarketingProvider?: MailMarketingProvider | null,
      paymentProvier?: PaymentProvider | null,
      POSProviders?: POSProvider | null,
      eventsConfig?:  {
        __typename: "ConfigEvents",
        usePaymentProvider?: boolean | null,
        tablesEnabled?: boolean | null,
      } | null,
      bookingsConfig?:  {
        __typename: "ConfigBookings",
        bookingProvider?: BookingSystemProvider | null,
        integratePOS?: boolean | null,
        emailsDisabled?: boolean | null,
      } | null,
      offersConfig?:  {
        __typename: "ConfigOffers",
        integratePOS?: boolean | null,
      } | null,
      subscribersConfig?:  {
        __typename: "ConfigSubscribers",
        isPaid?: boolean | null,
        subscriptionLength?: number | null,
      } | null,
      loyaltyConfig?:  {
        __typename: "ConfigLoyalty",
        integratePOS?: boolean | null,
        loyaltyEnabled?: boolean | null,
        color?: string | null,
        textColor?: string | null,
        loyaltyPeriodInDays?: number | null,
        logo?: string | null,
        listLogo?: string | null,
        cardLogo?: string | null,
        tiersEnabled?: boolean | null,
        type?: LoyaltySystemType | null,
        businessShortName?: string | null,
        loyaltyPointCurrencyValue?: number | null,
        classes?: string | null,
        securityCode?: number | null,
        disabledAutoTierCardColours?: boolean | null,
        useMainLogo?: boolean | null,
        textPosition?: string | null,
        customCardDesignUrl?: string | null,
        text?: string | null,
      } | null,
      menuConfig?:  {
        __typename: "MenuConfig",
        integratePOS?: boolean | null,
      } | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    publisherType: PublisherType,
    editors?: Array< string | null > | null,
    owner: string,
    username?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    country?:  {
      __typename: "Country",
      currencies?:  Array< {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null > | null,
      flag?: string | null,
      name?: string | null,
      alpha2Code?: string | null,
      alpha3Code?: string | null,
      callingCodes?: Array< string | null > | null,
      timezones?: Array< string | null > | null,
      region?: string | null,
    } | null,
    pushTimestamps?: Array< number | null > | null,
    emailsDisabled?: boolean | null,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    timezone?: string | null,
    subscibedToEmails?: boolean | null,
    timezoneOffset?: number | null,
    logo?: string | null,
    businessName?: string | null,
    description?: string | null,
    websiteUrl?: string | null,
    whatsappNumber?: string | null,
    phone?: string | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    canMessage?: boolean | null,
    subscriberCount?: number | null,
    loyaltyCount?: number | null,
    status?: string | null,
    termsVersionSigned?: number | null,
    activated?: string | null,
    reviewsDisabled?: boolean | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    activationDate?: string | null,
    stripeConnectId?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    stripeCustomerId?: string | null,
    fidelId?: string | null,
    stripeSubscriptions?:  Array< {
      __typename: "StripeCustomerSubscription",
      id?: string | null,
      current_period_end?: number | null,
      current_period_start?: number | null,
      quantity?: number | null,
      entitiesIncluded?: Array< string | null > | null,
      items?:  Array< {
        __typename: "StripeCustomerSubscriptionItem",
        id?: string | null,
        priceId?: string | null,
        quantity?: number | null,
      } | null > | null,
      latest_invoice?: string | null,
      status?: string | null,
      created?: number | null,
    } | null > | null,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    loyaltyCards?:  {
      __typename: "ModelLoyaltyCardConnection",
      items:  Array< {
        __typename: "LoyaltyCard",
        id: string,
        userId: string,
        publisherId: string,
        businessTitle?: string | null,
        currentTier?: string | null,
        entityId?: string | null,
        visits?: number | null,
        businessAssignedTier?: string | null,
        transactions?: number | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        totalSpend?: number | null,
        pendingPoints?: number | null,
        totalPoints?: number | null,
        ownerType?: string | null,
        status?: string | null,
        userFullName?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        totalSpendablePoints?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entities?:  {
      __typename: "ModelEntityConnection",
      items:  Array< {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPublishersQueryVariables = {
  filter?: ModelPublisherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPublishersQuery = {
  listPublishers?:  {
    __typename: "ModelPublisherConnection",
    items:  Array< {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPublisherByOwnerQueryVariables = {
  owner?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPublisherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPublisherByOwnerQuery = {
  getPublisherByOwner?:  {
    __typename: "ModelPublisherConnection",
    items:  Array< {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPublishersQueryVariables = {
  filter?: SearchablePublisherFilterInput | null,
  sort?: SearchablePublisherSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPublishersQuery = {
  searchPublishers?:  {
    __typename: "SearchablePublisherConnection",
    items:  Array< {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCategoriesQueryVariables = {
  id: string,
};

export type GetCategoriesQuery = {
  getCategories?:  {
    __typename: "Categories",
    id: string,
    title: string,
    type?: string | null,
    icon?: string | null,
    desc?: string | null,
    pingEditTitle?: string | null,
    metaTitle?: string | null,
    metaDescription?: string | null,
    priority?: number | null,
    showcaseImage?: string | null,
    categoryCityCount?: string | null,
    isHidden?: boolean | null,
    citiesAvailable?: Array< string | null > | null,
    translateString?: string | null,
    colour: string,
    image: string,
    subCategories?:  Array< {
      __typename: "Category",
      title: string,
      image: string,
      isAdmin?: boolean | null,
      desc?: string | null,
      showcaseImage?: string | null,
      translateString?: string | null,
      pingEditTitle?: string | null,
      metaTitle?: string | null,
      metaDescription?: string | null,
      categoryCityCount?: string | null,
      isHidden?: boolean | null,
      citiesAvailable?: Array< string | null > | null,
      icon?: string | null,
      colour?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCategoriessQueryVariables = {
  filter?: ModelCategoriesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriessQuery = {
  listCategoriess?:  {
    __typename: "ModelCategoriesConnection",
    items:  Array< {
      __typename: "Categories",
      id: string,
      title: string,
      type?: string | null,
      icon?: string | null,
      desc?: string | null,
      pingEditTitle?: string | null,
      metaTitle?: string | null,
      metaDescription?: string | null,
      priority?: number | null,
      showcaseImage?: string | null,
      categoryCityCount?: string | null,
      isHidden?: boolean | null,
      citiesAvailable?: Array< string | null > | null,
      translateString?: string | null,
      colour: string,
      image: string,
      subCategories?:  Array< {
        __typename: "Category",
        title: string,
        image: string,
        isAdmin?: boolean | null,
        desc?: string | null,
        showcaseImage?: string | null,
        translateString?: string | null,
        pingEditTitle?: string | null,
        metaTitle?: string | null,
        metaDescription?: string | null,
        categoryCityCount?: string | null,
        isHidden?: boolean | null,
        citiesAvailable?: Array< string | null > | null,
        icon?: string | null,
        colour?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVenueCategoryTopicsQueryVariables = {
  id: string,
};

export type GetVenueCategoryTopicsQuery = {
  getVenueCategoryTopics?:  {
    __typename: "VenueCategoryTopics",
    id: string,
    title?: string | null,
    subtitle?: string | null,
    image?: string | null,
    URL?: string | null,
    type?: string | null,
    itemId?: string | null,
    content?: string | null,
    pinToTop?: boolean | null,
    tag?: string | null,
    category?: string | null,
    subCategory?: string | null,
    price?: number | null,
    omittedCities?: Array< string | null > | null,
    allowedCities?: Array< string | null > | null,
    orderBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVenueCategoryTopicssQueryVariables = {
  filter?: ModelVenueCategoryTopicsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVenueCategoryTopicssQuery = {
  listVenueCategoryTopicss?:  {
    __typename: "ModelVenueCategoryTopicsConnection",
    items:  Array< {
      __typename: "VenueCategoryTopics",
      id: string,
      title?: string | null,
      subtitle?: string | null,
      image?: string | null,
      URL?: string | null,
      type?: string | null,
      itemId?: string | null,
      content?: string | null,
      pinToTop?: boolean | null,
      tag?: string | null,
      category?: string | null,
      subCategory?: string | null,
      price?: number | null,
      omittedCities?: Array< string | null > | null,
      allowedCities?: Array< string | null > | null,
      orderBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPingConfigsQueryVariables = {
  id: string,
};

export type GetPingConfigsQuery = {
  getPingConfigs?:  {
    __typename: "PingConfigs",
    id: string,
    type?: string | null,
    token?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListPingConfigssQueryVariables = {
  filter?: ModelPingConfigsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPingConfigssQuery = {
  listPingConfigss?:  {
    __typename: "ModelPingConfigsConnection",
    items:  Array< {
      __typename: "PingConfigs",
      id: string,
      type?: string | null,
      token?: string | null,
      data?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListConfigsByTypeQueryVariables = {
  type?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPingConfigsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConfigsByTypeQuery = {
  listConfigsByType?:  {
    __typename: "ModelPingConfigsConnection",
    items:  Array< {
      __typename: "PingConfigs",
      id: string,
      type?: string | null,
      token?: string | null,
      data?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeedbackQueryVariables = {
  id: string,
};

export type GetFeedbackQuery = {
  getFeedback?:  {
    __typename: "Feedback",
    id: string,
    userId?: string | null,
    ttl?: number | null,
    email?: string | null,
    name?: string | null,
    feedback?: string | null,
    createdAt: number,
    updatedAt: number,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListFeedbacksQueryVariables = {
  filter?: ModelFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeedbacksQuery = {
  listFeedbacks?:  {
    __typename: "ModelFeedbackConnection",
    items:  Array< {
      __typename: "Feedback",
      id: string,
      userId?: string | null,
      ttl?: number | null,
      email?: string | null,
      name?: string | null,
      feedback?: string | null,
      createdAt: number,
      updatedAt: number,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVenueTableQueryVariables = {
  id: string,
};

export type GetVenueTableQuery = {
  getVenueTable?:  {
    __typename: "VenueTable",
    id: string,
    capacity?: number | null,
    quantityAvailable?: number | null,
    tablesBooked?: number | null,
    price?: number | null,
    includes?:  Array< {
      __typename: "TableIncludes",
      quantity?: number | null,
      title?: string | null,
      description?: string | null,
    } | null > | null,
    disclaimer?: string | null,
    instructions?: string | null,
    parentType?: TableParentType | null,
    entityId: string,
    eventId: string,
    repeatEventId: string,
    status?: string | null,
    title?: string | null,
    owner: string,
    createdAt: number,
    updatedAt: number,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListVenueTablesQueryVariables = {
  filter?: ModelVenueTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVenueTablesQuery = {
  listVenueTables?:  {
    __typename: "ModelVenueTableConnection",
    items:  Array< {
      __typename: "VenueTable",
      id: string,
      capacity?: number | null,
      quantityAvailable?: number | null,
      tablesBooked?: number | null,
      price?: number | null,
      includes?:  Array< {
        __typename: "TableIncludes",
        quantity?: number | null,
        title?: string | null,
        description?: string | null,
      } | null > | null,
      disclaimer?: string | null,
      instructions?: string | null,
      parentType?: TableParentType | null,
      entityId: string,
      eventId: string,
      repeatEventId: string,
      status?: string | null,
      title?: string | null,
      owner: string,
      createdAt: number,
      updatedAt: number,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTableBookingRequestQueryVariables = {
  id: string,
};

export type GetTableBookingRequestQuery = {
  getTableBookingRequest?:  {
    __typename: "TableBookingRequest",
    id: string,
    userName?: string | null,
    userId: string,
    paid?: boolean | null,
    amountPaid?: number | null,
    eventId: string,
    status?: string | null,
    owners?: Array< string | null > | null,
    tableId: string,
    message?: string | null,
    createdAt: number,
    updatedAt: number,
    table?:  {
      __typename: "VenueTable",
      id: string,
      capacity?: number | null,
      quantityAvailable?: number | null,
      tablesBooked?: number | null,
      price?: number | null,
      includes?:  Array< {
        __typename: "TableIncludes",
        quantity?: number | null,
        title?: string | null,
        description?: string | null,
      } | null > | null,
      disclaimer?: string | null,
      instructions?: string | null,
      parentType?: TableParentType | null,
      entityId: string,
      eventId: string,
      repeatEventId: string,
      status?: string | null,
      title?: string | null,
      owner: string,
      createdAt: number,
      updatedAt: number,
      event?:  {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListTableBookingRequestsQueryVariables = {
  filter?: ModelTableBookingRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTableBookingRequestsQuery = {
  listTableBookingRequests?:  {
    __typename: "ModelTableBookingRequestConnection",
    items:  Array< {
      __typename: "TableBookingRequest",
      id: string,
      userName?: string | null,
      userId: string,
      paid?: boolean | null,
      amountPaid?: number | null,
      eventId: string,
      status?: string | null,
      owners?: Array< string | null > | null,
      tableId: string,
      message?: string | null,
      createdAt: number,
      updatedAt: number,
      table?:  {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TableRequestsByTableEventQueryVariables = {
  tableId?: string | null,
  eventId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTableBookingRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TableRequestsByTableEventQuery = {
  tableRequestsByTableEvent?:  {
    __typename: "ModelTableBookingRequestConnection",
    items:  Array< {
      __typename: "TableBookingRequest",
      id: string,
      userName?: string | null,
      userId: string,
      paid?: boolean | null,
      amountPaid?: number | null,
      eventId: string,
      status?: string | null,
      owners?: Array< string | null > | null,
      tableId: string,
      message?: string | null,
      createdAt: number,
      updatedAt: number,
      table?:  {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TableRequestsByUserEventQueryVariables = {
  userId?: string | null,
  eventId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTableBookingRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TableRequestsByUserEventQuery = {
  tableRequestsByUserEvent?:  {
    __typename: "ModelTableBookingRequestConnection",
    items:  Array< {
      __typename: "TableBookingRequest",
      id: string,
      userName?: string | null,
      userId: string,
      paid?: boolean | null,
      amountPaid?: number | null,
      eventId: string,
      status?: string | null,
      owners?: Array< string | null > | null,
      tableId: string,
      message?: string | null,
      createdAt: number,
      updatedAt: number,
      table?:  {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TableRequestsByEventQueryVariables = {
  eventId?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTableBookingRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TableRequestsByEventQuery = {
  tableRequestsByEvent?:  {
    __typename: "ModelTableBookingRequestConnection",
    items:  Array< {
      __typename: "TableBookingRequest",
      id: string,
      userName?: string | null,
      userId: string,
      paid?: boolean | null,
      amountPaid?: number | null,
      eventId: string,
      status?: string | null,
      owners?: Array< string | null > | null,
      tableId: string,
      message?: string | null,
      createdAt: number,
      updatedAt: number,
      table?:  {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTicketVariationOccurenceQueryVariables = {
  id: string,
};

export type GetTicketVariationOccurenceQuery = {
  getTicketVariationOccurence?:  {
    __typename: "TicketVariationOccurence",
    id: string,
    eventId?: string | null,
    repeatEventId?: string | null,
    repeatEventOccurenceId?: string | null,
    eventType?: string | null,
    variationRuleId: string,
    isSoldOut?: boolean | null,
    amountSoldCounter?: number | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListTicketVariationOccurencesQueryVariables = {
  filter?: ModelTicketVariationOccurenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketVariationOccurencesQuery = {
  listTicketVariationOccurences?:  {
    __typename: "ModelTicketVariationOccurenceConnection",
    items:  Array< {
      __typename: "TicketVariationOccurence",
      id: string,
      eventId?: string | null,
      repeatEventId?: string | null,
      repeatEventOccurenceId?: string | null,
      eventType?: string | null,
      variationRuleId: string,
      isSoldOut?: boolean | null,
      amountSoldCounter?: number | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListVariationsByRuleIdQueryVariables = {
  variationRuleId?: string | null,
  amountSoldCounter?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketVariationOccurenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVariationsByRuleIdQuery = {
  listVariationsByRuleId?:  {
    __typename: "ModelTicketVariationOccurenceConnection",
    items:  Array< {
      __typename: "TicketVariationOccurence",
      id: string,
      eventId?: string | null,
      repeatEventId?: string | null,
      repeatEventOccurenceId?: string | null,
      eventType?: string | null,
      variationRuleId: string,
      isSoldOut?: boolean | null,
      amountSoldCounter?: number | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRepeatEventOccurenceVariationsQueryVariables = {
  repeatEventOccurenceId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketVariationOccurenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRepeatEventOccurenceVariationsQuery = {
  listRepeatEventOccurenceVariations?:  {
    __typename: "ModelTicketVariationOccurenceConnection",
    items:  Array< {
      __typename: "TicketVariationOccurence",
      id: string,
      eventId?: string | null,
      repeatEventId?: string | null,
      repeatEventOccurenceId?: string | null,
      eventType?: string | null,
      variationRuleId: string,
      isSoldOut?: boolean | null,
      amountSoldCounter?: number | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEventVariationsQueryVariables = {
  eventId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketVariationOccurenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventVariationsQuery = {
  listEventVariations?:  {
    __typename: "ModelTicketVariationOccurenceConnection",
    items:  Array< {
      __typename: "TicketVariationOccurence",
      id: string,
      eventId?: string | null,
      repeatEventId?: string | null,
      repeatEventOccurenceId?: string | null,
      eventType?: string | null,
      variationRuleId: string,
      isSoldOut?: boolean | null,
      amountSoldCounter?: number | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUpdateQueryVariables = {
  id: string,
};

export type GetUpdateQuery = {
  getUpdate?:  {
    __typename: "Update",
    id: string,
    title: string,
    content: string,
    parentId: string,
    parentTitle?: string | null,
    isPinned?: boolean | null,
    metadata?: string | null,
    actionCount?: number | null,
    viewCount?: number | null,
    url?: string | null,
    images?: Array< string | null > | null,
    route?: Array< string | null > | null,
    extra?: string | null,
    type?: string | null,
    updatedAt?: number | null,
    createdAt?: number | null,
  } | null,
};

export type ListUpdatesQueryVariables = {
  filter?: ModelUpdateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUpdatesQuery = {
  listUpdates?:  {
    __typename: "ModelUpdateConnection",
    items:  Array< {
      __typename: "Update",
      id: string,
      title: string,
      content: string,
      parentId: string,
      parentTitle?: string | null,
      isPinned?: boolean | null,
      metadata?: string | null,
      actionCount?: number | null,
      viewCount?: number | null,
      url?: string | null,
      images?: Array< string | null > | null,
      route?: Array< string | null > | null,
      extra?: string | null,
      type?: string | null,
      updatedAt?: number | null,
      createdAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUpdatesByDateQueryVariables = {
  parentId?: string | null,
  updatedAt?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUpdateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUpdatesByDateQuery = {
  listUpdatesByDate?:  {
    __typename: "ModelUpdateConnection",
    items:  Array< {
      __typename: "Update",
      id: string,
      title: string,
      content: string,
      parentId: string,
      parentTitle?: string | null,
      isPinned?: boolean | null,
      metadata?: string | null,
      actionCount?: number | null,
      viewCount?: number | null,
      url?: string | null,
      images?: Array< string | null > | null,
      route?: Array< string | null > | null,
      extra?: string | null,
      type?: string | null,
      updatedAt?: number | null,
      createdAt?: number | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlaceCollectionQueryVariables = {
  id: string,
};

export type GetPlaceCollectionQuery = {
  getPlaceCollection?:  {
    __typename: "PlaceCollection",
    id: string,
    userId: string,
    title?: string | null,
    description?: string | null,
    area?: string | null,
    saved?: number | null,
    shared?: number | null,
    likes?: number | null,
    venueCount?: number | null,
    headerImg?: string | null,
    videos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    views?: number | null,
    lockCriteria?: string | null,
    score?: number | null,
    areas?: Array< string | null > | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    username?: string | null,
    city?: string | null,
    cities?: Array< string | null > | null,
    status?: string | null,
    isPublic?: string | null,
    dateTime?: string | null,
    securityCode?: string | null,
    owners?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    users?:  {
      __typename: "ModelUserWishListLinkConnection",
      items:  Array< {
        __typename: "UserWishListLink",
        id: string,
        listId: string,
        userId: string,
        remminderSet?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    collectionLink?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPlaceCollectionsQueryVariables = {
  filter?: ModelPlaceCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlaceCollectionsQuery = {
  listPlaceCollections?:  {
    __typename: "ModelPlaceCollectionConnection",
    items:  Array< {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserPlaceListsQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaceCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserPlaceListsQuery = {
  listUserPlaceLists?:  {
    __typename: "ModelPlaceCollectionConnection",
    items:  Array< {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserPlaceListsCreatedAtQueryVariables = {
  userId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaceCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserPlaceListsCreatedAtQuery = {
  listUserPlaceListsCreatedAt?:  {
    __typename: "ModelPlaceCollectionConnection",
    items:  Array< {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserListsByCityQueryVariables = {
  city?: string | null,
  isPublic?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaceCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserListsByCityQuery = {
  listUserListsByCity?:  {
    __typename: "ModelPlaceCollectionConnection",
    items:  Array< {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserPlaceListByUsernameQueryVariables = {
  username?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaceCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserPlaceListByUsernameQuery = {
  userPlaceListByUsername?:  {
    __typename: "ModelPlaceCollectionConnection",
    items:  Array< {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserListsByCityScoreQueryVariables = {
  city?: string | null,
  score?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaceCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserListsByCityScoreQuery = {
  listUserListsByCityScore?:  {
    __typename: "ModelPlaceCollectionConnection",
    items:  Array< {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserListsByIsPublicQueryVariables = {
  isPublic?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPlaceCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserListsByIsPublicQuery = {
  listUserListsByIsPublic?:  {
    __typename: "ModelPlaceCollectionConnection",
    items:  Array< {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPlaceCollectionsQueryVariables = {
  filter?: SearchablePlaceCollectionFilterInput | null,
  sort?: SearchablePlaceCollectionSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchPlaceCollectionsQuery = {
  searchPlaceCollections?:  {
    __typename: "SearchablePlaceCollectionConnection",
    items:  Array< {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    cognitoUsername?: string | null,
    role?: UserRole | null,
    appVersion?: string | null,
    activated?: string | null,
    blocked?: boolean | null,
    firstName?: string | null,
    emailSettings?:  {
      __typename: "UserEmailSettings",
      transactions?: boolean | null,
      offers?: boolean | null,
      loyaltyCard?: boolean | null,
      eventInvites?: boolean | null,
      marketing?: boolean | null,
    } | null,
    lastName?: string | null,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    placesAddedToListCount?: number | null,
    bio?: string | null,
    preferredTitle?: string | null,
    country?:  {
      __typename: "Country",
      currencies?:  Array< {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null > | null,
      flag?: string | null,
      name?: string | null,
      alpha2Code?: string | null,
      alpha3Code?: string | null,
      callingCodes?: Array< string | null > | null,
      timezones?: Array< string | null > | null,
      region?: string | null,
    } | null,
    dob?: string | null,
    linkCardRewardClaimed?: string | null,
    email?: string | null,
    stripeConnectId?: string | null,
    stripeCustomerId?: string | null,
    gender?: string | null,
    phone?: string | null,
    city?: string | null,
    allowNotifications?: boolean | null,
    allowLocation?: boolean | null,
    allowPersonalisation?: boolean | null,
    allowCalendar?: boolean | null,
    profilePicture?: string | null,
    subscibedToEmails?: boolean | null,
    pushTokens?:  Array< {
      __typename: "PushToken",
      token?: string | null,
      device?: string | null,
      endpointArn?: string | null,
      parentApp?: string | null,
    } | null > | null,
    points?: number | null,
    accumulatedPoints?: number | null,
    isStudent?: boolean | null,
    lastLoggedIn?: string | null,
    locale?: string | null,
    isInfluencer?: string | null,
    summary?: string | null,
    categories?: Array< string | null > | null,
    social?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    termsSigned?: boolean | null,
    termsVersionSigned?: string | null,
    EPOSId?: number | null,
    appConfigs?: string | null,
    disabled?: boolean | null,
    username?: string | null,
    offers?:  {
      __typename: "ModelClaimedOfferConnection",
      items:  Array< {
        __typename: "ClaimedOffer",
        id: string,
        userId: string,
        offerId: string,
        fidelOfferId?: string | null,
        activated?: string | null,
        pointsCosts?: number | null,
        entityId: string,
        metadata?: string | null,
        publisherId: string,
        type?: string | null,
        ttl?: number | null,
        owners?: Array< string | null > | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        userId: string,
        bookingReference: string,
        eventId: string,
        repeatEventId: string,
        repeatEventOccurencId: string,
        eventType?: string | null,
        eventStartTimestamp?: number | null,
        chargeId?: string | null,
        paymentIntentId?: string | null,
        content?: string | null,
        ticketActivasted?: string | null,
        userName?: string | null,
        tier?: string | null,
        variation?: string | null,
        owners?: Array< string | null > | null,
        status?: string | null,
        ttl?: number | null,
        test?: string | null,
        refunded?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    linkedCards?:  Array< {
      __typename: "IUserCard",
      scheme?: string | null,
      accountId?: string | null,
      countryCode?: string | null,
      created?: string | null,
      expYear?: number | null,
      expDate?: string | null,
      live?: boolean | null,
      lastNumbers?: string | null,
      expMonth?: number | null,
      updated?: string | null,
      programId?: string | null,
      firstNumbers?: string | null,
      id?: string | null,
      type?: string | null,
    } | null > | null,
    checkIns?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    userEvents?:  {
      __typename: "ModelUserEventConnection",
      items:  Array< {
        __typename: "UserEvent",
        id: string,
        eventDate: number,
        eventId: string,
        userName?: string | null,
        repeatEventId: string,
        repeatEventOccurenceId: string,
        type?: string | null,
        userId: string,
        owners?: Array< string | null > | null,
        status?: string | null,
        checkInDate?: string | null,
        tablesBooked?: Array< string | null > | null,
        subscriptionArn?: string | null,
        plusOnesRemaining?: number | null,
        authCode?: string | null,
        subscriptionArns?: Array< string | null > | null,
        subscriptionActive?: boolean | null,
        title: string,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    loyaltyCards?:  {
      __typename: "ModelLoyaltyCardConnection",
      items:  Array< {
        __typename: "LoyaltyCard",
        id: string,
        userId: string,
        publisherId: string,
        businessTitle?: string | null,
        currentTier?: string | null,
        entityId?: string | null,
        visits?: number | null,
        businessAssignedTier?: string | null,
        transactions?: number | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        totalSpend?: number | null,
        pendingPoints?: number | null,
        totalPoints?: number | null,
        ownerType?: string | null,
        status?: string | null,
        userFullName?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        totalSpendablePoints?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tableBookings?:  {
      __typename: "ModelTableBookingRequestConnection",
      items:  Array< {
        __typename: "TableBookingRequest",
        id: string,
        userName?: string | null,
        userId: string,
        paid?: boolean | null,
        amountPaid?: number | null,
        eventId: string,
        status?: string | null,
        owners?: Array< string | null > | null,
        tableId: string,
        message?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    lists?:  {
      __typename: "ModelUserWishListLinkConnection",
      items:  Array< {
        __typename: "UserWishListLink",
        id: string,
        listId: string,
        userId: string,
        remminderSet?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscriptions?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserByUsernameQueryVariables = {
  username?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserByUsernameQuery = {
  getUserByUsername?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInfluencerUsersQueryVariables = {
  isInfluencer?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetInfluencerUsersQuery = {
  getInfluencerUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchUsersQueryVariables = {
  filter?: SearchableUserFilterInput | null,
  sort?: SearchableUserSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchUsersQuery = {
  searchUsers?:  {
    __typename: "SearchableUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetSubscriberQueryVariables = {
  id: string,
};

export type GetSubscriberQuery = {
  getSubscriber?:  {
    __typename: "Subscriber",
    id: string,
    userId: string,
    entityId: string,
    publisherId: string,
    userFullName?: string | null,
    counters?:  {
      __typename: "FollowerCounters",
      bookings?: number | null,
      offersRedeemed?: number | null,
      checkins?: number | null,
    } | null,
    subscriptionArn?: string | null,
    subscriptionArns?: Array< string | null > | null,
    notificationsEnabled?: boolean | null,
    credit?: number | null,
    loyaltyPoints?: number | null,
    loyaltyStatus?: string | null,
    EPOSId?: string | null,
    type?: string | null,
    status: string,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSubscribersQueryVariables = {
  filter?: ModelSubscriberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubscribersQuery = {
  listSubscribers?:  {
    __typename: "ModelSubscriberConnection",
    items:  Array< {
      __typename: "Subscriber",
      id: string,
      userId: string,
      entityId: string,
      publisherId: string,
      userFullName?: string | null,
      counters?:  {
        __typename: "FollowerCounters",
        bookings?: number | null,
        offersRedeemed?: number | null,
        checkins?: number | null,
      } | null,
      subscriptionArn?: string | null,
      subscriptionArns?: Array< string | null > | null,
      notificationsEnabled?: boolean | null,
      credit?: number | null,
      loyaltyPoints?: number | null,
      loyaltyStatus?: string | null,
      EPOSId?: string | null,
      type?: string | null,
      status: string,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLoyaltyUsersFollowerKeyQueryVariables = {
  publisherId?: string | null,
  loyaltyStatus?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubscriberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLoyaltyUsersFollowerKeyQuery = {
  listLoyaltyUsersFollowerKey?:  {
    __typename: "ModelSubscriberConnection",
    items:  Array< {
      __typename: "Subscriber",
      id: string,
      userId: string,
      entityId: string,
      publisherId: string,
      userFullName?: string | null,
      counters?:  {
        __typename: "FollowerCounters",
        bookings?: number | null,
        offersRedeemed?: number | null,
        checkins?: number | null,
      } | null,
      subscriptionArn?: string | null,
      subscriptionArns?: Array< string | null > | null,
      notificationsEnabled?: boolean | null,
      credit?: number | null,
      loyaltyPoints?: number | null,
      loyaltyStatus?: string | null,
      EPOSId?: string | null,
      type?: string | null,
      status: string,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchSubscribersQueryVariables = {
  filter?: SearchableSubscriberFilterInput | null,
  sort?: SearchableSubscriberSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchSubscribersQuery = {
  searchSubscribers?:  {
    __typename: "SearchableSubscriberConnection",
    items:  Array< {
      __typename: "Subscriber",
      id: string,
      userId: string,
      entityId: string,
      publisherId: string,
      userFullName?: string | null,
      counters?:  {
        __typename: "FollowerCounters",
        bookings?: number | null,
        offersRedeemed?: number | null,
        checkins?: number | null,
      } | null,
      subscriptionArn?: string | null,
      subscriptionArns?: Array< string | null > | null,
      notificationsEnabled?: boolean | null,
      credit?: number | null,
      loyaltyPoints?: number | null,
      loyaltyStatus?: string | null,
      EPOSId?: string | null,
      type?: string | null,
      status: string,
      createdAt: number,
      updatedAt: number,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  owners: string,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    messageConversationId: string,
    conversation?:  {
      __typename: "ConvoLink",
      id: string,
      status?: string | null,
      createdAt: number,
      updatedAt: number,
      owners: Array< string | null >,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      publisherId: string,
      entityId: string,
      userId: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null,
    content: string,
    contentType?: string | null,
    author: string,
    entity: string,
    receiver: string,
    owners: Array< string | null >,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  owners: string,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    messageConversationId: string,
    conversation?:  {
      __typename: "ConvoLink",
      id: string,
      status?: string | null,
      createdAt: number,
      updatedAt: number,
      owners: Array< string | null >,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      publisherId: string,
      entityId: string,
      userId: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null,
    content: string,
    contentType?: string | null,
    author: string,
    entity: string,
    receiver: string,
    owners: Array< string | null >,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  owners: string,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    messageConversationId: string,
    conversation?:  {
      __typename: "ConvoLink",
      id: string,
      status?: string | null,
      createdAt: number,
      updatedAt: number,
      owners: Array< string | null >,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      publisherId: string,
      entityId: string,
      userId: string,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUsername?: string | null,
        role?: UserRole | null,
        appVersion?: string | null,
        activated?: string | null,
        blocked?: boolean | null,
        firstName?: string | null,
        lastName?: string | null,
        placesAddedToListCount?: number | null,
        bio?: string | null,
        preferredTitle?: string | null,
        dob?: string | null,
        linkCardRewardClaimed?: string | null,
        email?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        gender?: string | null,
        phone?: string | null,
        city?: string | null,
        allowNotifications?: boolean | null,
        allowLocation?: boolean | null,
        allowPersonalisation?: boolean | null,
        allowCalendar?: boolean | null,
        profilePicture?: string | null,
        subscibedToEmails?: boolean | null,
        points?: number | null,
        accumulatedPoints?: number | null,
        isStudent?: boolean | null,
        lastLoggedIn?: string | null,
        locale?: string | null,
        isInfluencer?: string | null,
        summary?: string | null,
        categories?: Array< string | null > | null,
        createdAt: number,
        updatedAt: number,
        termsSigned?: boolean | null,
        termsVersionSigned?: string | null,
        EPOSId?: number | null,
        appConfigs?: string | null,
        disabled?: boolean | null,
        username?: string | null,
      } | null,
    } | null,
    content: string,
    contentType?: string | null,
    author: string,
    entity: string,
    receiver: string,
    owners: Array< string | null >,
    status?: string | null,
    createdAt: number,
    updatedAt: number,
  } | null,
};

export type OnCreateTicketSubscriptionVariables = {
  owners?: string | null,
};

export type OnCreateTicketSubscription = {
  onCreateTicket?:  {
    __typename: "Ticket",
    id: string,
    userId: string,
    bookingReference: string,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    eventType?: string | null,
    eventStartTimestamp?: number | null,
    price?:  {
      __typename: "TicketPrice",
      price?: number | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
    } | null,
    chargeId?: string | null,
    paymentIntentId?: string | null,
    content?: string | null,
    ticketActivasted?: string | null,
    userName?: string | null,
    additionalOptions?:  Array< {
      __typename: "TicketChosenOption",
      title?: string | null,
      chosenOption?: string | null,
    } | null > | null,
    tier?: string | null,
    variation?: string | null,
    owners?: Array< string | null > | null,
    status?: string | null,
    ttl?: number | null,
    test?: string | null,
    refunded?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEventOccurence?:  {
      __typename: "RepeatEventOccurence",
      id: string,
      repeatEventId: string,
      startDate?: string | null,
      endDate?: string | null,
      owners?: Array< string | null > | null,
      isSoldOut?: boolean | null,
      totalTicketsSold?: number | null,
      canBuyTickets?: boolean | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateTicketSubscriptionVariables = {
  owners?: string | null,
};

export type OnUpdateTicketSubscription = {
  onUpdateTicket?:  {
    __typename: "Ticket",
    id: string,
    userId: string,
    bookingReference: string,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    eventType?: string | null,
    eventStartTimestamp?: number | null,
    price?:  {
      __typename: "TicketPrice",
      price?: number | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
    } | null,
    chargeId?: string | null,
    paymentIntentId?: string | null,
    content?: string | null,
    ticketActivasted?: string | null,
    userName?: string | null,
    additionalOptions?:  Array< {
      __typename: "TicketChosenOption",
      title?: string | null,
      chosenOption?: string | null,
    } | null > | null,
    tier?: string | null,
    variation?: string | null,
    owners?: Array< string | null > | null,
    status?: string | null,
    ttl?: number | null,
    test?: string | null,
    refunded?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEventOccurence?:  {
      __typename: "RepeatEventOccurence",
      id: string,
      repeatEventId: string,
      startDate?: string | null,
      endDate?: string | null,
      owners?: Array< string | null > | null,
      isSoldOut?: boolean | null,
      totalTicketsSold?: number | null,
      canBuyTickets?: boolean | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteTicketSubscriptionVariables = {
  owners?: string | null,
};

export type OnDeleteTicketSubscription = {
  onDeleteTicket?:  {
    __typename: "Ticket",
    id: string,
    userId: string,
    bookingReference: string,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    eventType?: string | null,
    eventStartTimestamp?: number | null,
    price?:  {
      __typename: "TicketPrice",
      price?: number | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
    } | null,
    chargeId?: string | null,
    paymentIntentId?: string | null,
    content?: string | null,
    ticketActivasted?: string | null,
    userName?: string | null,
    additionalOptions?:  Array< {
      __typename: "TicketChosenOption",
      title?: string | null,
      chosenOption?: string | null,
    } | null > | null,
    tier?: string | null,
    variation?: string | null,
    owners?: Array< string | null > | null,
    status?: string | null,
    ttl?: number | null,
    test?: string | null,
    refunded?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEvent?:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    repeatEventOccurence?:  {
      __typename: "RepeatEventOccurence",
      id: string,
      repeatEventId: string,
      startDate?: string | null,
      endDate?: string | null,
      owners?: Array< string | null > | null,
      isSoldOut?: boolean | null,
      totalTicketsSold?: number | null,
      canBuyTickets?: boolean | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      repeatEvent?:  {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateBlogPostEntitySubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnCreateBlogPostEntitySubscription = {
  onCreateBlogPostEntity?:  {
    __typename: "BlogPostEntity",
    id: string,
    entityId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateBlogPostEntitySubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnUpdateBlogPostEntitySubscription = {
  onUpdateBlogPostEntity?:  {
    __typename: "BlogPostEntity",
    id: string,
    entityId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteBlogPostEntitySubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnDeleteBlogPostEntitySubscription = {
  onDeleteBlogPostEntity?:  {
    __typename: "BlogPostEntity",
    id: string,
    entityId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateBlogPostEventSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnCreateBlogPostEventSubscription = {
  onCreateBlogPostEvent?:  {
    __typename: "BlogPostEvent",
    id: string,
    eventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateBlogPostEventSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnUpdateBlogPostEventSubscription = {
  onUpdateBlogPostEvent?:  {
    __typename: "BlogPostEvent",
    id: string,
    eventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteBlogPostEventSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnDeleteBlogPostEventSubscription = {
  onDeleteBlogPostEvent?:  {
    __typename: "BlogPostEvent",
    id: string,
    eventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      title: string,
      entityId: string,
      logo?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      area?: string | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      categories?: Array< string | null > | null,
      dateTime?: number | null,
      startDate?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      endDate?: string | null,
      closingTime?: number | null,
      rules?: string | null,
      discount?: number | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      isOnlineEvent?: boolean | null,
      featured?: string | null,
      video?: string | null,
      images?: Array< string | null > | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      artistsList?: Array< string | null > | null,
      mapImages?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      city: string,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      attending?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      promoterIds?: Array< string | null > | null,
      ttl?: number | null,
      views?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      status?: string | null,
      owner?: string | null,
      owners?: Array< string | null > | null,
      pushTimestamps?: Array< number | null > | null,
      ownerType?: string | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      tablesAvailable?: number | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      username?: string | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      variations?:  {
        __typename: "ModelTicketVariationOccurenceConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateBlogPostRepeatEventSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnCreateBlogPostRepeatEventSubscription = {
  onCreateBlogPostRepeatEvent?:  {
    __typename: "BlogPostRepeatEvent",
    id: string,
    repeatEventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    repeatEvent:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateBlogPostRepeatEventSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnUpdateBlogPostRepeatEventSubscription = {
  onUpdateBlogPostRepeatEvent?:  {
    __typename: "BlogPostRepeatEvent",
    id: string,
    repeatEventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    repeatEvent:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteBlogPostRepeatEventSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnDeleteBlogPostRepeatEventSubscription = {
  onDeleteBlogPostRepeatEvent?:  {
    __typename: "BlogPostRepeatEvent",
    id: string,
    repeatEventId: string,
    blogPostId: string,
    summary?: string | null,
    title?: string | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
    blogPost:  {
      __typename: "BlogPost",
      id: string,
      postType?: string | null,
      title?: string | null,
      desc?: string | null,
      city: string,
      area?: string | null,
      catgories?: Array< string | null > | null,
      headerImg?: string | null,
      readCount?: number | null,
      shareCount?: number | null,
      metaDesc?: string | null,
      metaTitle?: string | null,
      videoUrl?: string | null,
      instagram?: string | null,
      twitter?: string | null,
      website?: string | null,
      facebook?: string | null,
      userId?: string | null,
      publishStatus?: string | null,
      owners?: Array< string | null > | null,
      ttl?: number | null,
      content?:  Array< {
        __typename: "BlogSectionContent",
        title?: string | null,
        image?: string | null,
        content?: string | null,
      } | null > | null,
      summary?:  Array< {
        __typename: "BlogSummary",
        title?: string | null,
        content?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      entities?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelBlogPostEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      user?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
    },
    owner?: string | null,
    repeatEvent:  {
      __typename: "RepeatEvent",
      id: string,
      title: string,
      entityId: string,
      area?: string | null,
      headerVideos?: Array< string | null > | null,
      publisherId: string,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      logo?: string | null,
      subtitle?: string | null,
      description?: string | null,
      type?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      discount?: number | null,
      isOnlineEvent?: boolean | null,
      score?: number | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      eventFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      closingTime?: number | null,
      dayOfWeek?: number | null,
      daysOfWeek?: Array< number | null > | null,
      startTime?: string | null,
      endTime?: string | null,
      showWeather?: boolean | null,
      buyTicketUrl?: string | null,
      canBook?: boolean | null,
      ttl?: number | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      mapImages?: Array< string | null > | null,
      video?: string | null,
      images?: Array< string | null > | null,
      artistsList?: Array< string | null > | null,
      featured?: string | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      firstEventDate?: string | null,
      lastEventDate?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      price?: string | null,
      rules?: string | null,
      city?: string | null,
      attending?: number | null,
      status: string,
      ownerType?: string | null,
      owner?: string | null,
      pushTimestamps?: Array< number | null > | null,
      promoterIds?: Array< string | null > | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      owners?: Array< string | null > | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      occurencesRules?:  Array< {
        __typename: "OccurencesRules",
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        typeOfRepeat?: string | null,
        repeatInterval?: number | null,
        daysOfWeek?: Array< number | null > | null,
        daysOfMonth?: Array< number | null > | null,
        weeksOfMonth?: Array< number | null > | null,
        weeksDaysOfMonth?: Array< number | null > | null,
        occurenceTotalRepeat?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        usesEntityTables?: boolean | null,
        quantity?: number | null,
      } | null > | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      username?: string | null,
      influencerInfo?:  {
        __typename: "EventInfluencerInfo",
        hashtags?: Array< string | null > | null,
        tags?: Array< string | null > | null,
        details?: string | null,
      } | null,
      config?:  {
        __typename: "EventConfig",
        canBuyTickets?: boolean | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      blogPosts?:  {
        __typename: "ModelBlogPostRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      occurences?:  {
        __typename: "ModelRepeatEventOccurenceConnection",
        nextToken?: string | null,
      } | null,
      attendees?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      entity?:  {
        __typename: "Entity",
        id: string,
        title: string,
        shortUrl?: string | null,
        subtitle?: string | null,
        lastAdminCheck?: string | null,
        area?: string | null,
        notes?: string | null,
        summary?: string | null,
        uniqueAspect?: string | null,
        tags?: Array< string | null > | null,
        nickname?: string | null,
        hasEmailed?: boolean | null,
        firstName?: string | null,
        hasStudentDiscount?: boolean | null,
        LastName?: string | null,
        outdoorArea?: boolean | null,
        outdoorHeating?: boolean | null,
        description?: string | null,
        stripeSubscriptionItemId?: string | null,
        emojiSummary?: Array< string | null > | null,
        categories?: Array< string | null > | null,
        phone?: string | null,
        internationalPhoneNumber?: string | null,
        logo?: string | null,
        pushTimestamps?: Array< number | null > | null,
        websiteUrl?: string | null,
        orderfromTableUrl?: string | null,
        entityTitle?: string | null,
        opens?: number | null,
        closes?: number | null,
        asFeaturedOn?: Array< string | null > | null,
        username?: string | null,
        trendingScore?: number | null,
        status: string,
        virtualTourUrl?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        video?: string | null,
        headerVideos?: Array< string | null > | null,
        city: string,
        rules?: string | null,
        position?: number | null,
        score?: number | null,
        score_before_boost?: number | null,
        boost_multiplier?: number | null,
        boost_until?: string | null,
        boostPurchaseId?: string | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        canMessage?: boolean | null,
        forceupdate?: boolean | null,
        email?: string | null,
        menuImages?: Array< string | null > | null,
        price?: string | null,
        featured?: string | null,
        cuisines?: Array< string | null > | null,
        dietryOptions?: Array< string | null > | null,
        verified?: boolean | null,
        qrIds?: Array< string | null > | null,
        publisherId: string,
        subscribeOfferId?: string | null,
        subscriberCount?: number | null,
        viewCount?: number | null,
        bookCount?: number | null,
        shareCount?: number | null,
        checkInCount?: number | null,
        addToListCount?: number | null,
        pushTimestamp?: number | null,
        images?: Array< string | null > | null,
        minBookingLimit?: number | null,
        maxBookingLimit?: number | null,
        bookingUrl?: string | null,
        createdAt: number,
        offersArray?: Array< string | null > | null,
        whatsappNumber?: string | null,
        updatedAt: number,
        owner?: string | null,
        capacity?: number | null,
        acceptWalkIns?: boolean | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCouponSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnCreateCouponSubscription = {
  onCreateCoupon?:  {
    __typename: "Coupon",
    id: string,
    code: string,
    discountType?: string | null,
    discountAmount?: number | null,
    status?: string | null,
    publisherId: string,
    eventType?: string | null,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCouponSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnUpdateCouponSubscription = {
  onUpdateCoupon?:  {
    __typename: "Coupon",
    id: string,
    code: string,
    discountType?: string | null,
    discountAmount?: number | null,
    status?: string | null,
    publisherId: string,
    eventType?: string | null,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCouponSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnDeleteCouponSubscription = {
  onDeleteCoupon?:  {
    __typename: "Coupon",
    id: string,
    code: string,
    discountType?: string | null,
    discountAmount?: number | null,
    status?: string | null,
    publisherId: string,
    eventType?: string | null,
    eventId: string,
    repeatEventId: string,
    repeatEventOccurencId: string,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateEntitySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateEntitySubscription = {
  onCreateEntity?:  {
    __typename: "Entity",
    id: string,
    title: string,
    shortUrl?: string | null,
    subtitle?: string | null,
    lastAdminCheck?: string | null,
    area?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    notes?: string | null,
    summary?: string | null,
    uniqueAspect?: string | null,
    tags?: Array< string | null > | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    nickname?: string | null,
    hasEmailed?: boolean | null,
    firstName?: string | null,
    hasStudentDiscount?: boolean | null,
    LastName?: string | null,
    config?:  {
      __typename: "EntityConfig",
      loyaltyEnabled?: boolean | null,
      canMessage?: boolean | null,
      canBook?: boolean | null,
      canCheckin?: boolean | null,
      canCall?: boolean | null,
      canEmail?: boolean | null,
      autoResponseMessage?: string | null,
      enterVenueCheckInEnabled?: boolean | null,
      checkInMessage?: string | null,
      colorTheme?: string | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
      bookingConfig?:  {
        __typename: "EntityBookingConfig",
        type?: string | null,
        bookingProvider?: EntityBookingProvider | null,
        bookingProviderBackup?: string | null,
        bookingProviderUsername?: string | null,
        bookingProviderUsernameBackup?: string | null,
        canAutoBook?: boolean | null,
        notes?: string | null,
        bookingDurationMins?: number | null,
        bookingEmails?: Array< string | null > | null,
        bookingPhoneNumber?: string | null,
        areas?: Array< string | null > | null,
      } | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      enableTrackTrace?: boolean | null,
    } | null,
    nearbyStations?:  Array< {
      __typename: "EntityClosestStation",
      title?: string | null,
      distance?: number | null,
      line?: string | null,
      zone?: number | null,
      type?: string | null,
    } | null > | null,
    outdoorArea?: boolean | null,
    outdoorHeating?: boolean | null,
    description?: string | null,
    stripeSubscriptionItemId?: string | null,
    emojiSummary?: Array< string | null > | null,
    categories?: Array< string | null > | null,
    phone?: string | null,
    internationalPhoneNumber?: string | null,
    logo?: string | null,
    pushTimestamps?: Array< number | null > | null,
    websiteUrl?: string | null,
    orderfromTableUrl?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    entityTitle?: string | null,
    opens?: number | null,
    closes?: number | null,
    asFeaturedOn?: Array< string | null > | null,
    username?: string | null,
    trendingScore?: number | null,
    openHours?:  {
      __typename: "DailyOpeningTimes",
      monday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      tuesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      wednesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      thursday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      friday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      saturday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      sunday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
    } | null,
    status: string,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    virtualTourUrl?: string | null,
    emailsDisabled?: boolean | null,
    reviewsDisabled?: boolean | null,
    video?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    headerVideos?: Array< string | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    city: string,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    entityFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    rules?: string | null,
    position?: number | null,
    score?: number | null,
    score_before_boost?: number | null,
    boost_multiplier?: number | null,
    boost_until?: string | null,
    boostPurchaseId?: string | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    canMessage?: boolean | null,
    forceupdate?: boolean | null,
    email?: string | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    price?: string | null,
    featured?: string | null,
    cuisines?: Array< string | null > | null,
    dietryOptions?: Array< string | null > | null,
    foodDeliveryAppLinks?:  Array< {
      __typename: "FoodDeliveryAppLink",
      title?: string | null,
      icon?: string | null,
      url?: string | null,
    } | null > | null,
    verified?: boolean | null,
    qrIds?: Array< string | null > | null,
    publisherId: string,
    subscribeOfferId?: string | null,
    subscriberCount?: number | null,
    viewCount?: number | null,
    bookCount?: number | null,
    shareCount?: number | null,
    checkInCount?: number | null,
    addToListCount?: number | null,
    pushTimestamp?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    images?: Array< string | null > | null,
    googleImages?:  Array< {
      __typename: "GoogleImage",
      html_attributions?: Array< string | null > | null,
      image?: string | null,
    } | null > | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    minBookingLimit?: number | null,
    maxBookingLimit?: number | null,
    bookingUrl?: string | null,
    createdAt: number,
    offersArray?: Array< string | null > | null,
    whatsappNumber?: string | null,
    updatedAt: number,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    owner?: string | null,
    reviewScores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    safetyChecklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    venueSafetyData?:  {
      __typename: "VenueSafetyData",
      list?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      links?: Array< string | null > | null,
      updated?: string | null,
    } | null,
    capacity?: number | null,
    acceptWalkIns?: boolean | null,
    covidMeasures?:  {
      __typename: "CovidMeasures",
      capacityPercentage?: number | null,
      tableLimit?: number | null,
      masksRequired?: boolean | null,
      bookingsRequired?: boolean | null,
      notes?: string | null,
    } | null,
    mediaChannels?:  {
      __typename: "MediaChannels",
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      amazonMusicUrl?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    pingReview?:  {
      __typename: "PingReview",
      score?: number | null,
      title?: string | null,
      review?: string | null,
      createdAt?: string | null,
      reviewer?: string | null,
      reviewerPhoto?: string | null,
    } | null,
    pingAwards?:  Array< {
      __typename: "PingAward",
      date?: string | null,
      type?: string | null,
    } | null > | null,
    blogPosts?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bookingTables?:  {
      __typename: "ModelBookableTableConnection",
      items:  Array< {
        __typename: "BookableTable",
        id: string,
        tableNumber?: number | null,
        capacity?: number | null,
        area?: string | null,
        entityId: string,
        ttl?: number | null,
        maxPeople?: number | null,
        minPeople?: number | null,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dailyPromotions?:  {
      __typename: "ModelDailyPromotionsConnection",
      items:  Array< {
        __typename: "DailyPromotions",
        id: string,
        entityId: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        description?: string | null,
        price?: number | null,
        images?: Array< string | null > | null,
        title?: string | null,
        status?: string | null,
        disclaimer?: string | null,
        owner?: string | null,
        subscribersOnly?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    collection?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateEntitySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateEntitySubscription = {
  onUpdateEntity?:  {
    __typename: "Entity",
    id: string,
    title: string,
    shortUrl?: string | null,
    subtitle?: string | null,
    lastAdminCheck?: string | null,
    area?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    notes?: string | null,
    summary?: string | null,
    uniqueAspect?: string | null,
    tags?: Array< string | null > | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    nickname?: string | null,
    hasEmailed?: boolean | null,
    firstName?: string | null,
    hasStudentDiscount?: boolean | null,
    LastName?: string | null,
    config?:  {
      __typename: "EntityConfig",
      loyaltyEnabled?: boolean | null,
      canMessage?: boolean | null,
      canBook?: boolean | null,
      canCheckin?: boolean | null,
      canCall?: boolean | null,
      canEmail?: boolean | null,
      autoResponseMessage?: string | null,
      enterVenueCheckInEnabled?: boolean | null,
      checkInMessage?: string | null,
      colorTheme?: string | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
      bookingConfig?:  {
        __typename: "EntityBookingConfig",
        type?: string | null,
        bookingProvider?: EntityBookingProvider | null,
        bookingProviderBackup?: string | null,
        bookingProviderUsername?: string | null,
        bookingProviderUsernameBackup?: string | null,
        canAutoBook?: boolean | null,
        notes?: string | null,
        bookingDurationMins?: number | null,
        bookingEmails?: Array< string | null > | null,
        bookingPhoneNumber?: string | null,
        areas?: Array< string | null > | null,
      } | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      enableTrackTrace?: boolean | null,
    } | null,
    nearbyStations?:  Array< {
      __typename: "EntityClosestStation",
      title?: string | null,
      distance?: number | null,
      line?: string | null,
      zone?: number | null,
      type?: string | null,
    } | null > | null,
    outdoorArea?: boolean | null,
    outdoorHeating?: boolean | null,
    description?: string | null,
    stripeSubscriptionItemId?: string | null,
    emojiSummary?: Array< string | null > | null,
    categories?: Array< string | null > | null,
    phone?: string | null,
    internationalPhoneNumber?: string | null,
    logo?: string | null,
    pushTimestamps?: Array< number | null > | null,
    websiteUrl?: string | null,
    orderfromTableUrl?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    entityTitle?: string | null,
    opens?: number | null,
    closes?: number | null,
    asFeaturedOn?: Array< string | null > | null,
    username?: string | null,
    trendingScore?: number | null,
    openHours?:  {
      __typename: "DailyOpeningTimes",
      monday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      tuesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      wednesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      thursday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      friday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      saturday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      sunday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
    } | null,
    status: string,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    virtualTourUrl?: string | null,
    emailsDisabled?: boolean | null,
    reviewsDisabled?: boolean | null,
    video?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    headerVideos?: Array< string | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    city: string,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    entityFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    rules?: string | null,
    position?: number | null,
    score?: number | null,
    score_before_boost?: number | null,
    boost_multiplier?: number | null,
    boost_until?: string | null,
    boostPurchaseId?: string | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    canMessage?: boolean | null,
    forceupdate?: boolean | null,
    email?: string | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    price?: string | null,
    featured?: string | null,
    cuisines?: Array< string | null > | null,
    dietryOptions?: Array< string | null > | null,
    foodDeliveryAppLinks?:  Array< {
      __typename: "FoodDeliveryAppLink",
      title?: string | null,
      icon?: string | null,
      url?: string | null,
    } | null > | null,
    verified?: boolean | null,
    qrIds?: Array< string | null > | null,
    publisherId: string,
    subscribeOfferId?: string | null,
    subscriberCount?: number | null,
    viewCount?: number | null,
    bookCount?: number | null,
    shareCount?: number | null,
    checkInCount?: number | null,
    addToListCount?: number | null,
    pushTimestamp?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    images?: Array< string | null > | null,
    googleImages?:  Array< {
      __typename: "GoogleImage",
      html_attributions?: Array< string | null > | null,
      image?: string | null,
    } | null > | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    minBookingLimit?: number | null,
    maxBookingLimit?: number | null,
    bookingUrl?: string | null,
    createdAt: number,
    offersArray?: Array< string | null > | null,
    whatsappNumber?: string | null,
    updatedAt: number,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    owner?: string | null,
    reviewScores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    safetyChecklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    venueSafetyData?:  {
      __typename: "VenueSafetyData",
      list?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      links?: Array< string | null > | null,
      updated?: string | null,
    } | null,
    capacity?: number | null,
    acceptWalkIns?: boolean | null,
    covidMeasures?:  {
      __typename: "CovidMeasures",
      capacityPercentage?: number | null,
      tableLimit?: number | null,
      masksRequired?: boolean | null,
      bookingsRequired?: boolean | null,
      notes?: string | null,
    } | null,
    mediaChannels?:  {
      __typename: "MediaChannels",
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      amazonMusicUrl?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    pingReview?:  {
      __typename: "PingReview",
      score?: number | null,
      title?: string | null,
      review?: string | null,
      createdAt?: string | null,
      reviewer?: string | null,
      reviewerPhoto?: string | null,
    } | null,
    pingAwards?:  Array< {
      __typename: "PingAward",
      date?: string | null,
      type?: string | null,
    } | null > | null,
    blogPosts?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bookingTables?:  {
      __typename: "ModelBookableTableConnection",
      items:  Array< {
        __typename: "BookableTable",
        id: string,
        tableNumber?: number | null,
        capacity?: number | null,
        area?: string | null,
        entityId: string,
        ttl?: number | null,
        maxPeople?: number | null,
        minPeople?: number | null,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dailyPromotions?:  {
      __typename: "ModelDailyPromotionsConnection",
      items:  Array< {
        __typename: "DailyPromotions",
        id: string,
        entityId: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        description?: string | null,
        price?: number | null,
        images?: Array< string | null > | null,
        title?: string | null,
        status?: string | null,
        disclaimer?: string | null,
        owner?: string | null,
        subscribersOnly?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    collection?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteEntitySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteEntitySubscription = {
  onDeleteEntity?:  {
    __typename: "Entity",
    id: string,
    title: string,
    shortUrl?: string | null,
    subtitle?: string | null,
    lastAdminCheck?: string | null,
    area?: string | null,
    contacts?:  Array< {
      __typename: "ContactInfo",
      title?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      venue?: string | null,
      email?: string | null,
      phone?: string | null,
      position?: string | null,
      notes?: string | null,
    } | null > | null,
    notes?: string | null,
    summary?: string | null,
    uniqueAspect?: string | null,
    tags?: Array< string | null > | null,
    highlights?:  Array< {
      __typename: "PlaceHighlight",
      title?: string | null,
      image?: string | null,
      highlights?:  Array< {
        __typename: "PlaceHighlightItem",
        title?: string | null,
        videoUrl?: string | null,
        thumbnail?: string | null,
        createdAt?: string | null,
        isInfluencer?: boolean | null,
        duration?: number | null,
        userName?: string | null,
        userId?: string | null,
      } | null > | null,
      order?: number | null,
    } | null > | null,
    nickname?: string | null,
    hasEmailed?: boolean | null,
    firstName?: string | null,
    hasStudentDiscount?: boolean | null,
    LastName?: string | null,
    config?:  {
      __typename: "EntityConfig",
      loyaltyEnabled?: boolean | null,
      canMessage?: boolean | null,
      canBook?: boolean | null,
      canCheckin?: boolean | null,
      canCall?: boolean | null,
      canEmail?: boolean | null,
      autoResponseMessage?: string | null,
      enterVenueCheckInEnabled?: boolean | null,
      checkInMessage?: string | null,
      colorTheme?: string | null,
      colourScheme?:  {
        __typename: "EntityColourScheme",
        primary?: string | null,
        secondary?: string | null,
        text?: string | null,
        buttons?: Array< string | null > | null,
        backgrounds?: Array< string | null > | null,
      } | null,
      bookingConfig?:  {
        __typename: "EntityBookingConfig",
        type?: string | null,
        bookingProvider?: EntityBookingProvider | null,
        bookingProviderBackup?: string | null,
        bookingProviderUsername?: string | null,
        bookingProviderUsernameBackup?: string | null,
        canAutoBook?: boolean | null,
        notes?: string | null,
        bookingDurationMins?: number | null,
        bookingEmails?: Array< string | null > | null,
        bookingPhoneNumber?: string | null,
        areas?: Array< string | null > | null,
      } | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      enableTrackTrace?: boolean | null,
    } | null,
    nearbyStations?:  Array< {
      __typename: "EntityClosestStation",
      title?: string | null,
      distance?: number | null,
      line?: string | null,
      zone?: number | null,
      type?: string | null,
    } | null > | null,
    outdoorArea?: boolean | null,
    outdoorHeating?: boolean | null,
    description?: string | null,
    stripeSubscriptionItemId?: string | null,
    emojiSummary?: Array< string | null > | null,
    categories?: Array< string | null > | null,
    phone?: string | null,
    internationalPhoneNumber?: string | null,
    logo?: string | null,
    pushTimestamps?: Array< number | null > | null,
    websiteUrl?: string | null,
    orderfromTableUrl?: string | null,
    location?:  {
      __typename: "Location",
      lat?: number | null,
      lon?: number | null,
    } | null,
    entityTitle?: string | null,
    opens?: number | null,
    closes?: number | null,
    asFeaturedOn?: Array< string | null > | null,
    username?: string | null,
    trendingScore?: number | null,
    openHours?:  {
      __typename: "DailyOpeningTimes",
      monday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      tuesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      wednesday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      thursday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      friday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      saturday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
      sunday?:  {
        __typename: "OpenHours",
        open?: number | null,
        close?: number | null,
        fullDay?: boolean | null,
        closed?: boolean | null,
        vibe?: string | null,
        walkInWaitTimeInMins?: number | null,
        perfectFor?: Array< string | null > | null,
      } | null,
    } | null,
    status: string,
    address?:  {
      __typename: "Address",
      title?: string | null,
      streetNameNumber?: string | null,
      addressLine1?: string | null,
      addressLine2?: string | null,
      addressLine3?: string | null,
      city?: string | null,
      province?: string | null,
      country?: string | null,
      postcode?: string | null,
    } | null,
    virtualTourUrl?: string | null,
    emailsDisabled?: boolean | null,
    reviewsDisabled?: boolean | null,
    video?: string | null,
    pageVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    cardVideos?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    sponsors?:  Array< {
      __typename: "Sponsor",
      id?: string | null,
      title?: string | null,
      logo?: string | null,
      desc?: string | null,
    } | null > | null,
    headerVideos?: Array< string | null > | null,
    videoData?:  Array< {
      __typename: "VideoData",
      state?: string | null,
      key?: string | null,
      thumbnail?: string | null,
      duration?: number | null,
      updatedAt?: string | null,
      types?: Array< string | null > | null,
    } | null > | null,
    city: string,
    currency?:  {
      __typename: "Currency",
      code?: string | null,
      name?: string | null,
      symbol?: string | null,
    } | null,
    features?:  Array< {
      __typename: "Feature",
      title?: string | null,
      icon?: Array< string | null > | null,
    } | null > | null,
    entityFeatures?:  Array< {
      __typename: "EntityFeature",
      images?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      bookUrl?: string | null,
      status?: string | null,
    } | null > | null,
    rules?: string | null,
    position?: number | null,
    score?: number | null,
    score_before_boost?: number | null,
    boost_multiplier?: number | null,
    boost_until?: string | null,
    boostPurchaseId?: string | null,
    reviewRating?: number | null,
    reviewCount?: number | null,
    canMessage?: boolean | null,
    forceupdate?: boolean | null,
    email?: string | null,
    menuUrlList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuPDFList?:  Array< {
      __typename: "UrlType",
      title?: string | null,
      type?: string | null,
      url?: string | null,
    } | null > | null,
    menuImages?: Array< string | null > | null,
    menu?:  {
      __typename: "Menu",
      id: string,
      sections?:  Array< {
        __typename: "MenuSection",
        id: string,
        description?: string | null,
        title?: string | null,
        type?: string | null,
        icon?: Array< string | null > | null,
        status?: string | null,
      } | null > | null,
      title?: string | null,
      status: string,
    } | null,
    price?: string | null,
    featured?: string | null,
    cuisines?: Array< string | null > | null,
    dietryOptions?: Array< string | null > | null,
    foodDeliveryAppLinks?:  Array< {
      __typename: "FoodDeliveryAppLink",
      title?: string | null,
      icon?: string | null,
      url?: string | null,
    } | null > | null,
    verified?: boolean | null,
    qrIds?: Array< string | null > | null,
    publisherId: string,
    subscribeOfferId?: string | null,
    subscriberCount?: number | null,
    viewCount?: number | null,
    bookCount?: number | null,
    shareCount?: number | null,
    checkInCount?: number | null,
    addToListCount?: number | null,
    pushTimestamp?: number | null,
    extraInfo?:  Array< {
      __typename: "AccordionData",
      title?: string | null,
      content?: string | null,
      url?: string | null,
    } | null > | null,
    images?: Array< string | null > | null,
    googleImages?:  Array< {
      __typename: "GoogleImage",
      html_attributions?: Array< string | null > | null,
      image?: string | null,
    } | null > | null,
    socials?:  {
      __typename: "SocialItems",
      facebook?: string | null,
      twitter?: string | null,
      tiktok?: string | null,
      linkedIn?: string | null,
      instagram?: string | null,
    } | null,
    minBookingLimit?: number | null,
    maxBookingLimit?: number | null,
    bookingUrl?: string | null,
    createdAt: number,
    offersArray?: Array< string | null > | null,
    whatsappNumber?: string | null,
    updatedAt: number,
    conversations?:  {
      __typename: "ModelConvoLinkConnection",
      items:  Array< {
        __typename: "ConvoLink",
        id: string,
        status?: string | null,
        createdAt: number,
        updatedAt: number,
        owners: Array< string | null >,
        publisherId: string,
        entityId: string,
        userId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelines?:  Array< {
      __typename: "Timeline",
      id: string,
      title?: string | null,
      description?: string | null,
      hideTimes?: boolean | null,
      timeslots?:  Array< {
        __typename: "TimelineItem",
        id?: string | null,
        title?: string | null,
        description?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        artistId?: string | null,
        genres?: Array< string | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        image?: string | null,
      } | null > | null,
    } | null > | null,
    checklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    owner?: string | null,
    reviewScores?:  {
      __typename: "ReviewScores",
      food?: number | null,
      vibe?: number | null,
      service?: number | null,
      value?: number | null,
      overall?: number | null,
    } | null,
    safetyChecklist?:  {
      __typename: "Checklist",
      title: string,
      listItems?: Array< string | null > | null,
    } | null,
    venueSafetyData?:  {
      __typename: "VenueSafetyData",
      list?: Array< string | null > | null,
      title?: string | null,
      description?: string | null,
      links?: Array< string | null > | null,
      updated?: string | null,
    } | null,
    capacity?: number | null,
    acceptWalkIns?: boolean | null,
    covidMeasures?:  {
      __typename: "CovidMeasures",
      capacityPercentage?: number | null,
      tableLimit?: number | null,
      masksRequired?: boolean | null,
      bookingsRequired?: boolean | null,
      notes?: string | null,
    } | null,
    mediaChannels?:  {
      __typename: "MediaChannels",
      youtubeUrl?: string | null,
      spotifyUrl?: string | null,
      deezerUrl?: string | null,
      mixcloudUrl?: string | null,
      amazonMusicUrl?: string | null,
      tidalUrl?: string | null,
      soundcloudUrl?: string | null,
      appleMusicUrl?: string | null,
    } | null,
    fidelConfig?:  {
      __typename: "FidelConfig",
      merchantId?: string | null,
      brandId?: string | null,
      status?: string | null,
      brandUserId?: string | null,
      locationId?: string | null,
      contractSigned?: string | null,
    } | null,
    pingReview?:  {
      __typename: "PingReview",
      score?: number | null,
      title?: string | null,
      review?: string | null,
      createdAt?: string | null,
      reviewer?: string | null,
      reviewerPhoto?: string | null,
    } | null,
    pingAwards?:  Array< {
      __typename: "PingAward",
      date?: string | null,
      type?: string | null,
    } | null > | null,
    blogPosts?:  {
      __typename: "ModelBlogPostEntityConnection",
      items:  Array< {
        __typename: "BlogPostEntity",
        id: string,
        entityId: string,
        blogPostId: string,
        summary?: string | null,
        title?: string | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    bookingTables?:  {
      __typename: "ModelBookableTableConnection",
      items:  Array< {
        __typename: "BookableTable",
        id: string,
        tableNumber?: number | null,
        capacity?: number | null,
        area?: string | null,
        entityId: string,
        ttl?: number | null,
        maxPeople?: number | null,
        minPeople?: number | null,
        createdAt?: number | null,
        updatedAt?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    checkins?:  {
      __typename: "ModelCheckInConnection",
      items:  Array< {
        __typename: "CheckIn",
        id: string,
        userId: string,
        entityId: string,
        fullName?: string | null,
        phone?: string | null,
        partySize?: number | null,
        gender?: string | null,
        dob?: string | null,
        owners?: Array< string | null > | null,
        age?: number | null,
        email?: string | null,
        ttl?: number | null,
        status?: string | null,
        updatedAt?: number | null,
        createdAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        title: string,
        entityId: string,
        logo?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        area?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        dateTime?: number | null,
        startDate?: string | null,
        endDate?: string | null,
        closingTime?: number | null,
        rules?: string | null,
        discount?: number | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        isOnlineEvent?: boolean | null,
        featured?: string | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        mapImages?: Array< string | null > | null,
        price?: string | null,
        city: string,
        attending?: number | null,
        promoterIds?: Array< string | null > | null,
        ttl?: number | null,
        views?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        status?: string | null,
        owner?: string | null,
        owners?: Array< string | null > | null,
        pushTimestamps?: Array< number | null > | null,
        ownerType?: string | null,
        tablesAvailable?: number | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    repeatEvents?:  {
      __typename: "ModelRepeatEventConnection",
      items:  Array< {
        __typename: "RepeatEvent",
        id: string,
        title: string,
        entityId: string,
        area?: string | null,
        headerVideos?: Array< string | null > | null,
        publisherId: string,
        logo?: string | null,
        subtitle?: string | null,
        description?: string | null,
        type?: string | null,
        discount?: number | null,
        isOnlineEvent?: boolean | null,
        score?: number | null,
        categories?: Array< string | null > | null,
        closingTime?: number | null,
        dayOfWeek?: number | null,
        daysOfWeek?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        showWeather?: boolean | null,
        buyTicketUrl?: string | null,
        canBook?: boolean | null,
        ttl?: number | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        mapImages?: Array< string | null > | null,
        video?: string | null,
        images?: Array< string | null > | null,
        artistsList?: Array< string | null > | null,
        featured?: string | null,
        firstEventDate?: string | null,
        lastEventDate?: string | null,
        price?: string | null,
        rules?: string | null,
        city?: string | null,
        attending?: number | null,
        status: string,
        ownerType?: string | null,
        owner?: string | null,
        pushTimestamps?: Array< number | null > | null,
        promoterIds?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        menuImages?: Array< string | null > | null,
        username?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      items:  Array< {
        __typename: "Review",
        id: string,
        review?: string | null,
        score?: number | null,
        feedback?: string | null,
        dateOfVisit?: number | null,
        userId: string,
        entityId: string,
        publisherId: string,
        status?: string | null,
        verified?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    offers?:  {
      __typename: "ModelSpecialOfferConnection",
      items:  Array< {
        __typename: "SpecialOffer",
        id: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        price?: number | null,
        discount?: number | null,
        endTime?: string | null,
        endDate?: string | null,
        countryCode?: string | null,
        declineReason?: string | null,
        requiresBooking?: string | null,
        startDate?: string | null,
        maxTransactionAmount?: number | null,
        minTransactionAmount?: number | null,
        returnPeriod?: number | null,
        description?: string | null,
        totalLoyaltyPointsRequiredForAccess?: number | null,
        title: string,
        images?: Array< string | null > | null,
        weeks?: number | null,
        entityId: string,
        publisherId: string,
        fidelId?: string | null,
        spendRequired?: number | null,
        delayMinutes?: number | null,
        delayHours?: number | null,
        currency?: string | null,
        isStudentDiscount?: boolean | null,
        city?: string | null,
        quantity?: number | null,
        status: string,
        loyaltyPointsRequired?: number | null,
        loyaltyPointsValue?: number | null,
        visitsRequired?: number | null,
        redeemCount?: number | null,
        claimCount?: number | null,
        dateAvailable?: string | null,
        allowanceLimit?: number | null,
        refreshDaysPeriod?: string | null,
        type?: string | null,
        rewardType?: string | null,
        subscribersOnly?: boolean | null,
        owner?: string | null,
        disclaimer?: string | null,
        fidelOfferId?: string | null,
        fidelStartDate?: string | null,
        fidelEndDate?: string | null,
        fidelMinTransactionAmount?: number | null,
        fidelMaxTransactionAmount?: number | null,
        fidelReturnPeriod?: number | null,
        fidelDaysOfWeek?: Array< number | null > | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dailyPromotions?:  {
      __typename: "ModelDailyPromotionsConnection",
      items:  Array< {
        __typename: "DailyPromotions",
        id: string,
        entityId: string,
        daysAvailable?: Array< number | null > | null,
        startTime?: string | null,
        endTime?: string | null,
        description?: string | null,
        price?: number | null,
        images?: Array< string | null > | null,
        title?: string | null,
        status?: string | null,
        disclaimer?: string | null,
        owner?: string | null,
        subscribersOnly?: boolean | null,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    publisher?:  {
      __typename: "Publisher",
      id: string,
      cognitoUsername?: string | null,
      financeEmail?: string | null,
      role?: string | null,
      profilePicture?: string | null,
      bio?: string | null,
      config?:  {
        __typename: "PublisherConfig",
        type?: PublisherType | null,
        isBespoke?: boolean | null,
        standAloneVenue?: boolean | null,
        notificationsEnabled?: boolean | null,
        mailMarketingProvider?: MailMarketingProvider | null,
        paymentProvier?: PaymentProvider | null,
        POSProviders?: POSProvider | null,
      } | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      publisherType: PublisherType,
      editors?: Array< string | null > | null,
      owner: string,
      username?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      pushTimestamps?: Array< number | null > | null,
      emailsDisabled?: boolean | null,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      timezone?: string | null,
      subscibedToEmails?: boolean | null,
      timezoneOffset?: number | null,
      logo?: string | null,
      businessName?: string | null,
      description?: string | null,
      websiteUrl?: string | null,
      whatsappNumber?: string | null,
      phone?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      canMessage?: boolean | null,
      subscriberCount?: number | null,
      loyaltyCount?: number | null,
      status?: string | null,
      termsVersionSigned?: number | null,
      activated?: string | null,
      reviewsDisabled?: boolean | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      activationDate?: string | null,
      stripeConnectId?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      stripeCustomerId?: string | null,
      fidelId?: string | null,
      stripeSubscriptions?:  Array< {
        __typename: "StripeCustomerSubscription",
        id?: string | null,
        current_period_end?: number | null,
        current_period_start?: number | null,
        quantity?: number | null,
        entitiesIncluded?: Array< string | null > | null,
        latest_invoice?: string | null,
        status?: string | null,
        created?: number | null,
      } | null > | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      entities?:  {
        __typename: "ModelEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    tables?:  {
      __typename: "ModelVenueTableConnection",
      items:  Array< {
        __typename: "VenueTable",
        id: string,
        capacity?: number | null,
        quantityAvailable?: number | null,
        tablesBooked?: number | null,
        price?: number | null,
        disclaimer?: string | null,
        instructions?: string | null,
        parentType?: TableParentType | null,
        entityId: string,
        eventId: string,
        repeatEventId: string,
        status?: string | null,
        title?: string | null,
        owner: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    collection?:  {
      __typename: "ModelPlaceCollectionEntityConnection",
      items:  Array< {
        __typename: "PlaceCollectionEntity",
        id: string,
        entityId: string,
        listId: string,
        usersVisited?: Array< string | null > | null,
        owners?: Array< string | null > | null,
        orderIndex?: number | null,
        images?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    subscribers?:  {
      __typename: "ModelSubscriberConnection",
      items:  Array< {
        __typename: "Subscriber",
        id: string,
        userId: string,
        entityId: string,
        publisherId: string,
        userFullName?: string | null,
        subscriptionArn?: string | null,
        subscriptionArns?: Array< string | null > | null,
        notificationsEnabled?: boolean | null,
        credit?: number | null,
        loyaltyPoints?: number | null,
        loyaltyStatus?: string | null,
        EPOSId?: string | null,
        type?: string | null,
        status: string,
        createdAt: number,
        updatedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateBookingsSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnCreateBookingsSubscription = {
  onCreateBookings?:  {
    __typename: "Bookings",
    id: string,
    userName: string,
    userId: string,
    publisher?: string | null,
    bookingType?: string | null,
    entityId: string,
    venueNotes?: string | null,
    durationInMinutes?: number | null,
    entityTitle?: string | null,
    userEmail?: string | null,
    userPhone?: string | null,
    confirmed?: boolean | null,
    owners: Array< string | null >,
    owner?: string | null,
    dateTime: string,
    tableId: string,
    timeString?: string | null,
    provider?: BookingSystemProvider | null,
    dateString?: string | null,
    dateTimestamp: number,
    people: number,
    artistId?: string | null,
    notes?: string | null,
    status: string,
    ttl?: number | null,
    paymentStatus?: string | null,
    artistBookingNotes?:  {
      __typename: "ArtistBookingNotes",
      isEquiptmentProvided?: boolean | null,
      isBirthday?: boolean | null,
      isBrandEvent?: boolean | null,
      BrandDetails?: string | null,
      genresRequired?: string | null,
      equiptmentRequired?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateBookingsSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnUpdateBookingsSubscription = {
  onUpdateBookings?:  {
    __typename: "Bookings",
    id: string,
    userName: string,
    userId: string,
    publisher?: string | null,
    bookingType?: string | null,
    entityId: string,
    venueNotes?: string | null,
    durationInMinutes?: number | null,
    entityTitle?: string | null,
    userEmail?: string | null,
    userPhone?: string | null,
    confirmed?: boolean | null,
    owners: Array< string | null >,
    owner?: string | null,
    dateTime: string,
    tableId: string,
    timeString?: string | null,
    provider?: BookingSystemProvider | null,
    dateString?: string | null,
    dateTimestamp: number,
    people: number,
    artistId?: string | null,
    notes?: string | null,
    status: string,
    ttl?: number | null,
    paymentStatus?: string | null,
    artistBookingNotes?:  {
      __typename: "ArtistBookingNotes",
      isEquiptmentProvided?: boolean | null,
      isBirthday?: boolean | null,
      isBrandEvent?: boolean | null,
      BrandDetails?: string | null,
      genresRequired?: string | null,
      equiptmentRequired?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteBookingsSubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnDeleteBookingsSubscription = {
  onDeleteBookings?:  {
    __typename: "Bookings",
    id: string,
    userName: string,
    userId: string,
    publisher?: string | null,
    bookingType?: string | null,
    entityId: string,
    venueNotes?: string | null,
    durationInMinutes?: number | null,
    entityTitle?: string | null,
    userEmail?: string | null,
    userPhone?: string | null,
    confirmed?: boolean | null,
    owners: Array< string | null >,
    owner?: string | null,
    dateTime: string,
    tableId: string,
    timeString?: string | null,
    provider?: BookingSystemProvider | null,
    dateString?: string | null,
    dateTimestamp: number,
    people: number,
    artistId?: string | null,
    notes?: string | null,
    status: string,
    ttl?: number | null,
    paymentStatus?: string | null,
    artistBookingNotes?:  {
      __typename: "ArtistBookingNotes",
      isEquiptmentProvided?: boolean | null,
      isBirthday?: boolean | null,
      isBrandEvent?: boolean | null,
      BrandDetails?: string | null,
      genresRequired?: string | null,
      equiptmentRequired?: string | null,
    } | null,
    createdAt: number,
    updatedAt: number,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreatePodcastSubscription = {
  onCreatePodcast?:  {
    __typename: "Podcast",
    id: string,
    entityId: string,
    status?: string | null,
    embedType?: string | null,
    embedId?: string | null,
    embedUrl?: string | null,
    title?: string | null,
    description?: string | null,
    images?: Array< string | null > | null,
    username?: string | null,
    categories?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    guests?:  Array< {
      __typename: "PodcastGuest",
      name?: string | null,
      description?: string | null,
      url?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      details?: string | null,
      image?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdatePodcastSubscription = {
  onUpdatePodcast?:  {
    __typename: "Podcast",
    id: string,
    entityId: string,
    status?: string | null,
    embedType?: string | null,
    embedId?: string | null,
    embedUrl?: string | null,
    title?: string | null,
    description?: string | null,
    images?: Array< string | null > | null,
    username?: string | null,
    categories?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    guests?:  Array< {
      __typename: "PodcastGuest",
      name?: string | null,
      description?: string | null,
      url?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      details?: string | null,
      image?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeletePodcastSubscription = {
  onDeletePodcast?:  {
    __typename: "Podcast",
    id: string,
    entityId: string,
    status?: string | null,
    embedType?: string | null,
    embedId?: string | null,
    embedUrl?: string | null,
    title?: string | null,
    description?: string | null,
    images?: Array< string | null > | null,
    username?: string | null,
    categories?: Array< string | null > | null,
    tags?: Array< string | null > | null,
    guests?:  Array< {
      __typename: "PodcastGuest",
      name?: string | null,
      description?: string | null,
      url?: string | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      details?: string | null,
      image?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    entity?:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateTicketVariationOccurenceSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTicketVariationOccurenceSubscription = {
  onCreateTicketVariationOccurence?:  {
    __typename: "TicketVariationOccurence",
    id: string,
    eventId?: string | null,
    repeatEventId?: string | null,
    repeatEventOccurenceId?: string | null,
    eventType?: string | null,
    variationRuleId: string,
    isSoldOut?: boolean | null,
    amountSoldCounter?: number | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateTicketVariationOccurenceSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTicketVariationOccurenceSubscription = {
  onUpdateTicketVariationOccurence?:  {
    __typename: "TicketVariationOccurence",
    id: string,
    eventId?: string | null,
    repeatEventId?: string | null,
    repeatEventOccurenceId?: string | null,
    eventType?: string | null,
    variationRuleId: string,
    isSoldOut?: boolean | null,
    amountSoldCounter?: number | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteTicketVariationOccurenceSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTicketVariationOccurenceSubscription = {
  onDeleteTicketVariationOccurence?:  {
    __typename: "TicketVariationOccurence",
    id: string,
    eventId?: string | null,
    repeatEventId?: string | null,
    repeatEventOccurenceId?: string | null,
    eventType?: string | null,
    variationRuleId: string,
    isSoldOut?: boolean | null,
    amountSoldCounter?: number | null,
    owners?: Array< string | null > | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserWishListLinkSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateUserWishListLinkSubscription = {
  onCreateUserWishListLink?:  {
    __typename: "UserWishListLink",
    id: string,
    listId: string,
    userId: string,
    remminderSet?: boolean | null,
    createdAt: string,
    updatedAt: string,
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateUserWishListLinkSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateUserWishListLinkSubscription = {
  onUpdateUserWishListLink?:  {
    __typename: "UserWishListLink",
    id: string,
    listId: string,
    userId: string,
    remminderSet?: boolean | null,
    createdAt: string,
    updatedAt: string,
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteUserWishListLinkSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteUserWishListLinkSubscription = {
  onDeleteUserWishListLink?:  {
    __typename: "UserWishListLink",
    id: string,
    listId: string,
    userId: string,
    remminderSet?: boolean | null,
    createdAt: string,
    updatedAt: string,
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      cognitoUsername?: string | null,
      role?: UserRole | null,
      appVersion?: string | null,
      activated?: string | null,
      blocked?: boolean | null,
      firstName?: string | null,
      emailSettings?:  {
        __typename: "UserEmailSettings",
        transactions?: boolean | null,
        offers?: boolean | null,
        loyaltyCard?: boolean | null,
        eventInvites?: boolean | null,
        marketing?: boolean | null,
      } | null,
      lastName?: string | null,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      placesAddedToListCount?: number | null,
      bio?: string | null,
      preferredTitle?: string | null,
      country?:  {
        __typename: "Country",
        flag?: string | null,
        name?: string | null,
        alpha2Code?: string | null,
        alpha3Code?: string | null,
        callingCodes?: Array< string | null > | null,
        timezones?: Array< string | null > | null,
        region?: string | null,
      } | null,
      dob?: string | null,
      linkCardRewardClaimed?: string | null,
      email?: string | null,
      stripeConnectId?: string | null,
      stripeCustomerId?: string | null,
      gender?: string | null,
      phone?: string | null,
      city?: string | null,
      allowNotifications?: boolean | null,
      allowLocation?: boolean | null,
      allowPersonalisation?: boolean | null,
      allowCalendar?: boolean | null,
      profilePicture?: string | null,
      subscibedToEmails?: boolean | null,
      pushTokens?:  Array< {
        __typename: "PushToken",
        token?: string | null,
        device?: string | null,
        endpointArn?: string | null,
        parentApp?: string | null,
      } | null > | null,
      points?: number | null,
      accumulatedPoints?: number | null,
      isStudent?: boolean | null,
      lastLoggedIn?: string | null,
      locale?: string | null,
      isInfluencer?: string | null,
      summary?: string | null,
      categories?: Array< string | null > | null,
      social?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      createdAt: number,
      updatedAt: number,
      termsSigned?: boolean | null,
      termsVersionSigned?: string | null,
      EPOSId?: number | null,
      appConfigs?: string | null,
      disabled?: boolean | null,
      username?: string | null,
      offers?:  {
        __typename: "ModelClaimedOfferConnection",
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
      } | null,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      linkedCards?:  Array< {
        __typename: "IUserCard",
        scheme?: string | null,
        accountId?: string | null,
        countryCode?: string | null,
        created?: string | null,
        expYear?: number | null,
        expDate?: string | null,
        live?: boolean | null,
        lastNumbers?: string | null,
        expMonth?: number | null,
        updated?: string | null,
        programId?: string | null,
        firstNumbers?: string | null,
        id?: string | null,
        type?: string | null,
      } | null > | null,
      checkIns?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      userEvents?:  {
        __typename: "ModelUserEventConnection",
        nextToken?: string | null,
      } | null,
      loyaltyCards?:  {
        __typename: "ModelLoyaltyCardConnection",
        nextToken?: string | null,
      } | null,
      tableBookings?:  {
        __typename: "ModelTableBookingRequestConnection",
        nextToken?: string | null,
      } | null,
      lists?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      subscriptions?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreatePlaceCollectionEntitySubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnCreatePlaceCollectionEntitySubscription = {
  onCreatePlaceCollectionEntity?:  {
    __typename: "PlaceCollectionEntity",
    id: string,
    entityId: string,
    listId: string,
    usersVisited?: Array< string | null > | null,
    owners?: Array< string | null > | null,
    orderIndex?: number | null,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type OnUpdatePlaceCollectionEntitySubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnUpdatePlaceCollectionEntitySubscription = {
  onUpdatePlaceCollectionEntity?:  {
    __typename: "PlaceCollectionEntity",
    id: string,
    entityId: string,
    listId: string,
    usersVisited?: Array< string | null > | null,
    owners?: Array< string | null > | null,
    orderIndex?: number | null,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type OnDeletePlaceCollectionEntitySubscriptionVariables = {
  owner?: string | null,
  owners?: string | null,
};

export type OnDeletePlaceCollectionEntitySubscription = {
  onDeletePlaceCollectionEntity?:  {
    __typename: "PlaceCollectionEntity",
    id: string,
    entityId: string,
    listId: string,
    usersVisited?: Array< string | null > | null,
    owners?: Array< string | null > | null,
    orderIndex?: number | null,
    images?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    entity:  {
      __typename: "Entity",
      id: string,
      title: string,
      shortUrl?: string | null,
      subtitle?: string | null,
      lastAdminCheck?: string | null,
      area?: string | null,
      contacts?:  Array< {
        __typename: "ContactInfo",
        title?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        venue?: string | null,
        email?: string | null,
        phone?: string | null,
        position?: string | null,
        notes?: string | null,
      } | null > | null,
      notes?: string | null,
      summary?: string | null,
      uniqueAspect?: string | null,
      tags?: Array< string | null > | null,
      highlights?:  Array< {
        __typename: "PlaceHighlight",
        title?: string | null,
        image?: string | null,
        order?: number | null,
      } | null > | null,
      nickname?: string | null,
      hasEmailed?: boolean | null,
      firstName?: string | null,
      hasStudentDiscount?: boolean | null,
      LastName?: string | null,
      config?:  {
        __typename: "EntityConfig",
        loyaltyEnabled?: boolean | null,
        canMessage?: boolean | null,
        canBook?: boolean | null,
        canCheckin?: boolean | null,
        canCall?: boolean | null,
        canEmail?: boolean | null,
        autoResponseMessage?: string | null,
        enterVenueCheckInEnabled?: boolean | null,
        checkInMessage?: string | null,
        colorTheme?: string | null,
        emailsDisabled?: boolean | null,
        reviewsDisabled?: boolean | null,
        enableTrackTrace?: boolean | null,
      } | null,
      nearbyStations?:  Array< {
        __typename: "EntityClosestStation",
        title?: string | null,
        distance?: number | null,
        line?: string | null,
        zone?: number | null,
        type?: string | null,
      } | null > | null,
      outdoorArea?: boolean | null,
      outdoorHeating?: boolean | null,
      description?: string | null,
      stripeSubscriptionItemId?: string | null,
      emojiSummary?: Array< string | null > | null,
      categories?: Array< string | null > | null,
      phone?: string | null,
      internationalPhoneNumber?: string | null,
      logo?: string | null,
      pushTimestamps?: Array< number | null > | null,
      websiteUrl?: string | null,
      orderfromTableUrl?: string | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      entityTitle?: string | null,
      opens?: number | null,
      closes?: number | null,
      asFeaturedOn?: Array< string | null > | null,
      username?: string | null,
      trendingScore?: number | null,
      status: string,
      address?:  {
        __typename: "Address",
        title?: string | null,
        streetNameNumber?: string | null,
        addressLine1?: string | null,
        addressLine2?: string | null,
        addressLine3?: string | null,
        city?: string | null,
        province?: string | null,
        country?: string | null,
        postcode?: string | null,
      } | null,
      virtualTourUrl?: string | null,
      emailsDisabled?: boolean | null,
      reviewsDisabled?: boolean | null,
      video?: string | null,
      pageVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      cardVideos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      sponsors?:  Array< {
        __typename: "Sponsor",
        id?: string | null,
        title?: string | null,
        logo?: string | null,
        desc?: string | null,
      } | null > | null,
      headerVideos?: Array< string | null > | null,
      videoData?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      city: string,
      currency?:  {
        __typename: "Currency",
        code?: string | null,
        name?: string | null,
        symbol?: string | null,
      } | null,
      features?:  Array< {
        __typename: "Feature",
        title?: string | null,
        icon?: Array< string | null > | null,
      } | null > | null,
      entityFeatures?:  Array< {
        __typename: "EntityFeature",
        images?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        bookUrl?: string | null,
        status?: string | null,
      } | null > | null,
      rules?: string | null,
      position?: number | null,
      score?: number | null,
      score_before_boost?: number | null,
      boost_multiplier?: number | null,
      boost_until?: string | null,
      boostPurchaseId?: string | null,
      reviewRating?: number | null,
      reviewCount?: number | null,
      canMessage?: boolean | null,
      forceupdate?: boolean | null,
      email?: string | null,
      menuUrlList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuPDFList?:  Array< {
        __typename: "UrlType",
        title?: string | null,
        type?: string | null,
        url?: string | null,
      } | null > | null,
      menuImages?: Array< string | null > | null,
      menu?:  {
        __typename: "Menu",
        id: string,
        title?: string | null,
        status: string,
      } | null,
      price?: string | null,
      featured?: string | null,
      cuisines?: Array< string | null > | null,
      dietryOptions?: Array< string | null > | null,
      foodDeliveryAppLinks?:  Array< {
        __typename: "FoodDeliveryAppLink",
        title?: string | null,
        icon?: string | null,
        url?: string | null,
      } | null > | null,
      verified?: boolean | null,
      qrIds?: Array< string | null > | null,
      publisherId: string,
      subscribeOfferId?: string | null,
      subscriberCount?: number | null,
      viewCount?: number | null,
      bookCount?: number | null,
      shareCount?: number | null,
      checkInCount?: number | null,
      addToListCount?: number | null,
      pushTimestamp?: number | null,
      extraInfo?:  Array< {
        __typename: "AccordionData",
        title?: string | null,
        content?: string | null,
        url?: string | null,
      } | null > | null,
      images?: Array< string | null > | null,
      googleImages?:  Array< {
        __typename: "GoogleImage",
        html_attributions?: Array< string | null > | null,
        image?: string | null,
      } | null > | null,
      socials?:  {
        __typename: "SocialItems",
        facebook?: string | null,
        twitter?: string | null,
        tiktok?: string | null,
        linkedIn?: string | null,
        instagram?: string | null,
      } | null,
      minBookingLimit?: number | null,
      maxBookingLimit?: number | null,
      bookingUrl?: string | null,
      createdAt: number,
      offersArray?: Array< string | null > | null,
      whatsappNumber?: string | null,
      updatedAt: number,
      conversations?:  {
        __typename: "ModelConvoLinkConnection",
        nextToken?: string | null,
      } | null,
      timelines?:  Array< {
        __typename: "Timeline",
        id: string,
        title?: string | null,
        description?: string | null,
        hideTimes?: boolean | null,
      } | null > | null,
      checklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      owner?: string | null,
      reviewScores?:  {
        __typename: "ReviewScores",
        food?: number | null,
        vibe?: number | null,
        service?: number | null,
        value?: number | null,
        overall?: number | null,
      } | null,
      safetyChecklist?:  {
        __typename: "Checklist",
        title: string,
        listItems?: Array< string | null > | null,
      } | null,
      venueSafetyData?:  {
        __typename: "VenueSafetyData",
        list?: Array< string | null > | null,
        title?: string | null,
        description?: string | null,
        links?: Array< string | null > | null,
        updated?: string | null,
      } | null,
      capacity?: number | null,
      acceptWalkIns?: boolean | null,
      covidMeasures?:  {
        __typename: "CovidMeasures",
        capacityPercentage?: number | null,
        tableLimit?: number | null,
        masksRequired?: boolean | null,
        bookingsRequired?: boolean | null,
        notes?: string | null,
      } | null,
      mediaChannels?:  {
        __typename: "MediaChannels",
        youtubeUrl?: string | null,
        spotifyUrl?: string | null,
        deezerUrl?: string | null,
        mixcloudUrl?: string | null,
        amazonMusicUrl?: string | null,
        tidalUrl?: string | null,
        soundcloudUrl?: string | null,
        appleMusicUrl?: string | null,
      } | null,
      fidelConfig?:  {
        __typename: "FidelConfig",
        merchantId?: string | null,
        brandId?: string | null,
        status?: string | null,
        brandUserId?: string | null,
        locationId?: string | null,
        contractSigned?: string | null,
      } | null,
      pingReview?:  {
        __typename: "PingReview",
        score?: number | null,
        title?: string | null,
        review?: string | null,
        createdAt?: string | null,
        reviewer?: string | null,
        reviewerPhoto?: string | null,
      } | null,
      pingAwards?:  Array< {
        __typename: "PingAward",
        date?: string | null,
        type?: string | null,
      } | null > | null,
      blogPosts?:  {
        __typename: "ModelBlogPostEntityConnection",
        nextToken?: string | null,
      } | null,
      bookingTables?:  {
        __typename: "ModelBookableTableConnection",
        nextToken?: string | null,
      } | null,
      checkins?:  {
        __typename: "ModelCheckInConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      repeatEvents?:  {
        __typename: "ModelRepeatEventConnection",
        nextToken?: string | null,
      } | null,
      reviews?:  {
        __typename: "ModelReviewConnection",
        nextToken?: string | null,
      } | null,
      offers?:  {
        __typename: "ModelSpecialOfferConnection",
        nextToken?: string | null,
      } | null,
      dailyPromotions?:  {
        __typename: "ModelDailyPromotionsConnection",
        nextToken?: string | null,
      } | null,
      publisher?:  {
        __typename: "Publisher",
        id: string,
        cognitoUsername?: string | null,
        financeEmail?: string | null,
        role?: string | null,
        profilePicture?: string | null,
        bio?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        publisherType: PublisherType,
        editors?: Array< string | null > | null,
        owner: string,
        username?: string | null,
        pushTimestamps?: Array< number | null > | null,
        emailsDisabled?: boolean | null,
        timezone?: string | null,
        subscibedToEmails?: boolean | null,
        timezoneOffset?: number | null,
        logo?: string | null,
        businessName?: string | null,
        description?: string | null,
        websiteUrl?: string | null,
        whatsappNumber?: string | null,
        phone?: string | null,
        canMessage?: boolean | null,
        subscriberCount?: number | null,
        loyaltyCount?: number | null,
        status?: string | null,
        termsVersionSigned?: number | null,
        activated?: string | null,
        reviewsDisabled?: boolean | null,
        reviewRating?: number | null,
        reviewCount?: number | null,
        activationDate?: string | null,
        stripeConnectId?: string | null,
        stripeCustomerId?: string | null,
        fidelId?: string | null,
        createdAt: number,
        updatedAt: number,
      } | null,
      tables?:  {
        __typename: "ModelVenueTableConnection",
        nextToken?: string | null,
      } | null,
      collection?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
      subscribers?:  {
        __typename: "ModelSubscriberConnection",
        nextToken?: string | null,
      } | null,
    },
    list:  {
      __typename: "PlaceCollection",
      id: string,
      userId: string,
      title?: string | null,
      description?: string | null,
      area?: string | null,
      saved?: number | null,
      shared?: number | null,
      likes?: number | null,
      venueCount?: number | null,
      headerImg?: string | null,
      videos?:  Array< {
        __typename: "VideoData",
        state?: string | null,
        key?: string | null,
        thumbnail?: string | null,
        duration?: number | null,
        updatedAt?: string | null,
        types?: Array< string | null > | null,
      } | null > | null,
      views?: number | null,
      lockCriteria?: string | null,
      score?: number | null,
      areas?: Array< string | null > | null,
      location?:  {
        __typename: "Location",
        lat?: number | null,
        lon?: number | null,
      } | null,
      username?: string | null,
      city?: string | null,
      cities?: Array< string | null > | null,
      status?: string | null,
      isPublic?: string | null,
      dateTime?: string | null,
      securityCode?: string | null,
      owners?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      users?:  {
        __typename: "ModelUserWishListLinkConnection",
        nextToken?: string | null,
      } | null,
      collectionLink?:  {
        __typename: "ModelPlaceCollectionEntityConnection",
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};
