import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'ping-sleek-loader',
  templateUrl: './sleek-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./sleek-loader.component.scss'],
})
export class SleekLoaderComponent implements OnInit {


  @Input() overrideLetters: string[];
  public letters: string[] = ['P', 'I', 'N', 'G', ' ',
  'C', 'U', 'L', 'T', 'U', 'R', 'E'];

  public showSpinner: boolean;

  constructor(private cd: ChangeDetectorRef, private utils: UtilitiesService) { }

  ngOnInit() {
    if (this.overrideLetters?.length) {
      this.letters = this.overrideLetters;
      this.cd.detectChanges();
    }

    this.utils.showSpinner$.pipe(distinctUntilChanged()).subscribe((show: boolean) => {
      this.showSpinner = show;
      this.cd.detectChanges();
    });
  }

}
