import { Injectable } from '@angular/core';
import { ICity } from 'src/app/core/models/city';
import { ApiService } from 'src/app/core/services/api.service';
import { StoreService } from 'src/app/core/services/store.service';
import { listCitys } from 'src/app/core/api/cities/cities.queries'
import { first, takeUntil } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/services/logger.service';
import { BehaviorSubject } from 'rxjs';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class CitiesService extends ApiService {

  constructor(private store: StoreService,
    private utils: UtilitiesService,
    private logger: LoggerService) {

      super();
  }

  public $city = new BehaviorSubject<ICity>(null);


  public async getCities(): Promise<ICity[]> {
    const currentCities: ICity[] = this.store.getObject<ICity[]>('cities');
    if (!currentCities?.length) {
      return await this.fetchCities();
    } else {
      return currentCities;
    }
  }

  public async fetchCities(): Promise<ICity[]> {
    try {
      const res: any = await this.runQuery(
        listCitys,
        { limit: 200 },
        'listCitys',
        true, true
      ).pipe(first()).toPromise();
      if (!res || !res?.items) {
        // throw new Error('No categoires found');
        return [];
      }
      // organise in city alphabetical order
      let cities: any[] = res.items?.sort((a: ICity, b: ICity) => a?.venueCount > b?.venueCount ? 1 : -1);
      cities = cities.sort((a: ICity, b: ICity) => a?.country > b?.country ? 1 : -1);
      this.store.setObject('cities', cities);

      // set current city
      let currentCity: string = 'London';
      if (typeof localStorage !== 'undefined') {
        currentCity = localStorage.getItem('city') || 'London';
      }

      const cityIndex: number = cities.findIndex(city => city.title === currentCity);
      if (cityIndex > -1) {
        this.store.setObject('city', cities[cityIndex]);
        this.store.setKey('city', cities[cityIndex].title);
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('city', currentCity);
        }
      }

      return cities;
    } catch (err) {
      this.logger.logError(err);
      return [];
    }
  }

    // ORder cities by distance to coords
    public orderCitiesByClosest(cities: ICity[], lat: number, lng: number): ICity[] {

      let newCities: ICity[] = this.utils.clone(cities);

      for (var i = 0; i < newCities.length; i++) {
        newCities[i]['distanceToUser'] = this.utils.distanceBetweenCoords(lat, lng, newCities[i].location.lat, newCities[i].location.lon, "K")
      }

      if (newCities?.length && lat && lng) {
        newCities.sort((a: ICity, b: ICity) =>{
          return a.distanceToUser - b.distanceToUser;
        });
      }

      return newCities
    }

}
