import { Injectable, OnDestroy } from "@angular/core";
import { Observable, from, of, Subject } from "rxjs";
import { map, catchError, tap, takeUntil, take, first } from "rxjs/operators";
import {
  IPublisher, IPlace, IEvent, IArtist
} from "src/app/shared/interfaces";
import {
  searchEntitys,
  searchElasticEvents,
  searchRepeatEvents,
} from "../api/queries";
import {
  SearchableEntityFilterInput,
  SearchableEntitySortInput,
  SearchableSortDirection,
  SearchableEntitySortableFields,
  SearchableEventFilterInput,
  SearchableEventSortInput,
  SearchElasticArtistEventPositionFilter,
  SearchEventsInput,
  ModelSortDirection,
  SearchableRepeatEventFilterInput,
  SearchableArtistFilterInput,
  LocationInput,
  SearchPlacesSortInput,
  SearchPlacesSortFields,
  SearchPlacesInput,
  ModelFloatKeyConditionInput,
  SearchRepeatEventFilter,
  SearchRepeatEventsSortInput,
  SearchRepeatEventsSortFields,
  SearchEventsSortInput,
  SearchEventsSortFields,
  SearchRepeatEventsInput,
  SearchableEventSortableFields,
} from "src/app/core/api/api";
import { searchElasticartistEventLocation } from 'src/graphql/queries';

import * as moment from 'moment';
import { StoreService } from 'src/app/core/services/store.service';
import { getPublisherByUserName } from 'src/app/modules/promoter/api/publisher.queries';
import { entityShortUrlQuery } from 'src/app/modules/places/api/places.queries';
import {
  eventShortUrlQuery, repeatEventShortUrlQuery, searchElasticRepeatedEvents,
  searchEvents
} from 'src/app/modules/events/api/events.queries';
import { ApiService, ErrorType } from "src/app/core/services/api.service";
import { searchElasticPlaces } from "src/app/core/api/places/places.queries";
import { EventSearchFilter, GraphResponse } from "src/app/core/models/models";
import { IRepeatEvent } from "src/app/core/models/event";
import { IEntity } from "src/app/core/models/entity";
import { LoggerService } from "src/app/core/services/logger.service";
import { ParentType } from "src/app/core/models/enums";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { UtilitiesService } from "./utilities.service";


export interface PlacesSearchFilter {
  city?: string;
  area?: string;
  day?: string;
  cuisine?: string,
  dietryOption?: string,
  vibeFilter?: SearchPlacesVibeInput
  dayFilter?: SearchPlacesHoursInput,
  studentDiscount?: null,
  tags?: string[],
  rewardVenue?: boolean,
  price?: string;
  acceptWalkIns?: boolean,
  outdoorArea?: boolean,
  openLate?: boolean,
  categories?: string[];
  subCategories?: string[],
  location?: LocationInput;
  sort?: any;
  km?: number;
}

export interface SearchPlacesHoursInput {
  day: string,
  gte: number,
  lte: number,
  type: string
}

export interface SearchPlacesVibeInput {
  type?: string,
  day?: string
}

@Injectable({
  providedIn: "root"
})
export class SearchService extends ApiService {

  private $destroy = new Subject();

  constructor(private store: StoreService,
    private analytics: GoogleAnalyticsService,
    private utils: UtilitiesService,
              private logger: LoggerService) {
    super();
  }

  ngOnDestroy() {
    this.$destroy.next(null);
  }

  public getPlaces(city?: string, limit?: number, nextToken?: any): Observable<GraphResponse<any>> {
    const storePlaces: GraphResponse<any> = this.store.getList("entities");
    if (!nextToken) {
      if (storePlaces?.items?.length && storePlaces?.items?.length >= limit && !city) {
        return this.store.list$("entities");
      }
    }
    const filter: SearchableEntityFilterInput = {
      status: { eq: "ACTIVE" },
    };

    if (city) {
      filter['city'] = { eq: city };
    }

    const sort: SearchableEntitySortInput = {
      field: SearchableEntitySortableFields.score,
      direction: SearchableSortDirection.desc
    };
    this.runQuery(searchEntitys, { filter, sort, limit: limit || 10, nextToken }, 'searchEntitys', true, true)
      .pipe(
        first())
      .subscribe((entities: any) => {
        if ((entities as ErrorType) !== ErrorType.ERROR) {
          if (nextToken) {
            this.store.joinToList("entities", entities);
          } else {
            this.store.setList("entities", entities);
          }

          if (entities?.items?.length) {
            // add impressions analytics
            this.addPlacesImpressions(entities.items);
          }
        }
      });
      return this.store.list$("entities");
  }

  public async searchForPlaces(filter: PlacesSearchFilter, sortInput?: SearchPlacesSortInput, nextToken?: any, limit?: number, skipStore?: boolean): Promise<GraphResponse<IEntity>> {

    if (!nextToken) {
      this.store.setList('places-entities', null);
    }

    let sort: SearchPlacesSortInput = !sortInput ? {
      field: SearchPlacesSortFields.createdAt,
      direction: SearchableSortDirection.desc
    } : sortInput;

    const filterInput: PlacesSearchFilter = {
      city: filter?.city || null,
      area: filter.area && filter.area.toLowerCase() !== 'na' ? filter.area : null,
      day: filter?.day || null,
      cuisine: filter?.cuisine || null,
      vibeFilter: filter?.vibeFilter || null,
      dayFilter: filter?.dayFilter || null,
      outdoorArea: filter?.outdoorArea || null,
      price: filter?.price || null,
      categories: filter?.categories || null,
    }

    if (filter?.categories?.length) {
      if (filter?.subCategories?.length) {
        filterInput.categories = [...filter.categories, ...filter.subCategories];
      }
    }

    const input: SearchPlacesInput = {
      filter: filterInput,
      sort,
      limit: limit ? limit : 14,
      nextToken
    };

    const response: any = await this.runQuery(searchElasticPlaces, { input }, 'searchElasticPlaces', true, true).pipe(first()).toPromise();
    if (response?.nextToken && response?.items?.length < (limit || 9)) {
      response.nextToken = null;
    }
    if (response?.items?.length) {
      if (!skipStore) {
        if (nextToken) {
          this.store.joinToList("places-entities", response);
        } else {
          this.store.setList("places-entities", response);
        }
      }

      // add impressions analytics
      this.addPlacesImpressions(response.items);

      return response;
    } else {
      if (nextToken) {
        // this.store.joinToList("places-entities", { items: [], nextToken: null });
      } else {
        this.store.setList("places-entities", { items: [], nextToken: null });
      }
      return { items: [], nextToken: null };
    }
  }

  public getPlacesBySearch(query: string, city?: string, limit?: number, nextToken?: any, category?: string): Observable<GraphResponse<any>> {
    let joinedQuery;
    if (query) {
      const splitQuery = query.toLowerCase().split(" ");
      joinedQuery = splitQuery.map((part: string) => ({
          or: [
            { city: { regexp: `.*${part}.*` } },
            { area: { regexp: `.*${part}.*` } },
            { title: { regexp: `.*${part}.*` } },
            { cuisines: { regexp: `.*${part}.*` }},
            { categories: { regexp: `.*${part}.*` }}
          ],
          status: { eq: 'ACTIVE' }
      }));
    } else if (category) {
      joinedQuery = [{
        status: { eq: 'ACTIVE' },
      }];
    }

    for (let j of joinedQuery) {
      if (city) {
        j['city'] = {match: city};
      }
      if (category) {
        j['categories'] = { match: category }
      }
    }

    const filter: SearchableEntityFilterInput = {
      and: joinedQuery
    };
    const sort: SearchableEntitySortInput = {
      field: SearchableEntitySortableFields.score,
      direction: SearchableSortDirection.desc
    };
    return this.runQuery<any>(searchEntitys, { filter, sort, limit: limit || 10, nextToken }, 'searchEntitys', true, true)
      .pipe(
        tap((res: any) => {
          if ((res as ErrorType) !== ErrorType.ERROR) {
            if (nextToken) {
              this.store.joinToList("searchResults", res);
            } else {
              this.store.setList("searchResults", res);
            }


            if (res?.items?.length) {
              // add impressions analytics
              this.addPlacesImpressions(res.items);
            }

            return res;
          } else {
            return { items: [], nextToken: null };
          }
        }),
        takeUntil(this.$destroy),
        take(1),
      );
  }

  public async searchEvents(filter: EventSearchFilter, nextToken?: any[], limit?: number, store?: string, useNextTokens?: boolean): Promise<any> {

    // let sort: SearchEventsSortInput = !sortInput ? {
    //   field: SearchPlacesSortFields.score,
    //   direction: SearchableSortDirection.desc
    // } : sortInput;

    try {
      const eventNT = nextToken?.length ? nextToken[0] : null;
      const repeatEventNT = nextToken?.length ? nextToken[1] : null;
      const data: any = await Promise.all([
        // only use nextTokens if they are wanted to be considered, otherwise it might return the initial paginated list again (duplicates)
        (eventNT && useNextTokens) || (!eventNT && !useNextTokens) ? this.searchForEvents(filter, eventNT, limit) : {items: [], nextToken: null},
        (repeatEventNT && useNextTokens) || (!repeatEventNT && !useNextTokens) ? this.searchForRepeatEvents(filter, repeatEventNT, limit) : {items: [], nextToken: null},
      ]);

      const events: GraphResponse<IEvent> | ErrorType = data[0];
      const repeatEvents: GraphResponse<IRepeatEvent> | ErrorType = data[1];

      const response = {
        nextToken: [],
        items: []
      };

      if ((<GraphResponse<IEvent>>events)?.items?.length) {
        response.nextToken.push((<GraphResponse<IEvent>>events).nextToken);
        response.items.push(...(<GraphResponse<IEvent>>events).items);
      }

      if ((<GraphResponse<IRepeatEvent>>repeatEvents)?.items?.length) {
        response.nextToken.push((<GraphResponse<IRepeatEvent>>repeatEvents).nextToken);
        response.items.push(...(<GraphResponse<IRepeatEvent>>repeatEvents).items);
      }

      response.items = response.items.sort((a, b) => (a.score > b.score) ? 1 : ((b.score > a.score) ? -1 : 0));

      if (nextToken) {
        const currentEventsList: GraphResponse<any> = this.store.getList(store || 'events');

        if (currentEventsList?.items?.length) {
          currentEventsList.items.push(...response.items);
          currentEventsList.nextToken[0] = (<GraphResponse<IEvent>>events).nextToken;
          currentEventsList.nextToken[1] = (<GraphResponse<IRepeatEvent>>repeatEvents).nextToken;
          this.store.addToList(store || 'events', currentEventsList);
        }
      } else {
        this.store.setList(store || 'events', response);
      }

      return response;
    } catch (err) {
      this.logger.logError(err);
      return null;
    }
  }

  public async searchForEvents(filter: EventSearchFilter, nextToken?: any, limit?: number): Promise<GraphResponse<IEvent> | ErrorType> {

    const sort: SearchEventsSortInput = {
      field: SearchEventsSortFields.dateTime,
      direction: SearchableSortDirection.asc
    };

    const filterInput: EventSearchFilter = {
      start: filter?.start,
      end: filter?.end,
      area: filter?.area,
      closingTime: filter?.closingTime,
      categories: filter?.categories,
    };

    if (filter.city) {
      filterInput['city'] = filter.city;
    }

    if (filter?.categories?.length) {
      if (filter?.subCategories?.length) {
        filterInput.categories = [...filter.categories, ...filter.subCategories];
      }
    }

    const input: SearchEventsInput = {
      filter: filterInput,
      limit: limit ? limit : 9,
      nextToken
    };

    const res: GraphResponse<IEvent> | ErrorType = await this.runQuery<GraphResponse<IEvent>>(
      searchElasticEvents,
      { input }, 'searchElasticEvents', true).toPromise();
    if ((<GraphResponse<IEvent>>res)?.items) {
      const events: GraphResponse<IEvent> = (<GraphResponse<IEvent>>res);

      if (events?.items?.length) {
        // add impressions analytics
        this.addEventsImpressions(events.items);
      }

      return events;
    } else {
      return res;
    }
  }

  public async searchForRepeatEvents(inputfilter: any, nextToken?: any, limit?: number): Promise<GraphResponse<IRepeatEvent> | ErrorType> {
    const filter = JSON.parse(JSON.stringify(inputfilter));

    const sort: SearchRepeatEventsSortInput = {
      field: SearchRepeatEventsSortFields.daysOfWeek,
      direction: SearchableSortDirection.asc
    };

    const filterInput: SearchRepeatEventFilter = {
      start: filter?.start,
      end: filter?.end,
      area: filter?.area,
      closingTime: filter?.closingTime,
      categories: filter?.categories,
    };

    if (filter.city) {
      filterInput['city'] = filter.city;
    }

    if (filter?.categories?.length) {
      if (filter?.subCategories?.length) {
        filterInput.categories = [...filter.categories, ...filter.subCategories];
      }
    }

    const daysOfWeek: number[] | null = this.getDaysOfWeekToSearch(filter);

    filter['daysOfWeek'] = daysOfWeek;
    filter['start'] = null;
    filter['end'] = null;

    const input: SearchRepeatEventsInput = {
      filter: filterInput,
      limit: limit ? limit : 5,
      nextToken
    };



    const res: GraphResponse<IRepeatEvent> | ErrorType = await this.runQuery<GraphResponse<IRepeatEvent>>(searchElasticRepeatedEvents, { input }, 'searchElasticRepeatedEvents', true).toPromise();
    if ((<GraphResponse<IRepeatEvent>>res)?.items) {
      const events: GraphResponse<IRepeatEvent> = (<GraphResponse<IRepeatEvent>>res);

      if (events?.items?.length) {
        // add impressions analytics
        this.addEventsImpressions(events.items);
      }

      return events;
    } else {
      return res;
    }
  }

  public getEventsBySearch(query: string, city?: string, limit?: number, nextToken?: any, category?: string): Observable<GraphResponse<any> | null> {

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const filter: SearchableEventFilterInput = {
      status: { eq: 'ACTIVE' },
      dateTime: { gte: +new Date() }
    };

    if (category || query) {
      filter['or'] = [];
      filter['or'].push({ categories: { regexp: `.*${category || query}.*` }});
    }
    if (query) {
      filter['or'].push({ title: { regexp: `.*${query}.*` } });
    }


    const sort: SearchableEventSortInput = {
      field: SearchableEventSortableFields.dateTime,
      direction: SearchableSortDirection.asc
    };

    if (city) {
      filter['city'] = { match: city };
    }

    return this.runQuery(searchEvents, { filter, sort, limit: limit || 9, nextToken }, 'searchEvents', true, true)
      .pipe(
        tap((res: any) => {
          if ((res as ErrorType) !== ErrorType.ERROR) {
            if (nextToken) {
              this.store.joinToList('searchResults', res)
            } else {
              this.store.setList('searchResults', res);
            }

            if (res?.items?.length) {
              // add impressions analytics
              this.addEventsImpressions(res.items);
            }
          }
        })
      ) as Observable<GraphResponse<any> | null>;
  }

  public getRepeatEventsBySearch(query: string, city?: string, limit?: number, nextToken?: string, category?: string): Observable<GraphResponse<any> | null> {
    const filter: SearchableRepeatEventFilterInput = {
      or: [
        { title: { regexp: `.*${query}.*` } },
        { categories: { regexp: `.*${category || query}.*` }}
      ],
    };
    if (city) {
      filter['city'] = { match: city };
    }

    const sort: SearchableEventSortInput = {
      direction: SearchableSortDirection.desc
    };

    return this.runQuery(searchRepeatEvents, { filter, sort, limit: limit | 9, nextToken }, 'searchRepeatEvents', true, true)
      .pipe(
        map((res: any) => {
          if ((res as ErrorType) !== ErrorType.ERROR) {
            this.store.joinToList('searchResults', res);

            if (res?.items?.length) {
              // add impressions analytics
              this.addEventsImpressions(res.items);
            }
            return res
          } else {
            return null;
          }
        }), first()
      ) as Observable<GraphResponse<any> | null>;
  }


  public getRepeatEvents(limit?: number, city?: string): Observable<GraphResponse<any> | null> {
    const storeEvents: GraphResponse<any> = this.store.getList("repeatEvents");
    if (
      storeEvents?.items?.length >= limit
    ) {
      return this.store.list$("repeatEvents");
    }
    const filter: SearchableEventFilterInput = {
      status: { eq: "ACTIVE" }
    };

    if (city) {
      filter['city'] = { eq: city };
    }

    const sort: SearchableEventSortInput = {
      direction: SearchableSortDirection.desc
    };
    return this.runQuery(searchRepeatEvents, { filter, sort, limit: limit | 10 }, 'searchRepeatEvents', true, true)
      .pipe(
        map((res: any) => {
          if ((res as ErrorType) !== ErrorType.ERROR) {
            this.store.setList("repeatEvents", res?.items);

            if (res?.items?.length) {
              // add impressions analytics
              this.addEventsImpressions(res.items);
            }

            return res.data.searchRepeatEvents;
          } else {
            return { items: [], nextToken: null };
          }
        }),
        first()
      ) as Observable<GraphResponse<any> | null>;
  }

  public async getTendingArtists(city?: string, location?: LocationInput, limit?: number, nextToken?: string): Promise<GraphResponse<any>> {
    // const storeArtists: GraphResponse<any> = this.store.getList("artists");
    // // if (storeArtists && storeArtists.items && storeArtists.items.length) {
    // //   return this.store.list$("artists");
    // // }
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const filter: SearchElasticArtistEventPositionFilter = {
      city: location ? null : city || null,
      location: city ? null : location || null,
      date: +new Date()
    };

    const res: GraphResponse<IArtist> | ErrorType = await this.runQuery<GraphResponse<IArtist>>(
      searchElasticartistEventLocation,
      { filter, km: 5, limit: limit || 30 }, 'searchElasticartistEventLocation', true).toPromise();
    if ((<GraphResponse<IArtist>>res)?.items) {
      const artists: GraphResponse<IArtist> = (<GraphResponse<IArtist>>res);
      if (nextToken) {
        this.store.addToList("artists", artists);
      } else {
        this.store.setList("artists", artists);
      }
      return artists;
    } else {
      return { items: [], nextToken: null };
    }

  }

  public getTendingArtistsBySearch(query: string, limit?: number, nextToken?: string): Observable<GraphResponse<any>> {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const filter: SearchableArtistFilterInput = {
      title: { wildcard: query.toLowerCase() + '*' }
    };
    return this.runQuery(searchElasticartistEventLocation, { filter, limit: limit || 10, nextToken }, 'searchElasticartistEventLocation', true, true)
      .pipe(
        map((res: any) => {
          if ((res as ErrorType) !== ErrorType.ERROR) {
            res.items = res.items.filter(
              (a: any, index: number, self: any) =>
                index === self.findIndex((t: any) => t.artist.id === a.artist.id)
            );
            res.items = res.items.filter(
              (a: any) => a?.artist?.images?.length
            );
            this.store.setList("searchResults", res);
            return res;
          } else {
            return { items: [], nextToken: null };
          }
        }),
        first()
      );
  }

  public findPromoterByUsename$(id: string): Observable<IPublisher | null> {
    const now = new Date(new Date().setHours(0, 0, 0, 0));
    const dateTime = { ge: +now };
    const sortDirection = ModelSortDirection.ASC;
    return this.runQuery(getPublisherByUserName, { id, dateTime, sortDirection, limit: 15 }, 'getPublisher', true, true)
      .pipe(first()) as Observable<IPublisher | null>;
  }

  public findEntityByUsename$(username: string): Observable<IEntity | null> {
    const now = new Date(new Date().setHours(0, 0, 0, 0));
    const dateTime = { ge: +now };
    const sortDirection = ModelSortDirection.ASC;
    return this.runQuery(entityShortUrlQuery, {  username, dateTime, sortDirection }, 'entityShortUrlQuery', true, true)
      .pipe(
        map((res: any) => res?.items?.length ? res?.items[0] : null),
        tap((res: IEntity) => {
          if (res) {
            // add impressions analytics
            this.addPlacesImpressions([res]);
          }
        }),
        first()
      ) as Observable<IEntity | null>;
  }

  public findEventByUsename$(username: string, type?: string): Observable<IPlace | null> {
    const now = new Date(new Date().setHours(0, 0, 0, 0));
    const dateTime = { ge: +now };
    const sortDirection = ModelSortDirection.ASC;
    return this.runQuery(
      type === 'REPEAT' ? repeatEventShortUrlQuery : eventShortUrlQuery,
      { username, dateTime, sortDirection },
      type === 'REPEAT' ? 'repeatEventShortUrlQuery' : 'eventShortUrlQuery'
    )
      .pipe(
        map((res: any) => res?.items?.length ? res?.items[0] : null),
        tap((res: IEvent | IRepeatEvent) => {
          if (res) {
            // add impressions analytics
            this.addEventsImpressions([res]);
          }
        }),
        first()
      ) as Observable<IPlace | null>;
  }


  private getDaysOfWeekToSearch(filter: any): number[] | null {

    if (!filter?.start || !filter?.end) {
      return null;
    }

    const startDay: moment.Moment = moment(filter.start);
    const endDay: moment.Moment = moment(filter.end);
    const daysDiff = +endDay.diff(startDay, 'd');

    if (daysDiff >= 7) {
      return null;
    } else {
      if (daysDiff === 1) {
        return [startDay.day()];
      }
      const start: number = startDay.day();
      const daysUntilEndWeek: number = 6 - start;
      let outputArray: number[] = [start];
      let i: number = 0;
      if (daysDiff > daysUntilEndWeek) {
        while (i < daysDiff) {
          outputArray.push(start + i);
          i++;
        }
        i = 0;
        while (i < (daysUntilEndWeek - daysDiff)) {
          outputArray.push(i);
          i++;
        }
      } else {
        while (i < daysDiff) {
          outputArray.push(start + i);
          i++;
        }
      }
      if (outputArray?.length) {
        outputArray = [...new Set(outputArray)];
      }
      return outputArray;
    }


  }

  public addPlacesImpressions(places: IEntity[]) {
    places.forEach((e: IEntity) => {
      this.analytics.trackEvent('Venue Impression', 'Web App', e.title);
      this.utils.addCount(e.id, e.publisherId, ParentType.PUBLISHER, 'impressions', 'entity', 1, e?.city);
      this.utils.addCount(e.id, e.publisherId, ParentType.PUBLISHER, 'seen', 'entity', 1, e?.city);
    });
  }

  public addEventsImpressions(events: (IEvent | IRepeatEvent)[]) {
    events.forEach((e: IEvent | IRepeatEvent) => {
      this.analytics.trackEvent('Event Impression', 'Web App', e.title);
      this.utils.addCount(e.id, e.publisherId, e.ownerType, 'impressions', e.type.toLowerCase() + '-event', 1);
    });
  }

}
