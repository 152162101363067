import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysOfWeek'
})
export class DaysOfWeekPipe implements PipeTransform {

  transform(days: number[] | number): any {
    const weekday = new Array(7);

    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';
    weekday[0] = 'Sunday';
    weekday[7] = 'Everyday';
    weekday[8] = 'Weekdays';
    weekday[9] = 'Weekends';
    let outputString: string;
    if (typeof (days) !== 'object') {
      if (days === 7) { return 'Everyday' }
      return weekday[+days];
    } else {
      if (days?.length) {
        days = days.sort((a, b) => a - b);
        if (days[days.length -1] === 0) {
          const sunday: number = days.splice(days.length -1, 1)[0];
          days.push(0);
        }
        for (let i = 0; i < days?.length; i++) {
          if (i === 0) {
            outputString = weekday[days[i]];
          } else {
            outputString += ', ' + weekday[days[i]];
          }
        }
      }
    }

    if (!outputString) {
      outputString = 'Everyday';
    }

    return outputString;
  }


}
