/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendSnsToTopic = /* GraphQL */ `
  query SendSnsToTopic($payload: PushPayload!, $env: String) {
    sendSNSToTopic(payload: $payload, env: $env)
  }
`;
export const unsubscribeFromTopic = /* GraphQL */ `
  query UnsubscribeFromTopic($endpointArns: [String]) {
    unsubscribeFromTopic(endpointArns: $endpointArns)
  }
`;
export const toggleUserEventSubscription = /* GraphQL */ `
  query ToggleUserEventSubscription(
    $userEventId: String
    $type: String
    $tokens: [UserPushTokenInput]
    $arns: [String]
  ) {
    toggleUserEventSubscription(
      userEventId: $userEventId
      type: $type
      tokens: $tokens
      arns: $arns
    )
  }
`;
export const subscribeToFrom = /* GraphQL */ `
  query SubscribeToFrom(
    $parentId: ID
    $subscribe: Boolean
    $type: String
    $userId: ID
    $tokens: [UserPushTokenInput]
    $onlySNS: Boolean
  ) {
    subscribeToFrom(
      parentId: $parentId
      subscribe: $subscribe
      type: $type
      userId: $userId
      tokens: $tokens
      onlySNS: $onlySNS
    )
  }
`;
export const addUserPoints = /* GraphQL */ `
  query AddUserPoints($input: UserPointsInput) {
    addUserPoints(input: $input)
  }
`;
export const addUserToEvent = /* GraphQL */ `
  query AddUserToEvent(
    $userId: ID
    $eventId: ID
    $attending: Boolean
    $tokens: [UserPushTokenInput]
    $type: String
    $env: String
  ) {
    addUserToEvent(
      userId: $userId
      eventId: $eventId
      attending: $attending
      tokens: $tokens
      type: $type
      env: $env
    )
  }
`;
export const addLoyaltyPoints = /* GraphQL */ `
  query AddLoyaltyPoints(
    $parentId: ID
    $type: String!
    $userId: ID!
    $points: Int!
    $env: String
  ) {
    addLoyaltyPoints(
      parentId: $parentId
      type: $type
      userId: $userId
      points: $points
      env: $env
    ) {
      code
      data {
        id
        userId
        isNewSubscriber
        reward {
          title
          description
          rewardType
          loyaltyPointsRequired
        }
        publisher {
          title
          logo
          id
        }
        event {
          id
          publisherId
          type
          images
          title
          entity
          city
        }
        entity {
          id
          publisherId
          logo
          images
          title
          city
        }
        totalPoints
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  query DeleteTopic($type: String!, $id: String!) {
    deleteTopic(type: $type, id: $id)
  }
`;
export const createNewSnsApplicationEndpoint = /* GraphQL */ `
  query CreateNewSnsApplicationEndpoint(
    $deviceType: String!
    $deviceToken: String!
    $env: String!
    $deviceName: String
  ) {
    createNewSNSApplicationEndpoint(
      deviceType: $deviceType
      deviceToken: $deviceToken
      env: $env
      deviceName: $deviceName
    )
  }
`;
export const updatePushToken = /* GraphQL */ `
  query UpdatePushToken(
    $endpointArn: String!
    $deviceToken: String!
    $enabled: Boolean!
    $env: String
  ) {
    updatePushToken(
      endpointArn: $endpointArn
      deviceToken: $deviceToken
      enabled: $enabled
      env: $env
    )
  }
`;
export const citiesByLocation = /* GraphQL */ `
  query CitiesByLocation($input: SearchCitiesInput, $env: String) {
    citiesByLocation(input: $input, env: $env) {
      items {
        id
        title
        areas {
          title
          parentArea
          images
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchElasticEvents = /* GraphQL */ `
  query SearchElasticEvents($input: SearchEventsInput, $env: String) {
    searchElasticEvents(input: $input, env: $env) {
      items {
        id
        title
        entityId
        publisherId
        area
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        dateTime
        startDate
        endDate
        closingTime
        rules
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        ownerType
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        username
        menu {
          id
          title
          status
        }
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchElasticFeaturedPlaces = /* GraphQL */ `
  query SearchElasticFeaturedPlaces(
    $input: SearchFeaturedPlacesInput
    $env: String
  ) {
    searchElasticFeaturedPlaces(input: $input, env: $env) {
      items {
        id
        entityId
        eventId
        repeatEventId
        location {
          lat
          lon
        }
        area
        activationDate
        featuredUntil
        paid
        count
        createdAt
        updatedAt
        status
        ttl
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const searchElasticRepeatedEvents = /* GraphQL */ `
  query SearchElasticRepeatedEvents(
    $input: SearchRepeatEventsInput
    $env: String
  ) {
    searchElasticRepeatedEvents(input: $input, env: $env) {
      items {
        id
        title
        entityId
        area
        publisherId
        subtitle
        description
        type
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchElasticPlaces = /* GraphQL */ `
  query SearchElasticPlaces($input: SearchPlacesInput, $env: String) {
    searchElasticPlaces(input: $input, env: $env) {
      items {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchElasticartistEventLocation = /* GraphQL */ `
  query SearchElasticartistEventLocation(
    $filter: SearchElasticArtistEventPositionFilter
    $km: Int
    $limit: Int
    $nextToken: String
    $env: String
  ) {
    searchElasticartistEventLocation(
      filter: $filter
      km: $km
      limit: $limit
      nextToken: $nextToken
      env: $env
    ) {
      items {
        id
        location {
          lat
          lon
        }
        eventDate
        city
        ttl
        createdAt
        updatedAt
        artist {
          id
          title
          country
          spotifyAppUri
          soundcloudAppUri
          appleMusicAppUri
          spotifyUrl
          soundcloudUrl
          appleMusicUrl
          description
          genres
          images
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const addViewCount = /* GraphQL */ `
  query AddViewCount($input: AWSJSON) {
    addViewCount(input: $input)
  }
`;
export const incrementCount = /* GraphQL */ `
  query IncrementCount($input: CountInput) {
    incrementCount(input: $input)
  }
`;
export const addDeductUserPoints = /* GraphQL */ `
  query AddDeductUserPoints(
    $userId: String
    $action: String
    $deduct: Boolean
  ) {
    addDeductUserPoints(userId: $userId, action: $action, deduct: $deduct)
  }
`;
export const activatePublisherProfile = /* GraphQL */ `
  query ActivatePublisherProfile(
    $input: ActivationPayload
    $entityIds: [String]
    $env: String
  ) {
    activatePublisherProfile(input: $input, entityIds: $entityIds, env: $env)
  }
`;
export const stripeCreateCardToken = /* GraphQL */ `
  query StripeCreateCardToken(
    $card: StripeCard
    $id: String
    $name: String
    $email: String
    $env: String
  ) {
    stripeCreateCardToken(
      card: $card
      id: $id
      name: $name
      email: $email
      env: $env
    ) {
      code
      message
    }
  }
`;
export const eposCustomerApi = /* GraphQL */ `
  query EposCustomerApi($requestType: String, $data: AWSJSON) {
    eposCustomerAPI(requestType: $requestType, data: $data)
  }
`;
export const eposCustomerTypeApi = /* GraphQL */ `
  query EposCustomerTypeApi($requestType: String, $data: AWSJSON) {
    eposCustomerTypeAPI(requestType: $requestType, data: $data)
  }
`;
export const sevenRoomsAvailability = /* GraphQL */ `
  query SevenRoomsAvailability($input: SevenRoomsAvailabilityInput!) {
    sevenRoomsAvailability(input: $input)
  }
`;
export const sevenRoomsCreateBooking = /* GraphQL */ `
  query SevenRoomsCreateBooking($input: SevenRoomsBookingInput!) {
    sevenRoomsCreateBooking(input: $input)
  }
`;
export const getConvoLink = /* GraphQL */ `
  query GetConvoLink($id: ID!) {
    getConvoLink(id: $id) {
      id
      status
      createdAt
      updatedAt
      owners
      messages {
        items {
          id
          messageConversationId
          content
          contentType
          author
          entity
          receiver
          owners
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      publisherId
      entityId
      userId
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
    }
  }
`;
export const listConvoLinks = /* GraphQL */ `
  query ListConvoLinks(
    $filter: ModelConvoLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConvoLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        createdAt
        updatedAt
        owners
        messages {
          nextToken
        }
        publisherId
        entityId
        userId
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      messageConversationId
      conversation {
        id
        status
        createdAt
        updatedAt
        owners
        messages {
          nextToken
        }
        publisherId
        entityId
        userId
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      content
      contentType
      author
      entity
      receiver
      owners
      status
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageConversationId
        conversation {
          id
          status
          createdAt
          updatedAt
          owners
          publisherId
          entityId
          userId
        }
        content
        contentType
        author
        entity
        receiver
        owners
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      userName
      userId
      publisher
      bookingType
      entityId
      durationInMinutes
      entityTitle
      owners
      owner
      dateTime
      tableId
      timeString
      provider
      dateString
      dateTimestamp
      people
      notes
      status
      ttl
      createdAt
      updatedAt
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        durationInMinutes
        entityTitle
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        notes
        status
        ttl
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const getSubscription = /* GraphQL */ `
  query GetSubscription($id: ID!) {
    getSubscription(id: $id) {
      id
      topicId
      title
      userId
      type
      subscriptionArn
      entityId
      entityTitle
      status
      createdAt
      updatedAt
      onCreateBooking {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        durationInMinutes
        entityTitle
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        notes
        status
        ttl
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      onUpdateBooking {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        durationInMinutes
        entityTitle
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        notes
        status
        ttl
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      onDeleteBooking {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        durationInMinutes
        entityTitle
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        notes
        status
        ttl
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      onCreateSubscription {
        id
        topicId
        title
        userId
        type
        subscriptionArn
        entityId
        entityTitle
        status
        createdAt
        updatedAt
        onCreateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onUpdateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onDeleteBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onCreateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onUpdateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onDeleteSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onCreateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onUpdateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onDeleteEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        owner
      }
      onUpdateSubscription {
        id
        topicId
        title
        userId
        type
        subscriptionArn
        entityId
        entityTitle
        status
        createdAt
        updatedAt
        onCreateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onUpdateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onDeleteBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onCreateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onUpdateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onDeleteSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onCreateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onUpdateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onDeleteEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        owner
      }
      onDeleteSubscription {
        id
        topicId
        title
        userId
        type
        subscriptionArn
        entityId
        entityTitle
        status
        createdAt
        updatedAt
        onCreateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onUpdateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onDeleteBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onCreateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onUpdateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onDeleteSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onCreateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onUpdateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onDeleteEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        owner
      }
      onCreateEntity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      onUpdateEntity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      onDeleteEntity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      owner
    }
  }
`;
export const listSubscriptions = /* GraphQL */ `
  query ListSubscriptions(
    $filter: ModelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        topicId
        title
        userId
        type
        subscriptionArn
        entityId
        entityTitle
        status
        createdAt
        updatedAt
        onCreateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onUpdateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onDeleteBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onCreateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onUpdateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onDeleteSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onCreateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onUpdateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onDeleteEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getCountryData = /* GraphQL */ `
  query GetCountryData($id: ID!) {
    getCountryData(id: $id) {
      id
      currencies {
        code
        name
        symbol
      }
      flag
      name
      alpha2Code
      alpha3Code
      callingCodes
      timezones
      region
      createdAt
      updatedAt
    }
  }
`;
export const listCountryDatas = /* GraphQL */ `
  query ListCountryDatas(
    $filter: ModelCountryDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountryDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currencies {
          code
          name
          symbol
        }
        flag
        name
        alpha2Code
        alpha3Code
        callingCodes
        timezones
        region
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserReport = /* GraphQL */ `
  query GetUserReport($id: ID!) {
    getUserReport(id: $id) {
      id
      userId
      message
      targetId
      targetType
      createdAt
      updatedAt
    }
  }
`;
export const listUserReports = /* GraphQL */ `
  query ListUserReports(
    $filter: ModelUserReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        message
        targetId
        targetType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      userId
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
      ttl
      feedback
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
        ttl
        feedback
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listBookingByBookDate = /* GraphQL */ `
  query ListBookingByBookDate(
    $entityId: ID
    $dateTimestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingByBookDate(
      entityId: $entityId
      dateTimestamp: $dateTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        durationInMinutes
        entityTitle
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        notes
        status
        ttl
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const listUserBookings = /* GraphQL */ `
  query ListUserBookings(
    $userId: ID
    $dateTimestamp: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserBookings(
      userId: $userId
      dateTimestamp: $dateTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        durationInMinutes
        entityTitle
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        notes
        status
        ttl
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const subscriptionByPublisher = /* GraphQL */ `
  query SubscriptionByPublisher(
    $entityId: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionByPublisher(
      entityId: $entityId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        title
        userId
        type
        subscriptionArn
        entityId
        entityTitle
        status
        createdAt
        updatedAt
        onCreateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onUpdateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onDeleteBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onCreateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onUpdateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onDeleteSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onCreateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onUpdateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onDeleteEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const subscriptionByUser = /* GraphQL */ `
  query SubscriptionByUser(
    $userId: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionByUser(
      userId: $userId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        title
        userId
        type
        subscriptionArn
        entityId
        entityTitle
        status
        createdAt
        updatedAt
        onCreateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onUpdateBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onDeleteBooking {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        onCreateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onUpdateSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onDeleteSubscription {
          id
          topicId
          title
          userId
          type
          subscriptionArn
          entityId
          entityTitle
          status
          createdAt
          updatedAt
          owner
        }
        onCreateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onUpdateEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        onDeleteEntity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const listCountriesByName = /* GraphQL */ `
  query ListCountriesByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCountryDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountriesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currencies {
          code
          name
          symbol
        }
        flag
        name
        alpha2Code
        alpha3Code
        callingCodes
        timezones
        region
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listReportsByDate = /* GraphQL */ `
  query ListReportsByDate(
    $createdAt: Float
    $sortDirection: ModelSortDirection
    $filter: ModelUserReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportsByDate(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        message
        targetId
        targetType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFeedbackByDate = /* GraphQL */ `
  query ListFeedbackByDate(
    $createdAt: Float
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbackByDate(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
        ttl
        feedback
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchBookings = /* GraphQL */ `
  query SearchBookings(
    $filter: SearchableBookingFilterInput
    $sort: SearchableBookingSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchBookings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        publisher
        bookingType
        entityId
        durationInMinutes
        entityTitle
        owners
        owner
        dateTime
        tableId
        timeString
        provider
        dateString
        dateTimestamp
        people
        notes
        status
        ttl
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
      total
    }
  }
`;
export const getCounters = /* GraphQL */ `
  query GetCounters($id: ID!) {
    getCounters(id: $id) {
      id
      parentId
      itemId
      attending
      views
      week
      booked
      subscribed
      unsubscribed
      checkins
      shared
      reviews
      offersClaimed
      loyaltyPoints
      loyaltyCard
      scan
      score
      ttl
      reviewScore
      date
      updatedAt
      createdAt
    }
  }
`;
export const listCounterss = /* GraphQL */ `
  query ListCounterss(
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCounterss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentId
        itemId
        attending
        views
        week
        booked
        subscribed
        unsubscribed
        checkins
        shared
        reviews
        offersClaimed
        loyaltyPoints
        loyaltyCard
        scan
        score
        ttl
        reviewScore
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listCountersByParent = /* GraphQL */ `
  query ListCountersByParent(
    $parentId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountersByParent(
      parentId: $parentId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        itemId
        attending
        views
        week
        booked
        subscribed
        unsubscribed
        checkins
        shared
        reviews
        offersClaimed
        loyaltyPoints
        loyaltyCard
        scan
        score
        ttl
        reviewScore
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listCountersByItem = /* GraphQL */ `
  query ListCountersByItem(
    $itemId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountersByItem(
      itemId: $itemId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        itemId
        attending
        views
        week
        booked
        subscribed
        unsubscribed
        checkins
        shared
        reviews
        offersClaimed
        loyaltyPoints
        loyaltyCard
        scan
        score
        ttl
        reviewScore
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getDailyCounters = /* GraphQL */ `
  query GetDailyCounters($id: ID!) {
    getDailyCounters(id: $id) {
      id
      parentId
      itemId
      attending
      views
      week
      booked
      subscribed
      unsubscribed
      checkins
      shared
      reviews
      offersClaimed
      loyaltyPoints
      loyaltyCard
      scan
      ttl
      date
      updatedAt
      createdAt
    }
  }
`;
export const listDailyCounterss = /* GraphQL */ `
  query ListDailyCounterss(
    $filter: ModelDailyCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyCounterss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentId
        itemId
        attending
        views
        week
        booked
        subscribed
        unsubscribed
        checkins
        shared
        reviews
        offersClaimed
        loyaltyPoints
        loyaltyCard
        scan
        ttl
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listDailyCountersByParent = /* GraphQL */ `
  query ListDailyCountersByParent(
    $parentId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyCountersByParent(
      parentId: $parentId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        itemId
        attending
        views
        week
        booked
        subscribed
        unsubscribed
        checkins
        shared
        reviews
        offersClaimed
        loyaltyPoints
        loyaltyCard
        scan
        ttl
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listDailyCountersByItem = /* GraphQL */ `
  query ListDailyCountersByItem(
    $itemId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyCountersByItem(
      itemId: $itemId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        itemId
        attending
        views
        week
        booked
        subscribed
        unsubscribed
        checkins
        shared
        reviews
        offersClaimed
        loyaltyPoints
        loyaltyCard
        scan
        ttl
        date
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listArtists = /* GraphQL */ `
  query ListArtists(
    $filter: ModelArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        country
        spotifyAppUri
        soundcloudAppUri
        appleMusicAppUri
        spotifyUrl
        soundcloudUrl
        appleMusicUrl
        description
        genres
        images
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArtist = /* GraphQL */ `
  query GetArtist($id: ID!) {
    getArtist(id: $id) {
      id
      title
      country
      spotifyAppUri
      soundcloudAppUri
      appleMusicAppUri
      spotifyUrl
      soundcloudUrl
      appleMusicUrl
      description
      genres
      images
      createdAt
      updatedAt
    }
  }
`;
export const searchArtists = /* GraphQL */ `
  query SearchArtists(
    $filter: SearchableArtistFilterInput
    $sort: SearchableArtistSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchArtists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        country
        spotifyAppUri
        soundcloudAppUri
        appleMusicAppUri
        spotifyUrl
        soundcloudUrl
        appleMusicUrl
        description
        genres
        images
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getArtistEventPosition = /* GraphQL */ `
  query GetArtistEventPosition($id: ID!) {
    getArtistEventPosition(id: $id) {
      id
      location {
        lat
        lon
      }
      eventDate
      city
      ttl
      createdAt
      updatedAt
      artist {
        id
        title
        country
        spotifyAppUri
        soundcloudAppUri
        appleMusicAppUri
        spotifyUrl
        soundcloudUrl
        appleMusicUrl
        description
        genres
        images
        createdAt
        updatedAt
      }
    }
  }
`;
export const listArtistEventPositions = /* GraphQL */ `
  query ListArtistEventPositions(
    $filter: ModelArtistEventPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtistEventPositions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        location {
          lat
          lon
        }
        eventDate
        city
        ttl
        createdAt
        updatedAt
        artist {
          id
          title
          country
          spotifyAppUri
          soundcloudAppUri
          appleMusicAppUri
          spotifyUrl
          soundcloudUrl
          appleMusicUrl
          description
          genres
          images
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchArtistEventPositions = /* GraphQL */ `
  query SearchArtistEventPositions(
    $filter: SearchableArtistEventPositionFilterInput
    $sort: SearchableArtistEventPositionSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchArtistEventPositions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        location {
          lat
          lon
        }
        eventDate
        city
        ttl
        createdAt
        updatedAt
        artist {
          id
          title
          country
          spotifyAppUri
          soundcloudAppUri
          appleMusicAppUri
          spotifyUrl
          soundcloudUrl
          appleMusicUrl
          description
          genres
          images
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getBookingTable = /* GraphQL */ `
  query GetBookingTable($id: ID!) {
    getBookingTable(id: $id) {
      id
      tableNumber
      capacity
      area
      entityId
      ttl
      maxPeople
      minPeople
      bookings {
        items {
          id
          userName
          userId
          publisher
          bookingType
          entityId
          durationInMinutes
          entityTitle
          owners
          owner
          dateTime
          tableId
          timeString
          provider
          dateString
          dateTimestamp
          people
          notes
          status
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      availability {
        startTime
        endTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBookingTables = /* GraphQL */ `
  query ListBookingTables(
    $filter: ModelBookingTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tableNumber
        capacity
        area
        entityId
        ttl
        maxPeople
        minPeople
        bookings {
          nextToken
        }
        availability {
          startTime
          endTime
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEntityBookingTablesByCapacity = /* GraphQL */ `
  query ListEntityBookingTablesByCapacity(
    $entityId: ID
    $minPeople: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityBookingTablesByCapacity(
      entityId: $entityId
      minPeople: $minPeople
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tableNumber
        capacity
        area
        entityId
        ttl
        maxPeople
        minPeople
        bookings {
          nextToken
        }
        availability {
          startTime
          endTime
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCheckIn = /* GraphQL */ `
  query GetCheckIn($id: ID!) {
    getCheckIn(id: $id) {
      id
      userId
      entityId
      fullName
      phone
      partySize
      gender
      dob
      email
      ttl
      location {
        lat
        lon
      }
      createdAt
      updatedAt
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
    }
  }
`;
export const listCheckIns = /* GraphQL */ `
  query ListCheckIns(
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckIns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        entityId
        fullName
        phone
        partySize
        gender
        dob
        email
        ttl
        location {
          lat
          lon
        }
        createdAt
        updatedAt
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      title
      areas {
        title
        location {
          lat
          lon
        }
        parentArea
        images
      }
      description
      country
      continent
      location {
        lat
        lon
      }
      timezoneOffset
      currency
      images
      createdAt
      updatedAt
    }
  }
`;
export const listCitys = /* GraphQL */ `
  query ListCitys(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        areas {
          title
          parentArea
          images
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const findCityByName = /* GraphQL */ `
  query FindCityByName(
    $title: String
    $sortDirection: ModelSortDirection
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findCityByName(
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        areas {
          title
          parentArea
          images
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCitiesByCountry = /* GraphQL */ `
  query ListCitiesByCountry(
    $title: String
    $sortDirection: ModelSortDirection
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCitiesByCountry(
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        areas {
          title
          parentArea
          images
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCitys = /* GraphQL */ `
  query SearchCitys(
    $filter: SearchableCityFilterInput
    $sort: SearchableCitySortInput
    $limit: Int
    $nextToken: String
  ) {
    searchCitys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        areas {
          title
          parentArea
          images
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        entityId
        publisherId
        area
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        dateTime
        startDate
        endDate
        closingTime
        rules
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        ownerType
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        username
        menu {
          id
          title
          status
        }
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      entityId
      publisherId
      area
      subtitle
      description
      type
      score
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      categories
      dateTime
      startDate
      endDate
      closingTime
      rules
      discount
      buyTicketUrl
      canBook
      isOnlineEvent
      featured
      video
      images
      artistsList
      mapImages
      location {
        lat
        lon
      }
      price
      city
      features {
        title
        icon
      }
      attending
      extraInfo {
        title
        content
        url
      }
      promoterIds
      ttl
      views
      whatsappNumber
      phone
      status
      owner
      owners
      ownerType
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      checklist {
        title
        listItems
      }
      tablesAvailable
      menuUrlList {
        title
        url
      }
      menuPDFList {
        title
        url
      }
      menuImages
      username
      menu {
        id
        sections {
          id
          description
          title
          type
          icon
          status
        }
        title
        status
      }
      createdAt
      updatedAt
      attendees {
        items {
          id
          eventDate
          eventId
          userName
          repeatEventId
          type
          userId
          checkInDate
          tablesBooked
          subscriptionArn
          subscriptionArns
          subscriptionActive
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      tables {
        items {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const eventShortUrlQuery = /* GraphQL */ `
  query EventShortUrlQuery(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventShortUrlQuery(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        entityId
        publisherId
        area
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        dateTime
        startDate
        endDate
        closingTime
        rules
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        ownerType
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        username
        menu {
          id
          title
          status
        }
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchEvents = /* GraphQL */ `
  query SearchEvents(
    $filter: SearchableEventFilterInput
    $sort: SearchableEventSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        entityId
        publisherId
        area
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        dateTime
        startDate
        endDate
        closingTime
        rules
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        ownerType
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        username
        menu {
          id
          title
          status
        }
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const listRepeatEvents = /* GraphQL */ `
  query ListRepeatEvents(
    $filter: ModelRepeatEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRepeatEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        entityId
        area
        publisherId
        subtitle
        description
        type
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRepeatEvent = /* GraphQL */ `
  query GetRepeatEvent($id: ID!) {
    getRepeatEvent(id: $id) {
      id
      title
      entityId
      area
      publisherId
      subtitle
      description
      type
      discount
      isOnlineEvent
      score
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      categories
      closingTime
      dayOfWeek
      daysOfWeek
      startTime
      endTime
      showWeather
      buyTicketUrl
      canBook
      ttl
      whatsappNumber
      phone
      mapImages
      video
      images
      artistsList
      featured
      features {
        title
        icon
      }
      location {
        lat
        lon
      }
      price
      rules
      city
      attending
      status
      ownerType
      owner
      promoterIds
      extraInfo {
        title
        content
        url
      }
      owners
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      checklist {
        title
        listItems
      }
      menuUrlList {
        title
        url
      }
      menuPDFList {
        title
        url
      }
      menuImages
      menu {
        id
        sections {
          id
          description
          title
          type
          icon
          status
        }
        title
        status
      }
      username
      createdAt
      updatedAt
      attendees {
        items {
          id
          eventDate
          eventId
          userName
          repeatEventId
          type
          userId
          checkInDate
          tablesBooked
          subscriptionArn
          subscriptionArns
          subscriptionActive
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      tables {
        items {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const repeatEventShortUrlQuery = /* GraphQL */ `
  query RepeatEventShortUrlQuery(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelRepeatEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    repeatEventShortUrlQuery(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        entityId
        area
        publisherId
        subtitle
        description
        type
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchRepeatEvents = /* GraphQL */ `
  query SearchRepeatEvents(
    $filter: SearchableRepeatEventFilterInput
    $sort: SearchableRepeatEventSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchRepeatEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        entityId
        area
        publisherId
        subtitle
        description
        type
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      review
      score
      feedback
      dateOfVisit
      userId
      entityId
      publisherId
      status
      verified
      createdAt
      updatedAt
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        review
        score
        feedback
        dateOfVisit
        userId
        entityId
        publisherId
        status
        verified
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const getUserEvent = /* GraphQL */ `
  query GetUserEvent($id: ID!) {
    getUserEvent(id: $id) {
      id
      eventDate
      eventId
      userName
      repeatEventId
      type
      userId
      checkInDate
      tablesBooked
      subscriptionArn
      subscriptionArns
      subscriptionActive
      title
      createdAt
      updatedAt
      event {
        id
        title
        entityId
        publisherId
        area
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        dateTime
        startDate
        endDate
        closingTime
        rules
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        ownerType
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        username
        menu {
          id
          title
          status
        }
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      repeatEvent {
        id
        title
        entityId
        area
        publisherId
        subtitle
        description
        type
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
    }
  }
`;
export const listUserEvents = /* GraphQL */ `
  query ListUserEvents(
    $filter: ModelUserEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventDate
        eventId
        userName
        repeatEventId
        type
        userId
        checkInDate
        tablesBooked
        subscriptionArn
        subscriptionArns
        subscriptionActive
        title
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const getDailyPromotions = /* GraphQL */ `
  query GetDailyPromotions($id: ID!) {
    getDailyPromotions(id: $id) {
      id
      entityId
      daysAvailable
      startTime
      endTime
      description
      price
      images
      title
      status
      disclaimer
      owner
      subscribersOnly
      createdAt
      updatedAt
    }
  }
`;
export const listDailyPromotionss = /* GraphQL */ `
  query ListDailyPromotionss(
    $filter: ModelDailyPromotionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyPromotionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        daysAvailable
        startTime
        endTime
        description
        price
        images
        title
        status
        disclaimer
        owner
        subscribersOnly
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHelpTopic = /* GraphQL */ `
  query GetHelpTopic($id: ID!) {
    getHelpTopic(id: $id) {
      id
      title
      icon
      color
      subtitle
      index
      articles {
        index
        title
        subtitle
        content
        articles {
          index
          title
          subtitle
          content
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listHelpTopics = /* GraphQL */ `
  query ListHelpTopics(
    $filter: ModelHelpTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelpTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        icon
        color
        subtitle
        index
        articles {
          index
          title
          subtitle
          content
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSpecialOffers = /* GraphQL */ `
  query ListSpecialOffers(
    $filter: ModelSpecialOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        daysAvailable
        description
        title
        images
        weeks
        entityId
        publisherId
        quantity
        status
        loyaltyPointsRequired
        loyaltyPointsValue
        redeemCount
        dateAvailable
        allowanceLimit
        refreshDaysPeriod
        type
        rewardType
        owner
        disclaimer
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSpecialOffer = /* GraphQL */ `
  query GetSpecialOffer($id: ID!) {
    getSpecialOffer(id: $id) {
      id
      daysAvailable
      description
      title
      images
      weeks
      entityId
      publisherId
      quantity
      status
      loyaltyPointsRequired
      loyaltyPointsValue
      redeemCount
      dateAvailable
      allowanceLimit
      refreshDaysPeriod
      type
      rewardType
      owner
      disclaimer
      createdAt
      updatedAt
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const searchSpecialOffers = /* GraphQL */ `
  query SearchSpecialOffers(
    $filter: SearchableSpecialOfferFilterInput
    $sort: SearchableSpecialOfferSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchSpecialOffers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        daysAvailable
        description
        title
        images
        weeks
        entityId
        publisherId
        quantity
        status
        loyaltyPointsRequired
        loyaltyPointsValue
        redeemCount
        dateAvailable
        allowanceLimit
        refreshDaysPeriod
        type
        rewardType
        owner
        disclaimer
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getLoyaltyActivity = /* GraphQL */ `
  query GetLoyaltyActivity($id: ID!) {
    getLoyaltyActivity(id: $id) {
      id
      userPublisherKey
      points
      eventId
      entityId
      publisherId
      repeatEventId
      userId
      adminAllocated
      message
      createdAt
      updatedAt
      event {
        id
        title
        entityId
        publisherId
        area
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        dateTime
        startDate
        endDate
        closingTime
        rules
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        ownerType
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        username
        menu {
          id
          title
          status
        }
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      repeatEvent {
        id
        title
        entityId
        area
        publisherId
        subtitle
        description
        type
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
    }
  }
`;
export const listLoyaltyActivitys = /* GraphQL */ `
  query ListLoyaltyActivitys(
    $filter: ModelLoyaltyActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoyaltyActivitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userPublisherKey
        points
        eventId
        entityId
        publisherId
        repeatEventId
        userId
        adminAllocated
        message
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const listUserLoyaltyActivity = /* GraphQL */ `
  query ListUserLoyaltyActivity(
    $userId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLoyaltyActivity(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userPublisherKey
        points
        eventId
        entityId
        publisherId
        repeatEventId
        userId
        adminAllocated
        message
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const listUserPublisherLoyaltyActivity = /* GraphQL */ `
  query ListUserPublisherLoyaltyActivity(
    $userPublisherKey: String
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPublisherLoyaltyActivity(
      userPublisherKey: $userPublisherKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userPublisherKey
        points
        eventId
        entityId
        publisherId
        repeatEventId
        userId
        adminAllocated
        message
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const listEntityLoyaltyActivity = /* GraphQL */ `
  query ListEntityLoyaltyActivity(
    $entityId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityLoyaltyActivity(
      entityId: $entityId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userPublisherKey
        points
        eventId
        entityId
        publisherId
        repeatEventId
        userId
        adminAllocated
        message
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const listPublisherLoyaltyActivity = /* GraphQL */ `
  query ListPublisherLoyaltyActivity(
    $publisherId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublisherLoyaltyActivity(
      publisherId: $publisherId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userPublisherKey
        points
        eventId
        entityId
        publisherId
        repeatEventId
        userId
        adminAllocated
        message
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const getLoyaltyCard = /* GraphQL */ `
  query GetLoyaltyCard($id: ID!) {
    getLoyaltyCard(id: $id) {
      id
      userId
      publisherId
      ownerType
      owner
      owners
      totalPoints
      totalSpendablePoints
      status
      createdAt
      updatedAt
      publisher {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
    }
  }
`;
export const listLoyaltyCards = /* GraphQL */ `
  query ListLoyaltyCards(
    $id: ID
    $filter: ModelLoyaltyCardFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLoyaltyCards(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        publisherId
        ownerType
        owner
        owners
        totalPoints
        totalSpendablePoints
        status
        createdAt
        updatedAt
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const listUserLoyaltyCards = /* GraphQL */ `
  query ListUserLoyaltyCards(
    $userId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLoyaltyCards(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        publisherId
        ownerType
        owner
        owners
        totalPoints
        totalSpendablePoints
        status
        createdAt
        updatedAt
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const listPublisherLoyaltyCards = /* GraphQL */ `
  query ListPublisherLoyaltyCards(
    $publisherId: ID
    $totalPoints: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoyaltyCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublisherLoyaltyCards(
      publisherId: $publisherId
      totalPoints: $totalPoints
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        publisherId
        ownerType
        owner
        owners
        totalPoints
        totalSpendablePoints
        status
        createdAt
        updatedAt
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const getLoyaltyScan = /* GraphQL */ `
  query GetLoyaltyScan($id: ID!) {
    getLoyaltyScan(id: $id) {
      id
      publisherId
      points
      userId
      redeemed
      expires
      createdAt
      updatedAt
    }
  }
`;
export const listLoyaltyScans = /* GraphQL */ `
  query ListLoyaltyScans(
    $filter: ModelLoyaltyScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoyaltyScans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        publisherId
        points
        userId
        redeemed
        expires
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEntitys = /* GraphQL */ `
  query ListEntitys(
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEntity = /* GraphQL */ `
  query GetEntity($id: ID!) {
    getEntity(id: $id) {
      id
      title
      shortUrl
      subtitle
      area
      summary
      nickname
      config {
        loyaltyEnabled
        canMessage
        autoResponseMessage
        canBook
        bookingConfig {
          type
          canAutoBook
          bookingDurationMins
          areas
        }
        emailsDisabled
        reviewsDisabled
        enableTrackTrace
      }
      description
      categories
      phone
      internationalPhoneNumber
      logo
      websiteUrl
      orderfromTableUrl
      location {
        lat
        lon
      }
      entityTitle
      opens
      closes
      username
      trendingScore
      openHours {
        monday {
          open
          close
          fullDay
          closed
        }
        tuesday {
          open
          close
          fullDay
          closed
        }
        wednesday {
          open
          close
          fullDay
          closed
        }
        thursday {
          open
          close
          fullDay
          closed
        }
        friday {
          open
          close
          fullDay
          closed
        }
        saturday {
          open
          close
          fullDay
          closed
        }
        sunday {
          open
          close
          fullDay
          closed
        }
      }
      status
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      virtualTourUrl
      emailsDisabled
      reviewsDisabled
      video
      city
      currency {
        code
        name
        symbol
      }
      features {
        title
        icon
      }
      entityFeatures {
        images
        title
        description
        bookUrl
        status
      }
      rules
      score
      reviewRating
      reviewCount
      canMessage
      email
      menuUrlList {
        title
        url
      }
      menuPDFList {
        title
        url
      }
      menuImages
      menu {
        id
        sections {
          id
          description
          title
          type
          icon
          status
        }
        title
        status
      }
      price
      featured
      foodDeliveryAppLinks {
        title
        icon
        url
      }
      verified
      publisherId
      subscribeOfferId
      subscriberCount
      pushTimestamp
      extraInfo {
        title
        content
        url
      }
      images
      socials {
        facebook
        twitter
        instagram
      }
      minBookingLimit
      maxBookingLimit
      createdAt
      offersArray
      whatsappNumber
      updatedAt
      conversations {
        items {
          id
          status
          createdAt
          updatedAt
          owners
          publisherId
          entityId
          userId
        }
        nextToken
      }
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      checklist {
        title
        listItems
      }
      safetyChecklist {
        title
        listItems
      }
      bookingTables {
        items {
          id
          tableNumber
          capacity
          area
          entityId
          ttl
          maxPeople
          minPeople
          createdAt
          updatedAt
        }
        nextToken
      }
      checkins {
        items {
          id
          userId
          entityId
          fullName
          phone
          partySize
          gender
          dob
          email
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      repeatEvents {
        items {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          review
          score
          feedback
          dateOfVisit
          userId
          entityId
          publisherId
          status
          verified
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyPromotions {
        items {
          id
          entityId
          daysAvailable
          startTime
          endTime
          description
          price
          images
          title
          status
          disclaimer
          owner
          subscribersOnly
          createdAt
          updatedAt
        }
        nextToken
      }
      offers {
        items {
          id
          daysAvailable
          description
          title
          images
          weeks
          entityId
          publisherId
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          redeemCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          owner
          disclaimer
          createdAt
          updatedAt
        }
        nextToken
      }
      rewards {
        items {
          id
          daysAvailable
          description
          title
          images
          weeks
          entityId
          publisherId
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          redeemCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          owner
          disclaimer
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      publisher {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      tables {
        items {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      subscribers {
        items {
          id
          userId
          entityId
          publisherId
          subscriptionArn
          subscriptionArns
          loyaltyPoints
          credit
          EPOSId
          notificationsEnabled
          type
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const entityShortUrlQuery = /* GraphQL */ `
  query EntityShortUrlQuery(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entityShortUrlQuery(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listEntitiesByArea = /* GraphQL */ `
  query ListEntitiesByArea(
    $area: String
    $score: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntitiesByArea(
      area: $area
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchEntitys = /* GraphQL */ `
  query SearchEntitys(
    $filter: SearchableEntityFilterInput
    $sort: SearchableEntitySortInput
    $limit: Int
    $nextToken: String
  ) {
    searchEntitys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getFeaturedPlace = /* GraphQL */ `
  query GetFeaturedPlace($id: ID!) {
    getFeaturedPlace(id: $id) {
      id
      entityId
      eventId
      repeatEventId
      location {
        lat
        lon
      }
      area
      activationDate
      featuredUntil
      paid
      count
      createdAt
      updatedAt
      status
      ttl
      event {
        id
        title
        entityId
        publisherId
        area
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        dateTime
        startDate
        endDate
        closingTime
        rules
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        ownerType
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        username
        menu {
          id
          title
          status
        }
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      repeatEvent {
        id
        title
        entityId
        area
        publisherId
        subtitle
        description
        type
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const listFeaturedPlaces = /* GraphQL */ `
  query ListFeaturedPlaces(
    $filter: ModelFeaturedPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeaturedPlaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        entityId
        eventId
        repeatEventId
        location {
          lat
          lon
        }
        area
        activationDate
        featuredUntil
        paid
        count
        createdAt
        updatedAt
        status
        ttl
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const listFeaturedPlacesByArea = /* GraphQL */ `
  query ListFeaturedPlacesByArea(
    $area: String
    $paidFeaturedUntil: ModelFeaturedPlaceFeaturedPlacesByAreaCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeaturedPlaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeaturedPlacesByArea(
      area: $area
      paidFeaturedUntil: $paidFeaturedUntil
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        eventId
        repeatEventId
        location {
          lat
          lon
        }
        area
        activationDate
        featuredUntil
        paid
        count
        createdAt
        updatedAt
        status
        ttl
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const searchFeaturedPlaces = /* GraphQL */ `
  query SearchFeaturedPlaces(
    $filter: SearchableFeaturedPlaceFilterInput
    $sort: SearchableFeaturedPlaceSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchFeaturedPlaces(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        eventId
        repeatEventId
        location {
          lat
          lon
        }
        area
        activationDate
        featuredUntil
        paid
        count
        createdAt
        updatedAt
        status
        ttl
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
      }
      nextToken
      total
    }
  }
`;
export const listPublishers = /* GraphQL */ `
  query ListPublishers(
    $filter: ModelPublisherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPublisher = /* GraphQL */ `
  query GetPublisher($id: ID!) {
    getPublisher(id: $id) {
      id
      role
      config {
        features {
          dashboard
          events
          places
          loyalty
          messaging
          offers
          promos
          subscribers
          updates
          reviews
          bookings
        }
        type
        isBespoke
        standAloneVenue
        notificationsEnabled
        mailMarketingProvider
        paymentProvier
        POSProviders
        eventsConfig {
          usePaymentProvider
          tablesEnabled
        }
        bookingsConfig {
          bookingProvider
          integratePOS
          emailsDisabled
        }
        offersConfig {
          integratePOS
        }
        subscribersConfig {
          isPaid
          subscriptionLength
        }
        loyaltyConfig {
          integratePOS
          loyaltyEnabled
          color
          loyaltyPeriodInDays
          logo
          tiersEnabled
          type
          loyaltyPointCurrencyValue
          classes
          securityCode
          useMainLogo
          textPosition
          customCardDesignUrl
          text
        }
        menuConfig {
          integratePOS
        }
      }
      firstName
      lastName
      email
      publisherType
      editors
      owner
      username
      currency {
        code
        name
        symbol
      }
      country {
        currencies {
          code
          name
          symbol
        }
        flag
        name
        alpha2Code
        alpha3Code
        callingCodes
        timezones
        region
      }
      emailsDisabled
      address {
        title
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      timezone
      pushTimestamp
      subscibedToEmails
      timezoneOffset
      logo
      businessName
      description
      websiteUrl
      whatsappNumber
      phone
      socials {
        facebook
        twitter
        instagram
      }
      canMessage
      profilePicture
      subscriberCount
      loyaltyCount
      status
      termsVersionSigned
      activated
      reviewsDisabled
      reviewRating
      reviewCount
      activationDate
      conversations {
        items {
          id
          status
          createdAt
          updatedAt
          owners
          publisherId
          entityId
          userId
        }
        nextToken
      }
      createdAt
      updatedAt
      events {
        items {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      repeatEvents {
        items {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          review
          score
          feedback
          dateOfVisit
          userId
          entityId
          publisherId
          status
          verified
          createdAt
          updatedAt
        }
        nextToken
      }
      offers {
        items {
          id
          daysAvailable
          description
          title
          images
          weeks
          entityId
          publisherId
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          redeemCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          owner
          disclaimer
          createdAt
          updatedAt
        }
        nextToken
      }
      loyaltyCards {
        items {
          id
          userId
          publisherId
          ownerType
          owner
          owners
          totalPoints
          totalSpendablePoints
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      entities {
        items {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        nextToken
      }
      subscribers {
        items {
          id
          userId
          entityId
          publisherId
          subscriptionArn
          subscriptionArns
          loyaltyPoints
          credit
          EPOSId
          notificationsEnabled
          type
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getPublisherByOwner = /* GraphQL */ `
  query GetPublisherByOwner(
    $owner: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPublisherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPublisherByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCategories = /* GraphQL */ `
  query GetCategories($id: ID!) {
    getCategories(id: $id) {
      id
      title
      type
      priority
      isHidden
      translateString
      colour
      image
      subCategories {
        title
        image
        translateString
        icon
        colour
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategoriess = /* GraphQL */ `
  query ListCategoriess(
    $filter: ModelCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoriess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        priority
        isHidden
        translateString
        colour
        image
        subCategories {
          title
          image
          translateString
          icon
          colour
        }
      }
      nextToken
    }
  }
`;
export const getPingConfigs = /* GraphQL */ `
  query GetPingConfigs($id: ID!) {
    getPingConfigs(id: $id) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const listPingConfigss = /* GraphQL */ `
  query ListPingConfigss(
    $filter: ModelPingConfigsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPingConfigss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWebAppUpdate = /* GraphQL */ `
  query GetWebAppUpdate($id: ID!) {
    getWebAppUpdate(id: $id) {
      id
      title
      description
      route
      images
      status
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const listWebAppUpdates = /* GraphQL */ `
  query ListWebAppUpdates(
    $filter: ModelWebAppUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebAppUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        route
        images
        status
        type
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listwebUpdatesByDate = /* GraphQL */ `
  query ListwebUpdatesByDate(
    $status: String
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebAppUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listwebUpdatesByDate(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        route
        images
        status
        type
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTables = /* GraphQL */ `
  query ListTables(
    $filter: ModelTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        capacity
        quantityAvailable
        tablesBooked
        price
        includes {
          quantity
          title
          description
        }
        disclaimer
        instructions
        parentType
        entityId
        eventId
        repeatEventId
        status
        title
        owner
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getTable = /* GraphQL */ `
  query GetTable($id: ID!) {
    getTable(id: $id) {
      id
      capacity
      quantityAvailable
      tablesBooked
      price
      includes {
        quantity
        title
        description
      }
      disclaimer
      instructions
      parentType
      entityId
      eventId
      repeatEventId
      status
      title
      owner
      createdAt
      updatedAt
      event {
        id
        title
        entityId
        publisherId
        area
        subtitle
        description
        type
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        dateTime
        startDate
        endDate
        closingTime
        rules
        discount
        buyTicketUrl
        canBook
        isOnlineEvent
        featured
        video
        images
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
          url
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        status
        owner
        owners
        ownerType
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        tablesAvailable
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        username
        menu {
          id
          title
          status
        }
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      repeatEvent {
        id
        title
        entityId
        area
        publisherId
        subtitle
        description
        type
        discount
        isOnlineEvent
        score
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        closingTime
        dayOfWeek
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        canBook
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        featured
        features {
          title
          icon
        }
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        ownerType
        owner
        promoterIds
        extraInfo {
          title
          content
          url
        }
        owners
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        username
        createdAt
        updatedAt
        attendees {
          nextToken
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
    }
  }
`;
export const getTableBookingRequest = /* GraphQL */ `
  query GetTableBookingRequest($id: ID!) {
    getTableBookingRequest(id: $id) {
      id
      userName
      userId
      paid
      amountPaid
      eventId
      status
      owners
      tableId
      message
      createdAt
      updatedAt
      table {
        id
        capacity
        quantityAvailable
        tablesBooked
        price
        includes {
          quantity
          title
          description
        }
        disclaimer
        instructions
        parentType
        entityId
        eventId
        repeatEventId
        status
        title
        owner
        createdAt
        updatedAt
        event {
          id
          title
          entityId
          publisherId
          area
          subtitle
          description
          type
          score
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          discount
          buyTicketUrl
          canBook
          isOnlineEvent
          featured
          video
          images
          artistsList
          mapImages
          price
          city
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          tablesAvailable
          menuImages
          username
          createdAt
          updatedAt
        }
        repeatEvent {
          id
          title
          entityId
          area
          publisherId
          subtitle
          description
          type
          discount
          isOnlineEvent
          score
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          showWeather
          buyTicketUrl
          canBook
          ttl
          whatsappNumber
          phone
          mapImages
          video
          images
          artistsList
          featured
          price
          rules
          city
          attending
          status
          ownerType
          owner
          promoterIds
          owners
          menuImages
          username
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
      }
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
    }
  }
`;
export const listTableBookingRequests = /* GraphQL */ `
  query ListTableBookingRequests(
    $filter: ModelTableBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTableBookingRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        paid
        amountPaid
        eventId
        status
        owners
        tableId
        message
        createdAt
        updatedAt
        table {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const tableRequestsByTableEvent = /* GraphQL */ `
  query TableRequestsByTableEvent(
    $tableId: ID
    $eventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTableBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tableRequestsByTableEvent(
      tableId: $tableId
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        paid
        amountPaid
        eventId
        status
        owners
        tableId
        message
        createdAt
        updatedAt
        table {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const tableRequestsByUserEvent = /* GraphQL */ `
  query TableRequestsByUserEvent(
    $userId: ID
    $eventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTableBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tableRequestsByUserEvent(
      userId: $userId
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        paid
        amountPaid
        eventId
        status
        owners
        tableId
        message
        createdAt
        updatedAt
        table {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const tableRequestsByEvent = /* GraphQL */ `
  query TableRequestsByEvent(
    $eventId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTableBookingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tableRequestsByEvent(
      eventId: $eventId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        userId
        paid
        amountPaid
        eventId
        status
        owners
        tableId
        message
        createdAt
        updatedAt
        table {
          id
          capacity
          quantityAvailable
          tablesBooked
          price
          disclaimer
          instructions
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const getUpdate = /* GraphQL */ `
  query GetUpdate($id: ID!) {
    getUpdate(id: $id) {
      id
      title
      content
      parentId
      isPinned
      url
      images
      route
      updatedAt
      createdAt
    }
  }
`;
export const listUpdates = /* GraphQL */ `
  query ListUpdates(
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        parentId
        isPinned
        url
        images
        route
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listUpdatesByDate = /* GraphQL */ `
  query ListUpdatesByDate(
    $parentId: ID
    $updatedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdatesByDate(
      parentId: $parentId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        parentId
        isPinned
        url
        images
        route
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const searchUpdates = /* GraphQL */ `
  query SearchUpdates(
    $filter: SearchableUpdateFilterInput
    $sort: SearchableUpdateSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchUpdates(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        parentId
        isPinned
        url
        images
        route
        updatedAt
        createdAt
      }
      nextToken
      total
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      role
      firstName
      lastName
      dob
      email
      gender
      phone
      city
      allowNotifications
      allowLocation
      allowPersonalisation
      allowCalendar
      profilePicture
      subscibedToEmails
      pushTokens {
        token
        device
        endpointArn
        parentApp
      }
      points
      accumulatedPoints
      locale
      categories
      social {
        facebook
        twitter
        instagram
      }
      createdAt
      updatedAt
      termsSigned
      EPOSId
      conversations {
        items {
          id
          status
          createdAt
          updatedAt
          owners
          publisherId
          entityId
          userId
        }
        nextToken
      }
      checkIns {
        items {
          id
          userId
          entityId
          fullName
          phone
          partySize
          gender
          dob
          email
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          review
          score
          feedback
          dateOfVisit
          userId
          entityId
          publisherId
          status
          verified
          createdAt
          updatedAt
        }
        nextToken
      }
      userEvents {
        items {
          id
          eventDate
          eventId
          userName
          repeatEventId
          type
          userId
          checkInDate
          tablesBooked
          subscriptionArn
          subscriptionArns
          subscriptionActive
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      loyaltyCards {
        items {
          id
          userId
          publisherId
          ownerType
          owner
          owners
          totalPoints
          totalSpendablePoints
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      tableBookings {
        items {
          id
          userName
          userId
          paid
          amountPaid
          eventId
          status
          owners
          tableId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      subscriptions {
        items {
          id
          userId
          entityId
          publisherId
          subscriptionArn
          subscriptionArns
          loyaltyPoints
          credit
          EPOSId
          notificationsEnabled
          type
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      offers {
        items {
          id
          userId
          offerId
          activated
          entityId
          publisherId
          type
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getSubscriber = /* GraphQL */ `
  query GetSubscriber($id: ID!) {
    getSubscriber(id: $id) {
      id
      userId
      entityId
      publisherId
      subscriptionArn
      subscriptionArns
      loyaltyPoints
      credit
      EPOSId
      notificationsEnabled
      type
      status
      createdAt
      updatedAt
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
    }
  }
`;
export const listSubscribers = /* GraphQL */ `
  query ListSubscribers(
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        entityId
        publisherId
        subscriptionArn
        subscriptionArns
        loyaltyPoints
        credit
        EPOSId
        notificationsEnabled
        type
        status
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const getClaimedOffer = /* GraphQL */ `
  query GetClaimedOffer($id: ID!) {
    getClaimedOffer(id: $id) {
      id
      userId
      offerId
      activated
      entityId
      publisherId
      type
      status
      createdAt
      updatedAt
      offer {
        id
        daysAvailable
        description
        title
        images
        weeks
        entityId
        publisherId
        quantity
        status
        loyaltyPointsRequired
        loyaltyPointsValue
        redeemCount
        dateAvailable
        allowanceLimit
        refreshDaysPeriod
        type
        rewardType
        owner
        disclaimer
        createdAt
        updatedAt
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
      }
      entity {
        id
        title
        shortUrl
        subtitle
        area
        summary
        nickname
        config {
          loyaltyEnabled
          canMessage
          autoResponseMessage
          canBook
          emailsDisabled
          reviewsDisabled
          enableTrackTrace
        }
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        orderfromTableUrl
        location {
          lat
          lon
        }
        entityTitle
        opens
        closes
        username
        trendingScore
        status
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        virtualTourUrl
        emailsDisabled
        reviewsDisabled
        video
        city
        currency {
          code
          name
          symbol
        }
        features {
          title
          icon
        }
        entityFeatures {
          images
          title
          description
          bookUrl
          status
        }
        rules
        score
        reviewRating
        reviewCount
        canMessage
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        menu {
          id
          title
          status
        }
        price
        featured
        foodDeliveryAppLinks {
          title
          icon
          url
        }
        verified
        publisherId
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
          url
        }
        images
        socials {
          facebook
          twitter
          instagram
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        conversations {
          nextToken
        }
        timelines {
          id
          title
          description
          hideTimes
        }
        checklist {
          title
          listItems
        }
        safetyChecklist {
          title
          listItems
        }
        bookingTables {
          nextToken
        }
        checkins {
          nextToken
        }
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        dailyPromotions {
          nextToken
        }
        offers {
          nextToken
        }
        rewards {
          nextToken
        }
        owner
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        tables {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      publisher {
        id
        role
        config {
          type
          isBespoke
          standAloneVenue
          notificationsEnabled
          mailMarketingProvider
          paymentProvier
          POSProviders
        }
        firstName
        lastName
        email
        publisherType
        editors
        owner
        username
        currency {
          code
          name
          symbol
        }
        country {
          flag
          name
          alpha2Code
          alpha3Code
          callingCodes
          timezones
          region
        }
        emailsDisabled
        address {
          title
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        timezone
        pushTimestamp
        subscibedToEmails
        timezoneOffset
        logo
        businessName
        description
        websiteUrl
        whatsappNumber
        phone
        socials {
          facebook
          twitter
          instagram
        }
        canMessage
        profilePicture
        subscriberCount
        loyaltyCount
        status
        termsVersionSigned
        activated
        reviewsDisabled
        reviewRating
        reviewCount
        activationDate
        conversations {
          nextToken
        }
        createdAt
        updatedAt
        events {
          nextToken
        }
        repeatEvents {
          nextToken
        }
        reviews {
          nextToken
        }
        offers {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        entities {
          nextToken
        }
        subscribers {
          nextToken
        }
      }
      user {
        id
        role
        firstName
        lastName
        dob
        email
        gender
        phone
        city
        allowNotifications
        allowLocation
        allowPersonalisation
        allowCalendar
        profilePicture
        subscibedToEmails
        pushTokens {
          token
          device
          endpointArn
          parentApp
        }
        points
        accumulatedPoints
        locale
        categories
        social {
          facebook
          twitter
          instagram
        }
        createdAt
        updatedAt
        termsSigned
        EPOSId
        conversations {
          nextToken
        }
        checkIns {
          nextToken
        }
        reviews {
          nextToken
        }
        userEvents {
          nextToken
        }
        loyaltyCards {
          nextToken
        }
        tableBookings {
          nextToken
        }
        subscriptions {
          nextToken
        }
        offers {
          nextToken
        }
      }
    }
  }
`;
export const listClaimedOffers = /* GraphQL */ `
  query ListClaimedOffers(
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaimedOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        offerId
        activated
        entityId
        publisherId
        type
        status
        createdAt
        updatedAt
        offer {
          id
          daysAvailable
          description
          title
          images
          weeks
          entityId
          publisherId
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          redeemCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          owner
          disclaimer
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
export const userClaimedOffersBetweenMonths = /* GraphQL */ `
  query UserClaimedOffersBetweenMonths(
    $userId: ID
    $createdAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimedOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userClaimedOffersBetweenMonths(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        offerId
        activated
        entityId
        publisherId
        type
        status
        createdAt
        updatedAt
        offer {
          id
          daysAvailable
          description
          title
          images
          weeks
          entityId
          publisherId
          quantity
          status
          loyaltyPointsRequired
          loyaltyPointsValue
          redeemCount
          dateAvailable
          allowanceLimit
          refreshDaysPeriod
          type
          rewardType
          owner
          disclaimer
          createdAt
          updatedAt
        }
        entity {
          id
          title
          shortUrl
          subtitle
          area
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          orderfromTableUrl
          entityTitle
          opens
          closes
          username
          trendingScore
          status
          virtualTourUrl
          emailsDisabled
          reviewsDisabled
          video
          city
          rules
          score
          reviewRating
          reviewCount
          canMessage
          email
          menuImages
          price
          featured
          verified
          publisherId
          subscribeOfferId
          subscriberCount
          pushTimestamp
          images
          minBookingLimit
          maxBookingLimit
          createdAt
          offersArray
          whatsappNumber
          updatedAt
          owner
        }
        publisher {
          id
          role
          firstName
          lastName
          email
          publisherType
          editors
          owner
          username
          emailsDisabled
          timezone
          pushTimestamp
          subscibedToEmails
          timezoneOffset
          logo
          businessName
          description
          websiteUrl
          whatsappNumber
          phone
          canMessage
          profilePicture
          subscriberCount
          loyaltyCount
          status
          termsVersionSigned
          activated
          reviewsDisabled
          reviewRating
          reviewCount
          activationDate
          createdAt
          updatedAt
        }
        user {
          id
          role
          firstName
          lastName
          dob
          email
          gender
          phone
          city
          allowNotifications
          allowLocation
          allowPersonalisation
          allowCalendar
          profilePicture
          subscibedToEmails
          points
          accumulatedPoints
          locale
          categories
          createdAt
          updatedAt
          termsSigned
          EPOSId
        }
      }
      nextToken
    }
  }
`;
