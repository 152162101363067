export const environment = {
  production: true,
  awsEnv: 'prod',
  authEnv: 'prod',
  aws: {
    s3UrlProd: 'https://drdhilyttn5uq.cloudfront.net/public/',
    s3Url: 'https://drdhilyttn5uq.cloudfront.net/public/',
    snsEnv: 'prod',
    videoBucket: 'https://ping-culture-bucket-pool.s3.eu-west-1.amazonaws.com/public',
    pushAppName: 'Ping'
  },
  google: {
    apiKey: 'AIzaSyDHhigxcvZRMYvmQL5E8jRrfiEJWdZymYU',
    clientId: '119283190219-bpgg07kfdfq43ufqh12k0068a66vltg2.apps.googleusercontent.com',
    geo: 'https://maps.googleapis.com/maps/api/geocode/json?latlng='
  },
	baseHref: '/',
  mapbox: {
    accessToken: 'sk.eyJ1Ijoib2xpdmVyYW5kZXJzZW5jb3giLCJhIjoiY2p3ODlsbWJrMDRvdzQ0bWxtNGxwcWMzYiJ9.58O3VtTQhteWfmNFKtMaXw',
    publicToken: 'pk.eyJ1Ijoib2xpdmVyYW5kZXJzZW5jb3giLCJhIjoiY2p2Z3owaGt6MDZnZzQ4bW0wYWI2b3U2ZiJ9.WkovqTNeXYD8ccRNPa8-Vg'
  },
  instagram: {
    appId: '514901727407756',
    secret: '70cda397a0e4e3ef72beeb53f43a0aab',
    accessToken: 'IGQVJVWUxZAOEljVV9kUGRvWXFxaGszRnRxNVFYcFFHclllWEs4Nl82NkVqc2gwRk5pT3B1eU42WDE5aWNwOGxtZAXItWUlrenhEZAklOb3FnNlZADLWxOSDVyMDhXcXBWbXhXdVpIRWJnLUh0bUl0YUlIQgZDZD'
  },
  firebase: {
    apiKey: "AIzaSyAICOcevJGiirv2V4eoFGXUouSKxwukdmw",
    authDomain: "ping-linkin-bio.firebaseapp.com",
    projectId: "ping-linkin-bio",
    storageBucket: "ping-linkin-bio.appspot.com",
    messagingSenderId: "549393374504",
    appId: "1:549393374504:web:a3ce799d1ebf92c440041d",
    measurementId: "G-2YDG34JD2K",
    dynamicLink: 'https://pingapp.page.link'
  }
};
