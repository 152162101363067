import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LocalstorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'ping-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./cookie-bar.component.scss'],
  animations: [
    trigger('fadeUp', [
      state('hide', style({
        bottom: '-100%',
        opacity: '0'
      })),
      state('show', style({
        bottom: '0',
        opacity: '1'
      })),
      transition('hide=>show', animate('500ms')),
      transition('show=>hide', animate('500ms'))
    ]),
  ]
})
export class CookieBarComponent implements OnInit {

  public state: string = "hide";

  constructor(private cd: ChangeDetectorRef,
    private localstorageService: LocalstorageService) { }

  ngOnInit(): void {

    if (!this.localstorageService.getItem('accepted-policies')) {
        this.state = 'show';
        this.cd.detectChanges();
    }

  }

  public accept() {

    this.localstorageService.setItem('accepted-policies', new Date().toISOString());
    this.state = 'hide';
    this.cd.detectChanges();

  }

}
