import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { StoreService } from 'src/app/core/services/store.service';
import { ICategories, ICategory } from 'src/app/core/models/categories';
import { Observable } from 'rxjs';
import { isNotEmpty } from 'src/app/core/guards/type.guards';
import { filter, first, map } from 'rxjs/operators';
import { ICity } from 'src/app/core/models/city';
import { listCategoriess } from 'src/app/core/api/categories/categories.queries'
import { environment } from 'src/environments/environment';
import { LoggerService } from 'src/app/core/services/logger.service';
import { UtilitiesService } from './utilities.service';
@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends ApiService {


  constructor(private store: StoreService,
    private utils: UtilitiesService,
              private logger: LoggerService) {
    super();

    this.store.object$('city').pipe().subscribe((city: ICity) => {
      // update categories list with available categories for the current city
      this.updateCategoriesWithCityFilter(city);
    });
  }

  public getCategories() {
    const currentCategories: any = this.store.getObject('categories');
    if (!currentCategories?.length) {
      this.fetchCategories();
    }
  }

  public get categories$(): Observable<ICategories[]> {
    return this.store.object$<ICategories[]>('categories')
      .pipe(
        filter(isNotEmpty),first())
  }

  public get categories(): ICategories[] {
    const categories = this.store.getObject<ICategories[]>('categories');
    if (categories) {
      return JSON.parse(JSON.stringify(categories));
    } else {
      return null;
    }

  }

  public async listCategories(): Promise<ICategories[]> {
    let categories: ICategories[] = this.store.getObject('categories');
    if (categories?.length) {
      return categories;
    } else {
      return [];
    }
  }

  public updateCategoriesWithCityFilter(city?: ICity) {
    this.store.object$('categories').pipe(filter(isNotEmpty), first())
      .subscribe((res: ICategories[]) => {

        if (!city) {
          city = this.store.getObject('city');
        }
        if (!city) { return res; }

        let cityTitle = city?.nearestCity || city?.title;

        let categories = res ? this.utils.clone(res) : res;

        if (cityTitle === 'around me' || city?.title === 'Dubai' || city?.parentCity === 'Dubai') {
          this.store.setObject(`categories-${cityTitle}`, categories);
          return;
        }

        // filer main catgories
        categories = categories.filter((c: ICategories) => {
          if (c?.type === 'EVENT' || c?.type === `EVENT_ADMIN`) {
            return true;
          }
          // For main cities
          if (!c?.categoryCityCount) {
            return false;
          }

          if (!city?.parentCity) {
            if (c?.categoryCityCount[cityTitle] && c?.categoryCityCount[cityTitle]?.count > 1) {
              return true;
            } else {
              return false;
            }
          } else {
            if (c?.categoryCityCount[city.parentCity] && c?.categoryCityCount[city.parentCity]?.areas && c?.categoryCityCount[city.parentCity]?.areas[cityTitle] && c.categoryCityCount[city.parentCity]?.areas[cityTitle]?.count > 1) {
              return true;
            } else {
              return false;
            }
          }

        });
        // Filter subcategories
        if (categories?.length) {
          categories = categories.map((c: ICategories) => {

            if (c?.subCategories?.length && (c?.type !== 'EVENT' && c?.type !== `EVENT_ADMIN`)) {
              c.subCategories = c.subCategories.filter((sub: ICategory) => {
                // For main cities
                if (!sub?.categoryCityCount) {
                  return false;
                }
                if (!city?.parentCity) {

                  if (sub?.categoryCityCount[cityTitle] && sub?.categoryCityCount[cityTitle]?.count > 1) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  if (sub?.categoryCityCount[city.parentCity] && sub?.categoryCityCount[city.parentCity]?.areas && sub?.categoryCityCount[city.parentCity]?.areas[cityTitle] && sub?.categoryCityCount[city.parentCity]?.areas[cityTitle]?.count > 1) {
                    return true;
                  } else {
                    return false;
                  }
                }
              })
            }
            return c;
          });

        }

        this.store.setObject(`categories-${cityTitle}`, categories);
      });
  }

  public async fetchCategories(): Promise<ICategories[]> {
    try {
      const res: any = await this.runQuery(
        listCategoriess,
        { limit: 200 },
        'listCategoriess',
         true, true
      ).pipe(first()).toPromise();
      if (!res || !res.items) {
        console.log('No categories found...');
        return;
      }

      let categories: ICategories[] = res?.items.map((category: ICategories) => {
        category.image = category?.image ? category.image.replace(/\s/g, '+') : null;
        category.categoryCityCount = category?.categoryCityCount ? JSON.parse(category.categoryCityCount) : null;
        if (category?.subCategories?.length) {
          category.subCategories = category.subCategories.map((s: ICategories) => {
            s.categoryCityCount = s?.categoryCityCount ? JSON.parse(s.categoryCityCount) : null;
            s.image = s?.image ? s.image.replace(/\s/g, '+') : null;
            return s;
          }
          )
        }
        return category;
      });
      categories = categories.sort((a: ICategories, b: ICategories) => a?.priority > b?.priority ? -1 : 1);
      if (categories?.length) {
        const dateIndex: number = categories.findIndex((cat: ICategories) => cat.id === 'Date Night');
        if (dateIndex > -1) {
          const dateCat: ICategories = categories[dateIndex];
          categories.splice(dateIndex, 1);
          categories.unshift(dateCat);
        }
      }
      this.store.setObject('categories', categories);
      return categories;
    } catch (err) {
      this.logger.logError(err);
    }
  }


  /**
   * Get Category Image
   * If an item has not been assigned an image, the category iage will be used
   * This extracts the category image from the array and returns the image file name
   * Returns a promise as a string
   */
  public async getCategoryImage(title: string): Promise<string> {
    let categories: ICategories[] = this.store.getObject<ICategories[]>('categories');
    if (!categories?.length) {
      await this.fetchCategories();
    }
    categories = this.store.getObject<ICategories[]>('categories');
    const category: ICategories = categories?.find((c: ICategories) => c.title === title) || null;
    return category?.image || null;
  }


  public async fallbackCategoryImage(parent: any, size?: number): Promise<string> {
    if (!parent.categories?.length) { return null }
    const image: string = await this.getCategoryImage(parent.categories[0]);
    return `${environment.aws.s3Url}categories/${size || 600}/${image}`;
  }


}
