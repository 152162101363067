import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CountInput } from 'src/app/core/api/api';
import { IEntity } from 'src/app/core/models/entity';
import { ApiService, ErrorType } from 'src/app/core/services/api.service';
import { GraphResponse, StoreService } from 'src/app/core/services/store.service';
import { environment } from 'src/environments/environment';
import { incrementCount } from 'src/graphql/queries';
import { DownloadModalComponent } from '../components/download-modal/download-modal.component';
import { IEvent, IRepeatEvent } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService extends ApiService {

  $showSpinner = new BehaviorSubject<boolean>(false);

  constructor(
    private modalService: MatDialog,
    private store: StoreService,
  ) { super(); }


  public validURL(str: string) {
    if (!str) { return null; }
    return str.includes('http') || str.includes('http') || str.includes('www')
  }

  // CUSTOM LOADER
  public get showSpinner$(): Observable<boolean> {
    return this.$showSpinner.asObservable();
  }
  public startSpinner() {
    this.$showSpinner.next(true);
  }
  public stopSpinner() {
    this.$showSpinner.next(false);
  }


  public createGoogleImageUrl(id: string, size: number): string {
    return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=${size || 600}&photoreference=${id}&key=${environment.google.apiKey}&callback=Function.prototype`;
  }


  public shuffle<T>(array: T[]): T[] {
    var currentIndex = array?.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  public encodeRoute(value: string): string {
    if (value) {
      value = value.replace("&", "and");
      value = value.replace(" ", "-");
    }
    return value;
  }

  public decodeRoute(value: string): string {
    if (value) {
      value = this.replaceAll(value, '--', ' ');
      value = this.replaceAll(value, '-', ' ');
      value = this.replaceAll(value, "and", "&");
    }
    return value;
  }

  public htmlToTextFn(html) {
       // Create a new div element
       var tempDivElement = document.createElement("div");

       // Set the HTML content with the given value
       tempDivElement.innerHTML = html;

       // Retrieve the text property of the element
       return tempDivElement.textContent || tempDivElement.innerText || "";

  }

  public replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }
  public escapeRegExp(string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

    /**
   * addCount
   * @param id
   * @param parentId
   * @param parentType
   * @param field
   * @param type
   * @param increment
   * @param city
   */
     public async addCount(id: string, parentId: string, parentType: string, field: string, type: string, increment: number, city?: string): Promise<void> {
        try {
          const env = environment.production ? 'prod' : 'dev';
          const input: CountInput = {
            id,
            field,
            type,
            increment,
            parentId,
            city,
            parentType,
            env
          };
          const data: any = await this.runMutation(incrementCount, { input }, 'incrementCount', true);
          if ((data as ErrorType) === ErrorType.ERROR || (data as ErrorType) === ErrorType.NETWORK) {
            return null;
          }
          const result: any = data.incrementCount === 'Count Added' ? true : false;
          return result;
        } catch (err) {
          return null;
        }
    }


    public urlFriendly(username: string): string {
      username = username.toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-+|-+$/g, "-")
        .replace(/^-+|-+$/g, '');
      username = this.replaceAll(username, 'é', '');
      username = this.replaceAll(username, "'", '');
      username = this.replaceAll(username, '"', '');
      username = this.replaceAll(username, '&', '');
      username = this.replaceAll(username, ':', '');
      username = this.replaceAll(username, '!', '');
      username = this.replaceAll(username, '(', '-');
      username = this.replaceAll(username, ')', '');
      username = username.replace(/[^a-zA-Z0-9-_]/g, '');
      username = username.replace(/[^\x00-\x7F]/g,"");
      username.normalize("NFD").replace(/\p{Diacritic}/gu, "");
      return username;
    }

    public async openDownloadModal(forceOpen?: boolean) {
      if (typeof localStorage !== 'undefined') {
      if (!localStorage.getItem('shownDownloadModal') || forceOpen) {

        if (this.isMobile.Android() || this.isMobile.iOS()) {
            localStorage.setItem('shownDownloadModal', 'true');

            // Wait 30 seconds
            if (!forceOpen) {
              await this.timeout(15000);
            }

            this.modalService.open(DownloadModalComponent, {
              width: "450px",
            });
          }
        }
      }
    }


  public isMobile = {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone | iPad | iPod/i)
    }
  }

  public timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // for virtual scroll to work with the formatting we have
  public splitGraphItemsIntoGroups(graphItems: GraphResponse, groupNumber: number): GraphResponse {
    const copiedGraphItems: GraphResponse = graphItems;
    if (copiedGraphItems?.items?.length) {
      let newItems: any[] = [];
      for (let i = 0; i < copiedGraphItems?.items.length; i += groupNumber) {
        newItems.push(copiedGraphItems?.items.slice(i, i + groupNumber));
      }
      copiedGraphItems.groupedItems = newItems;
    }

    return copiedGraphItems;
  }


  /**
   * clone
   * performs a deep clone of an object
   * @param item
   * @returns
   */
   public clone(item: any): any {
    if (!item) { return item; } // null, undefined values check

    var types = [Number, String, Boolean],
      result;

    // normalizing primitives if someone did new String('aaa'), or new Number('444');
    types.forEach((type) => {
      if (item instanceof type) {
        result = type(item);
      }
    });

    if (typeof result == "undefined") {
      if (Object.prototype.toString.call(item) === "[object Array]") {
        result = [];
        item.forEach((child, index, array) => {
          result[index] = this.clone(child);
        });
      } else if (typeof item == "object") {
        // testing that this is DOM
        if (item.nodeType && typeof item.cloneNode == "function") {
          result = item.cloneNode(true);
        } else if (!item.prototype) { // check that this is a literal
          if (item instanceof Date) {
            result = new Date(item);
          } else {
            // it is an object literal
            result = {};
            for (var i in item) {
              result[i] = this.clone(item[i]);
            }
          }
        } else {
          // depending what you would like here,
          // just keep the reference, or create new object
          if (false && item.constructor) {
            // would not advice to do that, reason? Read below
            result = new item.constructor();
          } else {
            result = item;
          }
        }
      } else {
        result = item;
      }
    }

    return result;
  }

 public async getUserLocation(): Promise<any> {
    try {
      const currentLocation: any = await this.execJSONP('https://geolocation-db.com/jsonp', 'callback');
      if (currentLocation?.city) {
        this.store.setObject('userCity', currentLocation.city);
      }
      if (currentLocation?.country_name) {
        this.store.setObject('userCountry', currentLocation.country_name);
      }
      if (currentLocation?.latitude && currentLocation?.longitude) {
        this.store.setObject('userLocation', {lat: currentLocation?.latitude, lon: currentLocation?.longitude});
      }
      return currentLocation || null;
    } catch(err) {
      console.error(err);
      return null;
    }
  }

  public execJSONP(url: string, callbackName: string) {

    return new Promise(function(resolve, reject) {

      let id = '_' + Math.round(10000 * Math.random());

      window[callbackName] = function(data) {
          delete window[callbackName];
          let ele = document.getElementById(id);
          ele.parentNode.removeChild(ele);
          window[id] = null;
          delete window[id];
          resolve(data)
      }

      let src = url;

      let script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.addEventListener('error', reject);
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }


  public distanceBetweenCoords(lat1: number, lon1: number, lat2: number, lon2: number, unit: string) {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
  }

}
