export enum DeviceType {
    ANDROID = 'android',
    IOS = 'ios',
    PWA = 'pwa'
}

export enum Environment {
    DEV = 'DEV',
    PROD = 'PROD'
}

export enum Status {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PENDING = 'PENDING',
    DELETED = 'DELETED'
}

export enum SNSSubscriptionType {
    PLACE = 'ENTITY',
    PROMOTER = 'PROMOTER',
    EVENT = 'EVENT'
}

export enum EventType {
    DEFAULT = 'DEFAULT',
    REPEAT = 'REPEAT'
}
export enum ParentType {
    ENTITY = 'ENTITY',
    PUBLISHER = 'PUBLISHER',
    PROMOTER = 'PROMOTER',
    EVENT ='EVENT',
    REPEAT = 'REPEAT'
}

export enum IPingAwardType {
  WINNER = 'WINNER',
  RUNNER_UP = 'RUNNER UP',
  SHORT_LIST = 'SHORT LIST'
}
