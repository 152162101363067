import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";
import { CookieBarComponent } from "./shared/components/cookie-bar/cookie-bar.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import {LayoutModule} from '@angular/cdk/layout';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { CitiesResolver } from "./modules/places/resolvers/cities.resolver";
import { CategoriesResolver } from "./modules/places/resolvers/categories.resolver";
import { PlacesListResolver } from "./modules/places/resolvers/places-page.resolver";
import { EventsListResolver } from "./modules/events/resolvers/events-list.resolver";
import { ArtistsPageResolver } from "./modules/artists/resolvers/artists-page.resolver";
import { BlogsResolver } from "./modules/blog/resolvers/blog-places-page.resolver";
import { SidebarBlogsResolver } from "./modules/blog/resolvers/sidebar-blogs.resolver";
import { ArtistPageResolver } from "./modules/artists/resolvers/artist-page.resolver";
import { ArtistService } from "./modules/artists/services/artist.service";
import { DownloadModalComponent } from "./shared/components/download-modal/download-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [AppComponent, NotFoundComponent, CookieBarComponent,
    DownloadModalComponent,
],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    CoreModule,
    LayoutModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    // ServiceWorkerModule.register('ngsw-worker.js'),
  ],
  providers: [
    CategoriesResolver,
    CitiesResolver,
    PlacesListResolver,
    EventsListResolver,
    BlogsResolver,
    SidebarBlogsResolver,
    ArtistsPageResolver,
    ArtistPageResolver,
    ArtistService,
    // {
      //   provide: SwRegistrationOptions,
      //   useFactory: () => ({ enabled: environment.production }),
      // },
  ],
  entryComponents: [
    DownloadModalComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
