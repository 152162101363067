import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { isNotEmpty } from 'src/app/core/guards/type.guards';
import { SearchService } from 'src/app/shared/services/search.service';
import { IArtist } from 'src/app/shared/interfaces';
import { GraphResponse } from 'src/app/core/models/aws';
import { SeoService } from 'src/app/shared/services/seo.service';

@Injectable()
export class ArtistsPageResolver implements Resolve<GraphResponse<IArtist>> {

    constructor(
        private searchService: SearchService,
        @Inject(PLATFORM_ID) private platformId,
        private router: Router,
        private seoService: SeoService,
        private transferState: TransferState) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const cityParam = route.params?.city;
        const areaParam = route.params?.area;
        let city: string = cityParam ? this.decodeRoute(cityParam) : null;
        if (!city && typeof localStorage !== 'undefined') {
          city = localStorage.getItem('city') || null;
        }

        let area: string = areaParam ? this.decodeRoute(areaParam) : null;
        this.applySEO(city, area);
        const KEY = makeStateKey<any>('artists');
        if (this.transferState.hasKey(KEY)) {
            const artists: GraphResponse<IArtist> = this.transferState.get<GraphResponse<IArtist>>(KEY, null);
            this.transferState.remove(KEY);
            return of(artists);
        }
        else {
            return from(this.searchService.getTendingArtists(city || null, null, 30))
                .pipe(
                    filter(isNotEmpty),
                    map((artists: any) => {
                        return artists
                    }),
                    tap(artists => {
                        if (isPlatformServer(this.platformId)) {
                            this.transferState.set(KEY, artists);
                        }
                    }),
                    first()
                );
        }
    }


    public decodeRoute(value: string): string {
      if (value) {
        value = this.replaceAll(value, '--', ' ');
        value = this.replaceAll(value, '-', ' ');
        value = this.replaceAll(value, "and", "&");
      }
      return value;
    }

    public replaceAll(str: string, find: string, replace: string): string {
      return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }
    public escapeRegExp(string): string {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    private applySEO(city?: string, area?: string) {
      if (city) {
        this.seoService.updateMeta(
          `Best music artists and events ${city ? 'in ' + city : 'in your area'} - Ping Culture`,
          `See the best local events and artists ${city || 'your area'} has to offer. Music links, events, live-updating set times and much more.`,
          this.router?.url || null,
          'https://ping-culture.com/assets/images/artists-blue.jpg'
        );
      } else {
        this.seoService.updateMeta(
          `Best music artists and events ${area ? 'in ' + area : 'in your area'} - Ping Culture`,
          `See the best local events and artists ${area || 'your area'} has to offer. Music links, events, live-updating set times and much more.`,
          this.router?.url || null,
          'https://ping-culture.com/assets/images/artists-blue.jpg'
        );
      }

      // if a places list page then list canonical
      if (this.router.url.includes('ping-culture.com/artists')) {
        this.seoService.createLinkForCanonicalURL('https://ping-culture.com/artists');
      }
    }
}
