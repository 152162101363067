import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { StoreService } from 'src/app/core/services/store.service';
import { isNotEmpty } from 'src/app/core/guards/type.guards';
import { ICity } from 'src/app/core/models/city';
import { CitiesService } from 'src/app/shared/services/cities.service';

@Injectable()
export class CitiesResolver implements Resolve<ICity[]> {

    constructor(
        private citiesService: CitiesService,
        private store: StoreService,
        @Inject(PLATFORM_ID) private platformId,
        private transferState:TransferState) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICity[]> {

      if (!isPlatformServer(this.platformId)) {
        return of(null);
      }

      const CITIES_KEY = makeStateKey<ICity[]>('cities');

      if (this.transferState.hasKey(CITIES_KEY)) {
        const cities: ICity[] = this.transferState.get<ICity[]>(CITIES_KEY, null);
        this.transferState.remove(CITIES_KEY);
        return of(cities);
      }
      else {
          this.citiesService.fetchCities()
            return this.store.object$<ICity[]>('cities')
                .pipe(
                    filter(isNotEmpty),
                    tap(c => {
                        if (isPlatformServer(this.platformId)) {
                            this.transferState.set(CITIES_KEY, c);
                        }
                    }),
                    first()
                );
        }
    }
}
