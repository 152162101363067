export const listBlogPosts = /* GraphQL */ `
  query ListBlogPosts(
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postType
        title
        desc
        publishStatus
        city
        area
        videoUrl
        entities (limit: 1) {
          items {
            entity {
              images
            }
          }
        }
        headerImg
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const blogPostsByReadCountShortQuery = /* GraphQL */ `
  query BlogPostsByReadCountQuery(
    $city: String
    $readCount: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogPostsByReadCountQuery(
      city: $city
      readCount: $readCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        title
        desc
        city
        area
        headerImg
        readCount
        shareCount
        metaDesc
        metaTitle
        videoUrl
        instagram
        twitter
        website
        facebook
        userId
        publishStatus
        owners
        ttl
        content {
          title
          image
          content
        }
        entities (limit: 1) {
          items {
            entity {
              images
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listBlogPostByStatus = /* GraphQL */ `
  query ListBlogPostByStatus(
    $publishStatus: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPostByStatus(
      publishStatus: $publishStatus
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        title
        desc
        city
        publishStatus
        area
        videoUrl
        entities (limit: 1) {
          items {
            entity {
              images
            }
          }
        }
        headerImg
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const searchBlogPosts = /* GraphQL */ `
  query SearchBlogPosts(
    $filter: SearchableBlogPostFilterInput
    $sort: SearchableBlogPostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBlogPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        postType
        title
        desc
        city
        area
        catgories
        headerImg
        readCount
        shareCount
        metaDesc
        metaTitle
        videoUrl
        instagram
        twitter
        website
        facebook
        userId
        publishStatus
        owners
        ttl
        content {
          title
          image
          content
        }
        createdAt
        updatedAt
        user {
          id
          cognitoUsername
          financeEmail
          role
          profilePicture
          firstName
          lastName
        }
        entities (limit: 1) {
          items {
            entity {
              images
            }
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const subscribeToEmailDatabase = /* GraphQL */ `
  query SubscribeToEmailDatabase($input: AWSJSON) {
    subscribeToEmailDatabase(input: $input)
  }
`;

export const blogPostsByEntityQuery = /* GraphQL */ `
  query BlogPostsByEntityQuery(
    $entityId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogPostsByEntityQuery(
      entityId: $entityId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        summary
        blogPostId
        blogPost {
          id
          postType
          title
          desc
          publishStatus
          city
          area
          videoUrl
          headerImg
          ttl
          updatedAt
        }
      }
    }
  }
`;
