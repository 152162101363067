import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeIntToString'
})
export class TimeIntToStringPipe implements PipeTransform {

  transform(time: number, ...args: any[]): any {
    if (time === 0) { return '12am' }

      return time > 12 ? `${this.mapTime(time - 12)} pm` : `${this.mapTime(time)} am`;
    

  }

  private mapTime(hours: number) {
    var sign = hours < 0 ? "-" : "";
    var min = Math.floor(Math.abs(hours));
    var sec = Math.floor((Math.abs(hours) * 60) % 60);
    return (min === 0 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  }

}
