import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { isNotEmpty } from 'src/app/core/guards/type.guards';
import { IEntity } from 'src/app/core/models/entity';
import { ArtistService } from '../services/artist.service';
import { IArtist } from 'src/app/shared/interfaces';
import { SeoService } from 'src/app/shared/services/seo.service';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';

@Injectable()
export class ArtistPageResolver implements Resolve<IArtist> {

    constructor(
        private artistService: ArtistService,
        @Inject(PLATFORM_ID) private platformId,
        private seoService: SeoService,
        private router: Router,
        private utils: UtilitiesService,
        private transferState:TransferState) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IArtist> {

      this.utils.startSpinner();
        const id = route.params?.id;
        const KEY = makeStateKey<IArtist>('artist-' + id);
        if (this.transferState.hasKey(KEY)) {
            const artist: IArtist = this.transferState.get<IArtist>(KEY, null);
            this.transferState.remove(KEY);
            return of(artist);
        }
        else {
            return this.artistService.artist$(id)
                .pipe(
                    filter(isNotEmpty),
                    tap(en => {
                      this.applySEO(en);
                        if (isPlatformServer(this.platformId)) {
                            this.transferState.set(KEY, en);
                        }
                    }),
                    first()
                );
        }
    }

    private applySEO(artist: IArtist) {
      this.seoService.updateMeta(
        `${artist.title} - Ping Culture`,
        `See ${artist.title}'s music and upcoming events. See their line-ups and set times for all their events.`,
        this.router?.url || null,
        artist?.images?.length ? environment.aws.s3Url + "artist/" + artist.images[0] : 'https://ping-culture.com/assets/images/artists-blue.jpg'
      );

      this.seoService.createLinkForCanonicalURL(`https://ping-culture.com/artists/${artist.id}`);

    }
}
