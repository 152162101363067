export const listCitys = /* GraphQL */ `query ListCitys(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        venueCount
        areas {
          title
          parentArea
          location {
            lat
            lon
          }
        }
        description
        country
        continent
        location {
          lat
          lon
        }
        timezoneOffset
        currency
        images
      }
      nextToken
    }
  }
  `;
  