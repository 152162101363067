import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { StoreService } from 'src/app/core/services/store.service';
import { isNotEmpty } from 'src/app/core/guards/type.guards';
import { IEntity } from 'src/app/core/models/entity';
import { PlacesSearchFilter, SearchService } from 'src/app/shared/services/search.service';
import { GraphResponse } from 'src/app/core/models/aws';
import { SeoService } from 'src/app/shared/services/seo.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';

@Injectable()
export class PlacesListResolver implements Resolve<GraphResponse<IEntity>> {

    constructor(
        private store: StoreService,
        private searchService: SearchService,
        @Inject(PLATFORM_ID) private platformId,
        private seoService: SeoService,
        private router: Router,
        private utils: UtilitiesService,
        private transferState:TransferState) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GraphResponse<IEntity>> {

      this.utils.startSpinner();

        if (!isPlatformServer(this.platformId)) {
          return of(null);
        }

        let city = route.params['city'];
        let area = route.params['area'];
        let category = route.params['category'];
        let subCategory = route.params['subCategory'];
        let cuisine = route.params['cuisine'];

        let categoriesCombined: string[];
        if (category) {
          categoriesCombined = [category];
          if (subCategory) {
            categoriesCombined.push(subCategory);
          }
        }

        let placesFilter: PlacesSearchFilter = {};

        if (city) {
          city = this.decodeRoute(city);
        } else if (typeof localStorage !== 'undefined') {
          city = localStorage.getItem('city') || 'London';
        } else {
          city = 'London';
        }

        placesFilter['city'] = city;

        if (area) {
          area = this.decodeRoute(area);
          placesFilter['area'] = area;
        }
        if (cuisine) {
          area = this.decodeRoute(cuisine);
          placesFilter['cuisine'] = cuisine;
        }
        if (categoriesCombined?.length) {
          placesFilter['categories'] = categoriesCombined;
        }

        const placesKeyString = `placeslist${city ? '-' + city : ''}${area ? '-' + area : ''}${category ? '-' + category : ''}${subCategory ? '-' + subCategory : ''}`;
        const PLACES_KEY = makeStateKey<GraphResponse<IEntity>>(placesKeyString);
        this.updateSEO(city, area, category, subCategory, cuisine);

        if (this.transferState.hasKey(PLACES_KEY)) {
          const places: GraphResponse<IEntity> = this.transferState.get<GraphResponse<IEntity>>(PLACES_KEY, null);
          this.transferState.remove(PLACES_KEY);
          return of(places);
        } else {
          this.searchService.searchForPlaces(placesFilter);
          return this.store.list$<GraphResponse<IEntity>>('places-entities')
          .pipe(
            filter(isNotEmpty),
            tap(p => {
              if (isPlatformServer(this.platformId)) {
                this.transferState.set(PLACES_KEY, p);
              }
                }),
                first()
            );
      }
    }

    public updateSEO(city?: string, area?: string, category?: string, subCategory?: string, cuisine?: string) {
      area = area ? area + ', ' + city : city || null;
      category = (subCategory ? subCategory + ' - ' : '') + (category ? category : '');
      category = category ? category?.toLowerCase() : null

      // if a places list page then list canonical
      if (this.router.url.includes('ping-culture.com/places')) {
        this.seoService.createLinkForCanonicalURL('https://ping-culture.com/places');
      }

      this.seoService.updateMeta(
        `Best ${cuisine ? cuisine + ' ' : ''}${category || 'Places'} ${area ? 'in ' + area : 'in your area'} - Ping Culture`,
        `Explore a range of ${cuisine ? cuisine + ' ' : ''}${category ? category + ' ' : ''}venues for events ${area ? 'in ' + area : 'in your area'} online at Ping Culture. You can also check which offer best-in-class services at a low cost.`,
        this.router?.url || null,
        'https://ping-culture.com/assets/images/places.jpg'
      );
    }

    public decodeRoute(value: string): string {
      if (value) {
        value = this.replaceAll(value, '--', ' ');
        value = this.replaceAll(value, '-', ' ');
        value = this.replaceAll(value, "and", "&");
      }
      return value;
    }

    public replaceAll(str: string, find: string, replace: string): string {
      return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }
    public escapeRegExp(string): string {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
}
