export const listCategoriess = /* GraphQL */ `query ListCategoriess(
    $filter: ModelCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoriess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        image
        type
        icon
        desc
        pingEditTitle
        metaTitle
        discoverTitle
        metaDescription
        priority
        isHidden
        colour
        categoryCityCount
        pinToDiscover,
        subCategories {
          title
          image
          desc
          pingEditTitle
          metaTitle
          metaDescription
          colour
          categoryCityCount
        }
      }
      nextToken
    }
  }
  `;
