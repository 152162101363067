
export const getArtist = /* GraphQL */ `
query GetArtist($id: ID!) {
  getArtist(id: $id) {
    id
    title
    country
    spotifyAppUri
    soundcloudAppUri
    appleMusicAppUri
    spotifyUrl
    soundcloudUrl
    appleMusicUrl
    description
    genres
    images
  }
}
`;