import { Pipe, PipeTransform } from '@angular/core';
import { DailyOpeningTimesInput } from 'src/app/core/api/api';

@Pipe({
  name: 'openHoursToday'
})
export class OpenHoursTodayPipe implements PipeTransform {
  private days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  constructor() { }

  transform(openHours: DailyOpeningTimesInput, closeOnly?: boolean, dayToCheck?: number): any {
    if (!openHours) {
      return 'Temporarily closed'
    } else {
      if (openHours?.monday?.closed &&
        openHours?.thursday?.closed &&
        openHours?.tuesday?.closed &&
        openHours?.wednesday?.closed &&
        openHours?.friday?.closed &&
        openHours?.saturday?.closed &&
        openHours?.sunday?.closed) {
        return 'Temporily closed';
      }
      // const date = new Date();
      const date = new Date();

      // if passed an overriding day to check
      const dayIndex = dayToCheck || date.getDay();
      const previousDayIndex = dayToCheck === 0 || dayIndex === 0 ? 6 : dayIndex - 1;
      const day = this.days[dayIndex];
      const previousDay = this.days[previousDayIndex];
      // to help with the logic
      if (openHours[day].open === 0) { openHours[day].open = 24; }
      if (openHours[day].close === 0) { openHours[day].close = 24; }
      if (openHours[previousDay].open === 0) { openHours[previousDay].open = 24; }
      if (openHours[previousDay].close === 0) { openHours[previousDay].close = 24; }

      if (openHours && (openHours[day] || openHours[previousDay]) ) {
        let hours = openHours[day];
        const previousHours = openHours[previousDay];
        const hourNow: number = date.getHours();
        let isOpen: boolean = false;

        // check if close time is next morning/day
        if (!dayToCheck && (
            (!openHours[day].open && (openHours[previousDay].open || openHours[previousDay].fullDay) && (hourNow < openHours[previousDay].open))
            ||
            ((openHours[day].open > openHours[day].close) && (hourNow < openHours[day].close))
          ))
          {
            // check opening times based on day before opening time
            if (hourNow < previousHours.open) {
              isOpen = hourNow < (previousHours.close) && hourNow >= 0;
            } else {
              isOpen = hourNow >= (previousHours.open) && hourNow <= 24;
            }

            if ((isOpen && !previousHours.closed) || (!isOpen && hours.open)) {
              const open = previousHours.open > 12 && previousHours.open !== 0 ? `${this.mapTime(previousHours.open - 12)} pm` : previousHours.open === 12 ? `${this.mapTime(previousHours.open)} pm` : `${this.mapTime(previousHours.open)} am`;
              const nextOpen = hours.open > 12 && hours.open !== 0 ? `${this.mapTime(hours.open - 12)} pm` : hours.open === 12 ? `${this.mapTime(hours.open)} pm` : `${this.mapTime(hours.open)} am`;
              const close = previousHours.close > 12 && previousHours.close !== 0 ? `${this.mapTime(previousHours.close - 12)} pm` : previousHours.close === 12 ? `${this.mapTime(previousHours.close)} pm` : `${this.mapTime(previousHours.close)} am`;
              if (closeOnly) {
                if (isOpen) {
                  return hours ? 'until ' + close : null;
                } else {
                  return hours ? 'until ' + nextOpen : null;
                }

              } else {
                  return hours ? open + ' - ' + close : null;
              }
            }
            if (previousHours.fullDay) {
              return 'Open all day';
            }
            return null;

        } else {
          isOpen = hours.close < hours.open ?
          // for when venue closes next day
          (hourNow < hours.open && hourNow < hours.close) || (hourNow >= hours.open && hourNow > hours.close)
          : hourNow >= hours.open && hourNow < hours.close;
          if (!hours.closed) {
            if (hours.open >= 0 && hours.close >= 0) {
              const open = hours.open > 12 && hours.open !== 0 ? `${this.mapTime(hours.open - 12)} pm` : hours.open === 12 ? `${this.mapTime(hours.open)} pm` : `${this.mapTime(hours.open)} am`;
              const close = hours.close > 12 && hours.close !== 0 ? `${this.mapTime(hours.close - 12)} pm` : hours.close === 12 ? `${this.mapTime(hours.close)} pm` : `${this.mapTime(hours.close)} am`;
              if (closeOnly) {
                if (isOpen) {
                  return hours ? 'until ' + close : null;
                } else {
                  return hours ? 'until ' + open : null;
                }

              } else {
                return hours ? open + ' - ' + close : null;
              }

            }
            if (hours.fullDay) {
              return 'Open all day';
            }
            return null;

          } else {
            return 'Closed today';
          }
        }


      }
      return null;
    }
  }

  private mapTime(hours: number) {
    var sign = hours < 0 ? "-" : "";
    var min = Math.floor(Math.abs(hours));
    var sec = Math.floor((Math.abs(hours) * 60) % 60);
    return (min === 0 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  }

}
