import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'shortenDaysOfWeek'
})
export class ShortenDaysOfWeekPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      value = moment().day(value).format("ddd");
    }
    return value;
  }

}
