import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'twoDatesDisplay'
})
export class TwoDatesFormat implements PipeTransform {
  transform(date1: any, date2: any, includeDay?: boolean, spanClass?: string): any {
    date1 = moment(date1);
    date2 = moment(date2);
    const sameDay = date1.isSame(date2, 'day');
    const nextDay = moment(date1).add(1, 'day').isSame(date2, 'day');
    const sameMonth = date1.isSame(date2, 'month');
    if (sameDay) {
      return `${date1.format(includeDay ? 'dddd D MMM' : 'D MMM')} <span ${spanClass ? 'class="' + spanClass + '"' : ''}>${date1.format('HH:mm')} - ${date2.format('HH:mm')}</span>`;
    } else if (nextDay) {
      return `${date1.format(includeDay ? 'dddd D MMM' : 'D MMM')} <span ${spanClass ? 'class="' + spanClass + '"' : ''}>${date1.format('HH:mm')} - ${date2.format('HH:mm')}</span>`;
    } else if (sameMonth) {
      return `${date1.format('MMMM')} ${date1.format('D')} - ${date2.format('D')}`;
    } else {
      return `${date1.format(includeDay ? 'dddd D MMM' : 'D MMM')} - ${date2.format(includeDay ? 'dddd D MMM' : 'D MMM')}`;
    }
  }
};
