<div class="cookie-container container py-4" [@fadeUp]="state">
    <div class="cookie-panel d-flex flex-row flex-no-wrap align-items-center justify-content-between">
        <p class="mr-1 mr-sm-3 my-0 py-0 py-sm-2">
            By using our site, you acknowledge that you have read and understand our <a href="https://help.ping-culture.com/docs/cookie-policy" target="_blank">Cookie</a> & <a href="https://help.ping-culture.com/docs/privacy-policy" target="_blank">Privacy</a> Policy as well as
            our <a href="https://help.ping-culture.com/docs/terms-of-service" target="_blank">Terms and conditions</a>.
        </p>
        <div class="accept-btn d-flex flex-center p-2 flex-grow-0" (click)="accept()">
            OK
        </div>
    </div>
</div>
