import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { StoreService } from 'src/app/core/services/store.service';
import { ICategories } from 'src/app/core/models/categories';
import { isNotEmpty } from 'src/app/core/guards/type.guards';

@Injectable()
export class CategoriesResolver implements Resolve<ICategories[]> {

    constructor(
        private categoriesService: CategoriesService,
        private store: StoreService,
        @Inject(PLATFORM_ID) private platformId,
        private transferState:TransferState) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICategories[]> {


        if (!isPlatformServer(this.platformId)) {
          return of(null);
        }

        const CATEGORIES_KEY = makeStateKey<ICategories[]>('categories');

        if (this.transferState.hasKey(CATEGORIES_KEY)) {
            const categories: ICategories[] = this.transferState.get<ICategories[]>(CATEGORIES_KEY, null);
            this.transferState.remove(CATEGORIES_KEY);
            return of(categories);
        }
        else {
            this.categoriesService.fetchCategories();
            return this.store.object$<ICategories[]>('categories')
                .pipe(
                    filter(isNotEmpty),
                    tap(cats => {
                        if (isPlatformServer(this.platformId)) {
                            this.transferState.set(CATEGORIES_KEY, cats);
                        }
                    }),
                    first()
                );
        }
    }
}
