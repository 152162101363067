import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { createFeedback } from '../api/mutations';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends ApiService {

  constructor(private message: MatSnackBar) { 
    super();
  }

  public submitReport(data: any) {
    const input: any = {
      feedback: `${data.type}: ${data.comment}`,
      name: data?.name,
      email: data?.email,
      createdAt: + new Date(),
      updatedAt: + new Date(),
    }
    return this.runMutation(createFeedback, { input }, 'createFeedback', true, true)
      .pipe(
        tap(() => {
          this.message.open('Thanks! We\'ll get back to you ASAP!', null, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'center'});
        }),
        first())
  }
}
