export const getEntity = /* GraphQL */ `
  query GetEntity(
    $id: ID!
    $dateTime: ModelFloatKeyConditionInput,
    $sortDirection: ModelSortDirection
    ) {
    getEntity(id: $id) {
      id
      title
      subtitle
      summary
      nickname
      virtualTourUrl
      description
      uniqueAspect
      headerVideos
      username
      categories
      cuisines
      dietryOptions
      phone
      nearbyStations {
        title
        distance
        line
        zone
        type
      }
      safetyChecklist {
        title
        listItems
      }
      internationalPhoneNumber
      logo
      websiteUrl
      location {
        lat
        lon
      }
      opens
      closes
      openHours {
        monday {
          open
          close
          fullDay
          closed
          vibe
        }
        tuesday {
          open
          close
          fullDay
          closed
          vibe
        }
        wednesday {
          open
          close
          fullDay
          closed
          vibe
        }
        thursday {
          open
          close
          fullDay
          closed
          vibe
        }
        friday {
          open
          close
          fullDay
          closed
          vibe
        }
        saturday {
          open
          close
          fullDay
          closed
          vibe
        }
        sunday {
          open
          close
          fullDay
          closed
          vibe
        }
      }
      status
      address {
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      bookingUrl
      config {
        canBook
        bookingConfig {
          type
          bookingProvider,
          bookingProviderUsername
          bookingEmails
        }
        colourScheme {
          primary
          secondary
          text
          backgrounds
          buttons
        }
      }
      reviewsDisabled
      video
      city
      area
      currency {
        symbol
      }
      features {
        title
        icon
      }
      rules
      score
      reviewRating
      reviewCount
      email
      menuUrlList {
        title
        url
      }
      menuPDFList {
        title
        url
      }
      menuImages
      checklist {
        title
        listItems
      }
      entityFeatures {
          title
          description
          bookUrl
          images
          status
        }
      menu {
        id
        title
        status
        sections {
          id
          description
          title
          icon
          type
          status
          items {
            id
            title
            description
            price
            image
            offerPrice
            type
            status
          }
        }
      }
      price
      currency {
        symbol
      }
      featured
      verified
      publisherId
      publisher {
        email
      }
      subscribeOfferId
      subscriberCount
      pushTimestamp
      extraInfo {
        title
        content
      }
      images
      socials {
        facebook
        twitter
        instagram
        tiktok
      }
      minBookingLimit
      maxBookingLimit
      createdAt
      offersArray
      whatsappNumber
      updatedAt
      events(
        dateTime: $dateTime,
        sortDirection: $sortDirection,
        limit: 6) {
        items {
          id
          title
          entityId
          publisherId
          subtitle
          description
          type
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          buyTicketUrl
          featured
          video
          images
          mapImages
          price
          city
          area
          attending
          promoterIds
          ttl
          views
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          createdAt
          updatedAt
        }
        nextToken
      }
      repeatEvents(sortDirection: DESC, limit: 6) {
        items {
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          id
          title
          entityId
          publisherId
          subtitle
          description
          type
          categories
          closingTime
          rules
          buyTicketUrl
          featured
          video
          images
          mapImages
          price
          city
          area
          attending
          promoterIds
          whatsappNumber
          phone
          status
          owner
          owners
          ownerType
          createdAt
          updatedAt

        }
      }
      reviews {
        items {
          id
          entityId
          review
          score
          feedback
          dateOfVisit
          user {
            firstName
            lastName
            profilePicture
          }
          status
          verified
          createdAt
          updatedAt
        }
        nextToken
      }
      offers (
      typeStatus: {
        eq : {
          type: "OFFER",
          status: "ACTIVE"
        }
      }
    ){
        items {
          id
          daysAvailable
          description
          title
          images
          weeks
          entityId
          quantity
          status
          redeemCount
          owner
          disclaimer
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyPromotions {
        items {
          id
          entityId
          daysAvailable
          startTime
          endTime
          description
          price
          images
          title
          status
          owner
          subscribersOnly
          createdAt
          updatedAt
        }
      }
      tables {
        items {
          id
          capacity
          quantityAvailable
          price
          disclaimer
          parentType
          entityId
          eventId
          repeatEventId
          status
          title
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      blogPosts (limit: 5) {
        items {
          id
          entityId
          summary
          blogPostId
          blogPost {
            id
            postType
            title
            desc
            publishStatus
            city
            area
            videoUrl
            headerImg
            ttl
            updatedAt
          }
        }
      }
      pingAwards {
        type
        date
      }
    }
  }
`;


export const entityShortUrlQuery = /* GraphQL */ `
  query EntityShortUrlQuery(
    $username: String
    $dateTime: ModelFloatKeyConditionInput,

    $sortDirection: ModelSortDirection
    $filter: ModelEntityFilterInput
  ) {
    entityShortUrlQuery(
      username: $username
      filter: $filter
      limit: 1
    ) {
      items {
        id
        title
        subtitle
        summary
        nickname
        virtualTourUrl
        username
        description
        uniqueAspect
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        headerVideos
        categories
        cuisines
        phone
        safetyChecklist {
          title
          listItems
        }
        internationalPhoneNumber
        logo
        websiteUrl
        location {
          lat
          lon
        }
        opens
        closes
        dietryOptions
        openHours {
          monday {
            open
            close
            fullDay
            closed
            vibe
          }
          tuesday {
            open
            close
            fullDay
            closed
            vibe
          }
          wednesday {
            open
            close
            fullDay
            closed
            vibe
          }
          thursday {
            open
            close
            fullDay
            closed
            vibe
          }
          friday {
            open
            close
            fullDay
            closed
            vibe
          }
          saturday {
            open
            close
            fullDay
            closed
            vibe
          }
          sunday {
            open
            close
            fullDay
            closed
            vibe
          }
        }
        status
        address {
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        bookingUrl
        config {
          canBook
          bookingConfig {
            type
            bookingProvider
            bookingEmails
            bookingProviderUsername
          }
          colourScheme {
            primary
            secondary
            text
            backgrounds
            buttons
          }
        }
        reviewsDisabled
        video
        city
        area
        currency {
          symbol
        }
        features {
          title
          icon
        }
        rules
        score
        reviewRating
        reviewCount
        email
        menuUrlList {
          title
          url
        }
        menuPDFList {
          title
          url
        }
        menuImages
        checklist {
          title
          listItems
        }
        entityFeatures {
            title
            description
            bookUrl
            images
            status
          }
        menu {
          id
          title
          status
          sections {
            id
            description
            title
            icon
            type
            status
            items {
              id
              title
              description
              price
              image
              offerPrice
              type
              status
            }
          }
        }
        price
        featured
        verified
        publisherId
        publisher {
          email
        }
        subscribeOfferId
        subscriberCount
        pushTimestamp
        extraInfo {
          title
          content
        }
        images
        socials {
          facebook
          twitter
          instagram
          tiktok
        }
        minBookingLimit
        maxBookingLimit
        createdAt
        offersArray
        whatsappNumber
        updatedAt
        events(
          dateTime: $dateTime,
          sortDirection: $sortDirection,
          limit: 6) {
          items {
            id
            title
            entityId
            publisherId
            subtitle
            description
            type
            categories
            dateTime
            startDate
            endDate
            closingTime
            rules
            buyTicketUrl
            featured
            video
            images
            mapImages
            price
            city
            area
            attending
            promoterIds
            ttl
            views
            whatsappNumber
            phone
            status
            owner
            owners
            ownerType
            createdAt
            updatedAt
          }
          nextToken
        }
        repeatEvents(sortDirection: DESC, limit: 6) {
          items {
            dayOfWeek
            daysOfWeek
            startTime
            endTime
            id
            title
            entityId
            publisherId
            subtitle
            description
            type
            categories
            closingTime
            rules
            buyTicketUrl
            featured
            video
            images
            mapImages
            price
            city
            area
            attending
            promoterIds
            whatsappNumber
            phone
            status
            owner
            owners
            ownerType
            createdAt
            updatedAt

          }
        }
        reviews {
          items {
            id
            entityId
            review
            score
            feedback
            dateOfVisit
            user {
              firstName
              lastName
              profilePicture
            }
            status
            verified
            createdAt
            updatedAt
          }
          nextToken
        }
        offers (
        typeStatus: {
          eq : {
            type: "OFFER",
            status: "ACTIVE"
          }
        }
      ){
          items {
            id
            daysAvailable
            description
            title
            images
            weeks
            entityId
            quantity
            status
            redeemCount
            owner
            disclaimer
            createdAt
            updatedAt
          }
          nextToken
        }
        dailyPromotions {
          items {
            id
            entityId
            daysAvailable
            startTime
            endTime
            description
            price
            images
            title
            status
            owner
            subscribersOnly
            createdAt
            updatedAt
          }
        }
        tables {
          items {
            id
            capacity
            quantityAvailable
            price
            disclaimer
            parentType
            entityId
            eventId
            repeatEventId
            status
            title
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        blogPosts (limit: 5) {
          items {
            id
            entityId
            summary
            blogPostId
            blogPost {
              id
              postType
              title
              desc
              publishStatus
              city
              area
              videoUrl
              headerImg
              ttl
              updatedAt
            }
          }
        }
        pingAwards {
          type
          date
        }
      }
    }
  }
`;
