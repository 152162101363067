export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      entityId
      publisherId
      username
      subtitle
      publisher {
        businessName
        logo
      }
      description
      type
      score
      config {
        canBuyTickets
        ticketConfig {
          availableAppOnly
        }
      }
      address {
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      eventFeatures {
        title
        description
        bookUrl
        images
        status
      }
      categories
      dateTime
      startDate
      endDate
      closingTime
      menuUrlList {
        title
        url
      }
      menuPDFList{
        title
        url
      }
      menu {
        id
        sections {
          id
          description
          title
          type
          icon
          items {
            title
            description
            price
            offerPrice
            image
          }
          status
        }
        title
        status
      }
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      checklist {
        title
        listItems
      }
      rules
      buyTicketUrl
      featured
      video
      images
      artistsList
      mapImages
      location {
        lat
        lon
      }
      price
      city
      features {
        title
        icon
      }
      attending
      extraInfo {
        title
        content
      }
      promoterIds
      ttl
      views
      whatsappNumber
      phone
      tables {
        items {
          id
        }
      }
      status
      owner
      owners
      ownerType
      createdAt
      updatedAt
      entity {
        id
        title
        logo
        subtitle
        summary
        nickname
        description
        price
        currency {
          symbol
        }
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        username
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        location {
          lat
          lon
        }
        opens
        closes
        status
        images
        address {
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
      }
    }
  }
`;
export const eventShortUrlQuery = /* GraphQL */ `
  query EventShortUrlQuery(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventShortUrlQuery(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        entityId
        publisherId
        username
        subtitle
        publisher {
          businessName
          logo
        }
        description
        type
        score
        config {
          canBuyTickets
          ticketConfig {
            availableAppOnly
          }
        }
        address {
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        eventFeatures {
          title
          description
          bookUrl
          images
          status
        }
        categories
        dateTime
        startDate
        endDate
        closingTime
        menuImages
        menuUrlList {
          title
          url
        }
        menuPDFList{
          title
          url
        }
        menu {
          id
          sections {
            id
            description
            title
            type
            icon
            items {
              title
              description
              price
              offerPrice
              image
            }
            status
          }
          title
          status
        }
        timelines {
          id
          title
          description
          hideTimes
          timeslots {
            id
            title
            description
            startDate
            endDate
            artistId
            genres
            startTime
            endTime
            image
          }
        }
        checklist {
          title
          listItems
        }
        rules
        buyTicketUrl
        featured
        video
        images
        artistsList
        mapImages
        location {
          lat
          lon
        }
        price
        city
        features {
          title
          icon
        }
        attending
        extraInfo {
          title
          content
        }
        promoterIds
        ttl
        views
        whatsappNumber
        phone
        tables {
          items {
            id
          }
        }
        status
        owner
        owners
        ownerType
        createdAt
        updatedAt
        entity {
          id
          title
          logo
          subtitle
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          username
          price
          currency {
            symbol
          }
          nearbyStations {
            title
            distance
            line
            zone
            type
          }
          location {
            lat
            lon
          }
          opens
          closes
          status
          images
          address {
            streetNameNumber
            addressLine1
            addressLine2
            addressLine3
            city
            province
            country
            postcode
          }
        }
      }
    }
  }
`;

export const getRepeatEvent = /* GraphQL */ `
  query GetRepeatEvent($id: ID!) {
    getRepeatEvent(id: $id) {
      id
      title
      entityId
      publisherId
      publisher {
        businessName
        logo
      }
      subtitle
      description
      type
      username
      score
      address {
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      eventFeatures {
        title
        description
        bookUrl
        images
        status
      }
      config {
        canBuyTickets
        ticketConfig {
          availableAppOnly
        }
      }
      categories
      closingTime
      dayOfWeek
      daysOfWeek
      startTime
      endTime
      showWeather
      buyTicketUrl
      ttl
      whatsappNumber
      phone
      mapImages
      video
      images
      artistsList
      features {
        title
        icon
      }
      featured
      location {
        lat
        lon
      }
      price
      rules
      city
      attending
      status
      ownerType
      owner
      promoterIds
      extraInfo {
        title
        content
      }
      owners
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      checklist {
        title
        listItems
      }
      createdAt
      menuImages
      menuUrlList {
        title
        url
      }
      menuPDFList{
        title
        url
      }
      menu {
        id
        sections {
          id
          description
          title
          type
          icon
          items {
            title
            description
            price
            offerPrice
            image
          }
          status
        }
        title
        status
      }
      updatedAt
      entity {
        id
        title
        images
        subtitle
        summary
        nickname
        description
        categories
        phone
        internationalPhoneNumber
        logo
        websiteUrl
        username
        nearbyStations {
          title
          distance
          line
          zone
          type
        }
        location {
          lat
          lon
        }
        opens
        closes
        status
        address {
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
      }
    }
  }
`;



export const repeatEventShortUrlQuery = /* GraphQL */ `
  query RepeatEventShortUrlQuery(
    $username: String
  ) {
    repeatEventShortUrlQuery(
      username: $username
      limit: 1
    ) {
      items {
        id
        title
        entityId
        publisherId
        ownerType
        publisher {
          businessName
          logo
        }
        subtitle
        description
        type
        score
        address {
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        categories
        closingTime
        dayOfWeek
        username
        config {
          canBuyTickets
          ticketConfig {
            availableAppOnly
          }
        }
        daysOfWeek
        startTime
        endTime
        showWeather
        buyTicketUrl
        ttl
        whatsappNumber
        phone
        mapImages
        video
        images
        artistsList
        features {
          title
          icon
        }
        featured
        location {
          lat
          lon
        }
        price
        rules
        city
        attending
        status
        owner
        promoterIds
        extraInfo {
          title
          content
        }
        owners
        timelines {
          id
          title
          description
          hideTimes
          timeslots {
            id
            title
            description
            startDate
            endDate
            artistId
            genres
            startTime
            endTime
            image
          }
        }
        checklist {
          title
          listItems
        }
        createdAt
        updatedAt
        menuUrlList {
          title
          url
        }
        menuPDFList{
          title
          url
        }
        menu {
          id
          sections {
            id
            description
            title
            type
            icon
            items {
              title
              description
              price
              offerPrice
              image
            }
            status
          }
          title
          status
        }
        entity {
          id
          title
          subtitle
          images
          summary
          nickname
          description
          categories
          phone
          internationalPhoneNumber
          logo
          websiteUrl
          username
          nearbyStations {
            title
            distance
            line
            zone
            type
          }
          location {
            lat
            lon
          }
          opens
          closes
          status
          address {
            streetNameNumber
            addressLine1
            addressLine2
            addressLine3
            city
            province
            country
            postcode
          }
        }
      }
    }
  }
`;

export const timelineByEvent = /* GraphQL */ `
  query TimelineByEvent(
    $eventId: String
    $sortDirection: ModelSortDirection
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timelineByEvent(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        eventId
        title
        hideTimes
        description
        timeslots {
            id
            title
            description
            startDate
            endDate
            artistId
            image
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const searchElasticRepeatedEvents =  /* GraphQL */  `query SearchElasticRepeatedEvents($input: SearchRepeatEventsInput) {
  searchElasticRepeatedEvents(input: $input) {
    items {
      id
      title
      entityId
      daysOfWeek
      subtitle
      description
      type
      categories
      closingTime
      dayOfWeek
      publisherId
      ownerType
      daysOfWeek
      startTime
      username
      endTime
      location {
        lat
        lon
      }
      entity {
        id
        title
        images
        username
      }
      timelines {
        id
        title
        description
        hideTimes
        timeslots {
          id
          title
          description
          startDate
          endDate
          artistId
          genres
          startTime
          endTime
          image
        }
      }
      buyTicketUrl
      canBook
      video
      images
      price
      city
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const searchEvents =  /* GraphQL */  `query SearchEvents(
  $filter: SearchableEventFilterInput
  $sort: SearchableEventSortInput
  $limit: Int
  $nextToken: String
) {
  searchEvents(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      type
      artistsList
      startDate
      publisherId
      ownerType
      attending
      categories
      username
      endDate
      dateTime
      categories
      images
      location {
        lat
        lon
      }
      city
      entity {
        title
        logo
        images
        location {
          lat
          lon
        }
      }
    }
    nextToken
  }
}
`;
