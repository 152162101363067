import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { CreateEntityInput } from 'src/app/core/api/api';

@Pipe({
  name: 'isOpen'
})
export class IsOpenPipe implements PipeTransform {


  private weekday = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  transform(entity: CreateEntityInput, ...args: any[]): any {
    try {
      const date = new Date();
      const dateMoment = moment(date);
      const dayIndex = date.getDay();
      let day = this.weekday[dayIndex];


      if (!entity.openHours) {
        return false;
      }

      // check if close time is next morning/day, if so then use previous days hours
      const previousDayIndex = dayIndex === 0 ? 6 : dayIndex - 1;
      const previousDay = this.weekday[previousDayIndex];

      // to help with the logic
      if (entity?.openHours[day].open === 0) { entity.openHours[day].open = 24; }
      if (entity?.openHours[day].close === 0) { entity.openHours[day].close = 24; }
      if (entity?.openHours[previousDay].open === 0) { entity.openHours[previousDay].open = 24; }
      if (entity?.openHours[previousDay].close === 0) { entity.openHours[previousDay].close = 24; }

      if (
          (!entity.openHours[day].open && (entity.openHours[previousDay].open || entity.openHours[previousDay].fullDay) && (date.getHours() < entity.openHours[previousDay].open))
          || ((entity.openHours[day].open > entity.openHours[day].close) && (date.getHours() < entity.openHours[day].open))
        ) {
        day = previousDay;
        dateMoment.subtract(1, 'day');
      }

      const hours = entity.openHours[day] || false;

      if (hours.closed) { return false; }
      if (hours.fullDay) { return true; }
      if (hours) {
        const open = hours.open % 1 == 0 ? dateMoment.clone().hour(hours.open).minute(0) : dateMoment.clone().hour(hours.open);
        const close = hours.close % 1 === 0 ? dateMoment.clone().hour(hours.close).minute(0) : dateMoment.clone().hour(hours.close);
        return this.isOpen(open, close);
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }


  private isOpen(startTime: moment.Moment, endTime: moment.Moment,) {

    if (endTime.isBefore(startTime)) {
      endTime = endTime.clone().add(1, 'day');
    }
    const now = moment();
    const isBetween = now.isBetween(startTime, endTime);
    return isBetween;


  }

}
