export const searchElasticPlaces = /* GraphQL */ `query SearchElasticPlaces(
    $input: SearchPlacesInput
    $dateTime: ModelFloatKeyConditionInput
    ) {
      searchElasticPlaces(input: $input) {
        items {
          id
          title
          description
          subtitle
          username
          summary
          maxBookingLimit
          logo
          headerVideos
          minBookingLimit
          offers (
            typeStatus: {
              eq : {
                type: "OFFER",
                status: "ACTIVE"
              }
            }
          ){
            items {
              id
            }
          }
          address {
            title
            streetNameNumber
            addressLine1
            addressLine2
            addressLine3
            city
            province
            country
            postcode
          }
          outdoorArea
          outdoorHeating

          categories
          cuisines
          publisherId
          publisher {
            id
          }
          location {
            lat
            lon
          }
          openHours {
            monday {
              open
              close
              fullDay
              closed
              vibe
            }
            tuesday {
              open
              close
              fullDay
              closed
              vibe
            }
            wednesday {
              open
              close
              fullDay
              closed
              vibe
            }
            thursday {
              open
              close
              fullDay
              closed
              vibe
            }
            friday {
              open
              close
              fullDay
              closed
              vibe
            }
            saturday {
              open
              close
              fullDay
              closed
              vibe
            }
            sunday {
              open
              close
              fullDay
              closed
              vibe
            }
          }
          currency {
            symbol
          }
          reviewRating
          reviewCount
          price
          verified
          images
          events (
            dateTime: $dateTime
            sortDirection: ASC
          ){
            items {
              id
            }
          }
        }
        nextToken
      }
    }
    `;

export const searchElasticPlacesSmall = /* GraphQL */ `query SearchElasticPlaces(
  $input: SearchPlacesInput
  ) {
    searchElasticPlaces(input: $input) {
      items {
        id
        title
        subtitle
        summary
        description
        images
        headerVideos
        username
        price
        publisherId
        city
        location {
          lat
          lon
        }
        currency {
          symbol
        }
        outdoorArea
        outdoorHeating
        categories
        logo
      }
      nextToken
    }
  }
  `;
