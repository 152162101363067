<div class="p-4 mt-4">
  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-center flex-column">
        <h3 class="font-weight-bold">Tell Us What You Think</h3>
        <p>We are always open to your ideas, want to recommend a place, or if you want to tell us some feedback and even any bugs you've found, just use the form below. And thanks!</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="input-group input-group-lg mb-3 w-100">
        <select class="custom-select" id="form-type-input" [(ngModel)]="type">
          <option selected disabled [value]="null">Choose...</option>
          <option value="Feedback">Feedback</option>
          <option value="Recommendation">Place Recommendation</option>
          <option value="Bug">Website Bug</option>
        </select>
      </div>





      <div class="input-group input-group-lg">
        <textarea class="form-control" aria-label="Feedback text area" [(ngModel)]="comment" rows="6" placeholder="Tell us your thoughts...."></textarea>
      </div>
    </div>
  </div>

  <div class="row my-3">
    <div class="col-12 col-md-6">
      <div class="input-group input-group-lg">
        <input type="text" class="form-control" aria-label="Large" [(ngModel)]="email" type="email" placeholder="Your email">
      </div>

    </div>
    <div class="col-12 col-md-6">
      <div class="input-group input-group-lg">
        <input type="text" class="form-control" placeholder="Your Name" aria-label="Large" [(ngModel)]="name">
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-center p-3">
        <button [disabled]="!comment || !type || loading" class="btn btn-lg" (click)="submit()" nzType="primary" >
         Submit
        </button>
      </div>
    </div>
  </div>
</div>

