export const getPublisher = /* GraphQL */  `query GetPublisher(
    $id: ID!
    $dateTime: ModelFloatKeyConditionInput
    $filter: ModelEventFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
    ) {
    getPublisher(id: $id) {
      id
        role
        firstName
        lastName
        email
        timezoneOffset
        timezone
        currency {
          code
          name
          symbol
        }
        country {
          alpha2Code
          alpha3Code
          callingCodes
          flag
          name
        }
        socials {
          facebook
          instagram
          twitter
        }
        phone
        whatsappNumber
        description
        websiteUrl
        logo
        termsVersionSigned
        businessName
        activationDate
        activated
        address {
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
        }
        profilePicture
        owner
        createdAt
        updatedAt
        repeatEvents(
          status: { eq: "ACTIVE" },
          limit: 15
        ){
          items {
            id
            title
            entityId
            publisherId
            subtitle
            description
            canBook
            type
            score
            address {
              streetNameNumber
              addressLine1
              addressLine2
              addressLine3
              city
              province
              country
              postcode
            }
            categories
            closingTime
            dayOfWeek
            daysOfWeek
            startTime
            endTime
            buyTicketUrl
            canBook
            whatsappNumber
            mapImages
            video
            images
            artistsList
            featured
            location {
              lat
              lon
            }
            price
            rules
            checklist {
              title
              listItems
            }
            timelines {
              id
              title
              description
              timeslots {
                title
                description
                startDate
                endDate
                artistId
                genres
                startTime
                endTime
                image
              }
              hideTimes
            }
            city
            attending
            status
            ownerType
            owner
            promoterIds
            extraInfo {
              title
              content
            }
            owners
            createdAt
            updatedAt
            entity {
              id
              title
              nickname
              location {
                lat
                lon
              }
              address {
                streetNameNumber
                addressLine1
                city
                province
                postcode
              }
            }
          }
          nextToken
        }
        events(
            dateTime: $dateTime
            filter: $filter
            limit: $limit
            sortDirection: $sortDirection
            nextToken: $nextToken
        ) {
        items {
            id
            title
            entityId
            publisherId
            subtitle
            description
            type
            canBook
            score
            address {
            streetNameNumber
            addressLine1
            addressLine2
            addressLine3
            city
            province
            country
            postcode
            }
            categories
            dateTime
            startDate
            endDate
            closingTime
            rules
            buyTicketUrl
            checklist {
            title
            listItems
            }
            timelines {
            id
            title
            description
            timeslots {
                title
                description
                startDate
                endDate
                artistId
                genres
                startTime
                endTime
                image
            }
            hideTimes
            }
            canBook
            featured
            video
            images
            artistsList
            mapImages
            location {
            lat
            lon
            }
            price
            city
            features {
            title
            icon
            }
            attending
            extraInfo {
            title
            content
            }
            promoterIds
            whatsappNumber
            tablesAvailable
            status
            owner
            owners
            ownerType
            createdAt
            updatedAt
            entity {
            id
            title
            nickname
            location {
                lat
                lon
            }
            address {
                streetNameNumber
                addressLine1
                city
                province
                postcode
            }
            }
        }
        nextToken
        }
    }
  }
  `;

export const getPublisherByUserName = /* GraphQL */  `query GetPublisher(
  $username: String!
  $dateTime: ModelFloatKeyConditionInput
  $filter: ModelEventFilterInput
  $sortDirection: ModelSortDirection
  $limit: Int
  $nextToken: String
  ) {
  getPublisher(username: $username) {
    id
      role
      firstName
      lastName
      email
      timezoneOffset
      timezone
      currency {
        code
        name
        symbol
      }
      country {
        alpha2Code
        alpha3Code
        callingCodes
        flag
        name
      }
      socials {
        facebook
        instagram
        twitter
      }
      phone
      whatsappNumber
      description
      websiteUrl
      logo
      termsVersionSigned
      businessName
      activationDate
      activated
      address {
        streetNameNumber
        addressLine1
        addressLine2
        addressLine3
        city
        province
        country
        postcode
      }
      profilePicture
      owner
      createdAt
      updatedAt
      repeatEvents(
        status: { eq: "ACTIVE" },
        limit: 15
      ){
        items {
          id
          title
          entityId
          publisherId
          subtitle
          description
          canBook
          type
          score
          address {
            streetNameNumber
            addressLine1
            addressLine2
            addressLine3
            city
            province
            country
            postcode
          }
          categories
          closingTime
          dayOfWeek
          daysOfWeek
          startTime
          endTime
          buyTicketUrl
          canBook
          whatsappNumber
          mapImages
          video
          images
          artistsList
          featured
          location {
            lat
            lon
          }
          price
          rules
          checklist {
            title
            listItems
          }
          timelines {
            id
            title
            description
            timeslots {
              title
              description
              startDate
              endDate
              artistId
              genres
              startTime
              endTime
              image
            }
            hideTimes
          }
          city
          attending
          status
          ownerType
          owner
          promoterIds
          extraInfo {
            title
            content
          }
          owners
          createdAt
          updatedAt
          entity {
            id
            title
            nickname
            location {
              lat
              lon
            }
            address {
              streetNameNumber
              addressLine1
              city
              province
              postcode
            }
          }
        }
        nextToken
      }
      events(
          dateTime: $dateTime
          filter: $filter
          limit: $limit
          sortDirection: $sortDirection
          nextToken: $nextToken
      ) {
      items {
          id
          title
          entityId
          publisherId
          subtitle
          description
          type
          canBook
          score
          address {
          streetNameNumber
          addressLine1
          addressLine2
          addressLine3
          city
          province
          country
          postcode
          }
          categories
          dateTime
          startDate
          endDate
          closingTime
          rules
          buyTicketUrl
          checklist {
          title
          listItems
          }
          timelines {
          id
          title
          description
          timeslots {
              title
              description
              startDate
              endDate
              artistId
              genres
              startTime
              endTime
              image
          }
          hideTimes
          }
          canBook
          featured
          video
          images
          artistsList
          mapImages
          location {
          lat
          lon
          }
          price
          city
          features {
          title
          icon
          }
          attending
          extraInfo {
          title
          content
          }
          promoterIds
          whatsappNumber
          tablesAvailable
          status
          owner
          owners
          ownerType
          createdAt
          updatedAt
          entity {
          id
          title
          nickname
          location {
              lat
              lon
          }
          address {
              streetNameNumber
              addressLine1
              city
              province
              postcode
          }
          }
      }
      nextToken
      }
  }
}
`;
